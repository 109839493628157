import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './MissingInformationModal.scss';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { UserData, useUpdateCurrentUser } from '../../../data/user';
import { LoadingButton } from '../../../components/LoadingButton';
import { useUserData } from '../../../stores/auth';
import MiniteSnackbar from '../../../components/snackbar';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
}
export interface UserInfoForm {
  iban: string;
  kvk_number: string;
  street_name: string;
  house_number: string;
  postal_code: string;
  company: string;
  city: string;
  vat_number: number;
}

export const initialState = {
  iban: '',
  kvk_number: null,
  street_name: '',
  house_number: '',
  postal_code: '',
  city: '',
  vat_number: null,
  company: '',
};

const schema = yup
  .object()
  .shape({
    iban: yup.string().required(),
    street_name: yup.string().required(),
    house_number: yup.string().required(),
    postal_code: yup
      .string()
      .matches(/\d{4}[A-Za-z]{2}$/)
      .required(),
    city: yup.string().required(),
    kvk_number: yup
      .string()
      .matches(/\d{8}$/)
      .nullable()
      .optional()
      .transform((_, val) => val || null),
    vat_number: yup
      .string()
      .matches(/[A-Za-z]{2}\d{9}B\d{2}$/)
      .nullable()
      .optional()
      .transform((_, val) => val || null),
    company: yup.string().when('vat_number', (value) => {
      return value
        ? yup.string().required()
        : yup.string().optional().nullable();
    }),
  })
  .required();

const MissingInformationModal = ({ open, handleClose }: Props) => {
  const { mutateAsync: updateUser, isLoading } = useUpdateCurrentUser();
  const userData = useUserData((state) => state.user);
  const refetchUser = useUserData((state) => state.refetchUser);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    iban,
    kvk_number,
    street_name,
    house_number,
    postal_code,
    city,
    vat_number,
    student,
  } = userData as unknown as UserData;

  const userInfo = {
    iban,
    kvk_number,
    street_name,
    house_number,
    postal_code,
    city,
    vat_number,
    company: student?.company_name,
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<UserInfoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialState,
      ...userInfo,
    },
  });

  const selectedVatNumber = useWatch({ control, name: 'vat_number' });

  const onClose = () => {
    reset(initialState);
    handleClose();
  };

  const onSubmit = async (value) => {
    const {
      iban,
      kvk_number,
      street_name,
      house_number,
      postal_code,
      city,
      vat_number,
      company,
    } = value;

    try {
      let payload: any = {
        user: {
          kvk_number,
          street_name,
          house_number,
          postal_code,
          city,
          vat_number,
          iban,
        },
      };

      if (company) {
        payload = {
          ...payload,
          student: {
            company_name: company,
          },
        };
      }

      await updateUser(payload);
      await refetchUser();
      onClose();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };

  return (
    <>
      <Dialog open={open} className="missing-info-modal">
        <DialogTitle className="content-title">
          Missing information
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="content-text">
            Oops, you’re missing some important details. Please update to
            continue.
          </DialogContentText>
          <form className="user-info-form">
            <TextField
              {...register('iban')}
              type="text"
              id="iban-input"
              label="IBAN"
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />

            <FormGroup className="form-group">
              <TextField
                {...register('street_name')}
                type="text"
                className="user-info-form-groups"
                id="street-input"
                variant="standard"
                label="Street name"
                error={!!errors.street_name}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                type="text"
                id="house-input"
                className="user-info-form-groups"
                {...register('house_number')}
                variant="standard"
                label="House number"
                error={!!errors.house_number}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>

            <FormGroup className="form-group">
              <TextField
                {...register('postal_code')}
                type="text"
                id="postal-input"
                className="user-info-form-groups"
                variant="standard"
                label="Postal code"
                placeholder="1111AA"
                inputProps={{
                  maxLength: 6,
                }}
                error={!!errors.postal_code}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                type="text"
                id="city-input"
                className="user-info-form-groups"
                {...register('city')}
                variant="standard"
                label="City"
                error={!!errors.city}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>

            <TextField
              {...register('kvk_number')}
              type="text"
              id="kvk-input"
              label="KvK Nr."
              inputProps={{
                maxLength: 8,
              }}
              variant="standard"
              helperText={'* Optional'}
              error={!!errors.kvk_number}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              {...register('vat_number')}
              type="text"
              id="vat-input"
              label="VAT Nr."
              variant="standard"
              helperText={'* Optional'}
              error={!!errors.vat_number}
              InputLabelProps={{ shrink: true }}
            />
            {selectedVatNumber && (
              <TextField
                {...register('company')}
                type="text"
                id="vat-input"
                label="Company name"
                variant="standard"
                placeholder="If you don’t have this, you can use your first and last name"
                error={!!errors.company}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button
            onClick={onClose}
            className="button"
            data-testid="close-mising-info-modal"
          >
            Cancel
          </Button>
          <Tooltip disableHoverListener={isValid} title={t('fill_all')}>
            <span>
              <LoadingButton
                className="button agree"
                autoFocus
                onClick={handleSubmit(onSubmit)}
                isDisabled={!isValid}
                buttonText={'Confirm'}
                isLoading={isLoading}
                confirmButtonTestId="save-missing-user-info"
              />
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};

export default MissingInformationModal;
