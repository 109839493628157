import React from 'react';
import {
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import Row from './Row';
import './CollapsibleTable.scss';

const CollapsibleTable = ({
  data = [],
  pagination,
  changePage,
  handleChangeRowsPerPage,
}: {
  data: any;
  pagination: { total: number; page: number; per_page: number };
  changePage: (arg: number) => void;
  handleChangeRowsPerPage: (arg: number) => void;
}) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage);
  };
  const handleUseChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleChangeRowsPerPage(parseInt(event.target.value));
  };

  return (
    <div className="reviews-table">
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderTop: 'none' }}>
              <TableCell>Review</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Receiver</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={pagination?.total || 0}
        rowsPerPage={pagination?.per_page || 15}
        onRowsPerPageChange={handleUseChangeRowsPerPage}
        page={pagination?.page - 1 || 0}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[
          15,
          50,
          100,
          { label: 'All', value: pagination?.total || 0 },
        ]}
      />
    </div>
  );
};

export default CollapsibleTable;
