import { request, ServerResponse } from '../core/request';
import { useMutation } from '@tanstack/react-query';

const addUniversities = async (payload: IAddUniversity[]) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/universities/create`,
    method: 'POST',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useAddUniversities = () => {
  return useMutation((payload: IAddUniversity[]) => addUniversities(payload), {
    onError: (e) => console.error(e),
  });
};

const deleteUniversities = async (universities: string[]) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/universities/delete`,
    method: 'POST',
    data: universities,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useDeleteUniversities = () => {
  return useMutation(
    (universities: string[]) => deleteUniversities(universities),
    {
      onError: (e) => console.error(e),
    },
  );
};

export interface IAddUniversity {
  university_name: string;
}
