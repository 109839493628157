import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import './LoadingButton.scss';

interface IProps {
  variant?: 'text' | 'outlined' | 'contained';
  id?: string;
  className?: string;
  onClick?: (e?: any) => void;
  isDisabled?: boolean;
  isLoading: boolean;
  buttonText: string;
  type?: 'button' | 'submit' | 'reset';
  autoFocus?: boolean;
  sx?: unknown;
  confirmButtonTestId?: string;
  startIcon?: any;
}

export const LoadingButton = ({
  variant = 'contained',
  id,
  onClick,
  isDisabled,
  isLoading,
  buttonText,
  className,
  autoFocus,
  type,
  sx,
  confirmButtonTestId,
  startIcon,
}: IProps) => {
  return (
    <Button
      variant={variant}
      id={id}
      startIcon={startIcon}
      onClick={onClick}
      autoFocus={autoFocus}
      disabled={isDisabled || isLoading}
      className={`button ${className}`}
      type={type || 'button'}
      sx={sx}
      data-testid={confirmButtonTestId}
    >
      {isLoading ? (
        <CircularProgress size={14} sx={{ margin: '5px' }} />
      ) : (
        buttonText
      )}
    </Button>
  );
};
