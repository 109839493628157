import React, { useState } from 'react';
import { Button } from '@mui/material';
import GenericModal from '../../../../../components/generic-modal/GenericModal';

const DeleteJob = ({
  deleteJob,
  isLoading,
}: {
  deleteJob: () => void;
  isLoading: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirm = () => {
    deleteJob();
  };

  return (
    <div className="delete-wrapper">
      <Button
        onClick={handleShowModal}
        className="delete-button"
        data-testid="delete-job"
      >
        Delete job
      </Button>
      <GenericModal
        open={isOpen}
        title="Are you sure?"
        content="Are you sure you want to delete that job?"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        handleConfirm={handleConfirm}
        handleCancel={handleShowModal}
        confirmButtonTestId="confirm-delete-job"
        mode="warning"
        isLoading={isLoading}
        loadingButton
      />
    </div>
  );
};

export default DeleteJob;
