import React from 'react';
import '../AdminGeneralStyles.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import { useJobStatistic } from '../../../data/admin-jobs';
import { useCompanyStatistic } from '../../../data/admin-companies';
import { useStudentsStatistic } from '../../../data/admin-students';
import { numberWithDec } from '../../../utils/numberWithDec';
import { useReviewsStatistic } from '../../../data/admin-reviews';
import {
  AdminTimesheetsStatisticsResponse,
  useTimesheetStatistic,
} from '../../../data/admin-timesheets';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoVat from './NoVat';

const getStatisticCards = (statisticsInfo) => {
  const {
    jobs_pending_approval,
    jobs_open,
    jobs_in_progress,
    jobs_interviewing,
  } = statisticsInfo;
  return (
    <div className="admin-statistics-container admin-smaller-statistics">
      <AdminStatisticCard
        key={'approval'}
        text={'Jobs pending approval'}
        counter={jobs_pending_approval || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'open'}
        text={'Jobs open'}
        counter={jobs_open || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'progress'}
        text={'Jobs in progress'}
        counter={jobs_in_progress || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'interview'}
        text={'Jobs interviewing'}
        counter={jobs_interviewing || 0}
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const getStatisticCardsCompanies = (statisticsCompanies) => {
  return (
    <div className="admin-statistics-container admin-smaller-statistics">
      <AdminStatisticCard
        key={'total'}
        text={'Total companies'}
        counter={statisticsCompanies.companies_total}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'average'}
        text={'Average posted jobs per company'}
        counter={statisticsCompanies.average_jobs_per_company}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'high-flyers'}
        text={'Average High Flyers per company'}
        counter={statisticsCompanies.average_students_per_company}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'this-month'}
        text={'New company signups this month'}
        counter={statisticsCompanies.companies_this_month}
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const getStatisticCardsStudents = (data) => {
  const { students_total, students_verified, students_with_job_in_progress } =
    data;
  return (
    <div className="admin-statistics-container admin-smaller-statistics">
      <AdminStatisticCard
        key={'total'}
        text={'Total students'}
        counter={students_total}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'verified'}
        text={'Verified students'}
        counter={students_verified}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'progress'}
        text={'Students with at least 1 job in progress'}
        counter={students_with_job_in_progress}
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const getStatisticCardsReviews = (data) => {
  const {
    student_avg_review_by_companies = 0,
    company_avg_review_by_students = 0,
    reviews_this_month,
    reviews_total,
  } = data;

  return (
    <div className="admin-statistics-container admin-smaller-statistics">
      <AdminStatisticCard
        key={'student_avg_review_by_companies'}
        text={'Average review from companies about students'}
        counter={numberWithDec(student_avg_review_by_companies)}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'company_avg_review_by_students'}
        text={'Average review from students about companies'}
        counter={numberWithDec(company_avg_review_by_students)}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'reviews_this_month'}
        text={'Reviews this month'}
        counter={reviews_this_month || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'reviews_total'}
        text={'Total reviews'}
        counter={reviews_total || 0}
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const getStatisticCardsHours = (
  statistics: AdminTimesheetsStatisticsResponse,
) => {
  return (
    <div className="admin-statistics-container admin-smaller-statistics">
      <AdminStatisticCard
        key={'total'}
        text={'Total hours booked this month'}
        counter={statistics?.total_hours_this_month || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'high-flyers'}
        text={'Ongoing jobs in progress this month'}
        counter={statistics?.total_jobs_in_progress_this_month || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'approved'}
        text={'Approved timesheets this month'}
        counter={
          (statistics?.percentage_timesheets_approved_this_month || 0) + '%'
        }
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const AdminStatistics = () => {
  const { data: statisticsJob = {} } = useJobStatistic();
  const { data: statisticsCompanies = {} } = useCompanyStatistic();
  const { data: statisticsStudents = {} } = useStudentsStatistic();
  const { data: statisticsReviews = {} } = useReviewsStatistic();
  const { data: statisticsHours } = useTimesheetStatistic();

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-jobs-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Statistics</p>

      <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Show all statistic cards
            </AccordionSummary>
            <AccordionDetails>
              <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                {getStatisticCards(statisticsJob)}
              </Grid>

              <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
                {getStatisticCardsCompanies(statisticsCompanies)}
              </Grid>
              <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
                {getStatisticCardsStudents(statisticsStudents)}
              </Grid>
              <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
                {getStatisticCardsReviews(statisticsReviews)}
              </Grid>
              <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
                {getStatisticCardsHours(statisticsHours)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>

      <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
        <NoVat />
      </Grid>
    </Grid>
  );
};

export default AdminStatistics;
