import React, { useEffect, useState } from 'react';
import './Timesheet.scss';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  TimesheetStatuses,
  useApproveJobTimesheetMonth,
  useCreateJobTimeEntry,
  useDeleteJobTimeEntry,
  useFetchJobTimesheets,
  useFetchStudentTimesheets,
  useLowHourReason,
  useRejectJobTimesheetMonth,
  useSetReviewJob,
  useSubmitJobTimesheetMonth,
  useUpdateJobTimeEntry,
} from '../../data/timesheets';
import { useFetchJobById } from '../../data/jobs';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link as MuiLink,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  LinearProgress,
} from '@mui/material';
import { Routes } from '../../utils/consts';
import StatisticCard from '../../components/statistic-card';
import MiniteGrid from '../../components/grid';
import {
  GridColDef,
  GridFooterContainer,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatDate } from '../../utils/dates';
import SubmitConfirmation from './components/SubmitConfirmation';
import { UserData } from '../../data/user';
import ApproveConfirmation from './components/ApproveConfirmation';
import RejectConfirmation from './components/RejectConfirmation';
import CreateEntryModal from './components/CreateEntryModal';
import LowHoursModal from './components/LowHoursModal';
import ReviewModal from '../../components/review-modal/ReviewModal';
import MiniteSnackbar from '../../components/snackbar';
import { ProfileData, useFetchStudentById } from '../../data/profile';
import { handleGridHeight } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../utils/useDocumentTitle';
import CompanyAvatar from '../../components/company-avatar';
import { useSnackbar } from 'notistack';
import NotFound from '../not-found';
import { useUserData } from '../../stores/auth';
import GenericModal from '../../components/generic-modal/GenericModal';

const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const getTabName = (monthIndex: number, year: number): string => {
  return `${monthNames[monthIndex]} ‘${year.toString().substring(2)}`;
};

const getTimesheetsByYear = (timesheets = [], year) => {
  return timesheets?.reduce((acc, item) => {
    if (item?.year === year) {
      acc.push({
        key: item.month + '/' + item?.year,
        tabName: getTabName(item.month, item?.year),
      });
    }

    return acc;
  }, []);
};

const getYears = (timesheets) => {
  return timesheets.map((item) => item?.year);
};

const date = new Date();
const currentDate = {
  month: date.getMonth() + 1,
  year: date.getFullYear(),
};

const addCurrentMonth = (tabs) => {
  const { month, year } = currentDate;

  if (!tabs.some(({ key }) => key === month + '/' + year)) {
    const currentTab = {
      key: month + '/' + year,
      tabName: getTabName(month, year),
    };
    tabs = [currentTab, ...tabs];
  }

  return tabs;
};

const FormSkeleton = () => (
  <Stack spacing={1}>
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

    <Skeleton variant="circular" width={80} height={80} />
    <Skeleton variant="rectangular" width={400} height={60} />
    <Skeleton variant="rounded" width={400} height={60} />
  </Stack>
);

const TableSkeleton = () => (
  <Stack spacing={1} className="skeleton-wrapper">
    <Skeleton variant="rectangular" height={60} />
    <Skeleton variant="rectangular" height={60} />
  </Stack>
);

const Timesheet = () => {
  useDocumentTitle('Timesheet');

  const isMobileView = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const params = useParams<{ jobUid: string; studentUid?: string }>();
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;
  const byCompany = user?.company_uid;

  const {
    data: jobData,
    isFetching,
    error: jobError,
  } = useFetchJobById(params.jobUid);
  const { data: timesheets = [], refetch: refetchTimesheets } = byCompany
    ? useFetchStudentTimesheets(params.jobUid, params.studentUid)
    : useFetchJobTimesheets(params.jobUid, !!user?.student_uid);

  const { data: studentData } = useFetchStudentById(params.studentUid);
  const student: ProfileData = studentData as unknown as ProfileData;

  const [editableGrid, setEditableGrid] = useState(false);
  const [createButtonDisabled, changeCreateButtonDisabled] = useState(false);

  useEffect(() => {
    if (byCompany && params.jobUid && !params.studentUid) {
      return navigate(
        generatePath(Routes.JobDetails, {
          jobUid: params.jobUid,
        }),
      );
    }
  }, [params]);

  const { mutateAsync: createEntry, isLoading: isLoadingCreate } =
    useCreateJobTimeEntry();
  const { mutateAsync: updateEntry, isLoading: isLoadingUpdate } =
    useUpdateJobTimeEntry(params?.jobUid);
  const { mutateAsync: deleteEntry, isLoading: isLoadingDelete } =
    useDeleteJobTimeEntry();
  const { mutateAsync: setReviewJob, isLoading: isLoadingReview } =
    useSetReviewJob();
  const [timesheetUid, setTimesheetUid] = useState(null);

  const [enabledSubmit, enableSubmit] = useState(false);
  const [shownCreateEntry, showCreateEntry] = useState(false);

  const showCreateEntryModal = async (value, isClose) => {
    if (value) {
      const date = new Date(value.date).toLocaleDateString('en-GB');
      const dateArr = date?.split('/');
      const payload: any = {
        job_uid: jobData?.job_uid,
        year: +dateArr[2],
        month: +dateArr[1],
        time_entry: {
          day: +dateArr[0],
          hours: value.hours,
          description: value.description,
        },
      };
      await createEntry(payload);
      await refetchTimesheets();
    }
    showCreateEntry(isClose);
  };

  const isStudent = !!user?.student_uid;

  const [currentMonth, setCurrentMonth] = useState(currentDate.month);
  const [currentYear, setCurrentYear] = useState(currentDate.year);
  const [avatar, setAvatar] = useState(null);
  const [years, setYears] = useState<number[] | null>(null);

  const [monthTabs, setMonthTabs] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTab, setActiveTab] = useState(currentMonth + '/' + currentYear);
  const [ratingValue, setRatingValue] = useState(null);
  const [reviewMonthDialogOpen, toggleReviewMonthDialog] = useState(false);

  useEffect(() => {
    if (!!rows?.length) {
      enableSubmit(
        rows.reduce((acc, row) => {
          acc = Object.values(row).every((value) => !!value);
          return acc;
        }, false),
      );
    } else {
      enableSubmit(false);
    }
  }, [rows]);

  const handleRowsFormat = () => {
    const rowsData = timesheets
      ?.find(
        (timesheet) => timesheet.month + '/' + timesheet.year === activeTab,
      )
      ?.time_entries.filter(
        (timesheet_entry) => !!timesheet_entry.time_entry_uid,
      )
      .map((timesheet_entry) => {
        return {
          id: timesheet_entry.time_entry_uid,
          date: `${timesheet_entry.day}/${activeTab}`,
          hours: timesheet_entry.hours,
          description: timesheet_entry.description,
        };
      });
    setRows(!!rowsData?.length ? rowsData : []);
  };

  useEffect(() => {
    if (!years && timesheets.length) {
      setYears([
        ...new Set([...getYears(timesheets), currentYear]),
      ] as number[]);
    }
  }, [timesheets]);

  const currentTimesheet = () =>
    timesheets.find((timesheet) => timesheet.timesheet_uid === timesheetUid);

  const setSubmittedTimesheet = () => {
    for (let i = 0; i < timesheets.length; i++) {
      const { timesheet_status, timesheet_uid, year, month } = timesheets[i];

      if (timesheet_status === TimesheetStatuses.Submitted) {
        setTimesheetUid(timesheet_uid);
        setActiveTab(`${month}/${year}`);
        return;
      }
    }

    setTimesheetUid(timesheets[0].timesheet_uid);
  };

  useEffect(() => {
    if (timesheets?.length) {
      const timesheet = timesheets?.find(
        (timesheet) => timesheet.month + '/' + timesheet.year === activeTab,
      );

      if (!timesheetUid && timesheet) {
        setTimesheetUid(timesheet.timesheet_uid);
      }

      if (byCompany && !timesheetUid) {
        setSubmittedTimesheet();
      }

      const tabs = getTimesheetsByYear(timesheets, currentYear);

      setMonthTabs(tabs);

      const { reviewed, timesheet_status, month } = timesheet || {};

      if (
        !reviewed &&
        month === currentDate.month &&
        timesheet_status !== TimesheetStatuses.Draft
      ) {
        if (
          (byCompany && timesheet_status !== TimesheetStatuses.Submitted) ||
          !byCompany
        ) {
          if (timesheet_status !== TimesheetStatuses.Closed)
            toggleReviewMonthDialog(true);
        }
      }
    } else {
      setMonthTabs([
        {
          key: currentMonth + '/' + currentYear,
          tabName: getTabName(currentMonth, currentYear),
        },
      ]);
    }

    if (!!timesheets?.length) {
      handleRowsFormat();
    }
  }, [timesheets, timesheetUid]);

  useEffect(() => {
    setEditableGrid(
      (!timesheetUid ||
        currentTimesheet()?.timesheet_status === TimesheetStatuses.Draft) &&
        !byCompany,
    );
  }, [timesheetUid]);

  const handleTabSelect = (event: React.SyntheticEvent, value: string) => {
    const monthYear = value.split('/');
    const selectedTimesheetUid = timesheets.find((item) => {
      return item.month === +monthYear[0] && item.year === +monthYear[1];
    })?.timesheet_uid;

    setCurrentMonth(+monthYear[0]);
    setTimesheetUid(selectedTimesheetUid);
    setActiveTab(value);
    changeCreateButtonDisabled(false);
  };

  useEffect(() => {
    handleRowsFormat();
    setEditableGrid(
      (!timesheetUid ||
        currentTimesheet()?.timesheet_status === TimesheetStatuses.Draft) &&
        !byCompany,
    );
  }, [activeTab]);

  useEffect(() => {
    if (monthTabs.length && !byCompany) {
      setMonthTabs(addCurrentMonth(monthTabs));
    }
  }, [monthTabs]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!!jobData?.company?.avatar) {
      setAvatar(
        process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${jobData?.company?.avatar}`,
      );
    }
  }, [jobData]);

  const { enqueueSnackbar } = useSnackbar();

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  useEffect(() => {
    if (jobError) {
      showError((jobError?.response?.data as any)?.error?.message || 'Error');
    }
  }, [jobError]);

  const navigateToDashboard = () => {
    navigate(Routes.BrowseJobsRoute, { replace: true });
  };

  const navigateToDetails = () => {
    const path = generatePath(Routes.JobDetails, {
      jobUid: jobData?.job_uid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const onSelectedYearChange = (e, value) => {
    setCurrentYear(value);

    const tabs = getTimesheetsByYear(timesheets, value);

    setMonthTabs(tabs);

    const monthYear = tabs[0]?.key.split('/');

    if (monthYear) {
      setCurrentMonth(value);
      setActiveTab(tabs[0]?.key);
    }
  };

  const CustomEditNumberField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = +event.target.value < 0 ? 0 : +event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <TextField
        className="edit-cell-field"
        inputProps={{
          min: 0,
          max: 24,
          step: 0.5,
        }}
        type="number"
        variant="standard"
        value={value}
        error={!value || +value < 0 || +value > 24}
        onChange={handleValueChange}
        onClick={() => setIsOpen(false)}
        id="hours-count"
      />
    );
  };

  const handleDeleteEntry = async (cellParams: GridRenderCellParams) => {
    if (cellParams?.row?.hours > 0) {
      await deleteEntry({
        time_entry_uid: cellParams?.row?.id,
        job_uid: params?.jobUid,
      });
      await refetchTimesheets();
    } else {
      setRows(rows.filter((rows) => rows.id !== cellParams?.row?.id));
    }
    changeCreateButtonDisabled(false);
  };

  const CustomTextField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const maxValue = 160;

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue.length <= maxValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }
    };

    return (
      <TextField
        className="edit-cell-field"
        inputProps={{
          max: maxValue,
        }}
        type="text"
        value={value}
        variant="standard"
        error={!value}
        onChange={handleValueChange}
        onClick={() => setIsOpen(false)}
        data-testid="description"
        id="description"
      />
    );
  };

  const CustomDatepickerField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const dateArr = value?.split('/');
    const selectedDate = value
      ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`)
      : '';
    const [dateValue, setValue] = useState(selectedDate);
    const timesheet = currentTimesheet();
    const apiRef = useGridApiContext();
    const { month, year } = timesheet || {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    const daysInMonth = new Date(year, month, 0).getDate();

    const handleValueChange = (event) => {
      setValue(event);
      setIsOpen(false);
      apiRef.current.setEditCellValue({ id, field, value: event });
    };

    const handleOpen = () => {
      setIsOpen(!isOpen);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={dateValue}
          minDate={new Date(`${year}-${month}-${1}`)}
          maxDate={new Date(`${year}-${month}-${daysInMonth}`)}
          inputFormat="DD/MM/YYYY"
          onChange={handleValueChange}
          open={isOpen}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div className="input-wrapper">
              <TextField
                type="text"
                ref={inputRef}
                value={dateValue}
                inputProps={inputProps}
                variant="standard"
                data-testid="startDate"
                id="startDate"
                error={!value || value === 'Invalid date'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={InputProps}
                onClick={handleOpen}
              />
              <div className="input-block" onClick={handleOpen}></div>
            </div>
          )}
        />
      </LocalizationProvider>
    );
  };

  // Review month
  const reviewMonthDialogTitle = byCompany ? 'month_review' : 'month_completed';

  const reviewMonthDialogText = byCompany
    ? 'another_month_done'
    : 'student_month_done';

  const [submitLowHoursDialogOpen, toggleLowHourDialog] = useState(false);
  const [complimentDialogOpen, toggleComplimentDialogOpen] = useState(false);

  const handleToggleLowHoursDialog = () => {
    toggleLowHourDialog(!submitLowHoursDialogOpen);
  };

  const handleToggleComplimentDialogOpen = () => {
    toggleComplimentDialogOpen(!complimentDialogOpen);
  };

  const handleToggleReviewMonthDialog = () => {
    toggleReviewMonthDialog(!reviewMonthDialogOpen);
  };

  // Submit month

  const [submitMonthDialogOpen, toggleSubmitMonthDialog] = useState(false);
  const { mutateAsync: submitMonth, isLoading: isLoadingSubmitMonth } =
    useSubmitJobTimesheetMonth(params?.jobUid, timesheetUid);

  const totalHoursPerMonth = (timesheet) =>
    timesheet?.time_entries?.reduce((accumulator, timeEntry) => {
      return accumulator + timeEntry.hours;
    }, 0);

  const totalHoursCurrentMoth = () => totalHoursPerMonth(currentTimesheet());

  const totalHoursWorked = () =>
    timesheets?.reduce((accumulator, timesheet) => {
      return accumulator + totalHoursPerMonth(timesheet);
    }, 0);

  const submitMonthDialogText = () => {
    const hours = totalHoursCurrentMoth();

    return `${t('submit_msg_1')} ${hours} ${
      t('submit_msg_2') + monthNames[currentMonth] + t('submit_msg_3')
    }`;
  };

  const handleToggleSubmitMonthDialog = () => {
    toggleSubmitMonthDialog(!submitMonthDialogOpen);
  };

  // Approve month

  const [approveMonthDialogOpen, toggleApproveMonthDialog] = useState(false);
  const { mutateAsync: approveMonth, isLoading: isLoadingApproveMonth } =
    useApproveJobTimesheetMonth(params?.jobUid, timesheetUid);

  const approveMonthDialogText = () => {
    return `${t('are_you_sure1')} ${student?.user?.first_name} ${t(
      'are_you_sure2',
    )} ${monthNames[currentMonth]}. ${t('are_you_sure3')}`;
  };

  const handleApproveMonth = async () => {
    await approveMonth();
    await refetchTimesheets();
    toggleApproveMonthDialog(false);

    if (!currentTimesheet()?.reviewed) {
      toggleReviewMonthDialog(true);
    }
  };

  const handleToggleApproveMonthDialog = () => {
    toggleApproveMonthDialog(!approveMonthDialogOpen);
  };

  // Reject month

  const [rejectedSnackbarOpened, toggleRejectedSnackbar] = useState(false);
  const [rejectMonthDialogOpen, toggleRejectMonthDialog] = useState(false);
  const { mutateAsync: rejectMonth, isLoading: isLoadingRejectMonth } =
    useRejectJobTimesheetMonth(params?.jobUid, timesheetUid);

  const handleRejectMonth = async (reason: string) => {
    await rejectMonth(reason);
    await refetchTimesheets();
    await toggleRejectMonthDialog(false);

    if (!currentTimesheet()?.reviewed) {
      toggleReviewMonthDialog(true);
    }
  };

  const handleToggleRejectMonthDialog = () => {
    toggleRejectMonthDialog(!rejectMonthDialogOpen);
  };

  const handleToggleRejectedSnackbar = () => {
    toggleRejectedSnackbar(!rejectedSnackbarOpened);
  };

  // Reason

  const { mutateAsync: lowHours } = useLowHourReason(
    params?.jobUid,
    timesheetUid,
  );

  const expectedHoursMonth = (
    Number(jobData?.hours_estimated) * 4.34
  )?.toFixed();

  const handleSubmitMonth = async () => {
    await submitMonth();
    await refetchTimesheets();
    setEditableGrid(false);
    toggleSubmitMonthDialog(false);

    if (!currentTimesheet()?.reviewed) {
      toggleReviewMonthDialog(true);
    } else {
      const totalHours = totalHoursCurrentMoth();
      if (totalHours < Number(expectedHoursMonth)) {
        handleToggleLowHoursDialog();
      } else {
        handleToggleComplimentDialogOpen();
      }
    }
  };

  const handleReviewMonth = (review) => {
    setReviewJob({
      description: review,
      rating: ratingValue,
      timesheet_uid: timesheetUid,
    }).then(() => {
      refetchTimesheets();
      toggleReviewMonthDialog(false);
      const totalHours = totalHoursCurrentMoth();
      if (totalHours < Number(expectedHoursMonth)) {
        handleToggleLowHoursDialog();
      } else {
        handleToggleComplimentDialogOpen();
      }
    });
  };

  const handleConfirmLowHours = async (reason) => {
    try {
      await lowHours(reason);
    } catch (error) {
      console.error(error);
    } finally {
      handleToggleLowHoursDialog();
    }
  };

  const handleCloseLowHours = () => {
    handleToggleLowHoursDialog();
    if (!currentTimesheet()?.reviewed) {
      toggleReviewMonthDialog(true);
    }
  };

  const myTimesheetColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      width: 50,
      field: 'edit',
      type: 'actions',
      headerName: '',
      sortable: false,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const apiRef = useGridApiContext();
        const { id } = params;
        const row_id = id as string;
        const [editMode, setEditMode] = useState(row_id.includes('create'));

        const handleEditEntry = () => {
          apiRef.current.startRowEditMode({ id });
          setEditMode(true);
        };

        const handleSaveEntry = () => {
          apiRef.current.stopRowEditMode({ id });
          setEditMode(false);
        };
        return (
          editableGrid && (
            <>
              {editMode ? (
                <SaveOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    mr: 2,
                  }}
                  onClick={() => handleSaveEntry()}
                />
              ) : (
                <EditOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    mr: 2,
                  }}
                  onClick={() => handleEditEntry()}
                />
              )}
            </>
          )
        );
      },
    } as GridActionsColDef,

    {
      field: 'date',
      headerName: isMobileView ? 'Day' : t('date'),
      type: 'date',
      flex: 1,
      editable: editableGrid,
      cellClassName: 'minite-editable-cell',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomDatepickerField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError =
          !params.props.value || params.props.value === 'Invalid Date';
        return { ...params.props, error: hasError };
      },
      valueParser: (value) => {
        return value ? formatDate(value, 'DD/MM/YYYY') : null;
      },
      valueFormatter: (value) => {
        const date = value?.value ? value?.value : '';
        const formatDate = date?.split('/');
        const day =
          formatDate[0]?.length === 1 ? '0' + formatDate[0] : formatDate[0];
        return isMobileView ? day : date;
      },
    },
    {
      field: 'hours',
      headerName: t('hours'),
      editable: editableGrid,
      flex: 1,
      cellClassName: 'minite-editable-cell',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditNumberField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const value = params?.props?.value;
        const hasError = !value || value < 0 || value > 24;
        return { ...params.props, error: hasError };
      },
    },
    {
      minWidth: 110,
      field: 'description',
      headerName: t('desc'),
      flex: 3,
      sortable: false,
      cellClassName: 'minite-editable-cell',
      editable: editableGrid,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomTextField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !params.props.value;
        return { ...params.props, error: hasError };
      },
    },
    {
      width: 50,
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => {
        return (
          editableGrid && (
            <DeleteOutlineOutlinedIcon
              sx={{ color: '#ED6C02', cursor: 'pointer' }}
              onClick={() => handleDeleteEntry(params)}
            />
          )
        );
      },
    } as GridActionsColDef,
  ];

  const TimeEntriesFooter = () => {
    const apiRef = useGridApiContext();
    const handleNewEntryClick = () => {
      if (!isMobileView) {
        const id = 'create-' + Math.random();
        const rowsData = [
          ...rows,
          {
            id,
            date: '',
            description: '',
            hours: 0,
          },
        ];
        setRows(rowsData);
        apiRef.current.startRowEditMode({ id });
      } else {
        showCreateEntry(true);
      }
      changeCreateButtonDisabled(true);
    };

    return (
      <GridFooterContainer>
        {editableGrid && !byCompany && (
          <Button
            disabled={createButtonDisabled}
            className="add-entry-button"
            variant="text"
            onClick={handleNewEntryClick}
            startIcon={<AddIcon />}
            data-testid="create-entry"
          >
            {t('create_entry')}
          </Button>
        )}
      </GridFooterContainer>
    );
  };

  const handleEditStopRow = async (data) => {
    const dateArr = data.date?.split('/');
    if (dateArr) {
      const payload: any = {
        job_uid: jobData?.job_uid,
        year: +dateArr[2],
        month: +dateArr[1],
        time_entry: {
          day: +dateArr[0],
          hours: data.hours,
          description: data.description,
        },
      };

      if (data?.id?.includes('create')) {
        await createEntry(payload);
      } else {
        await updateEntry({
          time_entry_uid: data?.id,
          time_entry: payload.time_entry,
        });
      }

      await refetchTimesheets();
      changeCreateButtonDisabled(false);
    }
  };

  const getPercentageWorked = () => {
    const expected = Number(expectedHoursMonth);
    if (isNaN(expected) || expected === 0) {
      return [0, 0];
    }
    const percentage =
      (totalHoursCurrentMoth() / Number(expectedHoursMonth)) * 100;
    let progressBarValue = percentage;
    if (percentage > 100) {
      progressBarValue = 100;
    }
    return [progressBarValue, percentage];
  };

  if (isFetching) {
    return <FormSkeleton />;
  }

  return (
    <Grid
      container
      direction="row"
      className="timesheet-container"
      alignItems="stretch"
      sx={{
        maxWidth: 'md',
        margin: '0 auto',
      }}
    >
      {!jobData && <NotFound />}
      {!!jobData && (
        <Grid item md={12} xs={12} className="timesheet-content">
          <div className="navigation">
            <Breadcrumbs>
              {!byCompany && (
                <MuiLink onClick={navigateToDashboard}>Jobs</MuiLink>
              )}
              <MuiLink onClick={navigateToDetails}>
                Job: {jobData?.job_title}
              </MuiLink>
              <p>
                {byCompany ? `${student?.user?.first_name}'s ` : ''}
                Timesheet
              </p>
            </Breadcrumbs>
          </div>
          <section className="company-details">
            <div className="job-titles">
              <CompanyAvatar avatar={avatar} className="company-avatar" />
              <div className="job-details">
                <p className="job-title">{jobData?.job_title}</p>
                <p className="job-company-name">
                  {jobData?.company?.company_name}
                </p>
                <p className="job-rate">
                  {jobData?.hours_estimated} {t('per_week')} (
                  {expectedHoursMonth} {t('per_month')})
                </p>
                {years && !!years.length && (
                  <div className="years-container">
                    <span className="info-label">Year</span>
                    <Select
                      id="status-select"
                      variant="standard"
                      onChange={(e) => onSelectedYearChange(e, e.target.value)}
                      value={currentYear}
                    >
                      {years.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </div>
            <div className="cards-wrapper">
              <StatisticCard
                className="total-current-month-hours total-hours"
                number={totalHoursCurrentMoth()}
                text={t('worked_current_month')}
              />
              <StatisticCard
                className="total-current-month-hours total-hours"
                number={totalHoursWorked()}
                text={t('total_hours')}
              />
            </div>
          </section>
          <section className="timesheet-grid-container">
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            >
              <Tabs
                className="month-tabs"
                value={activeTab}
                onChange={handleTabSelect}
                variant="scrollable"
                scrollButtons
              >
                {monthTabs.map((monthTab, index) => {
                  return (
                    <Tab
                      className={
                        monthTab.key === activeTab
                          ? 'month-tab selected'
                          : 'month-tab'
                      }
                      key={monthTab.key}
                      label={monthTabs[index].tabName}
                      value={monthTabs[index].key}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <div className="timesheet-grid-container">
              {isLoadingCreate || isLoadingUpdate || isLoadingDelete ? (
                <TableSkeleton />
              ) : (
                <MiniteGrid
                  columns={myTimesheetColumns}
                  rows={rows}
                  pageSize={rows?.length || 0}
                  pagination
                  rowsPerPageOptions={[rows?.length || 0]}
                  height={handleGridHeight(
                    rows?.length || 0,
                    rows?.length || 0,
                  )}
                  rowId={'id'}
                  editRowEndCallback={handleEditStopRow}
                  isAuto
                  components={{
                    Footer: TimeEntriesFooter,
                  }}
                />
              )}
            </div>
          </section>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ marginRight: '10px' }}>
              <Typography className="progress-bar">{t('progress')}</Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                sx={{
                  backgroundColor: '#aaa',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#deb900',
                  },
                  marginTop: '4px',
                }}
                variant="determinate"
                value={getPercentageWorked()[0]}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography className="progress-bar">
                {`${Math.round(getPercentageWorked()[1])}%`}
              </Typography>
            </Box>
          </Box>
          {timesheetUid && !byCompany && (
            <section className="timesheet-actions">
              <Typography className="total-hours-month">
                {t('total')}: {totalHoursCurrentMoth()} {t('hours')}
              </Typography>
              <SubmitConfirmation
                enabled={editableGrid && enabledSubmit}
                timesheetStatus={currentTimesheet()?.timesheet_status}
                content={submitMonthDialogText()}
                onConfirm={handleSubmitMonth}
                onToggle={handleToggleSubmitMonthDialog}
                open={submitMonthDialogOpen}
                isLoading={isLoadingSubmitMonth}
              />
            </section>
          )}
          {byCompany && (
            <>
              <section className="timesheet-totals">
                <Grid container direction="row" alignItems="stretch" gap={1}>
                  <Grid item xs={5} md={2}>
                    <Typography className="total-hours-month">
                      {t('expected_hours')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    {expectedHoursMonth}
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="stretch" gap={1}>
                  <Grid item xs={5} md={2}>
                    <Typography className="total-hours-month">
                      {t('logged_hours')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    {totalHoursCurrentMoth() || 0}
                  </Grid>
                </Grid>
              </section>
              {timesheetUid && (
                <section className="timesheet-actions">
                  <RejectConfirmation
                    timesheetStatus={currentTimesheet()?.timesheet_status}
                    content={t('reject_month_warning')}
                    onConfirm={handleRejectMonth}
                    onToggle={handleToggleRejectMonthDialog}
                    open={rejectMonthDialogOpen}
                    isLoading={
                      isLoadingRejectMonth ||
                      (isFetching && rejectMonthDialogOpen)
                    }
                  />
                  <ApproveConfirmation
                    timesheetStatus={currentTimesheet()?.timesheet_status}
                    content={approveMonthDialogText()}
                    onConfirm={handleApproveMonth}
                    onToggle={handleToggleApproveMonthDialog}
                    open={approveMonthDialogOpen}
                    isLoading={
                      isLoadingApproveMonth ||
                      (isFetching && approveMonthDialogOpen)
                    }
                  />
                </section>
              )}
            </>
          )}
          <CreateEntryModal
            open={shownCreateEntry}
            handleClose={(value) => showCreateEntryModal(value, false)}
          />
          <LowHoursModal
            open={submitLowHoursDialogOpen && isStudent}
            title="Reason why your logged hours are less than your expected hours:"
            handleClose={handleCloseLowHours}
            handleConfirm={handleConfirmLowHours}
          />
          <GenericModal
            open={complimentDialogOpen && isStudent}
            title="Well done!"
            content="You have logged more hours than expected. Keep up the good work!"
            confirmButtonText="Thanks"
            handleConfirm={handleToggleComplimentDialogOpen}
          />
          <ReviewModal
            ratingValue={ratingValue}
            isOpen={reviewMonthDialogOpen}
            content={[t(reviewMonthDialogText)]}
            withBoldText={
              byCompany ? null : ` ${jobData?.company?.company_name}?`
            }
            handleRatingValue={setRatingValue}
            onClose={handleToggleReviewMonthDialog}
            onSubmit={handleReviewMonth}
            title={t(reviewMonthDialogTitle)}
            isLoading={isLoadingReview}
          />
          <MiniteSnackbar
            severity="info"
            autoHideDuration={null}
            message="The month has been rejected"
            open={rejectedSnackbarOpened}
            onClose={handleToggleRejectedSnackbar}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Timesheet;
