import React from 'react';
import '../Dashboard.scss';

import HighlightedProfiles from '../../../components/highlighted-profiles/HighlightedProfiles';
import { useFetchHighlightedProfiles } from '../../../data/highlighted-profiles';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const HighlightedProfilesDashboard = () => {
  const { data: profiles = [], isLoading } = useFetchHighlightedProfiles(true);
  const { t } = useTranslation();
  return (
    profiles?.length !== 0 && (
      <div className="dashboard-jobs fav-container-small">
        <h3 className="heading-text">{t('dashboard.highlighted_profiles')}</h3>
        {isLoading ? (
          <Skeleton sx={{ height: 250 }} />
        ) : (
          <HighlightedProfiles dashboard pool={profiles} />
        )}
      </div>
    )
  );
};

export default HighlightedProfilesDashboard;
