import React, { ReactElement, useEffect, useState } from 'react';
import { JobTypeSelection } from './JobTypeSelection';
import { SharedStepper } from '../../shared/stepper/Stepper';
import './CreateJob.scss';
import { IdealStudentStep } from './IdealStudentStep';
import { DescribeJobStep } from './DescribeJobStep';
import { ReviewStep } from './ReviewStep';
import { AddDetailsStep } from './AddDetailsStep';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fetchJobForEditRequest,
  getDraftJobRequest,
  JobType,
  JobTypes,
} from '../../data/jobs';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../utils/consts';
import MiniteSnackbar from '../../components/snackbar';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  Alert,
  AlertTitle,
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { UserData } from '../../data/user';
import { useFetchCategories } from '../../data/shared';
import { useManageJobForm } from '../../stores/job-form';
import { useUserData } from '../../stores/auth';
import { Auth } from 'aws-amplify';
import { usePostJobWizardLoading } from './store';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const SkeletonForm = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
    </Stack>
  );
};

const PostAJob = () => {
  useDocumentTitle('Post a Job');
  const [saveMessage, setSaveMessage] = useState(null);
  const [step, setStep] = useState(0);
  const { data: categories = [], refetch: refetchCategories } =
    useFetchCategories();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useUserData((state) => state.user);
  const setInitializing = usePostJobWizardLoading(
    (state) => state.setInitializing,
  );
  const isMobileView = useMediaQuery('(max-width: 700px)');
  const job = usePostJobWizardLoading((state) => state.job);
  const setJob = usePostJobWizardLoading((state) => state.setJob);
  const user: UserData = userData as unknown as UserData;
  const refetchUser = useUserData((state) => state.refetchUser);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const { resetJobFormState } = useManageJobForm();

  const steps = [
    {
      label: t('create_job.stepper.job_type'),
    },
    {
      label: t('create_job.stepper.category_skills'),
    },
    {
      label: t('create_job.stepper.description'),
    },
    {
      label: t('create_job.stepper.budget_location'),
    },
  ];

  const params = useParams();
  const { jobUid } = params;
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const isPaid = path[path.length - 1] === 'paid';

  const titles = [
    { title: t('create_job.step_job_type.title'), desc: '' },
    { title: t('create_job.step_category_skills.title'), desc: '' },
    { title: t('create_job.step_description.title'), desc: '' },
    {
      title: t('create_job.step_budget_location.title'),
    },
    {
      title: !isPaid ? t('create_job.step_review.title') : '',
      desc: '',
    },
  ];

  const handleBack = () => {
    if (step === 0) {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    (async () => {
      // Fetch existing job
      if (jobUid) {
        try {
          setInitializing(true);
          const response = await fetchJobForEditRequest(jobUid);
          setJob(response.job);
        } catch (error) {
          setErrorMessage('Failed to fetch job');
        } finally {
          setInitializing(false);
        }
      } else {
        try {
          setInitializing(true);
          const response = await getDraftJobRequest();
          setJob(response.job);
        } catch (e) {
          setJob(null);
        } finally {
          setInitializing(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (user.company_uid === null) {
      navigate(Routes.Forbidden);
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname === Routes.JobPostRoute) {
      setStep(0);
      setJob(null);
      resetJobFormState();
    }
  }, [location.pathname]);

  const handleNext = () => {
    refetchUser();

    if (step < steps.length) setStep(step + 1);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (user?.email) {
      (async () => {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          // Only check for non google users since google users are not allowed
          // to change emails
          if (!cognitoUser.username.includes('google_')) {
            const userEmail = localStorage.getItem('username');
            if (
              userEmail &&
              user.email.toLowerCase() !== userEmail.toLowerCase()
            ) {
              await Auth.signOut();
            }
          }
        } catch (error) {
          await Auth.signOut();
        }
      })();
    }
  }, [user]);

  const stepProps = {
    handleNext: () => handleNext(),
    handleBack: () => handleBack(),
    job: job,
  };

  const stepComponents = [
    <JobTypeSelection
      {...stepProps}
      serviceFee={userData?.company?.service_fee}
    />,
    <AddDetailsStep
      {...stepProps}
      categories={categories}
      refetchCategories={refetchCategories}
    />,
    <DescribeJobStep {...stepProps} />,
    <IdealStudentStep
      {...stepProps}
      serviceFee={userData?.company?.service_fee}
    />,
    <ReviewStep {...stepProps} serviceFee={userData?.company?.service_fee} />,
  ];

  const InfoMessage = (props: { type: JobType }): ReactElement<any, any> => {
    if (isMobileView) return null;

    if (props.type === JobTypes.ONE_TIME) {
      return (
        <Alert
          severity="info"
          className="posting-fee-info"
          style={{ marginTop: '66%' }}
        >
          <AlertTitle className="info-title">
            {userData?.company?.service_fee}% Posting{' '}
            {t('create_job.step_budget_location.budget.posting_fee_title')}
          </AlertTitle>
          {/* <span className="info-text">
            {t('create_job.step_ideal_student.budget.posting_fee_description')}
          </span> */}
        </Alert>
      );
    } else if (props.type === JobTypes.PREMIUM) {
      return (
        <Alert
          severity="info"
          className="posting-fee-info"
          style={{ marginTop: '350px' }}
        >
          <AlertTitle className="info-title">
            25% Matching{' '}
            {t('create_job.step_budget_location.budget.posting_fee_title')}
          </AlertTitle>
          {/* <span className="info-text">
            {t('create_job.step_ideal_student.budget.message_title')}.{' '}
            {t('create_job.step_ideal_student.budget.posting_fee_description')}
          </span> */}
        </Alert>
      );
    } else if (props.type === JobTypes.ONGOING) {
      return (
        <Alert
          severity="info"
          className="posting-fee-info"
          style={{ marginTop: '88%' }}
        >
          <AlertTitle className="info-title">
            {userData?.company?.service_fee}% Service{' '}
            {t('create_job.step_budget_location.budget.posting_fee_title')}
          </AlertTitle>
          {/* <span className="info-text">
            {t('create_job.step_ideal_student.budget.posting_fee_description')}
          </span> */}
        </Alert>
      );
    }

    return <></>;
  };

  const AiMessage = (): ReactElement<any, any> => {
    if (isMobileView) return null;
    return (
      <Alert
        icon={<AutoAwesomeIcon />}
        severity="info"
        className="form-alert"
        style={{ marginTop: '229px' }}
      >
        <strong style={{ fontFamily: '"Sofia W05 Medium", sans-serif' }}>
          {t('create_job.step_description.description.alert_title')}
        </strong>
        <br />
        <small>
          {t('create_job.step_description.description.alert_footer')}
        </small>
        <br />
      </Alert>
    );
  };

  const comments = [
    null,
    null,
    <AiMessage />,
    <InfoMessage type={job?.job_type || JobTypes.ONGOING} />,
  ];

  useEffect(() => {
    if (isPaid) {
      setStep(stepComponents.length - 1);
    }
  }, [jobUid]);

  return (
    <>
      <Grid
        className="createjob-container"
        container
        direction="row"
        justifyContent="center"
      >
        <SharedStepper steps={steps} activeStep={step} />
        <MiniteSnackbar
          severity={saveMessage?.includes('fail') ? 'error' : 'success'}
          message={saveMessage}
          title={'Success'}
          autoHideDuration={4000}
          open={!!saveMessage}
          onClose={() => setSaveMessage(null)}
        />
        <div className="createjob-content">
          <p className="createjob-content-title">
            <span>{titles[step].title}</span>
            {titles[step].desc && (
              <span className="content-desc">{titles[step].desc}</span>
            )}
          </p>

          <>{stepComponents[step]}</>
        </div>
        <div className="createjob-notes">
          <>{comments[step]}</>
        </div>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default PostAJob;
