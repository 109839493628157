import { useMutation, useQuery } from '@tanstack/react-query';
import { request, ServerResponse } from '../core/request';
import { CHAT, STUDENT, USER } from './query-keys';
import { UserType } from '../components/sidepanel/UserType';
import { StudentType } from './jobs';
import { UpdateUserProfile } from './edit-profile';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';

const fetchCurrentStudent = async () => {
  try {
    const { data } = await request<ServerResponse<User>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/me/student`,
      method: 'get',
    });

    return data?.data || {};
  } catch (error) {
    return 'Something went wrong';
  }
};

export function useFetchCurrentStudent(isStudent: boolean) {
  return useQuery([STUDENT], () => fetchCurrentStudent(), {
    enabled: isStudent,
    staleTime: 3600,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    select: (data) => {
      return data;
    },
    onError: (e) => {
      return e;
    },
  });
}

export enum SignUpType {
  Company = 'companies',
  Student = 'students',
}

const signUp = async (signUpType: SignUpType, data): Promise<AxiosResponse> => {
  if (![SignUpType.Company, SignUpType.Student].includes(signUpType)) {
    throw new Error(`Invalid signup: ${signUpType}`);
  }

  const url = process.env.REACT_APP_API_URL + `/api/v1/${signUpType}/signup`;

  const response = await Auth.currentAuthenticatedUser();
  const config = {
    headers: {
      Authorization: 'Bearer ' + response.signInUserSession.idToken.jwtToken,
    },
  };

  return axios.post(url, data, config);
};

export const signUpStudent = async (data) => signUp(SignUpType.Student, data);

export const signUpCompany = async (data) => signUp(SignUpType.Company, data);

export const fetchCurrentUser = async () => {
  try {
    const { data } = await request<ServerResponse<User>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/me`,
      method: 'get',
    });

    return data?.data || {};
  } catch (error) {
    return { error: error.response.status };
  }
};

export const fetchUserData = async () => {
  const { data } = await request<ServerResponse<UserData>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me`,
    method: 'get',
  });

  return data?.data || {};
};

export const validateUser = async (cognitoUser) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken,
    },
  };

  const url = process.env.REACT_APP_API_URL + `/api/v1/signup/validate`;
  return axios.get(url, config);
};

export const resetPassword = async (email: string) => {
  return axios({
    method: 'post',
    url:
      process.env.REACT_APP_API_URL + `/api/v1/users/password-reset/${email}`,
  });
};

export function useFetchCurrentUser(token: string) {
  return useQuery([USER], () => fetchCurrentUser(), {
    enabled: !!token,
    staleTime: 3600,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    select: (data) => {
      return data;
    },
    onError: () => {
      return {};
    },
  });
}

const fetchOpenedChat = async () => {
  try {
    const { data } = await request<ServerResponse<IOpenedChatsRequest>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/me/chats`,
      method: 'get',
    });

    return data?.data;
  } catch (error) {
    return { error: error.response.status };
  }
};

export function useFetchOpenedChat() {
  return useQuery([CHAT], () => fetchOpenedChat(), {
    select: (data) => {
      return data;
    },
    onError: () => {
      return {};
    },
  });
}

const updateCurrentUser = async (body: UpdateUserPayload) => {
  const { data } = await request<ServerResponse<User>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me`,
    method: 'put',
    data: body,
  });

  return data?.data || {};
};

export function useUpdateCurrentUser() {
  return useMutation((body: UpdateUserPayload) => updateCurrentUser(body));
}

const acceptTermsAndConditions = async () => {
  const { data } = await request<ServerResponse<User>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/users/accept-terms-and-conditions`,
    method: 'post',
  });

  return data?.data || {};
};

export function useAcceptTermsAndConditions() {
  return useMutation(() => acceptTermsAndConditions());
}

export const updateUserProfileByAdmin = async (
  userId: string,
  payload: UpdateUserProfile,
) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/users/${userId}`,
    method: 'put',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data.data;
};

export const useUpdateUserProfileByAdmin = (userId: string) => {
  return useMutation(
    (payload: UpdateUserProfile) => updateUserProfileByAdmin(userId, payload),
    {
      onError: (e) => console.error(e),
    },
  );
};

const removeUser = async (userId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/users/${userId}`,
    method: 'delete',
  });
  return data;
};

export function useRemoveUser() {
  return useMutation((userId: string) => removeUser(userId), {
    onError: (e: AxiosError) => e,
  });
}

const setOnboarding = async () => {
  try {
    const { data } = await request<ServerResponse<User>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/me/onboarding`,
      method: 'POST',
    });

    return data?.data || {};
  } catch (error) {
    return 'Something went wrong';
  }
};

export function useSetOnboarding() {
  return useMutation(() => setOnboarding(), {
    onError: (e) => console.error(e),
  });
}

interface EmailConfirmation {
  code: string;
}

const confirmEmail = async (body: EmailConfirmation) => {
  try {
    const { data } = await request<ServerResponse<unknown>>({
      url: `${process.env.REACT_APP_API_URL}/api/v1/me/confirm-email`,
      method: 'POST',
      data: body,
    });

    return data?.data || {};
  } catch (error) {
    const message =
      error.response.data?.error?.message ||
      error.response.data?.error ||
      error;
    throw new Error(message || 'Something went wrong');
  }
};

export function useConfirmEmail() {
  return useMutation((body: EmailConfirmation) => confirmEmail(body), {
    onError: (e) => console.error(e),
  });
}

export interface CreateAccountLinkResponse {
  url: string | null;
  onboarding_already_completed: boolean;
}

export const getStripeOnboardingLink =
  async (): Promise<CreateAccountLinkResponse> => {
    const { data } = await request<ServerResponse<unknown>>({
      url: `${process.env.REACT_APP_API_URL}/api/v1/users/stripe-onboarding-link`,
      method: 'GET',
    });
    return data?.data as CreateAccountLinkResponse;
  };

export interface IdentityDetails {
  gender: string | null;
  ethnicity: string | null;
  nationality: string | null;
}

export const getIdentityDetails = async (): Promise<IdentityDetails> => {
  const { data } = await request<ServerResponse<unknown>>({
    url: `${process.env.REACT_APP_API_URL}/api/v1/users/identity`,
    method: 'GET',
  });
  return data?.data as IdentityDetails;
};

const setIdentity = async (params: IdentityDetails) => {
  const { gender, ethnicity, nationality } = params;

  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/users/identity`,
    method: 'POST',
    data: { gender, ethnicity, nationality },
  });

  return data;
};

export function useSetIdentity() {
  return useMutation((data: IdentityDetails) => setIdentity(data), {
    onError: (e: AxiosError) => e,
  });
}

const setStartedAssessment = async (
  params: AssessmentParams,
): Promise<AssessmentParams> => {
  const { data } = await request<ServerResponse<AssessmentParams>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/users/assessment`,
    method: 'POST',
    data: params,
  });

  return data?.data || ({} as AssessmentParams);
};

export function useSetStartedAssessment() {
  return useMutation(
    (params: AssessmentParams) => setStartedAssessment(params),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

const updateAssessment = async (params: AssessmentParams) => {
  const { data } = await request<ServerResponse<User>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/users/assessment`,
    method: 'PUT',
    data: params,
  });

  return data?.data || {};
};

export function useUpdateAssessment() {
  return useMutation((params: AssessmentParams) => updateAssessment(params), {
    onError: (e: AxiosError) => e,
  });
}

export const getPersonalityAssessmentResult = async (
  studentUid: string,
): Promise<Array<unknown>> => {
  const { data } = await request<ServerResponse<PersonalityAssessmentResult>>({
    url: `${process.env.REACT_APP_API_URL}/api/v1/assessments/results/${studentUid}/personality-assessment`,
    method: 'GET',
  });
  return data?.data.results;
};

export interface GetStudentAssessmentsResponse {
  assessments: AssessmentParams[];
}

export const getStudentAssessments = async (): Promise<
  Array<AssessmentParams>
> => {
  const { data } = await request<ServerResponse<unknown>>({
    url: `${process.env.REACT_APP_API_URL}/api/v1/users/assessment`,
    method: 'GET',
  });
  return data?.data as AssessmentParams[];
};

export const adminGetStudentAssessments = async (
  studentUid: string,
): Promise<Array<AssessmentParams>> => {
  const { data } = await request<ServerResponse<unknown>>({
    url: `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/admin/${studentUid}/assessments`,
    method: 'GET',
  });
  return data?.data as AssessmentParams[];
};

const generateJobDescription = async (
  jobUid: string,
  params: JobDescriptionDetails,
) => {
  const { data } = await request<ServerResponse<JobDescriptionResponse>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/job-description`,
    method: 'POST',
    data: params,
  });

  return data;
};

export function useGenerateJobDescription(jobUid: string) {
  return useMutation(
    (data: JobDescriptionDetails) => generateJobDescription(jobUid, data),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

// Reevaluation

export interface ReevaluationRequestDto {
  message: string;
}

export interface ReevaluationRequest {
  message: string;
  created_at: string;
}

export interface ReevaluationResponse {
  reevaluation_request: ReevaluationRequest;
}

const requestReevaluation = async (body: ReevaluationRequestDto) => {
  try {
    const { data } = await request<ServerResponse<ReevaluationResponse>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/reevaluate`,
      method: 'post',
      data: body,
    });

    return data?.data?.reevaluation_request || {};
  } catch (error) {
    return 'Something went wrong';
  }
};

export function useRequestReevaluation() {
  return useMutation(
    (body: ReevaluationRequestDto) => requestReevaluation(body),
    {
      onError: (e) => console.error(e),
    },
  );
}

export const getUserType = (user): UserType => {
  return user && !!user?.company?.company_uid
    ? UserType.Company
    : user?.student?.student_uid
    ? UserType.Student
    : user?.manager_uid
    ? UserType.Admin
    : null;
};

const requestSubscribe = async (body: { email: string }) => {
  try {
    const { data } = await request<ServerResponse<unknown>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/users/subscribe`,
      method: 'post',
      data: body,
    });

    return data?.data || {};
  } catch (error) {
    return 'Something went wrong';
  }
};

export function useRequestSubscribe() {
  return useMutation((body: { email: string }) => requestSubscribe(body), {
    onError: (e) => console.error(e),
  });
}

const requestNewSingupCallback = async (body: {
  companyName: string;
  contactName: string;
  phoneNumber: string;
}) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/users/email-admin`,
    method: 'post',
    data: {
      action: 'NewCompanyCallbackRequested',
      variables: body,
    },
  });

  if (!data.success)
    throw new Error('requestCallbackJob failed, code: ' + data.code);
};

export function userRequestNewSingupCallback() {
  return useMutation(
    (body: { companyName: string; contactName: string; phoneNumber: string }) =>
      requestNewSingupCallback(body),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

const setWelcome = async () => {
  try {
    const { data } = await request<ServerResponse<User>>({
      url: process.env.REACT_APP_API_URL + `/api/v1/me/welcome`,
      method: 'POST',
    });

    return data?.data || {};
  } catch (error) {
    return 'Something went wrong';
  }
};

export function useSetWelcome() {
  return useMutation(() => setWelcome(), {
    onError: (e) => console.error(e),
  });
}

export interface User {
  top_talent?: boolean;
  error?: number;
  data: UserData;
  type?: UserType;
  user_uid: string;
  verified: boolean;
  timestamp: Date;
  success: boolean;
  token?: string;
  email_unverified?: null | string;
}

export interface UserData {
  avatar: string;
  error?: number;
  city: string;
  company: UserCompany;
  company_uid: string;
  company_stats: UserCompanyStats;
  student: UserStudent;
  country: string;
  email: string;
  phone: number;
  invoice_email: string;
  first_name: string;
  house_number: string;
  how_did_you_find_minite: string;
  iban: string;
  kvk_number: string;
  last_name: string;
  other_how_did_you_find_minite: string | null;
  postal_code: string;
  street_name: string;
  user_uid: string;
  vat_number: number;
  verified: boolean;
  student_uid: string;
  student_stats: UserStudentStats;
  profile_completion: number;
  email_verified: boolean;
  token: string;
  signature?: string;
  type: number;
  manager: {
    super_admin: boolean;
  };
  manager_uid: string | null;
  onboarding_completed_at: string;
  email_unverified?: null | string;
  ref_code: string;
  internal_chat: {
    email: string;
    user_uid: string;
    signature: string;
  };
  accepted_terms_and_conditions: boolean;
  legacy_id?: string;
  stripe_account_id?: string;
  stripe_onboarding_completed?: boolean;
  stripe_onboarding_completed_at?: string;
  created_at?: string;
  welcome_seen_at?: string;
  is_lgbtq?: boolean;
}

export interface UserStudentStats {
  reevaluation_requested_at?: string;
  allow_destroy: boolean;
  total_hours_worked: number;
  total_jobs_in_progress: number;
  total_ongoing_jobs_in_progress: number;
  total_proposals_created: number;
  total_proposals_submitted: number;
  latest_attention_to_detail_assessment_score?: number;
  number_of_attention_to_detail_assessment_tries?: number;
  attention_to_detail_assessment_last_attempted_at?: string;
  ongoing_jobs_in_progress: {
    job_uid: string;
    company: {
      company_uid: string;
      company_name: string;
    };
  }[];
  total_jobs_completed: number;
  personality_assessment_results?: Array<unknown>;
}

interface IOpenedChatsRequest {
  data: IOpenedChats[];
}

export interface IOpenedChats {
  custom?: {
    proposalId: string;
  };
}
export interface UserCompanyStats {
  allow_destroy: boolean;
  total_active_students: number;
  total_jobs_in_progress: number;
  total_ongoing_jobs_in_progress: number;
  total_signed_proposals: number;
  timesheets_submitted: Array<{
    job_uid: string;
    student_uid: string;
    first_name: string;
    month: number;
  }>;
}

export interface UserCompany {
  company_name: string | null;
  company_uid: string | null;
  rating: number | null;
  bio: string;
  interest_uid: string;
  website: string;
  country: string;
  service_fee: number;
  direct_job_fee: number;
  direct_job_remaining_credits: number;
}

export interface UserStudent {
  student_name: string;
  company_name: string | null;
  hourly_rate: number | null;
  one_liner: string | null;
  profile_summary: string | null;
  rating: number | null;
  student_type: StudentType | null;
  student_uid: string | null;
  kor_approved_at: string;
  top_talent?: boolean;
  highlighted?: boolean;
}

export interface UpdateUser {
  kvk_number?: number;
  how_did_you_find_minite?: string;
  other_how_did_you_find_minite?: string;
  street_name?: string;
  house_number?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  iban?: string;
  vat_number?: number;
  accepted_terms_and_conditions?: boolean;
}

export interface UpdateUserPayload {
  user: UpdateUser;
  company?: UserCompany;
  student?: UserStudent;
}

export interface AssessmentParams {
  student_test_uuid?: string;
  started: boolean;
  started_at?: string;
  finished: boolean;
  finished_at?: string;
  seconds?: number;
  max_points?: number;
  points?: number;
  number_of_attempts?: number;
}

export interface JobDescriptionDetails {
  company_desc: string;
  main_tasks: string;
  desc_language: string;
  job_title: string;
}

export interface JobDescriptionResponse {
  job_description: string;
}

export interface PersonalityAssessmentResult {
  results: Array<unknown>;
}
