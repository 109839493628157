import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import CompanyStudentChat from './CompanyStudentChat';
import './AdminChat.scss';
import ChatByType from './Chat';
import { useUserData } from '../../../stores/auth';
import ChatTabs from './ChatTabs';

import { getFirebaseConfig } from '../../../utils/chat';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';

const firebaseConfig = getFirebaseConfig();
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
signInAnonymously(auth);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AdminChatNew = () => {
  const [value, setValue] = useState(0);
  const user = useUserData((state) => state.user);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid
      sx={{
        maxWidth: '1200px',
        width: '100%',
      }}
      className="admin-chat"
    >
      <p className="section-title">Chats</p>
      <Box sx={{ width: '100%' }}>
        <Box
          className="tabs-wrapper"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <ChatTabs onChange={handleChange} value={value} db={db} />
        </Box>
        <TabPanel value={value} index={0}>
          {user && <ChatByType user={user} type="student" db={db} />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {user && <ChatByType user={user} type="company" db={db} />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CompanyStudentChat user={user} db={db} />
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default AdminChatNew;
