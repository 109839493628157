import React from 'react';
import './ReminderCard.scss';
import { ButtonBase } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

interface ReminderCardProps {
  text: string;
  link?: string;
  title: string;
  callback?: () => void;
  newWindowLink?: string;
}

const ReminderCard = ({
  text,
  link,
  title,
  callback,
  newWindowLink,
}: ReminderCardProps) => {
  const navigate = useNavigate();

  const handleNavigation = (event) => {
    event?.preventDefault();
    if (callback && !link) {
      callback();
    }

    if (link && !callback) {
      navigate(link, { replace: true });
    }

    if (newWindowLink) {
      window.open(newWindowLink, '_blank');
    }
  };

  return (
    <div className="reminder-container-card" onClick={handleNavigation}>
      <div className="lamp-image">
        <img src="/images/lamp-small.png" alt="Reminder Lamp" />
      </div>

      <div className="reminder-card-content">
        <p className="reminder-card-title">{title || 'Reminder'}</p>
        <p className="reminder-card-text">{text}</p>
      </div>

      <div className="reminder-card-link">
        <ButtonBase>
          <ArrowForwardIcon
            sx={{
              color: '#DEB900',
              cursor: 'pointer',
            }}
            className="next-button"
          />
        </ButtonBase>
      </div>
    </div>
  );
};

export default ReminderCard;
