import React, { useEffect, useState } from 'react';
import './TalentCard.scss';
import { Chip, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { MatchedStudent } from './Talents';
import { useParams } from 'react-router-dom';
import { StudentTypes, useRequestProposal } from '../../data/jobs';
import { ProposalRequest } from '../favourite-talents/FavouriteTalents';
import { useSnackbar } from 'notistack';
import { numberWithDec } from '../../utils/numberWithDec';
import StudentAvatar from '../student-avatar';
import TopTalent from '../top-talent';
import { LoadingButton } from '../LoadingButton';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  student: MatchedStudent;
  proposalRequests: Array<ProposalRequest>;
  refetchProposalRequests: () => void;
}

const TalentCard = (props: Props) => {
  const { student, proposalRequests, refetchProposalRequests } = props;
  const [sendProposalLoading, setSendProposalLoading] = useState(false);
  const params = useParams();
  const { jobUid } = params;

  const { mutateAsync: requestProposalJob, error: requestProposalError } =
    useRequestProposal(student.student_uid);

  const { enqueueSnackbar } = useSnackbar();

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  useEffect(() => {
    if (requestProposalError) {
      showError(
        (requestProposalError?.response?.data as any)?.error?.message ||
          'Error',
      );
    }
  }, [requestProposalError]);

  const proposalRequest = proposalRequests?.find(
    (r) => r.job_uid === jobUid && r.student_uid === student.student_uid,
  );

  const handleSendProposal = async (event) => {
    event?.preventDefault();
    setSendProposalLoading(true);

    if (!proposalRequest) {
      await requestProposalJob(jobUid);
    }
    refetchProposalRequests();
    setSendProposalLoading(false);
  };

  const maxProposalRequestsMade = proposalRequests.length >= 30;

  return (
    <div className="talent-card-container">
      <div className="card-header">
        <div className="talent-rate-wrapper">
          <StudentAvatar
            avatar={student.user.avatar}
            alt="user avatar"
            className="talent-avatar"
          />
          {student?.top_talent && (
            <TopTalent
              styles={{
                width: '40px',
                marginRight: '10px',
              }}
            />
          )}
          <div className="talent-rate-container">
            <div className="rate">
              <StarIcon className="talent-rate-star" />
              <span className="rate-number">
                {numberWithDec(student.rating)}
              </span>
            </div>

            {student?.student_type === StudentTypes.EntryLevel && (
              <Chip
                sx={{
                  width: 'auto',
                  height: 24,
                  padding: 0,
                  border: '1.5px solid #1976D2',
                  fontSize: '12px',
                  lineHeight: '20px',
                  backgroundColor: 'transparent',
                  color: '#1976D2',
                  fontFamily: 'Sofia W05 Medium, sans-serif',
                }}
                label={student.student_type}
              />
            )}
          </div>
        </div>
        {student.match_score && (
          <Tooltip
            title="How well the student’s profile fits with the job. This score is based on multiple data points using AI."
            placement="right-start"
          >
            <div className="match-score-container">
              <CircularProgressWithLabel value={student.match_score} />
              <InfoOutlinedIcon fontSize="small" color="disabled" />
            </div>
          </Tooltip>
        )}
      </div>

      <div className="card-content">
        <p className="student-study">
          {student.education[0]?.degree} {student.education[0]?.study}
        </p>
        <p className="language-list">
          <span className="label">Languages: </span>
          <span className="value">
            {student.languages.map((lang) => lang.language_name).join(', ')}
          </span>
        </p>

        <div className="student-skills">
          {student.skills.map((skill) => (
            <Chip
              sx={{
                width: 'auto',
                height: 32,
                backgroundColor: '#F2F9FC',
                color: '#393A42',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '20px',
                fontFamily: 'Sofia W05 Medium, sans-serif',
              }}
              key={skill.skill_uid}
              label={skill.skill_name}
            />
          ))}
        </div>
      </div>
      <div className="card-footer">
        <LoadingButton
          variant="contained"
          isDisabled={!!proposalRequest || maxProposalRequestsMade}
          onClick={handleSendProposal}
          isLoading={sendProposalLoading}
          className="proposal-button"
          confirmButtonTestId="send-proposal"
          buttonText={
            proposalRequest
              ? 'Proposal requested'
              : maxProposalRequestsMade
              ? 'Limit hit'
              : 'Request proposal'
          }
        />
      </div>
    </div>
  );
};

export default TalentCard;
