import React from 'react';

const StepInfo = ({
  highlightText,
  text,
}: {
  highlightText: string;
  text: string;
}) => {
  return (
    <div className="step-description">
      <span className="text-span">{highlightText}😸</span> {text}
    </div>
  );
};

export default StepInfo;
