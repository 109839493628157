import React from 'react';
import { ChangePasswordForm } from '../../components/change-password/ChangePasswordForm';
import useDocumentTitle from '../../utils/useDocumentTitle';

const ChangePassword = () => {
  useDocumentTitle('Change Password');

  return <ChangePasswordForm />;
};

export default ChangePassword;
