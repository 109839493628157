import { ProposalStatus, ProposalStatuses } from '../../../data/jobs';
import { Chip } from '@mui/material';
import React from 'react';
import './ProposalStatusChip.scss';

interface Props {
  proposalStatus: ProposalStatus;
  forStudent?: boolean;
}

const getBgColor = (proposalStatus: ProposalStatus) => {
  switch (proposalStatus) {
    case ProposalStatuses.Pending:
      return '#DEB900';
    case ProposalStatuses.Signed:
      return '#14DB87';
    case ProposalStatuses.Interviewing:
    case ProposalStatuses.Accepted:
      return '#1976D2';
    case ProposalStatuses.Rejected:
    case ProposalStatuses.Retracted:
      return '#EA4335';
    case ProposalStatuses.Draft:
      return 'transparent';
    default:
      return '#4E5B73';
  }
};

const getBorderColor = (proposalStatus: ProposalStatus) => {
  switch (proposalStatus) {
    case ProposalStatuses.Pending:
      return '#DEB900';
    case ProposalStatuses.Signed:
      return '#14DB87';
    case ProposalStatuses.Interviewing:
    case ProposalStatuses.Accepted:
      return '#1976D2';
    case ProposalStatuses.Rejected:
    case ProposalStatuses.Retracted:
      return '#EA4335';
    default:
      return '#4E5B73';
  }
};

const getColor = (proposalStatus: ProposalStatus) => {
  switch (proposalStatus) {
    case ProposalStatuses.Draft:
    case ProposalStatuses.Pending:
    case ProposalStatuses.Signed:
      return '#393A42';
    default:
      return '#FFFFFF';
  }
};

const getVariant = (proposalStatus: ProposalStatus) => {
  switch (proposalStatus) {
    case ProposalStatuses.Draft:
    case ProposalStatuses.Signed:
      return 'outlined';
    default:
      return 'filled';
  }
};

const ProposalStatusChip = ({ proposalStatus, forStudent }: Props) => {
  const fontColor = getColor(proposalStatus);
  const backgroundColor = getBgColor(proposalStatus);
  const borderColor = getBorderColor(proposalStatus);

  const handleChipClick = () => {
    //TODO show proposal drawer when implement
    if (proposalStatus === ProposalStatuses.Pending) {
      console.log('Drawer Shown');
    }
  };

  const label =
    forStudent && proposalStatus === ProposalStatuses.Pending
      ? 'submitted'
      : proposalStatus;

  return (
    <Chip
      onClick={handleChipClick}
      variant={getVariant(proposalStatus)}
      label={label}
      className={`proposal-status-chip ${proposalStatus}`}
      sx={{
        borderColor,
        backgroundColor,
        color: fontColor,
        width: 'auto',
        minWidth: 'auto',
        textTransform: 'capitalize',
      }}
    />
  );
};

export default ProposalStatusChip;
