import { requestUnprotected, ServerResponse } from '../core/request';

// Interfaces

export interface StripePaymentIntentId {
  stripe_payment_intent_id: string;
}

export interface GetPaymentIntentResponse {
  client_secret: string;
  invoice_paid: boolean;
  company_collective_invoice_number: string;
  amount: number;
}

// Functions

export const getStripePaymentIntent = async (
  params: StripePaymentIntentId,
): Promise<GetPaymentIntentResponse> => {
  const { data } = await requestUnprotected<ServerResponse<unknown>>({
    url: `${process.env.REACT_APP_API_URL}/api/v1/users/stripe-payment-intent`,
    method: 'POST',
    data: params,
  });
  return data?.data as GetPaymentIntentResponse;
};
