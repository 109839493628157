import { useTranslation } from 'react-i18next';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const isPasswordMatchRule = (
  password: string,
  rule: string,
): React.ReactNode | '' => {
  return password.match(rule) ? (
    <CheckOutlinedIcon />
  ) : (
    <CloseIcon style={{ color: 'rgb(211, 47, 47)' }} />
  );
};

export const PasswordRequirements = ({
  password,
  confirm_password,
}: {
  password: string;
  confirm_password: string;
}) => {
  const { t } = useTranslation();

  return (
    <span className="password-hint">
      <span className="text">
        {t('sign_up.form_details.8_characters')}{' '}
        {isPasswordMatchRule(password, '[a-zA-Z0-9!@#$%^&*-]{8,}')}
      </span>
      <span className="text">
        {t('sign_up.form_details.1_special_character')}{' '}
        {isPasswordMatchRule(password, '(?=.*[!@#$%^&*-])')}
      </span>
      <span className="text">
        {t('sign_up.form_details.1_capital_letter')}{' '}
        {isPasswordMatchRule(password, '(?=.*[A-Z]{1,})')}
      </span>
      <span className="text">
        1 number {isPasswordMatchRule(password, '(?=.*?[0-9])')}{' '}
      </span>
      <span className="text">
        Passwords must match
        {password?.length > 0 || confirm_password?.length > 0 ? (
          <>
            {confirm_password !== password ? (
              <CloseIcon style={{ color: 'rgb(211, 47, 47)' }} />
            ) : (
              <CheckIcon />
            )}
          </>
        ) : null}
      </span>
    </span>
  );
};
