import React from 'react';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { Grid } from '@mui/material';
import './ReferFriend.scss';
import ReferStudent from './ReferStudent';
import ReferCompany from './ReferCompany';
import { UserType } from '../../components/sidepanel/UserType';
import { useUserData } from '../../stores/auth';

const ReferFriend = () => {
  useDocumentTitle('Refer a Friend');
  const user = useUserData((state) => state.user);

  return (
    <Grid
      container
      className="refer-friend-container"
      sx={{
        maxWidth: 'sm',
        width: '100%',
      }}
    >
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <p className="title">Refer a Friend</p>
        {user.type === UserType.Student ? (
          <ReferStudent ref_code={user.ref_code} />
        ) : (
          <ReferCompany ref_code={user.ref_code} />
        )}
      </Grid>
    </Grid>
  );
};

export default ReferFriend;
