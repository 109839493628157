import React from 'react';
import './JobOverview.scss';
import { AdminJobDescriptionForm } from './admin-job-edit-form/AdminJobDescriptionForm';
import { AdminJobDetailsForm } from './admin-job-edit-form/AdminJobDetailsForm';
import { AdminJobStudentForm } from './admin-job-edit-form/AdminJobStudentForm';
import { useFetchJob } from '../../../../data/admin-job-details';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

const JobEdit = () => {
  const { id: jobId } = useParams<{ id?: string }>();
  const { data: job, refetch } = useFetchJob(jobId);

  return (
    <Grid item lg={10} md={10}>
      <AdminJobDetailsForm job={job} refetch={refetch} />
      <AdminJobStudentForm job={job} refetch={refetch} />
      <AdminJobDescriptionForm job={job} refetch={refetch} />
    </Grid>
  );
};

export default JobEdit;
