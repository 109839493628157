import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import './SkeletonProposalModal.scss';

const SkeletonProposalModal = () => {
  return (
    <Stack spacing={1} className="skeleton-wrapper">
      <Skeleton variant="rectangular" width={'100%'} height={'15%'} />
      <Skeleton variant="rectangular" width={'100%'} height={'35%'} />
      <Skeleton variant="rectangular" width={'100%'} height={'50%'} />
    </Stack>
  );
};

export default SkeletonProposalModal;
