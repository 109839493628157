import React, { useEffect, useState } from 'react';
import './JobCard.scss';
import { ButtonBase } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { JobRecommended } from '../../data/jobs';
import { useNavigate } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { numberWithDec } from '../../utils/numberWithDec';
import CompanyAvatar from '../company-avatar';

interface DashboardJobCardProps {
  verified: boolean;
  routeLink: string;
  job: JobRecommended;
}

const DashboardJobCard = ({
  verified,
  job,
  routeLink,
}: DashboardJobCardProps) => {
  const navigate = useNavigate();
  const [jobAvatar, setAvatar] = useState(null);

  const handleNavigation = () => {
    navigate(routeLink, { replace: true });
  };

  useEffect(() => {
    if (job?.company?.avatar) {
      setAvatar(
        process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${job?.company?.avatar}?` +
          Date.now(),
      );
    }
  }, [job]);

  return (
    <div className="dashboard-job-card-container">
      <div className="dashboard-job-card-avatar">
        {verified && <CompanyAvatar avatar={jobAvatar} alt="Job Avatar" />}
      </div>

      <div className="dashboard-job-card-text">
        <p className="job-title">{job?.job_title}</p>
        <div className="dashboard-job-card-content">
          <div className="card-rating-name-container">
            <div className="rating">
              <StarBorderIcon className="favourite-rate-star" />
              <p className="rating-number">
                {numberWithDec(job?.company?.rating)}
              </p>
            </div>

            <p className="company-name">
              {verified
                ? job?.company?.company_name
                : 'Get verified to unlock details'}
            </p>
          </div>

          <ButtonBase onClick={handleNavigation}>
            <ArrowForwardIcon
              sx={{
                color: '#DEB900',
                cursor: 'pointer',
                float: 'right',
              }}
              className="next-button"
              data-testid="open-job-details"
            />
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default DashboardJobCard;
