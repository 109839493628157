import React from 'react';
import { FormGroup, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const DatepickerFilter = ({
  control,
  name,
  min,
  max,
  calendarSetup,
}: {
  control: any;
  name: string[];
  min: string;
  max: string;
  calendarSetup?: { isMinYear: string; isMaxYear: string };
}) => {
  const minDate = calendarSetup ? new Date(`${calendarSetup.isMinYear}`) : null;
  const maxDate = calendarSetup ? new Date(`${calendarSetup.isMaxYear}`) : null;

  return (
    <FormGroup className="form-group datepicker-wrapper" row={true}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          defaultValue={min}
          render={({ field, field: { value, onChange } }) => (
            <DesktopDatePicker
              {...field}
              value={value}
              onChange={(value) => onChange(value)}
              disableMaskedInput={true}
              label={'{Min}'}
              inputFormat="MMMM YYYY"
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
          control={control}
          name={name[0]}
        />
        <span className="separator">To</span>

        <Controller
          defaultValue={max}
          render={({ field, field: { value, onChange } }) => (
            <DesktopDatePicker
              {...field}
              value={value || null}
              onChange={(value) => onChange(value)}
              disableMaskedInput={true}
              label={'{Max}'}
              inputFormat="MMMM YYYY"
              minDate={minDate}
              maxDate={maxDate}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          )}
          control={control}
          name={name[1]}
        />
      </LocalizationProvider>
    </FormGroup>
  );
};

export default DatepickerFilter;
