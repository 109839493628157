import React, { useState } from 'react';
import { Divider, IconButton, TextField, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { socials } from '../../utils/consts';
import './SubFooter.scss';
import { isValidEmail } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useRequestSubscribe } from '../../data/user';

export const SubFooter = () => {
  const { t } = useTranslation();
  const { mutateAsync: requestSubscribe } = useRequestSubscribe();
  const [state, setState] = useState({
    email: '',
    error: '',
    disabled: true,
    submitted: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidEmail(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        disabled: true,
        email: e.target.value,
        error: t('footer.valid_email'),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        disabled: false,
        error: '',
        email: e.target.value,
      }));
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  function subscribe() {
    requestSubscribe({ email: state.email }).then((res) => {
      setState((prevState) => ({
        ...prevState,
        email: '',
      }));

      if (res) {
        enqueueSnackbar(t('footer.subscribed'), {
          variant: 'success',
        });
      }
    });
  }

  const forStudentsElement = (
    <section className="routes-container" id="routes-container-for-students">
      <div>
        <span className="section-title">{t('footer.for_students')}</span>
      </div>

      <ul>
        <li>
          <a href="https://www.minite.works/students" target="_blank">
            {t('footer.how_it_works')}
          </a>
        </li>
        <li>
          <a href="https://www.minite.works/kb-highflyers" target="_blank">
            {t('footer.faq')}
          </a>
        </li>

        <li>
          <a
            href="https://www.minite.works/terms-of-use/terms-of-use-students"
            target="_blank"
          >
            {t('footer.terms_of_use')}
          </a>
        </li>
      </ul>
    </section>
  );

  const forCompaniesElement = (
    <section className="routes-container">
      <div>
        <span className="section-title">{t('footer.for_companies')}</span>
      </div>

      <ul>
        <li>
          <a href="https://www.minite.works/" target="_blank">
            {t('footer.how_it_works')}
          </a>
        </li>
        <li>
          <a href="https://calendly.com/hellominite/demo" target="_blank">
            {t('footer.free_demo')}
          </a>
        </li>
        <li>
          <a href="https://www.minite.works/kb-companies" target="_blank">
            {t('footer.faq')}
          </a>
        </li>

        <li>
          <a
            href="https://www.minite.works/terms-of-use/terms-of-use-companies"
            target="_blank"
          >
            {t('footer.terms_of_use')}
          </a>
        </li>
      </ul>
    </section>
  );

  const matches = useMediaQuery('(min-width:906px)');

  return (
    <footer className="sub-footer">
      <div className="sub-footer-container">
        {!matches && (
          <div className="for-routes-container">
            {forStudentsElement}
            {forCompaniesElement}
          </div>
        )}

        {matches && forStudentsElement}
        {matches && forCompaniesElement}

        <section className="routes-container">
          <div>
            <span className="section-title">Minite</span>
          </div>

          <div className="routes-minite-list">
            <ul className="routes-minite-list-1">
              <li>
                <a href="https://www.minite.works/about" target="_blank">
                  {t('footer.about')}
                </a>
              </li>
              <li>
                <a href="https://www.minite.works/blog" target="_blank">
                  {t('footer.blog')}
                </a>
              </li>
              <li>
                <a href="https://www.minite.works/press" target="_blank">
                  {t('footer.press')}
                </a>
              </li>
            </ul>
            <ul
              className="routes-minite-list-2"
              style={!matches ? { paddingLeft: '11%' } : { marginTop: '12px' }}
            >
              <li>
                <a href="https://www.minite.works/careers" target="_blank">
                  {t('footer.careers')}
                </a>
              </li>
              <li>
                <a href="https://www.minite.works/contact" target="_blank">
                  {t('footer.contact')}
                </a>
              </li>
            </ul>
          </div>
        </section>

        <Divider className="sub-footer-divider" sx={{ color: '#393A42' }} />

        <section className="newsletter-container">
          <div>
            <span className="section-title">{t('footer.keep_in_loop')}</span>
          </div>

          <p className="newsletter-text">{t('footer.stay_updated')}</p>

          <div className="newsletter-textfield-container">
            <TextField
              label={t('footer.subscribe')}
              className="subscribe-input"
              variant="standard"
              error={!!state.error}
              helperText={state.error}
              value={state.email}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="subscribe"
                    onClick={subscribe}
                    disabled={state.disabled}
                    sx={{ padding: '0' }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                ),
              }}
              onChange={handleChange}
              sx={{ width: '100%' }}
            />
          </div>
        </section>

        <section className="connect-with-us">
          <div>
            <span className="section-title" id="section-title-connect">
              {t('footer.connect_with_us')}
            </span>

            <div className="social-medias">
              {socials.map((item) => (
                <a href={item.url} key={item.url}>
                  <div className="social-media" key={item.alt}>
                    <img src={item.src} alt={item.alt} />
                  </div>
                </a>
              ))}
            </div>

            <span className="social-email">hello@minite.works</span>
          </div>
        </section>
      </div>

      <div className="cat-icon-container">
        <a
          href="https://www.linkedin.com/in/tofu-chen-a28495239/"
          target="_blank"
        >
          <img src="/images/cat.png" alt="Kitty" />
        </a>
      </div>
    </footer>
  );
};
