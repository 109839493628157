import { useEffect } from 'react';
import type { Blocker, Transition } from 'history';
import history from './history';

export function useBlocker(blocker: Blocker, when = true): void {
  useEffect(() => {
    if (!when) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return;
    }

    const unblock = history.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [blocker, when]);
}
