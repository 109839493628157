import React from 'react';
import * as yup from 'yup';
import { Button, TextField } from '@mui/material';
import GenericModal from '../../../components/generic-modal/GenericModal';
import { TimesheetStatus, TimesheetStatuses } from '../../../data/timesheets';
import TimesheetStatusChip from './TimesheetStatusChip';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  content: string;
  timesheetStatus: TimesheetStatus | string;
  onToggle: () => void;
  onConfirm: (reason: string) => void;
  isLoading?: boolean;
}

export interface RejectMonthForm {
  rejectReason: string;
}

export const initialState = {
  rejectReason: '',
};

const schema = yup
  .object()
  .shape({
    rejectReason: yup.string().required(),
  })
  .required();

const RejectConfirmation = ({
  open,
  content,
  timesheetStatus,
  onToggle,
  onConfirm,
  isLoading,
}: Props): JSX.Element => {
  const {
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<RejectMonthForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  const onClose = () => {
    reset(initialState);
    onToggle();
  };

  const onSubmit = () => {
    const formValues = getValues();
    onConfirm(formValues.rejectReason);
    onToggle();
  };
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });

  return (
    <>
      {timesheetStatus === TimesheetStatuses.Submitted && (
        <Button
          className="reject-month-button"
          variant="contained"
          data-testid="reject-month"
          sx={{
            color: '#ffffff',
            backgroundColor: '#D32F2F',
          }}
          onClick={onToggle}
        >
          {t('reject_month')}
        </Button>
      )}

      {(timesheetStatus === TimesheetStatuses.Approved ||
        timesheetStatus === TimesheetStatuses.Rejected) && (
        <TimesheetStatusChip timesheetStatus={timesheetStatus} />
      )}

      <GenericModal
        open={open}
        title={t('reject_month')}
        mode="confirm"
        content={content}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        confirmButtonDisabled={!isValid}
        handleCancel={onClose}
        handleConfirm={onSubmit}
        isLoading={isLoading}
        loadingButton
        confirmButtonTestId="confirm-reject-month"
      >
        <form className="reject-reason-form">
          <TextField
            {...register('rejectReason')}
            type="text"
            id="reject-reason"
            fullWidth
            variant="standard"
            maxRows={3}
            multiline
            error={!!errors.rejectReason}
            helperText="required"
            placeholder={t('tell_us_why')}
            InputLabelProps={{ hidden: true }}
            data-testid="reject-reason"
          />
        </form>
      </GenericModal>
    </>
  );
};

export default RejectConfirmation;
