import React from 'react';
import { Box, Typography } from '@mui/material';
import './NotFound.scss';
import useDocumentTitle from '../../utils/useDocumentTitle';
import * as Sentry from '@sentry/react';

const NotFound = () => {
  useDocumentTitle('Page Not Found');

  Sentry.captureMessage('Page not found');

  return (
    <Box className="not-found-container">
      <Typography variant="h1">Error 404.</Typography>
      <Typography variant="h6">Oops, we couldn’t find that page!</Typography>
    </Box>
  );
};

export default NotFound;
