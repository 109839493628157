import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './ProposalStudentView.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import {
  JobData,
  JobStatuses,
  JobTypes,
  StudentTypes,
  useAddToFavorites,
  useFetchFavorites,
  useRemoveFromFavorites,
} from '../../../data/jobs';
import { ProposalStatuses } from '../index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { UserData } from '../../../data/user';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import {
  ProposalResponse,
  useFetchProposalById,
  useRetractProposal,
} from '../../../data/proposals';
import ProposalConfirmationModal from './ProposalConfirmationModal';
import ProposalStatusChip from './ProposalStatusChip';
import { useTranslation } from 'react-i18next';
import CompanyAvatar from '../../../components/company-avatar';
import { openChatUrl } from '../../../utils/openChat';
import TopTalent from '../../../components/top-talent';
import { LoadingButton } from '../../../components/LoadingButton';
import MiniteSnackbar from '../../../components/snackbar';

interface Props {
  job: JobData;
  user?: UserData;
  proposal: ProposalResponse;
  handleDrawerClose?: (event) => void;
}

const ProposalStudentView = ({
  job,
  proposal,
  handleDrawerClose,
  user,
}: Props) => {
  const [isRetractDialogOpen, setIsRetractDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [jobAvatar, setAvatar] = useState(null);

  useEffect(() => {
    if (job?.company?.avatar) {
      setAvatar(
        process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${job?.company?.avatar}?`,
      );
    }
  }, [job]);
  const { data: favorites, refetch: refetchFavorites } = useFetchFavorites();
  const { mutateAsync: addToFavourites } = useAddToFavorites();
  const { mutateAsync: removeFromFavourites } = useRemoveFromFavorites();
  const { mutateAsync: retractProposal } = useRetractProposal();
  const { data: proposalData, refetch: refetchProposal } = useFetchProposalById(
    proposal?.proposal_uid,
  );
  proposal = proposalData?.proposal;

  const [errorMessage, setErrorMessage] = useState(null);

  const favorite = favorites?.find((fav) => fav.job_uid === job?.job_uid);
  const [favoritesLoading, setFavoritesLoading] = useState(false);

  const handleFavouritesClick = async (event) => {
    event?.preventDefault();
    try {
      setFavoritesLoading(true);
      if (favorite) {
        await removeFromFavourites(favorite.favorite_uid);
      } else if (job) {
        await addToFavourites(job.job_uid);
      }
      refetchFavorites();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    } finally {
      setFavoritesLoading(false);
    }
  };

  const handleRetractDialogOpen = () => setIsRetractDialogOpen(true);

  const [retractJobIsLoading, setRetractJobIsLoading] = useState(false);

  const handleRetractJob = async () => {
    retractProposal(proposal?.proposal_uid).then(() => {
      refetchProposal();
      setIsRetractDialogOpen(false);
    });

    try {
      setRetractJobIsLoading(true);
      await retractProposal(proposal?.proposal_uid);
      await refetchProposal();
      setIsRetractDialogOpen(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    } finally {
      setRetractJobIsLoading(false);
    }
  };

  const handleChatNavigate = () => {
    openChatUrl(proposal?.proposal_uid);
  };

  const handleAgreementNavigate = () => {
    navigate(Routes.AgreementRoute + proposal?.proposal_uid);
  };

  // const handleSignAgreement = () => {
  //   // signProposal(proposal.proposal_uid).then(() => {
  //   //   navigate(Routes.AgreementRoute + proposal?.proposal_uid);
  //   // });
  //
  //   handleAgreementNavigate();
  // };

  const handleTimesheetNavigate = () => {
    const path = generatePath(Routes.TimesheetRoute, {
      jobUid: job?.job_uid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <section className="proposal-student-container">
        <div className="proposal-student-header">
          <div className="close-icon-container">
            <CloseOutlinedIcon
              className="close-drawer-icon"
              onClick={handleDrawerClose}
            />
          </div>

          <div className="title-container">
            <p className="title">Proposal</p>
          </div>
        </div>
        <div className="proposal-student-content">
          <div className="job-header">
            <div className="job-titles">
              <div className="job-company-name-container">
                <CompanyAvatar
                  avatar={jobAvatar}
                  className="job-avatar-image"
                  alt="Job Picture"
                />

                <div className="titles-container">
                  <p className="job-title">{job?.job_title}</p>
                  <p className="company-title">{job?.company.company_name}</p>
                </div>
              </div>

              {job?.student_type === StudentTypes.EntryLevel && (
                <Chip
                  sx={{
                    width: 'auto',
                    height: '24px',
                    border: '1.5px solid #1976D2',
                    backgroundColor: 'transparent',
                    color: '#1976D2',
                    fontFamily: "'Sofia W05 Medium', sans-serif",
                  }}
                  label="Entry-level"
                  className="student-level-chip"
                />
              )}
            </div>

            <div className="job-header-buttons">
              <LoadingButton
                startIcon={
                  favorite ? (
                    <BookmarkRemoveOutlinedIcon />
                  ) : (
                    <BookmarkAddOutlinedIcon />
                  )
                }
                variant="text"
                onClick={handleFavouritesClick}
                isLoading={favoritesLoading}
                className="medium-text"
                confirmButtonTestId={`${favorite ? 'remove' : 'add'}-favorite`}
                buttonText={
                  favorite
                    ? t('job_details.remove_favourite')
                    : t('job_details.add_to_fav')
                }
              />
              <Button
                className="button medium-text"
                data-testid="view-job"
                onClick={handleDrawerClose}
              >
                {t('browse_jobs.view_job')}
              </Button>
            </div>
          </div>
          <div className="proposal-info-container">
            <div className="proposal-info">
              <p className="title">Proposal</p>
              {user?.student?.top_talent && (
                <TopTalent
                  styles={{
                    width: '40px',
                    position: 'relative',
                    top: '-20px',
                  }}
                />
              )}
            </div>

            <div className="info-rows">
              <p className="info-row">
                <span className="label">{t('dashboard.company')}</span>
                <span className="value">{job?.company.company_name}</span>
              </p>

              <p className="info-row">
                <span className="label">Status</span>

                <p className="value">
                  <ProposalStatusChip
                    proposalStatus={proposal?.proposal_status}
                  />
                </p>
              </p>

              {+job?.budget < +proposal?.rate && (
                <>
                  <p className="info-row">
                    <span className="label">Counteroffer</span>
                    <span className="value">
                      €
                      {job?.job_type === JobTypes.ONGOING
                        ? `${proposal?.rate}/hr`
                        : proposal?.rate}
                    </span>
                  </p>

                  <p className="info-row">
                    <span className="label">Why I’m worth it</span>
                    <span className="value">
                      {proposal?.counter_offer_description}
                    </span>
                  </p>
                </>
              )}

              {job.job_type === JobTypes.ONGOING && (
                <p className="info-row">
                  <span className="label">
                    {t('proposal.min_availability')}
                  </span>
                  <span className="value">{proposal?.min_month}</span>
                </p>
              )}
            </div>

            <div className="accordions-container">
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    backgroundColor: 'transparent',
                  },
                  backgroundColor: 'transparent',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="question-accordion"
                >
                  <Typography className="proposal-question">
                    {job?.question_required}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="proposal-answer">
                    {proposal?.how_you_do_x}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {job?.question_optional && (
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    '&:before': {
                      backgroundColor: 'transparent',
                    },
                    backgroundColor: 'transparent',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="question-accordion"
                  >
                    <Typography className="proposal-question">
                      {job?.question_optional}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="proposal-answer">
                      {proposal?.how_you_do_y}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>

            {proposal?.proposal_status === ProposalStatuses.SIGNED && (
              <div className="proposal-action-container">
                <p className="title">{t('dashboard.actions')}</p>

                <div className="action-list">
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ alignItems: 'flex-start' }}
                  >
                    {!proposal?.legacy_id && (
                      <IconButton
                        aria-label="agreement"
                        sx={{ borderRadius: 0 }}
                        onClick={handleAgreementNavigate}
                        data-testid="view-agreement"
                      >
                        <ReceiptIcon />
                        <span className="action-label">
                          {t('proposal.view_agreement')}
                        </span>
                      </IconButton>
                    )}
                    {proposal?.proposal_status === ProposalStatuses.SIGNED &&
                      job?.job_type === JobTypes.ONGOING &&
                      job?.job_status === JobStatuses.IN_PROGRESS && (
                        <IconButton
                          onClick={handleTimesheetNavigate}
                          aria-label="log"
                          color="primary"
                          sx={{ borderRadius: 0 }}
                          data-testid="open-timesheet"
                        >
                          <QueryBuilderOutlinedIcon />
                          <span className="action-label">
                            {t('proposal.log_hours')}
                          </span>
                        </IconButton>
                      )}
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="proposal-student-footer">
          <div className="drawer-buttons">
            {(proposal?.proposal_status === ProposalStatuses.ACCEPTED ||
              proposal?.proposal_status === ProposalStatuses.INTERVIEWING ||
              proposal?.proposal_status === ProposalStatuses.SIGNED) &&
              (job?.job_status === JobStatuses.IN_PROGRESS ||
                job?.job_status === JobStatuses.SIGNED ||
                job?.job_status === JobStatuses.INTERVIEWING) && (
                <Button
                  className="button chat-button"
                  data-testid="open-chat"
                  onClick={handleChatNavigate}
                >
                  Chat
                </Button>
              )}

            <div className="proposal-actions">
              {(proposal?.proposal_status === ProposalStatuses.PENDING ||
                proposal?.proposal_status === ProposalStatuses.DRAFT ||
                proposal?.proposal_status === ProposalStatuses.RETRACTED) && (
                <Button
                  className="button proposal-action retract"
                  onClick={handleRetractDialogOpen}
                  data-testid="retract-proposal"
                  disabled={
                    proposal?.proposal_status === ProposalStatuses.RETRACTED
                  }
                >
                  Retract proposal
                </Button>
              )}

              {proposal?.proposal_status === ProposalStatuses.ACCEPTED &&
                proposal?.signed_by_company_at && (
                  <Button
                    className={`button proposal-action sign`}
                    onClick={handleAgreementNavigate}
                    data-testid="sign-agreement"
                  >
                    {t('proposal.sign_agreement')}
                  </Button>
                )}
            </div>
          </div>
        </div>
      </section>

      <ProposalConfirmationModal
        action="retract"
        open={isRetractDialogOpen}
        isLoading={retractJobIsLoading}
        handleClose={() => setIsRetractDialogOpen(false)}
        handleProposalAction={handleRetractJob}
      />

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default ProposalStudentView;
