import React, { useState } from 'react';
import './AccountForms.scss';
import { Button, Grid } from '@mui/material';
import GenericModal from '../../../../components/generic-modal/GenericModal';
import { useTranslation } from 'react-i18next';
import { useDeactivateAccount } from '../../../../data/edit-profile';
import { Auth } from 'aws-amplify';

const DeleteAccount = () => {
  const [infoModalShown, showInfoModal] = useState(false);
  const { mutateAsync: deactivateAccount, isLoading } = useDeactivateAccount();

  const handleInfoModalToggle = () => {
    showInfoModal(!infoModalShown);
  };

  const handleDeactivateModal = () => {
    deactivateAccount().catch(() => {
      Auth.signOut();
    });
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const modalText = 'You’re about to deactivate your account';

  return (
    <section className="account-form-container account-financial-forms">
      <Grid container className="account-form-grid">
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles delete-account-title">
            Deactivate account
          </p>
        </Grid>

        <Grid item xs={12} md={10}>
          <div className="delete-button-container">
            <p className="delete-account-caution">{t('delete_caution')}</p>

            <Button
              variant="contained"
              className="delete-button"
              onClick={handleInfoModalToggle}
              data-testid="deactivate-my-account"
            >
              deactivate my account
            </Button>
          </div>
        </Grid>
      </Grid>

      <GenericModal
        open={infoModalShown}
        title="Are you sure?"
        mode="warning"
        content={modalText}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={handleInfoModalToggle}
        handleConfirm={handleDeactivateModal}
        isLoading={isLoading}
        loadingButton={true}
        confirmButtonTestId="confirm-deactivate-my-account"
      />
    </section>
  );
};

export default DeleteAccount;
