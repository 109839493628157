import React from 'react';
import './StatisticCard.scss';

interface StatisticCardProps {
  number: number | string;
  text: string;
  className?: string;
}

const StatisticCard = ({
  number = 0,
  text = '',
  className = '',
}: StatisticCardProps) => {
  return (
    <div className={`statistic-card-container ${className}`}>
      <p className="statistic-card-number">{number}</p>
      <p className="statistic-card-text">{text}</p>
    </div>
  );
};

export default StatisticCard;
