export const COMPANIES_QUERY_KEY = 'companies';
export const COMPANY_QUERY_KEY = 'company';
export const CREATE_JOB_KEY = 'create-job';
export const CATEGORIES = 'categories';
export const LANGUAGES = 'languages';
export const SKILLS = 'skills';
export const TALENT_POOL = 'talents';
export const HIGHLIGHTS = 'highlights';
export const MATCHES = 'matches';
export const ANNOUNCEMENTS = 'announcements';
export const USER = 'user';
export const CHAT = 'chat';
export const STUDENT = 'student';
export const STUDENT_JOBS = 'student-jobs';
export const MY_JOBS = 'my-jobs';
export const JOBS_RECOMMENDED = 'jobs-recommended';
export const MY_RECOMMENDATIONS = 'my-recommendations';
export const JOB = 'job';
export const PROPOSALS = 'proposals';
export const PROPOSAL_REQUESTS = 'proposal_requests';
export const HIRED = 'hired';
export const STUDENT_PROPOSALS = 'student-proposals';
export const STUDENT_FAVOURITES = 'student-favourites';
export const JOB_TIMESHEETS = 'job-timesheets';
export const JOB_STUDENT_TIMESHEETS = 'job-student-timesheets';
export const INVOICES = 'invoices';
export const REVIEWS = 'reviews';

// admin panel
export const ADMIN_JOBS = 'admin-jobs';
export const ADMIN_STATISTICS = 'admin-statistics';
export const ADMIN_COMPANIES = 'admin-companies';
export const ADMIN_COMPANY_OVERVIEW = 'admin-company-overview';
export const ADMIN_COMPANY_OVERVIEW_JOBS = 'admin-company-overview-jobs';
export const ADMIN_COMPANY_OVERVIEW_PROPOSALS =
  'admin-company-overview-proposals';
export const ADMIN_COMPANY_OVERVIEW_STUDENTS =
  'admin-company-overview-students';
export const ADMIN_STUDENTS = 'admin-students';
export const ADMIN_INVOICES = 'admin-invoices';
export const ADMIN_USERS = 'admin-users';
export const ADMIN_ANNOUNCEMENTS = 'admin-announcements';
export const ADMIN_TIMESHEETS = 'admin-timesheets';
export const ADMIN_TIMESHEET_STATISTICS = 'timesheet_statistics';
export const ADMIN_ONGOING_REVENUE = 'admin-ongoing-revenue';
export const ADMIN_ONETIME_REVENUE = 'admin-onetime-revenue';
export const ADMIN_REVENUE_STATISTICS = 'admin-revenue-statistics';
export const ADMIN_EVENT_LOGS = 'admin-event-logs';
export const ADMIN_NO_VAT = 'admin-no-vat';

// edit profile pages
export const INDUSTRIES = 'industries';
export const JOB_INTERESTS = 'job-interests';
export const UNIVERSITIES = 'universities';
export const JOB_STATISTICS = 'job_statistics';
export const COMPANY_STATISTICS = 'company_statistics';
export const STUDENTS_STATISTICS = 'students_statistics';
export const STUDENT_ASSESSMENTS = 'student_assessments';
export const ADMIN_REVIEWS = 'admin_reviews';
export const REVIEWS_STATISTICS = 'reviews_statistics';
export const STUDENT_DETAIL = 'admin-student-details';
export const JOB_STUDENTS_DETAILS = 'admin-job-students-details';
export const JOB_PROPOSALS_DETAILS = 'admin-job-proposals-details';
export const JOB_DETAILS = 'admin-job-details';
export const INVOICE_DETAILS = 'admin-invoice-details';
export const TIMESHEET_DETAILS = 'admin-timesheet-details';
export const TIMESHEET_INVOICES = 'admin-timesheet-invoices';
export const ADMIN_DASHBOARD_PROPOSAL = 'admin-dashboard-proposal';

// assessments
export const ASSESSMENT_CATEGORIES = 'assessment-categories';
export const ASSESSMENT_QUESTIONS = 'assessment-questions';
export const ASSESSMENT_QUESTION_OVERVIEW = 'assessment-question-overview';
