import React from 'react';
import { Box, Typography } from '@mui/material';
import './Forbidden.scss';
import useDocumentTitle from '../../utils/useDocumentTitle';

const Forbidden = () => {
  useDocumentTitle('Forbidden 403');

  return (
    <Box className="forbidden-container">
      <Typography variant="h1">Forbidden 403.</Typography>
      <Typography variant="h6">
        Oops, this page isn't meant for you 😉!
      </Typography>
    </Box>
  );
};

export default Forbidden;
