import React, { useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import './CompanyOverview.scss';
import CompanyDetails from './company-details';
import CompanyEdit from './company-edit';
import { useAdminCompanyOverview } from '../../../../data/admin-companies';
import { useNavigate, useParams } from 'react-router-dom';
import DeactivateAccount from '../../../../components/deactivate-account';
import {
  UserData,
  useRemoveUser,
  useUpdateUserProfileByAdmin,
} from '../../../../data/user';
import ResetPassword from '../../../../components/reset-password-admin';
import DeleteUser from '../../../../components/delete-user';
import AdminAccess from './admin-access';
import { Routes } from '../../../../utils/consts';
import MiniteSnackbar from '../../../../components/snackbar';
import { useUserData } from '../../../../stores/auth';

const TabsValues = {
  View: 'Overview',
  Edit: 'Edit',
};

function a11yProps(index: number) {
  return {
    className: 'overview-page-tab',
    id: `company-tab-${index}`,
    'aria-controls': `company-tabpanel-${index}`,
  };
}

const AdminCompanyOverview = () => {
  const navigate = useNavigate();
  const userData = useUserData((state) => state.user);
  const { id: companyId } = useParams<{ id?: string }>();
  const { data: companyData, refetch: refetchCompany } =
    useAdminCompanyOverview(companyId);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    companyData?.user?.user_uid,
  );
  const { mutateAsync: removeUser, isLoading } = useRemoveUser();
  const [errorMessage, setErrorMessage] = useState(null);

  const user: UserData = userData as unknown as UserData;

  const [selectedTab, selectTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    selectTab(newValue);
  };

  const deleteUser = async () => {
    try {
      await removeUser(companyData?.user?.user_uid);
      navigate(Routes.AdminCompaniesRoute);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };

  const deactivateAccount = async (reason) => {
    await updateUserProfile({ user: reason });
    await refetchCompany();
  };

  const assignAdmin = async () => {
    await updateUserProfile({ company: { admin: !companyData?.user?.admin } });
    await refetchCompany();
  };

  return (
    <Grid
      container
      className="admin-overview-page admin-company-container"
      sx={{
        width: '100%',
        gap: 4,
      }}
    >
      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        <div className="section-titles-container">
          <p className="section-title">Company: {companyData?.company_name}</p>
          <p className="section-sub-title">
            An overview of {companyData?.company_name}‘s data
          </p>
        </div>
      </Grid>

      {/* TODO: Non functional, implement in future */}
      {/* <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        <Alert severity="info" className="callback-request-alert">
          <div className="callback-alert-content">
            <span className="info-text">
              This company has requested a callback
            </span>

            <Button
              className="info-button"
              variant="text"
              endIcon={<CheckIcon />}
              data-testid="callback-request"
            >
              Mark as complete
            </Button>
          </div>
        </Alert>
      </Grid> */}

      <Grid item lg={12} md={12}>
        <Box sx={{ width: '100%', marginBottom: '24px' }}>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className="tabs-wrapper"
          >
            <Tabs
              className="company-overview-tabs overview-tabs"
              value={selectedTab}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#DEB900',
                },
              }}
            >
              <Tab label={TabsValues.View} {...a11yProps(0)} />
              <Tab label={TabsValues.Edit} {...a11yProps(1)} />
            </Tabs>
            <div className="tabs-wrapper">
              <DeactivateAccount
                onSubmit={deactivateAccount}
                isDeactivate={!companyData?.user?.deactivate_reason}
              />
              <ResetPassword userUid={companyData?.user?.user_uid} />
              {!!user?.manager?.super_admin && (
                <>
                  <DeleteUser deleteUser={deleteUser} isLoading={isLoading} />
                  <AdminAccess
                    isAdmin={companyData?.user?.admin}
                    onSubmit={assignAdmin}
                  />
                </>
              )}
            </div>
          </Box>

          {selectedTab === 0 && (
            <CompanyDetails
              details={companyData}
              refetchCompany={refetchCompany}
            />
          )}
          {selectedTab === 1 && (
            <CompanyEdit
              details={companyData || {}}
              refetchCompany={refetchCompany}
            />
          )}
          <MiniteSnackbar
            open={!!errorMessage}
            title={'Error'}
            message={errorMessage}
            autoHideDuration={4000}
            severity={'error'}
            onClose={() => setErrorMessage(null)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminCompanyOverview;
