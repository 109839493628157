import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { AssessmentType, CallToAction, Routes } from '../../../../utils/consts';
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';

import './DoAssessment.scss';
import { useTranslation } from 'react-i18next';

const DoAssessment = ({ loading, alreadyVerified }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAssessmentClick = () => {
    const path = generatePath(Routes.StudentAssessmentView, {
      assessmentCategoryType: AssessmentType.AttentionToDetail,
    });
    navigate(`${path}?callToAction=${CallToAction.BrowseJobs}`);
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={12}>
          {loading ? (
            <Skeleton />
          ) : (
            <Alert severity="info" className="student-scheduler-call">
              <AlertTitle className="info-title">
                {t('assessment.do_assessment')}
              </AlertTitle>
              <Typography className="info-text">
                {alreadyVerified ? (
                  <>{t('assessment.do_assessment_verified_description')}</>
                ) : (
                  <>{t('assessment.do_assessment_description')}</>
                )}
              </Typography>
              <div className="actions">
                <Button
                  variant="contained"
                  color="info"
                  sx={{ float: 'right' }}
                  onClick={onAssessmentClick}
                  data-testid="do-assessment"
                >
                  {t('assessment.do_assessment_button')}
                </Button>
              </div>
            </Alert>
          )}
        </Grid>
      </Grid>
    </section>
  );
};

export default DoAssessment;
