import React from 'react';
import './Ambassador.scss';

const Ambassador = () => {
  return (
    <img
      src="/images/ambassador.png"
      alt="Ambassador"
      className="ambassador-img"
    />
  );
};

export default Ambassador;
