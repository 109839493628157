import React from 'react';
import { Chip, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ProposalStatusChip from '../../job-details/components/ProposalStatusChip';
import Box from '@mui/material/Box';
import { ProposalStatus, ProposalStatuses } from '../../../data/jobs';
import { useTranslation } from 'react-i18next';
import StudentAvatar from '../../../components/student-avatar';
import { openChatUrl } from '../../../utils/openChat';

const ChatHeader = ({
  jobTitle,
  status,
  name,
  unreadConversations,
  isDeleted,
  isSupportButton,
  isAdmin,
  isUserStudent,
}: {
  jobTitle: string;
  avatar: string | null;
  status: ProposalStatus | null;
  name: string;
  unreadConversations: number;
  isDeleted: boolean;
  isSupportButton: boolean;
  isAdmin: boolean;
  isUserStudent: boolean;
}) => {
  const { t } = useTranslation();
  const openSupportChat = () => openChatUrl('admin', '_self');

  return (
    <Box className="chat-header-wrapper">
      <div className="title-wrapper">
        <p className="title">Chats</p>
        {isSupportButton && (
          <div
            className={`suport-wrapper ${isAdmin ? 'active' : ''}`}
            onClick={openSupportChat}
          >
            <div className="Avatar__avatar">
              <StudentAvatar alt="avatar" avatar="/images/cat.png" />
            </div>

            <span>Minite Support Team</span>
          </div>
        )}
        {!!unreadConversations && (
          <p>
            <span id="unreadCount" className="bold_blue">
              {unreadConversations}
            </span>{' '}
            {t('chat.unread_conversations')}.
          </p>
        )}
      </div>
      {name && (
        <div className="chat-header user">
          <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            style={{ marginBottom: '10px' }}
          >
            <div className="chat-avatar-wrapper">
              <StudentAvatar
                alt="avatar"
                avatar={'/images/student-avatars/7.png'}
              />
            </div>
            <div>
              <Box style={{ display: 'flex' }}>
                <Typography color="inherit" className="user-name">
                  {name}
                </Typography>{' '}
                <Typography color="inherit" className="user-name line">
                  {' '}
                  {jobTitle && '|'}{' '}
                </Typography>
                <Typography color="inherit" className="user-name">
                  {jobTitle}
                </Typography>
              </Box>
              {status && <ProposalStatusChip proposalStatus={status} />}
              {isDeleted && (
                <Chip
                  variant="filled"
                  label="Job deleted"
                  className={`proposal-status-chip ${status}`}
                  sx={{
                    borderColor: '#EA4335',
                    backgroundColor: '#EA4335',
                    color: '#FFFFFF',
                    width: 'auto',
                    minWidth: 'auto',
                    textTransform: 'capitalize',
                  }}
                />
              )}
            </div>
          </Grid>
          {status !== ProposalStatuses.Signed && jobTitle && (
            <div className="chat-warning">
              {t('chat.communication_restricted_chat')}
              {isUserStudent && (
                <span>
                  {' ' + t('chat.communication_restricted_chat_student')}
                </span>
              )}
            </div>
          )}
          {status === ProposalStatuses.Signed && isUserStudent && (
            <div className="chat-warning">
              {t('chat.communication_restricted_chat_student_signed')}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default ChatHeader;
