import { UserType } from '../../components/sidepanel/UserType';
import CompanyJobDetails from './CompanyJobDetails';
import StudentJobDetails from './StudentJobDetails';
import { JobTypes } from '../../data/jobs';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useUserData } from '../../stores/auth';
import { getUserType } from '../../data/user';
export const enum ProposalStatuses {
  DRAFT = 'draft',
  PENDING = 'pending',
  RETRACTED = 'retracted',
  INTERVIEWING = 'interviewing',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  SIGNED = 'signed',
}

export const budgetString = (budget, jobType) =>
  `€${budget}${jobType === JobTypes.ONGOING ? '/hr' : ''}`.replace('.', ',');

const JobDetails = () => {
  useDocumentTitle('Job Details');

  const userData = useUserData((state) => state.user);

  if (userData === undefined) {
    return null;
  }

  const userType = getUserType(userData);
  return userType === UserType.Company ? (
    <CompanyJobDetails />
  ) : (
    <StudentJobDetails />
  );
};

export default JobDetails;
