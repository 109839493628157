export const levels = ['minor', 'medium', 'major', 'critical'];

export const categories = {
  company: [
    'interviewing',
    'invoices',
    'legal',
    'my_hf',
    'my_profile',
    'post_job',
    'other',
  ],
  student: [
    'browsing_jobs',
    'freelancing',
    'invoices',
    'my_profile',
    'vat',
    'other',
  ],
};
