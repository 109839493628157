import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import './ReviewsModal.scss';
import { ReviewProps } from '../../data/reviews';
import { formatDate } from '../../utils/dates';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  reviews: ReviewProps[];
}

const ReviewsModal = ({ isOpen, onClose, reviews }: IProps) => {
  return (
    <Dialog open={isOpen} className="review-modal">
      <DialogTitle className="content-title">
        All reviews
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {reviews.length ? (
          reviews.map((review, i) => {
            const { description, rating, created_at, company, student } =
              review;

            return (
              <div key={i} className="review-block">
                <div className="rating-wrapper">
                  <div className="info-wrapper">
                    <span className="name">
                      {company?.company_name || student?.first_name}
                    </span>
                    <span className="date">
                      {formatDate(created_at, 'MMMM YYYY')}
                    </span>
                  </div>
                  <Rating
                    name="half-rating"
                    value={Number(rating)}
                    readOnly
                    sx={{
                      color: '#deb900',
                      fontSize: '16px',
                    }}
                    precision={0.5}
                    size="large"
                    emptyIcon={
                      <StarIcon
                        style={{ fill: '#DDDDDD' }}
                        fontSize="inherit"
                      />
                    }
                  />
                </div>
                {!!description && (
                  <span className="description">{description}</span>
                )}
              </div>
            );
          })
        ) : (
          <div>There are no reviews yet</div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ReviewsModal;
