import React from 'react';
import { IItemInfo } from './ContentInfo';

const InfoWithLink = ({ item }: { item: IItemInfo }) => {
  const { text, link, text_link } = item;
  const textInfo = text ? text.split('{{}}') : [];

  return (
    <>
      <>{textInfo[0] || ''}</>
      <a href={link} target="_blank" className="info-link">
        {text_link}
      </a>
      <>{textInfo[1] || ''}</>
    </>
  );
};

export default InfoWithLink;
