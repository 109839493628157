import React, { useState } from 'react';
import { Badge, FormControl, Grid, TextField } from '@mui/material';
import './JobAccountForms.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { JobForm } from '../../../../../stores/job-form';
import { useParams } from 'react-router-dom';
import { useUpdateAdminJobDetails } from '../../../../../data/admin';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';

const schema = yup
  .object()
  .shape({
    direct_job: yup.boolean(),
    job_title: yup.string().required(),
    job_description: yup.string().min(300).required(),
    question_required: yup.string().when('direct_job', {
      is: false,
      then: (schema) => schema.required(),
    }),
    question_optional: yup.string(),
  })
  .required();

export const AdminJobDescriptionForm = ({
  job,
  refetch,
}: {
  job: JobForm;
  refetch: () => void;
}) => {
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, errors, isSubmitted },
    register,
    reset,
  } = useForm<{
    direct_job: boolean;
    job_title: string;
    job_description: string;
    question_required: string;
    question_optional: string;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      direct_job: job?.direct_job,
      job_title: job?.job_title || '',
      job_description: job?.job_description || '',
      question_required: job?.question_required || '',
      question_optional: job?.question_optional || '',
    },
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_description',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { mutateAsync: updateAdminJobDetails } = useUpdateAdminJobDetails();
  const { id: jobId } = useParams<{ id?: string }>();

  const [descCharsCount, setDescCharsCount] = useState(0);
  const handleDescriptionChange = (event: any) => {
    setValue('job_description', event.target.value);
    setDescCharsCount(event.target.value.length);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload: any = {
        job: {
          job_title: data.job_title || 'Draft',
          job_description: data.job_description,
          question_required: data.question_required,
          question_optional: data.question_optional,
        },
      };

      await updateAdminJobDetails({
        jobId,
        jobDetails: payload,
      });
      refetch();
      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="describe-job-section">
        <Grid
          container
          direction="row"
          className="job-form-grid"
          alignItems="stretch"
          gap={2}
          sx={{ marginTop: '24px' }}
        >
          <Grid item xs={12} md={2}>
            <p className="job-forms-titles">Description</p>
          </Grid>

          <Grid item xs={12} md={9}>
            <form className="describe-job-form">
              <div className="form-inputs">
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...register('job_title')}
                    type="text"
                    id="title"
                    label={t('job_title')}
                    error={Boolean(errors.job_title)}
                    variant="standard"
                  />
                </FormControl>

                <FormControl sx={{ width: '100%', marginTop: '30px' }}>
                  <Badge
                    max={3000}
                    badgeContent={descCharsCount}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  />
                  <TextField
                    {...register('job_description')}
                    helperText={t('minimum')}
                    error={Boolean(errors.job_description)}
                    type="text"
                    rows={10}
                    multiline
                    onChange={handleDescriptionChange}
                    id="description"
                    label={t('description')}
                    variant="standard"
                  />
                </FormControl>
              </div>

              <div className="form-inputs">
                <FormControl
                  sx={{
                    width: '100%',
                    marginBottom: '32px',
                    marginTop: '50px',
                  }}
                >
                  <TextField
                    {...register('question_required')}
                    type="text"
                    id="question_required"
                    error={Boolean(errors.question_required)}
                    label={t('questions.one')}
                    variant="standard"
                  />
                </FormControl>

                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...register('question_optional')}
                    type="text"
                    id="question_optional"
                    label={t('questions.two')}
                    variant="standard"
                  />
                </FormControl>
              </div>
            </form>
            <div className="save-button-container">
              <LoadingButton
                isDisabled={!isValid || !isDirty}
                className="save-button"
                onClick={handleSubmit(onSubmit)}
                buttonText={
                  !isDirty && isSubmitted
                    ? t('changes_saved')
                    : t('save_changes')
                }
                isLoading={isLoading}
                confirmButtonTestId="save-job-description-admin"
              />
            </div>
          </Grid>
        </Grid>

        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </section>
    </>
  );
};
