import React, { useEffect, useState } from 'react';
import {
  ProposalStatus,
  ProposalStatuses,
  useFetchJobById,
} from '../../../data/jobs';
import '../Dashboard.scss';
import { Routes } from '../../../utils/consts';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import MiniteGrid from '../../../components/grid';

import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';

import { MyProposalResponse, useUpdateProposal } from '../../../data/proposals';
import ProposalStatusChip from '../../job-details/components/ProposalStatusChip';
import { JobDrawer } from '../../../components/job-drawer';
import StudentDrawerContent from '../../job-details/components/CreateProposalDrawer';
import ProposalStudentView from '../../job-details/components/ProposalStudentView';
import { QueryObserverResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ButtonBase, useMediaQuery } from '@mui/material';
import { formatDate } from '../../../utils/dates';
import NoData from './NoData';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { handleGridHeight } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import SkeletonProposalModal from '../../job-details/SkeletonProposalModal';
import { UserData } from '../../../data/user';
import { useUserData } from '../../../stores/auth';
import GenericModal from '../../../components/generic-modal/GenericModal';

interface Props {
  proposals: MyProposalResponse[];
  loading: boolean;
  reloadProposals: () => Promise<
    QueryObserverResult<MyProposalResponse[], AxiosError<unknown, any>>
  >;
}

const MyProposalsStudent = ({ proposals, loading, reloadProposals }: Props) => {
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const [shownProposalDrawer, showProposalDrawer] = useState(false);
  const [proposal, setProposal] = useState(null);
  const [successModal, showModal] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);

  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { data: jobData, isLoading: jobLoading } = useFetchJobById(
    proposal?.job_uid,
  );
  const userData = useUserData((state) => state.user);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const gridData = (): unknown[] =>
    proposals?.map((proposal) => {
      return {
        id: proposal.proposal_uid,
        createdAt: formatDate(proposal.created_at, 'DD MMMM YYYY'),
        status: proposal.proposal_status,
        company: proposal.company.company_name,
        jobTitle: proposal.job.job_title,
        jobId: proposal.job.job_uid,
        actions: '',
        mobileActions: '',
      };
    });

  useEffect(() => {
    if (!jobLoading && jobData && proposal) {
      if (proposal.proposal_status === ProposalStatuses.Draft) {
        setIsSkeleton(true);

        const callback = async () => {
          try {
            await updateProposal({
              proposalId: proposal.proposal_uid,
              data: proposal,
            });
            await reloadProposals();

            setIsSkeleton(false);
          } catch (err) {
            showProposalDrawer(false);

            console.log(err);
          }
        };

        callback();
      } else {
        setIsSkeleton(false);
      }
    }
  }, [proposal, jobData, jobLoading]);

  // Handlers

  const handleProposalDrawerOpen = async (proposalUid) => {
    const proposal = proposals.find(
      (item) => item.proposal_uid === proposalUid,
    );

    showProposalDrawer(true);
    setIsSkeleton(true);

    setProposal(proposal);
  };

  const handleProposalDrawerClose = async () => {
    showProposalDrawer(false);
    setProposal(null);
    await reloadProposals();
  };

  const handleNavigateToJobs = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  // Table

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'createdAt',
      headerName: t('dashboard.creation_date'),
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      hide: isMobileView,
      sortComparator: (a, b) => {
        return new Date(a) < new Date(b) ? -1 : 1;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <ProposalStatusChip forStudent proposalStatus={params.value} />
      ),
    },
    {
      field: 'company',
      headerName: t('dashboard.company'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      hide: isMobileView,
    },
    {
      field: 'jobTitle',
      headerName: t('dashboard.job_title'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="link"
          to={`${Routes.BrowseJobsRoute}/${params.row.jobId}`}
          title={params.row.jobTitle}
        >
          {params.row.jobTitle}
        </Link>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('dashboard.actions'),
      sortable: false,
      align: 'right',
      flex: 1,
      hide: isMobileView,
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <GridActionsCellItem
            sx={{
              textTransform: 'capitalize',
            }}
            onClick={() => handleProposalDrawerOpen(params.id)}
            label={t('proposal.view_proposal')}
            showInMenu
          />,
        );
        return actions;
      },
    } as GridActionsColDef,
    {
      field: 'mobileActions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 1,
      hide: !isMobileView,
      renderCell: (params: GridRenderCellParams) => (
        <div className="actions-bar">
          <ButtonBase
            onClick={() => handleProposalDrawerOpen(params.row.id)}
            data-testid="open-proposal-modal"
          >
            <ChevronRightOutlinedIcon
              sx={{
                color: '#DEB900',
                cursor: 'pointer',
              }}
              className="next-button"
            />
          </ButtonBase>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard-jobs">
      <h3 className="heading-text">{t('dashboard.my_proposals')}</h3>

      {proposals.length === 0 && !loading ? (
        <NoData message={t('dashboard.no_any_proposals')} />
      ) : (
        <MiniteGrid
          height={handleGridHeight(proposals?.length || 0)}
          loading={loading}
          columns={columns}
          rows={gridData()}
          rowId={'id'}
          disableColumnFilter={true}
          pagination
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      )}

      <JobDrawer
        open={shownProposalDrawer}
        onClose={handleProposalDrawerClose}
        className="job-drawer-student--wrapper"
      >
        {isSkeleton ? (
          <SkeletonProposalModal />
        ) : (
          proposal &&
          jobData &&
          (proposal.proposal_status === ProposalStatuses.Draft ? (
            <StudentDrawerContent
              user={userData}
              job={jobData}
              proposal={proposal}
              handleDrawerClose={handleProposalDrawerClose}
              handleProposalSubmit={() => showModal(true)}
            />
          ) : (
            <ProposalStudentView
              user={userData as unknown as UserData}
              job={jobData}
              proposal={proposal}
              handleDrawerClose={handleProposalDrawerClose}
            />
          ))
        )}
      </JobDrawer>

      <GenericModal
        open={successModal}
        title={t('proposal.one_step_closer')}
        content={
          <>
            {t('proposal.next_steps_0')}
            <br />
            {t('proposal.next_steps_1')}
            <br />
            {t('proposal.next_steps_2')}
            <br />
            {t('proposal.next_steps_3')}
            <br />
            {t('proposal.next_steps_4')}
          </>
        }
        cancelButtonText="Close"
        confirmButtonText="Browse more jobs"
        handleCancel={() => showModal(false)}
        handleConfirm={handleNavigateToJobs}
      />
    </div>
  );
};

export default MyProposalsStudent;
