import React, { useState } from 'react';
import '../AdminGeneralStyles.scss';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import { formatDate } from '../../../utils/dates';
import { useDebounce } from '../../../utils/useDebounce';
import {
  Review,
  useReviewsStatistic,
  useSearchAdminReviews,
} from '../../../data/admin-reviews';
import CollapsibleTable from './CollapsibleTable';
import './AdminReviews.scss';
import { numberWithDec } from '../../../utils/numberWithDec';

const getStatisticCards = (data) => {
  const {
    student_avg_review_by_companies = 0,
    company_avg_review_by_students = 0,
    reviews_this_month,
    reviews_total,
  } = data;

  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'student_avg_review_by_companies'}
        text={'Average review from companies about students'}
        counter={numberWithDec(student_avg_review_by_companies)}
      />
      <AdminStatisticCard
        key={'company_avg_review_by_students'}
        text={'Average review from students about companies'}
        counter={numberWithDec(company_avg_review_by_students)}
      />
      <AdminStatisticCard
        key={'reviews_this_month'}
        text={'Reviews this month'}
        counter={reviews_this_month || 0}
      />
      <AdminStatisticCard
        key={'reviews_total'}
        text={'Total reviews'}
        counter={reviews_total || 0}
      />
    </div>
  );
};

const reviewInfo = (review) => {
  const result = {
    name: '',
    id: '',
    type: '',
  };

  if (review.student) {
    const { first_name, student_uid, last_name } = review.student;

    result.name = `${first_name} ${last_name}`;
    result.id = student_uid;
    result.type = 'student';
  } else {
    const { company_uid, company_name } = review.company;

    result.name = company_name;
    result.id = company_uid;
    result.type = 'company';
  }

  return result;
};
const AdminReviews = () => {
  const [search, setSearch] = useState('');
  const { data: statistics = {} } = useReviewsStatistic();
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
  });

  const debouncedSearch = useDebounce(search, 500);
  const { data: reviewsData, refetch: refetchReviewsDate } =
    useSearchAdminReviews({ ...filters, query: debouncedSearch });

  const { pagination, reviews } = reviewsData || {};

  const reviewsGridData = (): unknown[] =>
    reviews?.map((review: Review) => {
      const {
        job_title,
        job_uid,
        created_at,
        rating,
        review_uid,
        sender,
        receiver,
        description,
      } = review;
      return {
        id: review_uid,
        job_info: { job_title: job_title || 'Draft', job_uid: job_uid },
        sender: reviewInfo(sender),
        receiver: reviewInfo(receiver),
        rating: `${rating}/5`,
        description,
        created_at: formatDate(created_at, 'DD/MM/YYYY'),
      };
    });

  const refetchReviews = async () => {
    await refetchReviewsDate();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });

    refetchReviews();
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });

    refetchReviews();
  };

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-reviews-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Reviews</p>

      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        {getStatisticCards(statistics)}
      </Grid>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All reviews</p>
          <div className="admin-table-inputs reviews-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-reviews-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />
            <FiltersPanel
              page="admin-reviews"
              filters={filters}
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;

                setFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <CollapsibleTable
          data={reviewsGridData()}
          pagination={pagination}
          changePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminReviews;
