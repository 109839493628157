import React, { useState } from 'react';
import { FormGroup, Grid, TextField } from '@mui/material';
import { LoadingButton } from '../../../components/LoadingButton';
import MiniteSnackbar from '../../../components/snackbar';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useAdminUpdateUser } from '../../../data/admin';
import { CognitoUserProvider } from '../../../data/admin-companies';

const schema = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^\S.*$/)
      .required(),
    last_name: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^\S.*$/)
      .required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .max(12)
      .matches(/^\+?\d+$/)
      .required(),
  })
  .required();

interface AccountFormInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

const AccountInfo = ({ student }: { student: any }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const { user } = student || {};

  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: updateUserByAdmin } = useAdminUpdateUser();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitted },
    reset,
  } = useForm<AccountFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone: user?.phone,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await updateUserByAdmin({
        userId: user.user_uid,
        dto: { user: { ...data } },
      });

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">Account</p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <FormGroup className="form-group">
              <TextField
                className="form-group-input form-input"
                {...register('first_name')}
                name="first_name"
                type="text"
                id="first-name-input"
                variant="standard"
                label={t('first_name')}
                error={!!errors.first_name}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className="form-group-input form-input"
                type="text"
                id="last-name-input"
                {...register('last_name')}
                variant="standard"
                label={t('last_name')}
                name="last_name"
                error={!!errors.last_name}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>
            <FormGroup className="form-group">
              <TextField
                className="form-input"
                {...register('email')}
                name="email"
                type="text"
                id="email-input"
                variant="standard"
                label="Email"
                error={!!errors.email}
                disabled={
                  student?.user?.provider === CognitoUserProvider.Google
                }
                helperText={
                  student?.user?.provider === CognitoUserProvider.Google
                    ? 'Email disabled because user signed up via google.'
                    : 'Email will be automatically confirmed'
                }
                InputLabelProps={{ shrink: true }}
                // InputProps={{
                //   endAdornment: false && (
                //     <InputAdornment position="end">
                //       <Tooltip
                //         title={'Email Verified Status'}
                //         placement="right"
                //       >
                //         <VerifiedIcon className="email-verified-icon" />
                //       </Tooltip>
                //     </InputAdornment>
                //   ),
                // }}
              />

              <TextField
                className="form-input"
                {...register('phone')}
                name="phone"
                type="text"
                id="phone-input"
                variant="standard"
                label={t('phone_number')}
                inputProps={{ maxLength: 12 }}
                error={!!errors.phone}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              confirmButtonTestId="save-account-info"
              isLoading={isLoading}
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AccountInfo;
