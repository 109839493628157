import React, { useState, useEffect } from 'react';
import {
  StripeElementsOptionsClientSecret,
  loadStripe,
} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './PayInvoice.scss';
import CheckoutForm from '../../components/stripe-checkout-form';
import { getStripePaymentIntent } from '../../data/stripe';
import { useLocation } from 'react-router-dom';
import { extractQueryParameter } from '../../utils/helpers';
import { CircularProgress } from '@mui/material';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PayInvoice = () => {
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [paymentIntentIdState, setPaymentIntentId] = useState('');
  const [redirectStatusState, setRedirectStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const paymentIntentId = extractQueryParameter(
          location,
          'paymentIntentId',
        );
        const redirectStatus = extractQueryParameter(
          location,
          'redirect_status',
        );
        setRedirectStatus(redirectStatus);
        setPaymentIntentId(paymentIntentId);
        const {
          client_secret,
          invoice_paid,
          company_collective_invoice_number,
          amount,
        } = await getStripePaymentIntent({
          stripe_payment_intent_id: paymentIntentId,
        });
        setInvoicePaid(invoice_paid);
        setClientSecret(client_secret);
        setInvoiceNumber(company_collective_invoice_number);
        setAmountToPay(amount);
      } catch (error) {
        console.error("Couldn't fetch client secret", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  } as StripeElementsOptionsClientSecret;

  return (
    <div className="stripe-form">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div>Invoice number: {invoiceNumber}</div>
          <div>Total amount: € {amountToPay / 100}</div>
          {redirectStatusState === 'failed' && (
            <div>Something went wrong, please try again!</div>
          )}
          {redirectStatusState === 'succeeded' ? (
            <div>Your payment was successful!</div>
          ) : (
            <>
              {invoicePaid && redirectStatusState === null ? (
                <>
                  <div>This invoice has already been paid!</div>
                  <div>
                    If you have any questions, please reach out to
                    finance@minite.works
                  </div>
                </>
              ) : (
                <>
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm paymentIntentId={paymentIntentIdState} />
                    </Elements>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PayInvoice;
