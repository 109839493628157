import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { Link } from 'react-router-dom';
import './SidePanel.scss';

interface Props {
  title: string;
  icon?: any;
  relativeUrl?: string;
  isSelected?: boolean;
  isCollapsed: boolean;
  color?: string;
  padding?: number;
  onClick?: React.MouseEvent<HTMLElement>;
  onClose: () => void;
  id?: string;
}

interface ExternalLinkProps {
  title: string;
  icon?: any;
  externalLink?: string;
  isSelected?: boolean;
  isCollapsed: boolean;
  color?: string;
  padding?: number;
  onClick?: React.MouseEvent<HTMLElement>;
  onClose: () => void;
  id?: string;
}

interface CollapsibleProps {
  title: string;
  icon: any;
  color?: string;
  children: React.ReactNode;
}

export const NavigationButton = (props: Props) => {
  const sidebarColor = '#393A42';
  const selectedColor = '#3788D8';

  return (
    <Link to={props.relativeUrl || '#'} onClick={props.onClose} id={props.id}>
      <ListItemButton
        sx={{
          pl: 0 || props.padding,
          marginBottom: '3px',
          ml: props.isCollapsed ? 2 : 0,
          mr: props.isCollapsed ? 2 : 0,
        }}
        selected={props.isCollapsed && props.isSelected}
      >
        {props.icon && (
          <ListItemIcon
            sx={{
              color:
                !props.isCollapsed && props.isSelected
                  ? selectedColor
                  : props.color || sidebarColor,
              minWidth: (theme) => theme.spacing(5),
            }}
          >
            {props.icon}
          </ListItemIcon>
        )}

        <ListItemText
          primary={props.title}
          primaryTypographyProps={{
            style: {
              color:
                !props.isCollapsed && props.isSelected
                  ? selectedColor
                  : props.color || sidebarColor,
            },
          }}
        />
      </ListItemButton>
    </Link>
  );
};

export const ExternalLink = (props: ExternalLinkProps) => {
  const sidebarColor = '#393A42';
  const selectedColor = '#3788D8';

  return (
    <ListItemButton
      sx={{
        pl: 0 || props.padding,
        marginBottom: '3px',
        ml: props.isCollapsed ? 2 : 0,
        mr: props.isCollapsed ? 2 : 0,
      }}
      onClick={props.onClose}
      id={props.id}
      selected={props.isCollapsed && props.isSelected}
    >
      {props.icon && (
        <ListItemIcon
          sx={{
            color:
              !props.isCollapsed && props.isSelected
                ? selectedColor
                : props.color || sidebarColor,
            minWidth: (theme) => theme.spacing(5),
          }}
        >
          {props.icon}
        </ListItemIcon>
      )}

      <a href={props.externalLink} target="_blank">
        <ListItemText
          primary={props.title}
          primaryTypographyProps={{
            style: {
              color:
                !props.isCollapsed && props.isSelected
                  ? selectedColor
                  : props.color || sidebarColor,
            },
          }}
        />
      </a>
    </ListItemButton>
  );
};

export const CollapsibleNavigationButton = (props: CollapsibleProps) => {
  const sidebarColor = '#393A42';
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ marginBottom: '3px' }}>
        <ListItemIcon
          sx={{
            color: props.color || sidebarColor,
            minWidth: (theme) => theme.spacing(5),
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.title}
          primaryTypographyProps={{
            style: { color: props.color || sidebarColor },
          }}
        />
        {open ? (
          <ExpandLess sx={{ color: sidebarColor }} />
        ) : (
          <ExpandMore sx={{ color: sidebarColor }} />
        )}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">{props.children}</List>
      </Collapse>
    </>
  );
};
