import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';

import { ADMIN_REVIEWS, REVIEWS_STATISTICS } from './query-keys';
import { AxiosError } from 'axios';

const searchAdminReviews = async (body?: ReviewsSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/reviews/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminReviews = (body: ReviewsSearchRequest) => {
  return useQuery([ADMIN_REVIEWS, body], () => searchAdminReviews(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const reviewsStatistic = async () => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/statistics/reviews`,
    method: 'get',
  });

  return data.data;
};

export const useReviewsStatistic = () => {
  return useQuery([REVIEWS_STATISTICS], () => reviewsStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

interface Search {
  reviews: Review[];
  pagination: { total: number; page: number; per_page: number };
}

interface ReviewsSearchRequest {
  start_date?: string;
  end_date?: string;
  hours_min?: number;
  hours_max?: number;

  query?: string;

  page?: number;
  per_page?: number;
}

export interface Review {
  created_at: string;
  description?: string;
  job_title: string;
  job_uid: string;
  rating: number;
  receiver: {
    company?: {
      company_uid: string;
      company_name: string;
    };
    student?: {
      student_uid: string;
      first_name: string;
      last_name: string;
    };
  };
  review_uid: string;
  sender: {
    student?: {
      student_uid: string;
      first_name: string;
      last_name: string;
    };
    company?: {
      company_uid: string;
      company_name: string;
    };
  };
  first_name: string;
  last_name: string;
  student_uid: string;
}
