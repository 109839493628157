import React, { useState } from 'react';
import '../../financial/components/AccountForms.scss';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  StudentEducationDto,
  useFetchUniversities,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import { Degrees } from '../../../../components/sign-up/interfaces/static-data';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import {
  initialUniversity,
  MIN_EDUCATION_EXPERIENCE_DATE,
  universitiesSchema,
  University,
} from '../../../../stores/student-signup-form';
import { formatDate } from '../../../../utils/dates';
import {
  getOptionLabel,
  IOption,
  isOptionEqualToValue,
} from '../../../../utils/ioption';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';
import { Profile } from '../../../../data/profile';

const schema = yup
  .object()
  .shape({
    universities: universitiesSchema,
    profile: yup.mixed<Profile>().required(),
  })
  .required();

interface EducationFormInterface {
  universities: University[];
  profile: Profile;
}

const EducationForm = ({ student, loading, afterSubmit }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { data: universities = student?.universities || [] } =
    useFetchUniversities();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.student',
  });
  const universityOptions: IOption[] = universities.map((item) => {
    return {
      label: item.university_name,
      id: item.university_uid,
    };
  });
  const education = student?.education?.filter((item) => !!item.university_uid);
  const universitiesData = !!education?.length
    ? education?.map((item) => {
        return {
          degree: item.degree,
          study: item.study,
          startDate: item.started_at,
          endDate: item.ended_at,
          university: universityOptions.find((uni) => {
            return uni?.id === item?.university_uid;
          }),
        };
      })
    : [{ ...initialUniversity }];
  const defaultValues = {
    universities: universitiesData,
    profile: student.profile,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<EducationFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues },
    reValidateMode: 'onChange',
  });

  const {
    fields: universitiesFields,
    append: appendUniversity,
    remove: removeUniversity,
  } = useFieldArray({
    control,
    name: 'universities',
  });

  const universitiesWatch = useWatch({ control, name: 'universities' });

  const onSubmit = async (data: EducationFormInterface) => {
    if (data) {
      setIsLoading(true);

      const payload_eductation: StudentEducationDto[] = data.universities.map(
        (item) => {
          return {
            university_uid: item.university?.id,
            degree: item.degree,
            study: item.study,
            started_at: formatDate(item.startDate, 'MM/DD/YYYY'),
            ended_at: formatDate(item.endDate, 'MM/DD/YYYY') || null,
          };
        },
      );
      await updateUserProfile({
        student: {
          education: payload_eductation,
          profile: data.profile,
        },
      });

      await reset(data);
      await afterSubmit();
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms education-form without-border">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">{t('education')}</p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            {loading ? (
              <Skeleton />
            ) : (
              <div className="form-inputs">
                {universitiesFields.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="form-array-header">
                        <span className="form-array-title">
                          Studies #{index + 1}
                        </span>
                        {universitiesWatch.length > 1 && index > 0 && (
                          <Button
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            variant="outlined"
                            className="array-buttons remove-field-button"
                            type="button"
                            data-testid="remove-university"
                            onClick={() => removeUniversity(index)}
                          >
                            {t('remove')}
                          </Button>
                        )}
                      </div>

                      <Controller
                        render={({ field: { value, onChange }, field }) => (
                          <Autocomplete
                            options={universityOptions}
                            value={value}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            onChange={(_, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...field}
                                error={
                                  !!errors.universities?.[index]?.university
                                }
                                helperText={
                                  errors?.universities?.[index]?.university
                                    ?.message
                                }
                                variant="standard"
                                label={t('university')}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`universities.${index}.university`}
                      />

                      <FormGroup className="form-group">
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <FormControl
                              variant="standard"
                              sx={{ flex: '1 1 20%' }}
                            >
                              <InputLabel id="degree-select-label">
                                {t('degree')}
                              </InputLabel>
                              <Select
                                labelId="degree-select-label"
                                id="degree-select"
                                label={t('degree')}
                                value={value}
                                onChange={onChange}
                              >
                                {Degrees.map((item) => {
                                  return (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                          control={control}
                          name={`universities.${index}.degree`}
                        />

                        <TextField
                          type="text"
                          id="study"
                          sx={{ flex: '1 1 100%' }}
                          variant="standard"
                          label={t('your_study')}
                          name="study"
                          error={!!errors.universities?.[index]?.study}
                          helperText={
                            errors?.universities?.[index]?.study?.message
                          }
                          {...register(`universities.${index}.study`)}
                        />
                      </FormGroup>

                      <FormGroup className="form-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            render={({ field: { value, onChange } }) => (
                              <DesktopDatePicker
                                value={value}
                                views={['year', 'month']}
                                onChange={(value) => onChange(value)}
                                disableMaskedInput={true}
                                label={t('start_date')}
                                inputFormat="MMMM YYYY"
                                minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{ flex: '1 1 50%' }}
                                    error={
                                      !!errors.universities?.[index]?.startDate
                                    }
                                    helperText={
                                      !!errors.universities?.[index]?.startDate
                                        ? t('datepicker_error')
                                        : ''
                                    }
                                  />
                                )}
                              />
                            )}
                            control={control}
                            name={`universities.${index}.startDate`}
                          />

                          <Controller
                            render={({ field: { value, onChange } }) => (
                              <DesktopDatePicker
                                value={value}
                                views={['year', 'month']}
                                onChange={(value) => onChange(value)}
                                disableMaskedInput={true}
                                label={t('end_date')}
                                inputFormat="MMMM YYYY"
                                minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{ flex: '1 1 50%' }}
                                    error={
                                      !!errors.universities?.[index]?.endDate
                                    }
                                    helperText={
                                      !!errors.universities?.[index]?.endDate
                                        ? t('datepicker_error')
                                        : ''
                                    }
                                  />
                                )}
                              />
                            )}
                            control={control}
                            name={`universities.${index}.endDate`}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}

                <div className="edit-profile-array-buttons-container">
                  <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    className="array-buttons add-field-button"
                    type="button"
                    data-testid="add-university"
                    onClick={() =>
                      appendUniversity({
                        ...initialUniversity,
                      })
                    }
                  >
                    {t('add_study')}
                  </Button>
                </div>
                <div>
                  <p className="step-subtitle">{t('profile')}</p>
                  <FormControl>
                    <Controller
                      name="profile"
                      control={control}
                      render={({ field }) => {
                        return (
                          <RadioGroup
                            {...field}
                            sx={{ marginTop: '10px' }}
                            data-testid="deactivation-reasons"
                          >
                            {Object.values(Profile).map((value) => (
                              <FormControlLabel
                                value={value}
                                control={
                                  <Radio
                                    sx={{
                                      '&, &.Mui-checked': {
                                        color: '#deb900',
                                      },
                                    }}
                                  />
                                }
                                label={t(value)}
                                key={value}
                              />
                            ))}
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </form>

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-educations"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default EducationForm;
