import React from 'react';
import '../Dashboard.scss';
import { Typography } from '@mui/material';

interface Props {
  message: string;
}

const NoData = ({ message }: Props) => {
  return (
    <div className="no-data">
      <img src="/images/no-data.png" alt="no-data-icon" />
      <Typography className="message">{message}</Typography>
    </div>
  );
};

export default NoData;
