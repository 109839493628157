import React, { useEffect, useState } from 'react';
import './CreateJob.scss';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  LinearProgress,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { JobForm, useManageJobForm } from '../../stores/job-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { JobStatuses, useUpdateJobRequest } from '../../data/jobs';
import CloseIcon from '@mui/icons-material/Close';
import MiniteSnackbar from '../../components/snackbar';
import { LoadingButton } from '../../components/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import { usePostJobWizardLoading } from './store';
import { useGenerateJobDescription } from '../../data/user';
import { useFetchCategories } from '../../data/shared';

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  job: any;
}

const schema = yup
  .object()
  .shape({
    job_title: yup.string().required(),
    job_description: yup.string().min(300).required(),
    question_required: yup.string().required(),
    question_optional: yup.string(),
  })
  .required();

const jobDescriptionSchema = yup
  .object()
  .shape({
    company_desc: yup.string().required(),
    main_tasks: yup.string().required(),
    desc_language: yup.string().required(),
  })
  .required();

export const DescribeJobStep = ({ handleNext, handleBack, job }: Props) => {
  const { jobFormState } = useManageJobForm();
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_description',
  });
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const refetchJob = usePostJobWizardLoading((state) => state.refetchJob);
  const [loadProgress, setLoadProgress] = React.useState(0);
  const { data: categories = [] } = useFetchCategories();

  const {
    handleSubmit,
    setValue,
    formState: { isValid, errors },
    register,
    reset,
  } = useForm<{
    job_title: string;
    job_description: string;
    question_required: string;
    question_optional: string;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      job_title: job.job_title,
      job_description: job.job_description || t(jobFormState.job_description),
      question_required: job.question_required,
      question_optional: job.question_optional || '',
    },
    reValidateMode: 'onChange',
  });

  const getCategoryName = (categoryUid: string) => {
    const category = categories.find((cat) => cat.category_uid === categoryUid);
    return category ? category.category_name : 'Category not found';
  };

  const [jobCategoryName, setJobCategoryName] = useState('');

  useEffect(() => {
    const categoryName = getCategoryName(job.category_uid);
    setJobCategoryName(categoryName);
  }, [categories, job.category_uid]);

  useEffect(() => {
    if (jobCategoryName) {
      reset({
        job_title: job.job_title === 'Draft' ? jobCategoryName : job.job_title,
        job_description: job.job_description || t(jobFormState.job_description),
        question_required: job.question_required,
        question_optional: job.question_optional || '',
      });
    }
  }, [jobCategoryName, job, t, jobFormState.job_description, reset]);

  const [errorMessage, setErrorMessage] = useState(null);

  const [descCharsCount, setDescCharsCount] = useState(
    job.job_description?.length,
  );

  const [showQuestionsDialog, toggleQuestionsDialog] = useState(false);

  const jobDescriptionForm = useForm<{
    company_desc: string;
    main_tasks: string;
    desc_language: string;
  }>({
    mode: 'all',
    resolver: yupResolver(jobDescriptionSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      company_desc: job.company_desc,
      main_tasks: job.main_tasks,
      desc_language: job.desc_language || '',
    },
  });

  const { isLoading: isLoadingJobDescription } = useGenerateJobDescription(
    job.job_uid,
  );

  const handleToggleQuestionsDialog = (event: any) => {
    event.preventDefault();
    toggleQuestionsDialog(!showQuestionsDialog);
  };

  const handleDescriptionChange = (event: any) => {
    setValue('job_description', event.target.value);
    setDescCharsCount(event.target.value.length);
  };

  const onSubmit = async (data: Partial<JobForm>) => {
    const { job_title, job_description, question_required, question_optional } =
      data;
    const { job_status } = job || {};

    try {
      setLoading(true);
      const payload = {
        job: {
          job_title,
          job_description,
          question_required,
          question_optional,
          company_desc: jobDescriptionForm.getValues('company_desc'),
          main_tasks: jobDescriptionForm.getValues('main_tasks'),
          desc_language: jobDescriptionForm.getValues('desc_language'),
        },
      };

      await updateJobRequest({ job_uid: job.job_uid, body: payload });

      if (job_status === JobStatuses.PENDING_APPROVAL) {
        navigate(`${Routes.BrowseJobsRoute}/${job.job_uid}`, {
          replace: true,
        });
        return;
      }
      await refetchJob(job.job_uid);
      handleNext();
    } catch (error) {
      setErrorMessage('Failed to update job!');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setInterval(() => {
      if (isLoadingJobDescription) {
        setLoadProgress((oldProgress) => {
          const diff = Math.random() * 2;
          return Math.min(oldProgress + diff, 97);
        });
      }
    }, 500);
  }, [isLoadingJobDescription]);

  return (
    <>
      <section className="describe-job-section">
        <form className="describe-job-form">
          <div className="form-inputs">
            <div>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('job_title')}
                  type="text"
                  id="job-title"
                  label={t('job_title')}
                  error={Boolean(errors.job_title)}
                  variant="outlined"
                  data-testid="job-title"
                />
              </FormControl>
            </div>
            <p className="form-title">{t('description.title')}</p>
            <FormControl sx={{ width: '100%', marginTop: '30px' }}>
              {isLoadingJobDescription ? (
                <>
                  <div>{t('description.waiting_1')}</div>
                  <div>{t('description.waiting_2')} ☕</div>
                  <LinearProgress variant="determinate" value={loadProgress} />
                  <Skeleton variant="rounded" height={220} />
                </>
              ) : (
                <>
                  <TextField
                    {...register('job_description')}
                    helperText={t('minimum')}
                    error={Boolean(errors.job_description)}
                    type="text"
                    rows={10}
                    multiline
                    onChange={handleDescriptionChange}
                    id="job-description"
                    label={t('description.title')}
                    variant="outlined"
                    data-testid="job-description"
                    disabled={isLoadingJobDescription}
                  />
                  <Badge
                    max={3000}
                    badgeContent={descCharsCount}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        marginRight: '10px',
                      },
                    }}
                  />
                  <br />
                </>
              )}
            </FormControl>

            <div className="add-question-container">
              <p className="title">{t('questions.title')}</p>
              <span className="description-step">
                {t('questions.description')}
              </span>{' '}
              <a
                className="sample-question-link"
                href="/"
                onClick={handleToggleQuestionsDialog}
              >
                {' '}
                {t('questions.click_here')}
              </a>{' '}
              <span className="description-step">
                {t('questions.for_sample_questions')}
              </span>
            </div>
          </div>

          <div className="form-inputs">
            <FormControl
              sx={{ width: '100%', marginBottom: '32px', marginTop: '50px' }}
            >
              <TextField
                {...register('question_required')}
                type="text"
                id="question_required"
                error={Boolean(errors.question_required)}
                label={t('questions.one')}
                variant="outlined"
                data-testid="required-guestion"
              />
            </FormControl>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                {...register('question_optional')}
                type="text"
                id="question_optional"
                label={t('questions.two')}
                variant="outlined"
                data-testid="optional-question"
              />
            </FormControl>
          </div>
        </form>
      </section>

      <div className="createjob-content-buttons">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="previous-step-post-job-description"
        >
          {t('back')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid || loading}
          confirmButtonTestId="save-description-post-job"
          buttonText={
            job.job_status === JobStatuses.PENDING_APPROVAL
              ? t('save_changes')
              : t('continue')
          }
          isLoading={loading}
        />
      </div>

      <Dialog open={showQuestionsDialog}>
        <DialogTitle>
          Feel free to use these sample questions!
          <IconButton
            aria-label="close"
            onClick={handleToggleQuestionsDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: '#4E5B73',
            }}
          >
            <CloseIcon className="avatar-close-icon" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">To test for creativity:</Typography>
          <Typography variant="body2">
            - If your personality were a dessert, which would you be and why?
          </Typography>
          <Typography variant="body2">
            - If you were the owner of our company, what would you do?
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 16 }}>
            To test for analytical skills:
          </Typography>
          <Typography variant="body2">
            - If you had to choose between three options, how would you decide?
          </Typography>
          <Typography variant="body2">
            - What came first: chicken or egg?
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 16 }}>
            To test for skills:
          </Typography>
          <Typography variant="body2">
            - Describe a time when you had to solve a problem, but didn’t have
            all necessary information. What did you do?
          </Typography>
          <Typography variant="body2">
            - If you get the project, what do you hope to accomplish in your
            first month?
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 16 }}>
            Always good:
          </Typography>
          <Typography variant="body2">
            - How do your past experiences, skills & knowledge recommend you for
            this project?
          </Typography>
          <Typography variant="body2">
            - If you were to start this project, what would your first day look
            like?
          </Typography>
        </DialogContent>
      </Dialog>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};
