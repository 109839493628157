import React from 'react';
import {
  JobStatus,
  JobStatuses,
  JobTypes,
  MyJobsListItem,
} from '../../../data/jobs';
import '../Dashboard.scss';
import { Routes } from '../../../utils/consts';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import JobStatusChip from '../../job-details/components/JobStatusChip';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { budgetString } from '../../job-details';
import MiniteGrid from '../../../components/grid';

import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import NoData from './NoData';
import { useMediaQuery } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { handleGridHeight } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { MyProposalResponse } from '../../../data/proposals';
import { formatDate } from '../../../utils/dates';

interface Props {
  jobs: MyJobsListItem[];
  proposals: MyProposalResponse[];
  loading: boolean;
}

const getProposalUid = (proposals, jobUid) => {
  return proposals.find((item) => item.job_uid === jobUid)?.proposal_uid;
};

const MyJobsStudent = ({ jobs, loading, proposals }: Props) => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();

  const myJobsGridData = (): unknown[] => {
    const jobIdInProgress = jobs?.find(
      (job) => job?.job_status === JobStatuses.IN_PROGRESS,
    )?.job_uid;

    if (jobIdInProgress) {
      const proposalId = getProposalUid(proposals, jobIdInProgress);

      if (proposalId) {
        localStorage.setItem('proposalIdJobInProgress', proposalId);
      }
    }

    return jobs
      ?.filter(
        (job) =>
          job?.job_status === JobStatuses.IN_PROGRESS ||
          job?.job_status === JobStatuses.COMPLETED,
      )
      .map((job: MyJobsListItem) => {
        return {
          id: job.job_uid,
          status: job.job_status,
          type: job.job_type,
          company: job.company.company_name,
          jobTitle: job.job_title,
          rate: budgetString(job.rate || job.budget, job.job_type),
          actions: '',
          mobileActions: '',
          in_progress_at: formatDate(job.in_progress_at, 'DD/MM/YYYY'),
          completed_at: job.completed_at
            ? formatDate(job.completed_at, 'DD/MM/YYYY')
            : formatDate(job.cancelled_at, 'DD/MM/YYYY'),
        };
      });
  };

  // Handlers

  const handleChatOpen = (jobUid: string) => {
    window.open(`chat/${getProposalUid(proposals, jobUid)}`, '_blank');
  };

  const handleTimesheetOpen = (jobUid: string) => {
    const path = generatePath(Routes.TimesheetRoute, {
      jobUid,
    });
    navigate(path, {
      replace: true,
    });
  };

  // Table

  const myJobsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      renderCell: (params: GridRenderCellParams<JobStatus>) => (
        <JobStatusChip jobStatus={params.value} />
      ),
    },
    {
      field: 'company',
      headerName: t('dashboard.company'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      hide: isMobileView,
    },
    {
      field: 'jobTitle',
      headerName: t('dashboard.job_title'),
      flex: 1.5,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          to={`${Routes.BrowseJobsRoute}/${params.row.id}`}
          title={params.row.jobTitle}
        >
          {params.row.jobTitle}
        </Link>
      ),
    },
    {
      field: 'in_progress_at',
      headerName: 'Started',
      hide: isMobileView,
    },
    {
      field: 'completed_at',
      headerName: 'Completed',
      hide: isMobileView,
    },
    {
      field: 'rate',
      headerName: t('dashboard.my_rate'),
      sortingOrder: ['asc', 'desc'],
      hide: isMobileView,
      sortComparator: (a, b) => {
        return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('dashboard.view'),
      sortable: false,
      align: 'right',
      hide: isMobileView,
      getActions: (params) => {
        const actions = [];

        if (params.row.status === JobStatuses.IN_PROGRESS) {
          actions.push(
            <GridActionsCellItem
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => handleChatOpen(params.row.id)}
              label="Chat"
              showInMenu
            />,
          );

          if (params.row.type === JobTypes.ONGOING) {
            actions.push(
              <GridActionsCellItem
                sx={{
                  textTransform: 'capitalize',
                }}
                onClick={() => handleTimesheetOpen(params.row.id)}
                label="Edit timesheet"
                showInMenu
              />,
            );
          }
        }

        return actions;
      },
    } as GridActionsColDef,
    {
      field: 'mobileActions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 1,
      hide: !isMobileView,
      renderCell: (params: GridRenderCellParams) => (
        <div className="actions-bar">
          <Link
            className="link"
            to={`${Routes.BrowseJobsRoute}/${params.row.id}`}
            title={params.row.jobTitle}
          >
            <ChevronRightOutlinedIcon
              sx={{
                color: '#DEB900',
                cursor: 'pointer',
              }}
              className="next-button"
            />{' '}
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard-jobs">
      <h3 className="heading-text">{t('dashboard.my_jobs')}</h3>

      {jobs.length === 0 && !loading ? (
        <NoData message={t('dashboard.no_any_jobs')} />
      ) : (
        <MiniteGrid
          height={handleGridHeight(jobs?.length || 0)}
          loading={loading}
          columns={myJobsColumns}
          rows={myJobsGridData()}
          rowId={'id'}
          disableColumnFilter
          pagination
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      )}
    </div>
  );
};

export default MyJobsStudent;
