import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './GenericModal.scss';
import { LoadingButton } from '../LoadingButton';
import React from 'react';

interface Props {
  open: boolean;
  title: string;
  content?: string | JSX.Element;
  children?: JSX.Element;
  mode?: 'confirm' | 'info' | 'warning';
  cancelButtonText?: string | 'Cancel';
  confirmButtonText: string | 'OK';
  confirmButtonDisabled?: boolean | false;
  handleCancel?: () => void;
  handleConfirm: () => void;
  loadingButton?: boolean;
  isLoading?: boolean;
  customWidth?: number;
  confirmButtonTestId?: string;
}

const GenericModal = ({
  open,
  title,
  content,
  children,
  mode = 'confirm',
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  handleCancel,
  handleConfirm,
  loadingButton,
  isLoading,
  customWidth,
  confirmButtonTestId,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      className="generic-modal"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: `${customWidth || '600'}px`,
          },
        },
      }}
    >
      <DialogTitle className="content-title">
        {title}{' '}
        {!!handleCancel && (
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {content && (
          <DialogContentText className="content-text">
            {content}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {mode === 'confirm' && (
          <Button
            onClick={handleCancel}
            className="button"
            data-testid="close-modal"
          >
            {cancelButtonText}
          </Button>
        )}
        {loadingButton ? (
          <LoadingButton
            className={`button ${mode === 'warning' ? 'warning' : 'confirm'}`}
            onClick={handleConfirm}
            buttonText={confirmButtonText || 'deactivate my account'}
            isLoading={isLoading}
            isDisabled={confirmButtonDisabled}
            confirmButtonTestId={confirmButtonTestId}
          />
        ) : (
          <Button
            disabled={confirmButtonDisabled}
            onClick={handleConfirm}
            className={`button ${mode === 'warning' ? 'warning' : 'confirm'}`}
            autoFocus
            data-testid={confirmButtonTestId}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenericModal;
