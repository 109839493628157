import { FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import './ChangePasswordForm.scss';

export const ChangePasswordForm = (props: any) => {
  const { t } = useTranslation();
  const passwordRegex =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  const [state, setState] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
  });

  const isPasswordMatchRule = (
    password: string,
    rule: string,
  ): React.ReactNode | '' => {
    if (password) {
      return password.match(rule) ? (
        <CheckIcon />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      );
    }

    return '';
  };

  const handleChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    props.handlePassword(state);
  }, [state]);

  const helperText = () => {
    return (
      <span className="password-hint">
        <span className="text">
          8 characters{' '}
          {isPasswordMatchRule(
            state.confirmPassword,
            '[a-zA-Z0-9!@#$%^&*]{8,}',
          )}
        </span>
        <span className="text">
          1 special character{' '}
          {isPasswordMatchRule(state.confirmPassword, '(?=.*[!@#$%^&*])')}
        </span>
        <span className="text">
          1 capital letter{' '}
          {isPasswordMatchRule(state.confirmPassword, '(?=.*[A-Z]{1,})')}
        </span>
        <span className="text">
          1 number {isPasswordMatchRule(state.confirmPassword, '(?=.*?[0-9])')}
        </span>
        <span className="text">
          Passwords must match
          {state.newPassword.length > 0 || state.confirmPassword.length > 0 ? (
            <>
              {state.confirmPassword !== state.newPassword ? (
                <CloseIcon style={{ color: 'red' }} />
              ) : (
                <CheckIcon />
              )}
            </>
          ) : null}
        </span>
      </span>
    );
  };

  return (
    <section className="content-section change-password-form-container">
      <form className="change-password-form">
        <div className="form-inputs">
          <FormControl>
            <TextField
              type="password"
              id="newPassword"
              label={t('reset_pass.new_password')}
              variant="standard"
              name="newPassword"
              value={state.newPassword}
              onChange={handleChange}
              data-testid="newPassword"
            />
          </FormControl>

          <FormControl>
            <TextField
              type="password"
              id="confirmPassword"
              label="Confirm Password"
              variant="standard"
              name="confirmPassword"
              helperText={helperText()}
              inputProps={{ minLength: 8, pattern: passwordRegex }}
              value={state.confirmPassword}
              onChange={handleChange}
              data-testid="confirmPassword"
            />
          </FormControl>

          {!props.isCode && (
            <FormControl>
              <TextField
                type="text"
                id="code"
                label={t('reset_pass.code')}
                variant="standard"
                name="code"
                value={state.code}
                onChange={handleChange}
                data-testid="code"
              />
            </FormControl>
          )}
        </div>
      </form>
    </section>
  );
};
