import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../../utils/consts';

import ResizableTable from '../../../components/resizable-table';
import { formatDate } from '../../../utils/dates';
import {
  NoVatResponse,
  useSearchAdminNoVat,
} from '../../../data/admin-statistics';

const NoVat = () => {
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    order_by: 'created_at',
    asc: false,
  });

  const {
    data: studentsNoVatData,
    refetch,
    isLoading,
  } = useSearchAdminNoVat({ ...filters });

  const { pagination, studentsNoVat } = studentsNoVatData || {};

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'student_name',
        header: 'Student',
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminStudentsRoute}/${row.original.student_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'first_job_start_at',
        header: 'First job started',
        enableSorting: false,
      },
    ];
  }, []);

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleSortModelChange = (sortModel) => {
    setFilters({
      ...filters,
      ...sortModel,
    });
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  const gridData = (): unknown[] =>
    studentsNoVat?.map((noVatResponse: NoVatResponse) => {
      return {
        student_uid: noVatResponse.student_uid,
        student_name: noVatResponse.student_name,
        first_job_start_at: formatDate(
          noVatResponse.first_job_start_date,
          'DD/MM/YYYY',
        ),
      };
    });

  return (
    <>
      <div className="grid-title-row one-time-jobs">
        <p className="section-sub-title">
          Active students without a VAT number
        </p>
      </div>

      <div className="table--wrapper">
        <ResizableTable
          pageSize={filters.per_page + 1}
          columns={columns}
          rows={gridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default NoVat;
