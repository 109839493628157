import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { Box } from '@mui/material';

export const BackButton = ({ page }: { page: string }) => {
  const { toSignIn } = useAuthenticator();
  const { t } = useTranslation();
  useDocumentTitle(page);

  return (
    <>
      <Box sx={{ justifyContent: 'center' }} className="back-wrapper">
        <Button
          variation="link"
          size="small"
          style={{
            fontWeight: 'var(--amplify-font-weights-normal)',
          }}
          onClick={toSignIn}
        >
          {t('general.back')}
        </Button>
      </Box>
    </>
  );
};
