import { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { PasswordRequirements } from '../password-requirements';
import useDocumentTitle from '../../../utils/useDocumentTitle';

export const ForceNewPasswordFields = () => {
  const [passwords, setPasswords] = useState({
    password: null,
    confirm_password: '',
  });

  useDocumentTitle('Force New Password');

  const passwordOnChange = (e) => {
    const { value, name } = e.target;

    setPasswords((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const password = document.querySelector('[name="password"]');
    const confirm_password = document.querySelector(
      '[name="confirm_password"]',
    );

    password.addEventListener('keyup', passwordOnChange);
    confirm_password.addEventListener('keyup', passwordOnChange);

    return () => {
      password.removeEventListener('keyup', passwordOnChange);
      confirm_password.removeEventListener('keyup', passwordOnChange);
    };
  }, []);

  return (
    <>
      <Authenticator.ForceNewPassword.FormFields />
      {passwords.password !== null && <PasswordRequirements {...passwords} />}
    </>
  );
};
