import React from 'react';
import StudentAvatar from '../../../components/student-avatar';
import CompanyAvatar from '../../../components/company-avatar';

interface IProps {
  companyAvatar: string | null;
  studentAvatar: string | null;
  companyName: string;
  studentName: string;
  jobTitle: string;
  list?: boolean;
}

const UserChatInfo = ({
  companyAvatar,
  studentAvatar,
  companyName,
  studentName,
  jobTitle,
  list,
}: IProps) => {
  return (
    <div className="user-chat-info">
      <div className="avatar-list">
        <CompanyAvatar avatar={companyAvatar} className="avatar-wrapper top" />
        <StudentAvatar
          alt="avatar"
          avatar={studentAvatar}
          className="avatar-wrapper right"
        />
      </div>
      <span className={`users-name ${list ? 'list' : ''}`}>
        {companyName}, {studentName} | {jobTitle}
      </span>
    </div>
  );
};

export default UserChatInfo;
