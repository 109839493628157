import React from 'react';
import { Button } from '@mui/material';

const PinJob = ({
  onSubmit,
  isPinned,
  isLoading,
}: {
  onSubmit: () => void;
  isPinned: boolean;
  isLoading: boolean;
}) => {
  return (
    <div className="pin-job-wrapper">
      <Button
        onClick={onSubmit}
        id="pin-job-button"
        data-testid={`${isPinned ? 'unpin' : 'pin'}-admin`}
        disabled={isLoading}
      >
        {isPinned ? 'Un-pin' : 'Pin'} job
      </Button>
    </div>
  );
};

export default PinJob;
