export const information = {
  student: [
    [
      {
        text: 'Congratulations on your first job! This onboarding will walk you through everything you need to know about being a Minite freelancer. Make sure you complete this onboarding before you start working.',
      },
      {
        text: 'Beyond attitude and ambition, there is an important baseline to every High-Flyer’s success that comes with the understanding of the difference between a client and an employer. As a student freelancer, you’ve been put on a job to save a company time and add value through your ability to perform work that the company may not have the skills or knowledge for in-house. Accordingly, your impact is immense, and you’re going to be taking far more initiative and responsibility than at any other job. That’s part of the challenge, but it’s also an immense source of excitement and valuable experience! ',
      },
    ],
    [
      {
        text: 'First things first: you need to request a VAT number (free) immediately. We need your VAT number asap to avoid a delay in payment. You must have a VAT number in order to freelance via Minite.',
      },
      {
        title: 'Getting a VAT number via the OSO form',
        text: 'Every High-Flyer who starts working at Minite, must have a valid VAT number. As soon as you get matched to your first job, you need to apply for this number. You can do this by filling out a form and sending it by post to the Belastingdienst. You will receive your VAT nr in 1-2 weeks. Once you receive it, please update your details in Minite. We will then add it to your future invoices.',
      },
      {
        text: `You can find the form {{}}.`,
        link: 'https://download.belastingdienst.nl/belastingdienst/docs/opg_start_ondernem_handelsreg_on0202z9fol.pdf',
        text_link: 'here',
      },
      {
        text: `You can find a full tutorial on how to fill out the form {{}}.`,
        link: 'https://www.youtube.com/watch?v=jq-2q_TX9gM',
        text_link: 'here',
      },
      {
        text: 'Still need help? Email your filled-out VAT form to highflyers@minite.works if you want us to double-check it.',
      },
      {
        title:
          'The difference between only getting a VAT number and registering with the Chamber of Commerce',
        text: `Getting a VAT number is free, and you can do so via the {{}}. If you also decide to register yourself with the Chamber of Commerce, it costs 50 euro. This registration must be done in person. You will also attain a VAT number upon registration, so whichever option you choose, you will receive a VAT number either way.`,
        link: 'https://download.belastingdienst.nl/belastingdienst/docs/opg_start_ondernem_handelsreg_on0202z9fol.pdf',
        text_link: 'OSO form',
      },
      {
        text: `It is a good idea to register if you are expecting to become a frequent High Flyer, but whether you will need a CoC registration depends on the frequency of your work and the exact definition can only be given by the Tax Authorities. You can find more on this on the following {{}}.`,
        link: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/hoe_werkt_de_btw/voor_wie_geldt_de_btw/ondernemer#:~:text=U%20bent%20ondernemer%20als%20u,n%C3%ADet%20aanmelden%20bij%20de%20KVK',
        text_link: 'website',
      },
      {
        text: `If you want to find out more about the benefits offered by registering with the Chamber of Commerce, you can check this {{}}. Alternatively, you can book an appointment and go to them directly, for a more streamlined approach!`,
        link: 'https://ondernemersplein.kvk.nl/startersaftrek/',
        text_link: 'link',
      },
    ],
    [
      {
        text: "If you’ve got an Ongoing Job, you will also have access to a timesheet. This is where you log your worked hours. Your client has direct access to your timesheet so it's important that it's always up to date. Have your sheet completed by the last day of the month, and submit it for approval. Your client has 3 working days to approve your hours. In case of a dispute, Minite will act as a mediator.",
      },
      {
        title: 'What counts as work?',
        text: `Any work you have been given by the company, or work that you perform for the company. Be ethical, fair, and transparent about what you log. Try to log work in time blocks of no more than 2-3 hours for an easy overview.`,
      },
      {
        title: 'Make sure you hit your agreed upon hours',
        text: `Take note that you have an agreement with the company on the expected hours per month that you will be working. You are expected to meet this number. You can work more hours if the company wants you to, but always ensure you hit the minimum. Your timesheet will indicate how many hours you are expected to log in the month. For example, if you accepted a job for 10 hours a week, you are expected to log 44 hours a month.`,
      },
      {
        title: 'Minimum admin fee charged by Minite',
        text: `Minite charges a 10% admin fee on your hourly rate, or the total amount in case of a One Time Job. If you work 44 hours, you are charged 10% admin fee on 44 hours. Take note that there is a minimum admin fee of 35 hours meaning if you work less than 35 hours in a month, your admin fee will be 10% of 35 hours. Another good reason to always hit your hours! 😉`,
      },
    ],
    [
      {
        text: 'Minite handles all invoicing both on the client and High-Flyer side. That means we deal with all the financial stuff for you. As a freelancer, you need to send invoices to your clients, but as a High-Flyer, Minite arranges this for you so you don’t need to worry about the rules and regulations surrounding this. All payments are collected by Minite and thus we also handle all payouts. Note that Minite can only issue your payment if the client has paid the invoice to Minite.',
      },
      {
        text: 'Minite partners with Stripe for secure payments. We need you to complete your Stripe onboarding so that they can verify your identity. This is necessary in order to receive payouts, so please complete this at your earliest convenience. You will find the link to your Stripe onboarding on the Minite dashboard.',
      },
      {
        text: 'Payment for Ongoing Jobs happens after a company has approved and paid your hours of the previous month. Once a company has approved your monthly hours, Minite will send an invoice to the company for the worked hours (always on the 4th - or the following business day). Your client has 14 days to fulfill this invoice. We partner with Stripe to facilitate automated payments. That means that once your company has paid the invoice, your funds will be deposited into your Stripe Connect account within the next 24 hours.',
      },
      {
        text: 'Here’s a concrete example of what the process looks like. Note that the dates may vary as we work with date ranges during which invoices are sent to clients. For simplicity, in this example we use a fixed date.',
      },
      {
        boldText: '31 January:',
        text: 'hours submitted for month of January',
      },
      {
        boldText: '3 February:',
        text: 'hours approved by client for month of January',
      },
      {
        boldText: '4 February:',
        text: 'invoice sent to client for hours of January',
      },
      {
        boldText: '18 February:',
        text: 'due date invoice for hours of January',
      },
      {
        boldText: '19 February:',
        text: 'if client paid invoice, payout is made to High-Flyer',
      },
      {
        text: 'Payment for One Time Jobs happens after a company has marked your job as completed and both parties have rated and reviewed each other. Your payment will be released if your client is satisfied with your work. Same peyment terms apply as for Ongoing Jobs. In case your client gives you a rating of 3 or lower, this will open a dispute in which case Minite will act as mediator.',
      },
      {
        text: 'Make sure you keep all your invoices, as this is mandatory as a freelancer. You must keep a record of all of your invoices.',
      },
      {
        text: 'Your first payout through Stripe might take longer due to mitigation processes. You can access your stripe dashboard to see the status of your payment here: ',
        link: 'https://connect.stripe.com/express_login',
        text_link: 'https://connect.stripe.com/express_login',
      },
    ],
    [
      {
        text: 'When you start working as a student freelancer, you have to pay VAT. This happens every quarter at fixed dates. The following dates apply for quarterly returns, so be sure to fill out your tax return before the following to avoid a fine if you don’t do it in time:',
      },
      {
        text: 'Deadline April 30th',
        boldText: 'Quarter 1 (1 January-31 March): ',
      },
      {
        text: 'Deadline July 31st',
        boldText: 'Quarter 2 (1 April-30 June): ',
      },
      {
        text: 'Deadline October 31st',
        boldText: 'Quarter 3 (1 July-30 September): ',
      },
      {
        text: 'Deadline January 31st',
        boldText: 'Quarter4 (1 October-31 December): ',
      },

      {
        title: 'Value Added Tax (VAT, or BTW in Dutch)',
        text: `The Dutch government charges value-added tax (VAT) on all goods and services. Since you are a High-Flyer, you are classified as a freelancer. That means we need to charge 21% VAT to the companies you work with, who we refer to as your clients. We can only do this if you actually have a VAT number, so make sure you get one! The VAT comes on top of your rate. If your hourly rate is €20, then we charge your client €20 plus 21% VAT which is €24,20 per hour. Important to note: you cannot keep this VAT! You must pay this to the Tax and Customs Administration (Belastingdienst) every quarter. So it is charged to your client, you receive it and then you pay it to the Belastingdienst later. In reality, this means you do not gain nor lose anything when it comes to VAT. The amount of money you were given by your client is your revenue (your rate) plus money to be used for your VAT return. This amount can be found in your invoice, so you always know how much to put aside for this reason.`,
      },
      {
        text: `Have you been away from the freelancing world for a bit, or otherwise not worked for some time? Then you still have to file your quarterly VAT returns, and you just declare €0. Do this to avoid being fined by the tax authorities for not filing your tax returns!`,
      },
      {
        title: 'Income tax',
        text: `{{}} is like a first cousin. Familiar to most in the Netherlands as tax you pay on your income on an annual basis. Though a freelancer, take note that you still have to file income taxes per annum.`,
        link: 'https://business.gov.nl/running-your-business/business-taxes/filing-your-tax-returns/filing-your-income-tax-return/',
        text_link: 'Income tax return',
      },
      {
        title: 'Need help with your tax returns?',
        text: `If this all sounds a little too convoluted, or you would like an extra helping hand to handle the VAT return process, you can always hire a bookkeeper. However, if you only freelance via Minite, filing your taxes is easy peasy. We also offer 2 free webinar(s) per quarter on tax returns, where we show you exactly how to fill out what and where. If you have never filed tax returns before, it is highly recommended that you attend. You can use this {{}} to see all upcoming events.`,
        link: 'https://www.eventbrite.com/o/minite-38822970693',
        text_link: 'link',
      },
      {
        title: 'File a return',
        text: `You can submit the VAT return digitally via the {{}} (in Dutch) of Belastingdienst.nl. The Tax and Customs Administration will send you login details.`,
        link: 'https://www.belastingdienst.nl/wps/wcm/connect/nl/ondernemers/content/inloggen-voor-ondernemers',
        text_link: 'secure portal',
      },
      {
        title: 'KOR (Small Businesses Scheme)',
        text: `Read more about this scheme {{}}.`,
        link: 'https://www.kvk.nl/english/rules-and-laws/making-use-of-the-small-businesses-scheme-kor/',
        text_link: 'here',
      },
    ],
    [
      {
        text: "Freelancing is not the same as being employed. If you don't do your job well, a company can let you go easily. Thus, as a freelancer you always want to be proactive, take initiative and show your value. It all starts with the first meeting!",
      },
      {
        title: 'Tips & tricks for your first meeting',
        text: "Freelancing is not the same as being employed. If you don't do your job well, a company can let you go easily. Thus, as a freelancer you always want to be proactive, take initiative and show your value. It all starts with the first meeting!",
      },
      {
        title: '1. Align with your client’s expectations.',
        text: 'Communication matters, so clarify things ahead of time. When you have meetings with your client to go over your work and what’s up next, do your research and make sure you’re prepared for your meeting so that you can make the most efficient use possible of your time.',
      },
      {
        title: '2. Take ownership.',
        text: 'There are many exciting things that come with a successfully run project, but you’ve got to help your client out. Schedule meetings regularly and define your own goals to avoid feeling lost. Keep track of your progress and work completed by logging your hours. This way, you and your client are on the same page about your progress and performance.',
      },
      {
        title: '3. Plan ahead.',
        text: 'Have you planned your work for the week? Do you know what you’ll be doing next week, and beyond that? It’s critical to have a structure for yourself. Our best High-Flyers plan their work days and what they’ll be doing up to three weeks in advance so that they’ll never be low on work.',
      },
      {
        title: '4. Take initiative.',
        text: 'Have some extra hours left? Use them to make suggestions and come up with new action plans! The name of the High-Flyer game is proactivity and autonomy… a good High-Flyer creates work for themselves. You don’t need an extra push to start working, you push yourself. You don’t rely on an external solution, you strategize and speed up the process.',
      },
      {
        title: '5. Think in terms of opportunities, not challenges',
        text: 'Companies don’t put stock into naysayers - the people who always push back. They want a certain attitude from a High-Flyer; one that turns challenges into opportunities and is able to maintain a positive outlook around them.',
      },
    ],
    [
      {
        text: "As a freelancer you have responsibilities, but you’re not in it alone. Here's how we help you:",
      },
      {
        list: [
          {
            text: 'We handle your invoicing',
          },
          {
            text: 'We have a {{}} where you can find lots of useful info',
            text_link: 'knowledge base',
            link: 'https://www.minite.works/kb-highflyers',
          },
          {
            text: 'We give 2 free webinars per quarter on tax returns, but you need to file your own taxes',
          },
          {
            text: 'VAT tax returns needs to be filed every quarter and we remind you of this through our newsletters and on Instagram, but it’s your responsibility to file them on time.',
          },
          {
            text: "We support with VAT returns via our free webinars, but we can't advise individual situations nor assist with annual income tax. ",
          },
          {
            text: 'If you are requesting a VAT number through the OSO form we have a video walking you through it and we can doublecheck your filled out form for you.',
          },
        ],
      },
      {
        text: 'In short, we do give you the tools to tackle the freelance job, but there are also responsibilities attached to being a freelancer. You are earning a higher hourly rate than if you were directly employed by a company, and that comes with a certain level of responsibility. As such, we will do our best to support you but keep in mind that there are a few things you will need to handle yourself. After all, that’s why you are a freelancer and not an employee! ;-)',
      },
      {
        text: 'If you have any other questions please feel free to log a support case or email highflyers@minite.works. Note that we are not authorized to help nor advise on individual situations related to taxes. For all other matters we’re happy to assist wherever possible.',
      },
    ],
    [
      {
        text: 'If a company contacts you outside of Minite or offers you work, please let us know asap (within 7 days) to avoid a €15000 fine since this is a serious breach of our {{}}',
        text_link: 'Terms of Use',
        link: 'https://www.minite.works/terms-of-use/terms-of-use-students',
      },
    ],
  ],
  company: [
    [
      {
        text: 'Congratulations, you’ve tapped into the pool of student talent and found your High-Flyer! You’re on the beginning of this path to student freelancer success. When a High-Flyer first joins your company, you can make their landing smoother by preparing clear expectations, defining your goals, and planning out future communication. This quick onboarding walks you through everything you need to know to ensure a great experience.',
      },
    ],
    [
      {
        text: 'One Time Jobs do not have timesheets. If you’ve got an Ongoing Job, you will have access to a timesheet which you can find on the Job Details page. This is where your High-Flyer logs their worked hours and submits it for approval to you latest by the last day of the month. You have 3 days to approve their hours. After this, the hours will automatically be approved to avoid delay in payment to your High-Flyer. Once approved, Minite will send you an invoice for the hours. If you reject hours, this opens a dispute, in which Minite will act as mediator.',
      },
      {
        title: 'Minimum service fee charged by Minite for Ongoing Jobs',
        text: 'Minite charges a 30% Service Fee on the hourly rate in case on ongoing job. If your High-Flyer works 44 hours, you are charged 30% Service Fee on 44 hours. Take note that there is a minimum Service Fee of 35 hours meaning if your High-Flyer works less than 35 hours in a month, your Service Fee will be 30% of 35 hours. Another good reason to put your High-Flyer to work!😉',
      },
    ],
    [
      {
        text: 'Minite handles all invoicing both on the client and High-Flyer side. That means we deal with all the financial stuff for you. Minite sends an invoice on behalf of the High-Flyer to you. All payments are collected by Minite and thus we also handle all payouts. Note that Minite can only issue payment to your High-Flyer if you have fulfilled the invoice to Minite.',
      },
      {
        text: 'Minite partners with Stripe for secure payments.',
      },
      {
        text: 'For Ongoing Jobs, once the monthly hours are approved, Minite will send you an invoice. The payment terms is 14 days. Once we receive payment, we are able to pay your High-Flyer.',
      },
      {
        text: 'Here’s a concrete example of what the process looks like. Note that the dates may vary as we work with date ranges during which invoices are sent to you. For simplicity, in this example we use a fixed date.',
      },
      {
        boldText: '31 January:',
        text: 'hours submitted by High-Flyer for the month of January',
      },
      {
        boldText: '3 February:',
        text: 'deadline to approve hours by company for month of January, no action means hours are automatically approved and invoiced',
      },
      {
        boldText: '4 February:',
        text: 'invoice sent to company for hours of January',
      },
      {
        boldText: '18 February:',
        text: 'due date invoice for hours of January (14 day payment term)',
      },
      {
        text: 'After your payment the student will see the balance on their Stripe account in 24 hrs. Stripe will pay out the student according to their schedule (avg 3 days).',
      },
      {
        text: 'Note that payment can only happen after the company has fulfilled the invoice! No payment or delayed payment affects the student’s payout directly',
      },
      {
        text: 'For One Time Jobs, payment is collected in full when the job is completed. Payout for One Time Jobs happen after you marked a job as completed (via the Job Details page) and both parties have rated and reviewed each other. Your High-Flyer’s payment will be released if you are satisfied with their work. Same payment terms apply as for Ongoing Jobs.',
      },
    ],
    [
      {
        title: '1. Prep with clear expectations ',
        text: 'The race to onboarding a student freelancer is only so efficient if you have clearly set expectations from the start. We recommend first ensuring that your High-Flyer understands and is able to apply your brand’s strategy to whatever they are doing: whether that be the tone in a sales email, the house style in a marketing visual, or the ideal user experience on a webpage. You’ve already personalized your recipe for company success in hiring a High-Flyer, now you’ve just got to make sure that your new team member can replicate your brand recipe to perfection. Although all our High-Flyers are ambition-driven, it’s up to you to write the rulebook for what you expect from them.',
      },
      {
        title: '2. Define your goals',
        text: 'Here’s a concrete example of what the process looks like. Note that the dates may vary as we work with date ranges during which invoices are sent to you. For simplicity, in this example we use a fixed date.',
      },
      {
        title: '3. Plan out future communication',
        text: 'Once the page of preparation is full, it must be folded up and sent off-- this is the step in the onboarding journey where communication comes in! Reach out to your High-Flyer and schedule a first-time Zoom meeting as soon as possible so that you can discuss all of the expectations and goals mentioned above. Make sure you’ve transmitted the contact information of all main contact persons for your High-Flyer, and don’t forget to define your preferred channel(s) of communication to facilitate any future collaborations and alleviate possible confusion. With proper communication comes proper alignment!',
      },
      {
        text: 'Three steps to making the first week a success-- all based on proper preparation and precision in your directions. The more effort you put into onboarding your High-Flyer at the beginning, the less you’ll have to put in later on in the process, when our student freelancers should be putting their independent determination to work and produce results for your company without having to be reminded to do so-- an elaborate and engaging introduction will keep them in the loop for the long run, with lots of pleasant and time-saving surprises to come of that High-Flyer magic!',
      },
    ],
    [
      {
        text: 'Three steps to making the first week a success-- all based on proper preparation and precision in your directions. The more effort you put into onboarding your High-Flyer at the beginning, the less you’ll have to put in later on in the process, when our student freelancers should be putting their independent determination to work and produce results for your company without having to be reminded to do so-- an elaborate and engaging introduction will keep them in the loop for the long run, with lots of pleasant and time-saving surprises to come of that High-Flyer magic!',
      },
      {
        list: [
          {
            text: 'Check our {{}} where you can find lots of useful info',
            text_link: 'knowledge base',
            link: 'https://www.minite.works/kb-companies',
          },
          {
            text: 'Log a support case via the form in the sidebar menu',
          },
          {
            text: 'Reach out to your Account Manager. Don’t know who your AM is? Send an email to highflyers@minite.works',
          },
        ],
      },
    ],
  ],
};

export const stepsInfo = {
  student: [
    {
      label: 'onboarding.welcome',
      description: '',
    },
    {
      label: 'onboarding.get_vat',
      description: '',
    },
    {
      label: 'onboarding.timesheet',
      description: 'onboarding.track_work',
    },
    {
      label: 'onboarding.invoice',
      description: 'onboarding.get_paid',
    },
    {
      label: 'onboarding.tax_kor',
      description: 'Incl webinar',
    },
    {
      label: 'onboarding.best_practice',
      description: 'onboarding.kick_ass',
    },
    {
      label: 'Support',
      description: 'onboarding.bff',
    },
    {
      label: 'onboarding.contact',
      description: '',
    },
  ],
  company: [
    {
      label: 'onboarding.first_hf',
      description: '',
    },
    {
      label: 'Timesheet',
      description: 'onboarding.timesheet_subtitle',
    },
    {
      label: 'onboarding.invoice',
      description: 'onboarding.invoice_subtitle',
    },
    {
      label: 'onboarding.best_practice',
      description: 'onboarding.success_collab',
    },
    {
      label: 'onboarding.support',
      description: 'onboarding.support_subtitle',
    },
  ],
};
