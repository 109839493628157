export const StatusValues = [
  { id: 'draft', label: 'Draft' },
  { id: 'pending-approval', label: 'Pending approval' },
  { id: 'open', label: 'Open' },
  { id: 'matched', label: 'Matched' },
  { id: 'in-progress', label: 'In progress' },
  { id: 'interviewing', label: 'Interviewing' },
  { id: 'completed', label: 'Completed' },
  { id: 'cancelled', label: 'Cancelled' },
  { id: 'rejected', label: 'Rejected' },
];

export const StudentLevel = {
  'entry-level': 'Entry-level',
  regular: 'Standard',
};

export const JobTypes = {
  'one-time': 'One time standard',
  'one-time-premium': 'One time premium',
  ongoing: 'Ongoing',
};

export const Location = [
  {
    id: 'remote',
    label: 'Remote',
  },
  {
    id: 'office',
    label: 'On location',
  },
  {
    id: 'hybrid',
    label: 'Hybrid',
  },
];

export const StudentLevels = [
  {
    id: 'entry-level',
    label: 'Entry-level',
  },
  {
    id: 'regular',
    label: 'Regular',
  },
];
