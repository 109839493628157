import React from 'react';
import './StudentAvatar.scss';

const StudentAvatar = ({
  className,
  alt,
}: {
  avatar: string;
  className?: string;
  alt?: string;
}) => {
  const defaultImage = '/images/student-avatars/7.png';

  return (
    <div className="avatar-wrapper">
      <img
        className={`student-avatar-image ${className}`}
        src={defaultImage}
        alt={alt || 'Student Avatar'}
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
      />
    </div>
  );
};

export default StudentAvatar;
