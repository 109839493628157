import React from 'react';
import './EditProfile.scss';
import { UserData } from '../../../data/user';
import Student from './Student';
import Company from './Company';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useUserData } from '../../../stores/auth';

const Profile = () => {
  useDocumentTitle('Profile');

  const userData = useUserData((state) => state.user);
  const refetchUser = useUserData((state) => state.refetchUser);
  const user: UserData = userData as unknown as UserData;
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile',
  });
  return (
    <Grid container className="edit-profile-page">
      <Grid
        className="edit-profile-forms-container"
        sx={{
          maxWidth: 'md',
          margin: '0 auto',
          width: '100%',
        }}
      >
        <p className="edit-profile-title">{t('my_profile')}</p>

        {user?.student_uid ? (
          <Student user={user} refetchUser={refetchUser} />
        ) : (
          <Company user={user} refetchUser={refetchUser} />
        )}
      </Grid>
    </Grid>
  );
};

export default Profile;
