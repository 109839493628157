import IndustryForm from './components/IndustryForm';
import CompanyProfileForm from './components/CompanyProfileForm';
import React from 'react';

const Company = ({ user, refetchUser }) => {
  return (
    <>
      <IndustryForm user={user} refetchUser={refetchUser} />
      <CompanyProfileForm user={user} refetchUser={refetchUser} />
    </>
  );
};

export default Company;
