import React, { useState } from 'react';
import './CompanyAccountForms.scss';
import {
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UpdateUserInterface } from '../../../../../data/edit-profile';
import MiniteSnackbar from '../../../../../components/snackbar';
import { useTranslation } from 'react-i18next';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  AdminCompanyOverview,
  CognitoUserProvider,
} from '../../../../../data/admin-companies';
import { useUpdateUserProfileByAdmin } from '../../../../../data/user';
import { LoadingButton } from '../../../../../components/LoadingButton';

const schema = yup
  .object()
  .shape({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().email(),
    phone: yup
      .string()
      .max(12)
      .matches(/^\+?\d+$/)
      .nullable()
      .transform((val) => (!val && val !== 0 ? null : val)),
  })
  .required();

interface AccountFormInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

const AdminCompanyAccountForm = (props) => {
  const details = props.details as AdminCompanyOverview;
  const refetch = props.refetch;
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    details?.user?.user_uid,
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitted },
  } = useForm<AccountFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: details?.user?.first_name,
      last_name: details?.user?.last_name,
      email: details?.user?.email,
      phone: details?.user?.phone,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: AccountFormInterface) => {
    try {
      setIsLoading(true);
      await updateUserProfile({ user: data as unknown as UpdateUserInterface });
      await refetch();
      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">Account</p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <FormGroup className="form-group">
              <TextField
                className="form-group-input form-input"
                {...register('first_name')}
                name="first_name"
                type="text"
                id="first-name-input"
                variant="standard"
                label={t('first_name')}
                error={!!errors.first_name}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className="form-group-input form-input"
                type="text"
                id="last-name-input"
                {...register('last_name')}
                variant="standard"
                label={t('last_name')}
                name="last_name"
                error={!!errors.last_name}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>

            <FormGroup className="form-group">
              <TextField
                className="form-input"
                {...register('email')}
                name="email"
                type="text"
                id="email-input"
                variant="standard"
                disabled={
                  details?.user?.provider === CognitoUserProvider.Google
                }
                label="Email"
                helperText={
                  details?.user?.provider === CognitoUserProvider.Google
                    ? 'Email disabled because user signed up via google.'
                    : ''
                }
                error={!!errors.email}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: details?.user?.verified && (
                    <InputAdornment position="end">
                      <Tooltip
                        title={'Email Verified Status'}
                        placement="right"
                      >
                        <VerifiedIcon className="email-verified-icon" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className="form-input"
                {...register('phone')}
                name="phone"
                type="text"
                id="phone-input"
                variant="standard"
                label={t('phone_number')}
                inputProps={{ maxLength: 12 }}
                error={!!errors.phone}
                InputLabelProps={{ shrink: true }}
              />
            </FormGroup>
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              confirmButtonTestId="save-company-account-info-admin"
              onClick={handleSubmit(onSubmit)}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AdminCompanyAccountForm;
