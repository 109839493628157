import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ChangePasswordForm } from '../change-password/ChangePasswordForm';
import './ResetForm.scss';
import { isValidEmail } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../utils/consts';
import axios from 'axios';
import { LoadingButton } from '../LoadingButton';
import MiniteSnackbar from '../snackbar';

const ResetSubmitted = (props: { email: string }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reset_pass' });
  const passwordRegex =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  const [state, setState] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [valid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isPasswordValid = (password): boolean => {
    return password.match(passwordRegex);
  };

  const handlePasswordChange = (state: {
    newPassword: string;
    confirmPassword: string;
    code: string;
  }) => {
    setState(state);

    setValid(
      !!state.code &&
        !!state.newPassword &&
        !!state.confirmPassword &&
        isPasswordValid(state.confirmPassword) &&
        state.newPassword === state.confirmPassword,
    );
  };

  const handleChangeSubmit = () => {
    setIsLoading(true);

    Auth.forgotPasswordSubmit(props.email, state.code, state.newPassword)
      .then((res) => {
        if (res) {
          Auth.signIn({
            username: props.email,
            password: state.newPassword,
          }).catch(() => {
            setIsLoading(false);
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="success-form-container">
      <div className="greeting">
        <p>{t('reset_pass')}</p>
        <span>{t('set_new')}</span>
      </div>

      <>
        <ChangePasswordForm
          handlePassword={(event) => handlePasswordChange(event)}
        />
      </>
      <LoadingButton
        id="change-password-button"
        onClick={handleChangeSubmit}
        isDisabled={!valid}
        buttonText={t('change')}
        isLoading={isLoading}
        confirmButtonTestId="change-password"
      />
    </section>
  );
};

export const ResetForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reset_pass' });
  const [state, setState] = useState({
    email: '',
    message: '',
    error: '',
    disabled: true,
    submitted: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    if (!isValidEmail(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        disabled: true,
        error: t('valid_email'),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        disabled: false,
        error: '',
        email: e.target.value,
      }));
    }
  };

  const handleReset = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API_URL +
        `/api/v1/users/password-reset/${state.email}`,
    })
      .then(({ data }) => {
        if (data.data.changed) {
          setIsLoading(false);
          setMessage('Your password has been reset. Please check your email.');

          const time = setTimeout(() => {
            navigate(Routes.DashboardRoute);
            clearTimeout(time);
          }, 3500);
        } else {
          Auth.forgotPassword(state.email)
            .then((res2) => {
              if (res2) {
                setState((prevState) => ({
                  ...prevState,
                  disabled: false,
                  submitted: true,
                }));

                setIsLoading(false);
              }
            })
            .catch(() => {
              console.log('Error');
            });
        }
      })
      .catch(() => setIsLoading(false));
  };

  if (state.submitted) {
    return <ResetSubmitted email={state.email} />;
  }

  return (
    <section className="reset-form-container">
      <div className="greeting">
        <p>{t('reset_pass')}</p>
        <span>{t('no_worries')}</span>
      </div>

      <form className="reset-form">
        <div className="form-inputs">
          <FormControl onChange={handleChange}>
            <TextField
              type="email"
              id="email"
              label={t('email')}
              variant="standard"
              data-testid="email"
            />
          </FormControl>
        </div>
        <LoadingButton
          id="reset-button"
          onClick={handleReset}
          isDisabled={state.disabled}
          buttonText={t('send')}
          isLoading={isLoading}
          confirmButtonTestId="reset"
        />
      </form>

      <MiniteSnackbar
        severity="info"
        message={message}
        title={' '}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
      />

      <div className="back-to-login">
        <span>{t('back')}</span>
        <Link to={Routes.DashboardRoute}>
          <span className="login-text">{t('log_in')}</span>
        </Link>
      </div>
    </section>
  );
};
