import React, { useEffect, useState } from 'react';
import {
  query,
  collection,
  where,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  Firestore,
} from 'firebase/firestore';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  ConversationHeader,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  AvatarGroup,
} from '@chatscope/chat-ui-kit-react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { UserData } from '../../../data/user';
import { fetchProposalInfoByProposalUid } from '../../../data/admin';
import { CircularProgress } from '@mui/material';

const CompanyStudentChat = ({
  user,
  db,
}: {
  user: UserData;
  db: Firestore;
}) => {
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userChat, setUserChat] = useState(null);
  const [isLoading, setIslLoading] = useState(false);
  const [chatsLoading, setChatsLoading] = useState(false);

  useEffect(() => {
    /**
     * This useEffect is used to get all chats of the user
     */
    if (user) {
      const getChats = async () => {
        setChatsLoading(true);
        const qChats = query(
          collection(db, 'chats'),
          where('proposal_uid', '!=', 'admin'),
        );
        const chatsSnapshot = await getDocs(qChats);
        const chats = [];
        for (const chatDoc of chatsSnapshot.docs) {
          if (!chatDoc.exists()) {
            continue;
          } else if (chatDoc.data().messages.length === 0) {
            continue;
          }
          const userOne = chatDoc.data().users[0];
          const userTwo = chatDoc.data().users[1];
          const userOneDoc = await getDoc(doc(db, 'users', userOne));
          const userTwoDoc = await getDoc(doc(db, 'users', userTwo));
          const userOneData = userOneDoc.data();
          const userTwoData = userTwoDoc.data();
          chats.push({
            ...chatDoc.data(),
            userOne: userOneData,
            userTwo: userTwoData,
            id: chatDoc.id,
          });
        }
        const sortedChats = chats.sort(
          (a, b) =>
            b.messages[b.messages.length - 1]?.created_at.toDate() -
            a.messages[a.messages.length - 1]?.created_at.toDate(),
        );
        setChats(sortedChats);
        setChatsLoading(false);
      };
      getChats();
    }
  }, []);

  const handleChangeChatSetOtherUser = async (
    userOneId,
    userTwoId,
    proposalUid,
  ) => {
    const userOneDoc = await getDoc(doc(db, 'users', userOneId));
    const userOne = userOneDoc.data();
    const userTwoDoc = await getDoc(doc(db, 'users', userTwoId));
    const userTwo = userTwoDoc.data();

    const { proposal } = await fetchProposalInfoByProposalUid(proposalUid);

    setUserChat({
      userOne: { id: userOneDoc.id, ...userOne },
      userTwo: { id: userTwoDoc.id, ...userTwo },
      proposal,
    });
    setIslLoading(false);
  };

  const handleChangeChat = async (chat) => {
    setIslLoading(true);
    setCurrentChat(chat.id);
    onSnapshot(doc(db, 'chats', chat.id), (doc) => {
      const userOneId = doc.data().users[0];
      const userTwoId = doc.data().users[1];
      const sortedMessages = doc
        .data()
        .messages?.sort(
          (a, b) =>
            a.created_at.toDate().toString() - b.created_at.toDate().toString(),
        );
      setMessages(sortedMessages);
      handleChangeChatSetOtherUser(
        userOneId,
        userTwoId,
        doc.data().proposal_uid,
      );
    });
  };

  return (
    <div>
      <section>
        <div style={styles}>
          <MainContainer responsive style={{ height: '500px' }}>
            <Sidebar
              position="left"
              scrollable={false}
              style={{ height: '500px' }}
            >
              <Search placeholder="Search..." disabled />
              {chatsLoading ? (
                <div style={{ margin: 'auto' }}>
                  <CircularProgress />
                </div>
              ) : (
                <ConversationList>
                  {chats.map((chat) => (
                    <Conversation
                      key={chat.id}
                      name={`${chat.userOne.name}, ${chat.userTwo.name}`}
                      onClick={() => handleChangeChat(chat)}
                      active={currentChat === chat.id}
                      lastSenderName={
                        chat.messages[chat.messages.length - 1]?.sent_by ===
                        chat.userOne.id
                          ? chat.userOne.name
                          : chat.userTwo.name
                      }
                      info={chat.messages[chat.messages.length - 1]?.message}
                      lastActivityTime={chat.messages[
                        chat.messages.length - 1
                      ]?.created_at
                        .toDate()
                        .toLocaleString([], {
                          month: 'numeric',
                          day: 'numeric',
                        })}
                    >
                      <AvatarGroup size="sm">
                        <Avatar
                          src={
                            chat.userOne?.avatarUrl
                              ? chat.userOne?.avatarUrl
                              : '/images/student-avatars/7.png'
                          }
                        />
                        <Avatar
                          src={
                            chat.userTwo?.avatarUrl
                              ? chat.userTwo?.avatarUrl
                              : '/images/student-avatars/7.png'
                          }
                        />
                      </AvatarGroup>
                    </Conversation>
                  ))}
                </ConversationList>
              )}
            </Sidebar>

            {isLoading && (
              <div style={{ margin: 'auto' }}>
                <CircularProgress />
              </div>
            )}

            {currentChat && !isLoading && (
              <ChatContainer style={{ minHeight: '500px' }}>
                <ConversationHeader>
                  <AvatarGroup size="sm">
                    <Avatar src={userChat?.userOne.avatarUrl} />
                    <Avatar src={userChat?.userTwo.avatarUrl} />
                  </AvatarGroup>
                  <ConversationHeader.Content
                    userName={`${userChat?.userOne.name}, ${userChat?.userTwo.name} | ${userChat?.proposal?.job.job_title}`}
                  />
                </ConversationHeader>
                <MessageList>
                  {messages.map((message) => (
                    <Message
                      key={message.created_at.toDate().toString()}
                      model={{
                        message: message.message,
                        sentTime: message.created_at.toDate().toString(),
                        sender: message.sent_by,
                        direction:
                          message.sent_by === userChat?.userOne.id
                            ? 'outgoing'
                            : 'incoming',
                        position: 'single',
                      }}
                    >
                      <Message.Header
                        sentTime={message.created_at
                          .toDate()
                          .toLocaleString([], {
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                      />
                    </Message>
                  ))}
                </MessageList>
              </ChatContainer>
            )}
          </MainContainer>
        </div>
      </section>
    </div>
  );
};

export default CompanyStudentChat;
