import { getLanguage } from '../../utils/getLanguage';

export const initialState = { value: getLanguage() };

export function switchLanguageReducer(state = initialState, action) {
  if (action.type === 'switchLanguage') {
    return {
      ...state,
      value: action.payload,
    };
  }
  return state;
}
