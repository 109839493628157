import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Skeleton,
  TextField,
} from '@mui/material';
import './AddressForm.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Company,
  useFetchCompanies,
  useFetchCompanyByKvk,
} from '../../../../data/companies';
import {
  CompanyForm,
  initialCompanyFormState,
  useCompanyForm,
} from '../../../../stores/company-form';
import { useDebounce } from '../../../../utils/useDebounce';
import { LoadingButton } from '../../../LoadingButton';
import { useUserData } from '../../../../stores/auth';
import { Countries } from '../../../../utils/consts';
import { getCountryCode, getCountryName } from '../../../../utils/helpers';
import { postcodeValidator } from 'postcode-validator';

interface Props {
  handleNextStep: (arg?: CompanyForm) => Promise<void>;
  companies?: Company[];
}

const FormSkeleton = () => (
  <Box sx={{ width: 360 }} className="skeleton-wrapper">
    <Skeleton sx={{ height: 50 }} />
    <Skeleton sx={{ height: 50 }} animation="wave" />
    <Skeleton sx={{ height: 50 }} animation="wave" />
    <Skeleton sx={{ height: 50, width: 60 }} animation="wave" />
  </Box>
);

const AddressForm = ({ handleNextStep }: Props) => {
  const [search, setSearch] = useState('');
  const debouncedFilter = useDebounce(search, 500);
  const { data: companies = [], isLoading: companiesLoading } =
    useFetchCompanies(debouncedFilter);
  const { t } = useTranslation();
  const { companyFormState, setCompanyFormState, resetFormState } =
    useCompanyForm();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useUserData((state) => state.user);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const companyOptions = companies.map((item) => {
    return {
      label: `${item.name} ${item.city || ''} ${item.kvkNumber}`,
      id: item.name,
      kvk: item.kvkNumber,
    };
  });

  const countryOptions = Countries.map((country) => ({
    label: country.name,
    country: country.name,
    code: country.code,
  }));

  const schema = yup.object().shape({
    company: yup.string().required(),
    kvk: yup
      .string()
      .matches(/\d{8}$/)
      .required(),
    street: yup.string().required(),
    house: yup.string().required(),
    postal: yup
      .string()
      .test((value) => {
        if (!selectedCountryCode) return false;
        try {
          return postcodeValidator(value, selectedCountryCode);
        } catch (error) {
          return false;
        }
      })
      .required(),
    city: yup.string().required(),
    country: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
    control,
    setValue,
    trigger,
  } = useForm<CompanyForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      ...companyFormState,
    },
  });

  const selectedCountryName = watch('country');

  useEffect(() => {
    setSelectedCountryCode(getCountryCode(selectedCountryName));
  }, [selectedCountryName]);

  const selectedCompanyName = useWatch({ control, name: 'company' });

  const selectedCompanyKvk = companyOptions.find(
    (item) => item.id === selectedCompanyName,
  )?.kvk;
  const { data: companyData, isFetching } = useFetchCompanyByKvk(
    selectedCompanyKvk,
    !companyFormState.manual,
  );

  useEffect(() => {
    if (!companyFormState.manual) {
      setCompanyFormState({
        ...companyFormState,
        showAllFields: !!selectedCompanyKvk,
      });
    }
  }, [selectedCompanyKvk]);

  useEffect(() => {
    const {
      company,
      postal_code,
      kvk_number,
      city,
      house_number,
      street_name,
    } = userData || {};
    if (company?.company_name && (!kvk_number || !city)) {
      setCompanyFormState({
        ...companyFormState,
        manual: true,
        showAllFields: true,
      });

      setValue('company', company?.company_name);
      setValue('kvk', kvk_number);
      setValue('street', street_name);
      setValue('house', house_number);
      setValue('postal', postal_code);
      setValue('city', city);
      setValue('country', getCountryName(userData?.country));
      trigger();
    }
  }, [userData]);

  useEffect(() => {
    let formData: CompanyForm;
    if (!companyFormState.manual) {
      formData = companyData as CompanyForm;
    } else {
      formData = companyFormState;
    }

    if (companyData) {
      setValue('company', formData.company);
      setValue('kvk', formData.kvk);
      setValue('street', formData.street);
      setValue('house', formData.house);
      setValue('city', formData.city);
      setValue('postal', formData.postal);
      trigger();
    }
  }, [companyData]);

  const onSubmit = (data: CompanyForm) => {
    setIsLoading(true);
    setCompanyFormState(data);

    const { country: countryName } = data;

    const countryCode = getCountryCode(countryName);

    data.country = countryCode;

    handleNextStep(data).then(() => {
      setIsLoading(false);
    });
  };

  const handleBack = () => {
    reset(initialCompanyFormState);
    resetFormState();
    setCompanyFormState({
      ...companyFormState,
      manual: false,
      showAllFields: false,
    });
  };

  const handleManuallyInput = () => {
    reset({
      ...initialCompanyFormState,
      manual: true,
      showAllFields: true,
    });
    resetFormState();
    setCompanyFormState({
      ...initialCompanyFormState,
      manual: true,
      showAllFields: true,
    });
  };

  if (isFetching) {
    return <FormSkeleton />;
  }

  const handleSearch = (event: any) => {
    if (event && event.type === 'change') {
      setSearch(event.target.value);
    }
  };

  return (
    <>
      <p className="step-title find-your-company-title">
        {t('sign_up_company.find_company')}
      </p>
      <section className="sign-up-section">
        <form className="form">
          <div className="form-inputs">
            {!companyFormState.manual && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    loading={companiesLoading && !!debouncedFilter}
                    value={value}
                    data-testid="company-name"
                    id="company-name"
                    loadingText={
                      <div style={{ display: 'flex' }}>
                        <CircularProgress size={14} sx={{ margin: 'auto' }} />
                      </div>
                    }
                    options={companyOptions}
                    onInputChange={handleSearch}
                    onChange={(e, data: { id: string } | null) => {
                      if (data) {
                        onChange(data.id);
                      } else {
                        onChange(null);
                      }
                    }}
                    isOptionEqualToValue={(option: { id: string }, value) => {
                      return option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        id="company-name-input"
                        label={t('sign_up_company.company_name')}
                      />
                    )}
                  />
                )}
                name="company"
                control={control}
              />
            )}
            {companyFormState.manual && (
              <TextField
                {...register('company')}
                type="text"
                id="company-name-input"
                label={t('sign_up_company.company_name')}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                data-testid="company-name"
              />
            )}
            {companyFormState.showAllFields && (
              <>
                <TextField
                  {...register('kvk')}
                  type="text"
                  id="kvk-input"
                  label={t('sign_up_company.kvk_number')}
                  variant="standard"
                  name="kvk"
                  disabled={!companyFormState.manual}
                  data-testid="kvk-number-company"
                  error={!!errors.kvk}
                  inputProps={{
                    maxLength: 8,
                  }}
                  InputLabelProps={{ shrink: true }}
                />

                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      data-testid="company-country"
                      id="company-country"
                      options={countryOptions}
                      onChange={(e, data: { country: string } | null) => {
                        if (data) {
                          onChange(data.country);
                        } else {
                          onChange(null);
                        }
                      }}
                      isOptionEqualToValue={(
                        option: { country: string },
                        value,
                      ) => {
                        return option.country === value.country;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          id="company-country-input"
                          label={t('sign_up_company.company_country')}
                        />
                      )}
                    />
                  )}
                  name="country"
                  control={control}
                />

                <FormGroup className="form-group">
                  <TextField
                    {...register('street')}
                    name="street"
                    type="text"
                    id="street-input"
                    variant="standard"
                    label={t('sign_up_company.street')}
                    error={!!errors.street}
                    data-testid="street-name"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="text"
                    id="house-input"
                    {...register('house')}
                    variant="standard"
                    label={t('sign_up_company.house_number')}
                    name="house"
                    error={!!errors.house}
                    data-testid="house-number"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormGroup>

                <FormGroup className="form-group">
                  <TextField
                    {...register('city')}
                    type="text"
                    id="city-input"
                    variant="standard"
                    label={t('sign_up_company.city')}
                    name="city"
                    data-testid="city"
                    error={!!errors.city}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register('postal')}
                    type="text"
                    id="postal-input"
                    variant="standard"
                    label={t('sign_up_company.postal')}
                    name="postal"
                    data-testid="postal-code"
                    error={!!errors.postal}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormGroup>
              </>
            )}
          </div>
        </form>
      </section>

      <div className="form-buttons">
        {companyFormState.showAllFields ? (
          <Button
            variant="contained"
            className="back-button"
            onClick={handleBack}
            data-testid="auto-complete-company-info"
          >
            {t('sign_up.back')}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="back-button manual-button"
            onClick={handleManuallyInput}
            data-testid="fill-manually-company"
          >
            {t('sign_up.fill_manually')}
          </Button>
        )}

        <LoadingButton
          className="confirm-button"
          id="complete-registration-company"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          buttonText={t('sign_up.sign_up')}
          isLoading={isLoading}
          confirmButtonTestId="complete-sign-up-company"
        />
      </div>
    </>
  );
};

export default AddressForm;
