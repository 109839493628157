import React from 'react';

import { Chip, ChipPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import './ProposalStatusChip.scss';

interface Props {
  text: string;
  color: OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    ChipPropsColorOverrides
  >;
  icon: React.ReactElement;
  sx?: any;
}

const ChipWithColor = ({ text, color, icon, sx }: Props) => {
  return (
    <Chip
      label={text}
      className={''}
      variant="filled"
      icon={icon}
      color={color}
      sx={{
        width: 'auto',
        minWidth: 'auto',
        textTransform: 'capitalize',
        padding: '0.5rem',
        fontSize: '80% !important',
        ...sx,
      }}
    />
  );
};

export default ChipWithColor;
