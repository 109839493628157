import React from 'react';
import { Grid } from '@mui/material';

import Chat from './chatComponent/Chat';
import useDocumentTitle from '../../utils/useDocumentTitle';

const ChatPage = () => {
  useDocumentTitle('Minite Chat');

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{
        maxWidth: '1200px',
        width: '100%',
      }}
      className="chat-wrapper"
    >
      <Chat />
    </Grid>
  );
};

export default ChatPage;
