export const sortArrayByDate = (a, b) => {
  if (a.ended_at === null && b.ended_at === null) {
    return (new Date(b.started_at) as any) - (new Date(a.started_at) as any);
  }

  if (a.ended_at === null) {
    return -1;
  }

  if (b.ended_at === null) {
    return 1;
  }

  return (new Date(b.started_at) as any) - (new Date(a.started_at) as any);
};
