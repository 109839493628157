import React, { useEffect, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';

export const JobDrawer = (props) => {
  const [open, setOpen] = useState(props.open);
  useEffect(() => {
    setOpen(props.open);
  }, [open, props.open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };

  return (
    <SwipeableDrawer
      variant={'persistent'}
      anchor={'right'}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className={props.className}
    >
      {props.children}
    </SwipeableDrawer>
  );
};
