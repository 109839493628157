export enum StudentForm {
  EDUCATION = 'EDUCATION',
  PERSONAL = 'PERSONAL',
  EXPERIENCE = 'EXPERIENCE',
  ALL_FORM = 'ALL_FORM',
}

export const StudentAllFormAction = () => {
  return {
    type: StudentForm.ALL_FORM,
  };
};

export const StudentEducationAction = (state: any) => {
  return {
    type: StudentForm.EDUCATION,
    payload: state,
  };
};

export const StudentPersonalAction = (state: any) => {
  return {
    type: StudentForm.PERSONAL,
    payload: state,
  };
};

export const StudentExperienceAction = (state: any) => {
  return {
    type: StudentForm.EXPERIENCE,
    payload: state,
  };
};
