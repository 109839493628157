import {
  AssessmentCategory,
  useStudentFetchAssessmentCategories,
} from '../../data/assessments';
import * as React from 'react';
import { Button, Typography, Grid, Skeleton, Chip } from '@mui/material';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import './index.scss';
import { useEffect, useState } from 'react';
import MiniteSnackbar from '../../components/snackbar';
import { isMoreThanOneMonth } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../components/generic-modal/GenericModal';

const LoadingPlaceHolder = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Skeleton variant="rectangular" width={'100%'} height={30} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rectangular" width={'100%'} height={300} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rectangular" width={'100%'} height={300} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rectangular" width={'100%'} height={300} />
      </Grid>
    </Grid>
  );
};

const AssessmentCard = ({ category }: { category: AssessmentCategory }) => {
  const navigate = useNavigate();

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'assessment' });
  const shortDescription =
    category.category_description.substring(0, 50) + '...';

  const toggleDescription = () => {
    setIsFullDescription(!isFullDescription);
  };

  const startAssessment = () => {
    const path = generatePath(Routes.StudentAssessmentView, {
      assessmentCategoryType: category.type,
    });
    navigate(path);
  };

  const showMobileModal = () => {
    if (window.innerWidth < window.innerHeight) {
      setIsMobileModalOpen(true);
    } else {
      startAssessment();
    }
  };

  const canRetake = (last_attempt_at: string | null | undefined) => {
    if (!last_attempt_at) return true;
    return isMoreThanOneMonth(last_attempt_at, category.number_of_attempts);
  };

  const isAssessmentPassed = (score: string): boolean => {
    if (parseInt(score) >= 50) return true;
    return false;
  };

  const calculateDaysLeft = (): number => {
    let daysLeft = 31;
    if (category.number_of_attempts > 1) {
      daysLeft *= 6;
    }
    return (
      daysLeft -
      Math.floor(
        Math.abs(
          new Date().getTime() - new Date(category.last_attempt_at).getTime(),
        ) /
          (1000 * 60 * 60 * 24),
      )
    );
  };

  const passed = isAssessmentPassed(category.latest_assessment_score);

  const defaultButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Sofia W05 Semi Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: ' 0.4px',
    textTransform: 'uppercase',
    color: '#393A42',
  };

  return (
    <Grid item md={4} xs={12}>
      <div className={'assessment-card'}>
        <Typography variant="h6" component="div">
          {category.category_name}
        </Typography>
        <div className={'latest-assessment-score'}>
          <p>
            <b className="label">{t('last_score')}:</b>{' '}
            {category.latest_assessment_score ? (
              <Chip
                label={`${category.latest_assessment_score}%`}
                color={passed ? 'success' : 'error'}
                variant="outlined"
                sx={{
                  fontWeight: 'bold',
                }}
              />
            ) : (
              <Chip
                label={'N/A'}
                variant="outlined"
                sx={{
                  fontWeight: 'bold',
                }}
              />
            )}
          </p>
          <p className={'last_attempt_date'}>
            <b className="label">{t('last_attempted_at')}:</b>{' '}
            {category.last_attempt_at || 'N/A'}
          </p>
        </div>
        <Typography className={'assessment-description'} variant="body2">
          {isFullDescription ? category.category_description : shortDescription}
          <p>
            <Button onClick={toggleDescription} variant="outlined">
              {isFullDescription ? t('less') : t('more')}
            </Button>
          </p>
        </Typography>
        <div className="asssess-card-footer">
          <Button
            variant="contained"
            onClick={showMobileModal}
            sx={{ ...defaultButtonStyle }}
            disabled={!canRetake(category.last_attempt_at)}
          >
            {canRetake(category.last_attempt_at)
              ? t('start')
              : `WAIT ${calculateDaysLeft()} DAYS`}
          </Button>
        </div>
      </div>
      <GenericModal
        open={isMobileModalOpen}
        title={t('mobile_notice_title')}
        content={t('mobile_notice_content')}
        confirmButtonText={t('mobile_notice_confirm')}
        cancelButtonText={t('mobile_notice_cancel')}
        handleConfirm={() => {
          startAssessment();
        }}
        handleCancel={() => {
          setIsMobileModalOpen(false);
        }}
      />
    </Grid>
  );
};

export const StudentAssessments = () => {
  /**
   * This view lists all assessment categories in the database
   */
  const {
    data: assessmentCategories,
    isLoading,
    refetch,
  } = useStudentFetchAssessmentCategories();

  const location = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'assessment' });

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await refetch();
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error?.message || error?.message?.toString(),
        );
      }
    })();
  }, [location]);

  if (isLoading) {
    return <LoadingPlaceHolder />;
  }

  const assessmentCategoryCards = assessmentCategories
    ? assessmentCategories.map((category) => (
        <AssessmentCard category={category} />
      ))
    : [];

  return (
    <>
      <div className={'assessmments-title-container'}>
        <Typography variant="h5" component="div">
          {t('assessments_list_title')}
        </Typography>
      </div>

      <Grid container spacing={2}>
        {assessmentCategoryCards}
      </Grid>
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'bottom', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};
