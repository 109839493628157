import React, { useEffect, useState } from 'react';
import './Agreement.scss';
import {
  Breadcrumbs,
  Grid,
  Link as MuiLink,
  Divider,
  Skeleton,
} from '@mui/material';
import OneTime from './OneTime';
import { useNavigate, useParams } from 'react-router-dom';
import Ongoing from './Ongoing';
import {
  fetchPdfURLByProposalId,
  useFetchProposalById,
  useSignProposalByCompany,
  useSignProposalByStudent,
} from '../../data/proposals';
import { JobTypes, ProposalStatuses } from '../../data/jobs';
import { COMPANY_AVATAR_BUCKET, Routes } from '../../utils/consts';
import { UserData } from '../../data/user';
import { formatDate } from '../../utils/dates';
import GenericModal from '../../components/generic-modal/GenericModal';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useCallbackPrompt } from '../../utils/useCallbackPrompt';
import { LoadingButton } from '../../components/LoadingButton';
import MiniteSnackbar from '../../components/snackbar';
import { useUserData } from '../../stores/auth';
import { getOrCreateChatWithUserWithoutDb } from '../../utils/chat';
const LoadingPlaceHolder = () => {
  return (
    <Grid
      container
      className="agreement-view-container"
      sx={{
        maxWidth: 'md',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <br />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </Grid>
    </Grid>
  );
};
const Agreement = () => {
  useDocumentTitle('Agreement');
  const { t } = useTranslation('translation', {
    keyPrefix: 'agreement',
  });

  const { proposalId } = useParams<{
    proposalId?: string;
  }>();
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;
  const {
    data: proposalData,
    refetch,
    isLoading: isLoadingProposalData,
  } = useFetchProposalById(proposalId);
  const {
    variables,
    job,
    signed_by_student_ip,
    signed_by_company_at,
    signed_by_student_at,
    proposal_status,
  } = proposalData?.proposal || {};
  const navigate = useNavigate();
  const { mutateAsync: signProposalByStudent, isLoading } =
    useSignProposalByStudent();
  const { mutateAsync: signProposalByCompany, isLoading: isLoadingByCompany } =
    useSignProposalByCompany();
  const [modalInfo, setModalInfo] = useState({
    title: '',
    content: '',
  });

  const isStudent = user.student_uid;
  const isCompany = user.company_uid;

  let isSigned;
  if (isStudent) {
    isSigned = signed_by_student_at;
  }
  if (isCompany) {
    isSigned = signed_by_company_at;
  }

  const [isDownloading, setIsDownloading] = useState(false);
  const [errorSnack, setErrorSnack] = useState(null);
  const navigateToJobs = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  const navigateToJobsCompany = () => {
    navigate(Routes.BrowseJobsRoute + `/${job.job_uid}`);
  };

  const signAgreement = async () => {
    try {
      if (isStudent) {
        await signProposalByStudent(proposalId);
        await refetch();

        setModalInfo({
          title: t('officially_flying_high'),
          content: user.onboarding_completed_at
            ? t('you_did_it_student')
            : t('complete_mandatory_virtual_onboarding'),
        });

        try {
          const currentName =
            (isStudent ? user.first_name : user.company.company_name) ||
            user.first_name;
          const companyAvatar = proposalData?.proposal.company.avatar
            ? COMPANY_AVATAR_BUCKET + proposalData?.proposal.company.avatar
            : '/images/student-avatars/7.png';
          getOrCreateChatWithUserWithoutDb(
            user.user_uid,
            isStudent ? 'student' : 'company',
            currentName,
            user.avatar || '/images/student-avatars/7.png',
            user.email,
            proposalData?.proposal.company.user_uid,
            'company',
            proposalData?.proposal.company.company_name,
            companyAvatar,
            proposalData?.proposal.company.email,
            proposalData?.proposal.proposal_uid,
          );
        } catch (error) {
          console.error('Could not open chat', error);
        }
      }

      if (isCompany) {
        await signProposalByCompany(proposalId);
        await refetch();

        setModalInfo({
          title: t('congrats'),
          content: user.onboarding_completed_at
            ? t('retake_virtual_onboarding')
            : t('you_found_your_high_flyer'),
        });
      }
    } catch (error) {
      setErrorSnack(error.response.data.error.message);
    }
  };

  const downloadAgreement = () => {
    setIsDownloading(true);
    fetchPdfURLByProposalId(proposalId)
      .then((res) => {
        window.open(res.pdf_url, '_blank');
        setIsDownloading(false);
      })
      .catch(() => setIsDownloading(false));
  };

  const handleConfirm = () => {
    navigate(Routes.OnboardingSupportRoute, { replace: true });
  };

  const handleCancel = () => {
    setModalInfo({
      title: '',
      content: '',
    });
  };
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  useEffect(() => {
    const isSign = isStudent ? signed_by_student_at : signed_by_company_at;
    setShowDialog(!isSign);
  }, [proposalData]);

  if (isLoadingProposalData) {
    return <LoadingPlaceHolder />;
  }

  return (
    <Grid
      container
      className="agreement-view-container"
      sx={{
        maxWidth: 'md',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <div className="navigation">
          <Breadcrumbs>
            {isStudent ? (
              <MuiLink onClick={navigateToJobs}>Jobs</MuiLink>
            ) : (
              <p>Jobs</p>
            )}
            <MuiLink onClick={navigateToJobsCompany}>
              Job: {job?.job_title}
            </MuiLink>
            <p style={{ color: '#393A42' }}>Agreement</p>
          </Breadcrumbs>
        </div>
        <p className="section-title">Agreement</p>
        <Divider />
        <span className="section-description">
          This agreement is generated on{' '}
          {formatDate(job?.matched_at, 'DD/MM/YYYY')} and needs to be signed by
          both parties before starting with the job.
        </span>
        <div className="agreement-info">
          {variables &&
            (job.job_type === JobTypes.ONGOING ? (
              <Ongoing variables={variables} />
            ) : (
              <OneTime variables={variables} />
            ))}
        </div>
        <div className="buttons-wrapper">
          {signed_by_student_ip &&
          proposal_status === ProposalStatuses.Signed ? (
            <LoadingButton
              className="button confirm"
              onClick={downloadAgreement}
              buttonText="Download agreement"
              isLoading={isDownloading}
              confirmButtonTestId="download-agreement"
            />
          ) : (
            !isSigned && (
              <LoadingButton
                className="button confirm"
                onClick={signAgreement}
                buttonText={t('sign_agreement')}
                isLoading={isLoading || isLoadingByCompany}
                confirmButtonTestId="sign-agreement"
              />
            )
          )}
        </div>
        <GenericModal
          open={!!modalInfo.title}
          title={modalInfo.title}
          content={''}
          confirmButtonText="Go to onboarding"
          mode="info"
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          confirmButtonTestId="open-onboarding"
        >
          <div>
            <img
              src={`/images/gifs/${isStudent ? 'student' : 'company'}.gif`}
              alt="success"
              style={{ width: '100%', marginBottom: '5px' }}
            />
            <div className="content-text">{modalInfo.content}</div>
          </div>
        </GenericModal>
      </Grid>
      <GenericModal
        open={showPrompt as boolean}
        title={t('warning')}
        content={
          isStudent
            ? t('leave_page_without_sign_agreement_student')
            : t('leave_page_without_sign_agreement_company')
        }
        confirmButtonText={t('complete_signing')}
        cancelButtonText={t('sign_later')}
        handleConfirm={cancelNavigation as () => void}
        handleCancel={confirmNavigation as () => void}
        mode={isStudent ? 'info' : 'confirm'}
        confirmButtonTestId="sign-later"
      />
      <MiniteSnackbar
        open={errorSnack}
        title={'Oops!'}
        message={errorSnack}
        autoHideDuration={0}
        severity={'error'}
        onClose={() => setErrorSnack(null)}
      />
    </Grid>
  );
};

export default Agreement;
