import { request, ServerResponse } from '../core/request';
import { Language, Skill } from '../stores/job-form';
import { useQueries, useQuery } from '@tanstack/react-query';
import { CATEGORIES, LANGUAGES, SKILLS } from './query-keys';

// Interfaces

export interface LanguagesResponse {
  data: Language[];
}

export interface SkillsResponse {
  data: Skill[];
}

export interface Category {
  category_name: string;
  category_uid: string;
  position: number;
  code: string;
  sub_categories: SubCategory[];
}

export interface CategoriesResponse {
  data: Category[];
}

export interface SubCategory {
  sub_category_name: string;
  sub_category_uid: string;
  code: string;
}

// Languages and Skills
const fetchLanguages = async () => {
  const { data } = await request<ServerResponse<LanguagesResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/languages',
    method: 'get',
  });

  return data.data as unknown as Language[];
};

const fetchSkills = async () => {
  const { data } = await request<ServerResponse<SkillsResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/skills',
    method: 'get',
  });

  return data.data as unknown as Skill[];
};

export function useFetchSkills() {
  return useQuery([SKILLS], fetchSkills, {
    select: (data) => {
      return data;
    },
    onError: (e) => console.error(e),
  });
}

export function useFetchLangsAndSkills() {
  return useQueries({
    queries: [
      {
        queryKey: [LANGUAGES, 1],
        queryFn: fetchLanguages,
        select: (data) => {
          return data as unknown as ReadonlyArray<Language>;
        },
        onError: (e) => console.error(e),
      },
      {
        queryKey: [SKILLS, 2],
        queryFn: fetchSkills,
        select: (data) => {
          return data as ReadonlyArray<Skill>;
        },
        onError: (e) => console.error(e),
      },
    ],
  });
}

// Categories
const fetchCategories = async () => {
  const { data } = await request<ServerResponse<CategoriesResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/categories',
    method: 'get',
  });

  return data.data as unknown as Category[];
};

export function useFetchCategories() {
  return useQuery([CATEGORIES], fetchCategories, {
    select: (data) => {
      return data.map((category) => {
        return {
          category_name: category.category_name,
          category_uid: category.category_uid,
          position: category.position,
          sub_categories: category.sub_categories,
          code: category.code,
        } as Category;
      }) as Category[];
    },
    onError: (e) => console.error(e),
  });
}
