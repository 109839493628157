import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import './NotificationHeader.scss';

export const NotificationHeader = ({
  content,
  redirectUrl,
}: {
  content: string;
  redirectUrl: string;
}) => {
  return (
    <>
      <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
        <header className="notification-header">
          <div className="notification-header-container">
            {content} <ChevronRightOutlinedIcon />
          </div>
        </header>
      </a>
    </>
  );
};
