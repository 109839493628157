import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './CreateEntryModal.scss';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  open: boolean;
  handleClose: (value: any) => void;
}
export interface CreateEntryForm {
  date: string;
  hours: number;
  description: string;
}

export const initialState = {
  date: null,
  description: null,
  hours: null,
};

const schema = yup
  .object()
  .shape({
    date: yup.date().required('required'),
    hours: yup.number().required().min(0).max(24),
    description: yup.string().required().max(200),
  })
  .required();

const CreateEntryModal = ({ open, handleClose }: Props) => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const daysInMonth = new Date(year, month, 0).getDate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<CreateEntryForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialState,
    },
  });

  const onClose = () => {
    reset(initialState);
    handleClose(null);
  };

  const onSubmit = (value) => {
    handleClose(value);
    onClose();
  };

  return (
    <Dialog open={open} className="create-entry-modal">
      <DialogTitle className="content-title">
        Create entry
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form className="user-info-form">
          <FormControl>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'Day'}
                    onChange={(endDate) => {
                      onChange(endDate);
                    }}
                    minDate={new Date(`${year}-${month}-${1}`)}
                    maxDate={new Date(`${year}-${month}-${daysInMonth}`)}
                    inputFormat="DD/MM/YYYY"
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <TextField
                        type="text"
                        ref={inputRef}
                        error={Boolean(errors.date)}
                        inputProps={inputProps}
                        variant="standard"
                        id="day"
                        label={'Day'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={InputProps}
                      />
                    )}
                    {...restField}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>

          <TextField
            {...register('hours')}
            className="create-entry-field"
            inputProps={{
              min: 0,
              max: 24,
              step: 0.5,
            }}
            type="number"
            label="Hours"
            error={!!errors.hours}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            {...register('description')}
            type="text"
            inputProps={{
              max: 200,
            }}
            id="desc-input"
            error={!!errors.description}
            label="Description"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          disabled={!isValid}
          className="button agree"
          autoFocus
          data-testid="confirm-create-entry"
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEntryModal;
