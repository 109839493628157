import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getNames } from 'country-list';

import './IdentityModal.scss';
import React, { useState } from 'react';
import { LoadingButton } from '../LoadingButton';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (gender, ethnicity, nationality) => void;
  isLoading?: boolean;
}

const IdentityModal = ({ open, onClose, onSubmit, isLoading }: Props) => {
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [nationality, setNationality] = useState('');

  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const handleEthnicityChange = (event: SelectChangeEvent) => {
    setEthnicity(event.target.value as string);
  };

  const handleNationalityChange = (
    e: React.ChangeEvent,
    value: string | null,
  ) => {
    setNationality(value as string);
  };

  const handleOnSubmit = () => {
    if (gender !== '' && ethnicity !== '' && nationality !== '') {
      onSubmit(gender, ethnicity, nationality);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle className="content-title">
        Diversity and Inclusion Profile
        {!!onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          At Minite, we're passionate about equal opportunities. It's important
          that our talented pool of High-Flyers reflects people of all
          backgrounds and identities, and this data will help us to stay
          accountable. Note: this data will not be shown on your profile or to
          companies, as we remain committed to anonymous applications to ensure
          a fair process for all.
        </DialogContentText>
        <br />
        <FormControl variant="standard" fullWidth>
          <InputLabel>Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="gender-simple-select"
            value={gender}
            label="Gender"
            onChange={handleGenderChange}
            required
          >
            <MenuItem value={'m'}>Male</MenuItem>
            <MenuItem value={'f'}>Female</MenuItem>
            <MenuItem value={'nb'}>Non-binary</MenuItem>
            <MenuItem value={'tg'}>Transgender</MenuItem>
            <MenuItem value={'other'}>Other</MenuItem>
            <MenuItem value={'n/a'}>Prefer not to say</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl variant="standard" fullWidth>
          <InputLabel>Ethnicity</InputLabel>
          <Select
            labelId="ethnicity-label"
            id="ethnicity-simple-select"
            value={ethnicity}
            label="Ethnicity"
            onChange={handleEthnicityChange}
            required
          >
            <MenuItem value={'african_american'}>
              African American/Black
            </MenuItem>
            <MenuItem value={'asian'}>Asian</MenuItem>
            <MenuItem value={'caucasian'}>Caucasian/White</MenuItem>
            <MenuItem value={'hispanic'}>Hispanic/Latino</MenuItem>
            <MenuItem value={'indigenous'}>Indigenous/Native American</MenuItem>
            <MenuItem value={'middle_eastern'}>Middle Eastern</MenuItem>
            <MenuItem value={'pacific_islander'}>Pacific Islander</MenuItem>
            <MenuItem value={'other'}>Other</MenuItem>
            <MenuItem value={'n/a'}>Prefer not to say</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br />
        <Autocomplete
          id="combo-box-country"
          options={getNames()}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Nationality" />
          )}
          value={nationality}
          onChange={handleNationalityChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Do it later</Button>
        <LoadingButton
          className="button"
          variant="text"
          onClick={handleOnSubmit}
          buttonText="Submit"
          isLoading={isLoading}
          confirmButtonTestId="send-identity"
        />
      </DialogActions>
    </Dialog>
  );
};

export default IdentityModal;
