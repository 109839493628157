import React, { useState } from 'react';
import './CompanyAccountForms.scss';
import { Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateUserProfileByAdmin } from '../../../../../data/user';
import { useTranslation } from 'react-i18next';
import {
  AdminCompanyOverview,
  useUpdateCompanies,
} from '../../../../../data/admin-companies';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';

const schema = yup
  .object()
  .shape({
    invoice_email: yup.string().email().nullable(),
  })
  .required();

interface FinancialFormInterface {
  invoice_email: string;
  service_fee: number;
  direct_job_fee: number;
  direct_job_remaining_credits: number;
}

const AdminCompanyFinancialForm = (props) => {
  const details = props.details as AdminCompanyOverview;
  const refetch = props.refetch;
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    details?.user?.user_uid,
  );
  const { mutateAsync: updateCompanyInformation } = useUpdateCompanies(
    details?.company_uid,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<FinancialFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      invoice_email: details?.user?.invoice_email,
      service_fee: details?.service_fee,
      direct_job_fee: details?.direct_job_fee,
      direct_job_remaining_credits: details?.direct_job_remaining_credits,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: FinancialFormInterface) => {
    try {
      setIsLoading(true);
      const payload = {
        invoice_email: data?.invoice_email,
      };
      const companyPayload = {
        service_fee: data?.service_fee,
        direct_job_fee: data?.direct_job_fee,
        direct_job_remaining_credits: data?.direct_job_remaining_credits,
      };
      await updateUserProfile({
        user: payload,
      });
      await updateCompanyInformation({
        company: companyPayload,
      });
      reset(data);
      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">
            {t('financial_information')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <TextField
              className="form-input"
              {...register('invoice_email')}
              name="invoice_email"
              type="text"
              id="invoice_email-input"
              variant="standard"
              label={t('invoice_email')}
              error={!!errors.invoice_email}
              InputLabelProps={{ shrink: true }}
            />
          </form>

          <form className="account-form">
            <TextField
              className="form-input"
              {...register('service_fee')}
              name="service_fee"
              type="number"
              id="service_fee-input"
              variant="standard"
              label={'Service fee'}
              error={!!errors.service_fee}
              InputLabelProps={{ shrink: true }}
            />
          </form>

          <form className="account-form">
            <TextField
              className="form-input"
              {...register('direct_job_fee')}
              name="direct_job_fee"
              type="number"
              id="direct_job_fee-input"
              variant="standard"
              label={'Direct job fee'}
              error={!!errors.direct_job_fee}
              InputLabelProps={{ shrink: true }}
            />
          </form>
          <form className="account-form">
            <TextField
              className="form-input"
              {...register('direct_job_remaining_credits')}
              name="direct_job_remaining_credits"
              type="number"
              id="direct_job_remaining_credits-input"
              variant="standard"
              label={'Direct job remaining credits'}
              error={!!errors.direct_job_remaining_credits}
              InputLabelProps={{ shrink: true }}
            />
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              confirmButtonTestId="save-company-financial-info-admin"
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AdminCompanyFinancialForm;
