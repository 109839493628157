import { Grid } from '@mui/material';
import React from 'react';
import ChipWithColor from '../../job-details/components/ChipWithColor';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import PaymentsIcon from '@mui/icons-material/Payments';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Routes } from '../../../utils/consts';
import { Link } from 'react-router-dom';

const jobTypes = {
  'one-time': 'One Time',
  ongoing: 'Ongoing',
  'one-time-premium': 'One Time',
  direct: 'Direct job',
};

const jobLocations = {
  remote: 'Remote',
  office: 'Office',
  hybrid: 'Hybrid',
};

const JobRow = ({ job }) => {
  return (
    <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
      <div className="open-job-container">
        <div className="open-job-title">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminJobsRoute}/${job.id}`}
            title={job.job_title}
          >
            {job.job_title}
          </Link>
        </div>
        <div className="open-job-details">
          <div>
            <ChipWithColor
              text={job.budget}
              color={'success'}
              icon={<PaymentsIcon />}
            />
          </div>
          <div>
            <ChipWithColor
              text={`Start ${job.job_start_at}`}
              color={'warning'}
              icon={<CalendarMonthIcon />}
            />
          </div>
          <div>
            <ChipWithColor
              text={
                job.job_location === 'office'
                  ? job.office_location
                  : jobLocations[job.job_location]
              }
              color={'primary'}
              icon={<LocationOnIcon />}
            />
          </div>
        </div>
        <div className="open-job-details">
          <div>
            <ChipWithColor
              text={jobTypes[job.job_type]}
              color={'info'}
              icon={<BusinessCenterIcon />}
            />
          </div>
          <div>
            {job.languages.map((language) => {
              return (
                <ChipWithColor
                  text={language.language_name}
                  color={'default'}
                  icon={<LanguageIcon />}
                  sx={{ marginRight: '5px' }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default JobRow;
