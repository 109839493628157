import React, { useState } from 'react';
import './StartChatModal.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '../../../components/LoadingButton';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleModalSubmit: () => void;
}

const StartChatModal = ({
  open,
  handleClose,
  handleModalSubmit,
  isLoading,
}: Props) => {
  const [buttonText, setButtontext] = useState('Confirm');
  const onClose = () => {
    handleClose();
  };
  const onSubmit = () => {
    handleModalSubmit();
    setButtontext('Start chat');
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  return (
    <Dialog open={open} className="request-interview-modal">
      <DialogTitle className="content-title">
        Start a chat
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          You’re about to start a chat with this High-Flyer. You can have a
          maximum of 2 chats open at a time per open job position. If you want
          to speak to more High-Flyers, close any other outstanding chats first
          by rejecting their proposal.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          onClick={onClose}
          className="button"
          data-testid="cancle-start-chat"
        >
          Cancel
        </Button>
        <LoadingButton
          className="button agree"
          onClick={onSubmit}
          buttonText={buttonText}
          isLoading={isLoading}
          confirmButtonTestId="start-chat"
        />
      </DialogActions>
    </Dialog>
  );
};

export default StartChatModal;
