import React from 'react';
import './AdminDashboardCard.scss';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

const AdminStatisticCard = (props: {
  counter: string | number;
  text: string;
  header: string;
  link: {
    text: string;
    url: string;
  };
}) => {
  const { counter, text, header, link } = props;
  const navigate = useNavigate();

  const onLinkClick = () => {
    navigate(link?.url, { replace: true });
  };
  return (
    <div className="admin-dashboard-card">
      <div className="admin-dashboard-card-header">{header}</div>
      <div className="admin-dashboard-card-content">
        <span className="counter">{counter}</span>
        <span className="text">{text}</span>
      </div>
      <div className="admin-dashboard-card-footer">
        <Button
          variant="text"
          className="admin-dashboard-card-link"
          onClick={onLinkClick}
          endIcon={<ChevronRightIcon />}
        >
          {link?.text}
        </Button>
      </div>
    </div>
  );
};

export default AdminStatisticCard;
