import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  FormControl,
  MenuItem,
  Select,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material';
import './Header.scss';
import { Routes } from '../../utils/consts';
import MenuIcon from '@mui/icons-material/Menu';
import { PublicSidePanel, SidePanel } from '../sidepanel/SidePanel';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserType } from '../sidepanel/UserType';
import { UserData } from '../../data/user';
import ChatFloatButton from '../chat-button';
import { useUserData } from '../../stores/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '../LoadingButton';
import { Auth } from 'aws-amplify';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { switchLanguageAction } from '../../store/actions/switch-language.action';
import GenericModal from '../generic-modal/GenericModal';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirebaseConfig } from '../../utils/chat';

const firebaseConfig = getFirebaseConfig();

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
signInAnonymously(auth);

export const Header = ({ showLogoutButton }: { showLogoutButton: boolean }) => {
  const { t } = useTranslation();
  const userData = useUserData((state) => state.user);
  const user = userData as unknown as UserData;
  const [auth, isAuth] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileView = useMediaQuery('(max-width: 901px)');
  const path = location.pathname.split('/');
  const isInfo = path[path.length - 1] === 'info';
  const { authStatus } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation('translation', { keyPrefix: 'footer' });
  const language = useSelector((state: RootState) => state.language.value);
  const [lang, setLang] = useState(language);
  const dispatch = useDispatch();
  const [isOpenChangeLanguage, setIsOpenChangeLangauage] = useState(false);

  const logged_in = useUserData((state) => state.logged_in);

  const changeLanguage = () => {
    i18n.changeLanguage(lang);
    dispatch(switchLanguageAction(lang));
    setIsOpenChangeLangauage(false);
    if (!logged_in) {
      window.location.reload();
    }
  };

  const onLanguageChanges = (event) => {
    event.preventDefault();
    setLang(event.target.value);
  };

  const formControlStyle = {
    m: 1,
    minWidth: 120,
    borderRadius: '0',
  };

  const handleSignOut = async (event: any) => {
    event.preventDefault();
    localStorage.removeItem('cypressId');
    setIsLoading(true);
    await Auth.signOut();
    setIsLoading(false);
  };

  //TODO better check for unsigned user
  const checkRoleByType = (
    checkedType: UserType,
    userType: UserType,
  ): boolean => {
    if (checkedType && userType) {
      return checkedType === userType;
    }
    return false;
  };

  useEffect(() => {
    if (userData?.user_uid) {
      isAuth(true);
    } else {
      isAuth(false);
    }
  }, [userData, auth]);

  // Close drawer on navigation
  useEffect(() => {
    setDrawerOpen(false);
  }, []);

  function toggleDrawer() {
    setDrawerOpen(!isDrawerOpen);
  }

  // function toggleNotificationDrawer() {
  // }

  const defaultButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    width: 'auto',
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Sofia W05 Semi Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: ' 0.4px',
    textTransform: 'uppercase',
    color: '#393A42',
  };

  const handleOpenPostJob = () => {
    if (checkRoleByType(UserType.Student, userData?.type)) {
      navigate(Routes.BrowseJobsRoute);
      return;
    } else if (location.pathname.includes(Routes.JobPostRoute)) {
      navigate(`${Routes.DashboardRoute}?company`);
      return;
    } else {
      navigate(Routes.JobPostRoute);
      return;
    }
  };

  const handleOpenPostDirectJob = () => {
    if (checkRoleByType(UserType.Student, userData?.type)) {
      navigate(Routes.BrowseJobsRoute);
      return;
    } else if (location.pathname.includes(Routes.JobPostRoute)) {
      navigate(`${Routes.DashboardRoute}?company`);
      return;
    } else {
      navigate(Routes.JobDirectPostRoute);
      return;
    }
  };

  return (
    <>
      <header className="header">
        <div className="header-container">
          <div className="header-container-left">
            {!isInfo && (
              <div className="menu-container" onClick={toggleDrawer}>
                <MenuIcon
                  sx={{
                    color: '#393A42',
                    fontSize: 28,
                  }}
                />
              </div>
            )}

            <div className="logo-container">
              <Link
                reloadDocument={!logged_in}
                to={
                  isInfo
                    ? '#'
                    : checkRoleByType(UserType.Admin, userData?.type)
                    ? Routes.AdminDashboardRoute
                    : `${Routes.DashboardRoute}?${
                        userData?.type === 1 ? 'student' : 'company'
                      }`
                }
              >
                <img src="/images/minite-logo.png" alt={t('header.logo_alt')} />
              </Link>
            </div>
          </div>
          <div className="routes-container">
            {auth && (
              <div className="header-button-container">
                {userData?.type && !user?.manager_uid && !isInfo && (
                  <Button
                    sx={{
                      ...defaultButtonStyle,
                      backgroundColor: location.pathname.includes(
                        Routes.JobPostRoute,
                      )
                        ? '#DEB900'
                        : '#14DB87',
                    }}
                    className={`header-route-button ${
                      checkRoleByType(UserType.Student, userData?.type)
                        ? 'browse-job'
                        : ''
                    }`}
                    variant="contained"
                    onClick={handleOpenPostJob}
                    data-testid={`open-${
                      location.pathname.includes(Routes.JobPostRoute)
                        ? 'dashboard'
                        : checkRoleByType(UserType.Student, userData?.type)
                        ? 'post-job'
                        : 'brows-job'
                    }`}
                  >
                    {location.pathname.includes(Routes.JobPostRoute)
                      ? t('header.dashboard')
                      : checkRoleByType(UserType.Student, userData?.type)
                      ? t('header.browse_jobs')
                      : !isMobileView
                      ? t('header.post_new_freelance_job')
                      : t('header.post_new_freelance_job_mobile')}
                  </Button>
                )}
                {userData?.type &&
                  !user?.manager_uid &&
                  !isInfo &&
                  !location.pathname.includes(Routes.JobPostRoute) &&
                  Date.parse(userData?.created_at) >
                    Date.parse('2024-02-05 00:00:00.000000+00') &&
                  !isMobileView &&
                  checkRoleByType(UserType.Company, userData?.type) && (
                    <div style={{ marginRight: '30px' }}>
                      <Badge badgeContent={'NEW'} color="secondary">
                        <Button
                          sx={{
                            ...defaultButtonStyle,
                            backgroundColor: '#14DB87',
                            marginRight: '0px !important',
                          }}
                          className={`header-route-button`}
                          variant="contained"
                          onClick={handleOpenPostDirectJob}
                          data-testid={`open-post-job`}
                        >
                          {t('header.post_new_ft_job')}
                        </Button>
                      </Badge>
                    </div>
                  )}
                {logged_in && (
                  <ChatFloatButton
                    user_uid={userData.user_uid}
                    db={db}
                    isAdmin={checkRoleByType(UserType.Admin, userData?.type)}
                  />
                )}
              </div>
            )}
            <div className="header-icon-button-container">
              <img
                onClick={() => setIsOpenChangeLangauage(true)}
                src={
                  lang === 'nl'
                    ? '/images/netherlands.png'
                    : '/images/britain.png'
                }
              />
            </div>
            {authStatus === 'authenticated' &&
              (showLogoutButton || !isMobileView) && (
                <div className="header-icon-button-container">
                  <LoadingButton
                    isDisabled={isLoading}
                    buttonText={t('header.log_out')}
                    variant={'text'}
                    onClick={handleSignOut}
                    isLoading={isLoading}
                    type="button"
                    className="logout-button"
                    confirmButtonTestId="logout"
                  />
                </div>
              )}
          </div>
        </div>

        <SwipeableDrawer
          anchor={'left'}
          open={isDrawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {auth ? (
            <SidePanel
              id="drawer-sidepanel"
              userType={userData?.type}
              toggleDrawer={toggleDrawer}
            />
          ) : (
            <PublicSidePanel />
          )}
        </SwipeableDrawer>
      </header>
      <GenericModal
        open={isOpenChangeLanguage}
        title="Change language"
        confirmButtonText="Change language"
        handleConfirm={() => changeLanguage()}
      >
        <FormControl
          sx={
            isMobileView
              ? {
                  ...formControlStyle,
                }
              : formControlStyle
          }
          size="small"
        >
          <Select
            id="lang-switcher"
            value={lang}
            sx={{ borderRadius: '0' }}
            data-testid="lang-switcher"
            onChange={(event) => onLanguageChanges(event)}
          >
            <MenuItem value={'en'} data-testid="lagn-en">
              <img style={{ width: '20%' }} src={'/images/britain.png'} />
              &nbsp;
              {'English'}
            </MenuItem>
            <MenuItem value={'nl'} data-testid="lagn-nl">
              <img style={{ width: '20%' }} src={'/images/netherlands.png'} />
              &nbsp;
              {'Dutch'}
            </MenuItem>
          </Select>
        </FormControl>
      </GenericModal>
    </>
  );
};
