import React from 'react';
import AccountInfo from './AccountInfo';
import CompanyInfo from './CompanyInfo';
import FinancialInfo from './FinanciaInfo';

const EditProfile = ({ studentData }: { studentData: any }) => {
  const { user, company_name, kor_approved_at } = studentData || {};

  return (
    <div>
      <AccountInfo student={studentData} />
      <CompanyInfo student={{ ...user, company_name, kor_approved_at }} />
      <FinancialInfo student={{ ...user }} />
    </div>
  );
};

export default EditProfile;
