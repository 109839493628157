import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './CalendlyModal.scss';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/LoadingButton';
import { useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onScheduleCall: (inTwoHours?: boolean) => void;
}

const CalendlyModal = ({ open, onScheduleCall, onClose }: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const fullScreen = useMediaQuery('(max-width: 600px)');

  useCalendlyEventListener({
    onEventScheduled: () => {
      onScheduleCall();
      onClose();
    },
  });

  const handleRequestCallback = () => {
    setIsDisabled(true);
    onScheduleCall(true);
    onClose();
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendly',
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="calendly-modal"
      fullScreen={fullScreen}
    >
      <DialogTitle className="content-title">
        {t('have_we_met')}{' '}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          <Typography variant="body2">{t('love_speak_to_you')}</Typography>
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Request a callback within 2 hours (9:00 - 17:00 on workdays):
          </Typography>
          <LoadingButton
            onClick={handleRequestCallback}
            isLoading={isDisabled}
            isDisabled={isDisabled}
            buttonText="Request callback"
            confirmButtonTestId="request-callback-button"
          />
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Or schedule a call with us directly:
          </Typography>
        </DialogContentText>
        <InlineWidget
          url="https://calendly.com/hellominite/jobreview"
          pageSettings={{ hideEventTypeDetails: true }}
          styles={{ width: '100%', height: 600 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className="button"
          data-testid="we-already-met"
        >
          {t('we_already_met')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalendlyModal;
