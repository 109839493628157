import React from 'react';
import './AdminStatisticCard.scss';

const AdminStatisticCard = (props: {
  counter: string | number;
  text: string;
  isColumn?: boolean;
  className?: string;
}) => {
  const { counter, text, isColumn, className } = props;
  return (
    <div className="admin-statistic-card">
      <div
        className={`admin-statistic-card-content ${className} ${
          isColumn ? 'column' : ''
        }`}
      >
        <span className="counter">{counter}</span>
        <span className="text">{text}</span>
      </div>
    </div>
  );
};

export default AdminStatisticCard;
