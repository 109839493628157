import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '../../../components/LoadingButton';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { useAdminUpdateUser } from '../../../data/admin';
import MiniteSnackbar from '../../../components/snackbar';

import { isValidIBAN } from 'ibantools';

const schema = yup
  .object()
  .shape({
    iban: yup
      .string()
      .nullable()
      .test(
        'test-number', // this is used internally by yup
        'IBAN is invalid', //validation message
        (value) => isValidIBAN(value),
      ), // return true false based on validation,
  })
  .required();

interface FinancialFormInterface {
  iban: string;
}

const FinancialInfo = ({ student }: { student?: any }) => {
  const { mutateAsync: updateUserByAdmin } = useAdminUpdateUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<FinancialFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      iban: student?.iban,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        user: {
          iban: data?.iban,
        },
      };
      await updateUserByAdmin({ userId: student.user_uid, dto: payload });

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">
            {t('financial_information')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <TextField
              className="form-input"
              {...register('iban')}
              name="iban"
              type="text"
              id="iban-input"
              variant="standard"
              label="IBAN"
              error={!!errors.iban}
              InputLabelProps={{ shrink: true }}
              helperText={errors.iban?.message}
            />
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-financial-info"
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default FinancialInfo;
