import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReferStudent = ({ ref_code }: { ref_code: string }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'refer_friend' });

  return (
    <div>
      <Typography variant="body2" className="description-text">
        {t('refer_your_friends')}
      </Typography>
      <Typography variant="h6" className="sub-title">
        {t('how_referral_works')}
      </Typography>
      <ul>
        <li className="description-text item">
          {t('unique_referral_link')}{' '}
          <span className="bold-text">
            {window.location.origin}?ref={ref_code}
          </span>
        </li>
        <li className="description-text item">
          {t('referral_code')}
          <span className="bold-text code">{ref_code}</span>
          {t('manually_enter')}
        </li>
      </ul>
      <Typography variant="h6" className="sub-title">
        {t('what_i_earn')}
      </Typography>
      <ul>
        <li className="description-text item">{t('each_referral_job')}</li>
        <li className="description-text item">
          {t('win_prizes_getting_signups')}
        </li>
      </ul>{' '}
      <Typography variant="body2" className="description-text">
        {t('log_support_case')}
        <a href="mailto:highflyers@minite.works">highflyers@minite.works</a>.
      </Typography>
    </div>
  );
};

export default ReferStudent;
