import EducationForm from './components/EducationForm';
import LanguagesForm from './components/LangugesForm';
import StudentPersonalForm from './components/StudentPersonalForm';
import StudentExperienceForm from './components/StudentExperienceForm';
import React, { useEffect, useState } from 'react';
import { UserData, useFetchCurrentStudent } from '../../../data/user';
import { useFetchStudentById } from '../../../data/profile';
import DoAssessment from './components/DoAssessment';
import CantAssessment from './components/CantAssessment';
import { isMoreThanOneMonth } from '../../../utils/helpers';

const Student = ({
  user,
  refetchUser,
}: {
  user: UserData;
  refetchUser: () => Promise<unknown>;
}) => {
  const {
    data: student,
    isLoading,
    refetch: refetchStudent,
  } = useFetchCurrentStudent(true);
  const [canRetry, setCanRetry] = useState(false);

  const { refetch: refetchStudentProfile } = useFetchStudentById(
    user?.student?.student_uid,
  );

  useEffect(() => {
    if (user?.student_stats?.attention_to_detail_assessment_last_attempted_at) {
      setCanRetry(
        isMoreThanOneMonth(
          user.student_stats.attention_to_detail_assessment_last_attempted_at,
          user.student_stats.number_of_attention_to_detail_assessment_tries,
        ),
      );
    } else {
      setCanRetry(true);
    }
  }, [user]);

  const handleStudentDataRefetch = async () => {
    await refetchUser();
    await refetchStudent();
    if (student['student_uid'] === user.student.student_uid) {
      await refetchStudentProfile();
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {user.verified ? (
        <>
          {canRetry && (
            <DoAssessment loading={isLoading} alreadyVerified={true} />
          )}
        </>
      ) : canRetry ? (
        <DoAssessment loading={isLoading} alreadyVerified={false} />
      ) : (
        <CantAssessment loading={isLoading} />
      )}

      <EducationForm
        student={student}
        loading={isLoading}
        afterSubmit={handleStudentDataRefetch}
      />
      <LanguagesForm
        student={student}
        loading={isLoading}
        afterSubmit={handleStudentDataRefetch}
      />
      <StudentPersonalForm
        user={user}
        student={student}
        loading={isLoading}
        afterSubmit={handleStudentDataRefetch}
      />
      <StudentExperienceForm
        student={student}
        loading={isLoading}
        afterSubmit={handleStudentDataRefetch}
      />
    </>
  );
};

export default Student;
