import React, { useState } from 'react';
import {
  Breadcrumbs,
  Link as MuiLink,
  Tab,
  Box,
  Tabs,
  Chip,
  Alert,
  ButtonBase,
  Grid,
  Skeleton,
} from '@mui/material';

import './AdminStudentDetails.scss';
import { Routes } from '../../../utils/consts';
import { useNavigate, useParams } from 'react-router-dom';
import Overview from './Overview';
import { StudentTypes } from '../../../data/jobs';
import DoneIcon from '@mui/icons-material/Done';
import EditProfile from './EditProfile';
import { useStudentDetails } from '../../../data/admin-student-details';
import DeactivateAccount from '../../../components/deactivate-account';
import { useAdminUpdateUser } from '../../../data/admin';
import ResetPassword from '../../../components/reset-password-admin';
import DeleteUser from '../../../components/delete-user';
import MiniteSnackbar from '../../../components/snackbar';
import { UserData, useRemoveUser } from '../../../data/user';
import { useUserData } from '../../../stores/auth';
import HighlightProfile from './HighlightProfile';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `student-tab-${index}`,
    'aria-controls': `student-tabpanel-${index}`,
  };
}

const AdminStudentDetails = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const userData = useUserData((state) => state.user);

  const params = useParams<{ studentUid?: string }>();
  const { data: studentData = {}, refetch } = useStudentDetails(
    params.studentUid,
  );
  const { mutateAsync: updateUserByAdmin } = useAdminUpdateUser();
  const { mutateAsync: removeUser, isLoading } = useRemoveUser();
  const [isLoadingDeactivateStudent, setIsLoadingDeactivateStudent] =
    useState(false);
  const user: UserData = userData as unknown as UserData;

  const [errorMessage, setErrorMessage] = useState(null);

  const [highlightLoading, setHighlightLoading] = React.useState(false);

  const navigateToAdminStudents = () => {
    navigate(Routes.AdminStudentsRoute);
  };

  const studentFullName = `${studentData?.user?.first_name || ''} ${
    studentData?.user?.last_name || ''
  }`;

  const deactivateAccount = async (reason) => {
    try {
      setIsLoadingDeactivateStudent(true);
      await updateUserByAdmin({
        dto: { user: reason },
        userId: studentData?.user?.user_uid,
      });
      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoadingDeactivateStudent(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const deleteUser = async () => {
    try {
      await removeUser(studentData?.user?.user_uid);
      await navigateToAdminStudents();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };

  const handlePinJob = async () => {
    try {
      setHighlightLoading(true);
      await updateUserByAdmin({
        dto: { student: { highlighted: !studentData?.highlighted } },
        userId: studentData?.user?.user_uid,
      });
      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setHighlightLoading(false);
    }
  };

  return (
    <section>
      <Grid
        container
        className="admin-student-container"
        sx={{
          maxWidth: 'md',
          width: '100%',
        }}
      >
        <Box>
          <Breadcrumbs>
            <MuiLink onClick={navigateToAdminStudents}>Students</MuiLink>
            <p>Student: {studentFullName}</p>
          </Breadcrumbs>
          <Box sx={{ display: 'flex', marginTop: '32px' }}>
            <h1 className="student-title">Student: {studentFullName}</h1>
            {studentData.student_type === StudentTypes.EntryLevel && (
              <Chip
                className="medium-text top-chip"
                label="Entry-level"
                variant="outlined"
                color="primary"
                sx={{
                  width: 'auto',
                  height: 24,
                  padding: 0,
                  border: '1.5px solid #1976D2',
                  fontSize: '12px',
                  lineHeight: '20px',
                  backgroundColor: 'transparent',
                  color: '#1976D2',
                  fontFamily: 'Sofia W05 Medium, sans-serif',
                  margin: '-7px 12px 0px',
                }}
              />
            )}
          </Box>
          <p className="student-description">
            An overview of {studentFullName} details
          </p>

          {studentData.reevaluation_requested_at && (
            <Alert severity="info" className="toast-student">
              <span className="description">
                This student has requested a <br />
                re-evaluation for their entry level status
              </span>
              <ButtonBase data-testid="complete-reevaluation-request">
                Mark as complete <DoneIcon />
              </ButtonBase>
            </Alert>
          )}
        </Box>

        <Box sx={{ width: '100%' }}>
          <Box
            className="tabs-wrapper"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#DEB900',
                },
              }}
            >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Edit profile" {...a11yProps(1)} />
              <Tab label="edit cv" {...a11yProps(2)} />
            </Tabs>
            <div className="tabs-wrapper">
              <HighlightProfile
                onSubmit={handlePinJob}
                isHighlighted={studentData?.highlighted}
                isLoading={highlightLoading}
              />
              {isLoadingDeactivateStudent ? (
                <Skeleton sx={{ height: 50, width: 101 }} />
              ) : (
                <DeactivateAccount
                  onSubmit={deactivateAccount}
                  isDeactivate={!studentData?.user?.deactivate_reason}
                />
              )}
              <ResetPassword userUid={studentData?.user?.user_uid} />
              {!!user?.manager?.super_admin && (
                <DeleteUser deleteUser={deleteUser} isLoading={isLoading} />
              )}
            </div>
          </Box>
          <TabPanel value={value} index={0}>
            <Overview />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditProfile studentData={studentData} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            Not implemented
          </TabPanel>
        </Box>
      </Grid>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AdminStudentDetails;
