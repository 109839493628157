import React from 'react';
import './InvoiceDetails.scss';
import { Button, Grid, MenuItem, Select, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useInvoiceDetails,
  useChangeInvoiceStatus,
} from '../../../data/admin-invoice-details';
import { formatDate } from '../../../utils/dates';
import MiniteGrid from '../../../components/grid';
import { GridColDef } from '@mui/x-data-grid';
import { ItemsResponse } from '../../../data/admin-invoices';

const statuses = [
  { id: 'true', label: 'Paid' },
  { id: 'false', label: 'Not paid' },
];

const AdminInvoiceOverview = () => {
  const { id: invoiceId } = useParams<{ id?: string }>();
  const { data: invoiceDetails, refetch: refetchInvoices } =
    useInvoiceDetails(invoiceId);
  const columns: GridColDef[] = [
    {
      field: 'price_description',
      headerName: 'Description',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
    },
  ];

  const items = invoiceDetails?.invoice_items;

  const itemsGridDta = (): unknown[] =>
    items?.map((items_response: ItemsResponse) => {
      return {
        id: items_response.invoice_item_uid,
        quantity: items_response.quantity,
        price_description: items_response.description,
        price: items_response.price,
      };
    });
  const onLinkClick = () => {
    window.open(invoiceDetails.invoice_url, '_blank');
  };

  const { mutateAsync: changeInvoiceStatus, isLoading } =
    useChangeInvoiceStatus(invoiceDetails?.invoice_uid);

  const handleChangsInvoiceStatus = async (e) => {
    await changeInvoiceStatus({
      invoice: {
        invoice_paid: e.target.value,
      },
    });

    await refetchInvoices();
  };

  return (
    <Grid
      container
      className="invoice-details-page invoice-info-container"
      sx={{
        width: '100%',
        gap: 4,
      }}
    >
      <div className="section-titles-container">
        <p className="section-title">
          Invoice: {invoiceDetails?.invoice_number || 'invoice number'}
        </p>
      </div>

      <div className="invoice-details-container">
        <div className="invoice-info-section">
          <div className="invoice-info-container">
            <span className="info-label">Paid: </span>
            <span className="info-value">
              {invoiceDetails?.invoice_paid ? 'YES' : 'NO'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Paid at: </span>
            <span className="info-value">
              {formatDate(invoiceDetails?.invoice_paid_at, 'DD/MM/YYYY') || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Sent: </span>
            <span className="info-value">
              {invoiceDetails?.invoice_sent ? 'YES' : 'NO'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Sent at: </span>
            <span className="info-value">
              {formatDate(invoiceDetails?.invoice_sent_at, 'DD/MM/YYYY') || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Invoice type: </span>
            <span className="info-value">{invoiceDetails?.type}</span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Mollie payment id: </span>
            <span className="info-value">
              {invoiceDetails?.payment.mollie_payment_id || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Payment status: </span>
            <span className="info-value">
              {invoiceDetails?.payment.payment_status || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Amount: </span>
            <span className="info-value">
              {invoiceDetails?.payment.amount || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <span className="info-label">Payment description: </span>
            <span className="info-value">
              {invoiceDetails?.payment.payment_description || '-'}
            </span>
          </div>
          <div className="invoice-info-container">
            <div className="info-label">Change paid status</div>
            {isLoading ? (
              <Skeleton sx={{ height: 50, width: 284 }} />
            ) : (
              <Select
                id="status-select"
                variant="standard"
                value={invoiceDetails?.invoice_paid || false}
                onChange={handleChangsInvoiceStatus}
              >
                {statuses.map((item) => {
                  const { id, label } = item;
                  return (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </div>
        </div>
        <div className="invoice-finance-container">
          <Button
            onClick={onLinkClick}
            className="button"
            variant="contained"
            type="button"
          >
            Download invoice
          </Button>
        </div>
        {invoiceDetails?.invoice_items[0]?.invoice_item_uid !== null && (
          <section className="invoice-info-section overview-grids">
            <p className="overview-grid-title">Items</p>
            <MiniteGrid
              columns={columns}
              rows={itemsGridDta()}
              rowId={'id'}
              pagination
              pageSize={8}
              disableColumnFilter={true}
              rowCount={invoiceDetails?.invoice_items.length}
            />
          </section>
        )}
      </div>
    </Grid>
  );
};

export default AdminInvoiceOverview;
