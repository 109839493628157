import React, { useEffect, useState } from 'react';
import './AdminOpenJobs.scss';
import '../AdminGeneralStyles.scss';
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import { useFetchIndustries } from '../../../data/edit-profile';
import { JobStatuses, MyJobsListItem } from '../../../data/jobs';
import { useSearchAdminJobs } from '../../../data/admin-jobs';
import { budgetString } from '../../job-details';
import { useDebounce } from '../../../utils/useDebounce';
import { useFetchLangsAndSkills } from '../../../data/shared';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { formatDate } from '../../../utils/dates';
import JobRow from './JobRow';

const AdminOpenJobs = () => {
  const [values, setValues] = useState({
    languages: undefined,
    skills: undefined,
  });
  const [search, setSearch] = useState('');
  const { data: industries = [] } = useFetchIndustries();
  const [{ data: languages = [] }, { data: skills = [] }] =
    useFetchLangsAndSkills();

  useDocumentTitle('Admin Open Jobs');

  const [filters, setFilters] = useState({
    per_page: 100,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    job_status: [JobStatuses.OPEN],
  });

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: jobsData,
    refetch: refetchJobsDate,
    isLoading,
  } = useSearchAdminJobs({ ...filters, query: debouncedSearch });

  const { jobs } = jobsData || {};

  const industriesOptions = industries?.map((item) => ({
    label: item.interest_name,
    id: item.interest_uid,
  }));

  useEffect(() => {
    const obj = {
      languages: values.languages || [],
      skills: values.skills || [],
    };

    if (!obj.languages.length) {
      obj.languages = languages.map((item: any) => {
        return {
          label: item.language_name,
          id: item.language_uid,
        };
      });
    }

    if (!obj.skills.length) {
      obj.skills = skills.map((item: any) => {
        return {
          label: item.skill_name,
          id: item.skill_uid,
        };
      });
    }

    setValues({ ...obj });
  }, [languages, skills]);

  const myJobsGridData = (): any[] =>
    jobs?.map((job: MyJobsListItem) => {
      return {
        id: job.job_uid,
        job_title: job.job_title || 'Draft',
        budget: job.direct_job
          ? job.direct_job_compensation
            ? job.direct_job_compensation
            : '-'
          : budgetString(job.budget, job.job_type),
        job_type: job.direct_job ? 'direct' : job.job_type,
        job_start_at: job.job_start_at
          ? new Date().getTime() < new Date(job.job_start_at).getTime()
            ? formatDate(job.job_start_at, 'DD MMM')
            : 'ASAP'
          : 'ASAP',
        languages: job.languages,
        job_location: job.job_location,
        office_location: job.office_location,
      };
    });

  const myJobs = myJobsGridData();

  const refetchJobs = async () => {
    await refetchJobsDate();
  };

  useEffect(() => {
    refetchJobs();
  }, [filters]);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-jobs-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Open jobs</p>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">Open jobs</p>
          <div className="admin-table-inputs jobs-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-jobs-input"
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />

            <FiltersPanel
              values={{ ...values, industries: industriesOptions }}
              page="admin-open-jobs"
              filters={filters}
              changeFilters={(customFilters) => {
                const { page, per_page, query, asc, job_status, order_by } =
                  filters;

                setFilters({
                  page,
                  per_page,
                  query,
                  asc,
                  job_status,
                  order_by,
                  ...customFilters,
                });
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            className="table-grid-item"
            item
            lg={5}
            md={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Grid container>
              {myJobs?.map((job) => {
                return <JobRow key={job.job_uid} job={job} />;
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminOpenJobs;
