import './Assessment.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  question: string;
  text_1: string;
  text_2?: string | null;
  image?: string | null;
}

const Description = ({ question, text_1, text_2, image }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {text_2 ? (
        <div style={{ width: '100%' }}>
          <div
            style={{ fontWeight: 'bold' }}
            dangerouslySetInnerHTML={{ __html: question }}
          />
          <p>
            <strong>{t('assessment.text_1')}: </strong>
            {text_1}
          </p>
          <p>
            <strong>{t('assessment.text_2')}: </strong>
            {text_2}
          </p>
        </div>
      ) : (
        <div>
          {text_1 && <p>{text_1}</p>}
          <div
            style={{ fontWeight: 'bold' }}
            dangerouslySetInnerHTML={{ __html: question }}
          />
          {image && (
            <img
              src={image}
              alt="image for assessment"
              style={{ width: '450px', maxWidth: '85vw' }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Description;
