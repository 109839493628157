import React, { useEffect, useState } from 'react';
import {
  JobStatuses,
  MyJobsListItem,
  useFetchMyJobs,
  useFetchRecommendedJobs,
} from '../../data/jobs';
import { useSnackbar } from 'notistack';
import {
  UserData,
  getIdentityDetails,
  getStripeOnboardingLink,
  useSetIdentity,
  useSetWelcome,
} from '../../data/user';
import VerifiedIcon from '@mui/icons-material/Verified';
import './Dashboard.scss';
import ReminderCard from '../../components/reminder-card';
import { Routes } from '../../utils/consts';
import ProgressPie from '../../components/progress-pie';
import StatisticCard from '../../components/statistic-card';
import { ButtonBase, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MyJobsStudent from './components/MyJobsStudent';
import MyProposalsStudent from './components/MyProposalsStudent';
import { useFetchStudentProposals } from '../../data/proposals';
import JobsRecommended from './components/JobsRecommended';
import MyJobsCompany from './components/MyJobsCompany';
import CalendlyModal from './components/CalendlyModal';
import MyTalents from './components/MyTalents';
import { UserType } from '../../components/sidepanel/UserType';
import { generatePath } from 'react-router-dom';
import MiniteSnackbar from '../../components/snackbar';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { numberWithDec } from '../../utils/numberWithDec';
import { useUserData } from '../../stores/auth';
import ReferFriend from './components/ReferFriend';
import IdentityModal from '../../components/identity-modal/IdentityModal';
import GenericModal from '../../components/generic-modal/GenericModal';
import CompanyWelcomeModal from '../../components/company-welcome-modal/CompanyWelcomeModal';
import HighlightedProfilesDashboard from './components/HighlightedProfiles';
import MyJobsInProgressCompany from './components/MyJobsInProgressCompany';

const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const getReminders = (user: UserData, jobs: MyJobsListItem[]) => {
  const reminders = [];
  const { t } = useTranslation();
  if (user?.student_uid) {
    if (!user?.iban || !user?.postal_code) {
      reminders.push({
        key: 'iban',
        text: t('dashboard.reminders.student.iban'),
        link: Routes.FinancialSettingsRoute,
      });
    }

    if (!user?.verified) {
      reminders.push({
        key: 'verified',
        text: t('assessment.do_assessment_description'),
        link: Routes.ProfileSettingsRoute,
      });
    }

    if (!user?.vat_number && user?.student_stats?.total_jobs_in_progress) {
      reminders.push({
        key: 'vat',
        text: t('dashboard.reminders.student.vat'),
        link: Routes.FinancialSettingsRoute,
      });
    }

    if (user?.student_stats?.ongoing_jobs_in_progress?.length > 0) {
      user?.student_stats?.ongoing_jobs_in_progress.forEach((item, index) => {
        reminders.push({
          key: 'timesheet' + item?.job_uid + index,
          text: `${t('dashboard.reminders.student.timesheet')} ${
            item?.company?.company_name
          }`,
          link: generatePath(Routes.TimesheetRoute, {
            jobUid: item.job_uid,
          }),
        });
      });
    }

    reminders.push({
      key: 'contact',
      title: t('dashboard.reminders.student.contact_title'),
      text: t('dashboard.reminders.student.contact'),
      newWindowLink:
        'https://www.minite.works/terms-of-use/terms-of-use-students',
    });
  }

  reminders.push({
    key: 'reminder',
    title: 'Refer a Friend',
    text: `${t('dashboard.reminders.use_code')} ${user?.ref_code} ${t(
      'dashboard.reminders.read_more',
    )}`,
    link: generatePath(Routes.ReferFriend),
  });

  if (user?.company_uid) {
    if (!user?.company?.bio) {
      reminders.push({
        key: 'profile_incomplete',
        title: t('dashboard.reminders.company.profile_incomplete_title'),
        text: t('dashboard.reminders.company.profile_incomplete'),
        link: Routes.ProfileSettingsRoute,
      });
    }

    if (!user?.verified) {
      reminders.push({
        key: 'verified',
        title: t('dashboard.reminders.company.want_demo'),
        text: t('dashboard.reminders.company.verified'),
        link: null,
      });
    }

    user?.company_stats?.timesheets_submitted?.forEach((timesheet, index) => {
      const { student_uid, month, first_name, job_uid } = timesheet;
      reminders.push({
        key: student_uid + index,
        text: `${t('dashboard.reminders.company.timesheet')} ${
          monthNames[month]
        } ${t('dashboard.reminders.company.timesheet1')} ${first_name}`,
        link: generatePath(Routes.TimesheetStudentRoute, {
          jobUid: job_uid,
          studentUid: student_uid,
        }),
      });
    });

    const draft = jobs.find((item) => item.job_status === JobStatuses.DRAFT);

    if (draft) {
      const { job_uid } = draft;

      reminders.push({
        key: job_uid,
        text: t('dashboard.reminders.company.unfinished_job_draft'),
        link: generatePath(Routes.JobEditRoute, {
          jobUid: job_uid,
        }),
      });
    }
  }

  return reminders;
};

const getStatistic = (user: UserData) => {
  const { t } = useTranslation();
  if (user?.student_uid) {
    const studentStats = user?.student_stats;
    const studentInfo = user?.student;

    return (
      <>
        <StatisticCard
          key={'hours'}
          number={studentStats?.total_hours_worked || 0}
          text={t('dashboard.hours_worked')}
        />
        <StatisticCard
          key={'jobs'}
          number={studentStats?.total_jobs_in_progress || 0}
          text={t('dashboard.job_progress')}
        />
        <StatisticCard
          key={'rating'}
          number={numberWithDec(studentInfo?.rating)
            .toString()
            ?.replace('.', ',')}
          text={t('dashboard.rating')}
        />
        <StatisticCard
          key={'proposals'}
          number={studentStats?.total_proposals_submitted || 0}
          text={t('dashboard.proposals_submitted')}
        />
      </>
    );
  } else if (user?.company_uid) {
    const companyStatus = user?.company_stats;
    const companyInfo = user?.company;

    return (
      <>
        <StatisticCard
          key={'active_students'}
          number={companyStatus?.total_active_students || 0}
          text={t('dashboard.active_hf')}
        />
        <StatisticCard
          key={'jobs'}
          number={companyStatus?.total_jobs_in_progress || 0}
          text={t('dashboard.job_progress')}
        />
        <StatisticCard
          key={'rating'}
          number={numberWithDec(companyInfo?.rating)
            .toString()
            ?.replace('.', ',')}
          text={t('dashboard.rating')}
        />
        <StatisticCard
          key={'jobs_matched'}
          number={companyStatus?.total_signed_proposals || 0}
          text={t('dashboard.jobs_matched')}
        />
      </>
    );
  } else {
    return <></>;
  }
};

const getKnowledgeBase = (isStudent) => {
  const link = isStudent
    ? 'https://www.minite.works/kb-highflyers'
    : 'https://www.minite.works/kb-companies';

  const handleNavigation = (event) => {
    event?.preventDefault();
    window.open(link, '_blank');
  };
  const { t } = useTranslation();

  return (
    <div className="base-container" onClick={handleNavigation}>
      <div className="base-image-container">
        <img src="/images/knowledge-base.png" alt="Knowledge Base" />
      </div>

      <p className="base-title">Knowledge base</p>

      <div className="base-text-link">
        <p className="base-text">{t('dashboard.have_question')}</p>

        <ButtonBase data-testid="open-knwledge-base">
          <ArrowForwardIcon
            sx={{
              color: '#DEB900',
              cursor: 'pointer',
            }}
            className="next-button"
          />
        </ButtonBase>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const userData = useUserData((state) => state.user);
  const refetch = useUserData((state) => state.refetchUser);
  const [errorMessage, setErrorMessage] = useState(null);
  const user: UserData = userData as unknown as UserData;
  const byStudent = !!user?.student_uid;
  const [saveMessage, setSaveMessage] = useState(null);
  const { t } = useTranslation();
  const {
    data: myJobs = [],
    isLoading: loadingMyJobs,
    error: responseError,
    refetch: reloadMyJob,
    isFetched: isFetchedJobs,
  } = useFetchMyJobs();
  const {
    data: myProposals = [],
    isLoading: loadingMyProposals,
    refetch: reloadProposals,
  } = useFetchStudentProposals(byStudent, true);
  const { data: recommendedJobs = [], isLoading: loadingRecommendedJobs } =
    useFetchRecommendedJobs(byStudent);

  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [identityModalOepn, setIdentityModalOpen] = useState(false);
  const [companyWelcomeModalOpen, setCompanyWelcomeModalOpen] = useState(true);
  const { mutateAsync: setWelcome } = useSetWelcome();

  // Catch server errors
  const { enqueueSnackbar } = useSnackbar();
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const toggleCalendly = () => {
    setCalendlyModalOpen(!calendlyModalOpen);
  };

  const handleCallbackRequest = () => {
    setCalendlyModalOpen(false);
  };

  useEffect(() => {
    if (responseError) {
      const error = (responseError?.response?.data as any)?.error?.message;

      if (error !== 'User not found') {
        showError(error || 'Error');
      }
    }
  }, [responseError]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('cypressId', user.student_uid || user.company_uid);
    }
    refetch();
    reloadMyJob();

    if (byStudent) {
      reloadProposals();
    }
  }, []);

  useEffect(() => {
    const scheduled = localStorage.getItem('scheduled');

    if (scheduled) {
      setSaveMessage('“Successfully scheduled.');
      localStorage.removeItem('scheduled');
    }
  });

  useDocumentTitle('Dashboard');

  const [stripeOnBoardingLink, setStripeOnBoardingLink] = useState(null);
  // Assume student has completed onboarding

  const [stripeOnboardingRequired, setStripeOnboardingRequired] =
    useState(false);

  useEffect(() => {
    if (userData?.student_uid) {
      (async () => {
        try {
          const { url, onboarding_already_completed } =
            await getStripeOnboardingLink();
          setStripeOnBoardingLink(url);
          setStripeOnboardingRequired(!onboarding_already_completed);
        } catch (error) {
          setStripeOnboardingRequired(false);
        }
      })();

      (async () => {
        try {
          const { gender, ethnicity, nationality } = await getIdentityDetails();
          if (gender || ethnicity || nationality) {
            setIdentityModalOpen(false);
          } else {
            setIdentityModalOpen(true);
          }
        } catch {}
      })();
    }
  }, []);

  const handleCompleteOnboarding = () => {
    if (stripeOnBoardingLink) {
      window.location = stripeOnBoardingLink;
    } else {
      setErrorMessage(
        'Failed to generate onboarding link. If this issue persists please it@minite.works to resolve it.',
      );
    }
  };

  const handlePayments = () => {
    if (user?.stripe_onboarding_completed || !stripeOnboardingRequired) {
      window.location = 'https://connect.stripe.com/express_login' as any;
    } else {
      window.location = stripeOnBoardingLink;
    }
  };

  const { mutateAsync: setIdentity, isLoading: isLoadingIdentity } =
    useSetIdentity();

  const handleIdentityModalClose = () => {
    setIdentityModalOpen(false);
  };

  const handleIdentityModalSubmit = (
    gender: string,
    ethnicity: string,
    nationality: string,
  ) => {
    setIdentity({
      gender: gender,
      ethnicity: ethnicity,
      nationality: nationality,
    });
    setIdentityModalOpen(false);
  };

  return (
    <Grid
      container
      className="dashboard-container"
      sx={{
        maxWidth: 'md',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <p className="dashboard-title">
          <span className="dashboard-user-name">Hi {user?.first_name}</span>
          {user?.type === UserType?.Student && !user?.verified && (
            <span className="unverified-user-tag">Unverified</span>
          )}
          {user?.verified && <VerifiedIcon className="verified-user-icon" />}
        </p>

        <p className="dashboard-greeting">{t('dashboard.see_you_again')}</p>

        <div className="dashboard-pie-reminder-container">
          {user?.profile_completion > 0 && user?.profile_completion !== 100 && (
            <div className="dashboard-pie-container">
              <ProgressPie value={user?.profile_completion} />
            </div>
          )}
          <Grid
            className="reminders-list"
            container
            direction="row"
            wrap="nowrap"
          >
            {user?.company_uid && !user?.company.bio && (
              <ReminderCard
                title="Update your profile"
                text="Add an image and bio to you profile"
                link={Routes.ProfileSettingsRoute}
              />
            )}
            {user?.company_uid && (
              <ReminderCard
                title="Financial information"
                text="Double check your financial information"
                link={Routes.FinancialSettingsRoute}
              />
            )}

            {user?.student_uid &&
              user?.stripe_account_id &&
              !user?.stripe_onboarding_completed && (
                <ReminderCard
                  title={
                    user?.stripe_onboarding_completed ||
                    stripeOnboardingRequired
                      ? 'Payment Dashboard'
                      : 'Payments'
                  }
                  text={
                    user?.stripe_onboarding_completed ||
                    !stripeOnboardingRequired
                      ? 'Track your payments'
                      : 'Complete onboarding to receive payments'
                  }
                  callback={handlePayments}
                />
              )}

            {getReminders(user, myJobs).map((item) => {
              return (
                <ReminderCard
                  title={item?.title}
                  text={item?.text}
                  link={item?.link}
                  key={item?.key}
                  callback={
                    item?.key === 'verified' && !item?.link
                      ? toggleCalendly
                      : null
                  }
                  newWindowLink={item?.newWindowLink}
                />
              );
            })}
          </Grid>
        </div>

        {user?.company?.company_uid ? (
          <ReferFriend
            ref_code={user.ref_code}
            content={t('dashboard.happy_our_services')}
          />
        ) : user?.student_uid ? (
          <ReferFriend
            ref_code={user.ref_code}
            title={t('dashboard.refer_friend')}
            content={t('dashboard.spread_love_earn_dough')}
          />
        ) : null}
        <Grid
          container
          direction="row"
          wrap="nowrap"
          className="dashboard-statistic-cards"
        >
          {getStatistic(user)}
        </Grid>
        {user?.student_uid && (
          <section>
            <MyProposalsStudent
              reloadProposals={reloadProposals}
              proposals={myProposals}
              loading={loadingMyProposals}
            />
            <MyJobsStudent
              jobs={myJobs}
              proposals={myProposals}
              loading={loadingMyJobs}
            />
            <JobsRecommended
              jobs={recommendedJobs}
              loading={loadingRecommendedJobs}
            />
          </section>
        )}

        {user?.company?.company_uid && (
          <section>
            <MyJobsCompany
              jobs={myJobs}
              loading={loadingMyJobs}
              isFetched={isFetchedJobs}
            />
            <MyJobsInProgressCompany
              jobs={myJobs}
              loading={loadingMyJobs}
              isFetched={isFetchedJobs}
            />
            <HighlightedProfilesDashboard />
            <MyTalents />
          </section>
        )}

        <div className="dashboard-knowledge-container">
          {getKnowledgeBase(byStudent)}
        </div>
      </Grid>

      <CalendlyModal
        open={calendlyModalOpen}
        onClose={toggleCalendly}
        onScheduleCall={handleCallbackRequest}
        onCallbackRequest={handleCallbackRequest}
      />

      <MiniteSnackbar
        severity={'success'}
        message={saveMessage}
        title={'Success'}
        autoHideDuration={4000}
        open={!!saveMessage}
        onClose={() => setSaveMessage(null)}
      />

      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />

      <IdentityModal
        open={identityModalOepn}
        onClose={handleIdentityModalClose}
        onSubmit={handleIdentityModalSubmit}
        isLoading={isLoadingIdentity}
      />

      {user?.type === UserType?.Company &&
        !user.welcome_seen_at &&
        Date.parse(user?.created_at) >
          Date.parse('2024-02-05 00:00:00.000000+00') && (
          <CompanyWelcomeModal
            open={companyWelcomeModalOpen}
            onClose={() => {
              setCompanyWelcomeModalOpen(false);
              setWelcome();
            }}
          />
        )}

      {stripeOnboardingRequired && user.stripe_account_id && (
        <GenericModal
          open
          content="Please complete your Stripe onboarding, so that we can promptly invoice your client and you can be paid out!"
          confirmButtonText="Complete onboarding"
          handleConfirm={async () => {
            handleCompleteOnboarding();
          }}
          title={''}
        />
      )}
    </Grid>
  );
};

export default Dashboard;
