import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import './DeactivateAccount.scss';
import GenericModal from '../generic-modal/GenericModal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';

const REASONS = [
  { title: 'Broke agreement' },
  { title: 'HF cancelled a job' },
  { title: 'Client cancelled HF' },
  { title: 'Fake account' },
  { title: 'No-Show Verification Call' },
  { title: 'Poor attitude' },
  { title: 'Unreliable company' },
  { title: 'Company Requested' },
  { title: 'Student Requested' },
];

const DeactivateAccount = ({
  onSubmit,
  isDeactivate,
}: {
  onSubmit: (arg: { deactivate_reason: string }) => void;
  isDeactivate: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    control,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({ deactivate_reason: yup.string().required() }),
    ),
    defaultValues: {
      deactivate_reason: null,
    },
  });

  const handleShowModal = () => {
    setIsOpen(!isOpen);
    reset();
  };

  const handleConfirm = () => {
    onSubmit(getValues());
    setIsOpen(false);
  };

  const reactivateAccount = () => {
    onSubmit({ deactivate_reason: 're-activate' });
  };

  return (
    <div className="deactivation">
      <GenericModal
        open={isOpen}
        title="De-activate reason"
        content=""
        confirmButtonText="De-activate"
        cancelButtonText="Cancel"
        handleConfirm={handleConfirm}
        handleCancel={handleShowModal}
        confirmButtonDisabled={!isValid}
        mode="warning"
        confirmButtonTestId="deactivate-user"
      >
        <FormControl>
          <Controller
            name="deactivate_reason"
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup
                  {...field}
                  sx={{ marginTop: '10px' }}
                  data-testid="deactivation-reasons"
                >
                  {REASONS.map(({ title }) => (
                    <FormControlLabel
                      value={title}
                      control={<Radio />}
                      label={title}
                      key={title}
                    />
                  ))}
                </RadioGroup>
              );
            }}
          />
        </FormControl>
      </GenericModal>
      {isDeactivate ? (
        <Button
          onClick={handleShowModal}
          className="deactivate-button"
          data-testid="deactivate-account"
        >
          De-activate
        </Button>
      ) : (
        <Button
          onClick={reactivateAccount}
          className="deactivate-button"
          data-testid="reactivate-account"
        >
          Re-activate
        </Button>
      )}
    </div>
  );
};
export default DeactivateAccount;
