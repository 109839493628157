import {
  Button,
  Grid,
  Skeleton,
  Typography,
  LinearProgress,
} from '@mui/material';
import './Assessment.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  UserData,
  getPersonalityAssessmentResult,
  useUpdateAssessment,
} from '../../data/user';
import { AssessmentType, CallToAction, Routes } from '../../utils/consts';
import { defaultButtonStyle } from './Assessment';
import { useUserData } from '../../stores/auth';
import MiniteSnackbar from '../snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AssessmentQuestionDetails,
  StudentAnswerAssessmentQuestionPayload,
  studentAnswerAssessmentQuestion,
} from '../../data/assessments';
import { Question } from './Question';
import { getQuestionRespresentationObject } from './question-data';
import { extractQueryParameter } from '../../utils/helpers';

const LoadingPlaceHolder = () => {
  return (
    <div>
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
    </div>
  );
};

const PersonalityResult = () => {
  const [result, setResult] = useState(null);

  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;

  useEffect(() => {
    async function getResponse() {
      const serverResponse = await getPersonalityAssessmentResult(
        user.student_uid,
      );
      setResult(serverResponse);
    }
    getResponse();
  }, []);

  return (
    <>
      <i>This is a beta feature. More in-depth details will follow soon.</i>
      {result?.map((item) => {
        return (
          <>
            <div>
              <b>Domain: {item.title}</b>
            </div>
            <div>Short description: {item.shortDescription}</div>
            <div>
              Result: <b>{item.scoreText}</b>
            </div>
            <br />
          </>
        );
      })}
    </>
  );
};

const Result = ({
  rightAnswers,
  maxPoints,
  assessmentCategoryType,
}: {
  rightAnswers: number;
  maxPoints: number;
  assessmentCategoryType: string;
}) => {
  switch (assessmentCategoryType) {
    case AssessmentType.Personality:
      return <PersonalityResult />;
    default:
      return <>{((rightAnswers / maxPoints) * 100).toFixed(2)}</>;
  }
};

const CallToActionButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const callToAction = extractQueryParameter(location, 'callToAction');

  const handleOpenJob = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  const handleOpenAssessments = () => {
    navigate(Routes.StudentAssessments);
  };

  switch (callToAction) {
    case CallToAction.BrowseJobs:
      return (
        <Button
          sx={defaultButtonStyle}
          className={'header-route-button browse-job'}
          variant="contained"
          onClick={handleOpenJob}
          data-testid="open-jobs"
        >
          {t('header.browse_jobs')}
        </Button>
      );
    default:
      return (
        <Button
          sx={defaultButtonStyle}
          className={'header-route-button browse-job'}
          variant="contained"
          onClick={handleOpenAssessments}
          data-testid="open-jobs"
        >
          {t('assessment.go_back')}
        </Button>
      );
  }
};

const CallToActionUI = ({
  wasVerifiedInitially,
  rightAnswers,
  maxPoints,
  assessmentCategoryType,
}: {
  wasVerifiedInitially: boolean;
  rightAnswers: number;
  maxPoints: number;
  assessmentCategoryType: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const callToAction = extractQueryParameter(location, 'callToAction');

  switch (callToAction) {
    case CallToAction.BrowseJobs:
      return (
        <section className="welcome-section">
          {!wasVerifiedInitially ? (
            rightAnswers > 0 ? (
              rightAnswers / maxPoints > 0.5 ? (
                <>
                  <div className="title-container">
                    <p className="title">{t('assessment.you_did_it')}</p>
                  </div>
                  <p className="results-description">
                    {t('assessment.start_applying')}
                  </p>
                </>
              ) : (
                <>
                  <div className="title-container">
                    <p className="title">{t('assessment.you_did_it_entry')}</p>
                  </div>
                  <p className="results-description">
                    {t('assessment.start_applying_entry')}
                  </p>
                </>
              )
            ) : (
              <>
                <div className="title-container">
                  <p className="title">{t('assessment.no_pass')}</p>
                </div>
                <p className="results-description">
                  {t('assessment.try_again_later')}
                </p>
              </>
            )
          ) : (
            <div className="title-container">
              <p className="title">{t('assessment.you_did_it_entry')}</p>
            </div>
          )}
          <div>
            <p>
              {t('assessment.your_score')}:{' '}
              {((rightAnswers / maxPoints) * 100).toFixed(2)}%
            </p>
          </div>
          <CallToActionButton />
        </section>
      );
    default:
      return (
        <section className="welcome-section">
          <div className="title-container">
            <p className="title">{t('assessment.you_did_it')}</p>
          </div>
          <p className="results-description">
            {t('assessment.assessment_finished_description')}
          </p>
          <div>
            <p>
              {t('assessment.your_result')}: <br />
              <br />
              <Result
                rightAnswers={rightAnswers}
                maxPoints={maxPoints}
                assessmentCategoryType={assessmentCategoryType}
              />
            </p>
          </div>
          <CallToActionButton />
        </section>
      );
  }
};

const UnlimitedAssessment = ({
  studentTestId,
  wasVerifiedInitially,
  questions,
  assessmentCategoryType,
  switchedTabs,
  setSwitchedTabs,
}: {
  studentTestId: string;
  wasVerifiedInitially: boolean;
  questions: Array<AssessmentQuestionDetails>;
  assessmentCategoryType: string;
  switchedTabs: boolean;
  setSwitchedTabs: (state: boolean) => void;
}) => {
  const [currentQuestionIndex, setCurrentQuestion] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [time, setTime] = useState(0);

  const refetchUser = useUserData((state) => state.refetchUser);

  const { mutateAsync: updateAssessment } = useUpdateAssessment();

  const questionPool = questions.map((questionItem) => {
    return getQuestionRespresentationObject(questionItem);
  });

  const maxPoints = questionPool.length;

  const answerQuestion = async (index: number, answer: string) => {
    const question = questionPool[index];

    const payload: StudentAnswerAssessmentQuestionPayload = {
      answer,
      student_test_uuid: studentTestId,
      hasLostFocus: switchedTabs,
    };
    setSwitchedTabs(false);

    return studentAnswerAssessmentQuestion(
      question.assessment_question_uid,
      payload,
    );
  };

  const updateProgress = (finished: boolean) => {
    const params = {
      student_test_uuid: studentTestId,
      started: true,
      finished: finished,
      finished_at: finished ? new Date().toISOString() : null,
      seconds: time,
      max_points: maxPoints,
    };
    updateAssessment(params);
  };

  const handleFinished = (finished: boolean) => {
    setComplete(true);
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          student_test_uuid: studentTestId,
          started: true,
          finished,
          finished_at: new Date().toISOString(),
          seconds: time,
          max_points: maxPoints,
        };
        await updateAssessment(params);
        await refetchUser();
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error.message || error.message.toString(),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const handleTestSubmit = async (value: string) => {
    console.log('Called twice');
    if (currentQuestionIndex !== questionPool.length) {
      // Don't await just do it in the background

      answerQuestion(currentQuestionIndex, value)
        .then((data) => {
          const { correct } = data;
          if (correct) {
            setRightAnswers(rightAnswers + 1);
          }
        })
        .catch((error) => {
          setErrorMessage(
            error.response?.data?.error?.message || error?.message?.toString(),
          );
        });
      setCurrentQuestion(currentQuestionIndex + 1);
    } else {
      try {
        const { correct } = await answerQuestion(currentQuestionIndex, value);

        if (correct) {
          setRightAnswers(rightAnswers + 1);
        }
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error?.message || error?.message?.toString(),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (currentQuestionIndex === questionPool.length) {
      handleFinished(true);
    } else {
      updateProgress(false);
    }
  }, [currentQuestionIndex]);

  const progress = ((currentQuestionIndex + 1) / questionPool.length) * 100;

  return (
    <>
      {!complete ? (
        <Grid
          container
          spacing={2}
          className="sample-container unselectable"
          sx={{
            padding: '20px',
          }}
        >
          <Grid item md={12} xs={12}>
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item md={10.5} xs={6}>
                  <Typography variant="h2" component="div">
                    {currentQuestionIndex + 1} / {questionPool.length}
                  </Typography>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            {questionPool.map((question, index) => {
              if (index === currentQuestionIndex) {
                return (
                  <Question
                    question={question}
                    handleTestSubmit={handleTestSubmit}
                    submitButtonText={
                      currentQuestionIndex === questionPool.length - 1
                        ? 'Submit'
                        : 'Next'
                    }
                  />
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <LoadingPlaceHolder />
          ) : (
            <CallToActionUI
              wasVerifiedInitially={wasVerifiedInitially}
              rightAnswers={rightAnswers}
              maxPoints={maxPoints}
              assessmentCategoryType={assessmentCategoryType}
            />
          )}
        </>
      )}
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default UnlimitedAssessment;
