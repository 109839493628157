import { AxiosError } from 'axios';
import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { REVIEWS } from './query-keys';
import { Search } from 'history';

export const fetchReviewsByUserType = async (
  uid: string,
  userType: string,
): Promise<ReviewsProps> => {
  const { data } = await request<ServerResponse<ReviewsProps>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/reviews/${userType}/${uid}`,
    method: 'get',
  });

  return data.data;
};

export function useFetchReviewsByUserType(uid: string, userType: string) {
  return useQuery(
    [uid, userType],
    () => fetchReviewsByUserType(uid, userType),
    {
      onError: (e) => console.error(e),
    },
  );
}

const fetchReviews = async (body?: ReviewsSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/my-reviews`,
    method: 'post',
    data: body,
  });

  return data.data as unknown as ReviewsSearchResponse;
};

export const useFetchReviews = (body: ReviewsSearchRequest) => {
  return useQuery([REVIEWS, body], () => fetchReviews(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface ReviewsSearchRequest {
  page?: number;
  per_page?: number;
  type: string;
}

export interface ReviewsSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  reviews: ReviewsResponse[];
}

export interface ReviewsResponse {
  review_uid: string;
  user_uid: string;
  created_at: string;

  rating: string;
  description: string;

  job_uid: string;
  job_title: string;

  sender: string;
  receiver: string;

  type: string;
}

export interface ReviewProps {
  description: string;
  rating: string;
  created_at: string;
  company?: { company_name: string };
  student?: { first_name: string };
}

interface ReviewsProps {
  reviews: ReviewProps[];
}
