import { Button } from '@mui/material';
import GenericModal from '../../../components/generic-modal/GenericModal';
import React from 'react';
import { TimesheetStatus, TimesheetStatuses } from '../../../data/timesheets';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  content: string;
  timesheetStatus: TimesheetStatus | string;
  onToggle: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const ApproveConfirmation = ({
  open,
  content,
  timesheetStatus,
  onToggle,
  onConfirm,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  return (
    <>
      {timesheetStatus === TimesheetStatuses.Submitted && (
        <Button
          className="approve-month-button"
          variant="contained"
          onClick={onToggle}
          data-testid="approve-month"
        >
          {t('approve_month')}
        </Button>
      )}

      <GenericModal
        open={open}
        title={t('approve_month')}
        mode="confirm"
        content={content}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={onToggle}
        handleConfirm={onConfirm}
        isLoading={isLoading}
        loadingButton
        confirmButtonTestId="confirm-approve-month"
      />
    </>
  );
};

export default ApproveConfirmation;
