import { request, ServerResponse } from '../core/request';
import { useMutation } from '@tanstack/react-query';

/*
{
  "users": {
    "user_uid": {
      "role": "admin",
      "chats": [
        "chat_uid"
      ]
    }
  },
  "chats": {
    "chat_uid": {
      "members": [
        "user_uid",
        "user_uid"
      ],
      "messages": [
        {
          "sent_by": "user_uid",
          "created_at": "TIMESTAMP",
          "message": "TEXT"
        },
        {
          "sent_by": "user_uid",
          "created_at": "TIMESTAMP",
          "message": "TEXT"
        }
      ]
    }
  }
}
*/

export interface FireBaseUsers {
  role: string;
  chats: string[];
}

export interface FireBaseChats {
  members: string[]; // user_uid
  messages: FireBaseMessages[];
  proposal_uid: string;
}

export interface FireBaseMessages {
  sent_by: string; // user_uid
  created_at: string; // timestamp
  message: string;
}

const uploadAttachment = async (payload: {
  filename: string;
  contentType: string;
  for_user_uid?: string;
  for_chat_id?: string;
}) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me/upload-attachment`,
    method: 'POST',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useUploadAttachment = () => {
  return useMutation(
    (payload: {
      filename: string;
      contentType: string;
      for_user_uid: string;
      for_chat_id: string;
    }) => uploadAttachment(payload),
    {
      onError: (e) => console.error(e),
    },
  );
};
