import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './CalendlyModal.scss';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onScheduleCall: () => void;
  onCallbackRequest?: () => void;
}

const CalendlyModal = ({ open, onScheduleCall, onClose }: Props) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      onScheduleCall();
      onClose();
    },
  });

  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  return (
    <Dialog open={open} onClose={onClose} className="calendly-modal">
      <DialogTitle className="content-title">
        {t('schedule_free_demo')}{' '}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          <Typography variant="body2">{t('love_to_speak')}</Typography>
        </DialogContentText>
        <InlineWidget
          url="https://calendly.com/hellominite/demo"
          pageSettings={{ hideEventTypeDetails: true }}
          styles={{ width: '100%', height: 600 }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CalendlyModal;
