import { SelectField } from '@aws-amplify/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  options: string[];
  isError: boolean;
  howFound: string;
  setHowFound: (val: string) => void;
}

export const HowFoundField = ({
  options,
  isError,
  howFound,
  setHowFound,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <SelectField
      label={t('sign_up_student.how_found')}
      name="custom:how_did_you_find_us"
      key="custom:how_did_you_find_us"
      isRequired={true}
      value={howFound}
      hasError={isError && !howFound}
      onChange={(e) => setHowFound(e.target.value)}
      data-testid="how_did_you_find_us_sign_up"
    >
      <option value={''}>Choose</option>
      {options.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      ))}
    </SelectField>
  );
};
