import React, { useState } from 'react';
import './ProposalConfirmationModal.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './CancelJobModal.scss';
import { LoadingButton } from '../../../components/LoadingButton';

interface Props {
  open: boolean;
  action: 'retract' | 'reject' | 'invite';
  content?: string;
  handleClose: () => void;
  handleProposalAction: (reason: string) => void;
  isLoading?: boolean;
  askReason?: boolean;
}

const ProposalConfirmationModal = ({
  open,
  content,
  handleClose,
  handleProposalAction,
  isLoading,
  askReason = false,
}: Props) => {
  const [reason, setReason] = useState('');
  const [isOther, setIsOther] = useState(false);

  const handleHandleClose = () => {
    setReason('');
    handleClose();
  };

  const handleSelect = (reason: string) => {
    setIsOther(false);
    setReason(reason);
  };

  return (
    <Dialog
      open={open}
      onClose={handleHandleClose}
      className="retract-proposal-modal"
    >
      <DialogTitle className="content-title">
        Are you sure?
        <IconButton onClick={handleHandleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          {content || (
            <span>
              Retracting your proposal means withdrawing from this job entirely.
              This action cannot be undone. Once you retract your proposal, you{' '}
              <span className="bold-text">cannot</span> apply to this job
              anymore. If you want to finish your proposal later, simply click
              the X icon or leave the screen and it will remain in Draft status
              if you have not submitted it yet.
            </span>
          )}
        </DialogContentText>
        {askReason && (
          <>
            <DialogContentText
              className="content-text"
              style={{ marginTop: '15px', fontSize: '150%' }}
            >
              Reason for rejecting student
            </DialogContentText>
            <RadioGroup sx={{ marginTop: '10px' }}>
              <FormControlLabel
                value={'skills'}
                control={<Radio />}
                label={'Lack of required skills'}
                onClick={() => handleSelect('Lack of required skills')}
              />
              <FormControlLabel
                value={'experience'}
                control={<Radio />}
                label={'Insufficient experience'}
                onClick={() => handleSelect('Insufficient experience')}
              />
              <FormControlLabel
                value={'interview'}
                control={<Radio />}
                label={'Interview performance'}
                onClick={() => handleSelect('Interview performance')}
              />
              <FormControlLabel
                value={'compensation'}
                control={<Radio />}
                label={'Compensation expectations'}
                onClick={() => handleSelect('Compensation expectations')}
              />
              <FormControlLabel
                value={'availability'}
                control={<Radio />}
                label={'Availability / Location'}
                onClick={() => handleSelect('Availability / Location')}
              />
              <FormControlLabel
                value={'other'}
                control={<Radio />}
                label={'Other'}
                onClick={() => {
                  setIsOther(true);
                  setReason('');
                }}
              />
            </RadioGroup>
            {isOther && (
              <TextField
                id="reason"
                label="Reason"
                variant="standard"
                multiline
                maxRows={2}
                sx={{ width: '100%', marginBottom: '20px' }}
                onChange={(e) => setReason(e.target.value)}
                error={reason.length > 249 ? true : false}
                helperText={reason.length > 249 ? 'Max 249 characters' : null}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleHandleClose}
          className="button"
          data-testid="cancel-retract-proposal"
        >
          No
        </Button>
        <LoadingButton
          className="button agree"
          onClick={() => handleProposalAction(reason)}
          buttonText="Yes"
          isLoading={isLoading}
          confirmButtonTestId="retract-proposal-confirm"
          isDisabled={(askReason && reason === '') || reason.length > 249}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ProposalConfirmationModal;
