import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './HighFlyer.scss';
import { TFunction, useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreatePersonalForm,
  useCreatePersonalForm,
} from '../../../../stores/student-signup-form';
import { ProfileData, useFetchStudentById } from '../../../../data/profile';
import StepInfo from './StepInfo';
import { LoadingButton } from '../../../LoadingButton';
import { PersonalityTypeValues } from '../../../../data/students';
import { isOptionEqualToValue } from '../../../../utils/ioption';

export const getPersonalQuestionTranslation = (
  t: TFunction<'translation', 'personal_questions'>,
  question: string,
) => {
  switch (question) {
    case 'This gives me energy':
      return t('this_gives_me_energy');
    case 'This is my pet peeve':
      return t('this_is_my_pet_peeve');
    default:
      return question;
  }
};

const schema = yup
  .object()
  .shape({
    job_interests: yup.array().min(1, 'Min. is 1 interest').required(),
    personal_sentences: yup
      .array()
      .of(
        yup.object().shape({
          question: yup.string().max(150, 'Max 150 characters'),
          response: yup.string().max(150, 'Max 150 characters').optional(),
        }),
      )
      .optional(),
    personality_type: yup.string().optional().nullable(),
  })
  .required();

interface Props {
  handleFormSubmit: (arg: any) => Promise<void>;
  handleBack: () => void;
  student_uid: string;
}

export const PersonalForm = ({
  handleBack,
  handleFormSubmit,
  student_uid,
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sign_up_student' });
  const { t: t_ps } = useTranslation('translation', {
    keyPrefix: 'personal_sentences',
  });
  const { personalFormState, setPersonalFormState } = useCreatePersonalForm();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control,
    reset,
  } = useForm<CreatePersonalForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: personalFormState,
  });
  const { data: student } = useFetchStudentById(student_uid);
  const studentInfo = student as unknown as ProfileData;
  const [isLoading, setIsLoading] = useState(false);

  const [interests, setInterests] = useState([]);

  const getInterestsArray = (jobInterestList) => {
    return jobInterestList?.map((item: any) => {
      return {
        label: item.job_interest_name,
        id: item.job_interest_uid,
      };
    });
  };

  const getInterests = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/dictionary/job_interests',
    ).then(
      (response) => {
        response.json().then((res) => {
          const data = getInterestsArray(res.data);
          setInterests(data);
        });
      },
      (err) => {
        console.log(err);
      },
    );
  };

  useEffect(() => {
    getInterests();
    setIsLoading(false);
  }, []);

  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState({ isError: false, message: '' });
  const tagRegex =
    /^(\w+ ?){0,2} ?(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])?$/;

  const addTag = (data: string) => {
    if (data.trim() === '') {
      return false;
    }
    if (tags.length >= 5) {
      setTagsError({ isError: true, message: 'Max number of tags is 5' });
      return false;
    }
    if (!tagRegex.test(data)) {
      setTagsError({
        isError: true,
        message: 'Tag can have up to two words and one emoji at the end',
      });
      return false;
    }
    if (data.length > 30) {
      setTagsError({
        isError: true,
        message: 'Tag must be less than 30 characters',
      });
      return false;
    }
    const newState = [...tags, data];
    setTagsError({ isError: false, message: '' });
    setTags(newState);
    setPersonalFormState({
      ...personalFormState,
      tags: newState,
    });
    return true;
  };

  const removeTag = (index: number) => {
    const newState = tags.filter((_, arr_idx) => {
      return arr_idx !== index;
    });
    setTags(newState);
    setPersonalFormState({
      ...personalFormState,
      tags: newState,
    });
  };

  useEffect(() => {
    setTags(personalFormState.tags);
  }, [personalFormState.tags]);

  useEffect(() => {
    if (!personalFormState?.info) {
      const {
        hourly_rate,
        job_interests,
        tags,
        personal_sentences,
        personality_type,
      } = studentInfo || {};

      if (job_interests.length) {
        const data = {
          rate: hourly_rate,
          avatar: '/images/student-avatars/7.png',
          job_interests: getInterestsArray(job_interests),
          tags: tags,
          personal_sentences,
          personality_type,
        };

        setTags(tags);
        setPersonalFormState(data);
        reset(data);
      }
    }
  }, [studentInfo]);

  useEffect(() => {
    setValue('avatar', personalFormState.avatar);
  }, [personalFormState]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const combinedState = { ...personalFormState, ...data };
    combinedState.tags = tags;

    setPersonalFormState(combinedState);

    handleFormSubmit(combinedState).then(() => {
      setIsLoading(false);
    });
  };

  console.log(errors);

  return (
    <section className="sign-up-section">
      <StepInfo
        highlightText="You're doing great!"
        text="We're almost there. Now, let's talk about YOU as a person. What excites you, why are you pawsome?"
      />

      <form className="form personal-form">
        <div className="form-inputs">
          <p className="step-subtitle personal-info-subtitle">
            {t('personal_info')}
          </p>
          <Alert severity="info" icon={false} className="toast-student">
            <span className="description">
              {`${t('legal_warning')} `}
              <a
                href="https://www.minite.works/terms-of-use/terms-of-use-students"
                className="toast-link"
                target="_blank"
              >
                Terms of Use
              </a>
              .
            </span>
          </Alert>
          <FormControl
            variant="standard"
            sx={{ width: '100%', marginBottom: '16px' }}
          >
            <Controller
              control={control}
              defaultValue={[]}
              name="job_interests"
              render={({ field: { onChange, value } }) => {
                const handleButtonClick = (option) => {
                  if (
                    value.some((selected) =>
                      isOptionEqualToValue(selected, option),
                    )
                  ) {
                    onChange(
                      value.filter(
                        (item) => !isOptionEqualToValue(item, option),
                      ),
                    );
                  } else {
                    onChange([...value, option]);
                  }
                };

                return (
                  <Box>
                    <Typography variant="h6">{t('interests')}</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {interests.map((option) => (
                        <Button
                          key={option.id}
                          variant={
                            value.some((selected) =>
                              isOptionEqualToValue(selected, option),
                            )
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() => handleButtonClick(option)}
                          sx={{
                            backgroundColor: value.some((selected) =>
                              isOptionEqualToValue(selected, option),
                            )
                              ? '#DEB900'
                              : 'inherit',
                            width: 'auto',
                          }}
                        >
                          {t(`job_interests.${option.label}`)}
                        </Button>
                      ))}
                    </Box>
                    {errors.job_interests && (
                      <Typography color="error" variant="caption">
                        {errors.job_interests.message}
                      </Typography>
                    )}
                  </Box>
                );
              }}
            />
          </FormControl>

          <Divider>{t('personal_touches')}</Divider>

          <Controller
            render={({ field: { value, onChange } }) => (
              <FormControl variant="standard">
                <InputLabel id="degree-select-label">
                  Personality type{' '}
                  <Tooltip
                    title={
                      'Showcase you personality as defined by the 16 personalities test.'
                    }
                    placement="right"
                  >
                    <InfoOutlinedIcon className="info-tooltip" />
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId="personality-type-label"
                  id="personality-type"
                  label="Personality type"
                  value={value}
                  onChange={onChange}
                  data-testid="personality-type"
                >
                  {PersonalityTypeValues.map((personality_type) => {
                    return (
                      <MenuItem
                        key={personality_type.id}
                        value={personality_type.id}
                        sx={{ color: personality_type.color }}
                        disabled={personality_type.color === 'disabled'}
                      >
                        {personality_type.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            control={control}
            name={'personality_type'}
          />

          <TextField
            label={t('personal_tags')}
            inputProps={{ maxLength: 30 }}
            maxRows={1}
            type="text"
            id="user-tags-input"
            data-testid="user-tags-input"
            variant="standard"
            name="tags"
            error={tagsError.isError}
            helperText={tagsError.message ? tagsError.message : t('add_tag')}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                const success = addTag((ev.target as HTMLInputElement).value);
                if (success) {
                  (ev.target as HTMLInputElement).value = '';
                }
              }
            }}
          />
          <div
            className="tags"
            style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
          >
            {tags?.map((item, index) => {
              return (
                <Chip
                  key={index}
                  onDelete={() => removeTag(index)}
                  sx={{
                    width: 'auto',
                    padding: '6px 6px 7px',
                    border: '1.5px solid #deb900',
                    backgroundColor: 'transparent',
                    color: '#deb900',
                    fontFamily: 'Sofia W05 Medium, sans-serif',
                  }}
                  label={item}
                  className="student-level-chip"
                />
              );
            })}
          </div>

          <FormControl>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              {personalFormState.personal_sentences.map((item, index) => {
                return (
                  <TextField
                    key={index}
                    label={getPersonalQuestionTranslation(
                      t_ps,
                      personalFormState.personal_sentences?.at(index).question,
                    )}
                    inputProps={{ maxLength: 200 }}
                    maxRows={3}
                    multiline
                    type="text"
                    id={`user-sentence-${index}-input`}
                    variant="standard"
                    data-testid={`user-sentence-${index}-input`}
                    error={
                      !!errors.personal_sentences &&
                      !!errors.personal_sentences[index] &&
                      !!errors.personal_sentences[index].response
                    }
                    helperText={
                      !!errors.personal_sentences &&
                      !!errors.personal_sentences[index] &&
                      !!errors.personal_sentences[index].response
                        ? errors.personal_sentences[index].response.message
                        : ''
                    }
                    {...register(`personal_sentences.${index}.response`)}
                  />
                );
              })}
            </div>
          </FormControl>
        </div>

        <div className="form-buttons">
          <Button
            variant="contained"
            className="back-button"
            onClick={handleBack}
            data-testid="previous-step-sign-up"
          >
            {t('back')}
          </Button>

          <LoadingButton
            className="confirm-button"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            buttonText={t('continue')}
            isLoading={isLoading}
            confirmButtonTestId="save-personal-info-sign-up"
          />
        </div>
      </form>
    </section>
  );
};
