import React, { useState } from 'react';
import './HireStudentModal.scss';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { JobData, JobTypes } from '../../../data/jobs';
import { ProposalResponse } from '../../../data/proposals';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/LoadingButton';

interface Props {
  open: boolean;
  job: JobData;
  proposal?: ProposalResponse;
  handleClose: () => void;
  handleModalSubmit: (payload: HireStudentForm) => void;
  isLoading: boolean;
}
export interface HireStudentForm {
  job_start_at: string;
  budget: number;
  hours_estimated: number;
}

export const initialState = {
  job_start_at: '',
  budget: null,
  hours_estimated: null,
};

const HireStudentModal = ({
  open,
  handleClose,
  handleModalSubmit,
  job,
  proposal,
  isLoading,
}: Props) => {
  const minBudgetRule =
    +proposal?.rate > +job?.budget ? proposal?.rate : job?.budget;
  const schema = yup
    .object()
    .shape({
      job_start_at: yup
        .string()
        .transform((value) => {
          const date = new Date(value);

          if (isNaN(date.getTime()) || date.getTime() === 0) return null;

          if (date.getDate() === new Date().getDate()) return value;

          if (date.getTime() < new Date().getTime()) return null;

          return value;
        })
        .required(),
      budget: yup
        .number()
        .min(+minBudgetRule, 'Cannot be lower than this job’s budget')
        .required(),
      hours_estimated:
        job.job_type === JobTypes.ONGOING
          ? yup
              .number()
              .integer('Must be an integer')
              .transform((value) =>
                isNaN(value) || value === null || value === undefined
                  ? 0
                  : value,
              )
              .min(
                Date.parse(job?.draft_at) >
                  Date.parse('2024-02-06 00:00:00.000000+00')
                  ? 8
                  : 5,
                `Must be at least ${
                  Date.parse(job?.draft_at) >
                  Date.parse('2024-02-06 00:00:00.000000+00')
                    ? 8
                    : 5
                } hour`,
              )
              .required()
          : yup.number().optional(),
    })
    .required();
  let job_start_at = null;
  if (job?.job_start_at?.length) {
    const now = new Date();
    const jobStartAt = new Date(job.job_start_at);
    if (jobStartAt > now) job_start_at = jobStartAt.toISOString();
  }
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<HireStudentForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      job_start_at,
      budget: +minBudgetRule,
      hours_estimated: job?.hours_estimated,
    },
  });

  const onClose = () => {
    reset(initialState);
    handleClose();
  };
  const onSubmit = () => {
    const formValues = getValues();
    handleModalSubmit(formValues);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { t } = useTranslation();

  return (
    <Dialog open={open} className="missing-info-modal">
      <DialogTitle className="content-title">
        Review job details
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          You’re about to hire this High-Flyer! Please review the details before
          signing the agreement.
        </DialogContentText>
        <form className="hire-student-form">
          <FormControl>
            <Controller
              name="job_start_at"
              control={control}
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    minDate={new Date()}
                    open={isOpen}
                    onChange={(startDate) => {
                      onChange(startDate);
                      setIsOpen(false);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <div className="input-wrapper">
                        <TextField
                          type="text"
                          ref={inputRef}
                          inputProps={inputProps}
                          error={Boolean(errors.job_start_at)}
                          variant="standard"
                          id="startDate"
                          label="Start Date"
                          data-testid="start-date-hire-student"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={InputProps}
                          onClick={handleOpen}
                        />
                        <div className="input-block" onClick={handleOpen}></div>
                      </div>
                    )}
                    {...restField}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>

          {job.job_type === JobTypes.ONGOING && (
            <TextField
              {...register('hours_estimated')}
              type="number"
              className="hire-student-form-inputs"
              id="per-week"
              variant="standard"
              data-testid="per-week-hire-student"
              label={t('job_details.hours_per_week')}
              helperText={errors?.hours_estimated?.message}
              error={!!errors.hours_estimated}
              InputLabelProps={{ shrink: true }}
            />
          )}

          <TextField
            {...register('budget')}
            type="number"
            id="budget"
            className="hire-student-form-inputs"
            variant="standard"
            data-testid="budget-hire-student"
            label={
              job?.job_type === JobTypes.ONGOING ? 'Hourly rate' : 'Budget'
            }
            error={!!errors.budget}
            helperText={`minimum €${minBudgetRule}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={onClose} className="button" data-testid="close-modal">
          Cancel
        </Button>
        <LoadingButton
          className="button agree"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          buttonText="Confirm"
          isLoading={isLoading}
          confirmButtonTestId="hire-student-confirm"
        />
      </DialogActions>
    </Dialog>
  );
};

export default HireStudentModal;
