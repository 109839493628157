import React, { useEffect, useState } from 'react';
import './CreateJob.scss';
import { JobForm, useManageJobForm } from '../../stores/job-form';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  JobStatuses,
  JobType,
  JobTypes,
  useCreateJobRequest,
  useUpdateJobRequest,
} from '../../data/jobs';
import MiniteSnackbar from '../../components/snackbar';
import { LoadingButton } from '../../components/LoadingButton';
import GenericModal from '../../components/generic-modal/GenericModal';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import { usePostJobWizardLoading } from './store';

interface ButtonProps {
  title: string;
  description: string;
  isSelected: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  jobType: string;
}

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  job: any;
  serviceFee: number;
}

export const JobTypeButton = ({
  title,
  description,
  isSelected,
  onClick,
  jobType,
}: ButtonProps) => {
  const disabledStyle = {
    opacity: '0.5',
    border: '2px solid #C7D2E2',
  };

  return (
    <div
      className="jobtype-button"
      style={isSelected ? {} : disabledStyle}
      onClick={onClick}
      data-testid={`job-type-${jobType}`}
    >
      <div className="jobtype-button-icon-container">
        <img src="/images/bulb.png" alt="Light bulb" />
      </div>
      <div>
        <h2 className="jobtype-button-title">{title}</h2>
        <p className="jobtype-button-description">{description}</p>
      </div>
    </div>
  );
};

const schema = yup
  .object()
  .shape({
    job_type: yup.string().required(),
  })
  .required();

export const JobTypeSelection = ({
  handleBack,
  handleNext,
  job,
  serviceFee,
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_job_type',
  });

  const { jobFormState } = useManageJobForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: createJobRequest } = useCreateJobRequest();
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();
  const initializing = usePostJobWizardLoading((state) => state.initializing);
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const refetchJob = usePostJobWizardLoading((state) => state.refetchJob);
  const [confirmedChangeJobType, setConfirmedChangeJobType] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isChangeJobDenied, setIsChangeJobDenied] = useState(false);
  const [isDedicatedRecruiter] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    control,
  } = useForm<JobForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      job_type: job?.job_type || jobFormState.job_type,
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('job_type', job?.job_type || jobFormState.job_type);
  }, [job]);

  const onSubmit = async (data: { job_type: JobType }) => {
    const jobTitle = { job_title: 'Concierge Service' };

    if (!job) {
      try {
        setLoading(true);
        const payload = {
          job: {
            job_type: data.job_type,
            job_title: undefined,
            direct_job: false,
          },
        };

        if (isDedicatedRecruiter) {
          payload.job = {
            ...payload.job,
            ...jobTitle,
          };
        }

        const response = await createJobRequest(payload);
        await refetchJob(response.job.job_uid);
        handleNext();
      } catch (error) {
        setErrorMessage('Job creation failed!');
      } finally {
        setLoading(false);
      }
    } else {
      if (confirmedChangeJobType) {
        try {
          setLoading(true);
          const payload = {
            job: {
              job_uid: job.job_uid,
              job_type: data.job_type,
              hours_estimated: null,
              budget: 0,
              job_title: undefined,
            },
          };

          if (isDedicatedRecruiter) {
            payload.job = {
              ...payload.job,
              ...jobTitle,
            };
          }

          await updateJobRequest({ job_uid: job.job_uid, body: payload });
          await refetchJob(job.job_uid);
          handleNext();
        } catch (error) {
          setErrorMessage('Failed to update job');
        } finally {
          setLoading(false);
        }
      } else {
        handleNext();
        if (isDedicatedRecruiter) {
          await updateJobRequest({
            job_uid: job.job_uid,
            body: { job: jobTitle },
          });
        }
      }
    }
  };

  // const handleDedicatedRecruiter = async () => {
  //   window.open('https://calendly.com/hellominite/30min');
  //   setIsDedicatedRecruiter(true);
  // };

  const onJobTypeChanged = (new_job_type: JobType) => {
    if (!job) return true;
    const { job_type, job_status } = job;
    const isNotDraft = job_status !== JobStatuses.DRAFT;

    if (new_job_type !== job_type) {
      if (isNotDraft) {
        setIsChangeJobDenied(true);
        return false;
      } else {
        setOpenConfirmationDialog(true);
      }
    }

    return true;
  };

  const handleCreateNewJob = () => {
    navigate(Routes.JobPostRoute);
  };
  const SkeletonForm = () => {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </Stack>
    );
  };

  if (initializing) {
    return <SkeletonForm />;
  }

  return (
    <>
      <div className="jobtype-selection">
        <Controller
          render={({ field: { value, onChange } }) => (
            <JobTypeButton
              title={t('ongoing_job')}
              jobType={'ongoing'}
              description={`${t(
                'ongoing_job_description_1',
              )} ${serviceFee}% ${t('ongoing_job_description_2')}`}
              isSelected={value === JobTypes.ONGOING}
              onClick={() => {
                if (onJobTypeChanged(JobTypes.ONGOING)) {
                  onChange(JobTypes.ONGOING);
                }
              }}
            />
          )}
          name="job_type"
          control={control}
        />

        <Controller
          render={({ field: { value, onChange } }) => (
            <JobTypeButton
              title={t('one_time_job_standard')}
              jobType={'standard'}
              description={`${t(
                'one_time_job_standard_description_1',
              )} ${serviceFee}% ${t('one_time_job_standard_description_2')}`}
              isSelected={value === JobTypes.ONE_TIME}
              onClick={() => {
                if (onJobTypeChanged(JobTypes.ONE_TIME)) {
                  onChange(JobTypes.ONE_TIME);
                }
              }}
            />
          )}
          name="job_type"
          control={control}
        />

        {/* <Controller
          render={({ field: { value, onChange } }) => (
            <JobTypeButton
              title={t('one_time_job_premium')}
              jobType={'premium'}
              description={t('one_time_job_premium_description')}
              isSelected={value === JobTypes.PREMIUM}
              onClick={() => {
                if (onJobTypeChanged(JobTypes.PREMIUM)) {
                  onChange(JobTypes.PREMIUM);
                }
              }}
            />
          )}
          name="job_type"
          control={control}
        /> */}

        {/* <JobTypeButton
          title={t('dedicated_recruiter')}
          jobType={'dedicated-recruiter'}
          description={`${t(
            'dedicated_recruiter_description_1',
          )} ${serviceFee}% ${t('dedicated_recruiter_description_2')}`}
          onClick={handleDedicatedRecruiter}
          isSelected={false}
        /> */}
      </div>

      <div className="createjob-content-buttons">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="previous-step-post-job-type"
        >
          {t('back')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid || loading}
          buttonText={t('continue')}
          isLoading={loading}
          confirmButtonTestId="save-job-type-post-job"
        />
      </div>

      <GenericModal
        open={openConfirmationDialog}
        title={t('heads_up')}
        content={t('change_job_type')}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        confirmButtonTestId="open-create-new-job"
        handleCancel={() => {
          setConfirmedChangeJobType(false);
          setOpenConfirmationDialog(false);
          setValue('job_type', job?.job_type);
        }}
        handleConfirm={() => {
          setConfirmedChangeJobType(true);
          setOpenConfirmationDialog(false);
        }}
      />
      <GenericModal
        open={isChangeJobDenied}
        title={t('oops')}
        content={t('cannot_change_job_type')}
        cancelButtonText="Cancel"
        confirmButtonText={t('create_new_job')}
        confirmButtonTestId="create-new-job"
        handleCancel={() => {
          setIsChangeJobDenied(false);
        }}
        handleConfirm={() => {
          setIsChangeJobDenied(false);
          handleCreateNewJob();
        }}
      />

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};
