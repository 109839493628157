import React, { useEffect, useMemo, useState } from 'react';
import useDocumentTitle from '../../utils/useDocumentTitle';
import './Invoices.scss';
import { Button, Grid, Stack, Switch, Typography } from '@mui/material';
import { formatDate } from '../../utils/dates';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ResizableTable from '../../components/resizable-table';
import { InvoicesResponse, useFetchInvoices } from '../../data/invoices';
import { styled } from '@mui/material/styles';
import { useUserData } from '../../stores/auth';
import { UserData } from '../../data/user';
import { GridRenderCellParams } from '@mui/x-data-grid';
import PaymentsIcon from '@mui/icons-material/Payments';

const TypeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const Invoices = () => {
  useDocumentTitle('Invoices');
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;
  const isStudent = user.student_uid;

  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    show: 'onetime',
  });

  const [hiddenColumn, setHiddenColumn] = useState({
    pay_button: !isStudent && filters.show !== 'onetime',
  });

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      show: event.target.checked ? 'ongoing' : 'onetime',
    });
    setHiddenColumn({
      pay_button: !isStudent && event.target.checked,
    });
  };

  const {
    data: invoicesData,
    refetch: refetchInvoicesDate,
    isLoading,
  } = useFetchInvoices(filters);

  const { pagination, invoices } = invoicesData || {};

  const myInvoicesGridData = (): unknown[] =>
    invoices?.map((invoice_response: InvoicesResponse) => {
      return {
        id: invoice_response.invoice_uid,
        job_name: invoice_response.job_title,
        invoice_number: invoice_response.invoice_number,
        created_at: formatDate(invoice_response.created_at, 'DD/MM/YYYY'),
        paid_status: invoice_response.invoice_paid,
        url: invoice_response.url,
        stripe_payment_intent_id: invoice_response.stripe_payment_intent_id,
      };
    });

  const refetchInvoices = async () => {
    await refetchInvoicesDate();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  useEffect(() => {
    refetchInvoices();
  }, [filters]);

  const invoiceColumns = useMemo(() => {
    return [
      {
        accessorKey: 'job_name',
        header: 'Job name',
        enableSorting: false,
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice number',
        enableSorting: false,
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
        enableSorting: false,
      },
      {
        accessorKey: 'paid_status',
        header: 'Paid status',
        enableSorting: false,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'pay_button',
        header: 'Pay invoice',
        enableSorting: false,
        Cell: (params: GridRenderCellParams) => {
          return (
            <>
              {!params.row.original.paid_status && (
                <Button
                  href={`pay-invoice?paymentIntentId=${params.row.original.stripe_payment_intent_id}`}
                  target="_blank"
                >
                  <PaymentsIcon sx={{ color: '#14DB87' }} />
                </Button>
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'url',
        header: 'Download invoice',
        enableSorting: false,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              <Button href={renderedCellValue} target="_blank">
                <DownloadIcon />
              </Button>
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <div className={'invoices-title-container'}>
        <Typography variant="h5" component="div">
          Invoices
        </Typography>
      </div>
      <div>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>
            {isStudent
              ? 'One-time job invoices'
              : 'Single invoices (job posting fee, promo pack fee, one-time job)'}
          </Typography>
          <TypeSwitch
            inputProps={{ 'aria-label': 'type switch' }}
            checked={filters.show === 'ongoing'}
            onChange={handleChangeSwitch}
          />
          <Typography>
            {isStudent
              ? 'Ongoing job invoices'
              : 'Recurring invoices (ongoing jobs)'}
          </Typography>
        </Stack>
      </div>

      <Grid
        container
        className="admin-grid-dashboard admin-students-container"
        sx={{
          width: '100%',
          gap: 5.3,
        }}
      >
        <Grid
          className="table-grid-item"
          item
          lg={12}
          md={12}
          xl={12}
          sm={12}
          xs={12}
        >
          <ResizableTable
            pageSize={filters.per_page}
            columns={invoiceColumns}
            rows={myInvoicesGridData()}
            page={filters.page}
            onSortChange={handleSortModelChange}
            onPageChange={handleChangePage}
            rowCount={pagination?.total || 0}
            isLoading={isLoading}
            hideColumn={hiddenColumn}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Invoices;
