import React, { useEffect, useMemo, useState } from 'react';
import './AdminStudents.scss';
import '../AdminGeneralStyles.scss';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import { useFetchLangsAndSkills } from '../../../data/shared';
import {
  useSearchAdminStudents,
  useStudentsStatistic,
} from '../../../data/admin-students';
import { useFetchUniversities } from '../../../data/edit-profile';
import { useDebounce } from '../../../utils/useDebounce';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Routes } from '../../../utils/consts';
import { formatDate } from '../../../utils/dates';
import ResizableTable from '../../../components/resizable-table';

const studentLevel = {
  'entry-level': 'Entry-level',
  regular: 'Standard',
};

const getStatisticCards = (data) => {
  const { students_total, students_verified, students_with_job_in_progress } =
    data;
  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'total'}
        text={'Total students'}
        counter={students_total}
      />
      <AdminStatisticCard
        key={'verified'}
        text={'Verified students'}
        counter={students_verified}
      />
      <AdminStatisticCard
        key={'progress'}
        text={'Students with at least 1 job in progress'}
        counter={students_with_job_in_progress}
      />
    </div>
  );
};

const AdminStudents = () => {
  const [search, setSearch] = useState('');

  const [values, setValues] = useState({
    languages: undefined,
    skills: undefined,
    universities: undefined,
  });
  const { data: statistics = {} } = useStudentsStatistic();
  const [{ data: languages = [] }, { data: skills = [] }] =
    useFetchLangsAndSkills();
  const { data: universities = [] } = useFetchUniversities();
  const [hidenColumn, setHidenColumn] = useState({
    other: false,
  });

  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    how_did_you_find_us: undefined,
  });

  const studentColumns = useMemo(() => {
    return [
      {
        accessorKey: 'student_name',
        header: 'Name',
        enableSorting: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
      },
      {
        accessorKey: 'latest_assessment_score',
        header: 'Latest assessment score',
        enableSorting: true,
        Cell: ({ renderedCellValue }) => {
          let color = 'red';
          if (renderedCellValue >= 50) {
            color = 'green';
          }

          return <span style={{ color }}>{renderedCellValue}%</span>;
        },
      },
      {
        accessorKey: 'degree',
        header: 'Degree',
        enableSorting: true,
      },
      {
        accessorKey: 'university',
        header: 'University',
        enableSorting: true,
      },
      {
        accessorKey: 'student_type',
        header: 'Level',
        enableSorting: true,
      },
      {
        accessorKey: 'alias',
        header: 'Alias',
        enableSorting: true,
      },
      {
        accessorKey: 'verified',
        header: 'Verified',
        enableSorting: true,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
        enableSorting: true,
      },
      {
        accessorKey: 'ref_code',
        header: 'Ref code of a user',
        enableSorting: true,
      },
      {
        accessorKey: 'invited_by_ref_code',
        header: 'Ref code used by a user',
        enableSorting: true,
      },
      {
        accessorKey: 'other',
        header: 'Other',
        enableSorting: true,
      },
    ];
  }, []);

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: studentsData,
    refetch: refetchStudentsDate,
    isLoading,
  } = useSearchAdminStudents({ ...filters, query: debouncedSearch });

  const { pagination, students } = studentsData || {};

  useEffect(() => {
    const obj = {
      languages: values.languages || [],
      skills: values.skills || [],
      universities: values.universities || [],
    };

    if (!obj.languages.length) {
      obj.languages = languages.map((item: any) => {
        return {
          label: item.language_name,
          id: item.language_uid,
        };
      });
    }

    if (!obj.skills.length) {
      obj.skills = skills.map((item: any) => {
        return {
          label: item.skill_name,
          id: item.skill_uid,
        };
      });
    }

    if (!obj.universities.length) {
      obj.universities = universities.map((item: any) => {
        return {
          label: item.university_name,
          id: item.university_uid,
        };
      });
    }

    setValues({ ...obj });
  }, [languages, skills, universities]);

  const myStudentsGridData = (): unknown[] =>
    students?.map((student: any) => {
      const {
        student_uid,
        student_type,
        latest_assessment_score,
        alias,
        user,
        education,
        verified,
        universities: studentUniversities,
        created_at,
      } = student;

      const recentEducation = education.sort((a, b) =>
        new Date(b.ended_at).getTime() - new Date(a.ended_at).getTime()
          ? 1
          : -1,
      )[0];

      return {
        id: student_uid,
        alias,
        student_name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        latest_assessment_score: `${latest_assessment_score || 0}`,
        verified,
        student_type: studentLevel[student_type],
        degree: `${recentEducation.degree} ${recentEducation.study}`,
        university: studentUniversities.find(
          (item) => item.university_uid === recentEducation.university_uid,
        ).university_name,
        created_at: formatDate(created_at, 'DD/MM/YYYY'),
        ref_code: user.ref_code,
        invited_by_ref_code: user.invited_by_ref_code,
        other: user.other_how_did_you_find_minite || '',
      };
    });

  const refetchStudents = async () => {
    await refetchStudentsDate();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleRowClick = (data) => {
    window.open(`${Routes.AdminStudentsRoute}/${data.id}`, '_blank');
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  const isHideOtherColumn = () => {
    return filters?.how_did_you_find_us?.includes('Other');
  };

  useEffect(() => {
    refetchStudents();
    setHidenColumn({ other: isHideOtherColumn() ?? false });
  }, [filters]);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-students-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Students</p>

      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        {getStatisticCards(statistics)}
      </Grid>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All students</p>
          <div className="admin-table-inputs students-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-students-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />
            <FiltersPanel
              values={values}
              filters={filters}
              page="admin-students"
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;

                setFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <ResizableTable
          pageSize={filters.per_page}
          columns={studentColumns}
          rows={myStudentsGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          hideColumn={hidenColumn}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminStudents;
