import React, { useState } from 'react';
import '../../financial/components/AccountForms.scss';
import {
  Autocomplete,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useFetchLanguages,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import { LangLevels } from '../../../../components/sign-up/interfaces/static-data';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import {
  initialFormLang,
  LanguageForm,
  languagesSchema,
} from '../../../../stores/student-signup-form';
import {
  getOptionLabel,
  IOption,
  isOptionEqualToValue,
} from '../../../../utils/ioption';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';

const schema = yup
  .object()
  .shape({
    languages: languagesSchema,
  })
  .required();

interface LanguagesFormInterface {
  languages: LanguageForm[];
}

const LanguagesForm = ({ student, loading, afterSubmit }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { data: languages = student?.languages || [] } = useFetchLanguages();
  const languageOptions: IOption[] = languages.map((item) => {
    return {
      label: item.language_name,
      id: item.language_uid,
    };
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.student',
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<LanguagesFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      languages: !!student?.student_languages?.length
        ? student?.student_languages?.map((item) => {
            return {
              language: languageOptions?.find((lang) => {
                return lang.id === item.language_uid;
              }),
              level: LangLevels.find((level) => {
                return level?.toLowerCase() === item?.level?.toLowerCase();
              }),
            };
          })
        : [{ ...initialFormLang }],
    },
    reValidateMode: 'onChange',
  });

  const {
    fields: languagesFields,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: 'languages',
  });

  const languagesWatch = useWatch({ control, name: 'languages' });

  const onSubmit = async (data: LanguagesFormInterface) => {
    if (data) {
      setIsLoading(true);

      await updateUserProfile({
        student: {
          languages: data.languages?.map((item) => {
            return {
              language_uid: item?.language?.id,
              level: item.level,
            };
          }),
        },
      });

      await reset(data);
      await afterSubmit();
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms education-form">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">{t('lang')}</p>
        </Grid>

        <Grid item xs={12} md={9}>
          {loading ? (
            <Skeleton />
          ) : (
            <form className="account-form">
              <div className="form-inputs">
                {languagesFields.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <FormGroup className="form-group">
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              options={languageOptions}
                              value={value}
                              sx={{ flex: '1 1 50%' }}
                              isOptionEqualToValue={isOptionEqualToValue}
                              id={`language language-${index}`}
                              getOptionLabel={getOptionLabel}
                              onChange={(e, data: IOption | null) => {
                                if (data) {
                                  onChange(data);
                                } else {
                                  onChange(null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label={t('lang')}
                                  error={!!errors.languages?.[index]?.language}
                                  helperText={
                                    errors?.languages?.[index]?.language
                                      ?.message
                                  }
                                />
                              )}
                            />
                          )}
                          name={`languages.${index}.language`}
                          control={control}
                        />

                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <FormControl
                              variant="standard"
                              sx={{ flex: '1 1 50%' }}
                            >
                              <InputLabel id="level-select-label">
                                {t('level')}
                              </InputLabel>
                              <Select
                                labelId="level-select-label"
                                id={`level-select level-select-${index}`}
                                label={t('level')}
                                name="level"
                                error={!!errors.languages?.[index]?.level}
                                value={value}
                                onChange={onChange}
                              >
                                {LangLevels.map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={item}
                                      value={item}
                                      id={`level-option-${i}`}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                          control={control}
                          name={`languages.${index}.level`}
                        />
                      </FormGroup>

                      {languagesWatch?.length > 1 && index > 0 && (
                        <div className="remove-button-container">
                          <Button
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            variant="outlined"
                            className="array-buttons remove-field-button"
                            type="button"
                            data-testid="delete-language"
                            onClick={() => removeLanguage(index)}
                          >
                            {'DELETE'}
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}

                <div className="edit-profile-array-buttons-container">
                  <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    className="array-buttons add-field-button"
                    type="button"
                    data-testid="add-language"
                    onClick={() => appendLanguage({ ...initialFormLang })}
                  >
                    {t('add_lang')}
                  </Button>
                </div>
              </div>
            </form>
          )}

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-languages"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default LanguagesForm;
