import { Grid } from '@mui/material';
import Description from './Description';
import MC from './MC';
import { QuestionRepresentationObject } from './question-data';
import './Question.scss';

export const Question = ({
  question,
  handleTestSubmit,
  submitButtonText,
}: {
  question: QuestionRepresentationObject;
  handleTestSubmit: (value: string | undefined) => void;
  submitButtonText: string;
}) => {
  return (
    <div className="assessment-question">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} lg={12}>
          <Description
            question={question.question}
            text_1={question.text_1}
            text_2={question.text_2}
            image={question.image}
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
        >
          <MC
            options={question.answers}
            onSubmit={handleTestSubmit}
            submitButtonText={submitButtonText}
          />
        </Grid>
      </Grid>
    </div>
  );
};
