import {
  ButtonBase,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchIndustries } from '../../../../data/edit-profile';
import { JobStatuses, JobTypes, StudentTypes } from '../../../../data/jobs';
import { COMPANY_AVATAR_BUCKET } from '../../../../utils/consts';
import { numberWithDec } from '../../../../utils/numberWithDec';
import JobStatusChip from '../../../job-details/components/JobStatusChip';
import { formatDate } from '../../../../utils/dates';
import './JobDetailsAdmin.scss';
import { fetchReviewsByUserType } from '../../../../data/reviews';
import ReviewsModal from '../../../../components/reviews-modal/ReviewsModal';
import { budgetString } from '../../../job-details';
import CompanyAvatar from '../../../../components/company-avatar';
import { useJobDetails } from '../../../../data/admin-job-details';

const JobDetailsAdmin = () => {
  const { t } = useTranslation();

  const params = useParams<{ jobUid?: string }>();
  const { data: industries = [] } = useFetchIndustries();

  const { data: jobData, isLoading } = useJobDetails(params.jobUid);
  const [industryName, setIndustryName] = useState('');

  const [avatar, setAvatar] = useState(null);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [jobStartDate, setJobStartDate] = useState(null);

  useEffect(() => {
    if (jobData) {
      if (jobData?.company?.avatar) {
        setAvatar(COMPANY_AVATAR_BUCKET + `${jobData?.company?.avatar}`);
      }

      if (jobData?.job_start_at) {
        const startDate = new Date(jobData.job_start_at);
        const now = new Date();

        if (now.getTime() < startDate.getTime()) {
          setJobStartDate(formatDate(jobData.job_start_at, 'DD MMMM YYYY'));
        } else {
          setJobStartDate('ASAP');
        }
      } else {
        setJobStartDate('-');
      }

      setIndustryName(
        industries?.find(
          (item) => item.interest_uid === jobData?.company?.interest_uid,
        )?.interest_name,
      );
    }
  }, [jobData]);

  useEffect(() => {
    if (jobData) {
      fetchReviewsByUserType(jobData.company.company_uid, 'company').then(
        (res) => {
          setReviews(res.reviews);
        },
      );
    }
  }, [jobData]);

  if (isLoading || jobData === undefined) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="rectangular" width={400} height={60} />
        <Skeleton variant="rounded" width={400} height={60} />
      </Stack>
    );
  }

  const handleShowReviewModal = () => {
    setIsOpenReviewModal(!isOpenReviewModal);
  };

  const subCategory = jobData.sub_category.code
    ? t(`sub_categories.${jobData.sub_category.code}`)
    : jobData.sub_category.sub_category_name || '';

  return (
    <Grid container className="job-details-student-view-container" gap={2}>
      <Grid item xs={12} md={8}>
        <div className="job-container">
          <h1 className="title">
            {jobData.direct_job
              ? jobData.direct_job_type === 'Inhouse day' ||
                jobData.direct_job_type === 'Event'
                ? 'Event details'
                : t('job_details.job_details')
              : t('job_details.job_details')}
          </h1>

          <div className="job-details">
            <div className="brief-info">
              <div className="top">
                <div className="left">
                  <CompanyAvatar avatar={avatar} />
                  <div className="titles-container">
                    <h3 className="title">{jobData.job_title || 'Draft'}</h3>
                    <div className="company-detail">
                      <div className="rate">
                        <StarIcon className="favourite-rate-star" />
                        <span className="rating">
                          {numberWithDec(jobData.company.rating)}
                        </span>
                        <ButtonBase
                          className="review-count"
                          data-testid="open-reviews"
                          onClick={handleShowReviewModal}
                        >
                          {reviews.length} reviews
                        </ButtonBase>
                        <ReviewsModal
                          reviews={reviews}
                          isOpen={isOpenReviewModal}
                          onClose={handleShowReviewModal}
                        />
                      </div>

                      <p className="company-label">
                        {jobData.company.company_name}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="job-chip-wrapper">
                    <JobStatusChip jobStatus={jobData.job_status} />
                  </div>
                  <p className="date medium-text">
                    {jobData.job_status !== JobStatuses.DRAFT &&
                      jobData.job_status !== JobStatuses.PENDING_APPROVAL &&
                      (formatDate(jobData?.open_at, 'DD MMMM YYYY') || '')}
                  </p>
                  {jobData.direct_job ? (
                    <>
                      <Chip
                        className="medium-text top-chip"
                        label={jobData.direct_job_type}
                        variant="outlined"
                        color="primary"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '20px',
                          width: 'auto',
                          height: 24,
                          backgroundColor: '#ffffff',
                          borderColor: '#13bd73',
                          color: '#13bd73',
                          fontWeight: 500,
                          fontFamily: 'Sofia W05 Medium, sans-serif',
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {(jobData.job_type === JobTypes.ONGOING &&
                        jobData.student_type) === StudentTypes.EntryLevel && (
                        <Chip
                          className="medium-text top-chip"
                          label="Entry-level"
                          variant="outlined"
                          color="primary"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '20px',
                            width: 'auto',
                            height: 24,
                            backgroundColor: '#ffffff',
                            borderColor: '#1976D2',
                            color: '#1976D2',
                            fontWeight: 500,
                            fontFamily: 'Sofia W05 Medium, sans-serif',
                          }}
                        />
                      )}
                      <Chip
                        className="medium-text"
                        label={`Hiring ${jobData.students_needed} High-Flyers`}
                        variant="outlined"
                        color="warning"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '20px',
                          width: 'auto',
                          height: 24,
                          backgroundColor: '#ffffff',
                          borderColor: '#DEB900',
                          color: '#46566E',
                          fontWeight: 500,
                          fontFamily: 'Sofia W05 Medium, sans-serif',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="bottom">
                <p className="applied medium-text">
                  {!(
                    jobData.direct_job &&
                    (jobData.direct_job_type === 'Inhouse day' ||
                      jobData.direct_job_type === 'Event')
                  ) && (
                    <>
                      {t('job_details.high_flyers_applied')}{' '}
                      {jobData.proposals_count}
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="main">
              <div className="top">
                <h2 className="title heading-text">
                  {jobData.direct_job
                    ? jobData.direct_job_type === 'Inhouse day' ||
                      jobData.direct_job_type === 'Event'
                      ? 'Event'
                      : 'Direct job'
                    : jobData.job_type}
                </h2>

                {jobData.direct_job ? (
                  <h2 className="budget heading-text">
                    {jobData.direct_job_compensation}
                  </h2>
                ) : (
                  <h2 className="budget heading-text">
                    {budgetString(jobData.budget, jobData.job_type)}
                  </h2>
                )}
              </div>

              {jobData.direct_job && (
                <div className="info">
                  <div className="left">
                    <p className="info-title medium-text">
                      Job type:{' '}
                      <span className="info-value">
                        {jobData.direct_job_type}
                      </span>
                    </p>
                    {jobData.office_location && (
                      <p className="info-title">
                        {t('job_details.office_location')}:{' '}
                        <span className="info-value location">
                          {jobData.office_location}
                        </span>
                      </p>
                    )}
                    {jobData.job_start_at && (
                      <p className="info-title">
                        {t('Event date')}:{' '}
                        <span className="info-value">
                          {jobData.job_start_at.substring(0, 10)}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {!jobData.direct_job && (
                <div className="info">
                  <div className="left">
                    <p className="info-title medium-text">
                      {t('filters.category')}:{' '}
                      <span className="info-value">
                        {t(`categories.${jobData.category.code}`)}
                        {jobData.sub_category &&
                          !!subCategory &&
                          ` | ${subCategory}`}
                      </span>
                    </p>
                    <p className="info-title medium-text">
                      {t('job_details.langs')}:{' '}
                      <span className="info-value">
                        {jobData.languages
                          .map((item) => item.language_name)
                          .join(', ')}
                      </span>
                    </p>

                    <p className="info-title medium-text">
                      {jobData?.job_type === JobTypes.ONGOING
                        ? t('job_details.hours_per_week')
                        : t('job_details.estimate_hours_needed')}
                      :{' '}
                      <span className="info-value">
                        {jobData.hours_estimated}
                      </span>
                    </p>

                    <p className="info-title medium-text">Skills:</p>
                    <div className="skills-container medium-text">
                      {jobData.skills.map((skill) => (
                        <Chip
                          className="medium-text"
                          key={skill.skill_uid}
                          label={skill.skill_name}
                          sx={{
                            fontSize: '12px',
                            lineHeight: '20px',
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="right">
                    <p className="info-title">
                      {t('job_details.location')}{' '}
                      <span className="info-value location">
                        {jobData.job_location}
                      </span>
                    </p>
                    {jobData.office_location && (
                      <p className="info-title">
                        {t('job_details.office_location')}:{' '}
                        <span className="info-value location">
                          {jobData.office_location}
                        </span>
                      </p>
                    )}
                    <p className="info-title">
                      {t('job_details.start_date')}:{' '}
                      <span className="info-value">{jobStartDate}</span>
                    </p>
                    {!!jobData?.job_end_at && (
                      <p className="info-title">
                        {t('job_details.end_date')}:{' '}
                        <span className="info-value">
                          {formatDate(jobData.job_end_at, 'DD MMMM YYYY')}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="description">
                <h4 className="title medium-text">
                  {jobData.direct_job
                    ? jobData.direct_job_type === 'Inhouse day' ||
                      jobData.direct_job_type === 'Event'
                      ? 'Event description'
                      : t('job_details.job_desc')
                    : t('job_details.job_desc')}
                </h4>
                <p className="text medium-text">{jobData.job_description}</p>
              </div>
            </div>
          </div>
        </div>
      </Grid>

      <Divider orientation="vertical" flexItem />

      <Grid item xs={12} md={3}>
        <div className="company-details">
          <div className="details-container">
            <CompanyAvatar avatar={avatar} />

            <div className="titles-container">
              <h3 className="title medium-text">
                {jobData.company.company_name}
              </h3>
              <div className="rate">
                <StarIcon className="favourite-rate-star" />
                <span className="rating">
                  {numberWithDec(jobData.company.rating)}
                </span>
                <ButtonBase
                  className="review-count"
                  data-testid="show-reviews"
                  onClick={handleShowReviewModal}
                >
                  {reviews.length} reviews
                </ButtonBase>
                <ReviewsModal
                  reviews={reviews}
                  isOpen={isOpenReviewModal}
                  onClose={handleShowReviewModal}
                />
              </div>
            </div>
          </div>

          <div className="info company-info">
            <p>
              <span className="info-title">{t('job_details.industry')}:</span>{' '}
              <span className="info-value">{industryName}</span>
            </p>
          </div>

          <div className="info company-address">
            <p>
              <span className="info-title">
                {jobData.address.street_name} {jobData.address.house_number},
              </span>
            </p>
            <p>
              <span className="info-title">
                {jobData.address.postal_code} {jobData.address.city}
              </span>
            </p>
          </div>

          <div className="info company-description">
            <p className="info-title">
              <span>{jobData?.company?.bio}</span>
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default JobDetailsAdmin;
