import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { JobTypes } from '../../interfaces/static-data';
import './HighFlyer.scss';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  ExperienceFormData,
  experienceSchema,
  initialExperienceJob,
  MIN_EDUCATION_EXPERIENCE_DATE,
  useCreateExperienceForm,
} from '../../../../stores/student-signup-form';
import { styled } from '@mui/system';
import { IOption } from '../../../../utils/ioption';
import StepInfo from './StepInfo';
import { LoadingButton } from '../../../LoadingButton';

const schema = experienceSchema;

interface Props {
  handleBack: () => void;
  handleFormSubmit: (arg?: any) => Promise<void>;
}

const StyledChip = styled(Chip)({
  tag: {
    backgroundColor: '#DEB900',
  },
});

export const ExperienceForm = ({ handleBack, handleFormSubmit }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sign_up_student' });
  const tooltipDescription = t('experience_tooltip');
  const [skills, setSkills] = useState([]);
  const [submitted, isSubmit] = useState(false);
  const { experienceFormState, setExperienceFormState } =
    useCreateExperienceForm();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ExperienceFormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: experienceFormState,
  });
  const isValid = !errors.jobs;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobs',
  });

  const jobsWatch = useWatch({ control, name: 'jobs' });

  const getSkills = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/dictionary/skills').then(
      (response) => {
        response.json().then((res) => {
          const data = res.data.map((item: any) => {
            return {
              label: item.skill_name,
              id: item.skill_uid,
            };
          });
          setSkills(data);
        });
      },
      (err) => {
        console.log(err);
      },
    );
  };

  useEffect(() => {
    getSkills();
    setIsLoading(false);
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    setExperienceFormState({
      ...experienceFormState,
      ...data,
    });
    isSubmit(true);
  };

  useEffect(() => {
    if (submitted) {
      handleFormSubmit({
        ...experienceFormState,
      }).then(() => {
        setIsLoading(false);
      });
    }
  }, [submitted]);

  return (
    <section className="sign-up-section">
      <p className="step-title">{t('work_exp')}</p>
      <StepInfo
        highlightText="Last step!"
        text="Add any relevant work experience: a parttime job, an internship, a freelance assignment, a board year, a committee you're in... it all counts towards your value!"
      />
      <form className="form">
        <div className="form-inputs">
          {fields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <FormControl>
                  <TextField
                    label={t('job_title')}
                    {...register(`jobs.${index}.job`)}
                    type="text"
                    id="job-title-input"
                    data-testid="job-title-input"
                    variant="standard"
                    error={!!errors.jobs?.[index]?.job}
                    helperText={errors.jobs?.[index]?.organization?.message}
                  />
                </FormControl>

                <FormControl variant="standard" data-testid="select-job-type">
                  <InputLabel id="type-select-label">{t('type')}</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    label={t('type')}
                    error={!!errors.jobs?.[index]?.type}
                    {...register(`jobs.${index}.type`)}
                  >
                    {JobTypes.map((item) => {
                      return (
                        <MenuItem
                          key={item}
                          value={item}
                          data-testid={`job-type-${item.replace(' ', '-')}`}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl>
                  <TextField
                    label={t('org_name')}
                    type="text"
                    id="org-input"
                    data-testid="job-organization-name"
                    variant="standard"
                    {...register(`jobs.${index}.organization`)}
                    error={!!errors.jobs?.[index]?.organization}
                    helperText={errors.jobs?.[index]?.organization?.message}
                  />
                </FormControl>

                <FormGroup className="form-group">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <DesktopDatePicker
                          value={value}
                          views={['year', 'month']}
                          onChange={(value) => onChange(value)}
                          disableMaskedInput={true}
                          label={t('start_date')}
                          inputFormat="MMMM YYYY"
                          minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              data-testid="job-started-at"
                              error={!!errors.jobs?.[index]?.startDate}
                              helperText={
                                !!errors.jobs?.[index]?.startDate
                                  ? t('datepicker_error')
                                  : ''
                              }
                            />
                          )}
                        />
                      )}
                      control={control}
                      name={`jobs.${index}.startDate`}
                    />

                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <DesktopDatePicker
                          value={value}
                          views={['year', 'month']}
                          onChange={(value) => onChange(value)}
                          disableMaskedInput={true}
                          label={t('end_date')}
                          inputFormat="MMMM YYYY"
                          minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              data-testid="job-ended-at"
                              error={!!errors.jobs?.[index]?.endDate}
                              helperText={
                                !!errors.jobs?.[index]?.endDate
                                  ? t('datepicker_error')
                                  : ''
                              }
                            />
                          )}
                        />
                      )}
                      control={control}
                      name={`jobs.${index}.endDate`}
                    />
                  </LocalizationProvider>
                </FormGroup>

                <FormControl
                  variant="standard"
                  sx={{ width: '100%', marginBottom: '16px' }}
                >
                  <Controller
                    control={control}
                    name={`jobs.${index}.skills`}
                    defaultValue={[]}
                    render={({ field, field: { onChange } }) => (
                      <Autocomplete
                        multiple
                        id="skills-select"
                        data-testis="job-skills"
                        limitTags={5}
                        options={skills}
                        onChange={(_, data) => onChange(data)}
                        defaultValue={experienceFormState[index]?.skills || []}
                        getOptionLabel={(option: IOption) =>
                          option?.label || ''
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <StyledChip
                              variant="outlined"
                              label={`${option.label}`}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label={t('skills')}
                            variant="standard"
                            error={!!errors.jobs?.[index]?.skills}
                            helperText={errors?.jobs?.[index]?.skills?.message}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <TextField
                    type="text"
                    id="Description-input"
                    data-testid="job-description"
                    variant="standard"
                    label={t('desc')}
                    error={!!errors.jobs?.[index]?.description}
                    helperText={errors.jobs?.[index]?.description?.message}
                    {...register(`jobs.${index}.description`)}
                    multiline
                    inputProps={{
                      max: 500,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={tooltipDescription} placement="right">
                            <InfoOutlinedIcon className="info-tooltip" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <div className="array-buttons-container">
                  {jobsWatch.length > 1 && (
                    <Button
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      variant="outlined"
                      className="array-buttons remove-field-button"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      {t('remove')}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}

          <div className="array-buttons-container">
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className="array-buttons add-field-button"
              type="button"
              onClick={() => append({ ...initialExperienceJob })}
            >
              {t('add_job')}
            </Button>
          </div>
        </div>

        <div className="form-buttons">
          <Button
            variant="contained"
            className="back-button"
            onClick={handleBack}
          >
            {t('back')}
          </Button>

          <LoadingButton
            className="confirm-button"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            buttonText={t('continue')}
            isLoading={isLoading}
            id="complete-registration-student"
            confirmButtonTestId="complete-registration-student"
          />
        </div>
      </form>
    </section>
  );
};
