import React, { useEffect, useState } from 'react';
import './CompanyOverview.scss';
import { Chip, MenuItem, Select, Skeleton } from '@mui/material';
import MiniteGrid from '../../../../components/grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  AdminCompanyJobs,
  AdminCompanyOverview,
  AdminCompanyProposals,
  AdminCompanyStudents,
  useAdminCompanyOverviewJobs,
  useAdminCompanyOverviewProposals,
  useAdminCompanyOverviewStudents,
} from '../../../../data/admin-companies';
import { formatDate } from '../../../../utils/dates';
import { JobStatus, JobStatuses, ProposalStatus } from '../../../../data/jobs';
import ProposalStatusChip from '../../../job-details/components/ProposalStatusChip';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../utils/consts';
import { useAdminVerifyUser } from '../../../../data/admin';
import { budgetString } from '../../../job-details';
import { sortDate } from '../../../../utils/sortDate';
import MiniteSnackbar from '../../../../components/snackbar';

const getStatusColor = (jobStatus: JobStatus) => {
  switch (jobStatus) {
    case JobStatuses.DRAFT:
    case JobStatuses.PENDING_APPROVAL:
      return '#4E5B73';
    case JobStatuses.OPEN:
    case JobStatuses.MATCHED:
    case JobStatuses.SIGNED:
      return '#14DB87';
    case JobStatuses.INTERVIEWING:
      return '#1976d2';
    case JobStatuses.REJECTED:
    case JobStatuses.CANCELLED:
      return '#EA4335';
    default:
      return '#4E5B73';
  }
};

const getCompanyJobs = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <Chip
          className="job-status"
          label={params.value}
          sx={{
            borderColor: getStatusColor(params.value as JobStatus),
            color: getStatusColor(params.value as JobStatus),
            textTransform: 'capitalize',
          }}
          variant="outlined"
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminJobsRoute}/${params.row.id}`}
            title={params.row.title}
          >
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: 'student',
      headerName: 'Student',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminStudentsRoute}/${params.row.student_uid}`}
            title={params.row.student_name}
          >
            {params.row.student_name}
          </Link>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Created',
      width: 120,
    },
    {
      field: 'matched_at',
      headerName: 'Matched',
      width: 120,
    },
    {
      field: 'completed_at',
      headerName: 'Completed',
      width: 120,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 80,
    },
    {
      field: 'proposals',
      headerName: 'Proposals',
      width: 100,
    },
  ];
};
const getCompanyProposals = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <ProposalStatusChip forStudent proposalStatus={params.value} />
      ),
    },
    {
      field: 'student',
      headerName: 'Student',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminStudentsRoute}/${params.row.student_uid}`}
            title={params.row.student}
          >
            {params.row.student}
          </Link>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Job title',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminJobsRoute}/${params.row.job_uid}`}
            title={params.row.title}
          >
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 100,
    },
  ];
};
const getCompanyStudents = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'education',
      headerName: 'Education',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Job title',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminJobsRoute}/${params.row.job_uid}`}
            title={params.row.title}
          >
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: 'level',
      headerName: 'Level',
      width: 180,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 100,
    },
  ];
};
const getJobRows = (jobsList: AdminCompanyJobs) => {
  if (!jobsList || !jobsList.jobs?.length) return [];

  return jobsList.jobs
    .sort((a, b) => sortDate(a.created_at, b.created_at))
    .map((job) => {
      return {
        id: job.job_uid,
        status: job.job_status,
        title: job.job_title || 'Draft',
        date: formatDate(job.job_start_at, 'DD/MM/YYYY'),
        rate: budgetString(job?.budget, job.job_type),
        proposals: job.proposals_count,
        matched_at: formatDate(job.matched_at, 'DD/MM/YYYY'),
        completed_at: job.completed_at
          ? formatDate(job.completed_at, 'DD/MM/YYYY')
          : formatDate(job.cancelled_at, 'DD/MM/YYYY'),
        student_uid: job.student_uid,
        student_name: job.student_name,
      };
    });
};

const studentLevel = {
  'entry-level': 'Entry-level',
  regular: 'Standard',
};

const getProposalRows = (proposalList: AdminCompanyProposals) => {
  if (!proposalList || !proposalList.proposals?.length) return [];

  return proposalList.proposals.map((proposal) => {
    return {
      id: proposal.proposal_uid,
      date: formatDate(proposal.created_at, 'DD/MM/YYYY'),
      status: proposal.proposal_status,
      student: proposal.student.first_name + ' ' + proposal.student.last_name,
      title: proposal.job.job_title || 'Draft',
      rate: `€${proposal.rate}`,
      student_uid: proposal.student.student_uid,
      job_uid: proposal.job.job_uid,
    };
  });
};

const getStudentRows = (studentList: AdminCompanyStudents) => {
  if (!studentList || !studentList.students?.length) return [];

  return studentList.students.map((student) => {
    return {
      id: student.student.student_uid,
      name: student.student?.first_name + ' ' + student?.student?.last_name,
      education:
        student.education[0].degree +
        ' ' +
        student.universities[0].university_name,
      title: student.job.job_title || 'Draft',
      level: studentLevel[student.student.student_type],
      rate: `€${student.rate}`,
      job_uid: student.job.job_uid,
    };
  });
};

const CompanyDetails = (props) => {
  const details = props.details as AdminCompanyOverview;
  const { refetchCompany } = props;
  const { data: jobsList } = useAdminCompanyOverviewJobs(details?.company_uid);
  const { data: proposalsList } = useAdminCompanyOverviewProposals(
    details?.company_uid,
  );
  const { data: studentsList } = useAdminCompanyOverviewStudents(
    details?.company_uid,
  );

  const { mutateAsync: verifyUserByAdmin, isLoading: isVerifyingUser } =
    useAdminVerifyUser();
  const [verifiedStatus, setVerifiedStatus] = useState(
    details?.user?.verified ? 1 : 0,
  );
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setVerifiedStatus(details?.user?.verified ? 1 : 0);
  }, [props?.details]);

  const onStatusChange = async (status) => {
    const verified = Boolean(status);

    try {
      await verifyUserByAdmin({
        user_uid: details?.user?.user_uid,
        verified,
      });
      setVerifiedStatus(status);
      await refetchCompany();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };
  return (
    <div className="company-details-container">
      <section className="company-info-section">
        <div className="company-info-container">
          <span className="info-label">Active Jobs</span>
          <span className="info-value">{details?.jobs_in_progress_count}</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Active High Flyers</span>
          <span className="info-value">
            {details?.students_in_progress_count}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Estimated monthly revenue</span>
          <span className="info-value">
            € {details?.estimated_monthly_revenue}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Phone number</span>
          <span className="info-value">{details?.user?.phone}</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">E-mail</span>
          <span className="info-value">{details?.user?.email}</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Website</span>
          <a
            className="info-value info-link"
            href={
              details?.website?.includes('http://')
                ? details?.website
                : 'http://' + details?.website
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {details?.website}
          </a>
        </div>

        <div className="company-info-container">
          <span className="info-label">Contact name</span>
          <span className="info-value">
            {details?.user?.first_name} {details?.user?.last_name}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">How did you find us?</span>
          <span className="info-value">
            {details?.user?.how_did_you_find_minite}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Registered since</span>
          <span className="info-value">
            {formatDate(details?.created_at, 'DD/MM/YYYY')}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Verified status</span>
          {isVerifyingUser ? (
            <Skeleton sx={{ height: 50, width: 225 }} animation="wave" />
          ) : (
            <Select
              id="status-select"
              variant="standard"
              onChange={(e) => onStatusChange(Number(e.target.value))}
              value={verifiedStatus}
            >
              <MenuItem value={0}>Unverified</MenuItem>
              <MenuItem value={1}>Verified</MenuItem>
            </Select>
          )}
        </div>

        <div className="company-info-container">
          <span className="info-label">Deactivate Reason</span>
          <span className="info-value">{details?.user?.deactivate_reason}</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Deactivated On</span>
          <span className="info-value">
            {formatDate(details?.user?.deactivated_at, 'DD/MM/YYYY')}
          </span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Service Fee</span>
          <span className="info-value">{details?.service_fee} %</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Direct Job Fee</span>
          <span className="info-value">€ {details?.direct_job_fee}</span>
        </div>

        <div className="company-info-container">
          <span className="info-label">Direct Job Remaining Credits</span>
          <span className="info-value">
            {details?.direct_job_remaining_credits}
          </span>
        </div>
      </section>
      <section className="company-info-section overview-grids">
        <p className="company-grid-title overview-grid-title">Jobs</p>
        <MiniteGrid
          pagination
          pageSize={8}
          columns={getCompanyJobs()}
          rows={getJobRows(jobsList)}
          rowId={'id'}
          rowsPerPageOptions={[8, 10, 20]}
          disableColumnFilter={true}
        />
      </section>
      <section className="company-info-section overview-grids">
        <p className="company-grid-title overview-grid-title">
          Received Proposals
        </p>
        <MiniteGrid
          pagination
          pageSize={8}
          columns={getCompanyProposals()}
          rows={getProposalRows(proposalsList)}
          rowId={'id'}
          rowsPerPageOptions={[8, 10, 20]}
          disableColumnFilter={true}
        />
      </section>
      <section className="company-info-section overview-grids">
        <p className="company-grid-title overview-grid-title">Hired students</p>
        <MiniteGrid
          pagination
          pageSize={8}
          columns={getCompanyStudents()}
          rows={getStudentRows(studentsList)}
          rowId={'id'}
          rowsPerPageOptions={[8, 10, 20]}
          disableColumnFilter={true}
        />
        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </section>
    </div>
  );
};

export default CompanyDetails;
