import React, { useState } from 'react';
import './WelcomeAboard.scss';
import { Button, InputAdornment, TextField } from '@mui/material';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import PercentIcon from '@mui/icons-material/Percent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../utils/consts';
import useDocumentTitle from '../../../../../utils/useDocumentTitle';
import { useUserData } from '../../../../../stores/auth';
import { UserData } from '../../../../../data/user';
import { useFetchStudentById } from '../../../../../data/profile';
import { isFirstYearStudent } from '../../../../../utils/helpers';

const WelcomeAboard = () => {
  useDocumentTitle('Schedule a call');

  const { t } = useTranslation('translation', { keyPrefix: 'welcome_aboard' });
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [percentage, setPercentage] = useState('');
  const { pathname } = useLocation();

  const userData = useUserData((state) => state.user);
  const user = userData as unknown as UserData;
  const { data: student } = useFetchStudentById(user?.student_uid);

  const isOnlyForDIY = isFirstYearStudent(student);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: () => {
      navigate(`${Routes.DashboardRoute}?student`, { replace: true });
      localStorage.setItem('scheduled', '1');
    },
  });

  const handlePercentage = (event: any) => {
    event.preventDefault();
    setPercentage(event.target.value);
  };

  const handleSubmit = () => {
    setSubmit(true);
  };

  const handleRedirectDashboard = async () => {
    window.location.pathname = '/';
  };

  const onDIYScreening = () => {
    window.open('https://www.minite.works/screening-call', '_blank');
  };

  return (
    <section className="welcome-section">
      <div className="title-container">
        <p className="title">{t('welcome_aboard')}</p>
        <p className="description">
          {isOnlyForDIY ? (
            <>
              {t('not_verified_1')}{' '}
              <a
                href="https://www.minite.works/screening-call"
                target="_blank"
                className="url"
              >
                DIY screening
              </a>
              {' ('}
              {t('please_read')}{' '}
              <a
                href="https://www.minite.works/kb-highflyers"
                target="_blank"
                className="url"
              >
                {t('not_verified_link_text')}
              </a>
              ).
              <Button
                color="info"
                variant="contained"
                onClick={onDIYScreening}
                className="button screening-button"
              >
                DIY SCREENING
              </Button>
            </>
          ) : (
            <>
              {t('not_verified_1')}{' '}
              <a
                href="https://www.minite.works/screening-call"
                target="_blank"
                className="url"
              >
                DIY screening
              </a>
              {t('not_verified_2')}{' '}
              <a
                href="https://www.minite.works/kb-highflyers"
                target="_blank"
                className="url"
              >
                {t('not_verified_link_text')}
              </a>
              ).
              <Button
                color="info"
                variant="contained"
                onClick={onDIYScreening}
                className="button screening-button"
              >
                DIY SCREENING
              </Button>
              {t('not_verified_3')}
            </>
          )}
        </p>
      </div>

      {isOnlyForDIY ? (
        pathname !== Routes.WelcomeAboard && (
          <Button
            sx={{
              width: '200px',
              marginTop: '24px',
            }}
            variant="contained"
            onClick={handleRedirectDashboard}
            className="submit-percentage-button"
            data-testid="book-call-later"
          >
            I’ll do this later
          </Button>
        )
      ) : (
        <div className="fee-container">
          <p className="title">
            {!submit ? t('what_percentage') : t('thats_correct')}
          </p>

          {!submit && (
            <>
              <TextField
                label={t('perc')}
                name="percentage"
                value={percentage}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon className="percentage-icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={handlePercentage}
              />
              <Button
                sx={{
                  width: '200px',
                  marginTop: '24px',
                }}
                variant="contained"
                disabled={!percentage || +percentage !== 90}
                onClick={handleSubmit}
                value={percentage}
                className="submit-percentage-button"
              >
                Submit
              </Button>
            </>
          )}

          {pathname !== Routes.WelcomeAboard && (
            <Button
              sx={{
                width: '200px',
                marginTop: '24px',
              }}
              variant="contained"
              onClick={handleRedirectDashboard}
              className="submit-percentage-button"
              data-testid="book-call-later"
            >
              I’ll do this later
            </Button>
          )}

          {submit && <InlineWidget url="https://calendly.com/linky-minite" />}
        </div>
      )}
    </section>
  );
};

export default WelcomeAboard;
