import { useQuery } from '@tanstack/react-query';
import { ServerResponse } from '../core/request';
import { COMPANIES_QUERY_KEY, COMPANY_QUERY_KEY } from './query-keys';
import axios from 'axios';
import { UserData } from './user';

const fetchCompanies = async (query?: string) => {
  try {
    const { data } = await axios.request<ServerResponse<CompaniesResponse>>({
      url: process.env.REACT_APP_API_URL + '/api/v1/kvk/companies',
      method: 'get',
      params: {
        handelsnaam: query,
      },
    });

    return data.data.resultaten;
  } catch (error) {
    return 'Something went wrong';
  }
};

export const isNotVerifiedCompany = (user: UserData | null) => {
  if (!user) return false;
  return user.company_uid && user.company.company_name && !user.verified;
};

export const fetchCompanyByKvk = async (kvkNumber: string) => {
  const { data } = await axios.request<ServerResponse<CompanyResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/kvk/companies/${kvkNumber}`,
    method: 'get',
  });

  return data.data;
};

export function useFetchCompanies(query: string) {
  return useQuery([COMPANIES_QUERY_KEY, query], () => fetchCompanies(query), {
    enabled: Boolean(query),
    select: (data) => {
      if (typeof data === 'string') {
        throw new Error(data);
      }

      return data.map((company) => {
        return {
          name: company.naam,
          kvkNumber: company.kvkNummer,
          locationNumber: company.vestigingsnummer,
          street: company.straatnaam,
          city: company.plaats,
          type: company.type,
        } as Company;
      });
    },
    onError: (e) => e,
  });
}

export function useFetchCompanyByKvk(kvkNumber: string, isPrefilled: boolean) {
  return useQuery(
    [COMPANY_QUERY_KEY, kvkNumber],
    () => fetchCompanyByKvk(kvkNumber),
    {
      select: (data) => {
        const companyName = data.eersteHandelsnaam;
        const kvkNumber = data.kvkNummer;
        const address = data.adressen[0];

        let street;

        if (address.straatnaam) {
          street = address.straatnaam;
        } else {
          [street] = address.volledigAdres.split(' ');
        }
        return {
          company: companyName,
          kvk: kvkNumber,
          street,
          house: address.huisnummer || address.postbusnummer,
          city: address.plaats,
          postal: address.postcode,
        };
      },
      enabled: isPrefilled && !!kvkNumber,
      onError: (e) => e,
    },
  );
}

export interface CompaniesResponse {
  resultaten: ApiCompany[];
}

export interface ApiCompany {
  kvkNummer: string;
  vestigingsnummer: string;
  naam: string;
  straatnaam: string;
  plaats: string;
  type: string;
}

export interface Company {
  name: string;
  kvkNumber: string;
  locationNumber: string;
  street: string;
  city: string;
  type: string;
}

export interface CompanyResponse {
  vestigingsnummer: string;
  kvkNummer: string;
  rsin: string;
  indNonMailing: string;
  formeleRegistratiedatum: string;
  materieleRegistratie: {
    datumAanvang: string;
    datumEinde: string;
  };
  eersteHandelsnaam: string;
  indHoofdvestiging: string;
  indCommercieleVestiging: string;
  voltijdWerkzamePersonen: number;
  totaalWerkzamePersonen: number;
  deeltijdWerkzamePersonen: number;
  handelsnamen: [
    {
      naam: string;
      volgorde: number;
    },
  ];
  adressen: [
    {
      type: string;
      indAfgeschermd: string;
      volledigAdres: string;
      straatnaam: string;
      huisnummer: string;
      huisnummerToevoeging: string;
      huisletter: string;
      toevoegingAdres: string;
      postcode: string;
      postbusnummer: number;
      plaats: string;
      straatHuisnummer: string;
      postcodeWoonplaats: string;
      regio: string;
      land: string;
      geoData: {
        addresseerbaarObjectId: string;
        nummerAanduidingId: string;
        gpsLatitude: number;
        gpsLongitude: number;
        rijksdriehoekX: number;
        rijksdriehoekY: number;
        rijksdriehoekZ: number;
      };
    },
  ];
  websites: [string];
  sbiActiviteiten: [
    {
      sbiCode: string;
      sbiOmschrijving: string;
      indHoofdactiviteit: string;
    },
  ];
  links: [
    {
      rel: string;
      href: string;
      hreflang: string;
      media: string;
      title: string;
      type: string;
      deprecation: string;
      profile: string;
      name: string;
    },
  ];
}
