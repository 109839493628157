import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import Search from '../../../components/search';
import { useDebounce } from '../../../utils/useDebounce';
import EventLogsTable from './EventLogsTable';
import { IEventLog, useFetchEventLogs } from '../../../data/admin-event-logs';
import MaterialReactTable from 'material-react-table';
import './EventLogs.scss';
import FiltersPanel from '../../../components/filter-panel';
import { formatDate } from '../../../utils/dates';

const AdminEventLogs = () => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    created_min_date: '',
    created_max_date: '',
  });

  const debouncedSearch = useDebounce(search, 500);
  const {
    data: auditsData,
    refetch,
    isLoading,
  } = useFetchEventLogs({ ...filters, query: debouncedSearch });
  const { pagination, audits } = auditsData || {};

  const eventsColumns = useMemo(() => {
    return [
      {
        accessorKey: 'created_at',
        header: 'Date',
        enableSorting: true,
      },
      {
        accessorKey: 'operation',
        header: 'Operation',
        enableSorting: true,
      },
      {
        accessorKey: 'object_type',
        header: 'Object Type',
        enableSorting: true,
      },
      {
        accessorKey: 'record_uid',
        header: 'Object ID',
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: 'Object Name',
        enableSorting: false,
      },

      {
        accessorKey: 'user_type',
        header: 'User Type',
        enableSorting: true,
      },

      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
      },
    ];
  }, []);

  const getEntityType = (audit: IEventLog) => {
    const { table_name } = audit;

    let object_type = table_name;

    switch (table_name) {
      case 'companies':
        object_type = 'Company';
        break;
      case 'jobs':
        object_type = 'Job';
        break;
      case 'managers':
        object_type = 'Manager';
        break;
      case 'paynments':
        object_type = 'Payment';
        break;
      case 'proposal_requests':
        object_type = 'Proposal Request';
        break;
      case 'proposals':
        object_type = 'Proposal';
        break;
      case 'reevaluation_requests':
        object_type = 'Reevaluation Request';
        break;
      case 'reviews':
        object_type = 'Review';
        break;
      case 'students':
        object_type = 'Student';
        break;
      case 'time_entries':
        object_type = 'Time Entry';
        break;
      case 'timesheets':
        object_type = 'Timesheet';
        break;
      case 'users':
        object_type = 'User';
        break;
    }
    return object_type;
  };

  const getEntityName = (audit: IEventLog) => {
    const { previous_state, updated_state } = audit;

    const state = updated_state || previous_state;

    const { table_name } = audit;

    let name;

    switch (table_name) {
      case 'companies':
        name = state.company_name;
        break;
      case 'jobs':
        name = state.job_title;
        break;
      case 'managers':
        name = state.manager_uid;
        break;
      case 'paynments':
        name = state.payment_description;
        break;
      case 'proposal_requests':
        name = state.proposal_request_uid;
        break;
      case 'proposals':
        name = state.proposal_uid;
        break;
      case 'reevaluation_requests':
        name = state.reevaluation_request_uid;
        break;
      case 'reviews':
        name = state.review_uid;
        break;
      case 'students':
        name = state.student_uid;
        break;
      case 'time_entries':
        name = state.time_entry_uid;
        break;
      case 'timesheets':
        name = state.timesheet_uid;
        break;
      case 'users':
        name = `${state.first_name} ${state.last_name}`;
        break;
    }
    return name || '-';
  };

  const getEntityDetails = (audit: IEventLog) => {
    const columns = [
      {
        accessorKey: 'property',
        header: 'Property',
        enableSorting: true,
      },
      {
        accessorKey: 'old_value',
        header: 'Old value',
        enableSorting: true,
      },
      {
        accessorKey: 'new_value',
        header: 'New value',
        enableSorting: true,
      },
    ];

    const { previous_state, updated_state } = audit;
    const data = [];
    let i = 0;

    for (const key of Object.keys(updated_state)) {
      if (updated_state[key] !== previous_state[key]) {
        let old_value = previous_state[key];
        let new_value = updated_state[key];

        if (typeof new_value === 'boolean') {
          new_value = new_value ? 'Yes' : 'No';
        }

        if (key === 'time_entries') {
          old_value = previous_state[key]?.length
            ? previous_state[key]?.length.toString()
            : '0';
          new_value = updated_state[key]?.length
            ? updated_state[key]?.length
            : '0';
        }

        if (key && (old_value || new_value)) {
          // check if this field is a date, otherwise it will just be ignored
          if (old_value && !isNaN(new Date(old_value).getTime())) {
            old_value = new Date(old_value).toLocaleString();
          }

          if (new_value && !isNaN(new Date(new_value).getTime())) {
            new_value = new Date(new_value).toLocaleString();
          }

          data.push({
            id: i,
            property: key,
            old_value,
            new_value,
          });
        }
        i++;
      }
    }

    return (
      <div className="event-log-detail">
        <MaterialReactTable
          columns={columns}
          data={data}
          getRowId={(row) => row.id}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
        />
      </div>
    );
  };
  const eventsGridData = () => {
    return audits
      ? audits.map((audit) => {
          const {
            created_at: created_at_utc,
            operation,
            email,
            audit_id: id,
            record_uid,
            user_type,
          } = audit;

          const name = getEntityName(audit);
          const object_type = getEntityType(audit);
          const created_at = new Date(created_at_utc).toLocaleString();
          return {
            id,
            created_at,
            operation,
            object_type,
            name,
            email,
            record_uid,
            user_type,
            details: getEntityDetails(audit),
          };
        })
      : [];
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const handleFilters = (customFilters) => {
    const filters = { ...customFilters };

    if ('start_date' in filters) {
      filters.start_date = formatDate(filters.start_date, 'MM/YYYY');
    }
    if ('end_date' in filters) {
      filters.end_date = formatDate(filters.end_date, 'MM/YYYY');
    }
    setFilters(filters);
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-revenue-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <div>
        <p className="section-title">Event logs</p>
        <div className="admin-table-inputs events-filter-wrapper">
          <Search
            setSearch={setSearch}
            className="search-jobs-input"
            value={search}
          />
          <FiltersPanel
            page="admin-event-logs"
            filters={filters}
            changeFilters={(customFilters) => {
              const { page, per_page, query } = filters;
              handleFilters({ page, per_page, query, ...customFilters });
            }}
          />
        </div>
      </div>
      <EventLogsTable
        pageSize={eventsGridData()?.length}
        enableExpanding={true}
        columns={eventsColumns}
        rows={eventsGridData()}
        page={pagination?.page || 1}
        onSortChange={handleSortModelChange}
        onPageChange={handleChangePage}
        rowCount={pagination?.total || 0}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default AdminEventLogs;
