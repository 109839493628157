import React from 'react';

import { Alert, AlertTitle, Grid, Skeleton, Typography } from '@mui/material';

import './DoAssessment.scss';
import { useTranslation } from 'react-i18next';

const CantAssessment = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={12}>
          {loading ? (
            <Skeleton />
          ) : (
            <Alert severity="warning" className="student-scheduler-call">
              <AlertTitle className="info-title">
                {t('assessment.no_pass')}
              </AlertTitle>
              <Typography className="info-text">
                {t('assessment.try_again_later')}
              </Typography>
            </Alert>
          )}
        </Grid>
      </Grid>
    </section>
  );
};

export default CantAssessment;
