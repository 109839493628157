import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import './JobAccountForms.scss';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { JobForm } from '../../../../../stores/job-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Location, StudentLevels } from '../constants';
import { useFetchCategories } from '../../../../../data/shared';
import { useUpdateAdminJobDetails } from '../../../../../data/admin';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../../../utils/dates';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';

export const AdminJobDetailsForm = ({
  job,
  refetch,
}: {
  job: JobForm;
  refetch: () => void;
}) => {
  const schema = yup
    .object()
    .shape({
      category_uid: yup.string().required(),
      sub_category_uid: yup.string().nullable().optional(),
      sub_category_name: yup.string().nullable().optional(),
      job_location: yup.string().required(),
      student_type: yup.string().required(),
      job_start_at: yup.date().required(),
      job_end_at: yup
        .date()
        .nullable()
        .when('job_start_at', (startDate) => {
          if (startDate && !isNaN(new Date(startDate).getDate())) {
            return yup
              .date()
              .min(startDate, 'must be greater than start date')
              .nullable()
              .optional();
          }
          return yup.date().nullable().optional();
        }),
      students_needed: yup.number().required().min(1),
      office_location: yup.string().nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors, isDirty, isSubmitted },
    control,
    trigger,
    reset,
  } = useForm<JobForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      category_uid: job?.category_uid,
      sub_category_uid: job?.sub_category_uid,
      job_start_at: job?.job_start_at,
      job_location: job?.job_location,
      job_end_at: job?.job_end_at,
      students_needed: job?.students_needed,
      student_type: job?.student_type,
      office_location: job?.office_location,
    },
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { id: jobId } = useParams<{ id?: string }>();
  const { mutateAsync: updateAdminJobDetails } = useUpdateAdminJobDetails();

  const categoryUid = useWatch({
    control,
    defaultValue: job?.category_uid,
    name: 'category_uid',
  });

  const [subCategories, setSubCategories] = useState([]);
  const { data: categories = [] } = useFetchCategories();

  useEffect(() => {
    if (categoryUid && categories.length > 0) {
      setSubCategories(
        categories.find((category) => category.category_uid === categoryUid)
          ?.sub_categories,
      );
    }
  }, [categoryUid, categories]);

  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_description',
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload: any = {
        job: {
          category_uid: data?.category_uid,
          sub_category_uid: data?.sub_category_uid,
          job_start_at: formatDate(data?.job_start_at, 'YYYY/MM/DD'),
          job_location: data?.job_location,
          job_end_at: formatDate(data?.job_end_at, 'YYYY/MM/DD') || null,
          students_needed: data?.students_needed,
          student_type: data?.student_type,
          office_location: data.office_location,
        },
      };

      await updateAdminJobDetails({
        jobId,
        jobDetails: payload,
      });

      refetch();

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="">
        <Grid
          container
          direction="row"
          className="job-form-grid"
          alignItems="stretch"
          gap={2}
          sx={{ marginTop: '24px' }}
        >
          <Grid item xs={12} md={2}>
            <p className="job-forms-titles">Job details</p>
          </Grid>

          <Grid item xs={12} md={9}>
            <form className="describe-job-form">
              <div className="form-group">
                <Controller
                  name="category_uid"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="standard">
                      <InputLabel id="category-select-label">
                        Category
                      </InputLabel>
                      <Select
                        id="status-select"
                        variant="standard"
                        value={value}
                        onChange={onChange}
                        error={!!errors.category_uid}
                      >
                        {categories.map((item) => {
                          const { category_uid, category_name } = item;

                          return (
                            <MenuItem key={category_uid} value={category_uid}>
                              {category_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name="sub_category_uid"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="standard">
                      <InputLabel id="category-select-label">
                        Subcategory
                      </InputLabel>
                      <Select
                        id="status-select"
                        variant="standard"
                        value={value}
                        onChange={onChange}
                        error={!!errors.sub_category_uid}
                      >
                        {subCategories
                          ?.filter(
                            (subCategory) => subCategory.sub_category_uid,
                          )
                          .map((item) => {
                            const { sub_category_uid, sub_category_name } =
                              item;

                            return (
                              <MenuItem
                                key={sub_category_uid}
                                value={sub_category_uid}
                              >
                                {sub_category_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <div className="form-group">
                <Controller
                  name="job_location"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="standard">
                      <InputLabel id="category-select-label">
                        Location
                      </InputLabel>
                      <Select
                        id="status-select"
                        variant="standard"
                        value={value}
                        onChange={onChange}
                        error={!!errors.job_location}
                      >
                        {Location.map((item) => {
                          const { id, label } = item;
                          return (
                            <MenuItem key={id} value={id}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    type="string"
                    id="office-location"
                    label={'City of office'}
                    error={Boolean(errors.office_location)}
                    variant="standard"
                    sx={{ width: '100%' }}
                    {...register('office_location')}
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl>
                  <Controller
                    name="job_start_at"
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={'Start date'}
                          inputFormat="DD/MM/YYYY"
                          onChange={(startDate) => {
                            onChange(startDate);
                            trigger();
                          }}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <TextField
                              type="text"
                              ref={inputRef}
                              inputProps={inputProps}
                              error={Boolean(errors.job_start_at)}
                              variant="standard"
                              id="startDate"
                              label={'Start date'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={InputProps}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    name="job_end_at"
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={'End date'}
                          inputFormat="DD/MM/YYYY"
                          onChange={(endDate) => {
                            onChange(endDate);
                            trigger();
                          }}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <TextField
                              type="text"
                              ref={inputRef}
                              helperText={
                                !!errors.job_end_at
                                  ? `${errors?.job_end_at?.message}`
                                  : '* Optional'
                              }
                              error={Boolean(errors.job_end_at)}
                              inputProps={inputProps}
                              variant="standard"
                              id="endDate"
                              label={'End date'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={InputProps}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <Controller
                  name="student_type"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="standard">
                      <InputLabel id="category-select-label">
                        Job seniority level
                      </InputLabel>
                      <Select
                        id="status-select"
                        variant="standard"
                        value={value}
                        onChange={onChange}
                        error={!!errors.student_type}
                      >
                        {StudentLevels.map((item) => {
                          const { id, label } = item;
                          return (
                            <MenuItem key={id} value={id}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    type="number"
                    id="students-count"
                    label={'Students needed'}
                    error={Boolean(errors.students_needed)}
                    variant="standard"
                    inputProps={{
                      min: 1,
                    }}
                    sx={{ width: '100%' }}
                    {...register('students_needed')}
                  />
                </FormControl>
              </div>
            </form>
            <div className="save-button-container">
              <LoadingButton
                isDisabled={!isValid || !isDirty}
                className="save-button"
                onClick={handleSubmit(onSubmit)}
                buttonText={
                  !isDirty && isSubmitted
                    ? t('changes_saved')
                    : t('save_changes')
                }
                isLoading={isLoading}
                confirmButtonTestId="save-job-details-admin"
              />
            </div>
          </Grid>
        </Grid>

        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </section>
    </>
  );
};
