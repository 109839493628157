import { PasswordRequirements } from '../../password-requirements';
import { BackButton } from '../controls/BackButton';

export const ConfirmResetPasswordFooter = () => {
  return (
    <>
      <PasswordRequirements />;
      <BackButton page={'Confirm Reset Password'} />
    </>
  );
};
