import React, { useState } from 'react';
import './AccountForms.scss';
import { Alert, Grid, Link, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UserData } from '../../../../data/user';
import { useUpdateUserProfile } from '../../../../data/edit-profile';
import { UserType } from '../../../../components/sidepanel/UserType';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';
import { useUserData } from '../../../../stores/auth';

import { isValidIBAN } from 'ibantools';
interface FinancialFormInterface {
  invoice_email: string;
  iban: string;
}

const FinancialForm = () => {
  const userData = useUserData((state) => state.user);
  const refetch = useUserData((state) => state.refetchUser);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const user: UserData = userData as unknown as UserData;
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const [isLoading, setIsLoading] = useState(false);

  const schemaFields = {};

  if (userData.student_uid) {
    schemaFields['iban'] = yup
      .string()
      .nullable()
      .test(
        'test-number', // this is used internally by yup
        'IBAN is invalid', //validation message
        (value) => isValidIBAN(value),
      ); // return true false based on validation,
  }

  if (userData.company_uid) {
    schemaFields['invoice_email'] = yup.string().email().nullable();
  }
  const schema = yup.object().shape(schemaFields).required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<FinancialFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      invoice_email: user?.invoice_email,
      iban: user?.iban,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: FinancialFormInterface) => {
    setIsLoading(true);

    const payload =
      user?.type === UserType.Company
        ? { invoice_email: data?.invoice_email }
        : { iban: data?.iban };
    await updateUserProfile({
      user: payload,
    });

    await refetch();

    reset(data);
    setIsLoading(false);
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">
            {t('financial_information')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            {user.student_uid && user.stripe_account_id && (
              <Alert severity="info">
                {t('stripe_1')}{' '}
                <Link
                  href="https://connect.stripe.com/express_login"
                  target="_blank"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  (https://connect.stripe.com/express_login)
                </Link>{' '}
                {t('stripe_2')}
                <ol>
                  <li>{t('stripe_3')}</li>
                  <li>{t('stripe_4')}</li>
                  <li>{t('stripe_5')}</li>
                  <li>{t('stripe_6')}</li>
                </ol>
              </Alert>
            )}
            {user?.type === UserType?.Company && (
              <TextField
                className="form-input"
                {...register('invoice_email')}
                name="invoice_email"
                type="text"
                id="invoice_email-input"
                variant="standard"
                label={t('invoice_email')}
                error={!!errors.invoice_email}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {user?.type === UserType?.Student && (
              <TextField
                className="form-input"
                {...register('iban')}
                name="iban"
                type="text"
                id="iban-input"
                variant="standard"
                label="IBAN"
                error={!!errors.iban}
                InputLabelProps={{ shrink: true }}
                helperText={errors.iban?.message}
              />
            )}
          </form>

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-financial-info"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default FinancialForm;
