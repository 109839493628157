import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { JobData, JobStatuses } from '../../../data/jobs';

interface Props {
  open: boolean;
  title: string;
  description: string;
  handleClose: () => void;
  handleConfirm: (reason: string, endDate: string) => void;
  jobData: JobData;
}

const CancelReasonModal = ({
  open,
  title,
  description,
  handleClose,
  handleConfirm,
  jobData,
}: Props) => {
  const [reason, setReason] = useState('');
  const [hasSelectedReason, setHasSelectedReason] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const { t } = useTranslation('translation', {
    keyPrefix: 'job_details.cancel_job_reason_modal',
  });

  useEffect(() => {
    if (reason === '') {
      setHasSelectedReason(false);
    } else {
      setHasSelectedReason(true);
    }
  }, [reason]);

  useEffect(() => {
    const today = new Date();
    const startedAt = new Date(jobData?.job_start_at);
    const min3Months = new Date(
      startedAt.getFullYear(),
      startedAt.getMonth() + 4,
      0,
    );
    const min1Month = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    setMinDate(min3Months > min1Month ? min3Months : min1Month);
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} className="cancel-job-modal">
      <DialogTitle className="content-title">
        {title}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          {description}
        </DialogContentText>
        <TextField
          id="reason"
          label="Reason"
          variant="standard"
          multiline
          maxRows={4}
          sx={{ width: '100%', marginBottom: '20px' }}
          onChange={(e) => setReason(e.target.value)}
        />
        {jobData.job_status === JobStatuses.IN_PROGRESS && (
          <>
            <DialogContentText className="content-text">
              What is your job end date?
            </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Job end date"
                minDate={minDate}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    helperText="The agreement has a minimum duration of 3 months. After that, the agreement can be terminated taking into account a notice period of 1 calendar month."
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setReason('');
            setHasSelectedReason(false);
            handleClose();
          }}
          className="button"
          data-testid="close-cancel-reason-modal"
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            handleConfirm(reason, new Date(endDate).toDateString());
          }}
          className="button agree"
          autoFocus
          data-testid="confirm-cancel-reason"
          disabled={
            !hasSelectedReason ||
            (jobData.job_status === JobStatuses.IN_PROGRESS && !endDate)
          }
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelReasonModal;
