import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  JOB_PROPOSALS_DETAILS,
  JOB_STUDENTS_DETAILS,
  JOB_DETAILS,
} from './query-keys';
import {
  AdminEducation,
  AdminUniversity,
  JobResponse,
} from './admin-companies';
import {
  JobLocation,
  JobStatus,
  JobType,
  ProposalStatus,
  StudentType,
} from './jobs';
import { Language, Skill } from '../stores/job-form';

const fetchJob = async (job_uid: string) => {
  const { data } = await request<ServerResponse<JobForm>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/${job_uid}`,
    method: 'get',
  });

  return data.data;
};

export const useFetchJob = (job_uid: string) => {
  return useQuery([JOB_DETAILS, job_uid], () => fetchJob(job_uid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const jobDetails = async (job_uid: string) => {
  const { data } = await request<ServerResponse<JobDetails>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/${job_uid}`,
    method: 'get',
  });

  return data.data;
};

export const useJobDetails = (job_uid: string) => {
  return useQuery([JOB_DETAILS, job_uid], () => jobDetails(job_uid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const jobStudentsDetails = async (job_uid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/students`,
    method: 'get',
  });

  return data.data as AdminJobStudents;
};

export const useJobStudentsDetails = (job_uid: string) => {
  return useQuery(
    [JOB_STUDENTS_DETAILS, job_uid],
    () => jobStudentsDetails(job_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};
const jobProposalsDetails = async (job_uid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/proposals`,
    method: 'get',
  });

  return data.data as AdminJobProposals;
};

export const useJobProposalsDetails = (job_uid: string) => {
  return useQuery(
    [JOB_PROPOSALS_DETAILS, job_uid],
    () => jobProposalsDetails(job_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

export interface AdminJobStudents {
  students: AdminJobStudent[];
}

export interface AdminJobStudent {
  proposal_uid: string;
  rate: number;
  student_type: string;
  student: StudentUserResponse;
  universities: AdminUniversity[];
  education: AdminEducation[];
}

export interface StudentUserResponse {
  student_uid: string;
  first_name: string;
  last_name: string;
  alias: string;
}

export interface AdminJobProposals {
  proposals: AdminJobProposal[];
}

export interface AdminJobProposal {
  proposal_uid: string;
  created_at: string;
  proposal_status: ProposalStatus;
  student_uid: string;
  student: StudentUserResponse;
  job: JobResponse;
  rate: number;
  alias: string;
}

export interface JobForm {
  parent_job_uid?: string | null;
  proposals_count?: number;
  job_uid: string;
  job_title: string;
  job_description: string;
  category_uid: string;
  sub_category_uid?: string;
  sub_category_name?: string;
  job_start_at: any;
  job_end_at?: any;
  hours_estimated: number | null;
  budget: number;
  payment_uid?: string;
  student_type: StudentType;
  students_needed: number;
  office_location: string;
  question_required: string;
  question_optional?: string;
  job_boost_pack: number;
  job_type: JobType;
  job_status: JobStatus;
  job_location: JobLocation;
  skills: Skill[];
  languages: Language[];
  created_at?: string;
  company?: {
    company_name: string;
    company_uid: string;
  };
  direct_job?: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;
  views?: string;
  unique_views?: string;
  job_completed_reason?: string;
  job_cancelled_reason?: string;
  completed_at?: string;
  cancelled_at?: string;
  matched_at?: string;
  invoices?: any[];
  timesheets?: any[];
  payment?: { mollie_payment_id: string };
  children_jobs?: number;
}

export interface JobDetails {
  job_uid: string;
  parent_job_uid: string | null;
  category_uid: string;
  sub_category_uid?: string;
  job_title: string;
  open_at: string;
  created_at: string;
  job_start_at: string;
  job_end_at?: string;
  job_type: JobType;
  student_type: StudentType;
  students_needed: number;
  students_applied: string;
  proposals_count: number;
  question_optional?: string;
  question_required: string;
  job_location: JobLocation;
  office_location?: string;
  job_status: JobStatus;
  budget: number;
  hours_estimated: number;
  interest_uid: string;
  job_description: string;
  company: {
    company_uid: string;
    company_name: string;
    rating: number;
    avatar: string;
    interest_uid: string;
    bio: string;
    website: string;
  };
  category: {
    category_uid: string;
    category_name: string;
    code: string;
  };
  sub_category: {
    sub_category_uid: string;
    sub_category_name: string;
    code: string;
  };
  address: {
    city: string;
    postal_code: string;
    street_name: string;
    house_number: string;
  };
  languages: {
    language_uid: string;
    language_name: string;
  }[];
  skills: {
    skill_uid: string;
    skill_name: string;
  }[];
  reviewed: boolean;
  requested_to_complete_at?: string;
  requested_complete_date?: string;
  job_cancelled_reason?: string;
  job_boost_pack?: string;
  direct_job?: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;
  pinned?: boolean;
  is_hot?: boolean;
}
