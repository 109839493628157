import { useMutation, useQuery } from '@tanstack/react-query';
import { request, ServerResponse } from '../core/request';
import { ADMIN_JOBS, ADMIN_USERS } from './query-keys';
import { JobStatus, ProposalStatus } from './jobs';
import { CompanyResponse, ProposalJobResponse } from './proposals';
import { AxiosError } from 'axios';

// Requests

const fetchAdminJobs = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs`,
    method: 'get',
  });

  return data.data;
};

export const useFetchAdminJobs = () => {
  return useQuery([ADMIN_JOBS], () => fetchAdminJobs(), {
    onError: (e) => console.error(e),
  });
};

const updateAdminJobStatus = async (jobId: string, jobStatus: JobStatus) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/${jobId}`,
    method: 'put',
    data: { job: { job_status: jobStatus } },
  });
  if (!data.success)
    throw new Error('update job status failed, code: ' + data.code);
};

const removeJob = async (jobUid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/${jobUid}`,
    method: 'delete',
  });
  return data;
};

export function useRemoveJob() {
  return useMutation((jobUid: string) => removeJob(jobUid), {
    onError: (e: AxiosError) => e,
  });
}

export const useUpdateAdminJobStatus = () => {
  return useMutation(
    (payload: any) => updateAdminJobStatus(payload.jobId, payload.jobStatus),
    {
      onError: (e) => console.error(e),
    },
  );
};

const updateAdminJobDetails = async (jobId: string, jobDetails: any) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/${jobId}`,
    method: 'put',
    data: jobDetails,
  });
  if (!data.success)
    throw new Error('update job details failed, code: ' + data.code);
};

export const useUpdateAdminJobDetails = () => {
  return useMutation(
    (payload: any) => updateAdminJobDetails(payload.jobId, payload.jobDetails),
    {
      onError: (e) => console.error(e),
    },
  );
};

export const fetchAdminUsers = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/users`,
    method: 'get',
  });

  return data.data;
};

export const useFetchAdminUsers = () => {
  return useQuery([ADMIN_USERS], () => fetchAdminUsers(), {
    onError: (e) => console.error(e),
  });
};

export const fetchProposalInfoByProposalUid = async (proposal_uid: string) => {
  const { data } = await request<ServerResponse<ProposalResponse>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/proposals/${proposal_uid}`,
    method: 'get',
  });

  return data.data;
};

const updateUserByAdmin = async (
  userId: string,
  dto: {
    user?: {
      verified: boolean;
    };
    student?: {
      approved: boolean;
    };
  },
) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/users/${userId}`,
    method: 'put',
    data: dto,
  });
  if (!data.success)
    throw new Error('update user verified status failed, code: ' + data.code);
};

export const useAdminUpdateUser = () => {
  return useMutation(
    (payload: any) => updateUserByAdmin(payload.userId, payload.dto),
    {
      onError: (e) => console.error(e),
    },
  );
};

const verifyUserByAdmin = async (payload: {
  user_uid: string;
  verified: boolean;
}) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/users/verify/${payload.user_uid}`,
    method: 'put',
    data: { verified: payload.verified },
  });
  return data;
};

export const useAdminVerifyUser = () => {
  return useMutation(
    (payload: { user_uid: string; verified: boolean }) =>
      verifyUserByAdmin(payload),
    {
      onError: (e) => console.error(e),
    },
  );
};

const resetPasswordByAdmin = async (user_uid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/users/${user_uid}/password_reset`,
    method: 'post',
  });

  return data.data;
};

export const useResetPasswordByAdmin = (user_uid: string) => {
  return useMutation(() => resetPasswordByAdmin(user_uid), {
    onError: (e) => console.error(e),
  });
};

const creatOtjCreditInvoice = async (job_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${job_uid}/create-otj-credit-invoice`,
    method: 'POST',
  });

  return data;
};

export function useCreatOtjCreditInvoice() {
  return useMutation(
    (payload: { job_uid: string }) => creatOtjCreditInvoice(payload.job_uid),
    {
      onError: (e) => console.error(e),
    },
  );
}

export interface IProposal {
  proposal_uid: string;
  created_at: string;
  job_uid: string;
  student_uid: string;
  proposal_status: ProposalStatus;
  rate: number;
  student_name: string;
  counter_offer_description: string;
  min_month?: number;
  how_you_do_x: string;
  how_you_do_y?: string;
  company: CompanyResponse;
  job: ProposalJobResponse;
  pdf_url?: string;
  x_sentiment?: string;
  y_sentiment?: string;
  interview_prediction?: string;
}

export interface ProposalResponse {
  proposal: IProposal;
}
