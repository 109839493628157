import { request, ServerResponse } from '../core/request';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const logSupportRequest = async (body: SupportRequest) => {
  const {
    data: { data },
  } = await request<ServerResponse<SupportRequest>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/support_requests',
    method: 'post',
    data: body,
  });

  return { data };
};

export function useLogSupport() {
  return useMutation((body: SupportRequest) => logSupportRequest(body), {
    onError: (e: AxiosError) => e,
  });
}

export interface SupportRequest {
  category: string;
  urgency_level: string;
  message: string;
}
