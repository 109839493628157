import { useTranslation } from 'react-i18next';
import { Radio, RadioGroupField } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import React from 'react';

interface IProps {
  handleOnChange: (val: string) => void;
  userType: string;
  isError: boolean;
}

export const UserTypeField = ({
  handleOnChange,
  userType,
  isError,
}: IProps) => {
  const { t } = useTranslation();

  const onChange = (event) => {
    handleOnChange(event.target.value);
  };

  const buttonVariant = (type) => {
    return userType === type ? 'contained' : 'outlined';
  };

  return (
    <div className="user-role">
      <div style={{ opacity: 0 }}>
        <RadioGroupField
          name="custom:signup"
          label=""
          direction="row"
          value={userType}
          key="custom:signup"
          isRequired={true}
          className="radio-group"
          onChange={onChange}
        >
          <Radio value="student" checked={userType === 'student'}></Radio>
          <Radio value="company" checked={userType === 'company'}></Radio>
        </RadioGroupField>
      </div>

      <div className="user-role-wrapper">
        <Button
          variant={buttonVariant('student')}
          onClick={onChange}
          className="button"
          value="student"
          data-testid="user-type-student"
        >
          {t('select_role.i_student')}
        </Button>
        <Button
          variant={buttonVariant('company')}
          onClick={onChange}
          className="button"
          value="company"
          data-testid="user-type-company"
        >
          {t('select_role.i_company')}
        </Button>
      </div>
      {isError && !userType && (
        <p className="errorMessage">{t('select_role.student_or_company')}</p>
      )}
    </div>
  );
};
