import React from 'react';
import { useTranslation } from 'react-i18next';

import { JobStatus, JobStatuses } from '../../../data/jobs';
import { Chip } from '@mui/material';
import './ProposalStatusChip.scss';

interface Props {
  jobStatus: JobStatus;
}

const getBorderColor = (jobStatus: JobStatus) => {
  switch (jobStatus) {
    case JobStatuses.DRAFT:
    case JobStatuses.PENDING_APPROVAL:
      return '#4E5B73';
    case JobStatuses.IN_PROGRESS:
    case JobStatuses.MATCHED:
    case JobStatuses.SIGNED:
    case JobStatuses.COMPLETED:
      return '#14DB87';
    case JobStatuses.OPEN:
    case JobStatuses.INTERVIEWING:
      return '#1976d2';
    case JobStatuses.REJECTED:
    case JobStatuses.CANCELLED:
      return '#EA4335';
    default:
      return '#4E5B73';
  }
};

const getBgColor = (jobStatus: JobStatus) => {
  switch (jobStatus) {
    case JobStatuses.MATCHED:
    case JobStatuses.SIGNED:
    case JobStatuses.IN_PROGRESS:
      return '#14DB87';
    case JobStatuses.OPEN:
    case JobStatuses.INTERVIEWING:
      return '#1976d2';
    case JobStatuses.REJECTED:
    case JobStatuses.CANCELLED:
      return '#EA4335';
    default:
      return 'transparent';
  }
};

const getColor = (jobStatus: JobStatus) => {
  switch (jobStatus) {
    case JobStatuses.OPEN:
    case JobStatuses.INTERVIEWING:
    case JobStatuses.REJECTED:
    case JobStatuses.CANCELLED:
      return '#ffffff';
    default:
      return '#393A42';
  }
};

const JobStatusChip = ({ jobStatus }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'job_statuses' });

  const color = getColor(jobStatus);
  const borderColor = getBorderColor(jobStatus);
  const bgColor = getBgColor(jobStatus);

  const handleChipClick = () => {
    console.log('TBD');
  };

  return (
    <Chip
      onClick={handleChipClick}
      label={t(jobStatus)}
      className={`job-status ${jobStatus}`}
      variant="outlined"
      sx={{
        borderColor,
        bgcolor: bgColor,
        color,
        width: 'auto',
        minWidth: 'auto',
        textTransform: 'capitalize',
      }}
    />
  );
};

export default JobStatusChip;
