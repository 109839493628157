import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './HighlightedProfiles.scss';
import HighlightedProfileCard from './HighlightedProfileCard';
import { StudentType } from '../../data/jobs';

export interface HighlightedProfilesEducation {
  study: string;
  degree: string;
  ended_at: string;
}

export interface HighlightedProfilesStudent {
  student_uid: string;
  student_type: StudentType;
  one_liner?: string;
  rating: number;
  alias: string;
}

export interface HighlightedProfilesUniversity {
  university_uid: string;
  university_name: string;
}

export interface HighlightedProfilesUser {
  user_uid: string;
  avatar: string;
}

export interface HighlightedProfiles {
  talent_pool_uid: string;
  student_uid: string;
  education: Array<HighlightedProfilesEducation>;
  student: HighlightedProfilesStudent;
  university: HighlightedProfilesUniversity;
  user: HighlightedProfilesUser;
}

interface Props {
  dashboard?: boolean;
  pool: HighlightedProfiles[];
}

const HighlightedProfiles = (props: Props) => {
  const { dashboard, pool } = props;

  const renderCards = () => {
    const cards = [];
    for (let i = 0; i < pool.length; i++) {
      cards.push(
        <HighlightedProfileCard {...pool[i]} dashboard={dashboard} key={i} />,
      );
    }
    return cards;
  };

  const desktopItems = 3;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: desktopItems,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: desktopItems,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="highlighted-section">
      {pool?.length > 0 && (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          centerMode={false}
          arrows={false}
          ssr={false}
          infinite={false}
          autoPlay={false}
          renderDotsOutside={true}
          renderButtonGroupOutside={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass=""
        >
          {renderCards()}
        </Carousel>
      )}
    </div>
  );
};

export default HighlightedProfiles;
