import React from 'react';
import './CompanyOverview.scss';
import AdminCompanyAccountForm from './admin-company-edit-form/AdminCompanyAccountForm';
import AdminCompanyForm from './admin-company-edit-form/AdminCompanyForm';
import AdminCompanyFinancialForm from './admin-company-edit-form/AdminCompanyFinancialForm';
import AdminCompanyProfileForm from './admin-company-edit-form/AdminCompanyProfileForm';
import { AdminCompanyOverview } from '../../../../data/admin-companies';
import AdminCompanyIndustry from './admin-company-edit-form/AdminCompanyIndustry';
import { Grid } from '@mui/material';

const CompanyEdit = (props) => {
  const details = (props.details as AdminCompanyOverview) || {};
  const refetch = props.refetchCompany;
  return (
    <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
      <AdminCompanyAccountForm details={details} refetch={refetch} />
      <AdminCompanyForm details={details} refetch={refetch} />
      <AdminCompanyIndustry details={details} refetch={refetch} />
      <AdminCompanyFinancialForm details={details} refetch={refetch} />
      <AdminCompanyProfileForm details={details} refetch={refetch} />
    </Grid>
  );
};

export default CompanyEdit;
