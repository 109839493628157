import React, { useEffect, useState } from 'react';
import { Routes } from '../../../utils/consts';
import {
  Breadcrumbs,
  Link,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Skeleton,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import JobStatusChip from '../../job-details/components/JobStatusChip';
import './AdminTimesheetDetails.scss';
import MiniteGrid from '../../../components/grid';
import {
  extractQueryParameter,
  handleGridHeight,
} from '../../../utils/helpers';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import PaidIcon from '@mui/icons-material/Paid';
import Divider from '@mui/material/Divider';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  GridColDef,
  GridFooterContainer,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import { formatDate } from '../../../utils/dates';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { TimesheetStatuses } from '../../../data/timesheets';
import { useJobDetails } from '../../../data/admin-job-details';
import RejectConfirmation from '../../timesheet/components/RejectConfirmation';
import { useTranslation } from 'react-i18next';
import ApproveConfirmation from '../../timesheet/components/ApproveConfirmation';
import {
  useCreateTimeEntry,
  useDeleteJobTimeEntry,
  useRejectJobTimesheetMonth,
  useSubmitJobTimesheetMonth,
  useTimesheetDetails,
  useUpdateJobTimeEntry,
  useApproveJobTimesheetMonth,
  useChangeTimesheetStatus,
  fetchTimesheetInvoices,
  TimesheetInvoiceLinks,
  TimesheetErrorMapping,
  TimesheetInvoiceMapping,
  useCreateTimesheetInvoicesOne,
  useSetInvoicePaid,
} from '../../../data/admin-timesheet-details';
import SubmitConfirmation from './SubmitComfirmation';
import MiniteSnackbar from '../../../components/snackbar';
import { LoadingButton } from '../../../components/LoadingButton';
import { useLocation } from 'react-router-dom';

const statuses = [
  { id: 'draft', label: 'Draft' },
  { id: 'submitted', label: 'Submitted' },
  { id: 'approved', label: 'Approved' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'closed', label: 'Closed' },
];

const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const getTabName = (monthIndex: number, year: number): string => {
  return `${monthNames[monthIndex]} ‘${year.toString().substring(2)}`;
};

const AdminTimesheetDetails = () => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const navigate = useNavigate();

  const { studentUid, jobUid } = useParams<{
    studentUid?: string;
    jobUid?: string;
  }>();
  const [monthTabs, setMonthTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(currentMonth + '/' + currentYear);
  const location = useLocation();
  const [timesheetUid, setTimesheetUid] = useState(null);
  const [createButtonDisabled, changeCreateButtonDisabled] = useState(false);
  const [editableGrid, setEditableGrid] = useState(true);
  const [timesheetStatus, setTimesheetStatus] = useState(null);
  const [rows, setRows] = useState([]);

  const [rejectedSnackbarOpened, toggleRejectedSnackbar] = useState(false);
  const [rejectMonthDialogOpen, toggleRejectMonthDialog] = useState(false);
  const [submitMonthDialogOpen, toggleSubmitMonthDialog] = useState(false);

  const { mutateAsync: rejectJobTimesheetMonth } = useRejectJobTimesheetMonth(
    jobUid,
    timesheetUid,
  );
  const { mutateAsync: submitJobTimesheetMonth } = useSubmitJobTimesheetMonth();
  const { data: timesheets = [], refetch: refetchTimesheets } =
    useTimesheetDetails(jobUid, studentUid);

  const { mutateAsync: createEntry } = useCreateTimeEntry();
  const { mutateAsync: updateEntry } = useUpdateJobTimeEntry(jobUid);
  const { mutateAsync: deleteEntry } = useDeleteJobTimeEntry();
  const { data: jobData } = useJobDetails(jobUid);

  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  const [enabledSubmit, enableSubmit] = useState(false);

  const [approveMonthDialogOpen, toggleApproveMonthDialog] = useState(false);
  const { mutateAsync: approveMonth } = useApproveJobTimesheetMonth(
    jobUid,
    timesheetUid,
  );
  const { mutateAsync: changeTimesheetStatus, isLoading } =
    useChangeTimesheetStatus(timesheetUid);

  const studentFullName = !!timesheets?.length
    ? `${timesheets[0]?.student?.first_name} ${timesheets[0]?.student?.last_name}`
    : '';

  const approveMonthDialogText = () => {
    return `${t('are_you_sure1')} Student Name ${t('are_you_sure2')} ${
      monthNames[currentMonth]
    }. ${t('are_you_sure3')}`;
  };

  const handleApproveMonth = async () => {
    await approveMonth();
    await refetchTimesheets();
    setEditableGrid(false);

    toggleApproveMonthDialog(false);
  };

  const handleToggleApproveMonthDialog = () => {
    toggleApproveMonthDialog(!approveMonthDialogOpen);
  };

  const currentTimesheet = () =>
    timesheets.find((timesheet) => timesheet.timesheet_uid === timesheetUid);

  const [timesheetInvoiceLinks, setTimesheetInvoiceLinks] = useState([]);

  const [timesheetErrors, setTimesheetErrors] = useState([]);

  const [currentTimesheetInvoices, setCurrentTimesheetInvoices] =
    useState(null);
  const [currentTimesheetError, setCurrentTimesheetError] = useState(null);

  const findTimesheetInvoices = (
    timesheet_uid: string,
  ): TimesheetInvoiceLinks | null => {
    try {
      const mapping: TimesheetInvoiceMapping | undefined =
        timesheetInvoiceLinks.find(
          (timesheetInvoiceMapping: TimesheetInvoiceMapping) =>
            timesheetInvoiceMapping.timesheet_uid === timesheet_uid,
        );

      if (!mapping) return null;

      return mapping.links;
    } catch (error) {
      return null;
    }
  };

  const getCurrentTimesheetInvoices = (): TimesheetInvoiceLinks | null => {
    if (!timesheetUid) return null;
    return findTimesheetInvoices(timesheetUid);
  };

  const findTimesheetError = (timesheet_uid): string | null => {
    try {
      const mapping: TimesheetErrorMapping | undefined = timesheetErrors.find(
        (timesheetErrorMapping: TimesheetErrorMapping) =>
          timesheetErrorMapping.timesheet_uid === timesheet_uid,
      );

      if (!mapping) return null;

      return mapping.error;
    } catch (error) {
      return null;
    }
  };

  const getCurrentTimesheetError = (): string | null => {
    if (!timesheetUid) return null;
    return findTimesheetError(timesheetUid);
  };

  // fetch invoice data for all timesheets
  const [loadingInvoices, setLoadingInvoices] = useState(true);

  const loadTimesheetInvoices = async () => {
    setLoadingInvoices(true);
    const invoiceMap: Array<TimesheetInvoiceMapping> = [];
    const errorMap: Array<TimesheetErrorMapping> = [];
    for (const timesheet of timesheets) {
      try {
        const links = await fetchTimesheetInvoices(timesheet.timesheet_uid);
        const timesheet_uid = timesheet.timesheet_uid;
        invoiceMap.push({
          timesheet_uid,
          links,
        });
      } catch (error) {
        // set error map
        const timesheet_uid = timesheet.timesheet_uid;
        errorMap.push({
          timesheet_uid,
          error:
            error.response?.data?.error.message || error?.message?.toString(),
        });
      }
    }
    setLoadingInvoices(false);
    setTimesheetInvoiceLinks(invoiceMap);
    setTimesheetErrors(errorMap);
  };

  const handleReloadTimesheetInvoices = async () => {
    await loadTimesheetInvoices();
  };

  useEffect(() => {
    // this is done in the background
    (async () => {
      if (timesheets?.length) {
        // select tab
        const timesheetUidForView = extractQueryParameter(
          location,
          'timesheet_uid',
        );

        await loadTimesheetInvoices();

        if (timesheetUidForView) {
          const timesheetForView = timesheets.find(
            (timesheet) => timesheet.timesheet_uid === timesheetUidForView,
          );
          if (timesheetForView) {
            setActiveTab(`${timesheetForView.month}/${timesheetForView.year}`);
            setTimesheetUid(timesheetForView.timesheet_uid);
          }
        } else {
          const firstTimesheet = timesheets?.[0];

          if (firstTimesheet) {
            setTimesheetUid(firstTimesheet.timesheet_uid);
          }
        }
      }
    })();
  }, [timesheets]);

  useEffect(() => {
    if (timesheetUid) {
      const links = getCurrentTimesheetInvoices();
      setCurrentTimesheetInvoices(links);

      const error = getCurrentTimesheetError();
      setCurrentTimesheetError(error);
    }
  }, [timesheetUid, loadingInvoices]);

  const handleOpenInvoice = (invoice_link: string) => {
    window.open(invoice_link, '_blank');
  };

  const CustomDatepickerField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const dateArr = value?.split('/');
    const selectedDate = value
      ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`)
      : '';
    const [dateValue, setValue] = useState(selectedDate);
    const apiRef = useGridApiContext();
    const timesheet = currentTimesheet();
    const { year, month } = timesheet;
    const daysInMonth = new Date(year, month, 0).getDate();

    const handleValueChange = (event) => {
      setValue(event);
      apiRef.current.setEditCellValue({ id, field, value: event });
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={dateValue}
          minDate={new Date(`${year}-${month}-${1}`)}
          maxDate={new Date(`${year}-${month}-${daysInMonth}`)}
          inputFormat="DD/MM/YYYY"
          onChange={handleValueChange}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <TextField
              type="text"
              ref={inputRef}
              value={dateValue}
              inputProps={inputProps}
              variant="standard"
              id="startDate"
              error={!value || value === 'Invalid date'}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={InputProps}
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  const navigateToAdminTimesheets = () => {
    navigate(Routes.HoursRoute);
  };

  const navigateToAdminCompanyDetails = () => {
    navigate(`${Routes.AdminCompaniesRoute}/${jobData?.company?.company_uid}`);
  };

  const CustomEditNumberField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = +event.target.value < 0 ? 0 : +event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <TextField
        className="edit-cell-field"
        inputProps={{
          min: 0,
          max: 24,
          step: 0.5,
        }}
        type="number"
        variant="standard"
        value={value}
        error={!value || +value < 0 || +value > 24}
        onChange={handleValueChange}
      />
    );
  };

  const navigateToAdminJobDetails = () => {
    navigate(`${Routes.AdminJobsRoute}/${jobUid}`);
  };

  const handleDeleteEntry = async (cellParams: GridRenderCellParams) => {
    if (cellParams?.row?.hours > 0) {
      await deleteEntry({
        time_entry_uid: cellParams?.row?.id,
        job_uid: jobUid,
      });
      await refetchTimesheets();
    } else {
      setRows(rows.filter((rows) => rows.id !== cellParams?.row?.id));
    }
    changeCreateButtonDisabled(false);
  };

  const CustomTextField = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue.length <= 200) {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }
    };

    return (
      <TextField
        className="edit-cell-field"
        inputProps={{
          max: 200,
        }}
        type="text"
        value={value}
        variant="standard"
        error={!value}
        onChange={handleValueChange}
      />
    );
  };

  const myTimesheetColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      width: 50,
      field: 'edit',
      type: 'actions',
      headerName: '',
      sortable: false,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const apiRef = useGridApiContext();
        const { id } = params;
        const row_id = id as string;
        const [editMode, setEditMode] = useState(row_id.includes('create'));

        const handleEditEntry = () => {
          apiRef.current.startRowEditMode({ id });
          setEditMode(true);
        };

        const handleSaveEntry = () => {
          apiRef.current.stopRowEditMode({ id });
          setEditMode(false);
        };
        return (
          editableGrid && (
            <>
              {editMode ? (
                <SaveOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    mr: 2,
                  }}
                  onClick={() => handleSaveEntry()}
                />
              ) : (
                <EditOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    mr: 2,
                  }}
                  onClick={() => handleEditEntry()}
                />
              )}
            </>
          )
        );
      },
    } as GridActionsColDef,
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      flex: 1,
      editable: editableGrid,
      cellClassName: 'minite-editable-cell',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomDatepickerField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError =
          !params.props.value || params.props.value === 'Invalid Date';
        return { ...params.props, error: hasError };
      },
      valueParser: (value) => {
        return value ? formatDate(value, 'DD/MM/YYYY') : '';
      },
      valueFormatter: (value) => {
        return value?.value || '';
      },
    },
    {
      field: 'hours',
      headerName: 'Hours',
      editable: editableGrid,
      flex: 1,
      cellClassName: 'minite-editable-cell',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditNumberField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const value = params?.props?.value;
        const hasError = !value || value < 0 || value > 24;
        return { ...params.props, error: hasError };
      },
    },
    {
      minWidth: 110,
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      cellClassName: 'minite-editable-cell',
      sortingOrder: ['asc', 'desc'],
      editable: editableGrid,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomTextField {...params} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !params.props.value;
        return { ...params.props, error: hasError };
      },
    },
    {
      width: 50,
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) =>
        editableGrid && (
          <DeleteOutlineOutlinedIcon
            sx={{ color: '#ED6C02', cursor: 'pointer' }}
            onClick={() => handleDeleteEntry(params)}
          />
        ),
    } as GridActionsColDef,
  ];

  const handleTabSelect = (event: React.SyntheticEvent, value: string) => {
    const monthYear = value.split('/');
    const selectedTimesheetUid = timesheets.find((item) => {
      return item.month === +monthYear[0] && item.year === +monthYear[1];
    })?.timesheet_uid;

    setTimesheetStatus(currentTimesheet()?.timesheet_status);
    setTimesheetUid(selectedTimesheetUid);
    console.log('Active tab: ', value);
    setActiveTab(value);
    changeCreateButtonDisabled(false);
  };

  const TimeEntriesFooter = () => {
    const apiRef = useGridApiContext();
    const handleNewEntryClick = () => {
      const id = 'create-' + Math.random();
      const rowsData = [
        ...rows,
        {
          id,
          date: '',
          description: '',
          hours: 0,
        },
      ];
      setRows(rowsData);
      apiRef.current.startRowEditMode({ id });

      changeCreateButtonDisabled(true);
    };
    return (
      <GridFooterContainer>
        {editableGrid && (
          <Button
            disabled={createButtonDisabled}
            className="add-entry-button"
            variant="text"
            onClick={handleNewEntryClick}
            data-testid="add-entry-timeshet"
            startIcon={<AddIcon />}
          >
            {t('create_entry')}
          </Button>
        )}
      </GridFooterContainer>
    );
  };

  const handleRowsFormat = () => {
    const rowsData = timesheets
      ?.find(
        (timesheet) => timesheet.month + '/' + timesheet.year === activeTab,
      )
      ?.time_entries.filter(
        (timesheet_entry) => !!timesheet_entry.time_entry_uid,
      )
      .map((timesheet_entry) => {
        return {
          id: timesheet_entry.time_entry_uid,
          date: `${timesheet_entry.day}/${activeTab}`,
          hours: timesheet_entry.hours,
          description: timesheet_entry.description,
        };
      });
    setRows(!!rowsData?.length ? rowsData : []);
  };

  useEffect(() => {
    if (timesheets?.length) {
      const timesheet = timesheets?.find(
        (timesheet) => timesheet.month + '/' + timesheet.year === activeTab,
      );
      if (!timesheetUid && timesheet) {
        setTimesheetUid(timesheet.timesheet_uid);
      }

      const tabs = timesheets?.map((item) => {
        return {
          key: item.month + '/' + item?.year,
          tabName: getTabName(
            item.month,
            item?.year === currentYear ? currentYear : item?.year,
          ),
        };
      });
      if (!tabs.find((item) => item.key === currentMonth + '/' + currentYear)) {
        tabs.unshift({
          key: currentMonth + '/' + currentYear,
          tabName: getTabName(currentMonth, currentYear),
        });
      }

      setMonthTabs(tabs);
    } else {
      setMonthTabs([
        {
          key: currentMonth + '/' + currentYear,
          tabName: getTabName(currentMonth, currentYear),
        },
      ]);
    }

    if (!!timesheets?.length) {
      handleRowsFormat();
    }

    setTimesheetStatus(currentTimesheet()?.timesheet_status);
  }, [timesheets, timesheetUid]);

  useEffect(() => {
    setEditableGrid(
      !timesheetUid ||
        currentTimesheet()?.timesheet_status !== TimesheetStatuses.Approved,
    );
  }, [timesheetUid]);

  useEffect(() => {
    if (!!rows?.length) {
      enableSubmit(
        rows.reduce((acc, row) => {
          acc = Object.values(row).every((value) => !!value);
          return acc;
        }, false),
      );
    } else {
      enableSubmit(false);
    }
  }, [rows]);

  const handleEditStopRow = async (data) => {
    const dateArr = data.date?.split('/');
    if (dateArr) {
      const payload: any = {
        job_uid: jobUid,
        student_uid: studentUid,
        year: +dateArr[2],
        month: +dateArr[1],
        time_entry: {
          day: +dateArr[0],
          hours: data.hours,
          description: data.description,
        },
      };

      if (data?.id?.includes('create')) {
        await createEntry(payload);
      } else {
        await updateEntry({
          time_entry_uid: data?.id,
          time_entry: payload.time_entry,
        });
      }

      await refetchTimesheets();
      changeCreateButtonDisabled(false);
    }
  };

  const handleSubmitMonth = async () => {
    await submitJobTimesheetMonth({
      job_uid: jobUid,
      timesheet_uid: timesheetUid,
    });
    await refetchTimesheets();

    toggleSubmitMonthDialog(false);
  };

  const handleRejectMonth = async (reason: string) => {
    await rejectJobTimesheetMonth(reason);
    await refetchTimesheets();
    toggleRejectMonthDialog(false);
    toggleRejectedSnackbar(true);
  };

  const handleToggleRejectMonthDialog = () => {
    toggleRejectMonthDialog(!rejectMonthDialogOpen);
  };

  const lessHoursReasonPerMonth = (timesheet) => {
    if (timesheet?.reason_for_low_hours === 'not_enough_hours') {
      return 'Did not get enough work/hours from the company';
    } else if (timesheet?.reason_for_low_hours === 'own_availability') {
      return 'Due to my own availability';
    } else {
      return timesheet?.reason_for_low_hours || undefined;
    }
  };

  const handleToggleSubmitMonthDialog = () => {
    toggleSubmitMonthDialog(!submitMonthDialogOpen);
  };

  const totalHoursPerMonth = (timesheet) =>
    timesheet?.time_entries?.reduce((accumulator, timeEntry) => {
      return accumulator + timeEntry.hours;
    }, 0);
  const totalHoursCurrentMoth = () => totalHoursPerMonth(currentTimesheet());
  const totalHours = () =>
    timesheets.reduce((acc, timesheet) => {
      return acc + totalHoursPerMonth(timesheet);
    }, 0);

  const lessHoursReasonCurrentMonth = () =>
    lessHoursReasonPerMonth(currentTimesheet());

  const handleToggleRejectedSnackbar = () => {
    toggleRejectedSnackbar(!rejectedSnackbarOpened);
  };

  const submitMonthDialogText = () => {
    const hours = totalHoursCurrentMoth();

    return `${t('submit_msg_1')} ${hours} ${
      t('submit_msg_2') + monthNames[currentMonth] + t('submit_msg_3')
    }`;
  };

  const expectedHoursMonth = (
    Number(jobData?.hours_estimated) * 4.34
  )?.toFixed();

  const handleChangeTimesheetStatus = async (e) => {
    await changeTimesheetStatus({
      timesheet: { timesheet_status: e.target.value },
    });

    await refetchTimesheets();
  };

  const { mutateAsync: createTimesheetInvoices } =
    useCreateTimesheetInvoicesOne(timesheetUid);

  const { mutateAsync: setInvoicePaid } = useSetInvoicePaid(timesheetUid);

  const [createTimesheetInvoicesLoading, setCreateTimesheetInvoicesLoading] =
    useState(false);
  const [sendTimesheetInvoicesLoading, setSendTimesheetInvoicesLoading] =
    useState(false);
  const [setPaidTimesheetInvoicesLoading, setSetPaidTimesheetInvoicesLoading] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [invoicingSuccessMessage, setInvoicingSuccessMessage] = useState(null);

  const handleCreateInvoices = async () => {
    try {
      if (!timesheetUid) {
        return;
      }
      setCreateTimesheetInvoicesLoading(true);
      await createTimesheetInvoices({ send: false });
      setInvoicingSuccessMessage(
        'Job to create invoices for this timesheet has been submitted',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setCreateTimesheetInvoicesLoading(false);
    }
  };

  const handleSendInvoices = async () => {
    try {
      if (!timesheetUid) {
        throw new Error('Timesheet was not loaded');
      }
      setSendTimesheetInvoicesLoading(true);
      await createTimesheetInvoices({ send: true });
      setInvoicingSuccessMessage(
        'Job to send invoices for this timesheet has been submitted',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setSendTimesheetInvoicesLoading(false);
    }
  };

  const handleSetPaid = async () => {
    try {
      if (!timesheetUid) {
        throw new Error('Timesheet was not loaded');
      }
      setSetPaidTimesheetInvoicesLoading(true);
      await setInvoicePaid({ invoice_paid: true });
      setInvoicingSuccessMessage(
        'Job to set invoice to paid for this timesheet has been submitted',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setSetPaidTimesheetInvoicesLoading(false);
    }
  };

  const canGenerateInvvoicesForTimesheet = (timesheet: any) => {
    return [TimesheetStatuses.Approved].includes(timesheet?.timesheet_status);
  };

  return (
    <>
      <Grid
        container
        className="admin-timesheet-details-container"
        sx={{
          maxWidth: 'md',
          width: '100%',
        }}
      >
        <Box>
          <Breadcrumbs>
            <Link onClick={navigateToAdminTimesheets}>Timesheet</Link>
            <p>Timesheet: {studentFullName}</p>
          </Breadcrumbs>
          <Box sx={{ display: 'flex', marginTop: '32px' }}>
            <h1 className="student-title">Timesheet: {studentFullName}</h1>
          </Box>
        </Box>
        <Grid
          container
          className="admin-student-container"
          sx={{
            maxWidth: 'md',
            width: '100%',
          }}
        >
          <Grid item md={3}>
            <div className="label-detail">Company</div>
            <Link
              className="timesheet-link"
              onClick={navigateToAdminCompanyDetails}
            >
              {jobData?.company?.company_name}
            </Link>
          </Grid>
          <Grid item md={3}>
            <div className="label-detail">Job</div>
            <Link
              className="timesheet-link"
              onClick={navigateToAdminJobDetails}
            >
              {jobData?.job_title || 'Draft'}
            </Link>
          </Grid>
          <Grid item md={2}>
            <div className="label-detail">Status</div>
            <JobStatusChip jobStatus={jobData?.job_status} />
          </Grid>
          <Grid item md={2}>
            <div className="label-detail">Lifetime hours</div>
            <div>{totalHours()}</div>
          </Grid>
          <Grid item md={2} id="timesheet-status">
            <div className="label-detail">Change Timesheet status</div>
            {isLoading ? (
              <Skeleton sx={{ height: 50, width: 284 }} />
            ) : (
              <Select
                id="status-select"
                variant="standard"
                value={timesheetStatus || ''}
                onChange={handleChangeTimesheetStatus}
              >
                {statuses.map((item) => {
                  const { id, label } = item;
                  return (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Grid>
        </Grid>
        <section className="timesheet-grid-container">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              className="month-tabs"
              value={activeTab}
              onChange={handleTabSelect}
              variant="scrollable"
            >
              {monthTabs.map((monthTab, index) => {
                return (
                  <Tab
                    className={
                      monthTab.key === activeTab
                        ? 'month-tab selected'
                        : 'month-tab'
                    }
                    key={monthTab.key}
                    label={monthTabs[index].tabName}
                    value={monthTabs[index].key}
                  />
                );
              })}
            </Tabs>
          </Box>

          <div className="timesheet-grid-container">
            <MiniteGrid
              columns={myTimesheetColumns}
              rows={rows}
              pageSize={rows?.length || 0}
              pagination
              rowsPerPageOptions={[rows?.length || 0]}
              height={handleGridHeight(rows?.length || 0, rows?.length || 0)}
              rowId={'id'}
              editRowEndCallback={handleEditStopRow}
              components={{
                Footer: TimeEntriesFooter,
              }}
            />
            <div>
              <Divider></Divider>
              <h3>Invoices</h3>
              <Alert
                sx={{
                  marginBottom: '10px',
                }}
                severity="info"
              >
                After clicking <strong>Create</strong> button a job is submitted
                to create invoices. If no current jobs are running, it should
                take bewteen ? - ? minutes. A reported is generated and sent to
                finance@minite.works
              </Alert>

              <LoadingButton
                isDisabled={
                  !!currentTimesheet()?.invoice_sent ||
                  !timesheetUid ||
                  createTimesheetInvoicesLoading ||
                  !canGenerateInvvoicesForTimesheet(currentTimesheet())
                }
                buttonText="Create invoices"
                onClick={handleCreateInvoices}
                isLoading={createTimesheetInvoicesLoading}
                type="button"
                startIcon={<PictureAsPdfIcon />}
                sx={{
                  width: 'fit-content',
                  marginBottom: '10px',
                  marginTop: '10px',
                  marginRight: '10px',
                }}
                confirmButtonTestId="create-invoices"
              />

              <LoadingButton
                isDisabled={
                  !!currentTimesheet()?.invoice_sent ||
                  !timesheetUid ||
                  sendTimesheetInvoicesLoading ||
                  !canGenerateInvvoicesForTimesheet(currentTimesheet())
                }
                buttonText="Send invoices"
                onClick={handleSendInvoices}
                startIcon={<SendIcon />}
                isLoading={sendTimesheetInvoicesLoading}
                variant="outlined"
                type="button"
                sx={{
                  width: 'fit-content',
                  marginBottom: '10px',
                  marginTop: '10px',
                  marginRight: '10px',
                }}
                confirmButtonTestId="send-invoices"
              />

              <LoadingButton
                isDisabled={
                  !!currentTimesheet()?.invoice_paid ||
                  !timesheetUid ||
                  setPaidTimesheetInvoicesLoading ||
                  !canGenerateInvvoicesForTimesheet(currentTimesheet())
                }
                buttonText="Set invoice to paid"
                onClick={handleSetPaid}
                startIcon={<PaidIcon />}
                isLoading={setPaidTimesheetInvoicesLoading}
                variant="outlined"
                type="button"
                sx={{
                  width: 'fit-content',
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
                confirmButtonTestId="send-invoices"
              />
              <Divider sx={{ marginTop: '10px' }} textAlign="left">
                Download
              </Divider>
              <LoadingButton
                isDisabled={loadingInvoices}
                isLoading={false}
                onClick={handleReloadTimesheetInvoices}
                startIcon={<RefreshIcon />}
                buttonText=""
                variant="outlined"
                type="button"
                sx={{
                  width: 'fit-content',
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
                confirmButtonTestId="reload-invoices"
              />
              {currentTimesheetInvoices && !loadingInvoices && (
                <div className="invoice-buttons">
                  <Button
                    disabled={!currentTimesheetInvoices?.admin_fee_invoice_url}
                    onClick={() =>
                      handleOpenInvoice(
                        currentTimesheetInvoices?.admin_fee_invoice_url,
                      )
                    }
                    variant="outlined"
                  >
                    Admin fee
                  </Button>

                  <Button
                    disabled={
                      !currentTimesheetInvoices?.service_fee_invoice_url
                    }
                    onClick={() =>
                      handleOpenInvoice(
                        currentTimesheetInvoices?.service_fee_invoice_url,
                      )
                    }
                    variant="outlined"
                  >
                    Service fee
                  </Button>

                  <Button
                    disabled={
                      !currentTimesheetInvoices?.student_to_company_invoice_url
                    }
                    onClick={() =>
                      handleOpenInvoice(
                        currentTimesheetInvoices?.student_to_company_invoice_url,
                      )
                    }
                    variant="outlined"
                  >
                    Student to company
                  </Button>

                  <Button
                    disabled={
                      !currentTimesheetInvoices?.student_collective_invoice_url
                    }
                    onClick={() =>
                      handleOpenInvoice(
                        currentTimesheetInvoices?.student_collective_invoice_url,
                      )
                    }
                    variant="outlined"
                  >
                    Student collective
                  </Button>

                  <Button
                    disabled={
                      !currentTimesheetInvoices?.company_collective_invoice_url
                    }
                    onClick={() =>
                      handleOpenInvoice(
                        currentTimesheetInvoices?.company_collective_invoice_url,
                      )
                    }
                    variant="outlined"
                  >
                    Company collective
                  </Button>
                </div>
              )}

              {loadingInvoices && (
                <div>
                  <CircularProgress />
                </div>
              )}
              {currentTimesheetError && !loadingInvoices && (
                <Alert sx={{ marginTop: '10px' }} severity="error">
                  {currentTimesheetError}
                </Alert>
              )}
            </div>
          </div>
        </section>

        <Grid container direction="row" className="hours-container">
          <Grid item md={2}>
            <div className="hour-item">Expected hours</div>
            <div>Booked hours</div>
            {lessHoursReasonCurrentMonth() && <div>Less hours reason</div>}
          </Grid>
          <Grid item md={2}>
            <div className="hour-item">{expectedHoursMonth}</div>
            <div>{totalHoursCurrentMoth()}</div>
            {lessHoursReasonCurrentMonth() && (
              <div>{lessHoursReasonCurrentMonth()}</div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            gap: '16px',
          }}
        >
          <Grid item>
            {timesheetUid && (
              <section className="timesheet-actions">
                <Typography className="total-hours-month">
                  Total: {totalHoursCurrentMoth()} {t('hours')}
                </Typography>
                <SubmitConfirmation
                  enabled={editableGrid && enabledSubmit}
                  timesheetStatus={currentTimesheet()?.timesheet_status}
                  content={submitMonthDialogText()}
                  onConfirm={handleSubmitMonth}
                  onToggle={handleToggleSubmitMonthDialog}
                  open={submitMonthDialogOpen}
                />
              </section>
            )}
          </Grid>

          <Grid item>
            <RejectConfirmation
              timesheetStatus={timesheetStatus}
              content={t('reject_month_warning')}
              onConfirm={handleRejectMonth}
              onToggle={handleToggleRejectMonthDialog}
              open={rejectMonthDialogOpen}
            />
          </Grid>
          <Grid item>
            <ApproveConfirmation
              timesheetStatus={timesheetStatus}
              content={approveMonthDialogText()}
              onConfirm={handleApproveMonth}
              onToggle={handleToggleApproveMonthDialog}
              open={approveMonthDialogOpen}
            />
          </Grid>
        </Grid>
        <MiniteSnackbar
          severity="info"
          message="The month has been rejected"
          open={rejectedSnackbarOpened}
          onClose={handleToggleRejectedSnackbar}
        />
      </Grid>
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
      <MiniteSnackbar
        message={invoicingSuccessMessage}
        title={'Message'}
        open={!!invoicingSuccessMessage}
        severity="success"
        onClose={() => setInvoicingSuccessMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};

export default AdminTimesheetDetails;
