import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { PromoButton } from '../../pages/postajob/components/PromoButton';
import { PREMIUM_BOOST_PRICE } from '../../data/jobs';
import { useTranslation } from 'react-i18next';
import './PromoButtons.scss';

export enum PromoType {
  Boost = 'Boost',
  Free = 'Free',
}

const PromoButtons = ({
  onClick,
  type,
}: {
  onClick: (promoType: PromoType) => void;
  type?: PromoType;
}) => {
  const [promoType, setPromoType] = useState(type || PromoType.Boost);
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_review',
  });

  const handleClick = (type: PromoType) => {
    setPromoType(type);
    onClick(type);
  };

  return (
    <Grid
      className={`promo-buttons ${type ? 'center' : ''}`}
      container
      direction="row"
      wrap="nowrap"
    >
      <PromoButton
        title={'Boost your job'}
        description={[
          t('promo_pack.boost.description.job_pinned'),
          t('promo_pack.boost.description.job_review'),
          t('promo_pack.boost.description.job_optimized'),
          t('promo_pack.boost.description.dedicated_instagram_story'),
          t('promo_pack.boost.description.added_weekly_overview'),
          t('promo_pack.boost.description.newsletters'),
        ]}
        count={`+ €${PREMIUM_BOOST_PRICE},00`}
        isSelected={promoType === PromoType.Boost}
        onClick={() => handleClick(PromoType.Boost)}
      />

      {!type && (
        <PromoButton
          title={'No promo'}
          description={[t('promo_pack.no_promo.description.visible')]}
          count={'Free'}
          isSelected={promoType === PromoType.Free}
          onClick={() => handleClick(PromoType.Free)}
        />
      )}
    </Grid>
  );
};

export default PromoButtons;
