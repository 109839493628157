import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import ContentInfo from './ContentInfo';
import { information, stepsInfo } from './information';

import './Onboarding.scss';
import CloseIcon from '@mui/icons-material/Close';
import { UserData, useSetOnboarding } from '../../../data/user';
import SidePanel from './SidePanel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useUserData } from '../../../stores/auth';

interface ISteps {
  label: string;
  description: string;
}

const Onboarding = () => {
  useDocumentTitle('Onboarding');

  const [step, setStep] = useState(0);
  const [isCompleteOngoing, setIsCompleteOngoing] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const userData = useUserData((state) => state.user);
  const refetch = useUserData((state) => state.refetchUser);
  const { mutateAsync: setOnboarding } = useSetOnboarding();
  const userInfo: UserData = userData as unknown as UserData;
  const user = userInfo.student_uid ? 'student' : 'company';
  const steps = stepsInfo[user] as ISteps[];
  const { t } = useTranslation();

  const descriptions = {
    student: {
      description: t('onboarding.student_msg'),
      time: 10,
    },
    company: {
      description: t('onboarding.company_msg'),
      time: 5,
    },
  };

  useEffect(() => {
    setIsCompleteOngoing(!!userInfo.onboarding_completed_at);
  }, [userData]);

  const handleBack = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const handleComplete = () => {
    setIsCompleteOngoing(true);
    setIsOpenModal(true);
    setOnboarding().then(() => {
      refetch();
    });
  };

  const Actions = () => {
    return (
      <div className="onboarding-content-buttons">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="onboarding-previous-step"
        >
          {t('onboarding.back')}
        </Button>
        {step === steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={handleComplete}
            disabled={isCompleteOngoing}
            data-testid="complete-onboading"
          >
            {isCompleteOngoing
              ? t('onboarding.completed')
              : t('onboarding.complete')}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => setStep(step + 1)}
            data-testid="onboarding-next-step"
          >
            {t('onboarding.next')}
          </Button>
        )}
      </div>
    );
  };

  const ActionMobile = () => {
    return (
      <div className="onboarding-content-buttons-mobile">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="onboarding-previous-step"
        >
          <ArrowBackIosIcon />
        </Button>
        {step === steps.length - 1 ? (
          <Button
            onClick={handleComplete}
            disabled={isCompleteOngoing}
            data-testid="complete-onboading"
          >
            <ArrowForwardIosIcon />
          </Button>
        ) : (
          <Button
            onClick={() => setStep(step + 1)}
            data-testid="onboarding-next-step"
          >
            <ArrowForwardIosIcon />
          </Button>
        )}
      </div>
    );
  };

  return (
    <Grid container className="onboarding" justifyContent="space-between">
      <Grid item lg={7} xl={7} md={7} sm={7} xs={0}>
        <div>
          <title>Onboarding | Minite</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <p>Onboarding</p>

          <div className="onboarding-container-parent">
            <div className="onboarding-content">
              <p className="onboarding-content-title">{t(steps[step].label)}</p>

              <ContentInfo
                actions={<Actions />}
                info={information[user][step]}
                serviceFee={userInfo?.company?.service_fee}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item lg={3} xl={3} md={4} sm={4} xs={12} style={{ width: '256px' }}>
        <SidePanel
          {...descriptions[user]}
          steps={steps}
          step={step}
          isCompleteOngoing={isCompleteOngoing}
          buttons={<ActionMobile />}
        />
      </Grid>

      {user === 'student' && (
        <Dialog
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          className="onboarding-modal"
        >
          <DialogTitle className="content-title">
            Onboarding completed
            <ButtonBase
              onClick={() => setIsOpenModal(false)}
              data-testid="close-onboarding-modal"
            >
              <CloseIcon />
            </ButtonBase>
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="onboarding-content-text description">
              {t('onboarding.good_luck')}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};

export default Onboarding;
