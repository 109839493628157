import React, { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';
import InfoList from './InfoList';
import InfoWithLink from './InfoWithLink';
import InfoBoldText from './InfoBoldText';

export interface IItem {
  title?: string;
  text?: string;
  link?: string;
  text_link?: string;
}

export interface IItemInfo extends IItem {
  boldText?: string;
  list?: IItem[];
}

interface IProps {
  actions: JSX.Element;
  info: IItemInfo[];
  serviceFee: number;
}

const ContentInfo = ({ actions, info = [], serviceFee }: IProps) => {
  const isMobileView = useMediaQuery('(max-width: 600px)');

  return (
    <div className="onboarding-content-info">
      {info.map((item, index) => {
        return (
          <Fragment key={index}>
            {!!item.title && <p className="title">{item.title}</p>}
            {item.list ? (
              <InfoList list={item.list} />
            ) : (
              <p className={`description ${item.boldText ? 'isBold' : ''}`}>
                {item.link ? (
                  <InfoWithLink item={item} />
                ) : item.boldText ? (
                  <InfoBoldText item={item} />
                ) : (
                  item.text.replaceAll('30%', `${serviceFee}%`)
                )}
              </p>
            )}
          </Fragment>
        );
      })}

      {!isMobileView && actions}
    </div>
  );
};

export default ContentInfo;
