import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';

export interface ServerResponse<T> {
  success: boolean;
  code: number;
  timestamp: string;
  data: T;
}

const axiosClient = axios.create();

export function request<T>(config: AxiosRequestConfig & { gatewayUrl?: true }) {
  return axiosClient.request<T>(config);
}

axiosClient.interceptors.request.use(
  async (requestConfig: AxiosRequestConfig & { gatewayUrl?: true }) => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: 'Bearer ' + token,
    };

    return requestConfig;
  },
  (error) => Promise.reject(error),
);

const unprotectedAxiosClient = axios.create();

export function requestUnprotected<T>(
  config: AxiosRequestConfig & { gatewayUrl?: true },
) {
  return unprotectedAxiosClient.request<T>(config);
}
