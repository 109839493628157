import React, { useState } from 'react';
import { Alert, Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { Routes } from '../../../../utils/consts';
import './JobOverview.scss';
import CheckIcon from '@mui/icons-material/Check';
import JobDetails from './job-details';
import JobEdit from './job-edit';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useJobDetails } from '../../../../data/admin-job-details';
import { UserData } from '../../../../data/user';
import DeleteJob from './admin-job-edit-form/delete-job';
import MiniteSnackbar from '../../../../components/snackbar';
import { useRemoveJob, useUpdateAdminJobDetails } from '../../../../data/admin';
import { useUserData } from '../../../../stores/auth';
import JobFinances from './job-finances';
import PinJob from './admin-job-edit-form/pin-job';
import HotJob from './admin-job-edit-form/hot-job';
import { JobStatuses } from '../../../../data/jobs';
import { formatDate } from '../../../../utils/dates';

const TabsValues = {
  View: 'Overview',
  Edit: 'Edit',
  Finances: 'Finances',
};

function a11yProps(index: number) {
  return {
    className: 'overview-page-tab',
    id: `job-tab-${index}`,
    'aria-controls': `job-tabpanel-${index}`,
  };
}

const AdminJobOverview = () => {
  const [selectedTab, selectTab] = React.useState(0);
  const { id: jobId } = useParams<{ id?: string }>();
  const { data: jobDetails, refetch } = useJobDetails(jobId);
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;
  const { mutateAsync: removeJob, isLoading } = useRemoveJob();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const [pinIsLoading, setPinIsLoading] = React.useState(false);
  const [hotIsLoading, setHotIsLoading] = React.useState(false);
  const { mutateAsync: updateAdminJobDetails } = useUpdateAdminJobDetails();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    selectTab(newValue);
  };

  const navigateToAdminJobs = () => {
    navigate(Routes.AdminJobsRoute);
  };

  const deleteJob = async () => {
    try {
      await removeJob(jobId);
      await navigateToAdminJobs();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };

  const handlePinJob = async () => {
    setPinIsLoading(true);
    await updateAdminJobDetails({
      jobId: jobDetails.job_uid,
      jobDetails: {
        job: {
          pinned: !jobDetails?.pinned,
        },
      },
    });
    await refetch();
    setPinIsLoading(false);
  };

  const handleHotJob = async () => {
    setHotIsLoading(true);
    await updateAdminJobDetails({
      jobId: jobDetails.job_uid,
      jobDetails: {
        job: {
          is_hot: !jobDetails?.is_hot,
        },
      },
    });
    await refetch();
    setHotIsLoading(false);
  };

  return (
    <Grid
      container
      className="admin-overview-page admin-job-container"
      sx={{
        width: '100%',
        gap: 4,
      }}
    >
      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        <div className="section-titles-container">
          <Link
            to={`${Routes.AdminJobsRoute}/${jobId}/details`}
            target="_blank"
          >
            <p className="section-title">
              Job: {jobDetails?.job_title || 'Draft'}
            </p>
          </Link>
        </div>
      </Grid>

      {jobDetails?.job_status === JobStatuses.IN_PROGRESS &&
        jobDetails?.requested_to_complete_at &&
        jobDetails?.job_cancelled_reason &&
        jobDetails?.requested_complete_date && (
          <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
            <Alert severity="warning">
              <div>
                <div className="info-text">
                  Company has requested to end this job
                </div>
                {jobDetails?.requested_to_complete_at && (
                  <div className="info-text">
                    <b>Requested at:</b>{' '}
                    {formatDate(
                      jobDetails?.requested_to_complete_at,
                      'DD/MM/YYYY',
                    )}
                  </div>
                )}
                {jobDetails?.job_cancelled_reason && (
                  <div className="info-text">
                    <b>Reason:</b> {jobDetails?.job_cancelled_reason}
                  </div>
                )}
                {jobDetails?.requested_complete_date && (
                  <div className="info-text">
                    <b>Requested end date:</b>{' '}
                    {formatDate(
                      jobDetails?.requested_complete_date,
                      'DD/MM/YYYY',
                    )}
                  </div>
                )}
              </div>
            </Alert>
          </Grid>
        )}

      {!!Number(jobDetails?.job_boost_pack) && (
        <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
          <Alert severity="info" className="callback-request-alert">
            <div className="callback-alert-content">
              <span className="info-text">
                This job has an active promo pack
              </span>

              <Button
                className="info-button"
                variant="text"
                data-testid="mark-promo-pack"
                endIcon={<CheckIcon />}
              >
                Mark as complete
              </Button>
            </div>
          </Alert>
        </Grid>
      )}

      <Grid item lg={12} md={12}>
        <Box sx={{ width: '100%', marginBottom: '24px' }}>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className="tabs-wrapper"
          >
            <Tabs
              className="job-overview-tabs overview-tabs"
              value={selectedTab}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#DEB900',
                },
              }}
            >
              <Tab label={TabsValues.View} {...a11yProps(0)} />
              <Tab label={TabsValues.Edit} {...a11yProps(1)} />
              <Tab label={TabsValues.Finances} {...a11yProps(2)} />
            </Tabs>
            <div className="tabs-wrapper">
              <PinJob
                onSubmit={handlePinJob}
                isPinned={jobDetails?.pinned}
                isLoading={pinIsLoading}
              />
              <HotJob
                onSubmit={handleHotJob}
                isHot={jobDetails?.is_hot}
                isLoading={hotIsLoading}
              />
              {!!user?.manager?.super_admin && (
                <DeleteJob deleteJob={deleteJob} isLoading={isLoading} />
              )}
            </div>
          </Box>

          {selectedTab === 0 && <JobDetails />}
          {selectedTab === 1 && <JobEdit />}
          {selectedTab === 2 && <JobFinances />}
        </Box>
        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </Grid>
    </Grid>
  );
};

export default AdminJobOverview;
