import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
  TextField,
} from '@mui/material';
import './ReviewModal.scss';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../LoadingButton';

interface IProps {
  title: string;
  content: string[];
  ratingValue: number | null;
  handleRatingValue: (value: number) => void;
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (val: string) => void;
  withBoldText?: string;
  isLoading?: boolean;
}

const ReviewModal = ({
  ratingValue,
  handleRatingValue,
  title,
  content,
  onClose,
  isOpen,
  onSubmit,
  withBoldText,
  isLoading,
}: IProps) => {
  const [review, setReview] = useState('');
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} className="rating-modal">
      <DialogTitle className="content-title">{title}</DialogTitle>
      <DialogContent>
        {content.map((text, i) => (
          <DialogContentText key={i} className="content-text">
            {text} {!i && <span className="boldText">{withBoldText}</span>}
          </DialogContentText>
        ))}
        <h4 className="rating-title">Rating</h4>
        <Box
          sx={{
            width: 300,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Rating
            name="half-rating"
            precision={0.5}
            value={ratingValue}
            data-testid="rating"
            onChange={(event, newValue) => {
              handleRatingValue(newValue);
            }}
            sx={{
              color: '#deb900',
              fontSize: '40px',
            }}
            size="large"
          />
        </Box>
        <TextField
          className="form-input"
          variant="standard"
          label={'Review'}
          multiline={true}
          value={review}
          onChange={(e) => setReview(e.target.value)}
          rows={5}
          data-testid="review-text"
          inputProps={{
            maxLength: 140,
          }}
          helperText={t('job_completed.max_char')}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <LoadingButton
          className="button"
          onClick={() => {
            onSubmit(review);
            onClose();
          }}
          buttonText={t('job_completed.submit')}
          isLoading={isLoading}
          isDisabled={!ratingValue}
          confirmButtonTestId="send-review"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReviewModal;
