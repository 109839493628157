import React, { useEffect, useState } from 'react';
import './JobMatches.scss';
import FavouriteTalents from '../../components/favourite-talents/FavouriteTalents';
import Talents from '../../components/talents/Talents';
import { useTranslation } from 'react-i18next';
import { useFetchMatches, useFetchTalentPool } from '../../data/talent-pool';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import { useFetchJobById } from '../../data/jobs';
import { Button, Chip, Grid, Pagination, Stack } from '@mui/material';
import { useFetchProposalRequests } from '../../data/proposal-requests';
import MiniteSnackbar from '../../components/snackbar';
import useDocumentTitle from '../../utils/useDocumentTitle';

const MatchesChips = ({ languages, skills }) => {
  const chipStyle = {
    width: 'auto',
    height: 32,
    padding: 0,
    fontSize: '12px',
    lineHeight: '20px',
    backgroundColor: '#F2F9FC',
    color: '#393A42',
    fontFamily: 'Sofia W05 Medium, sans-serif',
  };
  return (
    <div className="match-chips-row">
      {languages &&
        languages.map((language) => {
          return <Chip sx={chipStyle} label={language} key={language} />;
        })}

      {skills &&
        skills.map((skill) => {
          return <Chip sx={chipStyle} label={skill} key={skill} />;
        })}
    </div>
  );
};

const JobMatches = () => {
  useDocumentTitle('Invite Talent');

  const { t } = useTranslation('translation', { keyPrefix: 'talent_pool' });
  const navigate = useNavigate();
  const params = useParams();
  const { jobUid } = params;

  if (!jobUid) {
    return navigate(`${Routes.DashboardRoute}?company`, { replace: true });
  }
  const { data: jobData } = useFetchJobById(params.jobUid);
  const languageList = jobData?.languages.map((item) => item.language_name);
  const skillsList = jobData?.skills.map((item) => item.skill_name);
  const [page, setPage] = useState(1);
  const { data: matches } = useFetchMatches(jobUid, page);
  const { data: talentPool = [] } = useFetchTalentPool(true);
  const { data: proposalRequests = [], refetch: refetchProposalRequests } =
    useFetchProposalRequests(params.jobUid);
  const [isOpenToast, setIsOpenToast] = useState(false);

  const setPageApi = (value) => {
    setPage(value);
  };

  useEffect(() => {
    setIsOpenToast(true);
  }, []);

  const openJobDetails = () => {
    window.open(`${Routes.BrowseJobsRoute}/${jobUid}`, '_blank');
  };

  const totalStudents = matches?.pagination?.total || 0;
  const perPage = matches?.pagination?.per_page || 0;

  return (
    <Grid
      sx={{
        maxWidth: 'lg',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <section className="match-jobs">
        {talentPool?.length == 0 && matches?.pagination.total == 0 ? (
          <div className="no-matches">
            <span>
              We couldn't find a match! No worries, High-Flyers will still be
              able to apply to your job
            </span>
            <Button
              onClick={openJobDetails}
              variant="contained"
              className="view-job view-job-no-matches"
            >
              {t('view_job_here')}
            </Button>
          </div>
        ) : (
          <section className="company-talent-pool-container">
            <header className="pool-header">
              <p className="pool-title">
                <span>{t('title')}</span>
                <span className="pool-position">{jobData?.job_title}</span>
              </p>

              <span className="pool-description">{t('description')}</span>
            </header>

            <section>
              {talentPool?.length > 0 && (
                <section className="favourite-pool-talent-cards">
                  <div className="favourites-title-container">
                    <span className="favourites-title">
                      {t('favourites_title')}
                    </span>
                  </div>

                  <FavouriteTalents
                    pool={talentPool}
                    proposalRequests={proposalRequests}
                    refetchProposalRequests={refetchProposalRequests}
                  />
                </section>
              )}
            </section>
            <section className="pool-students-cards">
              <Button
                onClick={openJobDetails}
                variant="contained"
                className="view-job"
              >
                {t('view_job_here')}
              </Button>

              <p className="advice-matches">{`${t('let_us_advise_1')} ${
                perPage < +totalStudents ? perPage : totalStudents
              }/${totalStudents} ${t('let_us_advise_2')}`}</p>
              <div className="pool-results-row">
                <span className="results-count">{totalStudents} results</span>

                <MatchesChips languages={languageList} skills={skillsList} />
              </div>

              <Talents
                {...matches}
                proposalRequests={proposalRequests}
                refetchProposalRequests={refetchProposalRequests}
              />

              {+matches?.pagination.total > 0 && (
                <div className="matches-pagination">
                  <Stack spacing={1}>
                    <Pagination
                      page={page}
                      onChange={(e, value) => setPageApi(value)}
                      count={Math.ceil(
                        (matches.pagination.total as number) /
                          matches.pagination.per_page,
                      )}
                      showFirstButton
                      showLastButton
                    />
                  </Stack>
                </div>
              )}
            </section>
          </section>
        )}
      </section>
      <MiniteSnackbar
        severity={'success'}
        message={
          '🎉Your job is in review with our team and will be posted as soon as possible'
        }
        title={'Success'}
        autoHideDuration={4000}
        open={isOpenToast}
        onClose={() => setIsOpenToast(false)}
      />
    </Grid>
  );
};

export default JobMatches;
