import React from 'react';
import { ProposalStatus } from '../../../data/jobs';
import { Routes } from '../../../utils/consts';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link, useParams } from 'react-router-dom';
import MiniteGrid from '../../../components/grid';

import ProposalStatusChip from '../../job-details/components/ProposalStatusChip';
import { formatDate } from '../../../utils/dates';
import { handleGridHeight } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useStudentProposals } from '../../../data/admin-student-details';
import { budgetString } from '../../job-details';

const ProposalsStudent = () => {
  const { t } = useTranslation();
  const params = useParams<{ studentUid?: string }>();

  const { data: proposals = [], isLoading: loading } = useStudentProposals(
    params.studentUid,
  );

  const gridData = (): unknown[] =>
    proposals?.map((proposal) => {
      const {
        proposal_uid,
        created_at,
        proposal_status,
        company,
        job,
        rate,
        budget,
      } = proposal;
      return {
        id: proposal_uid,
        createdAt: formatDate(created_at, 'DD/MM/YYYY'),
        status: proposal_status,
        company: company.company_name,
        jobTitle: job.job_title,
        jobId: job.job_uid,
        rate: budgetString(rate || budget, job.job_type),
      };
    });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'createdAt',
      headerName: t('dashboard.creation_date'),
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      sortComparator: (a, b) => {
        return new Date(a) < new Date(b) ? -1 : 1;
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <ProposalStatusChip forStudent proposalStatus={params.value} />
      ),
    },
    {
      field: 'company',
      headerName: t('dashboard.company'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'jobTitle',
      headerName: t('dashboard.job_title'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="link"
          to={`${Routes.AdminJobsRoute}/${params.row.jobId}`}
          title={params.row.jobTitle}
        >
          {params.row.jobTitle}
        </Link>
      ),
    },
    {
      field: 'rate',
      headerName: 'Rate',
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      sortComparator: (a, b) => {
        return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
      },
    },
  ];

  return (
    <div className="dashboard-jobs">
      <h3 className="heading-text">Proposals</h3>

      <MiniteGrid
        loading={loading}
        columns={columns}
        rows={gridData()}
        rowId={'id'}
        pagination
        pageSize={8}
        disableColumnFilter={true}
        rowCount={proposals.length}
        height={handleGridHeight(proposals?.length || 0, 8)}
      />
    </div>
  );
};

export default ProposalsStudent;
