import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { LoadingButton } from '../../../components/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import './EditsAdmin.scss';

interface IProps {
  onSave: (arg: unknown) => void;
  buttonText: string;
  label: string;
  title: string;
  key_name: string;
  isLoading?: boolean;
  options?: { label: string; id: string }[];
}

interface ValuesFormInterface {
  values: { [key: string]: string }[];
}

const AddElement = ({
  onSave,
  buttonText,
  label,
  title,
  isLoading,
  key_name,
  options,
}: IProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.student',
  });
  const [isDup, setIsDup] = useState({});

  const schema = yup
    .object()
    .shape({
      values: yup
        .array()
        .of(
          yup.object().shape({
            [key_name]: yup.string().required('required'),
          }),
        )
        .required('required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    register,
    reset,
  } = useForm<ValuesFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      values: [{ [key_name]: '' }],
    },
    reValidateMode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values',
  });

  const valuesWatch = useWatch({
    control,
    name: 'values',
  });

  const isError = (value, index, values) => {
    return (
      values.some(
        (item, i) => item[key_name].toLowerCase() === value && i !== index,
      ) || options?.some(({ label }) => label.toLowerCase() === value)
    );
  };

  const checkIsError = (
    data?: { [key: string]: string }[] | ValuesFormInterface,
  ) => {
    let info = data || fields;

    if (!Array.isArray(info)) {
      info = info.values;
    }

    const errors = info.reduce((acc, item, index) => {
      if (
        item?.[key_name] &&
        isError(item[key_name].toLowerCase(), index, info)
      ) {
        acc[index] = index;
      }

      return acc;
    }, {} as { [key: number]: number });

    setIsDup(errors);
    return Object.keys(errors);
  };

  const onSubmit = (data: ValuesFormInterface) => {
    if (!checkIsError(data.values).length) {
      onSave(data.values);
      reset();
    }
  };

  useEffect(() => {
    checkIsError();
  }, [fields]);

  return (
    <div>
      <p>{title}</p>
      <form className="edits-form">
        {fields.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <TextField
                label={label}
                type="text"
                id={`value-input ${valuesWatch.length > 1 ? 'withButton' : ''}`}
                sx={{ flex: '1 1 100%', width: '100%' }}
                variant="standard"
                name={key_name}
                className="withButton"
                error={isDup[index] !== undefined}
                InputProps={{
                  endAdornment: valuesWatch.length > 1 && (
                    <Button
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      variant="outlined"
                      className="array-buttons remove-field-button"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      {t('remove')}
                    </Button>
                  ),
                }}
                inputProps={{ maxLength: 75 }}
                {...register(`values.${index}.${key_name}`)}
              />
            </React.Fragment>
          );
        })}

        <div className="array-buttons-container">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            className="array-buttons add-field-button"
            type="button"
            onClick={() => {
              append({ [key_name]: '' });
              checkIsError();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </form>
      <div className="save-button-container">
        <LoadingButton
          isDisabled={!isValid || !isDirty}
          className="save-button"
          onClick={handleSubmit(onSubmit)}
          buttonText="Save"
          isLoading={isLoading}
          confirmButtonTestId={`save-add-${key_name}`}
        />
      </div>
    </div>
  );
};

export default AddElement;
