import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './data/query-client';
import {
  unstable_HistoryRouter as BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MiniteTheme } from './utils/custom-theme';
import { SnackbarProvider } from 'notistack';
import history from './utils/history';
import * as Sentry from '@sentry/react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { awsConfig } from './utils/aws';

Sentry.init({
  dsn: 'https://0ef2ae1e122ff985d48f9177545d66b9@o4505301996994560.ingest.sentry.io/4505879376232448',
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^localhost/,
    /^https:\/\/minite-qa\.netlify\.app/,
    /^app\.minite\.works/,
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.APP_ENV === 'development' ? 1.0 : 0.25,
  // Session Replay
  replaysSessionSampleRate: process.env.APP_ENV === 'development' ? 1.0 : 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Amplify.configure(awsConfig);

root.render(
  <QueryClientProvider client={queryClient}>
    <Authenticator.Provider>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter history={history}>
              <ThemeProvider theme={MiniteTheme}>
                <SnackbarProvider
                  maxSnack={3}
                  hideIconVariant={false}
                  iconVariant={{
                    success: <CheckCircleOutlinedIcon />,
                    error: <ErrorOutlineOutlinedIcon />,
                    info: <InfoOutlinedIcon />,
                  }}
                  preventDuplicate={true}
                  autoHideDuration={3000}
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                >
                  <App />
                </SnackbarProvider>
              </ThemeProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Suspense>
    </Authenticator.Provider>
  </QueryClientProvider>,
);

reportWebVitals();
