import React, { useEffect, useMemo, useState } from 'react';
import useDocumentTitle from '../../utils/useDocumentTitle';
import './Reviews.scss';
import { Grid, Stack, Switch, Typography } from '@mui/material';
import { formatDate } from '../../utils/dates';
import ResizableTable from '../../components/resizable-table';
import { styled } from '@mui/material/styles';
import { ReviewsResponse, useFetchReviews } from '../../data/reviews';

const TypeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const Reviews = () => {
  useDocumentTitle('Reviews');

  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    type: 'sent',
  });

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      type: event.target.checked ? 'received' : 'sent',
    });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const {
    data: reviewsData,
    refetch: refetchReviewsData,
    isLoading,
  } = useFetchReviews(filters);

  const { pagination, reviews } = reviewsData || {};

  const myReviewsGridData = (): unknown[] =>
    reviews?.map((review_response: ReviewsResponse) => {
      return {
        id: review_response.review_uid,
        user_uid: review_response.user_uid,
        job_uid: review_response.job_uid,
        job_name: review_response.job_title,
        rating: `${review_response.rating}/5`,
        description: review_response.description
          ? review_response.description
          : 'N/A',
        created_at: formatDate(review_response.created_at, 'DD/MM/YYYY'),
        to_show:
          filters.type === 'received'
            ? review_response.sender
            : review_response.receiver,
      };
    });

  const refetchReviews = async () => {
    await refetchReviewsData();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  useEffect(() => {
    refetchReviews();
  }, [filters]);

  const reviewColumns = useMemo(() => {
    return [
      {
        accessorKey: 'to_show',
        header: filters.type === 'received' ? 'Received from' : 'Sent to',
        enableSorting: false,
      },
      {
        accessorKey: 'job_name',
        header: 'Job name',
        enableSorting: false,
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
        enableSorting: false,
      },
      {
        accessorKey: 'rating',
        header: 'Rating',
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        enableSorting: false,
      },
    ];
  }, []);

  return (
    <>
      <div className={'reviews-title-container'}>
        <Typography variant="h5" component="div">
          Reviews
        </Typography>
      </div>
      <div>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Reviews sent</Typography>
          <TypeSwitch
            inputProps={{ 'aria-label': 'type switch' }}
            checked={filters.type === 'received'}
            onChange={handleChangeSwitch}
          />
          <Typography>Reviews received</Typography>
        </Stack>
      </div>

      <Grid
        container
        className="admin-grid-dashboard admin-students-container"
        sx={{
          width: '100%',
          gap: 5.3,
        }}
      >
        <Grid
          className="table-grid-item"
          item
          lg={12}
          md={12}
          xl={12}
          sm={12}
          xs={12}
        >
          <ResizableTable
            pageSize={filters.per_page}
            columns={reviewColumns}
            rows={myReviewsGridData()}
            page={filters.page}
            onSortChange={handleSortModelChange}
            onPageChange={handleChangePage}
            rowCount={pagination?.total || 0}
            isLoading={isLoading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Reviews;
