import React from 'react';
import './AdminDashboard.scss';
import AdminStatisticCard from '../../../components/admin-dashboard-card';
import { Routes } from '../../../utils/consts';
import { Grid } from '@mui/material';
import MiniteGrid from '../../../components/grid';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import {
  useFetchAdminProposals,
  useUpdateAdminProposalStatus,
} from '../../../data/admin-dashboard';
import { useCompanyStatistic } from '../../../data/admin-companies';
import { useStudentsStatistic } from '../../../data/admin-students';
import { useJobStatistic } from '../../../data/admin-jobs';
const AdminDashboard = () => {
  const { data: proposalsData = [], refetch } = useFetchAdminProposals({
    order_by: 'created_at',
    asc: false,
  });
  const { mutateAsync: updateAdminProposalStatus } =
    useUpdateAdminProposalStatus();
  const { data: companiesStatistics = {} as any } = useCompanyStatistic();
  const { data: studentsStatistics = {} as any } = useStudentsStatistic();
  const { data: jobsStatistics = {} as any } = useJobStatistic();

  const gridData = (): unknown[] =>
    proposalsData?.map((proposal) => {
      const { proposal_uid, job, student } = proposal;
      return {
        id: proposal_uid,
        student_name: `${student.first_name} ${student.last_name}`,
        job_name: job.job_title || 'Draft',
        proposal_id: proposal_uid,
      };
    });

  const updateProposalStatus = (id, status) => {
    updateAdminProposalStatus({ proposalId: id, status }).then(() => {
      refetch();
    });
  };

  const getDashboardColumn = (): GridColDef[] => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'student_name',
        headerName: 'Student',
        flex: 1,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'job_name',
        headerName: 'Job',
        flex: 2,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'proposal_id',
        headerName: 'The proposal accepted',
        flex: 2,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => updateProposalStatus(params.id, 'signed')}
              label="Sign proposal"
              showInMenu
            />,
          );

          return actions;
        },
      } as GridActionsColDef,
    ];
  };

  return (
    <Grid
      container
      className="admin-dashboard-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        <div className="admin-dashboard-cards-container">
          <AdminStatisticCard
            header={'Students'}
            counter={studentsStatistics ? studentsStatistics.students_total : 0}
            text="Registered students"
            link={{ text: 'View all students', url: Routes.AdminStudentsRoute }}
          />
          <AdminStatisticCard
            header={'Jobs'}
            counter={jobsStatistics ? jobsStatistics.jobs_in_progress : 0}
            text="Active jobs"
            link={{ text: 'View all jobs', url: Routes.AdminJobsRoute }}
          />
          <AdminStatisticCard
            header={'Companies'}
            counter={
              companiesStatistics ? companiesStatistics.companies_total : 0
            }
            text="Companies"
            link={{ text: 'View all companies', url: Routes.CompaniesRoute }}
          />
        </div>
      </Grid>
      <MiniteGrid
        pagination
        columns={getDashboardColumn()}
        rows={gridData() || []}
        rowId={'id'}
        rowsPerPageOptions={[5, 10, 20]}
        disableColumnFilter={true}
      />
    </Grid>
  );
};

export default AdminDashboard;
