import React, { useState } from 'react';
import '../../financial/components/AccountForms.scss';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useFetchSkills,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import { JobTypes } from '../../../../components/sign-up/interfaces/static-data';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import {
  ExperienceJobData,
  experienceSchema,
  initialExperienceJob,
  MIN_EDUCATION_EXPERIENCE_DATE,
} from '../../../../stores/student-signup-form';
import { formatDate } from '../../../../utils/dates';
import {
  getOptionLabel,
  IOption,
  isOptionEqualToValue,
} from '../../../../utils/ioption';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';

interface StudentExperienceFormInterface {
  jobs: ExperienceJobData[];
}

const StudentExperienceForm = ({ student, loading, afterSubmit }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { data: skills = student?.skills || [] } = useFetchSkills();
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.student',
  });
  const [isLoading, setIsLoading] = useState(false);

  const skillOptions: IOption[] = skills.map((item) => {
    return {
      label: item.skill_name,
      id: item.skill_uid,
    };
  });
  const experiences = student?.experiences?.filter(
    (item) => !!item.student_experience_uid,
  );
  const experienceData = !!experiences?.length
    ? experiences.map((item) => {
        return {
          job: item?.job_title,
          type: JobTypes.find((jobType) => {
            return jobType?.toLowerCase() === item?.job_type?.toLowerCase();
          }),
          organization: item?.organization_name,
          startDate: item?.started_at,
          endDate: item?.ended_at,
          skills: !!student?.experience_skills?.length
            ? skillOptions?.filter((skill) => {
                return !!student.experience_skills
                  .filter(
                    (es) =>
                      es.student_experience_uid === item.student_experience_uid,
                  )
                  .find((item) => item?.skill_uid === skill?.id);
              })
            : [],
          description: item?.job_description,
        };
      })
    : [{ ...initialExperienceJob }];
  const defaultValues = { jobs: experienceData };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isSubmitted },
  } = useForm<StudentExperienceFormInterface>({
    mode: 'all',
    resolver: yupResolver(experienceSchema),
    defaultValues: { ...defaultValues },
    reValidateMode: 'onChange',
  });

  const isValid = !errors.jobs;

  const {
    fields: jobsFields,
    append: appendJob,
    remove: removeJob,
  } = useFieldArray({
    control,
    name: 'jobs',
  });

  const jobsWatch = useWatch({ control, name: 'jobs' });

  const onSubmit = async (data: StudentExperienceFormInterface) => {
    setIsLoading(true);

    const payload = data.jobs.map((item) => {
      return {
        organization_name: item.organization,
        job_title: item.job,
        job_type: item.type,
        job_description: item.description,
        started_at: formatDate(item.startDate, 'MM/DD/YYYY'),
        ended_at: formatDate(item.endDate, 'MM/DD/YYYY') || null,
        skills: item.skills.map((skill) => {
          return {
            skill_uid: skill?.id,
          };
        }),
      };
    });
    await updateUserProfile({
      student: {
        work_experience: payload,
      },
    });

    await reset(data);
    await afterSubmit();
    setIsLoading(false);
  };

  return (
    <section className="account-form-container account-financial-forms education-form">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">{t('work_exp')}</p>
        </Grid>

        <Grid item xs={12} md={9}>
          {loading ? (
            <Skeleton />
          ) : (
            <form className="account-form">
              <div className="form-inputs">
                {jobsFields.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="form-array-header">
                        <span className="form-array-title">
                          Job #{index + 1}
                        </span>
                        {jobsWatch.length > 1 && index > 0 && (
                          <Button
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            variant="outlined"
                            className="array-buttons remove-field-button"
                            type="button"
                            onClick={() => removeJob(index)}
                            data-testid="remove-experience"
                          >
                            {t('remove')}
                          </Button>
                        )}
                      </div>

                      <TextField
                        label={t('job_title')}
                        {...register(`jobs.${index}.job`)}
                        type="text"
                        id="job-title-input"
                        sx={{ flex: '1 1 100%', width: '100%' }}
                        variant="standard"
                        error={!!errors.jobs?.[index]?.job}
                        helperText={errors.jobs?.[index]?.organization?.message}
                        data-testid="job-title"
                      />

                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <FormControl
                            variant="standard"
                            sx={{ flex: '1 1 100%', width: '100%' }}
                          >
                            <InputLabel id="type-select-label">
                              {t('type')}
                            </InputLabel>
                            <Select
                              value={value}
                              onChange={onChange}
                              labelId="type-select-label"
                              id="type-select"
                              label={'Type'}
                              error={!!errors.jobs?.[index]?.type}
                              {...register(`jobs.${index}.type`)}
                              data-testid="job-type"
                            >
                              {JobTypes.map((item) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                        control={control}
                        name={`jobs.${index}.type`}
                      />

                      <TextField
                        label={t('org_name')}
                        type="text"
                        id="org-input"
                        variant="standard"
                        sx={{ flex: '1 1 100%', width: '100%' }}
                        {...register(`jobs.${index}.organization`)}
                        error={!!errors.jobs?.[index]?.organization}
                        helperText={errors.jobs?.[index]?.organization?.message}
                        data-testid="job-organisation-name"
                      />

                      <FormGroup className="form-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            render={({ field: { value, onChange } }) => (
                              <DesktopDatePicker
                                value={value}
                                views={['year', 'month']}
                                onChange={onChange}
                                disableMaskedInput={true}
                                label={t('start_date')}
                                inputFormat="MMMM YYYY"
                                data-testid="job-expirience-start-date"
                                minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                                maxDate={Date.now()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{ flex: '1 1 50%' }}
                                    error={!!errors.jobs?.[index]?.startDate}
                                    helperText={
                                      errors?.jobs?.[index]?.startDate?.message
                                    }
                                  />
                                )}
                              />
                            )}
                            control={control}
                            name={`jobs.${index}.startDate`}
                          />

                          <Controller
                            render={({ field: { value, onChange } }) => (
                              <DesktopDatePicker
                                value={value}
                                views={['year', 'month']}
                                onChange={onChange}
                                disableMaskedInput={true}
                                label={t('end_date')}
                                inputFormat="MMMM YYYY"
                                data-testid="job-expirience-end-date"
                                minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{ flex: '1 1 50%' }}
                                    error={!!errors.jobs?.[index]?.endDate}
                                    helperText={
                                      errors?.jobs?.[index]?.endDate?.message
                                    }
                                  />
                                )}
                              />
                            )}
                            control={control}
                            name={`jobs.${index}.endDate`}
                          />
                        </LocalizationProvider>
                      </FormGroup>

                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', marginBottom: '16px' }}
                      >
                        <Controller
                          control={control}
                          name={`jobs.${index}.skills`}
                          render={({ field, field: { onChange, value } }) => (
                            <Autocomplete
                              multiple
                              id="skills-select"
                              limitTags={5}
                              defaultValue={value || []}
                              options={skillOptions}
                              isOptionEqualToValue={isOptionEqualToValue}
                              getOptionLabel={getOptionLabel}
                              data-testid="job-expirience-skills"
                              onChange={(_, data) => onChange(data)}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    sx={{ backgroundColor: '#DEB900' }}
                                    variant="outlined"
                                    label={`${option.label}`}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  {...field}
                                  sx={{ flex: '1 1 100%' }}
                                  label={t('skills')}
                                  variant="standard"
                                  data-testid="job-expirience-skills-input"
                                  error={!!errors.jobs?.[index]?.skills}
                                  helperText={
                                    errors?.jobs?.[index]?.skills?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </FormControl>

                      <TextField
                        type="text"
                        id="Description-input"
                        variant="standard"
                        multiline
                        sx={{ flex: '1 1 100%', width: '100%' }}
                        label={t('desc')}
                        error={!!errors.jobs?.[index]?.description}
                        helperText={errors.jobs?.[index]?.description?.message}
                        data-testid="job-expirience-description"
                        inputProps={{
                          max: 500,
                        }}
                        {...register(`jobs.${index}.description`)}
                      />
                    </React.Fragment>
                  );
                })}

                <div className="edit-profile-array-buttons-container">
                  <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    className="array-buttons add-field-button"
                    type="button"
                    data-testid="add-job-expirience"
                    onClick={() =>
                      appendJob({
                        ...initialExperienceJob,
                      })
                    }
                  >
                    {t('add_job_position')}
                  </Button>
                </div>
              </div>
            </form>
          )}

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-job-expiriences"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default StudentExperienceForm;
