export const HowFoundStudent = [
  'Instagram',
  'Google',
  'LinkedIn',
  'TikTok',
  'Introweek: EUR',
  'Introweek: UvA',
  'Introweek: VU',
  'Introweek: Leiden',
  'Introweek: Utrecht',
  'Flyer',
  'Student Ambassador',
  'Student event',
  'Guest lecture',
  'RSM IG Takeover',
  'The Student Hotel',
  'Referral',
  'Other',
];

export const HowFoundCompany = [
  'Network',
  'Instagram',
  'Google',
  'LinkedIn',
  'Friend',
  'Referral',
  'Other',
];

export const BachelorDegrees = ['BA', 'Bachelor', 'BSc', 'LLB'];

export const Degrees = [
  ...BachelorDegrees,
  'Pre-Master',
  'MA',
  'MSc',
  'LLM',
  'MBA',
  'PhD',
];

export const JobInterests = [
  'Customer Support',
  'Marketing',
  'Sales',
  'IT/Data/Analytics',
  'Research',
  'Finance',
  'Design',
  'Community Building',
  'Legal',
];

export const Interests = [
  'Accounting',
  'Advertising',
  'Architecture & Interior Design',
  'Automotive',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Education Management',
  'Ecommerce',
  'Financial Services',
  'Food & Beverage',
  'Healthcare & Wellness',
  'Higher Education',
  'Hospital & Care',
  'Information Technology & Services',
  'Infrastructure & Real Estate',
  'Logistics, Supply Chain, Maritime',
  'Management & Consulting',
  'Marketing',
  'Marketplaces & Platforms',
  'Media & Entertainment',
  'Medtech',
  'Other',
  'Retail',
  'Security',
  'Sports',
  'Sustainability',
];

export const LangLevels = [
  'Limited working proficiency',
  'Professional working proficiency',
  'Native/bilingual proficiency',
];

export const Skills = [
  'Content Creation',
  'SEO',
  'Creative Writing',
  'Content Writing',
];

export const JobTypes = [
  'Partime job',
  'Fulltime job',
  'Internship',
  'Committee',
  'Board year',
  'University project',
  'Hobby project',
  'Freelance',
];
