import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleConfirm: (string) => void;
}

const LowHoursModal = ({ open, title, handleClose, handleConfirm }: Props) => {
  const [reason, setReason] = useState('');
  const [hasSelectedReason, setHasSelectedReason] = useState(false);

  // TODO: TRANSLATION FIELD

  useEffect(() => {
    if (reason === '') {
      setHasSelectedReason(false);
    } else {
      setHasSelectedReason(true);
    }
  }, [reason]);

  return (
    <Dialog open={open} onClose={handleClose} className="low-hours-modal">
      <DialogTitle className="content-title">{title}</DialogTitle>
      <DialogContent>
        <RadioGroup sx={{ marginTop: '10px' }}>
          <FormControlLabel
            value={'not_enough_hours'}
            control={<Radio />}
            label={'Did not get enough work/hours from the company'}
            onClick={() => setReason('not_enough_hours')}
          />
          <FormControlLabel
            value={'own_availability'}
            control={<Radio />}
            label={'Due to my own availability'}
            onClick={() => setReason('own_availability')}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleConfirm(reason);
          }}
          className="button agree"
          autoFocus
          data-testid="confirm-low-hours-reason"
          disabled={!hasSelectedReason}
        >
          {'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LowHoursModal;
