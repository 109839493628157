import React, { useState } from 'react';
import {
  Autocomplete,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { JobType, JobTypes } from '../../../../../data/jobs';
import { JobForm, Language, Skill } from '../../../../../stores/job-form';
import { useFetchLangsAndSkills } from '../../../../../data/shared';
import { useUpdateAdminJobDetails } from '../../../../../data/admin';
import { styled } from '@mui/system';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';

const getValidationSchema = (jobType: JobType, studentLevel = 1) => {
  const langSkillRules = {
    languages: yup.array().min(1).max(5).required(),
    skills: yup.array().min(1).max(5).required(),
  };

  if (jobType === JobTypes.ONGOING) {
    if (studentLevel === 1) {
      return yup
        .object()
        .shape({
          budget: yup.number().min(20).max(300).required(),
          hours_estimated: yup.number().min(5).required(),
          ...langSkillRules,
        })
        .required();
    } else if (studentLevel === 0) {
      return yup
        .object()
        .shape({
          budget: yup.number().min(17).max(19).required(),
          hours_estimated: yup.number().min(5).required(),
          ...langSkillRules,
        })
        .required();
    }
  } else if (jobType === JobTypes.ONE_TIME) {
    return yup
      .object()
      .shape({
        budget: yup.number().min(100).max(999_999).required(),
        hours_estimated: yup.number(),
        ...langSkillRules,
      })
      .required();
  } else if (jobType === JobTypes.PREMIUM) {
    return yup
      .object()
      .shape({
        budget: yup.number().min(400).max(999_999).required(),
        hours_estimated: yup.number(),
        ...langSkillRules,
      })
      .required();
  }
};

const StyledChip = styled(Chip)({
  tag: {
    backgroundColor: '#DEB900',
  },
});

export const AdminJobStudentForm = ({
  job,
  refetch,
}: {
  job: JobForm;
  refetch: () => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_description',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const schema = getValidationSchema(
    job?.job_type,
    job?.student_type === 'regular' ? 1 : 0,
  );

  const { mutateAsync: updateAdminJobDetails } = useUpdateAdminJobDetails();
  const { id: jobId } = useParams<{ id?: string }>();

  const {
    handleSubmit,
    formState: { isValid, errors, isDirty, isSubmitted },
    control,
    register,
    reset,
  } = useForm<{
    budget: number;
    hours_estimated: number;
    languages: Language[];
    skills: Skill[];
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      budget: job?.budget,
      hours_estimated: job?.hours_estimated,
      languages: job?.languages,
      skills: job?.skills,
    },
    reValidateMode: 'onChange',
  });

  const [
    { data: languages = [], isLoading: isLangLoading },
    { data: skills = [], isLoading: isSkillLoading },
  ] = useFetchLangsAndSkills();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload: any = {
        job: {
          budget: String(data.budget),
          hours_estimated: data.hours_estimated,
        },
        languages: data.languages,
        skills: data.skills,
      };

      await updateAdminJobDetails({
        jobId,
        jobDetails: payload,
      });

      refetch();
      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="ideal-student-section">
        <Grid
          container
          direction="row"
          className="job-form-grid"
          alignItems="stretch"
          gap={2}
          sx={{ marginTop: '24px' }}
        >
          <Grid item xs={12} md={2}>
            <p className="job-forms-titles">Student needed</p>
          </Grid>

          <Grid item xs={12} md={9}>
            <form className="ideal-student-form">
              <div className="form-inputs">
                <div className="form-group">
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      {...register('budget')}
                      error={Boolean(errors.budget)}
                      type="number"
                      id="budget"
                      label={'Rate'}
                      variant="standard"
                    />
                  </FormControl>

                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      {...register('hours_estimated')}
                      type="number"
                      id="hours"
                      label={'Estimated total hours'}
                      variant="standard"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="form-inputs">
                {!isLangLoading && (
                  <div>
                    <InputLabel className="label-select-label">
                      Languages
                    </InputLabel>
                    <FormControl
                      variant="standard"
                      sx={{ width: '100%', marginBottom: '16px' }}
                    >
                      <Controller
                        control={control}
                        name="languages"
                        defaultValue={[]}
                        render={({ field, field: { onChange } }) => (
                          <Autocomplete
                            multiple
                            id={`field-select`}
                            limitTags={5}
                            {...field}
                            options={languages}
                            onChange={(_, data) => onChange(data)}
                            value={field.value}
                            getOptionLabel={(option: Language) =>
                              option?.language_name
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.language_uid
                                ? option.language_uid === value.language_uid
                                : option === value
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <StyledChip
                                  variant="outlined"
                                  label={option.language_name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}

                {!isSkillLoading && (
                  <div>
                    <InputLabel className="label-select-label">
                      Skills
                    </InputLabel>
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="skills"
                        defaultValue={[]}
                        render={({ field, field: { onChange } }) => (
                          <Autocomplete
                            multiple
                            id={`field-select`}
                            limitTags={5}
                            {...field}
                            options={skills}
                            onChange={(_, data) => onChange(data)}
                            value={field.value}
                            getOptionLabel={(option: Skill) =>
                              option?.skill_name
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.skill_uid
                                ? option.skill_uid === value.skill_uid
                                : option === value
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <StyledChip
                                  variant="outlined"
                                  label={option.skill_name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
            </form>
            <div className="save-button-container">
              <LoadingButton
                isDisabled={!isValid || !isDirty}
                className="save-button"
                onClick={handleSubmit(onSubmit)}
                buttonText={
                  !isDirty && isSubmitted
                    ? t('changes_saved')
                    : t('save_changes')
                }
                isLoading={isLoading}
                confirmButtonTestId="save-job-ideal-student-admin"
              />
            </div>
          </Grid>
        </Grid>

        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </section>
    </>
  );
};
