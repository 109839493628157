import React from 'react';
import useDocumentTitle from '../../../utils/useDocumentTitle';

const KnowledgeBase = () => {
  useDocumentTitle('Knowledge Base');

  return (
    <>
      <title>Knowledge Base | Minite</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <p>Knowledge Base</p>
    </>
  );
};

export default KnowledgeBase;
