import React, { useEffect, useState } from 'react';
import { Button, Chip, Stack, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import './JobCard.scss';
import { useNavigate } from 'react-router-dom';
import { COMPANY_AVATAR_BUCKET, Routes } from '../../utils/consts';
import {
  Favorite,
  JobStatuses,
  JobTypes,
  StudentJobData,
  StudentType,
  StudentTypes,
  useAddToFavorites,
  useRemoveFromFavorites,
} from '../../data/jobs';
import { useTranslation } from 'react-i18next';
import { numberWithDec } from '../../utils/numberWithDec';
import CompanyAvatar from '../company-avatar';
import GenericModal from '../generic-modal/GenericModal';
import MiniteSnackbar from '../snackbar';
import { LoadingButton } from '../LoadingButton';
import CircularProgressWithLabel from '../talents/CircularProgressWithLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

interface Props {
  job: StudentJobData;
  studentType: StudentType;
  favorites: Favorite[];
  refetchFavorites: () => void;
  isVerified: boolean;
  registrationNotComplete: boolean;
}

export const JobCard = ({
  job,
  favorites,
  refetchFavorites,
  isVerified,
  registrationNotComplete,
}: Props) => {
  const navigate = useNavigate();
  const { mutateAsync: addToFavourites } = useAddToFavorites();
  const { mutateAsync: removeFromFavourites } = useRemoveFromFavorites();
  const jobTypes = {
    [JobTypes.ONE_TIME]: 'One time job',
    [JobTypes.ONGOING]: 'Ongoing job',
    [JobTypes.PREMIUM]: 'One time job',
  };

  const [jobAvatar, setAvatar] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigateToJobDetails = () => {
    if (isVerified || job.directJob) {
      window.open(`${Routes.BrowseJobsRoute}/${job.jobUid}`, '_blank');
    } else {
      setIsOpen(true);
    }
  };

  const { t } = useTranslation();

  const chipStyle = {
    width: 'auto',
    height: 32,
    backgroundColor: '#F2F9FC',
    color: '#393A42',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Sofia W05 Medium, sans-serif',
  };

  // const isButtonDisabled = () => {
  //   return (
  //     studentType &&
  //     studentType === StudentTypes.EntryLevel &&
  //     job?.studentType === StudentTypes.Regular
  //   );
  // };

  const favorite = favorites?.find(
    (favorite) => favorite.job_uid === job.jobUid,
  );

  const [errorMessage, setErrorMessage] = useState(null);
  const [favoritesLoading, setFavoritesLoading] = useState(false);

  const handleFavouritesClick = async (event) => {
    event?.preventDefault();

    try {
      setFavoritesLoading(true);
      if (favorite) {
        await removeFromFavourites(favorite.favorite_uid);
      } else if (job) {
        await addToFavourites(job.jobUid);
      }
      refetchFavorites();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    } finally {
      setFavoritesLoading(false);
    }
  };

  const navigateToMyProfile = () => {
    navigate(Routes.ProfileSettingsRoute);
  };

  const isOpenJob =
    job.jobStatus === JobStatuses.OPEN ||
    job.jobStatus === JobStatuses.INTERVIEWING;

  useEffect(() => {
    if (job?.companyUser?.avatar) {
      setAvatar(COMPANY_AVATAR_BUCKET + `${job?.companyUser.avatar}`);
    }
  }, [job]);

  return (
    <>
      <div
        className={`job-card-container ${isOpenJob ? '' : 'job-matched'} ${
          job?.pinned ? 'pinned-job' : ''
        }`}
      >
        {(isVerified || job.directJob) && (
          <div className="job-card-header">
            <div className="job-avatar-container">
              <CompanyAvatar
                avatar={isOpenJob ? jobAvatar : '/images/partying-face.png'}
                alt="Job Avatar"
              />
              <div>
                <div>
                  {job?.isHot && isOpenJob && (
                    <Chip
                      sx={{
                        width: 'auto',
                        padding: '6px 6px 7px',
                        border: '1.5px solid #ff5a00',
                        backgroundColor: 'transparent',
                        color: '#ff5a00',
                        fontFamily: 'Sofia W05 Medium, sans-serif',
                        marginBottom: '5px',
                      }}
                      label={'🔥 Hot Job - High chance of landing it 🔥'}
                      className="student-level-chip"
                    />
                  )}
                </div>
                <div className="align-flex-right">
                  {job?.directJob && (
                    <div>
                      <Chip
                        sx={{
                          width: 'auto',
                          padding: '6px 6px 7px',
                          border: '1.5px solid #13bd73',
                          backgroundColor: 'transparent',
                          color: '#13bd73',
                          fontFamily: 'Sofia W05 Medium, sans-serif',
                        }}
                        label={job.directJobType}
                        className="student-level-chip"
                      />
                    </div>
                  )}
                  {!job.directJob && (
                    <div>
                      {job.studentType === StudentTypes.EntryLevel &&
                        job.jobType === JobTypes.ONGOING &&
                        !job.directJob && (
                          <div>
                            <Chip
                              sx={{
                                width: 'auto',
                                padding: '6px 6px 7px',
                                border: '1.5px solid #1976D2',
                                backgroundColor: 'transparent',
                                color: '#1976D2',
                                fontFamily: 'Sofia W05 Medium, sans-serif',
                              }}
                              label={job.studentType}
                              className="student-level-chip"
                            />
                          </div>
                        )}
                      {job?.matchScore > 0 && (
                        <Tooltip
                          title="How well your profile fits with the job. This score is based on multiple data points using AI."
                          placement="right-start"
                          style={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div className="match-score-container">
                            <CircularProgressWithLabel value={job.matchScore} />
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="job-card-content">
          <div className="job-title-container">
            <p className="job-title">
              {job?.pinned && (
                <Tooltip
                  title="This job is pinned by our admins"
                  placement="left"
                >
                  <PushPinOutlinedIcon className="pin-icon" />
                </Tooltip>
              )}
              {job.jobTitle}
            </p>

            <div className="rate-container">
              <div className="rate">
                <StarIcon className="favourite-rate-star" />
                <span className="rate-number">
                  {numberWithDec(job.company.rating)}
                </span>
              </div>
              <span className="rate-title">
                {isVerified || job.directJob
                  ? isOpenJob
                    ? job.company.companyName
                    : ''
                  : 'Get verified to unlock all details'}
              </span>
            </div>
            {job.directJob && (
              <>
                <div className="job-languages">
                  <p className="language-list">
                    <span className="label">Type: </span>
                    <span className="value">{job.directJobType}</span>
                  </p>
                </div>
                {job.directJobCompensation && (
                  <div className="job-languages">
                    <p className="language-list">
                      <span className="label">Compensation: </span>
                      <span className="value">{job.directJobCompensation}</span>
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="job-description-container">
            <p className="job-description">
              {isOpenJob ? job.jobDescription : 'Offer no longer available'}
            </p>
          </div>

          {!job.directJob && (
            <>
              <div className="job-languages">
                <p className="language-list">
                  <span className="label">Languages: </span>
                  <span className="value">
                    {job.languages.map((item) => item.languageName).join(', ')}
                  </span>
                </p>
              </div>

              <div className="job-tags">
                <div className="job-tags-list">
                  <Chip
                    sx={chipStyle}
                    label={jobTypes[job.jobType]}
                    className="student-level-chip"
                  />
                  <Chip
                    sx={chipStyle}
                    label={
                      job.jobType === JobTypes.ONGOING
                        ? `€${job.budget} p/hr`
                        : `€${(+job.budget).toFixed()}`
                    }
                    className="student-budget-chip"
                  />
                  {job.jobType === JobTypes.ONGOING && (
                    <Chip
                      sx={chipStyle}
                      label={`${job.hoursEstimated} hrs/wk`}
                      className="student-hours-chip"
                    />
                  )}
                  <Chip
                    sx={{
                      ...chipStyle,
                      textTransform: 'capitalize',
                    }}
                    label={job.jobLocation}
                    className="student-location-chip"
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="job-card-footer">
          <div className="job-card-buttons">
            <Stack direction="row" spacing={2}>
              {isOpenJob ? (
                <>
                  <LoadingButton
                    startIcon={
                      favorite ? (
                        <BookmarkRemoveOutlinedIcon />
                      ) : (
                        <BookmarkAddOutlinedIcon />
                      )
                    }
                    variant="text"
                    onClick={handleFavouritesClick}
                    isLoading={favoritesLoading}
                    className="medium-text"
                    confirmButtonTestId={`${
                      favorite ? 'remove' : 'add'
                    }-favorite`}
                    buttonText={
                      favorite
                        ? t('job_details.remove_favourite')
                        : t('job_details.add_to_fav')
                    }
                  />

                  {/* Temporarly turned off entry-level barrier */}
                  {/* <Tooltip
                    disableHoverListener={!isButtonDisabled()}
                    title="Not available for Entry-Level"
                  > */}
                  <span>
                    <Button
                      onClick={navigateToJobDetails}
                      variant="contained"
                      // disabled={isButtonDisabled()}
                      id="view-job-button"
                      data-testid="view-job"
                      sx={{
                        width: '140px',
                        height: '42px',
                        backgroundColor: '#DEB900',
                        borderRadius: '4px',
                        fontFamily: '"Sofia W05 Semi Bold", sans-serif',
                        fontSize: '14px',
                        lineHeight: '26px',
                      }}
                    >
                      {job.directJobType === 'Inhouse day' ||
                      job.directJobType === 'Event'
                        ? t('browse_jobs.view_event')
                        : t('browse_jobs.view_job')}
                    </Button>
                  </span>
                  {/* </Tooltip> */}
                </>
              ) : (
                <>
                  <Chip
                    sx={{
                      width: '100%',
                      fontFamily: 'Sofia W05 Medium, sans-serif',
                    }}
                    color="success"
                    label="Matched"
                  />
                </>
              )}
            </Stack>
            <GenericModal
              open={isOpen}
              title={
                registrationNotComplete
                  ? t('sign_up_student.modal_title')
                  : t('assessment.do_assessment')
              }
              content={
                registrationNotComplete
                  ? t('brows_job.finish_registration')
                  : t('assessment.do_assessment_description')
              }
              cancelButtonText={t('brows_job.cancel')}
              confirmButtonText={
                registrationNotComplete
                  ? t('sign_up_student.modal_confirm')
                  : t('brows_job.go_my_profile')
              }
              handleCancel={() => setIsOpen(false)}
              handleConfirm={navigateToMyProfile}
              confirmButtonTestId="go-profile-settings"
            />
          </div>
        </div>
      </div>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};
