export enum PostJob {
  JOB_TYPE = 'JOB_TYPE',
}

export const initialState = {
  job_type: 'Ongoing',
  students_needed: 2,
  job_title: 'Minite QA',
  job_description: 'We need to test a Minite web site',
  category_uid: 'a067d060-069a-4566-a798-57a2af4bbb26',
  sub_category_name: 'QA Services',
  job_start_at: '09/20/2022',
  hours_estimated: 80,
  budget: 2500.0,
  question_required: 'Did you test any sites before?',
  languages: [],
  skills: [],
};

export function PostJobReducer(state = initialState, action) {
  if (action.type === PostJob.JOB_TYPE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}
