import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './FavouriteTalents.scss';
import FavouriteCard from './FavouriteCard';
import { StudentType } from '../../data/jobs';

export interface TalentPoolEducation {
  study: string;
  degree: string;
  ended_at: string;
}

export interface TalentPoolStudent {
  student_uid: string;
  student_type: StudentType;
  one_liner?: string;
  rating: number;
}

export interface TalentPoolUniversity {
  university_uid: string;
  university_name: string;
}

export interface TalentPoolUser {
  user_uid: string;
  avatar: string;
  first_name: string;
  last_name: string;
}

export interface ProposalRequest {
  proposal_request_uid: string;
  job_uid: string;
  student_uid: string;
}

export interface TalentPool {
  talent_pool_uid: string;
  student_uid: string;
  education: Array<TalentPoolEducation>;
  student: TalentPoolStudent;
  university: TalentPoolUniversity;
  user: TalentPoolUser;
}

interface Props {
  dashboard?: boolean;
  pool: TalentPool[];
  proposalRequests?: Array<ProposalRequest>;
  refetchProposalRequests?: () => void;
}

const FavouriteTalents = (props: Props) => {
  const { dashboard, pool, proposalRequests, refetchProposalRequests } = props;

  const renderCards = () => {
    const cards = [];
    for (let i = 0; i < pool.length; i++) {
      cards.push(
        <FavouriteCard
          {...pool[i]}
          dashboard={dashboard}
          proposalRequests={proposalRequests || []}
          refetchProposalRequests={refetchProposalRequests}
          key={i}
        />,
      );
    }
    return cards;
  };

  const desktopItems = refetchProposalRequests ? 4 : 3;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: desktopItems,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: desktopItems,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="favourites-section">
      {pool?.length > 0 && (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          centerMode={false}
          arrows={false}
          ssr={false}
          infinite={false}
          autoPlay={false}
          renderDotsOutside={true}
          renderButtonGroupOutside={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass=""
        >
          {renderCards()}
        </Carousel>
      )}
    </div>
  );
};

export default FavouriteTalents;
