import React from 'react';
import { JobStatus, MyJobsListItem } from '../../../data/jobs';
import { Routes } from '../../../utils/consts';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import JobStatusChip from '../../job-details/components/JobStatusChip';
import { Link, useParams } from 'react-router-dom';
import { budgetString } from '../../job-details';
import MiniteGrid from '../../../components/grid';

import { handleGridHeight } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/dates';
import { useStudentJobs } from '../../../data/admin-student-details';
import { ButtonBase } from '@mui/material';

const JobsStudent = () => {
  const { t } = useTranslation();
  const params = useParams<{ studentUid?: string }>();

  const { data: jobs = [], isLoading: loading } = useStudentJobs(
    params.studentUid,
  );

  const openTimesheet = (job_uid) => {
    window.open(
      `${Routes.HoursRoute}/${params.studentUid}/${job_uid}`,
      '_blank',
    );
  };

  const jobsGridData = (): unknown[] =>
    jobs.map((job: MyJobsListItem) => {
      const {
        job_uid,
        job_status,
        created_at,
        company,
        job_title,
        rate,
        budget,
        job_type,
      } = job;
      return {
        id: job_uid,
        status: job_status,
        created_at: formatDate(created_at, 'DD/MM/YYYY'),
        company: company.company_name,
        company_uid: company.company_uid,
        jobTitle: job_title,
        rate: budgetString(rate || budget, job_type),
      };
    });

  const jobsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortingOrder: ['asc', 'desc'],
      flex: 1,
      renderCell: (params: GridRenderCellParams<JobStatus>) => (
        <JobStatusChip jobStatus={params.value} />
      ),
    },
    {
      field: 'jobTitle',
      headerName: t('dashboard.job_title'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          to={`${Routes.AdminJobsRoute}/${params.row.id}`}
          title={params.row.jobTitle}
        >
          {params.row.jobTitle}
        </Link>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Start date',
      flex: 1,
      sortComparator: (a, b) => {
        return new Date(a) < new Date(b) ? -1 : 1;
      },
    },
    {
      field: 'rate',
      headerName: 'Rate',
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      sortComparator: (a, b) => {
        return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
      },
    },
    {
      field: 'company',
      headerName: t('dashboard.company'),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          to={`${Routes.AdminCompaniesRoute}/${params.row.company_uid}`}
          title={params.row.company}
        >
          {params.row.company}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 130,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="actions-bar">
            <ButtonBase
              onClick={() => openTimesheet(params.row.id)}
              className="open-button"
              data-testid="open-timesheet"
            >
              Go to Timesheet
            </ButtonBase>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dashboard-jobs">
      <h3 className="heading-text">Jobs</h3>

      <MiniteGrid
        rowId={'id'}
        loading={loading}
        columns={jobsColumns}
        rows={jobsGridData()}
        pageSize={8}
        pagination
        disableColumnFilter={true}
        rowCount={jobs.length}
        height={handleGridHeight(jobs?.length || 0, 8)}
      />
    </div>
  );
};

export default JobsStudent;
