import { QueryCache, QueryClient } from '@tanstack/react-query';
import { Routes } from '../utils/consts';

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

const redirectTo = (route) => {
  window.open(route, '_self');
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'online',
      retry: 1,
      retryDelay: 1000,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
  queryCache: new QueryCache({
    onError: async (error) => {
      const err = error as { response: { status: number; data: any } };
      const location = window.location.href.split('/');
      const { status, data } = err?.response || {};

      switch (status) {
        case 400:
          if (
            location[location.length - 1] &&
            data?.error?.message === 'Incorrect UUID value'
          ) {
            redirectTo(Routes.DashboardRoute);
          }
          break;
        case 401:
          if (location[location.length - 1]) {
            redirectTo(Routes.DashboardRoute);
          }
          break;
        case 403:
          if (location[location.length - 1] !== 'forbidden') {
            redirectTo(Routes.Forbidden);
          }
          break;
      }
    },
  }),
});
