import { Chip } from '@mui/material';
import React from 'react';
import './TimesheetStatusChip.scss';
import { TimesheetStatus, TimesheetStatuses } from '../../../data/timesheets';
import { useTranslation } from 'react-i18next';

interface Props {
  timesheetStatus: TimesheetStatus;
}

const getBgColor = (timesheetStatus: TimesheetStatus) => {
  switch (timesheetStatus) {
    case TimesheetStatuses.Approved:
      return '#14DB87';
    case TimesheetStatuses.Rejected:
      return '#EA4335';
    case TimesheetStatuses.Submitted:
    case TimesheetStatuses.Draft:
      return 'transparent';
    default:
      return '#4E5B73';
  }
};

const getBorderColor = (timesheetStatus: TimesheetStatus) => {
  switch (timesheetStatus) {
    case TimesheetStatuses.Submitted:
      return '#DEB900';
    case TimesheetStatuses.Approved:
      return '#14DB87';
    case TimesheetStatuses.Rejected:
      return '#EA4335';
    default:
      return '#4E5B73';
  }
};

const getColor = (timesheetStatus: TimesheetStatus) => {
  switch (timesheetStatus) {
    case TimesheetStatuses.Draft:
    case TimesheetStatuses.Submitted:
    case TimesheetStatuses.Approved:
      return '#393A42';
    default:
      return '#FFFFFF';
  }
};

const getVariant = (timesheetStatus: TimesheetStatus) => {
  switch (timesheetStatus) {
    case TimesheetStatuses.Draft:
    case TimesheetStatuses.Submitted:
      return 'outlined';
    default:
      return 'filled';
  }
};

const labelByStatus = {
  [TimesheetStatuses.Draft]: 'draft',
  [TimesheetStatuses.Submitted]: 'pending_approval',
  [TimesheetStatuses.Rejected]: 'rejected',
  [TimesheetStatuses.Approved]: 'approved',
  [TimesheetStatuses.Closed]: 'closed',
};

const TimesheetStatusChip = ({ timesheetStatus }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  const fontColor = getColor(timesheetStatus);
  const backgroundColor = getBgColor(timesheetStatus);
  const borderColor = getBorderColor(timesheetStatus);
  const label = t(labelByStatus[timesheetStatus]);

  return (
    <Chip
      variant={getVariant(timesheetStatus)}
      label={label}
      className={`timesheet-status-chip ${timesheetStatus}`}
      sx={{
        borderColor,
        backgroundColor,
        color: fontColor,
        width: 'auto',
        minWidth: 'auto',
        textTransform: 'capitalize',
      }}
    />
  );
};

export default TimesheetStatusChip;
