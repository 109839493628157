import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { JobTypes, StudentTypes } from '../../../../data/jobs';
import CompanyAvatar from '../../../../components/company-avatar';
import { useTranslation } from 'react-i18next';
import { ProposalResponse } from '../../../../data/proposals';
import ProposalStatusChip from '../../../job-details/components/ProposalStatusChip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Routes } from '../../../../utils/consts';
import { ProposalStatuses } from '../../../job-details';

interface Props {
  jobInfo: {
    question_required: string;
    student_type: string;
    question_optional: string;
  };
  proposal: ProposalResponse;
  handleDrawerClose?: (event) => void;
}

const ProposalModal = ({ proposal, handleDrawerClose, jobInfo }: Props) => {
  const { t } = useTranslation();
  const [jobAvatar, setAvatar] = useState(null);

  const handleViewProfile = () => {
    window.open(
      `${Routes.StudentsRoute}/${proposal.student.student_uid}`,
      '_blank',
    );
  };

  useEffect(() => {
    if (proposal.company.avatar) {
      setAvatar(
        process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${proposal.company.avatar}?`,
      );
    }
  }, [proposal]);

  return (
    <section className="proposal-student-container">
      <div className="proposal-student-header">
        <div className="close-icon-container">
          <CloseOutlinedIcon
            className="close-drawer-icon"
            onClick={handleDrawerClose}
          />
        </div>

        <div className="title-container">
          <p className="title">Proposal</p>
        </div>
      </div>
      <div className="proposal-student-content">
        <div className="job-header">
          <div className="job-titles">
            <div className="job-company-name-container">
              <CompanyAvatar
                avatar={jobAvatar}
                className="job-avatar-image"
                alt="Job Picture"
              />

              <div className="titles-container">
                <p className="job-title">{proposal.job.job_title}</p>
                <p className="company-title">{proposal.company.company_name}</p>
              </div>
            </div>

            {jobInfo.student_type === StudentTypes.EntryLevel && (
              <Chip
                sx={{
                  width: 'auto',
                  height: '24px',
                  border: '1.5px solid #1976D2',
                  backgroundColor: 'transparent',
                  color: '#1976D2',
                  fontFamily: "'Sofia W05 Medium', sans-serif",
                }}
                label="Entry-level"
                className="student-level-chip"
              />
            )}
          </div>
          <div className="job-header-buttons">
            <Button
              className="button medium-text"
              onClick={handleViewProfile}
              data-testid="view-profile"
            >
              {t('proposal.view_profile')}
            </Button>
          </div>
        </div>
        <div className="proposal-info-container">
          <p className="title">Proposal</p>

          <div className="info-rows">
            <p className="info-row">
              <span className="label">{t('dashboard.company')}</span>
              <span className="value">{proposal.company.company_name}</span>
            </p>

            <p className="info-row">
              <span className="label">Status</span>

              <p className="value">
                <ProposalStatusChip
                  proposalStatus={proposal?.proposal_status}
                />
              </p>
            </p>
            {proposal?.proposal_status === ProposalStatuses.REJECTED &&
              proposal?.reject_reason && (
                <p className="info-row">
                  <span className="label">Reject reason</span>

                  <p className="value">{proposal?.reject_reason}</p>
                </p>
              )}
            {proposal?.interview_prediction !== undefined && (
              <p className="info-row">
                <span className="label">Interview prediction</span>

                <p className="value">
                  <Chip
                    label={proposal?.interview_prediction ? 'Yes' : 'No'}
                    sx={{
                      width: 'auto',
                      minWidth: 'auto',
                      textTransform: 'capitalize',
                      marginBottom: '10px',
                      backgroundColor: '#cec2eb',
                    }}
                  />
                </p>
              </p>
            )}

            {+proposal.job.budget < +proposal?.rate && (
              <>
                <p className="info-row">
                  <span className="label">Counteroffer</span>
                  <span className="value">
                    €
                    {proposal.job.job_type === JobTypes.ONGOING
                      ? `${proposal?.rate}/hr`
                      : proposal?.rate}
                  </span>
                </p>

                <p className="info-row">
                  <span className="label">Why I’m worth it</span>
                  <span className="value">
                    {proposal?.counter_offer_description}
                  </span>
                </p>
              </>
            )}

            {proposal.job.job_type === JobTypes.ONGOING && (
              <p className="info-row">
                <span className="label">{t('proposal.min_availability')}</span>
                <span className="value">{proposal?.min_month}</span>
              </p>
            )}
          </div>

          <div className="accordions-container">
            <Accordion
              disableGutters
              elevation={0}
              sx={{
                '&:before': {
                  backgroundColor: 'transparent',
                },
                backgroundColor: 'transparent',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="question-accordion"
              >
                <Typography className="proposal-question">
                  {jobInfo.question_required}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {proposal?.x_sentiment && (
                  <div>
                    <Chip
                      label={proposal?.x_sentiment}
                      sx={{
                        width: 'auto',
                        minWidth: 'auto',
                        textTransform: 'capitalize',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                )}
                <Typography className="proposal-answer">
                  {proposal?.how_you_do_x}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {jobInfo.question_optional && (
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    backgroundColor: 'transparent',
                  },
                  backgroundColor: 'transparent',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className="question-accordion"
                >
                  <Typography className="proposal-question">
                    {jobInfo.question_optional}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {proposal?.y_sentiment && (
                    <div>
                      <Chip
                        label={proposal?.y_sentiment}
                        sx={{
                          width: 'auto',
                          minWidth: 'auto',
                          textTransform: 'capitalize',
                          marginBottom: '10px',
                        }}
                      />
                    </div>
                  )}
                  <Typography className="proposal-answer">
                    {proposal?.how_you_do_y}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProposalModal;
