import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, setDoc } from 'firebase/firestore';
import { doc, getDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import { useUserData } from '../../stores/auth';
import './index.scss';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { useParams } from 'react-router-dom';
import { ProposalStatus, ProposalStatuses } from '../../data/jobs';
import {
  fetchProposalById,
  sendChatNotificationEmail,
  useFetchProposalById,
} from '../../data/proposals';
import {
  findAdminUidFromChats,
  getFirebaseConfig,
  getOrCreateChatWithAdmin,
  getOrCreateChatWithUser,
} from '../../utils/chat';
import ProposalStatusChip from '../job-details/components/ProposalStatusChip';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { COMPANY_AVATAR_BUCKET } from '../../utils/consts';
import { useUploadAttachment } from '../../data/chats';
import Linkify from 'react-linkify';
import { FaFileDownload } from 'react-icons/fa';
import MiniteSnackbar from '../../components/snackbar';

const firebaseConfig = getFirebaseConfig();
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const isURL = (message) => {
  return /(gitlab.com|.zoom.us|github.com|linkedin.com|teams.microsoft.com|meet.google.com|calendar.google.com|teams.live.com)/.test(
    message,
  );
};

const isEmail = (message) => {
  return message.includes('@');
};

const isPhoneNumber = (message) => {
  let counter = 0;
  const newMess = message.replaceAll(' ', '');

  for (let i = 0; i < newMess.length; i++) {
    const letter = newMess[i];

    if (!Number.isNaN(+letter)) {
      counter++;

      if (counter >= 8) {
        return true;
      }
    } else {
      if (counter >= 8) {
        return true;
      }

      counter = 0;
    }
  }

  return counter >= 8;
};

const adminAvatar = '/images/cat.png';
const adminInfo = (info) => {
  const { user_uid, email } = info;
  return {
    name: 'Minite Support Team',
    id: user_uid,
    email,
    role: 'admin',
    photoUrl: adminAvatar,
  };
};
const admin_uid = await findAdminUidFromChats(db);

const notShowMessageFiled = {
  [ProposalStatuses.Rejected]: true,
  [ProposalStatuses.Retracted]: true,
};

const NewChat = () => {
  const { t } = useTranslation();
  const params = useParams();
  const isWithAdmin = params.proposalId === 'admin';
  const [proposalInfo, setProposalInfo] = useState({
    id: params.proposalId,
    avatar: null,
    status: null as ProposalStatus | undefined,
    jobTitle: '',
    name: '',
    isDeleted: false,
    email: '',
    user_uid: '',
    role: '',
  });
  const { data: userInfo, isLoading } = useFetchProposalById(
    isWithAdmin ? undefined : params.proposalId,
  );
  const user = useUserData((state) => state.user);
  const isStudent = !!user?.student_uid;

  const [messageInputValue, setMessageInputValue] = useState('');
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentUserName, setCurrentUserName] = useState('');
  const [chatsLoading, setChatsLoading] = useState(false);
  const [isChangeLoading, setIsChangeLoading] = useState(false);
  const [tempAttachment, setTempAttachment] = useState(null);
  const [errorSnack, showSnack] = useState(null);

  const { mutateAsync: uploadAttachment, isLoading: attachmentIsLoading } =
    useUploadAttachment();

  const initChat = async () => {
    const { proposal } = userInfo || {};
    const { company, student, proposal_status, job, proposal_uid } =
      proposal || {};

    const currentName =
      (isStudent ? user.first_name : user.company.company_name) ||
      user.first_name;
    setCurrentUserName(currentName);
    let otherUserName = '';
    let otherUser;

    if (isWithAdmin) {
      otherUser = adminInfo(user.internal_chat);
    } else {
      const { user_uid, email, avatar } = isStudent ? company : student;

      otherUserName = isStudent ? company.company_name : student.student_name;

      otherUser = {
        id: user_uid,
        name: otherUserName,
        email,
        photoUrl: !isStudent
          ? '/images/student-avatars/7.png'
          : avatar
          ? COMPANY_AVATAR_BUCKET + avatar
          : '/images/student-avatars/7.png',
        role: !isStudent ? 'student' : 'company',
      };
    }

    if (userInfo) {
      setProposalInfo({
        ...proposalInfo,
        id: proposal_uid,
        status: proposal_status,
        jobTitle: job?.job_title,
        name: otherUserName,
        avatar: otherUser.photoUrl,
        email: otherUser.email,
        user_uid: otherUser.id,
        role: otherUser.role,
      });
    } else if (isWithAdmin) {
      setProposalInfo({
        jobTitle: '',
        status: undefined,
        id: user.user_uid,
        name: 'Minite Support Team',
        avatar: adminAvatar,
        isDeleted: false,
        email: 'highflyers@minite.works',
        user_uid: admin_uid,
        role: 'admin',
      });
    }
  };

  const handleChangeChatSetProposal = async (proposalId) => {
    setIsChangeLoading(true);
    if (proposalId && proposalId !== 'admin') {
      fetchProposalById(proposalId)
        .then((r) => {
          const { company, student, proposal_status } = r.proposal;

          let { avatar } = user?.student_uid ? company : student;
          avatar = !isStudent
            ? '/images/student-avatars/7.png'
            : avatar
            ? COMPANY_AVATAR_BUCKET + avatar
            : '/images/student-avatars/7.png';

          const otherUserName = isStudent
            ? company.company_name
            : student.student_name;
          const otherUserEmail = isStudent ? company.email : student.email;

          setProposalInfo({
            ...proposalInfo,
            id: proposalId,
            status: proposal_status,
            jobTitle: r.proposal.job.job_title,
            avatar,
            name: otherUserName,
            isDeleted: false,
            email: otherUserEmail,
            role: !isStudent ? 'student' : 'company',
          });
        })
        .catch((e) => {
          console.log(e);
          setProposalInfo({
            ...proposalInfo,
            id: proposalId,
            status: undefined,
            jobTitle: '',
            avatar: '',
            name: '',
            isDeleted: true,
          });
        });
    } else {
      setProposalInfo({
        jobTitle: '',
        status: undefined,
        id: user.user_uid,
        name: 'Minite Support Team',
        avatar: adminAvatar,
        isDeleted: false,
        email: 'highflyers@minite.works',
        user_uid: admin_uid,
        role: 'admin',
      });
    }
    setIsChangeLoading(false);
  };

  const handleChangeChat = async (chatId) => {
    let proposalId;
    onSnapshot(doc(db, 'chats', chatId), (document) => {
      proposalId = document.data().proposal_uid;
      if (proposalId !== params.proposalId) {
        window.location.href = `/chat-v2/${proposalId}`;
      }
      const sortedMessages = document
        .data()
        .messages?.sort(
          (a, b) =>
            a.created_at.toDate().toString() - b.created_at.toDate().toString(),
        );
      setMessages(sortedMessages);
      handleChangeChatSetProposal(proposalId);
      if (
        sortedMessages[sortedMessages.length - 1]?.sent_by !== user.user_uid
      ) {
        setDoc(
          doc(db, 'chats', chatId),
          {
            seen: true,
          },
          { merge: true },
        );
      }
      setCurrentChat(chatId);
    });
  };

  const loadChat = async () => {
    if (!isWithAdmin) {
      getOrCreateChatWithUser(
        user.user_uid,
        isStudent ? 'student' : 'company',
        currentUserName,
        user.avatar,
        user.email,
        proposalInfo.user_uid,
        proposalInfo.role,
        proposalInfo.name,
        proposalInfo.avatar,
        proposalInfo.email,
        proposalInfo.id,
        db,
      ).then((chatId) => {
        if (chatId) {
          handleChangeChat(chatId);
        }
      });
    } else {
      getOrCreateChatWithAdmin(
        user?.user_uid,
        db,
        isStudent ? 'student' : 'company',
        `${user?.first_name} ${user?.last_name}`,
        user?.avatar,
        user?.email,
      ).then((chatId) => {
        if (chatId) {
          handleChangeChat(chatId);
        }
      });
    }
  };

  useEffect(() => {
    /**
     * This useEffect is used to get all chats of the user
     */
    if (user) {
      const docRef = doc(db, 'users', user.user_uid);
      const getChats = async () => {
        setChatsLoading(true);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          setChatsLoading(false);
          return;
        }
        const chatIds = docSnap.data().chats;
        const chats = [];
        for (const chatId of chatIds) {
          const chatDoc = await getDoc(doc(db, 'chats', chatId));
          if (chatDoc.exists()) {
            const otherUserId = chatDoc
              .data()
              .users.filter((u) => u !== user.user_uid)[0];
            const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
            const proposalUid = chatDoc.data().proposal_uid;
            if (proposalUid !== 'admin') {
              await fetchProposalById(chatDoc.data().proposal_uid)
                .then((proposal) => {
                  chats.push({
                    ...chatDoc.data(),
                    ...otherUserDoc.data(),
                    id: chatDoc.id,
                    name: !isStudent
                      ? proposal.proposal.student.student_name
                      : proposal.proposal.company.company_name,
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              chats.push({
                ...chatDoc.data(),
                ...otherUserDoc.data(),
                id: chatDoc.id,
              });
            }
          }
        }
        const adminChat = chats.filter((chat) => chat.proposal_uid === 'admin');
        const nonAdminChats = chats.filter(
          (chat) => chat.proposal_uid !== 'admin',
        );
        const nonEmptyChats = nonAdminChats.filter(
          (chat) => chat.messages.length !== 0,
        );
        const emptyChats = nonAdminChats.filter(
          (chat) => chat.messages.length === 0,
        );
        const sortedNonEmptyChats = nonEmptyChats.sort(
          (a, b) =>
            b.messages[b.messages.length - 1]?.created_at.toDate() -
            a.messages[a.messages.length - 1]?.created_at.toDate(),
        );
        const sortedChats = [
          ...(adminChat || []),
          ...(sortedNonEmptyChats || []),
          ...(emptyChats || []),
        ];
        setChats(sortedChats);
        setChatsLoading(false);
      };
      getChats();
      if ((userInfo || isWithAdmin) && user?.user_uid) {
        initChat().then(() => {
          loadChat();
        });
      }
    }
  }, [user, userInfo]);

  useEffect(() => {
    if (!isWithAdmin && userInfo && !currentChat) {
      loadChat();
    }
  }, [proposalInfo]);

  const stripMessage = (message: string) => {
    let messageValue = message.replace(
      /(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g,
      '',
    );
    messageValue = messageValue.replace(/<br\s*\/?>/g, '\n');
    const tmp = document.createElement('DIV');
    tmp.innerHTML = messageValue;
    return tmp.textContent || tmp.innerText || '';
  };

  const sendMessage = async () => {
    if (
      messageInputValue
        .replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '')
        .trim() === ''
    ) {
      return;
    }

    const ONE_MIN = 60 * 1000;
    const FIVE_MIN = 5 * 60 * 1000;
    const now = new Date();
    const sentAt = new Date(messages[messages.length - 1]?.created_at.toDate());
    const diff = now.valueOf() - sentAt.valueOf();

    if (
      proposalInfo.role !== 'admin' &&
      (messages.length === 0 ||
        diff > FIVE_MIN ||
        (messages[messages.length - 1]?.sent_by !== user.user_uid &&
          diff > ONE_MIN))
    ) {
      sendChatNotificationEmail(proposalInfo.id, {
        to_email: proposalInfo.email,
        to_user_role: proposalInfo.role,
      });
    }

    let messageValue = stripMessage(messageInputValue);
    if (tempAttachment) {
      const imageResponse = await uploadAttachment({
        filename: tempAttachment?.file?.name,
        contentType: tempAttachment?.file?.type,
        for_user_uid: user.user_uid,
        for_chat_id: currentChat,
      });
      await fetch(imageResponse?.data?.url, {
        method: 'PUT',
        body: new Blob([tempAttachment?.reader?.result], {
          type: tempAttachment?.file?.type,
        }),
      });
      const bucketUrl =
        process.env.REACT_APP_S3_BUCKET_URL +
        `/attachments/${currentChat}/${imageResponse?.data?.filename}`;
      messageValue = 'Download attachment: ' + bucketUrl;
    }
    await setDoc(
      doc(db, 'chats', currentChat),
      {
        messages: [
          ...messages,
          {
            message: messageValue,
            sent_by: user.user_uid,
            created_at: Timestamp.now(),
          },
        ],
        seen: false,
      },
      { merge: true },
    );
    setMessageInputValue('');
    setTempAttachment(null);
  };

  // Attachment

  const hiddenFileInput = React.useRef(null);

  const handleAttachment = async () => {
    hiddenFileInput.current.click();
    setMessageInputValue('Selecting Attachment');
  };

  const handleChange = async (event) => {
    setMessageInputValue('Attachment selected');
    const fileUploaded = event.target.files[0];
    if (fileUploaded?.size > 1048576) {
      showSnack('File size should be less or equal 1mb');
      setMessageInputValue('');
      return;
    }
    const tempFileUrl = URL.createObjectURL(fileUploaded);
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileUploaded);
    setTempAttachment({ url: tempFileUrl, file: fileUploaded, reader: reader });
  };

  const filterMessageIfNeeded = (message) => {
    if (
      proposalInfo.status &&
      proposalInfo.status !== ProposalStatuses.Signed
    ) {
      if (isURL(message) || isEmail(message) || isPhoneNumber(message)) {
        return '  ';
      }
    }
    return message;
  };

  return (
    <div>
      <section>
        <div style={styles}>
          <MainContainer responsive style={{ height: '500px' }}>
            <Sidebar
              position="left"
              scrollable={false}
              style={{ height: '500px' }}
            >
              <Search placeholder="Search..." disabled />
              {chatsLoading ? (
                <div style={{ margin: 'auto' }}>
                  <CircularProgress />
                </div>
              ) : (
                <ConversationList>
                  {chats?.map((chat) => (
                    <Conversation
                      key={chat.proposal_uid === 'admin' ? 'admin' : chat.id}
                      name={
                        chat.proposal_uid === 'admin'
                          ? 'Minite Support Team'
                          : chat.name
                      }
                      onClick={() => handleChangeChat(chat.id)}
                      active={currentChat === chat.id}
                      lastSenderName={
                        chat.messages[chat.messages.length - 1]?.sent_by ===
                        user.user_uid
                          ? 'You'
                          : chat.name
                      }
                      info={chat.messages[chat.messages.length - 1]?.message}
                      unreadDot={
                        chat.messages.length > 0 &&
                        !chat.seen &&
                        chat.messages[chat.messages.length - 1]?.sent_by !==
                          user.user_uid
                      }
                      lastActivityTime={chat.messages[
                        chat.messages.length - 1
                      ]?.created_at
                        .toDate()
                        .toLocaleString([], {
                          month: 'numeric',
                          day: 'numeric',
                        })}
                    >
                      <Avatar
                        src={
                          chat.proposal_uid === 'admin'
                            ? adminAvatar
                            : !isStudent
                            ? '/images/student-avatars/7.png'
                            : chat.avatarUrl
                            ? COMPANY_AVATAR_BUCKET + chat.avatarUrl
                            : '/images/student-avatars/7.png'
                        }
                      />
                    </Conversation>
                  ))}
                </ConversationList>
              )}
            </Sidebar>

            {(isChangeLoading || (isLoading && !isWithAdmin)) && (
              <div style={{ margin: 'auto' }}>
                <CircularProgress />
              </div>
            )}

            {currentChat && (
              <ChatContainer style={{ height: '500px' }}>
                <ConversationHeader>
                  <Avatar
                    src={
                      proposalInfo.avatar
                        ? proposalInfo.avatar
                        : '/images/student-avatars/7.png'
                    }
                  />
                  <ConversationHeader.Content>
                    <div>
                      <b>
                        {proposalInfo.name} {proposalInfo.jobTitle && '|'}{' '}
                        {proposalInfo.jobTitle}
                      </b>
                    </div>
                    <div>
                      {proposalInfo.status && (
                        <ProposalStatusChip
                          proposalStatus={proposalInfo.status}
                        />
                      )}
                    </div>
                    {!proposalInfo.status && (
                      <div>
                        Thank you for reaching out to Minite. We aim to respond
                        to you within 1 working day.
                      </div>
                    )}
                    {proposalInfo.status !== ProposalStatuses.Signed &&
                      proposalInfo.jobTitle && (
                        <div>
                          {`${t('chat.communication_restricted_chat')} ${
                            isStudent
                              ? t('chat.communication_restricted_chat_student')
                              : ''
                          }`}
                        </div>
                      )}
                    {proposalInfo.status === ProposalStatuses.Signed &&
                      isStudent && (
                        <div>
                          {t(
                            'chat.communication_restricted_chat_student_signed',
                          )}
                        </div>
                      )}
                  </ConversationHeader.Content>
                </ConversationHeader>
                <MessageList>
                  {messages?.map((message) => (
                    <Message
                      key={message.created_at.toDate().toString()}
                      model={{
                        sentTime: message.created_at.toDate().toString(),
                        sender: message.sent_by,
                        direction:
                          message.sent_by === user.user_uid
                            ? 'outgoing'
                            : 'incoming',
                        position: 'single',
                      }}
                    >
                      <Message.Header
                        sentTime={message.created_at
                          .toDate()
                          .toLocaleString([], {
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                      />
                      <Message.CustomContent>
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key,
                          ) => (
                            <a
                              target="blank"
                              rel="noopener"
                              href={decoratedHref}
                              key={key}
                              style={{ textDecoration: 'underline' }}
                            >
                              {decoratedText}
                            </a>
                          )}
                        >
                          {message.message.startsWith(
                            `Download attachment: ${process.env.REACT_APP_S3_BUCKET_URL}`,
                          )
                            ? 'Download attachment'
                            : stripMessage(
                                filterMessageIfNeeded(message.message),
                              )}
                        </Linkify>
                        {message.message.startsWith(
                          `Download attachment: ${process.env.REACT_APP_S3_BUCKET_URL}`,
                        ) && (
                          <>
                            <br />
                            <a
                              target="blank"
                              rel="noopener"
                              href={message.message
                                .split(' ')
                                .slice(2)
                                .join(' ')}
                            >
                              <FaFileDownload style={{ fontSize: '400%' }} />
                            </a>
                          </>
                        )}
                      </Message.CustomContent>
                    </Message>
                  ))}
                </MessageList>
                {!notShowMessageFiled[proposalInfo?.status] &&
                  !proposalInfo.isDeleted && (
                    <MessageInput
                      placeholder="Type message here"
                      value={
                        !!tempAttachment
                          ? attachmentIsLoading
                            ? '⏳'
                            : `📁 [ATTACHMENT: ${tempAttachment?.file?.name}]`
                          : messageInputValue
                      }
                      onChange={(val) => setMessageInputValue(val)}
                      onSend={() => sendMessage()}
                      onAttachClick={handleAttachment}
                      sendDisabled={false}
                      sendButton={!attachmentIsLoading}
                      disabled={attachmentIsLoading}
                      attachDisabled={
                        proposalInfo.status &&
                        proposalInfo.status !== ProposalStatuses.Signed
                      }
                    />
                  )}
              </ChatContainer>
            )}
          </MainContainer>
        </div>
        <input
          hidden
          accept="image/*,application/pdf,video/*,.doc,.xls,.csv,.ppt"
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
        />

        <MiniteSnackbar
          open={!!errorSnack}
          title={'Error'}
          message={errorSnack}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => showSnack(null)}
        />
      </section>
    </div>
  );
};

export default NewChat;
