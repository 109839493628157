import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ADMIN_NO_VAT } from './query-keys';

const searchAdminNoVat = async (body?: NoVatSearchRequest) => {
  const { data } = await request<ServerResponse<NoVatSearchResponse>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/statistics/no-vat/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminNoVat = (body: NoVatSearchRequest) => {
  return useQuery([ADMIN_NO_VAT, body], () => searchAdminNoVat(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface NoVatSearchRequest {
  page?: number;
  per_page?: number;
}

export interface NoVatSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  studentsNoVat: NoVatResponse[];
}

export interface NoVatResponse {
  student_uid: string;
  student_name: string;
  first_job_start_date: string;
}
