import React, { useEffect, useState } from 'react';
import { JobStatuses, JobTypes, MyJobsListItem } from '../../../data/jobs';
import '../Dashboard.scss';
import { Routes } from '../../../utils/consts';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { budgetString } from '../../job-details';
import MiniteGrid from '../../../components/grid';

import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import NoData from './NoData';
import { useMediaQuery } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { formatDate } from '../../../utils/dates';
import { useTranslation } from 'react-i18next';
import { sortDate } from '../../../utils/sortDate';

interface Props {
  jobs: MyJobsListItem[];
  loading: boolean;
  isFetched: boolean;
}

const MyJobsInProgressCompany = ({ jobs, loading, isFetched }: Props) => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();
  const [jobsInProgress, setJobsInProgress] = useState([]);

  useEffect(() => {
    setJobsInProgress(
      jobs.filter((job) => job.job_status === JobStatuses.IN_PROGRESS),
    );
  }, [jobs]);

  const myJobsGridData = (): unknown[] =>
    jobsInProgress
      ?.sort((a, b) => sortDate(a.created_at, b.created_at))
      .map((job: MyJobsListItem) => {
        return {
          id: job.job_uid,
          jobTitle: job.job_title,
          type: job.job_type,
          student_uid: job?.student_uid,
          student_in_progress: job?.student_in_progress,
          rate: job.direct_job ? '-' : budgetString(job.budget, job.job_type),
          actions: '',
          mobileActions: '',
          in_progress_at: formatDate(job.in_progress_at, 'DD/MM/YYYY'),
        };
      });

  // Handlers

  const navigateToJobDetails = (jobUid: string) => {
    navigate(`${Routes.BrowseJobsRoute}/${jobUid}`, {
      replace: true,
    });
  };
  const navigateToTimesheet = (jobUid: string, studentUid: string) => {
    navigate(
      generatePath(Routes.TimesheetStudentRoute, {
        jobUid,
        studentUid,
      }),
      {
        replace: true,
      },
    );
  };

  // Table

  const myJobsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'jobTitle',
      headerName: t('dashboard.job_title'),
      flex: 1.5,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          to={`${Routes.BrowseJobsRoute}/${params.row.id}`}
          title={params.row.jobTitle}
        >
          {params?.row?.status === JobStatuses.DRAFT && !params.row.jobTitle
            ? 'Draft'
            : params.row.jobTitle}
        </Link>
      ),
    },
    {
      field: 'student_in_progress',
      headerName: 'High-Flyer',
      flex: 1.5,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'in_progress_at',
      headerName: 'Started',
      hide: isMobileView,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 90,
      sortingOrder: ['asc', 'desc'],
      hide: isMobileView,
      sortComparator: (a, b) => {
        return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('dashboard.view'),
      sortable: false,
      align: 'right',
      width: 80,
      hide: isMobileView,
      getActions: (params) => {
        const actions = [];
        const viewProposal = {
          [JobStatuses.OPEN]: JobStatuses.OPEN,
          [JobStatuses.INTERVIEWING]: JobStatuses.INTERVIEWING,
        };

        actions.push(
          <GridActionsCellItem
            sx={{
              textTransform: 'capitalize',
            }}
            onClick={() => navigateToJobDetails(params.row.id)}
            label="Go to job details"
            showInMenu
          />,
        );

        if (viewProposal[params.row.status]) {
          actions.push(
            <GridActionsCellItem
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => navigateToJobDetails(params.row.id)}
              label={t('dashboard.view_proposal')}
              showInMenu
            />,
          );
        }

        if (params.row.type === JobTypes.ONGOING && params.row.student_uid) {
          actions.push(
            <GridActionsCellItem
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() =>
                navigateToTimesheet(params.row.id, params.row.student_uid)
              }
              label="View timesheet"
              showInMenu
            />,
          );
        }

        return actions;
      },
    } as GridActionsColDef,
    {
      field: 'mobileActions',
      headerName: '',
      sortable: false,
      align: 'right',
      hide: !isMobileView,
      renderCell: (params: GridRenderCellParams) => (
        <div className="actions-bar">
          <Link
            className="link"
            to={`${Routes.BrowseJobsRoute}/${params.row.id}`}
            title={params.row.jobTitle}
          >
            <ChevronRightOutlinedIcon
              sx={{
                color: '#DEB900',
                cursor: 'pointer',
              }}
              className="next-button"
            />{' '}
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard-jobs">
      <h3 className="heading-text">{t('dashboard.my_jobs_in_progress')}</h3>

      {jobsInProgress.length === 0 && !loading && isFetched ? (
        <NoData message={t('dashboard.no_any_jobs_company')} />
      ) : (
        <MiniteGrid
          height={375}
          loading={loading}
          columns={myJobsColumns}
          rows={myJobsGridData()}
          rowId={'id'}
          disableColumnFilter
          pagination
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      )}
    </div>
  );
};

export default MyJobsInProgressCompany;
