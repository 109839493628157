import React from 'react';
import './Financial.scss';
import AccountForm from './components/AccountForm';
import CompanyForm from './components/CompanyForm';
import FinancialForm from './components/FinancialForm';
import DeleteAccount from './components/DeleteAccount';
import { UserType } from '../../../components/sidepanel/UserType';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useUserData } from '../../../stores/auth';
// import { Button } from '@mui/material';

const Financial = () => {
  useDocumentTitle('Account & Financial');

  const user = useUserData((state) => state.user);

  let allowDeleteAccount = false;
  const { t } = useTranslation();

  if (user?.type === UserType.Student) {
    allowDeleteAccount = user?.student_stats?.allow_destroy;
  } else if (user?.type === UserType.Company) {
    allowDeleteAccount =
      user?.company_stats?.allow_destroy &&
      !user?.company_stats?.total_jobs_in_progress;
  }

  // const gotoStripeDashboard = () => {
  //   window.location = 'https://connect.stripe.com/express_login' as any;
  // };

  return (
    <Grid container className="account-financial-page">
      <Grid
        item
        sx={{
          maxWidth: 'md',
          margin: '0 auto',
          width: '100%',
        }}
        className="account-financial-forms-container"
      >
        <p className="account-financial-title">{t('navigation.account')}</p>
        <>
          {/* {user.stripe_account_id && user.stripe_onboarding_completed && (
            <div>
              <Button
                variant="contained"
                color="primary"
                sx={{ ...defaultButtonStyle, width: 'fit-content' }}
                onClick={gotoStripeDashboard}
              >
                Payment Dashboard
              </Button>
            </div>
          )} */}
          <AccountForm />
          <CompanyForm />
          <FinancialForm />
          {allowDeleteAccount && <DeleteAccount />}
        </>
      </Grid>
    </Grid>
  );
};

export default Financial;
