import { Button } from '@mui/material';
import GenericModal from '../../../components/generic-modal/GenericModal';
import React from 'react';
import { TimesheetStatus, TimesheetStatuses } from '../../../data/timesheets';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  enabled: boolean;
  content: string;
  timesheetStatus: TimesheetStatus;
  onToggle: () => void;
  onConfirm: () => void;
}

const SubmitConfirmation = ({
  open,
  enabled,
  content,
  timesheetStatus,
  onToggle,
  onConfirm,
}: Props): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  return (
    <>
      {timesheetStatus == TimesheetStatuses.Draft && (
        <Button
          disabled={!enabled}
          data-testid="submit-timesheet"
          className="submit-month-button"
          variant="contained"
          onClick={onToggle}
        >
          {t('submit_month')}
        </Button>
      )}

      <GenericModal
        open={open}
        title={t('submit_your_monthly_timesheet')}
        mode="confirm"
        content={content}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={onToggle}
        handleConfirm={onConfirm}
        confirmButtonTestId="confirm-send-timesheet"
      />
    </>
  );
};

export default SubmitConfirmation;
