import React, { useEffect, useState } from 'react';
import './FavouriteCard.scss';
import { ButtonBase } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { ProposalRequest, TalentPool } from './FavouriteTalents';
import { useRequestProposal } from '../../data/jobs';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Routes } from '../../utils/consts';
import { numberWithDec } from '../../utils/numberWithDec';
import StudentAvatar from '../student-avatar';
import MiniteSnackbar from '../snackbar';
import { LoadingButton } from '../LoadingButton';

interface Props extends TalentPool {
  dashboard?: boolean;
  proposalRequests: Array<ProposalRequest>;
  refetchProposalRequests?: () => void;
}

const FavouriteCard = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { jobUid } = params;

  const {
    dashboard,
    student,
    user,
    education,
    proposalRequests,
    refetchProposalRequests,
  } = props;

  const { mutateAsync: requestProposalJob, error: requestProposalError } =
    useRequestProposal(student.student_uid);

  const { enqueueSnackbar } = useSnackbar();

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  useEffect(() => {
    if (requestProposalError) {
      showError(
        (requestProposalError?.response?.data as any)?.error?.message ||
          'Error',
      );
    }
  }, [requestProposalError]);

  const proposalRequest = proposalRequests?.find(
    (r) => r.job_uid === jobUid && r.student_uid === student.student_uid,
  );

  const [sendProposalLoading, setSendProposalLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSendProposal = async (event) => {
    event?.preventDefault();

    try {
      setSendProposalLoading(true);
      if (!proposalRequest) {
        await requestProposalJob(jobUid);
      }
      refetchProposalRequests();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    } finally {
      setSendProposalLoading(false);
    }
  };

  const maxProposalRequestsMade = proposalRequests.length >= 30;

  const studyInfo = `${education[0]?.degree || 'N/A'} ${
    education[0]?.study || 'N/A'
  }`;

  const handleNavigation = () => {
    navigate(
      generatePath(Routes.StudentProfileRoute, {
        profileId: student.student_uid,
      }),
      {
        replace: true,
      },
    );
  };

  return (
    <>
      <div className="favourite-card-container">
        <div className="card-header">
          <StudentAvatar
            avatar={user?.avatar}
            className="talent-avatar"
            alt="user avatar"
          />
          <div className="student-name-rate">
            <span className="name">{user.first_name}</span>
            <div className="rate">
              <StarIcon className="favourite-rate-star" />
              <span className="rate-number">
                {numberWithDec(student.rating)}
              </span>
            </div>
          </div>
        </div>
        <div className="card-content">
          <p className="student-info">{studyInfo}</p>
        </div>
        {dashboard ? (
          <div className="talent-footer">
            <div></div>
            <ButtonBase>
              <ArrowForwardIcon
                onClick={handleNavigation}
                sx={{
                  color: '#DEB900',
                  cursor: 'pointer',
                }}
                className="next-button"
              />
            </ButtonBase>
          </div>
        ) : (
          <div className="card-footer">
            <LoadingButton
              variant="contained"
              isDisabled={!!proposalRequest || maxProposalRequestsMade}
              onClick={handleSendProposal}
              isLoading={sendProposalLoading}
              className="medium-text"
              confirmButtonTestId="send-proposal"
              buttonText={
                proposalRequest
                  ? 'Proposal requested'
                  : maxProposalRequestsMade
                  ? 'Limit hit'
                  : 'Request proposal'
              }
            />
          </div>
        )}
      </div>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default FavouriteCard;
