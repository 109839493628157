import React, { useState, useEffect } from 'react';
import '../../financial/components/AccountForms.scss';
import { Button, FormGroup, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useUpdateCompanyLogo,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import MiniteSnackbar from '../../../../components/snackbar';
import { useTranslation } from 'react-i18next';
import CompanyAvatar from '../../../../components/company-avatar';
import { LoadingButton } from '../../../../components/LoadingButton';

const schema = yup.object().shape({
  bio: yup.string().max(600).required(),
  avatar: yup.string().nullable(),
});
interface CompanyProfileFormInterface {
  bio: string;
  avatar: string;
}

const CompanyProfileForm = ({ user, refetchUser }) => {
  const [errorSnack, showSnack] = useState(null);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { mutateAsync: updateCompanyLogo } = useUpdateCompanyLogo();
  const [tempAvatar, setTempAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.company',
  });
  const [avatar, setAvatar] = useState(
    user?.avatar
      ? process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${user?.avatar}?` +
          Date.now()
      : null,
  );
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    trigger,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<CompanyProfileFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      bio: user?.company?.bio,
      avatar: user?.avatar,
    },
    reValidateMode: 'onChange',
  });

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded?.size > 1048576) {
      showSnack('Image size should be less or equal 1mb');
      return;
    }
    const tempFileUrl = URL.createObjectURL(fileUploaded);
    const reader = new FileReader();
    reader.addEventListener('loadend', async () => {
      setValue('avatar', tempFileUrl, { shouldDirty: true });
      trigger();
      setTempAvatar({ url: tempFileUrl, file: fileUploaded, reader: reader });
    });
    reader.readAsArrayBuffer(fileUploaded);
  };

  const onSubmit = async (data: CompanyProfileFormInterface) => {
    setIsLoading(true);

    if (tempAvatar) {
      const imageResponse = await updateCompanyLogo({
        filename: tempAvatar?.file?.name,
        contentType: tempAvatar?.file?.type,
      });

      await fetch(imageResponse?.data?.url, {
        method: 'PUT',
        body: new Blob([tempAvatar?.reader?.result], {
          type: tempAvatar?.file?.type,
        }),
      });

      await updateUserProfile({
        user: {
          avatar: imageResponse?.data?.filename,
        },
      });
    }

    await updateUserProfile({
      company: {
        bio: data?.bio,
      },
    });

    await refetchUser();

    setTempAvatar(null);

    reset(data);

    setIsLoading(false);
  };

  useEffect(() => {
    setAvatar(
      process.env.REACT_APP_S3_BUCKET_URL +
        '/avatars/companies/' +
        `${user?.avatar}?` +
        Date.now(),
    );
  }, [user]);

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-title account-financial-forms-titles">
            {t('company_profile')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <CompanyAvatar
              avatar={tempAvatar?.url || avatar}
              className="company-logo-img"
            />

            <FormGroup className="form-group form-input-btn-group">
              <TextField
                className="form-group-input form-input"
                {...register('avatar')}
                name="avatar"
                type="text"
                id="avatar-input"
                disabled={true}
                variant="standard"
                label={t('company_logo')}
                placeholder="No file selected"
                error={!!errors.avatar}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                className="choose-logo-btn"
                variant="contained"
                component="label"
                data-testid="choose-logo-company"
                sx={{
                  minWidth: 100,
                }}
              >
                {t('choose_file')}
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onClick={handleClick}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                />
              </Button>
            </FormGroup>
            <TextField
              className="form-group-input form-input"
              {...register('bio')}
              name="bio"
              type="text"
              multiline
              maxRows={6}
              inputProps={{ maxLength: 600 }}
              id="bio-input"
              variant="standard"
              label={t('company_bio')}
              helperText={t('max_600_characters')}
              error={!!errors.bio}
              InputLabelProps={{ shrink: true }}
            />
          </form>

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-company-profile"
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={errorSnack}
        title={'Error'}
        message={errorSnack}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => showSnack(null)}
      />
    </section>
  );
};

export default CompanyProfileForm;
