import { useEffect, useState } from 'react';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useUserData } from '../../stores/auth';
import { UserData } from '../../data/user';
import { Routes } from '../../utils/consts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Auth } from 'aws-amplify';
import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import MiniteSnackbar from '../../components/snackbar';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '../../components/LoadingButton';
import { usePostJobWizardLoading } from '../postajob/store';
import {
  JobTypes,
  PaymentType,
  StudentTypes,
  getPaymentStatus,
  useCreateJobRequest,
  usePayJob,
  useSubmitJob,
  useUpdateJobRequest,
} from '../../data/jobs';
import { useFetchCategories, useFetchLangsAndSkills } from '../../data/shared';
import './index.scss';
import GenericModal from '../../components/generic-modal/GenericModal';
import { useTranslation } from 'react-i18next';

const schema = yup
  .object()
  .shape({
    job_title: yup.string().required(),
    job_description: yup.string().required(),
    direct_job_type: yup.string().required(),
    office_location: yup.string().required(),
    direct_job_start_date: yup.date().nullable().optional(),
    direct_job_compensation: yup.string().nullable().optional(),
  })
  .required();

const PostDirectJob = () => {
  useDocumentTitle('Post a Job');
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.direct_job',
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [currentJobUid, setCurrentJobUid] = useState(null);
  const navigate = useNavigate();
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;

  const initializing = usePostJobWizardLoading((state) => state.initializing);
  const setInitializing = usePostJobWizardLoading(
    (state) => state.setInitializing,
  );
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const refetchJob = usePostJobWizardLoading((state) => state.refetchJob);
  const job = usePostJobWizardLoading((state) => state.job);
  const { mutateAsync: createJobRequest } = useCreateJobRequest();
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();

  const { pathname } = useLocation();
  const path = pathname.split('/');
  const isPaid = path[path.length - 1] === 'paid';
  const [paidStatus, setPaidStatus] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutateAsync: submitJob } = useSubmitJob();
  const { mutateAsync: payJob } = usePayJob();

  const { data: categories = [] } = useFetchCategories();
  const otherCategoryUid = categories?.find(
    (item) => item.category_name === 'Other',
  )?.category_uid;
  const [{ data: languages = [] }, { data: skills = [] }] =
    useFetchLangsAndSkills();
  const englishLanguage = languages?.find(
    (item) => item.language_name === 'Dutch',
  );
  const excelSkill = skills?.find((item) => item.skill_name === 'Excel');

  const params = useParams();
  const { jobUid } = params;

  const {
    handleSubmit,
    setValue,
    formState: { isValid, errors },
    register,
    getValues,
  } = useForm<{
    job_title: string;
    job_description: string;
    direct_job_type: string;
    direct_job_compensation: string;
    office_location: string;
    direct_job_start_date?: string | undefined;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      direct_job_type: '',
      office_location: '',
      direct_job_start_date: undefined,
    },
  });

  const toPay =
    getValues('direct_job_type') !== '' &&
    getValues('direct_job_type') !== 'Inhouse day' &&
    getValues('direct_job_type') !== 'Event' &&
    (!jobUid || job?.job_title === 'Draft' || paidStatus !== 'paid') &&
    user?.company?.direct_job_fee !== 0;

  const redirectToJob = () => {
    navigate(Routes.BrowseJobsRoute + `/${currentJobUid}`, {
      replace: true,
    });
  };

  const saveJob = async (data) => {
    const {
      job_title,
      job_description,
      direct_job_type,
      direct_job_compensation,
      office_location,
      direct_job_start_date,
    } = data;

    try {
      setLoading(true);
      const payload = {
        job: {
          job_title,
          job_description,
          direct_job_type,
          office_location,
          job_start_at: direct_job_start_date
            ? direct_job_start_date
            : undefined,
          job_end_at: direct_job_start_date ? direct_job_start_date : undefined,
          direct_job: true,
          direct_job_compensation: direct_job_compensation
            ? '€' + direct_job_compensation + ` / ${t('month')}`
            : undefined,
          student_type: StudentTypes.EntryLevel,
          category_uid: otherCategoryUid,
          sub_category_name: 'Other',
          students_needed: 9999,
        },
        languages: [englishLanguage],
        skills: [excelSkill],
      };

      await updateJobRequest({ job_uid: currentJobUid, body: payload });
    } catch (error) {
      setErrorMessage('Failed to update job!');
    }
  };

  const handleSubmitJob = async () => {
    try {
      setLoading(true);
      await submitJob({ jobUid: currentJobUid });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      redirectToJob();
    } catch (error) {
      setErrorMessage('Failed to submit job!');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await saveJob(data);
      if (toPay) {
        const response = await payJob({
          jobUid: currentJobUid,
          body: {
            job_boost_pack: 0,
            payment_type: PaymentType.DirectJobPostingFee,
          },
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        window.open(response.data.checkout_url, '_self');
      } else {
        setLoading(true);
        await handleSubmitJob();
      }
    } catch (error) {
      setErrorMessage('Failed to update job!');
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      // Fetch existing job
      if (jobUid) {
        try {
          setInitializing(true);
          setLoading(true);
          setCurrentJobUid(jobUid);
          await refetchJob(jobUid);
        } catch (error) {
          setErrorMessage('Failed to load job');
        } finally {
          if ((isPaid && paidStatus !== 'paid') || !isPaid) {
            setLoading(false);
            setInitializing(false);
          }
        }
      } else {
        try {
          setInitializing(true);
          setLoading(true);
          const payload = {
            job: {
              job_title: undefined,
              direct_job: true,
              job_type: JobTypes.ONGOING,
            },
          };
          const response = await createJobRequest(payload);
          setCurrentJobUid(response.job.job_uid);
          await refetchJob(response.job.job_uid);
        } catch (error) {
          setErrorMessage('Job creation failed!');
        } finally {
          setLoading(false);
          setInitializing(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (user.company_uid === null) {
      navigate(Routes.Forbidden);
    }
  }, [user]);

  useEffect(() => {
    if (user?.email) {
      (async () => {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          // Only check for non google users since google users are not allowed
          // to change emails
          if (!cognitoUser.username.includes('google_')) {
            const userEmail = localStorage.getItem('username');
            if (
              userEmail &&
              user.email.toLowerCase() !== userEmail.toLowerCase()
            ) {
              await Auth.signOut();
            }
          }
        } catch (error) {
          await Auth.signOut();
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    if (job) {
      setValue('job_title', job.job_title === 'Draft' ? '' : job.job_title);
      setValue('job_description', job.job_description);
      setValue(
        'direct_job_type',
        job.direct_job_type ? job.direct_job_type : '',
      );
      setValue(
        'direct_job_compensation',
        job.direct_job_compensation
          ? job.direct_job_compensation.split(' ')[0].slice(1)
          : '',
      );
      setValue('office_location', job.office_location);

      (async () => {
        try {
          const responseStatus = await getPaymentStatus(
            jobUid,
            PaymentType.DirectJobPostingFee,
          );
          setPaidStatus(responseStatus);
        } catch (error) {
          console.info('New job, no payment status');
        }
      })();
    }
  }, [job]);

  useEffect(() => {
    if (isPaid) {
      setLoading(true);
      (async () => {
        try {
          setLoading(true);
          try {
            setLoading(true);
            const responseStatus = await getPaymentStatus(
              jobUid,
              PaymentType.DirectJobPostingFee,
            );

            setPaidStatus(responseStatus);

            if (responseStatus !== 'paid') {
              setIsOpenModal(true);
            }
          } catch (error) {
            setErrorMessage('Failed to get payment status!');
          } finally {
            setLoading(false);
          }
        } catch (error) {
          setErrorMessage('Failed to retrieve job!');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (isPaid) {
      (async () => {
        try {
          setLoading(true);
          const responseStatus = await getPaymentStatus(
            jobUid,
            PaymentType.DirectJobPostingFee,
          );
          if (responseStatus === 'paid' && currentJobUid === jobUid) {
            await handleSubmitJob();
          }
        } catch (error) {
          setErrorMessage('Failed to retrieve job!');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentJobUid]);

  const handleTitleChange = (event: any) => {
    setValue('job_title', event.target.value, {
      shouldValidate: true,
    });
  };

  const handleLocationChange = (event: any) => {
    setValue('office_location', event.target.value, {
      shouldValidate: true,
    });
  };

  const handleDateChange = (newValue: any) => {
    setValue('direct_job_start_date', newValue, {
      shouldValidate: true,
    });
  };

  const handleTypeChange = (event: any) => {
    setValue('direct_job_type', event.target.value ? event.target.value : '', {
      shouldValidate: true,
    });
  };

  const handleDescriptionChange = (event: any) => {
    setValue('job_description', event.target.value, {
      shouldValidate: true,
    });
  };

  const handledirect_job_compensationChange = (event: any) => {
    setValue('direct_job_compensation', event.target.value, {
      shouldValidate: true,
    });
  };

  const SkeletonForm = () => {
    return (
      <Stack spacing={1} width={'45%'} sx={{ marginX: 'auto' }}>
        <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="rounded" height={260} />
      </Stack>
    );
  };

  if (initializing) {
    return <SkeletonForm />;
  }

  return (
    <>
      {loading ? (
        <>
          {!isPaid ? (
            <SkeletonForm />
          ) : (
            <div>
              <CircularProgress />
              <p>{t('loading')}</p>
            </div>
          )}
        </>
      ) : (
        <>
          <Grid
            className="createjob-container"
            container
            direction="row"
            justifyContent="center"
          >
            <div className="createjob-content">
              <p className="createjob-content-title">
                <span>{t('title')}</span>
                <span className="content-desc">{t('job_details')}</span>
              </p>
              <section className="direct-job-section">
                <form className="direct-job-form">
                  <div className="form-inputs">
                    <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
                      <TextField
                        {...register('job_title')}
                        type="text"
                        id="job-title"
                        label={t('job_title')}
                        error={Boolean(errors.job_title)}
                        variant="standard"
                        data-testid="job-title"
                        onChange={handleTitleChange}
                        InputLabelProps={{
                          shrink: Boolean(getValues('job_title')),
                        }}
                        required
                      />
                    </FormControl>
                    <FormControl
                      sx={{ width: '100%', marginBottom: '20px' }}
                      variant="standard"
                      required
                    >
                      <InputLabel>{t('job_type')}</InputLabel>
                      <Select
                        {...register('direct_job_type')}
                        id="job-type-select"
                        error={Boolean(errors.direct_job_type)}
                        label={t('job_type')}
                        data-testid="job-type"
                        onChange={handleTypeChange}
                        value={getValues('direct_job_type')}
                        required
                      >
                        <MenuItem value={'Internship'}>
                          {t('internship')}
                        </MenuItem>
                        <MenuItem value={'Traineeship'}>
                          {t('traineeship')}
                        </MenuItem>
                        <MenuItem value={'Fulltime'}>{t('fulltime')}</MenuItem>
                        <MenuItem value={'Parttime'}>{t('parttime')}</MenuItem>
                        <MenuItem value={'Inhouse day'}>
                          {t('inhouse')}
                        </MenuItem>
                        <MenuItem value={'Event'}>{t('event')}</MenuItem>
                      </Select>
                    </FormControl>
                    {(getValues('direct_job_type') === 'Inhouse day' ||
                      getValues('direct_job_type') === 'Event') && (
                      <FormControl
                        className="direct_job_start_at"
                        sx={{ width: '100%', marginBottom: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={`${getValues('direct_job_type')} date *`}
                            minDate={Date.now()}
                            value={getValues('direct_job_start_date')}
                            onChange={(newValue) => handleDateChange(newValue)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}
                    <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
                      <TextField
                        {...register('office_location')}
                        type="text"
                        id="office_location"
                        label={t('office')}
                        error={Boolean(errors.office_location)}
                        variant="standard"
                        data-testid="office_location"
                        onChange={handleLocationChange}
                        InputLabelProps={{
                          shrink: Boolean(getValues('office_location')),
                        }}
                        required
                      />
                    </FormControl>
                    {getValues('direct_job_type') !== 'Inhouse day' &&
                      getValues('direct_job_type') !== 'Event' && (
                        <FormControl
                          sx={{ width: '100%', marginBottom: '20px' }}
                        >
                          <TextField
                            {...register('direct_job_compensation')}
                            className="numberInput"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  / {t('month')}
                                </InputAdornment>
                              ),
                            }}
                            error={Boolean(errors.direct_job_compensation)}
                            type="text"
                            helperText={
                              'Enter a number (e.g. 400), or a range (e.g. 2500-3000)'
                            }
                            id="direct_job_compensation"
                            label={t('compensation')}
                            variant="standard"
                            data-testid="direct_job_compensation"
                            onChange={handledirect_job_compensationChange}
                            InputLabelProps={{
                              shrink: Boolean(
                                getValues('direct_job_compensation'),
                              ),
                              style: { marginLeft: 20 },
                            }}
                          />
                        </FormControl>
                      )}
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        {...register('job_description')}
                        error={Boolean(errors.job_description)}
                        type="text"
                        rows={10}
                        multiline
                        id="job-description"
                        label={t('job_description')}
                        variant="standard"
                        data-testid="job-description"
                        onChange={handleDescriptionChange}
                        InputLabelProps={{
                          shrink: Boolean(getValues('job_description')),
                        }}
                        required
                      />
                    </FormControl>
                  </div>
                </form>
              </section>
              {toPay && (
                <>
                  <h1>{t('payment')}</h1>
                  <div className="payment-section">
                    <div className="new-job-container">
                      <div className="new-job-container">
                        <div className="new-job-col1">
                          <p>{t('posting_fee')}</p>
                        </div>

                        <div className="new-job-col2">
                          <p>
                            €
                            {(user?.company?.direct_job_fee).toLocaleString(
                              'nl',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="total-section">
                    <div className="new-job-total">
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow
                              sx={{
                                borderBottomWidth: '1px',
                                borderBottomColor: '#c7d2e2',
                                borderBottomStyle: 'solid',
                              }}
                            >
                              <TableCell
                                className="table-cells total-cell left-cells"
                                align="right"
                              >
                                {t('total')}
                              </TableCell>
                              <TableCell
                                className="table-cells total-cell"
                                align="right"
                              >
                                {'€' +
                                  (user?.company?.direct_job_fee).toLocaleString(
                                    'nl',
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{ borderBottom: 'none' }}
                                className="table-cells left-cells"
                                align="right"
                              >
                                21% {t('vat')}
                              </TableCell>
                              <TableCell
                                sx={{ borderBottom: 'none' }}
                                className="table-cells"
                                align="right"
                              >
                                {'€' +
                                  (
                                    user?.company?.direct_job_fee * 0.21
                                  ).toLocaleString('nl', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{ borderBottom: 'none' }}
                                className="table-cells total-cell left-cells"
                                align="right"
                              >
                                {t('total_incl_vat')}
                              </TableCell>
                              <TableCell
                                sx={{ borderBottom: 'none' }}
                                className="table-cells total-cell"
                                align="right"
                              >
                                {'€' +
                                  (
                                    user?.company?.direct_job_fee * 1.21
                                  ).toLocaleString('nl', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </>
              )}

              <div className="createjob-content-buttons flex-end">
                <LoadingButton
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isValid || loading}
                  confirmButtonTestId="save-direct-post-job"
                  buttonText={toPay ? 'Save & Pay' : 'Save'}
                  isLoading={loading}
                  className="pay-button"
                />
              </div>
            </div>
          </Grid>

          <GenericModal
            open={isOpenModal}
            title={t('payment_status')}
            content={t('payment_failed')}
            confirmButtonText={t('continue')}
            confirmButtonTestId="close-couldnt-process-payment"
            handleConfirm={() => {
              setIsOpenModal(false);
              setPaidStatus('');
            }}
          />

          <MiniteSnackbar
            open={!!errorMessage}
            title={'Error'}
            message={errorMessage}
            autoHideDuration={4000}
            severity={'error'}
            onClose={() => setErrorMessage(null)}
          />
        </>
      )}
    </>
  );
};

export default PostDirectJob;
