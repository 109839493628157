import { useQuery } from '@tanstack/react-query';
import { ServerResponse, request } from '../core/request';
import { ASSESSMENT_CATEGORIES } from './query-keys';
import { AxiosError } from 'axios';

export enum AssessmentType {
  AttentionToDetail = 'attention-to-detail',
  Personality = 'personality',
}
export interface AssessmentCategory {
  assessment_category_uid: string;
  type: AssessmentType;
  category_name: string;
  category_description: string;
  created_at?: string;
  updated_at?: string;
  latest_assessment_score: string;
  last_attempt_at: string | null;
  number_of_attempts: number;
}
export interface AssessmentQuestion {
  assessment_question_uid: string;
  assessment_category_uid: string;
  text_1: string;
  text_2: string;
  language: string;
  question: string;
  correct_answer: string;
  image: string;
  created_at?: string;
  updated_at?: string;
  sample: boolean;
  explanation?: string;
}

export interface AssessmentQuestionChoice {
  assessment_questions_choice_uid: string;
  assessment_question_uid: string;
  letter: string;
  description: string;
  created_at?: string;
  updated_at?: string;
}

export interface AssessmentQuestionDetails extends AssessmentQuestion {
  choices: Array<AssessmentQuestionChoice>;
}

export interface AssessmentQuestionsResponse {
  questions: Array<AssessmentQuestionDetails>;
  category_name: string;
  category_description: string;
  sample_question: AssessmentQuestionDetails;
  can_retry: boolean;
  number_of_questions: number;
  time: number;
  assessment_category_uid: string;
}

export interface StudentAnswerAssessmenResponse {
  correct: boolean;
}

export interface StudentGetAsssessmentQuestionsPayload {
  language: string;
  count?: number;
}

export interface StudentAnswerAssessmentQuestionPayload {
  student_test_uuid: string;
  answer: string;
  hasLostFocus: boolean;
}

export const studentFetchAssessmentQuestions = async (
  assessment_category_type: string,
  payload: StudentGetAsssessmentQuestionsPayload,
) => {
  const { data } = await request<ServerResponse<AssessmentQuestionsResponse>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/assessments/questions/${assessment_category_type}`,
    method: 'POST',
    data: payload,
  });

  return data.data;
};

export const studentAnswerAssessmentQuestion = async (
  assessment_question_uid: string,
  payload: StudentAnswerAssessmentQuestionPayload,
) => {
  const { data } = await request<
    ServerResponse<StudentAnswerAssessmenResponse>
  >({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/assessments/questions/${assessment_question_uid}/answer`,
    method: 'POST',
    data: payload,
  });

  return data.data;
};

export const studentFetchAssessmentCategories = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/assessments/categories',
    method: 'GET',
  });

  return data.data?.assessment_categories as Array<AssessmentCategory>;
};

export const useStudentFetchAssessmentCategories = () => {
  return useQuery(
    [ASSESSMENT_CATEGORIES],
    () => studentFetchAssessmentCategories(),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};
