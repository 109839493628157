import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { ProposalStatus, ProposalStatuses } from '../../../data/jobs';
import ChatHeader from './ChatHeader';
import { useUserData } from '../../../stores/auth';
import { useFetchOpenedChat } from '../../../data/user';

const notShowMessageFiled = {
  [ProposalStatuses.Rejected]: true,
  [ProposalStatuses.Retracted]: true,
};

const Chat = () => {
  const params = useParams();
  const isWithAdmin = params.proposalId === 'admin';

  const [proposalInfo] = useState({
    id: params.proposalId,
    avatar: null,
    status: null as ProposalStatus | undefined,
    jobTitle: '',
    name: '',
    isDeleted: false,
  });
  const user = useUserData((state) => state.user);
  const { data: chats = [], isLoading } = useFetchOpenedChat();

  const isStudent = !!user?.student_uid;
  const talkjsContainer = useRef(null);
  const [chat, setChat] = useState(null);
  const [conversationId, setConversation] = useState(null);
  const [unreadConversations] = useState(0);
  const [isSupportButton, setIsShowSupport] = useState(false);

  const isShowSupport = () => {
    if (Array.isArray(chats) && chats.length) {
      const withSupport = chats.find((item) => {
        return item.custom?.admin && item.lastMessage;
      });

      if (isSupportButton !== !withSupport) {
        setIsShowSupport(!withSupport);
      }
    } else {
      setIsShowSupport(true);
    }
  };

  useEffect(() => {
    if (chat && proposalInfo) {
      if (
        proposalInfo?.status &&
        proposalInfo?.status !== ProposalStatuses.Signed
      ) {
        chat.setMessageFilter({
          custom: {
            sensitiveInfo: '!exists',
          },
        });
      } else {
        chat.setMessageFilter(null);
      }

      setChat(chat);
    }

    if (chat) {
      chat?.messageField.setVisible(
        !notShowMessageFiled[proposalInfo?.status] && !proposalInfo.isDeleted,
      );
    }

    if (
      !isWithAdmin &&
      conversationId &&
      proposalInfo &&
      conversationId.custom?.status !== proposalInfo?.status
    ) {
      conversationId.setAttributes({
        custom: {
          ...conversationId.custom,
          status: proposalInfo.status || '',
        },
      });

      setConversation(conversationId);
    }
  }, [proposalInfo]);

  useEffect(() => {
    isShowSupport();
  }, [chats]);

  return (
    <>
      <div
        className={`chat-container ${
          proposalInfo?.status !== ProposalStatuses.Signed ? 'isWarning' : ''
        }`}
      >
        <ChatHeader
          {...proposalInfo}
          unreadConversations={unreadConversations}
          isSupportButton={isSupportButton && !isLoading}
          isAdmin={!proposalInfo.status}
          isUserStudent={isStudent}
        />
        <div ref={talkjsContainer} className="messages-list">
          ...loading
        </div>
      </div>
    </>
  );
};

export default Chat;
