import React, { useState } from 'react';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { Routes } from '../../../utils/consts';

const Row = ({ row }: { row: any }) => {
  const [open, setOpen] = useState(false);
  const { created_at, sender, receiver, job_info, description } = row;

  return (
    <>
      <TableRow
        sx={{
          borderBottom: open ? 'none' : '1px solid rgb(224, 224, 224)',
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component="th" scope="row">
          {created_at}
        </TableCell>
        <TableCell>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${
              sender.type === 'student'
                ? Routes.AdminStudentsRoute
                : Routes.AdminCompaniesRoute
            }/${sender.id}`}
            title={sender.name}
          >
            {sender.name}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${
              receiver.type === 'student'
                ? Routes.AdminStudentsRoute
                : Routes.AdminCompaniesRoute
            }/${receiver.id}`}
            title={receiver.name}
          >
            {receiver.name}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminJobsRoute}/${job_info.job_uid}`}
            title={job_info.job_title}
          >
            {job_info.job_title}
          </Link>
        </TableCell>
        <TableCell>{row.rating}</TableCell>
        <TableCell style={{ width: '30px' }}>
          {open ? (
            <KeyboardArrowUpIcon style={{ fill: '#4E5B73' }} />
          ) : (
            <KeyboardArrowDownIcon style={{ fill: '#4E5B73' }} />
          )}
        </TableCell>
      </TableRow>
      <TableRow
        style={{
          borderBottom: open ? '1px solid rgb(224, 224, 224)' : 'none',
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>{description}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
