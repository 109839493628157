import React, { useEffect, useState } from 'react';
import './Snackbar.scss';
import {
  Alert,
  AlertTitle,
  Snackbar,
  SnackbarOrigin,
  useMediaQuery,
} from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

interface SnackbarProps {
  message: string;
  title?: string;
  severity?: AlertColor;
  open?: boolean;
  autoHideDuration?: number | null;
  onClose: () => void;
  location?: SnackbarOrigin;
  offsetTopValue?: number;
  cancelOffsetIfNotAtTop?: boolean;
}

const MiniteSnackbar = ({
  message,
  title,
  severity = 'error',
  open = false,
  autoHideDuration = 4000,
  onClose,
  location = null,
  offsetTopValue = 0,
  cancelOffsetIfNotAtTop,
}: SnackbarProps) => {
  const [snack, showSnack] = useState(false);

  const isMobileView = useMediaQuery('(max-width: 600px)');

  const [isPageAtTop, setPageAtTop] = useState(true);

  const handleClose = () => {
    showSnack(false);
    onClose && onClose();
  };

  useEffect(() => {
    showSnack(open);
  }, [open]);

  useEffect(() => {
    if (cancelOffsetIfNotAtTop) {
      setPageAtTop(window.scrollY <= 10);
    }
  }, [cancelOffsetIfNotAtTop, window.scrollY]);

  return (
    <>
      <Snackbar
        onClose={autoHideDuration && handleClose}
        autoHideDuration={autoHideDuration}
        open={snack}
        anchorOrigin={location || { vertical: 'bottom', horizontal: 'center' }}
        sx={{
          marginTop: (isPageAtTop ? offsetTopValue : 0) + 'px',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{
            width: isMobileView ? 300 : 600,
          }}
        >
          <AlertTitle className="minite-alert-title">
            {title ? title : severity.toUpperCase()}
          </AlertTitle>
          <span className="minite-alert-text">{message}</span>
        </Alert>
      </Snackbar>
    </>
  );
};

export default MiniteSnackbar;
