export const FormSteps = {
  company: [
    {
      label: 'sign_up_company.your_acc',
      description: 'sign_up_company.complete_signup',
    },
    {
      label: 'sign_up_company.your_company',
      description: 'sign_up_company.address',
    },
  ],
  student: [
    {
      label: 'sign_up_student.education',
      description: 'sign_up_student.on_paper',
    },
    {
      label: 'sign_up_student.personal',
      description: 'sign_up_student.makes_you',
    },
    {
      label: 'sign_up_student.professional',
      description: 'sign_up_student.any_work_exp',
    },
  ],
};
