import React, { useEffect, useState } from 'react';
import './Assessment.scss';
import { Alert, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../utils/useDocumentTitle';
import TimedAssessment from './TimedAssessment';
import { useSetStartedAssessment } from '../../data/user';
import { useLocation } from 'react-router-dom';
import { studentFetchAssessmentQuestions } from '../../data/assessments';
import { getLanguage } from '../../utils/getLanguage';
import { LoadingButton } from '../LoadingButton';
import MiniteSnackbar from '../snackbar';
import {
  QuestionRepresentationObject,
  getQuestionRespresentationObject,
} from './question-data';
import { Question } from './Question';
import UnlimitedAssessment from './UnlimitedAssessment';

export const defaultButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  width: 'auto',
  height: '36px',
  borderRadius: '4px',
  fontFamily: 'Sofia W05 Semi Bold',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: ' 0.4px',
  textTransform: 'uppercase',
  color: '#393A42',
};

const LoadingPlaceHolder = () => {
  return (
    <div>
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
    </div>
  );
};

const Assessment = ({
  assessmentCategoryType,
}: {
  assessmentCategoryType: string;
}) => {
  useDocumentTitle('Finish your assessment');

  const { t } = useTranslation('translation', { keyPrefix: 'assessment' });
  const [started, setStarted] = useState(false);
  const [sampleSubmitted, setSampleSubmitted] = useState(false);
  const [studentTestId, setStudentTestId] = useState('');
  const [canRetry, setCanRetry] = useState(false);
  const [wasVerifiedInitially] = useState(false);
  const [isStartDisabled, setIsStartDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const location = useLocation();

  const language = getLanguage();

  // Don't use react query, so that questions won't be reloaded in the
  // middle of assessment
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const assessmentData = await studentFetchAssessmentQuestions(
          assessmentCategoryType,
          {
            language,
          },
        );
        setAssessmentData(assessmentData);
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error?.message || error?.message?.toString(),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // Reload questions when path changes
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const assessmentData = await studentFetchAssessmentQuestions(
          assessmentCategoryType,
          {
            language,
          },
        );
        setAssessmentData(assessmentData);
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error?.message || error?.message?.toString(),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [location]);

  const questions = assessmentData?.questions ?? [];
  const {
    category_name,
    category_description,
    sample_question,
    can_retry,
    assessment_category_uid,
    time: maxTime,
  } = assessmentData || {};

  /**
   * Get sample question and pass it to the Question component
   */
  const sampleQuestionRepr: QuestionRepresentationObject | null =
    sample_question ? getQuestionRespresentationObject(sample_question) : null;

  const {
    mutateAsync: setStartedAssessment,
    isLoading: isLoadingStartAssessment,
  } = useSetStartedAssessment();

  useEffect(() => {
    setCanRetry(!!can_retry);
  }, [can_retry]);

  const [userSwitchedTabs, setUserSwitchedTabs] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setUserSwitchedTabs(true);
      }
    };

    const handleBlur = () => {
      setUserSwitchedTabs(true);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Needs to attach to the window object as this event only fires for elements that got focus
    window.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleTestSubmit = () => {
    setSampleSubmitted(true);
    setIsStartDisabled(false);
  };

  const handleStarted = async () => {
    try {
      const params = {
        started: true,
        started_at: new Date().toISOString(),
        assessment_category_uid,
        finished: false,
      };
      const res = await setStartedAssessment(params);
      setStarted(true);
      setUserSwitchedTabs(false);
      setStudentTestId(res.student_test_uuid);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error?.message || error?.message?.toString(),
      );
    }
  };

  if (!assessmentData && !isLoading) {
    return <Alert severity="error">{t('error_loading_assessment')}</Alert>;
  }

  return (
    <>
      {isLoading ? (
        <>
          <LoadingPlaceHolder />
        </>
      ) : canRetry ? (
        <>
          {!started ? (
            <section>
              <div>
                <div className="assessment-title">
                  <Typography variant="h5" component="div">
                    {category_name}
                  </Typography>
                </div>
                <p>{category_description}</p>
              </div>
              <Grid
                container
                spacing={2}
                className="sample-container unselectable"
              >
                <Grid item xs={12}>
                  <Typography variant="h6" component="div">
                    {t('sample')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Question
                    question={sampleQuestionRepr}
                    handleTestSubmit={handleTestSubmit}
                    submitButtonText={'Submit'}
                  />
                </Grid>
              </Grid>
              {sampleSubmitted && (
                <div>
                  <p>{sample_question.explanation}</p>
                  <p>
                    {maxTime ? t('sample_note') : t('sample_note_unlimited')}
                  </p>
                </div>
              )}
              <Tooltip
                title={
                  isStartDisabled
                    ? wasVerifiedInitially
                      ? t('button_disable_tooltip_verified')
                      : t('button_disable_tooltip')
                    : ''
                }
                arrow
                placement="right"
              >
                <span>
                  <LoadingButton
                    variant={'contained'}
                    onClick={handleStarted}
                    isLoading={isLoadingStartAssessment}
                    buttonText="Start assessment now"
                    confirmButtonTestId="start-test"
                    isDisabled={isStartDisabled}
                    sx={{
                      ...defaultButtonStyle,
                    }}
                  />
                </span>
              </Tooltip>
            </section>
          ) : maxTime ? (
            <TimedAssessment
              studentTestId={studentTestId}
              wasVerifiedInitially={wasVerifiedInitially}
              questions={questions}
              maxTime={maxTime}
              switchedTabs={userSwitchedTabs}
              setSwitchedTabs={setUserSwitchedTabs}
            />
          ) : (
            <UnlimitedAssessment
              studentTestId={studentTestId}
              wasVerifiedInitially={wasVerifiedInitially}
              questions={questions}
              assessmentCategoryType={assessmentCategoryType}
              switchedTabs={userSwitchedTabs}
              setSwitchedTabs={setUserSwitchedTabs}
            />
          )}
        </>
      ) : (
        <section className="welcome-section">
          <div className="title-container">
            <Typography className="title" variant="h6" component="div">
              {t('already_tried')}
            </Typography>
            <p className="already-tried-description">{t('wait')}</p>
          </div>
        </section>
      )}
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'bottom', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};

export default Assessment;
