export enum StudentForm {
  EDUCATION = 'EDUCATION',
  PERSONAL = 'PERSONAL',
  EXPERIENCE = 'EXPERIENCE',
  ALL_FORM = 'ALL_FORM',
}

export const initFormData = {
  education: {
    languages: [
      {
        language: '',
        level: '',
      },
    ],
    universities: [
      {
        university: '',
        degree: '',
        study: '',
        startDate: null,
        endDate: null,
      },
    ],
  },
  personal: {
    rate: '',
    avatar: '',
    info: '',
    details: '',
    job_interests: [],
    certificates: [
      {
        certificate: '',
      },
    ],
  },
  experience: {
    jobs: [
      {
        job: '',
        type: '',
        organization: '',
        startDate: null,
        endDate: null,
        skills: [],
        description: '',
      },
    ],
  },
};

export function StudentFormReducer(state = initFormData, action) {
  if (action.type === StudentForm.EDUCATION) {
    return {
      ...state,
      education: { ...action.payload },
    };
  }

  if (action.type === StudentForm.PERSONAL) {
    return {
      ...state,
      personal: { ...action.payload },
    };
  }

  if (action.type === StudentForm.EXPERIENCE) {
    return {
      ...state,
      personal: { ...action.payload },
    };
  }

  if (action.type === StudentForm.ALL_FORM) {
    return {
      ...state,
      ...initFormData,
    };
  }
  return state;
}
