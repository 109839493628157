import {
  useFinishUpdateProposal,
  useRetractProposal,
  useUpdateProposal,
} from '../../../data/proposals';
import * as yup from 'yup';
import { JobTypes, StudentTypes } from '../../../data/jobs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  checkboxClasses,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import './CreateProposalDrawer.scss';
import { ProposalStatuses } from '../index';
import ProposalConfirmationModal from './ProposalConfirmationModal';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/LoadingButton';

const isEmail = (message) => {
  return message.includes('@');
};

const isPhoneNumber = (message) => {
  let counter = 0;
  const newMess = message.replaceAll(' ', '');

  for (let i = 0; i < newMess.length; i++) {
    const letter = newMess[i];

    if (!Number.isNaN(+letter)) {
      counter++;

      if (counter >= 8) {
        return true;
      }
    } else {
      if (counter >= 8) {
        return true;
      }

      counter = 0;
    }
  }

  return counter >= 8;
};

const StudentDrawerContent = (props) => {
  const proposalStatusChips = {
    [ProposalStatuses.DRAFT]: {
      backgroundColor: '#ffffff',
      color: '#4E5B73',
      height: '24px',
      width: 'max-content',
      fontSize: '12px',
      fontFamily: '"Sofia Pro Medium", sans-serif',
      border: '1.5px solid #4E5B73',
    },
  };

  const jobData = props?.job;
  const proposalData = props?.proposal;
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { mutateAsync: retractProposal } = useRetractProposal();
  const { mutateAsync: finishUpdateProposal, isLoading } =
    useFinishUpdateProposal();
  const [isRetractDialogOpen, setIsRetractDialogOpen] = useState(false);
  const handleRetractDialogOpen = () => setIsRetractDialogOpen(true);
  const [proposalCharsCount, setProposalCharsCount] = useState(0);
  const [containsPersonal, setContainsPersonal] = useState(false);
  const [containsPersonalY, setContainsPersonalY] = useState(false);

  const schema = yup
    .object()
    .shape({
      rate: yup.number().min(+jobData?.budget),
      counter_offer_description: yup.string().nullable().notRequired(),
      how_you_do_x: yup.string().min(1).required(),
      how_you_do_y: yup.string().nullable().notRequired(),
      min_month:
        jobData?.job_type === JobTypes.ONGOING
          ? yup.number().min(1).required()
          : yup.number().min(1).nullable().notRequired(),
      accept: yup.bool().oneOf([true]).required(),
    })
    .required();
  if (jobData?.student_type === StudentTypes.Regular) {
    schema.rate = yup.number().required();
  }

  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    control,
    watch,
    getValues,
    setValue,
  } = useForm<{
    rate: number;
    counter_offer_description: string;
    how_you_do_x: string;
    how_you_do_y: string;
    min_month: number;
    accept: boolean;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      rate: +jobData?.budget,
      counter_offer_description: proposalData?.counter_offer_description,
      how_you_do_x: proposalData?.how_you_do_x,
      how_you_do_y: proposalData?.how_you_do_y,
      min_month: proposalData?.min_month,
      accept: false,
    },
    reValidateMode: 'onChange',
  });

  const rateValue = watch('rate');

  const onSubmit = (value) => {
    const payload = {
      min_month: value.min_month,
      counter_offer_description: value.counter_offer_description,
      how_you_do_x: value.how_you_do_x,
      how_you_do_y: value.how_you_do_y,
      rate: value.rate,
    };

    finishUpdateProposal({
      proposalId: proposalData.proposal_uid,
      data: payload,
    }).then(
      (res) => {
        props.handleDrawerClose();
        props?.handleProposalSubmit();
        console.log(res);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const handleRetractJob = () => {
    retractProposal(proposalData?.proposal_uid).then(() => {
      setIsRetractDialogOpen(false);
      props.handleDrawerClose(null);
    });
  };

  const handleClose = (event) => {
    const payload = getValues();

    updateProposal({
      proposalId: proposalData.proposal_uid,
      data: payload,
    }).then(
      () => {
        props.handleDrawerClose(event);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const handleProposalDescriptionChange = (event: any) => {
    const { value } = event.target;

    setValue('how_you_do_x', value);
    setProposalCharsCount(value.length);

    if (isEmail(value) || isPhoneNumber(value)) {
      setContainsPersonal(true);
    } else {
      setContainsPersonal(false);
    }
  };

  const handleProposalDescriptionChangeY = (event: any) => {
    const { value } = event.target;

    setValue('how_you_do_y', value);

    if (isEmail(value) || isPhoneNumber(value)) {
      setContainsPersonalY(true);
    } else {
      setContainsPersonalY(false);
    }
  };

  const { t } = useTranslation('translation', { keyPrefix: 'proposal' });

  return (
    <>
      <section className="student-drawer-container">
        <div className="student-drawer-header">
          <div className="title-container">
            <p className="title">{t('your_application')}</p>
            <CloseOutlinedIcon
              className="close-drawer-icon"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="student-drawer-content">
          <div className="status-row">
            <span className="status-title">Status</span>
            <Chip
              className="proposal-status"
              label={'Draft'}
              sx={{
                ...proposalStatusChips[ProposalStatuses.DRAFT],
              }}
              variant="outlined"
            />
          </div>
          <div className="form-container">
            {jobData?.student_type === StudentTypes.Regular && (
              <p className="rate-title">Rate</p>
            )}

            <form className="student-proposal-form">
              {jobData?.student_type === StudentTypes.Regular && (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...register('rate')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    helperText={
                      jobData?.job_type === JobTypes.ONGOING
                        ? `Min €${+jobData.budget}/hr`
                        : `Min €${(+jobData?.budget).toFixed()}`
                    }
                    type="number"
                    id="rate"
                    error={Boolean(errors.rate)}
                    label="Your counteroffer"
                    variant="standard"
                    data-testid="counteroffer"
                  />
                </FormControl>
              )}
              {rateValue > +jobData?.budget && (
                <FormControl sx={{ width: '100%', marginTop: '16px' }}>
                  <TextField
                    {...register('counter_offer_description')}
                    type="text"
                    rows={4}
                    multiline
                    id="counter_offer_description"
                    label="Tell them why you’re worth it!"
                    variant="standard"
                    data-testid="counter-offer-description"
                  />
                </FormControl>
              )}
              {jobData?.job_type === JobTypes.ONGOING && (
                <FormControl
                  className="min-month-control"
                  sx={{ width: '100%' }}
                >
                  <TextField
                    {...register('min_month')}
                    type="number"
                    id="min-months"
                    inputProps={{
                      min: 1,
                    }}
                    error={Boolean(errors.min_month)}
                    label={t('how_many_months')}
                    variant="standard"
                    data-testid="counter-min-month"
                  />
                </FormControl>
              )}
              <div className="proposal-container">
                <span className="proposal-title">Proposal</span>
              </div>
              <Alert severity="info" icon={false} className="toast-student">
                <span className="description">
                  {`${t('legal_warning')} `}
                  <a
                    href="https://www.minite.works/terms-of-use/terms-of-use-students"
                    className="toast-link"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  .
                </span>
              </Alert>

              <FormControl
                sx={{ width: '100%', marginTop: '32px' }}
                className="question--wrapper"
              >
                <Badge
                  max={3000}
                  badgeContent={proposalCharsCount}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                />
                <TextField
                  {...register('how_you_do_x')}
                  type="text"
                  rows={4}
                  multiline
                  helperText={
                    containsPersonal && 'Do not include contact details'
                  }
                  id="how_you_do_x"
                  error={Boolean(errors.how_you_do_x) || containsPersonal}
                  onChange={handleProposalDescriptionChange}
                  label={jobData?.question_required}
                  variant="standard"
                  data-testid="how-you-do-x"
                />
              </FormControl>

              {jobData?.question_optional && (
                <FormControl
                  sx={{ width: '100%', marginTop: '32px' }}
                  className="question--wrapper"
                >
                  <TextField
                    {...register('how_you_do_y')}
                    type="text"
                    rows={4}
                    multiline
                    id="how_you_do_y"
                    label={jobData?.question_optional}
                    onChange={handleProposalDescriptionChangeY}
                    helperText={
                      containsPersonalY ? 'Do not include contact details' : ''
                    }
                    error={Boolean(errors.how_you_do_y) || containsPersonalY}
                    variant="standard"
                    data-testid="how-you-do-y"
                  />
                </FormControl>
              )}

              <FormGroup sx={{ marginLeft: '14px' }}>
                <Controller
                  name="accept"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      label={
                        jobData?.job_type === JobTypes.ONGOING
                          ? t('i_accept')
                          : t('i_accept_one_time')
                      }
                      {...field}
                      control={
                        <Checkbox
                          id="accept-checkbox"
                          data-testid="accept-offer"
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </form>
          </div>
        </div>
        <div className="student-drawer-footer">
          <div className="drawer-buttons">
            <Button
              className="button proposal-action retract"
              onClick={handleRetractDialogOpen}
              data-testid="retract-proposal"
            >
              {t('retract_proposal')}
            </Button>
            <LoadingButton
              className="button submit-proposal"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || containsPersonal || containsPersonalY}
              buttonText={t('submit_proposal')}
              isLoading={isLoading}
              confirmButtonTestId="submit-proposal"
            />
          </div>
        </div>
      </section>

      <ProposalConfirmationModal
        action="retract"
        open={isRetractDialogOpen}
        handleClose={() => setIsRetractDialogOpen(false)}
        handleProposalAction={handleRetractJob}
      />
    </>
  );
};

export default StudentDrawerContent;
