import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { PROPOSAL_REQUESTS } from './query-keys';

const fetchProposalRequests = async (jobId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/proposal_requests`,
    method: 'get',
  });

  return data.data.proposal_requests;
};

export function useFetchProposalRequests(jobId: string) {
  return useQuery(
    [PROPOSAL_REQUESTS, jobId],
    () => fetchProposalRequests(jobId),
    {
      enabled: !!jobId,
      onError: (e) => console.error(e),
    },
  );
}
