import React from 'react';
import '../AdminGeneralStyles.scss';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

const AdminTasks = () => {
  return (
    <Grid
      container
      className="admin-grid-dashboard admin-tasks-container"
      sx={{
        maxWidth: 'md',
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Tasks</p>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All tasks</p>
          <div className="admin-table-inputs tasks-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-tasks-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                backgroundColor: '#DEB900',
                borderRadius: '4px',
                width: '86px',
                height: '30px',
                cursor: 'pointer',
              }}
              className={`admin-filter-table-button filter-tasks-button`}
              variant="contained"
              data-testid="open-filters"
              endIcon={<FilterListIcon />}
            >
              Filter
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AdminTasks;
