import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { TablePagination } from '@mui/material';
import './styles.scss';

interface Props {
  columns: any;
  rows: any;
  pageSize: number;
  onPageChange: (page: number) => void;
  onSortChange: (sort: { order_by: string; asc: boolean }) => void;
  page: number;
  rowCount: number;
  isLoading: boolean;
  onRowClick?: (data: unknown) => void;
  hideColumn?: { [key: string]: boolean };
  enableExpanding?: boolean;
  handleChangeRowsPerPage?: (per_page: number) => void;
}

const ResizableTable = ({
  columns,
  rows,
  pageSize,
  onPageChange,
  page,
  rowCount,
  isLoading,
  onSortChange,
  onRowClick,
  hideColumn,
  enableExpanding = false,
  handleChangeRowsPerPage,
}: Props) => {
  const [sorting, setSorting] = useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  const handleUseChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleChangeRowsPerPage(parseInt(event.target.value));
  };

  const calculateRowsPerPage = () => {
    if (!pageSize) return 15;
    if ([15, 50, 100, rowCount].includes(pageSize)) {
      return pageSize;
    } else {
      return pageSize - 1;
    }
  };

  useEffect(() => {
    const { id, desc } = sorting[0] || {};

    onSortChange({
      order_by: id,
      asc: !desc,
    });
  }, [sorting]);

  return (
    <div className="resizable-table-wrapper">
      <MaterialReactTable
        columns={columns}
        data={rows || []}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 150,
        }}
        manualSorting
        state={{
          sorting,
          isLoading,
          pagination: { pageIndex: 0, pageSize },
          columnVisibility: hideColumn,
        }}
        initialState={{
          sorting: [
            {
              id: 'created_at',
              desc: true,
            },
          ],
          columnVisibility: hideColumn,
        }}
        onSortingChange={setSorting}
        enableColumnResizing
        enableColumnActions={false}
        enableColumnFilters={false}
        enableBottomToolbar={false}
        enableExpanding={enableExpanding}
        enableTopToolbar={false}
        columnResizeMode="onChange"
        rowCount={rowCount}
        getRowId={(row: { id: string }) => row.id}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => (onRowClick ? onRowClick(row) : null),
          sx: { cursor: 'pointer' },
        })}
      />
      <TablePagination
        component="div"
        className="table-pagination"
        count={rowCount || 0}
        rowsPerPage={calculateRowsPerPage()}
        onRowsPerPageChange={handleUseChangeRowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[15, 50, 100, { label: 'All', value: rowCount }]}
      />
    </div>
  );
};

export default ResizableTable;
