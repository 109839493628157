import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Grid,
  Skeleton,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import { ExperienceForm } from '../../../components/sign-up/components/high-flyer/ExperienceForm';
import { FormSteps } from '../../../components/sign-up/interfaces/steps';
import { EducationForm } from '../../../components/sign-up/components/high-flyer/EducationForm';
import { PersonalForm } from '../../../components/sign-up/components/high-flyer/PersonalForm';
import { SharedStepper } from '../../../shared/stepper/Stepper';
import './Student.scss';
import { useDispatch } from 'react-redux';
import { StudentAllFormAction } from '../../../store/actions/student-form.action';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { ProfileData, useFetchStudentById } from '../../../data/profile';

import { useUserData } from '../../../stores/auth';
import { signUpStudent } from '../../../data/user';
import { sleep } from '../../../utils/helpers';
import { generatePath, useNavigate } from 'react-router-dom';
import { AssessmentType, CallToAction, Routes } from '../../../utils/consts';
import GenericModal from '../../../components/generic-modal/GenericModal';
import { useTranslation } from 'react-i18next';

const tofuCat = [
  '/images/tofu/tofu1.png',
  '/images/tofu/tofu2.png',
  '/images/tofu/tofu3.png',
];

const defaultButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  width: 'auto',
  height: '36px',
  borderRadius: '4px',
  fontFamily: 'Sofia W05 Semi Bold',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: ' 0.4px',
  textTransform: 'uppercase',
  color: '#393A42',
};

const StudentAdminFeeChallenge = () => {
  // TODO: change translation keys, this is not related to assessments at all.
  const { t } = useTranslation('translation', { keyPrefix: 'assessment' });

  const handleRedirectDashboard = async () => {
    window.location.pathname = '/';
  };

  const navigate = useNavigate();

  const handleRouteToAssessment = () => {
    const path = generatePath(Routes.StudentAssessmentView, {
      assessmentCategoryType: AssessmentType.AttentionToDetail,
    });
    navigate(`${path}?callToAction=${CallToAction.BrowseJobs}`);
  };

  return (
    <section className="welcome-section">
      <div className="title-container">
        <p className="title">{t('welcome_aboard')}</p>
        <p>{t('not_verified')}</p>
        <p>{t('do_assessment_verified_description')}</p>
      </div>
      <Button
        onClick={handleRouteToAssessment}
        variant="contained"
        sx={{
          width: '200px',
        }}
        className="welcome-button"
        data-testid="start-assessment"
      >
        Start assessment
      </Button>
      <Button
        sx={{
          width: '200px',
        }}
        variant="contained"
        onClick={handleRedirectDashboard}
        className="welcome-button"
        data-testid="book-call-later"
      >
        I’ll do this later
      </Button>
    </section>
  );
};

const StudentSignupSkeleton = () => {
  const isMobileView = useMediaQuery('(max-width: 901px)');

  if (isMobileView) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          flex: 1,
        }}
      >
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', marginBottom: '15px' }}
            width={'50%'}
          />
          <Skeleton
            sx={{
              marginBottom: '15px',
            }}
            variant="rectangular"
            width={'100%'}
            height={250}
          />
          <Skeleton variant="rectangular" width={'100%'} height={200} />
        </Grid>
      </Grid>
    );
  }
  return (
    <div className="student-form">
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Skeleton variant="rectangular" width={'100%'} height={300} />
        </Grid>
        <Grid item md={3}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', marginBottom: '15px' }}
            width={'50%'}
          />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', marginBottom: '15px' }}
            width={'50%'}
          />

          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', marginBottom: '15px' }}
            width={'50%'}
          />
        </Grid>
        <Grid item md={5}>
          <Skeleton
            sx={{
              marginBottom: '15px',
            }}
            variant="rectangular"
            width={'50%'}
            height={300}
          />
          <Skeleton variant="rectangular" width={'50%'} height={300} />
        </Grid>
      </Grid>
    </div>
  );
};

const Student = () => {
  const dispatch = useDispatch();
  const [errorSnack, showSnack] = useState(null);
  const [steps] = useState(FormSteps.student);
  const [showStudentChallenge, setShowStudentChallenge] = useState(false);
  const user = useUserData((state) => state.user);
  const refetchUser = useUserData((state) => state.refetchUser);
  const [activeStep, setActiveStep] = useState(0);
  const {
    data: student,
    refetch: refetchStudent,
    isLoading: isLoadingStudent,
  } = useFetchStudentById(user?.student_uid);
  const studentInfo = student as unknown as ProfileData;
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [time, setTime] = useState(0);
  const [rageclicks, setRageclicks] = useState(0);
  const [longDeletes, setLongDeletes] = useState(0);

  useDocumentTitle('Sign Up Student');

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    if (studentInfo) {
      const {
        education,
        languages,
        one_liner,
        profile_summary,
        job_interests,
      } = studentInfo;
      /**
       * Check that student has provide education details and
       * languages. If they haven't they should stay on step one
       * They won't be able to proceed without providing at least one for each
       */
      if (!initialized) {
        if (education.length > 0 && languages.length > 0) {
          /**
           * Same applies for step 2. Students can't proceed to step 3 without
           * filling the fields checked below
           * Note: we don't check on step 3 because if student has already provided one
           * job experience they can always edit it later in profile settings
           */
          if (one_liner && profile_summary && job_interests.length > 0) {
            setActiveStep(2);
          } else {
            setActiveStep(1);
          }
        }
        setInitialized(true);
      }
    }
  }, [studentInfo]);

  const handleSubmit = async (data) => {
    let userBody;

    if (data.avatar) {
      userBody = {
        avatar: data.avatar,
      };
    }

    const studentData = {
      hourly_rate: data.rate || undefined,
      one_liner: data.info || undefined,
      profile_summary: data.details || undefined,
      languages:
        data?.languages &&
        data.languages.map((item) => {
          return {
            language_uid: item.language.language_uid,
            level: item.level,
          };
        }),
      education:
        data?.universities &&
        data.universities.map((item) => {
          return {
            university_uid: item.university.id,
            degree: item.degree,
            study: item.study,
            started_at: item.startDate,
            ended_at: item.endDate,
          };
        }),
      profile: data.profile,
      job_interests:
        data.job_interests &&
        data.job_interests.map((item) => {
          return { job_interest_uid: item['id'] };
        }),
      tags: data.tags,
      personal_sentences: data.personal_sentences,
      personality_type: data.personality_type,
      work_experience:
        data.jobs &&
        data.jobs.map((item) => {
          return {
            organization_name: item.organization,
            job_title: item.job,
            job_type: item.type,
            job_description: item.description,
            started_at: item.startDate,
            ended_at: item.endDate,
            skills: item.skills.map((skill) => {
              return {
                skill_uid: skill.id,
              };
            }),
          };
        }),
    };

    const signupData = {
      time_step_1: activeStep === 0 ? time : undefined,
      time_step_2: activeStep === 1 ? time : undefined,
      time_step_3: activeStep === 2 ? time : undefined,
      rage_clicks: rageclicks,
      long_deletes: longDeletes,
    };

    try {
      const data = {
        ...studentData,
        user: userBody,
        signup_data: signupData,
      };

      await signUpStudent(data);

      dispatch(StudentAllFormAction());
      await refetchUser();
      await refetchStudent();
      if (activeStep === 2) {
        // Send to google analytics
        const studentSignUpTracker = document.querySelector(
          `#track-student-completed-signup`,
        ) as HTMLInputElement;

        studentSignUpTracker.click();

        // Send to google adwords
        if (window.location.host === 'app.minite.works') {
          (window as any).gtagReportStudentConversion();
        }

        // allow time to record to google ads and analytics
        await sleep(4000);
        // Show calendly
        setShowStudentChallenge(true);
      } else {
        // Send to google analytics
        const studentSignUpTracker = document.querySelector(
          `#track-student-details-step-${activeStep + 1}`,
        ) as HTMLInputElement;

        studentSignUpTracker.click();
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        showSnack(
          'Oops, looks like a timeout! Please try again later or contact it@minite.works',
        );
      } else {
        showSnack(error?.message);
      }
    }
  };

  const handleNextStep = async (data?: any) => {
    await handleSubmit(data);
    setTime(0);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const getStepComponent = (index: number) => {
    switch (index) {
      case 0:
      default:
        return (
          <EducationForm
            handleFormSubmit={handleNextStep}
            student_uid={user?.student_uid}
          />
        );
      case 1:
        return (
          <PersonalForm
            handleFormSubmit={handleNextStep}
            handleBack={handleBack}
            student_uid={user?.student_uid}
          />
        );
      case 2:
        return (
          <ExperienceForm
            handleFormSubmit={handleSubmit}
            handleBack={handleBack}
          />
        );
    }
  };

  const navigateToOpenJobs = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  // Handle rage clicks
  let lastClick = 0;
  let secondLastClick = 0;
  let totalClicks = 0;

  const handleRageClick = () => {
    const rageThreshold = 6;
    const now = Math.floor(Date.now());
    totalClicks++;
    if (
      now - lastClick < 500 &&
      now - secondLastClick &&
      totalClicks >= rageThreshold
    ) {
      setRageclicks((prev) => prev + 1);
    }
    secondLastClick = lastClick;
    lastClick = now;
    if (totalClicks >= rageThreshold) {
      totalClicks = 0;
    }
  };

  // Handle long deletes
  let totalDeletes = 0;

  function handleLongDelete(event) {
    const longDeleteThreshold = 5;
    const keyCode = event.keyCode || event.charCode;
    if (keyCode == 8 || keyCode == 46) {
      totalDeletes++;
      if (totalDeletes >= longDeleteThreshold) {
        setLongDeletes((prev) => prev + 1);
      }
    } else {
      totalDeletes = 0;
    }
    if (totalDeletes >= longDeleteThreshold) {
      totalDeletes = 0;
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleRageClick, false);
    document.addEventListener('keydown', handleLongDelete, false);
    setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  }, []);

  if (isLoadingStudent) return <StudentSignupSkeleton />;

  return (
    <>
      {/* Start google analytics */}
      <input type="hidden" id="track-student-details-step-1" />
      <input type="hidden" id="track-student-details-step-2" />
      <input type="hidden" id="track-student-completed-signup" />
      {/* End google analytics */}
      {showStudentChallenge ? (
        <StudentAdminFeeChallenge />
      ) : (
        <div>
          <div className="browse-jobs-before-finish">
            <Button
              sx={defaultButtonStyle}
              color="primary"
              variant="contained"
              onClick={() => setModalIsOpen(true)}
            >
              {t('sign_up_student.check_jobs')}
            </Button>
          </div>
          <section className="student-form">
            <div className="tofu-container">
              <img src={tofuCat[activeStep]} alt="cat" />
            </div>
            <SharedStepper steps={steps} activeStep={activeStep} />
            <div>{getStepComponent(activeStep)}</div>
          </section>
        </div>
      )}

      {!!errorSnack && (
        <Snackbar
          onClose={() => showSnack(null)}
          autoHideDuration={7000}
          open={Boolean(errorSnack)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            marginTop: '55px',
          }}
        >
          <Alert onClose={() => showSnack(null)} severity="error">
            {errorSnack}
          </Alert>
        </Snackbar>
      )}
      <GenericModal
        open={modalIsOpen}
        title={t('sign_up_student.modal_title')}
        content={t('sign_up_student.modal_content')}
        cancelButtonText={t('sign_up_student.modal_cancel')}
        confirmButtonText={t('sign_up_student.modal_confirm')}
        handleCancel={navigateToOpenJobs}
        handleConfirm={() => setModalIsOpen(false)}
        confirmButtonTestId="go-check-jobs"
      />
    </>
  );
};

export default Student;
