import { AssessmentQuestionDetails } from '../../data/assessments';

export interface AnswerRepresentationObject {
  value: string;
  label: string;
}
export interface QuestionRepresentationObject {
  assessment_question_uid: string;
  question: string;
  text_1: string;
  text_2: string;
  image?: string;
  answers: Array<AnswerRepresentationObject>;
}

export const getQuestionRespresentationObject = (
  rawQuestion: AssessmentQuestionDetails,
): QuestionRepresentationObject => {
  const { question, assessment_question_uid, text_1, text_2, image, choices } =
    rawQuestion;

  const answers: Array<AnswerRepresentationObject> = choices.map((choice) => ({
    value: choice.letter,
    label: choice.description,
  }));
  return {
    question,
    assessment_question_uid,
    text_1,
    text_2,
    image,
    answers,
  };
};
