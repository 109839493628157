import React, { useEffect, useState } from 'react';
import { TextField } from '@aws-amplify/ui-react';
import { HowFoundCompany, HowFoundStudent } from './interfaces/static-data';
import { HowFoundField } from './HowFoundField';
import { UserTypeField } from './UserTypeField';

export const SignUpType = ({ onSignUpTypeChanged }) => {
  const [howFoundOptions, setHowFoundOptions] = useState(['Referral']);
  const [userType, setUserType] = useState('');
  const storedRefCode = localStorage.getItem('ref_code') || '';
  const [refCode, setRefCode] = useState(storedRefCode);
  const [isError, setIsError] = useState(false);
  const [howFound, setHowFound] = useState(!!refCode ? 'Referral' : '');

  const submitData = () => {
    if (!userType || !howFound) {
      setIsError(true);
    }
  };

  useEffect(() => {
    const submitButton = document.querySelector(
      'form button[type="submit"]',
    ) as HTMLButtonElement;

    submitButton.addEventListener('click', submitData);

    return () => submitButton.removeEventListener('click', submitData);
  }, []);

  const handleUserTypeChanges = (userType) => {
    let _howFoundOptions = null;
    switch (userType) {
      case 'company':
        _howFoundOptions = HowFoundCompany;
        break;
      case 'student':
        _howFoundOptions = HowFoundStudent;
        break;
    }

    setHowFoundOptions(_howFoundOptions);
    setUserType(userType);
    onSignUpTypeChanged(userType);

    localStorage.setItem('user_type', userType);
  };

  const onRefCodeChanged = (event) => {
    const { value } = event.target;
    localStorage.setItem('ref_code', value);
    setRefCode(value);
  };

  return (
    <>
      <UserTypeField
        handleOnChange={handleUserTypeChanges}
        userType={userType}
        isError={isError}
      />
      {(howFoundOptions.length > 1 || howFound === 'Referral') && (
        <HowFoundField
          options={[...howFoundOptions]}
          isError={isError}
          setHowFound={setHowFound}
          howFound={howFound}
        />
      )}
      {howFound === 'Referral' && (
        <TextField
          placeholder="Referral code"
          key="custom:ref_code"
          label="Referral code"
          errorMessage="Invalid ref code"
          isRequired={true}
          name="custom:ref_code"
          data-textid="referral-code"
          value={refCode}
          onChange={onRefCodeChanged}
        />
      )}
    </>
  );
};
