import React from 'react';

const CompanyAvatar = ({
  avatar,
  className,
  alt,
}: {
  avatar: string;
  className?: string;
  alt?: string;
}) => {
  const defaultImage = '/images/default_avatar_company.jpg';

  return (
    <div>
      <img
        className={`job-details-company-logo-img ${className}`}
        src={avatar || defaultImage}
        alt={alt || 'Company Avatar'}
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
      />
    </div>
  );
};

export default CompanyAvatar;
