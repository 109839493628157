import { useQuery } from '@tanstack/react-query';
import { request, ServerResponse } from '../core/request';
import { HIGHLIGHTS } from './query-keys';
import { HighlightedProfiles } from '../components/highlighted-profiles/HighlightedProfiles';

const fetchHighlightedProfiles = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/highlighted_profiles',
    method: 'get',
  });

  return data;
};

export const useFetchHighlightedProfiles = (byCompany: boolean) =>
  useQuery([HIGHLIGHTS], () => fetchHighlightedProfiles(), {
    enabled: byCompany,
    staleTime: 3600,
    select: (response) => {
      return response.data as unknown as HighlightedProfiles[];
    },
    onError: (e) => console.error(e),
  });
