export type PersonalityType = {
  id: string;
  label: string;
  color: string;
};

export const PersonalityTypeValues: PersonalityType[] = [
  { id: 'analyst', label: 'Analyst', color: 'disabled' },
  { id: 'architect', label: 'INTJ / Architect', color: '#886199' },
  { id: 'logician', label: 'INTP / Logician', color: '#886199' },
  { id: 'commander', label: 'ENTJ / Commander', color: '#886199' },
  { id: 'debater', label: 'ENTP / Debater', color: '#886199' },
  { id: 'diplomat', label: 'Diplomat', color: 'disabled' },
  { id: 'advocate', label: 'INFJ / Advocate', color: '#33a474' },
  { id: 'mediator', label: 'INFP / Mediator', color: '#33a474' },
  { id: 'protagonist', label: 'ENFJ / Protagonist', color: '#33a474' },
  { id: 'campaigner', label: 'ENFP / Campaigner', color: '#33a474' },
  { id: 'sentinel', label: 'Sentinel', color: 'disabled' },
  { id: 'logistician', label: 'ISTJ / Logistician', color: '#4298b4' },
  { id: 'defender', label: 'ISFJ / Defender', color: '#4298b4' },
  { id: 'executive', label: 'ESTJ / Executive', color: '#4298b4' },
  { id: 'consul', label: 'ESFJ / Consul', color: '#4298b4' },
  { id: 'explorer', label: 'Explorer', color: 'disabled' },
  { id: 'virtuoso', label: 'ISTP / Virtuoso', color: '#e4ae3a' },
  { id: 'adventurer', label: 'ISFP / Adventurer', color: '#e4ae3a' },
  { id: 'entrepreneur', label: 'ESTP / Entrepreneur', color: '#e4ae3a' },
  { id: 'entertainer', label: 'ESFP / Entertainer', color: '#e4ae3a' },
];

export function getLabelById(id: string): PersonalityType | undefined {
  const item = PersonalityTypeValues.find((type) => type.id === id);
  return item ? item : undefined;
}
