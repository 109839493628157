import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { passwordCheck } from './passwordRequirements';

export const PasswordRequirements = () => {
  const { t } = useTranslation();
  const [passwords, setPasswords] = useState({
    password: null,
    confirm_password: '',
  });

  const passwordOnChange = (e) => {
    const { value, name } = e.target;

    setPasswords((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const passwordRequirements = document.querySelector(
      '.password-requirements',
    );

    if (passwords.password && passwordRequirements) {
      passwordRequirements.innerHTML = passwordCheck(passwords, t);
    }
  }, [passwords.password, passwords.confirm_password]);

  useEffect(() => {
    const password = document.querySelector('[name="password"]');
    const confirm_password = document.querySelector(
      '[name="confirm_password"]',
    );
    const confirmPasswordWrapper = document.querySelectorAll(
      '.amplify-passwordfield',
    );
    const requirementsCheck = document.querySelector('.password-requirements');

    const requirements = document.createElement('div');
    requirements.className = 'password-requirements';

    password?.addEventListener('keyup', passwordOnChange);
    confirm_password?.addEventListener('keyup', passwordOnChange);

    if (confirmPasswordWrapper.length && !requirementsCheck) {
      confirmPasswordWrapper[confirmPasswordWrapper.length - 1].append(
        requirements,
      );
    }

    return () => {
      password?.removeEventListener('keyup', passwordOnChange);
      confirm_password?.removeEventListener('keyup', passwordOnChange);
    };
  }, []);

  return passwords.password !== null && <></>;
};
