import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ADMIN_COMPANIES,
  ADMIN_COMPANY_OVERVIEW,
  ADMIN_COMPANY_OVERVIEW_JOBS,
  ADMIN_COMPANY_OVERVIEW_PROPOSALS,
  ADMIN_COMPANY_OVERVIEW_STUDENTS,
  COMPANY_STATISTICS,
} from './query-keys';
import { AxiosError } from 'axios';
import { ProposalStatus } from './jobs';

export const searchAdminCompanies = async (body?: any) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/companies/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminCompanies = (body: any) => {
  return useQuery([ADMIN_COMPANIES, body], () => searchAdminCompanies(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const jobStatistic = async () => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/statistics/companies`,
    method: 'get',
  });

  return data.data;
};

export const useCompanyStatistic = () => {
  return useQuery([COMPANY_STATISTICS], () => jobStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const adminCompanyOverview = async (id: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/companies/${id}`,
    method: 'get',
  });

  return data.data as AdminCompanyOverview;
};

export const useAdminCompanyOverview = (id: string) => {
  return useQuery(
    [ADMIN_COMPANY_OVERVIEW, id],
    () => adminCompanyOverview(id),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
      enabled: !!id,
    },
  );
};

const adminCompanyOverviewJobs = async (id: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/companies/${id}/jobs`,
    method: 'get',
  });

  return data.data as AdminCompanyJobs;
};

export const useAdminCompanyOverviewJobs = (id: string) => {
  return useQuery(
    [ADMIN_COMPANY_OVERVIEW_JOBS, id],
    () => adminCompanyOverviewJobs(id),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
      enabled: !!id,
    },
  );
};

const adminCompanyOverviewProposals = async (id: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/companies/${id}/proposals`,
    method: 'get',
  });

  return data.data as AdminCompanyProposals;
};

export const useAdminCompanyOverviewProposals = (id: string) => {
  return useQuery(
    [ADMIN_COMPANY_OVERVIEW_PROPOSALS, id],
    () => adminCompanyOverviewProposals(id),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
      enabled: !!id,
    },
  );
};

const adminCompanyOverviewStudents = async (id: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/companies/${id}/students`,
    method: 'get',
  });

  return data.data as AdminCompanyStudents;
};

export const useAdminCompanyOverviewStudents = (id: string) => {
  return useQuery(
    [ADMIN_COMPANY_OVERVIEW_STUDENTS, id],
    () => adminCompanyOverviewStudents(id),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
      enabled: !!id,
    },
  );
};

const updateCompanies = async (companyUid: string, body: CompanyUpdate) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/companies/${companyUid}`,
    method: 'put',
    data: body,
  });

  if (!data.success)
    throw new Error('company approve failed, code: ' + data.code);
};

export function useUpdateCompanies(companyUid: string) {
  return useMutation(
    (payload: CompanyUpdate) => updateCompanies(companyUid, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

interface CompanyUpdate {
  company: {
    service_fee: number;
    direct_job_fee: number;
    direct_job_remaining_credits: number;
  };
}

interface Search {
  companies: any[];
  pagination: { total: number; page: number; per_page: number };
}

export interface AdminCompanyOverview {
  bio: string;
  company_name: string;
  company_uid: string;
  created_at: Date;
  jobs_in_progress_count: number;
  students_in_progress_count: number;
  estimated_monthly_revenue: number;
  user: AdminUserInfo;
  website: string;
  service_fee: number;
  direct_job_fee: number;
  direct_job_remaining_credits: number;
}

export enum CognitoUserProvider {
  Email = 'email',
  Google = 'google',
  Unknown = 'unknown',
}

export interface AdminUserInfo {
  user_uid: string;
  avatar: string;
  first_name: string;
  how_did_you_find_minite: string;
  last_name: string;
  phone: number;
  email: string;
  verified: boolean;
  city: string;
  country: string;
  house_number: string;
  iban: string;
  invoice_email: string;
  kvk_number: string;
  onboarding_completed_at: Date;
  other_how_did_you_find_minite: string;
  postal_code: string;
  street_name: string;
  vat_number: string;
  deactivate_reason: string | null;
  deactivated_at: string | null;
  admin?: boolean;
  provider?: CognitoUserProvider;
}

export interface AdminCompanyJobs {
  jobs: AdminCompanyJob[];
}

export interface AdminCompanyJob {
  budget: number;
  created_at: Date;
  hours_estimated: number;
  job_end_at: Date;
  job_start_at: Date;
  job_status: string;
  job_title: string;
  job_type: string;
  job_uid: string;
  proposals_count: number;
  students_applied: number;
  students_needed: number;
  matched_at: Date;
  completed_at: Date;
  cancelled_at: Date;
  student_uid: string;
  student_name: string;
}

export interface AdminCompanyProposals {
  proposals: AdminCompanyProposal[];
}

export interface AdminCompanyProposal {
  proposal_uid: string;
  created_at: string;
  proposal_status: ProposalStatus;
  student_uid: string;
  student: StudentUserResponse;
  job: JobResponse;
  rate: number;
}

export interface AdminCompanyStudents {
  students: AdminCompanyStudent[];
}

export interface AdminCompanyStudent {
  proposal_uid: string;
  rate: number;
  hourly_rate: number;
  student: StudentUserResponse;
  job: JobResponse;
  universities: AdminUniversity[];
  education: AdminEducation[];
}

export interface StudentUserResponse {
  student_uid: string;
  first_name: string;
  last_name: string;
  student_type: string;
}

export interface JobResponse {
  job_uid: string;
  job_title: string;
}

export interface AdminUniversity {
  university_name: string;
  university_uid: string;
}

export interface AdminEducation {
  degree: string;
  ended_at: Date;
  study: string;
  university_uid: string;
}
