import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './CompanyWelcomeModal.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import MiniteSnackbar from '../snackbar';
import { UserData, userRequestNewSingupCallback } from '../../data/user';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../stores/auth';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CompanyWelcomeModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenToast, setIsOpenToast] = useState(false);
  const { mutateAsync: requestNewSingupCallback } =
    userRequestNewSingupCallback();
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;

  const handleRequestCallback = () => {
    const body = {
      companyName: user?.company?.company_name || '?',
      contactName: `${user?.first_name} ${user?.last_name}` || '?',
      phoneNumber: user?.phone.toString() || '?',
    };
    requestNewSingupCallback(body).then(() => {
      onClose();
      setIsOpenToast(true);
    });
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="content-title">
          {t('dashboard.welcome.title')}
          {!!onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="content-text">
            {t('dashboard.welcome.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="content-buttons">
          <Button
            onClick={() => {
              navigate(Routes.JobPostRoute);
              onClose();
            }}
            sx={{ fontSize: '12px' }}
          >
            {t('dashboard.welcome.button_freelance')}
          </Button>
          <Button
            onClick={() => {
              navigate(Routes.JobDirectPostRoute);
              onClose();
            }}
            sx={{ fontSize: '12px' }}
          >
            {t('dashboard.welcome.button_fulltime')}
          </Button>
          <Button onClick={handleRequestCallback} sx={{ fontSize: '12px' }}>
            {t('dashboard.welcome.button_callback')}
          </Button>
        </DialogActions>
      </Dialog>

      <MiniteSnackbar
        severity={'success'}
        message={t('dashboard.welcome.toast_message')}
        title={t('dashboard.welcome.done')}
        autoHideDuration={4000}
        open={isOpenToast}
        onClose={() => setIsOpenToast(false)}
      />
    </>
  );
};

export default CompanyWelcomeModal;
