import React, { useEffect, useState } from 'react';
import { Button, Divider } from '@mui/material';
import { Navigation } from './Navigation';
import { UserType } from './UserType';
import './SidePanel.scss';
import { COMPANY_AVATAR_BUCKET, Routes } from '../../utils/consts';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import CompanyAvatar from '../company-avatar';
import { fetchMyJobs, JobStatuses, ProposalStatuses } from '../../data/jobs';
import { fetchStudentProposals } from '../../data/proposals';
import StudentAvatar from '../student-avatar';
import { useUserData } from '../../stores/auth';

interface Props {
  id: string;
  userType: UserType;
  toggleDrawer?: () => void;
}

export const SidePanel = ({ id, userType, toggleDrawer }: Props) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const path = location.pathname.split('/');
  const isInfo = path[path.length - 1] === 'info';
  const [isOnboarding, setIsOnboarding] = useState(false);

  const user = useUserData((state) => state.user);

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!user?.avatar) {
      setAvatar(null);
    } else {
      if (user?.type === UserType.Student) {
        setAvatar(user?.avatar);
      } else if (user?.type === UserType.Company) {
        setAvatar(COMPANY_AVATAR_BUCKET + `${user?.avatar}`);
      }
    }

    if (user?.type === UserType.Student && user?.student_uid) {
      fetchStudentProposals().then((res) => {
        setIsOnboarding(
          res.some(
            ({ proposal_status }) =>
              proposal_status === ProposalStatuses.Signed,
          ),
        );
      });
    } else if (user?.type === UserType.Company && user?.company_uid) {
      const notShow = {
        [JobStatuses.MATCHED]: JobStatuses.MATCHED,
        [JobStatuses.SIGNED]: JobStatuses.SIGNED,
        [JobStatuses.COMPLETED]: JobStatuses.COMPLETED,
        [JobStatuses.IN_PROGRESS]: JobStatuses.IN_PROGRESS,
      };
      fetchMyJobs().then((res) => {
        setIsOnboarding(res.some(({ job_status }) => notShow[job_status]));
      });
    }
  }, [user]);

  if (
    userType !== UserType.Student &&
    userType !== UserType.Company &&
    userType !== UserType.Admin
  ) {
    return null;
  }

  const handleToggleDrawer = () => {
    if (isMobile) {
      toggleDrawer();
    }
  };

  return (
    !isInfo && (
      <div className="sidepanel" id={id}>
        <div className="sidepanel-top">
          {user?.type !== UserType.Admin &&
            (user?.type === UserType.Company ? (
              <CompanyAvatar avatar={avatar} />
            ) : (
              <StudentAvatar
                avatar={avatar}
                alt="Profile Picture"
                className="sidepanel-top-avatar"
              />
            ))}
          <h2 className="sidepanel-top-name">
            {user?.type === UserType.Admin ? 'Admin' : user?.first_name}
          </h2>
        </div>
        <Divider />
        <div className="sidepanel-bottom">
          <Navigation
            userType={userType}
            user={user}
            isSuperAdmin={user?.manager.super_admin}
            onboarding={isOnboarding}
            toggleDrawer={handleToggleDrawer}
          />
        </div>
      </div>
    )
  );
};

export const PublicSidePanel = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });

  return (
    <div className="sidepanel public-sidepanel">
      <div className="public-sidepanel-logo-container">
        <img
          src="/images/minite-logo.png"
          alt="Minite Logo"
          className="public-sidepanel-top-avatar"
        />
      </div>
      <div className="public-sidepanel-routes-container">
        <ul>
          <li>
            <a href="https://www.minite.works/" target="_blank">
              {t('for_companies')}
            </a>
          </li>
          <li>
            <a href="https://www.minite.works/students" target="_blank">
              {t('for_students')}
            </a>
          </li>
          <li>
            <a href="https://www.minite.works/about" target="_blank">
              {t('about_minite')}
            </a>
          </li>
        </ul>
      </div>
      <div className="public-sidepanel-bottom">
        <Button
          className="sidepanel-button yellow"
          variant="text"
          href={Routes.DashboardRoute}
        >
          {t('log_in')}
        </Button>

        <Button
          className={`sidepanel-button`}
          variant="contained"
          href={Routes.DashboardRoute}
        >
          {t('sign_up')}
        </Button>
      </div>
    </div>
  );
};
