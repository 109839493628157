import React, { useEffect, useMemo, useState } from 'react';
import './JobOverview.scss';
import { useFetchJob } from '../../../../data/admin-job-details';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { HiOutlineReceiptRefund } from 'react-icons/hi2';
import { LoadingButton } from '../../../../components/LoadingButton';
import { JobStatuses, JobTypes } from '../../../../data/jobs';
import MiniteSnackbar from '../../../../components/snackbar';
import { useCreatOtjCreditInvoice } from '../../../../data/admin';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Routes } from '../../../../utils/consts';
import { formatDate } from '../../../../utils/dates';
import ResizableTable from '../../../../components/resizable-table';

const JobFinances = () => {
  const { id: jobId } = useParams<{ id?: string }>();
  const { data: job } = useFetchJob(jobId);

  const { mutateAsync: creatOtjCreditInvoice } = useCreatOtjCreditInvoice();

  const [createCreditInvoiceLoading, setCreateCreditInvoiceLoading] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [invoicingSuccessMessage, setInvoicingSuccessMessage] = useState(null);
  const [wasCreditedBefore, setWasCreditedBefore] = useState(false);

  useEffect(() => {
    job?.invoices?.some((invoice) => invoice.type === 'credit')
      ? setWasCreditedBefore(true)
      : setWasCreditedBefore(false);
  }, []);

  const handleCreateOtjCreditInvoice = async () => {
    try {
      if (!jobId) {
        return;
      }
      setCreateCreditInvoiceLoading(true);
      await creatOtjCreditInvoice({ job_uid: jobId });
      setInvoicingSuccessMessage(
        'Job to create credit invoice for this job has been submitted. You will receive the invoice in max. 5 minutes.',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setCreateCreditInvoiceLoading(false);
    }
  };

  const handleServiceFeeRefund = async () => {
    try {
      if (!jobId) {
        return;
      }
      const baseLink =
        'https://my.mollie.com/dashboard/' +
        process.env.REACT_APP_MOLLI_ORG_ID +
        '/payments/';
      const paymentRef = job?.payment?.mollie_payment_id;
      const completeLink = baseLink + paymentRef;
      const newTab = window.open(completeLink, '_blank');
      newTab.focus();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    }
  };

  /* ----------------------------------- OTJ ---------------------------------- */

  const otjInvoicesGridData = (): unknown[] =>
    job?.invoices
      ?.filter(
        (invoice) =>
          invoice.invoice_number && invoice.invoice_number[0] !== 'S',
      )
      .map((invoice) => {
        return {
          id: invoice.invoice_uid,
          invoice_number: invoice.invoice_number,
          created_at: formatDate(invoice.created_at, 'DD/MM/YYYY'),
          paid_status: invoice.invoice_paid,
          invoice_type: invoice.type,
        };
      });

  const otjColumns = useMemo(() => {
    return [
      {
        accessorKey: 'invoice_number',
        header: 'Invoice number',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminInvoicesRoute}/${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
      },
      {
        accessorKey: 'paid_status',
        header: 'Paid status',
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'invoice_type',
        header: 'Type',
      },
    ];
  }, []);

  /* ------------------------------- Ongoing job ------------------------------ */

  const ongoingInvoicesGridData = (): unknown[] => {
    const onetimeInvoices = job?.invoices
      ?.filter((invoice) => invoice.invoice_number !== null)
      .map((invoice) => {
        return {
          id: invoice.invoice_uid,
          invoice_number: invoice.invoice_number,
          created_at: formatDate(invoice.created_at, 'DD/MM/YYYY'),
          paid_status: invoice.invoice_paid,
          invoice_type: invoice.type,
          student_uid: null,
        };
      });
    const timesheetInvoices = job?.timesheets
      ?.filter((t) => t.invoice_sent)
      .map((t) => {
        return {
          id: t.timesheet_uid,
          invoice_number: t.admin_fee_invoice_number,
          created_at: formatDate(t.created_at, 'DD/MM/YYYY'),
          paid_status: t.invoice_paid,
          student_uid: t.student_uid,
        };
      });
    if (onetimeInvoices && timesheetInvoices) {
      return timesheetInvoices.concat(onetimeInvoices);
    } else if (onetimeInvoices) {
      return onetimeInvoices;
    } else {
      return timesheetInvoices;
    }
  };

  const ongoingColumns = useMemo(() => {
    return [
      {
        accessorKey: 'invoice_number',
        header: 'Invoice number',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.invoice_number &&
            row.original.invoice_number[0] === 'B' ? (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`/hours/${row.original.student_uid}/${job?.job_uid}?timesheet_uid=${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          ) : (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminInvoicesRoute}/${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
      },
      {
        accessorKey: 'paid_status',
        header: 'Paid status',
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'invoice_type',
        header: 'Type',
      },
    ];
  }, []);

  /* ------------------------------- Direct job ------------------------------- */

  const directInvoicesGridData = (): unknown[] =>
    job?.invoices
      ?.filter((invoice) => invoice.invoice_number !== null)
      .map((invoice) => {
        return {
          id: invoice.invoice_uid,
          invoice_number: invoice.invoice_number,
          created_at: formatDate(invoice.created_at, 'DD/MM/YYYY'),
          paid_status: invoice.invoice_paid,
          invoice_type: invoice.type,
        };
      });

  const directColumns = useMemo(() => {
    return [
      {
        accessorKey: 'invoice_number',
        header: 'Invoice number',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminInvoicesRoute}/${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
      },
      {
        accessorKey: 'paid_status',
        header: 'Paid status',
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'invoice_type',
        header: 'Type',
      },
    ];
  }, []);

  return (
    <>
      {job?.job_type !== JobTypes.ONGOING && (
        <Grid container>
          <Grid item xs={8}>
            <div className="job-finance-container">
              <LoadingButton
                isDisabled={
                  job?.job_status !== JobStatuses.CANCELLED || wasCreditedBefore
                }
                isLoading={createCreditInvoiceLoading}
                className="refund-button"
                onClick={handleCreateOtjCreditInvoice}
                buttonText={'Create credit invoice'}
                confirmButtonTestId="save-job-details-admin"
                type="button"
                startIcon={<HiOutlineReceiptRefund />}
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="job-finance-container">
              <LoadingButton
                isDisabled={
                  job?.job_status !== JobStatuses.CANCELLED || wasCreditedBefore
                }
                isLoading={false}
                className="refund-service-fee-button"
                onClick={handleServiceFeeRefund}
                buttonText={'Refund service fee in Mollie'}
                confirmButtonTestId="save-job-details-admin"
                type="button"
                startIcon={<HiOutlineReceiptRefund />}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <h1>Invoices associated with this job</h1>

        {/* One-time jobs */}
        {job?.job_type === JobTypes.ONE_TIME && (
          <>
            <ResizableTable
              pageSize={job?.invoices?.length}
              columns={otjColumns}
              rows={otjInvoicesGridData()}
              page={1}
              onSortChange={() => true}
              onPageChange={() => true}
              rowCount={job?.invoices?.length}
              isLoading={false}
            />
          </>
        )}
        {/* Ongoing jobs */}
        {job?.job_type === JobTypes.ONGOING && !job?.direct_job && (
          <>
            <ResizableTable
              pageSize={ongoingInvoicesGridData().length}
              columns={ongoingColumns}
              rows={ongoingInvoicesGridData()}
              page={1}
              onSortChange={() => true}
              onPageChange={() => true}
              rowCount={ongoingInvoicesGridData().length}
              isLoading={false}
            />
          </>
        )}
        {/* Direct jobs */}
        {job?.job_type === JobTypes.ONGOING && job?.direct_job && (
          <>
            <ResizableTable
              pageSize={directInvoicesGridData().length}
              columns={directColumns}
              rows={directInvoicesGridData()}
              page={1}
              onSortChange={() => true}
              onPageChange={() => true}
              rowCount={directInvoicesGridData().length}
              isLoading={false}
            />
          </>
        )}
      </Grid>
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
      <MiniteSnackbar
        message={invoicingSuccessMessage}
        title={'Message'}
        open={!!invoicingSuccessMessage}
        severity="success"
        onClose={() => setInvoicingSuccessMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};

export default JobFinances;
