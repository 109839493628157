import { useMutation, useQuery } from '@tanstack/react-query';
import camelCaseKeys from 'camelcase-keys';
import { request, ServerResponse } from '../core/request';
import { JobsSearchRequest } from '../pages/browsejobs';
import { KeysToCamelCase } from '../utils/type';
import {
  CREATE_JOB_KEY,
  HIRED,
  JOB,
  JOBS_RECOMMENDED,
  MY_JOBS,
  PROPOSALS,
  STUDENT_FAVOURITES,
  STUDENT_JOBS,
} from './query-keys';
import { AxiosError } from 'axios';

export const PREMIUM_BOOST_PRICE = 50;

// Requests

const fetchJobById = async (jobId: string) => {
  const { data } = await request<ServerResponse<JobByIdResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}`,
    method: 'get',
  });

  return data.data.job;
};

const fetchJobProposals = async (jobId: string) => {
  const { data } = await request<ServerResponse<JobProposalsResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/proposals`,
    method: 'get',
  });

  return data.data.proposals;
};

const fetchJobHired = async (jobId: string) => {
  const { data } = await request<ServerResponse<JobProposalsResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/hired`,
    method: 'get',
  });

  return data.data.proposals;
};

const searchJobs = async (params) => {
  const { data } = await request<ServerResponse<JobsListResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/jobs/search',
    method: 'post',
    data: {
      ...params,
    },
  });

  return data.data;
};

export const fetchMyJobs = async () => {
  const { data } = await request<ServerResponse<MyJobsResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs`,
    method: 'get',
  });

  return data.data?.jobs || [];
};

const fetchRecommendedJobs = async () => {
  const { data } = await request<ServerResponse<JobsRecommended>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/recommended`,
    method: 'get',
  });

  return data.data.jobs;
};

export const getPaymentStatus = async (
  jobUid: string,
  payment_type: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/payment_status/${payment_type}`,
    method: 'get',
  });

  return data.data as string;
};

export const createJobRequest = async (body: {
  job: any;
}): Promise<JobResponse> => {
  const {
    data: { data },
  } = await request<ServerResponse<JobResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/jobs',
    method: 'post',
    data: body,
  });

  return { job: data as unknown as CreateJob };
};

export const getDraftJobRequest = async (): Promise<JobResponse> => {
  const {
    data: { data },
  } = await request<ServerResponse<JobResponse>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/jobs/draft',
    method: 'get',
  });

  return { job: data as unknown as CreateJob };
};

export const useCreateJobRequest = () => {
  return useMutation((payload: any) => createJobRequest(payload), {
    onError: (e) => console.error(e),
  });
};

export const duplicateJobRequest = async (
  jobUid: string,
  body: DuplicateJobData,
): Promise<DuplicateJobResponse> => {
  const {
    data: { data },
  } = await request<ServerResponse<DuplicateJobResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/duplicate`,
    method: 'post',
    data: body,
  });

  return data;
};

export const useDuplicateJobRequest = () => {
  return useMutation(
    (payload: { jobUid: string; body: DuplicateJobData }) =>
      duplicateJobRequest(payload.jobUid, payload.body),
    {
      onError: (e) => console.error(e),
    },
  );
};

export const payJob = async (jobId: string, body) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/pay`,
    method: 'post',
    data: body,
  });

  return data;
};

export const usePayJob = () => {
  return useMutation(
    (payload: { jobUid: string; body: Payment }) =>
      payJob(payload.jobUid, payload.body),
    {
      onError: (e) => console.error(e),
    },
  );
};

export const submitJob = async (jobId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/submit`,
    method: 'post',
  });

  return data;
};

export const useSubmitJob = () => {
  return useMutation(
    (payload: { jobUid: string }) => submitJob(payload.jobUid),
    {
      onError: (e) => console.error(e),
    },
  );
};

export const fetchJobForEditRequest = async (
  jobId: string,
): Promise<JobResponse> => {
  const {
    data: { data },
  } = await request<ServerResponse<JobResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/edit`,
    method: 'get',
  });

  return data;
};

export const updateJobRequest = async (
  job_uid: string,
  body: UpdateJobI,
): Promise<JobResponse> => {
  const {
    data: { data },
  } = await request<ServerResponse<JobResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${job_uid}`,
    method: 'put',
    data: body,
  });

  return data;
};

export const useUpdateJobRequest = () => {
  return useMutation(
    (payload: { job_uid: string; body: UpdateJobI }) =>
      updateJobRequest(payload.job_uid, payload.body),
    {
      onError: (e) => console.error(e),
    },
  );
};

const requestCallbackJob = async (jobUid: string, inTwoHours: boolean) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/request_callback`,
    method: 'post',
    data: { inTwoHours: inTwoHours },
  });

  if (!data.success)
    throw new Error('requestCallbackJob failed, code: ' + data.code);
};

const requestProposalJob = async (jobUid: string, studentUid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/request_proposal/${studentUid}`,
    method: 'post',
  });

  if (!data.success)
    throw new Error('requestProposalJob failed, code: ' + data.code);
};

const cancelJob = async (jobUid: string, reason?: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/cancel`,
    method: 'post',
    data: {
      reason,
    },
  });

  if (!data.success) throw new Error('cancelJob failed, code: ' + data.code);
};

const completeJob = async (
  jobUid: string,
  reason?: string,
  endDate?: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/complete`,
    method: 'post',
    data: {
      reason,
      endDate,
    },
  });

  if (!data.success) throw new Error('completeJob failed, code: ' + data.code);
};

const applyForJob = async (jobUid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + ` /api/v1/jobs/${jobUid}/proposals`,
    method: 'post',
  });

  if (!data.success) throw new Error('applyForJob failed, code: ' + data.code);
};

// Hooks

export const useFetchJobById = (jobUid: string) =>
  useQuery([JOB, jobUid], () => fetchJobById(jobUid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
    enabled: !!jobUid,
  });

export const useFetchJobProposals = (jobUid: string) =>
  useQuery([PROPOSALS, jobUid], () => fetchJobProposals(jobUid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
    enabled: !!jobUid,
  });

export const useFetchJobHired = (jobUid: string) =>
  useQuery([HIRED, jobUid], () => fetchJobHired(jobUid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
    enabled: !!jobUid,
  });

export const useFetchMyJobs = () =>
  useQuery([MY_JOBS], () => fetchMyJobs(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });

export const useFetchRecommendedJobs = (byStudent = false) =>
  useQuery([JOBS_RECOMMENDED], () => fetchRecommendedJobs(), {
    enabled: byStudent,
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });

export const useCreateJob = (body: { job: any }) => {
  return useQuery(
    [CREATE_JOB_KEY],
    () => {
      return createJobRequest(body);
    },
    {
      select: (data) => {
        return data;
      },
      onError: (e: AxiosError) => e,
    },
  );
};

export function useSearchJobs(params: JobsSearchRequest) {
  return useQuery([STUDENT_JOBS, params], () => searchJobs(params), {
    enabled: !!params,
    cacheTime: 0,
    select: (data) => {
      return camelCaseKeys(data, { deep: true }) as unknown as JobsSearchData;
    },
    onError: (e: AxiosError) => e,
  });
}

export function useRequestProposal(studentUid: string) {
  return useMutation(
    (jobUid: string) => requestProposalJob(jobUid, studentUid),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

export function useRequestCallback() {
  return useMutation(
    (payload: { jobUid: string; inTwoHours: boolean }) =>
      requestCallbackJob(payload.jobUid, payload.inTwoHours),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

export function useCancelJobByUid() {
  return useMutation(
    (payload: { jobUid: string; reason?: string }) =>
      cancelJob(payload.jobUid, payload.reason),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

export function useCompleteJob() {
  return useMutation(
    (payload: { jobUid: string; reason?: string; endDate?: string }) =>
      completeJob(payload.jobUid, payload.reason, payload.endDate),
    {
      onError: (e: AxiosError) => e,
    },
  );
}

export function useApplyForJob() {
  return useMutation((jobUid: string) => applyForJob(jobUid), {
    onError: (e: AxiosError) => e,
  });
}

const addToFavourites = async (jobId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/favorites/${jobId}`,
    method: 'post',
  });
  return data;
};

export function useAddToFavorites() {
  return useMutation((jobId: string) => addToFavourites(jobId), {
    onError: (e: AxiosError) => e,
  });
}

const removeFromFavourites = async (favId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/favorites/${favId}`,
    method: 'delete',
  });
  return data;
};

export function useRemoveFromFavorites() {
  return useMutation((favId: string) => removeFromFavourites(favId), {
    onError: (e: AxiosError) => e,
  });
}

const fetchFavourites = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/favorites`,
    method: 'get',
  });
  return data;
};

export function useFetchFavorites() {
  return useQuery([STUDENT_FAVOURITES], () => fetchFavourites(), {
    select: (data) => {
      return data.data as Favorite[];
    },
    onError: (e: AxiosError) => e,
  });
}

const creatOtjInvoice = async (job_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${job_uid}/create-otj-invoice`,
    method: 'POST',
  });

  return data;
};

export function useCreatOtjInvoice() {
  return useMutation(
    (payload: { job_uid: string }) => creatOtjInvoice(payload.job_uid),
    {
      onError: (e) => console.error(e),
    },
  );
}

export interface Favorite {
  favorite_uid: string;
  student_uid: string;
  job_uid: string;
}

export interface JobsListResponse {
  jobs: RawJobsListData[];
  pagination: {
    page: number;
    per_page: number;
    total: number;
  };
}

export interface MyJobsResponse {
  jobs: MyJobsListItem[];
}

export interface JobRecommended {
  job_uid: string;
  job_title: string;
  job_type: JobType;
  job_status: JobStatus;
  company: {
    company_uid: string;
    company_name: string;
    avatar: string;
    rating: number;
  };
}

export interface JobsRecommended {
  jobs: JobRecommended[];
}

export const JobTypes = {
  ONE_TIME: 'one-time',
  PREMIUM: 'one-time-premium',
  ONGOING: 'ongoing',
} as const;

export const JobTypesFullName = {
  'one-time': 'One time job standard',
  'one-time-premium': 'One time job premium',
  ongoing: 'Ongoing job',
};

export type JobType = typeof JobTypes[keyof typeof JobTypes];

export const JobStatuses = {
  DRAFT: 'draft',
  PENDING_APPROVAL: 'pending-approval',
  OPEN: 'open',
  INTERVIEWING: 'interviewing',
  CANCELLED: 'cancelled',
  MATCHED: 'matched',
  SIGNED: 'signed',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
} as const;

export type JobStatus = typeof JobStatuses[keyof typeof JobStatuses];

export const JobLocations = {
  REMOTE: 'remote',
  OFFICE: 'office',
  HYBRID: 'hybrid',
} as const;

export type JobLocation = typeof JobLocations[keyof typeof JobLocations];

export const StudentTypes = {
  EntryLevel: 'entry-level',
  Regular: 'regular',
} as const;

export enum DegreeType {
  BA = 'BA',
  Bachelor = 'Bachelor',
  BSc = 'BSc',
  LLB = 'LLB',
  PreMaster = 'Pre-Master',
  MA = 'Ma',
  MSc = 'MSc',
  LLM = 'LLM',
  MBA = 'MBA',
  PhD = 'PhD',
}

export enum UserActivity {
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export type StudentType = typeof StudentTypes[keyof typeof StudentTypes];

export const ProposalStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  Retracted: 'retracted',
  Interviewing: 'interviewing',
  Rejected: 'rejected',
  Accepted: 'accepted',
  Signed: 'signed',
} as const;

export type ProposalStatus =
  typeof ProposalStatuses[keyof typeof ProposalStatuses];

export const CompletionReasons = {
  Circumvention: 'Circumvention',
  Bankrupt: 'Company closing or bankrupt',
  Business: 'Business doing badly',
  Budget: 'No budget',
  Fte: 'Replaced by FTE',
  Customer: "Bad customer/doesn't pay",
  Student: 'Bad student',
  Converted: 'Student converted',
  Retracted: 'Student retracted',
  Temporary: 'Temporary project',
  Replace: 'Replace with new student',
};

export interface JobData {
  job_uid: string;
  job_title: string;
  open_at: string;
  draft_at: string;
  job_type: JobType;
  student_type: StudentType;
  students_needed: number;
  students_applied: string;
  proposals_count: number;
  question_optional?: string;
  question_required: string;
  job_location: JobLocation;
  office_location?: string;
  job_status: JobStatus;
  budget: string;
  hours_estimated: number;
  interest_uid: string;
  job_description: string;
  parent_job_uid: string | null;
  company: {
    company_uid: string;
    company_name: string;
    rating: number;
    avatar: string;
    interest_uid: string;
    bio: string;
    website: string;
  };
  category: {
    category_uid: string;
    category_name: string;
    code: string;
  };
  sub_category: {
    sub_category_uid: string;
    sub_category_name: string;
    code: string;
  };
  address: {
    city: string;
    postal_code: string;
    street_name: string;
    house_number: string;
  };
  languages: {
    language_uid: string;
    language_name: string;
  }[];
  skills: {
    skill_uid: string;
    skill_name: string;
  }[];
  job_start_at?: string;
  job_end_at?: string;
  reviewed: boolean;
  requested_to_complete_at?: string;
  requested_complete_date?: string;
  job_boost_pack?: string;
  direct_job: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;
  views?: string;
  match_score?: number;
  invoices?: any[];
  timesheets?: any[];
  in_progress_at?: string;
  completed_at?: string;
  is_hot?: boolean;
}

export interface RawJobsListData {
  budget: string;
  category_uid: string;
  company: {
    company_uid: string;
    company_name: string;
    rating: number;
  };
  companyUser: {
    avatar: string | null;
  };
  company_name: string;
  company_uid: string;
  rating: number;
  count: string;
  hours_estimated: number;
  job_boost_pack: string;
  job_description: string;
  job_end_at: string;
  job_location: string;
  job_start_at: string;
  job_status: JobStatus;
  job_title: string;
  job_type: JobType;
  job_uid: string;
  languages: { language_uid: string; language_name: string }[];
  skills: { skill_uid: string; skill_name: string }[];
  student_type: StudentType;
  students_applied: string;
  students_needed: number;
  sub_category_uid: string;
  direct_job: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;
  pinned?: boolean;
  is_hot?: boolean;
}

export interface MyJobsListItem {
  parent_job_uid: string | null;
  job_uid: string;
  job_title: string;
  job_type: JobType;
  job_status: JobStatus;
  created_at: string;
  job_start_at: string;
  job_end_at: string;
  hours_estimated: number;
  budget: number;
  rate: number;
  students_needed: number;
  proposals_count: number;
  company: { company_uid: string; company_name: string; rating: number };
  student_uid?: string;
  student_in_progress?: string;
  direct_job: boolean;
  matched_at: string;
  in_progress_at: string;
  completed_at: string;
  cancelled_at: string;
  direct_job_compensation: string;
  languages: { language_uid: string; language_name: string }[];
  job_location?: string;
  office_location?: string;
}

export interface JobByIdResponse {
  job: JobData;
}

export interface ProposalItem {
  proposal_uid: string;
  job_uid: string;
  student_uid: string;
  student_name: string;
  proposal_status: ProposalStatus;
  rate: string;
  counter_offer_description: string;
  min_month?: number;
  how_you_do_x: string;
  how_you_do_y?: string;
}

export interface JobProposalsResponse {
  proposals: ProposalItem[];
}

export interface StudentJobData {
  budget: string;
  categoryUid: string;
  company: {
    companyUid: string;
    companyName: string;
    rating: number;
  };
  companyUser?: {
    avatar: string | null;
  };
  companyName: string;
  companyUid: string;
  rating: number;
  count: string;
  hoursEstimated: number;
  jobBoostPack: string;
  jobDescription: string;
  jobEndAt: string;
  jobLocation: string;
  jobStartAt: string;
  jobStatus: JobStatus;
  jobTitle: string;
  jobType: JobType;
  jobUid: string;
  languages: { languageUid: string; languageName: string }[];
  skills: { skillUid: string; skillName: string }[];
  studentType: StudentType;
  studentsApplied: string;
  studentsNeeded: number;
  subCategoryUid: string;
  directJob: boolean;
  directJobType?: string;
  directJobCompensation?: string;
  matchScore?: number;
  pinned?: boolean;
  isHot?: boolean;
}

export const PaymentType = {
  PostingFee: 'posting-fee',
  PostingPlusBoostPackFee: 'posting-plus-boost-pack-fee',
  BoostPackFee: 'boost-pack-fee',
  DirectJobPostingFee: 'direct-job-posting-fee',
};

export interface Payment {
  job_boost_pack: number;
  payment_type: typeof PaymentType[keyof typeof PaymentType];
  overwrite_redirect?: string;
}

export type JobsSearchData = KeysToCamelCase<JobsListResponse>;

export interface JobResponse {
  job: CreateJob;
}

interface UpdateJobI {
  job: any;
  languages?: { language_uid: string }[];
  skills?: { skill_uid: string }[];
}

export interface CreateJob {
  job_uid: string;
  company_uid: string;
  job_type: JobType;

  job_title?: string;
  job_description?: string;
  category_uid?: string;
  sub_category_uid?: string;
  sub_category_name?: string;

  job_location?: JobLocation;
  job_status?: JobStatus;

  job_start_at?: string;
  job_end_at?: string;

  budget?: number;
  hours_estimated?: number;

  student_type?: StudentType;
  students_needed?: number;
  question_required?: string;
  question_optional?: string;

  job_boost_pack?: number;

  company_desc?: string;
  main_tasks?: string;
  desc_language?: string;

  direct_job: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;

  office_location?: string;
}

export interface DuplicateJobData {
  type: 'ongoing' | 'onetime';
  start_date: string;
  changes: {
    rate: number;
    hours?: number;
  };
}

export interface DuplicateJobResponse {
  payment_required: boolean;
  duplicate_job_uid: string;
}
