import React from 'react';
import './Talents.scss';
import TalentCard from './TalentCard';
import { StudentType } from '../../data/jobs';
import { Language, Skill } from '../../stores/job-form';
import { ProposalRequest } from '../favourite-talents/FavouriteTalents';

export interface StudentEducationResponse {
  degree: string;
  study: string;
  ended_at?: string;
}

export interface MatchedStudentUser {
  user_uid: string;
  avatar: string;
  first_name: string;
  last_name: string;
}

export interface MatchedStudent {
  student_uid: string;
  student_type: StudentType;
  rating: number;
  one_liner?: string;
  user: MatchedStudentUser;
  languages: Array<Language>;
  skills: Array<Skill>;
  education: Array<StudentEducationResponse>;
  top_talent?: boolean;
  match_score?: number;
}

export interface Pagination {
  page: number;
  per_page: number;
  total: number | string;
}

interface Props {
  students: Array<MatchedStudent>;
  pagination: Pagination;
  proposalRequests: Array<ProposalRequest>;
  refetchProposalRequests: () => void;
}

const Talents = (props: Props) => {
  const { students, proposalRequests, refetchProposalRequests } = props;

  return (
    <div className="talents-section">
      <div className="pool-container">
        {students?.map((student) => (
          <TalentCard
            key={student.student_uid}
            student={student}
            proposalRequests={proposalRequests}
            refetchProposalRequests={refetchProposalRequests}
          />
        ))}
      </div>
    </div>
  );
};

export default Talents;
