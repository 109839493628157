import React, { useState } from 'react';
import './RequestInterviewModal.scss';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { formatDate } from '../../../utils/dates';
import { JobTypes } from '../../../data/jobs';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/LoadingButton';
import dayjs from 'dayjs';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  jobType: string;
  handleModalSubmit: (payload: RequestInterviewForm) => void;
}

export interface RequestInterviewForm {
  date_one: string;
  date_two: string;
}

export const initialState = {
  date_one: '',
  date_two: '',
};

const minDate = new Date();
const RequestInterviewModal = ({
  open,
  handleClose,
  jobType,
  handleModalSubmit,
  isLoading,
}: Props) => {
  const schema = yup
    .object()
    .shape({
      date_one: yup.string().required(),
      date_two: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors, isValid, isSubmitted },
  } = useForm<RequestInterviewForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      date_one: null,
      date_two: null,
    },
  });
  const [openFirstPicker, setFirstOpen] = useState(false);
  const [openSecondPicker, setSecondOpen] = useState(false);
  const [hours, setHours] = useState(0);
  const { t } = useTranslation('translation', {
    keyPrefix: 'request_interview_modal',
  });

  const onClose = () => {
    reset(initialState);
    handleClose();
  };

  const onSubmit = () => {
    const formValues = getValues();
    handleModalSubmit(formValues);
    reset(initialState);

    return new Promise(() => {
      setTimeout(() => {
        handleClose();
      }, 100);
    });
  };

  const disableWeekends = (date) => {
    const parsedDate = new Date(date);
    return parsedDate.getDay() === 0 || parsedDate.getDay() === 6;
  };

  const disableTime = (timeValue, clockType): any => {
    if (clockType === 'hours') {
      setHours(timeValue);
      return timeValue < 9 || timeValue > 17;
    }

    if (clockType === 'minutes') {
      if (hours < 17) {
        return false;
      } else if (hours === 17) {
        return timeValue >= 31;
      }
    }
  };

  return (
    <Dialog open={open} className="request-interview-modal">
      <DialogTitle className="content-title">
        Request interview
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          {jobType === JobTypes.ONE_TIME ? (
            <span>
              {t('request_interview')} {t('Minite_contact')}{' '}
              {t('interview_facilitated_by_Minite')} <br /> <br />{' '}
              {t('can_interview_High_Flyers')}
            </span>
          ) : (
            <span>
              {t('request_interview')} {t('Minite_schedule_meeting')}{' '}
              {t('interview_facilitated_by_Minite')} <br /> <br />{' '}
              {t('can_interview_High_Flyers')} <br /> <br />
              {jobType === JobTypes.PREMIUM && t('before_make_selection')}
            </span>
          )}
        </DialogContentText>
        <form className="request-interview-form">
          <FormControl className="date-picker-form-control">
            Option 1:
            <Controller
              name="date_one"
              control={control}
              defaultValue={initialState.date_one}
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    onChange={(date) => {
                      onChange(date);
                    }}
                    shouldDisableDate={disableWeekends}
                    shouldDisableTime={disableTime}
                    open={openFirstPicker}
                    inputFormat={'DD MMMM YYYY H:mm A'}
                    minDate={dayjs(minDate)}
                    minTime={
                      +formatDate(getValues()?.date_one, 'D') ===
                      minDate.getDate()
                        ? dayjs(minDate)
                        : null
                    }
                    onClose={() => setFirstOpen(false)}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <>
                        <p
                          className="click-text"
                          ref={inputRef}
                          onClick={() => setFirstOpen(true)}
                        >
                          {getValues()?.date_one ? (
                            formatDate(
                              getValues()?.date_one,
                              'DD MMMM YYYY H:mm A',
                            )
                          ) : (
                            <span>Click to choose</span>
                          )}
                        </p>
                        <div id="date_one_input-wrapper">
                          <TextField
                            type="text"
                            ref={inputRef}
                            inputProps={inputProps}
                            error={Boolean(errors.date_one)}
                            variant="standard"
                            id="date_one_input"
                            label="Start Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...InputProps,
                              hidden: true,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {...restField}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>

          <FormControl className="date-picker-form-control">
            Option 2:
            <Controller
              name="date_two"
              control={control}
              defaultValue={initialState.date_two}
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    onChange={(date) => {
                      onChange(date);
                    }}
                    open={openSecondPicker}
                    shouldDisableDate={disableWeekends}
                    shouldDisableTime={disableTime}
                    onClose={() => setSecondOpen(false)}
                    inputFormat={'DD MMMM YYYY H:mm A'}
                    minDate={dayjs(minDate)}
                    minTime={
                      +formatDate(getValues()?.date_two, 'D') ===
                      minDate.getDate()
                        ? dayjs(minDate)
                        : null
                    }
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <>
                        <p
                          className="click-text"
                          ref={inputRef}
                          onClick={() => setSecondOpen(true)}
                        >
                          {getValues()?.date_two ? (
                            formatDate(
                              getValues()?.date_two,
                              'DD MMMM YYYY H:mm A',
                            )
                          ) : (
                            <span>Click to choose</span>
                          )}
                        </p>
                        <div id="date_two_input-wrapper">
                          <TextField
                            type="text"
                            ref={inputRef}
                            inputProps={inputProps}
                            error={Boolean(errors.date_two)}
                            variant="standard"
                            id="date_two_input"
                            label="Start Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...InputProps,
                              hidden: true,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {...restField}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={onClose} className="button">
          {t('cancel')}
        </Button>
        <LoadingButton
          className="button agree"
          isDisabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          buttonText={isSubmitted ? t('success') : t('confirm')}
          isLoading={isLoading}
          confirmButtonTestId="request-interview"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RequestInterviewModal;
