import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import {
  useFetchSkills,
  useFetchUniversities,
} from '../../../data/edit-profile';
import AddElement from './AddElement';
import RemoveElement from './RemoveElement';
import { IAddSkill, useAddSkill, useDeleteSkill } from '../../../data/skills';
import {
  IAddUniversity,
  useAddUniversities,
  useDeleteUniversities,
} from '../../../data/universities';
import { LoadingButton } from '../../../components/LoadingButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAnnouncementDetails,
  useUpdateAnnouncement,
} from '../../../data/admin-announcements';
import * as yup from 'yup';

interface AnnouncementUpdateInterface {
  value: string;
  url: string;
  visible: boolean;
}

const schema = yup
  .object()
  .shape({
    value: yup.string(),
  })
  .required();

const statuses = [
  { id: 'true', label: 'Visible' },
  { id: 'false', label: 'Not visible' },
];

const EditsAdmin = () => {
  const { data: universities = [], refetch: refetchUniversities } =
    useFetchUniversities();
  const { data: skills = [], refetch: refetchSkills } = useFetchSkills();
  const { mutateAsync: addSkill } = useAddSkill();
  const { data: announcement, refetch: refetchAnnouncement } =
    useAnnouncementDetails('banner_students');
  const { mutateAsync: deleteSkill, isLoading: isDeleteSkill } =
    useDeleteSkill();
  const { mutateAsync: addUniversities } = useAddUniversities();
  const { mutateAsync: deleteUniversities, isLoading: isDeleteUniversities } =
    useDeleteUniversities();
  const { mutateAsync: updateAnnouncement, isLoading } =
    useUpdateAnnouncement('banner_students');

  const [announcementValueState, setAnnouncementValueState] = useState(
    announcement?.value,
  );
  const [announcementUrlState, setAnnouncementUrlState] = useState(
    announcement?.url,
  );
  const [announcementVisibleState, setAnnouncementVisibleState] = useState(
    announcement?.visible,
  );

  useEffect(() => {
    setAnnouncementValueState(announcement?.value);
  }, [announcement]);

  useEffect(() => {
    setAnnouncementUrlState(announcement?.url);
  }, [announcement]);

  useEffect(() => {
    setAnnouncementVisibleState(announcement?.visible);
  }, [announcement]);

  const saveSkill = (info: IAddSkill[]) => {
    addSkill(info).then(() => {
      refetchSkills();
    });
  };
  const removeSkill = (info: { id: string }[]) => {
    deleteSkill(info.map((item) => item.id)).then(() => {
      refetchSkills();
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AnnouncementUpdateInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      value: announcement?.value,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async () => {
    try {
      const payload = {
        value: announcementValueState,
        url: announcementUrlState,
        visible: announcementVisibleState,
      };
      await updateAnnouncement({
        announcement: payload,
      });

      await refetchAnnouncement();
    } catch (error) {}
  };

  const saveUniversities = (info: IAddUniversity[]) => {
    addUniversities(info).then(() => {
      refetchUniversities();
    });
  };
  const removeUniversities = (info: { id: string }[]) => {
    deleteUniversities(info.map((item) => item.id)).then(() => {
      refetchUniversities();
    });
  };

  const getOption = (array, label, id) => {
    return array.map((item) => ({ label: item[label], id: item[id] }));
  };

  const universityOptions = getOption(
    universities,
    'university_name',
    'university_uid',
  );

  const skillOptions = getOption(skills, 'skill_name', 'skill_uid');

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-revenue-container"
      sx={{
        maxWidth: 'md',
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Edits</p>
      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div>
          <p className="section-sub-title">Skills</p>
          <AddElement
            label="Skill"
            buttonText="Add skill"
            onSave={saveSkill}
            title="Add Skill"
            key_name="skill_name"
            options={skillOptions}
          />
          <RemoveElement
            label="Skill"
            buttonText="Remove skill"
            onSave={removeSkill}
            title="Remove Skill"
            key_name="skill_name"
            options={skillOptions}
            isLoading={isDeleteSkill}
          />
        </div>
        <div>
          <p className="section-sub-title">Universities</p>
          <AddElement
            label="University"
            buttonText="Add university"
            onSave={saveUniversities}
            title="Add University"
            key_name="university_name"
            options={universityOptions}
          />
          <RemoveElement
            label="University"
            buttonText="Remove University"
            onSave={removeUniversities}
            title="Remove University"
            key_name="university_name"
            isLoading={isDeleteUniversities}
            options={universityOptions}
          />
        </div>
        <form className="ann-form">
          <div>
            <p className="section-sub-title">Banner (students)</p>
            <TextField
              className="form-input"
              name="banner"
              type="text"
              id="banner-input"
              variant="standard"
              label={'Banner text'}
              error={!!errors.value}
              value={announcementValueState}
              onChange={(e) => setAnnouncementValueState(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="form-input"
              name="banner"
              type="text"
              id="banner-input-url"
              variant="standard"
              label={'Url'}
              error={!!errors.value}
              value={announcementUrlState}
              onChange={(e) => setAnnouncementUrlState(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <Select
              id="status-select"
              variant="standard"
              value={announcementVisibleState || false}
              label={'Visibility'}
              onChange={(e) =>
                setAnnouncementVisibleState(e.target.value === 'true')
              }
            >
              {statuses.map((item) => {
                const { id, label } = item;
                return (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>

            <div className="save-button-container">
              <LoadingButton
                className="save-button"
                onClick={handleSubmit(onSubmit)}
                confirmButtonTestId="save-announcement"
                buttonText={'Save'}
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default EditsAdmin;
