import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@aws-amplify/ui-react';

export function PhoneNumberField() {
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();

  const onPhoneChange = (event) => {
    const { value } = event.target;
    const submitButton = document.querySelector(
      'form button[type="submit"]',
    ) as HTMLButtonElement;

    if (!value.match(/[0-9]{10,14}/gm)) {
      if (submitButton) {
        submitButton.disabled = true;
        submitButton.classList.add('amplify-button--disabled');
      }
      setPhoneHasError(true);
    } else {
      setPhoneHasError(false);

      if (submitButton) {
        submitButton.disabled = false;
        submitButton.classList.remove('amplify-button--disabled');
      }
    }
    setPhone(value);
  };

  return (
    <TextField
      placeholder={t('settings_financial.phone_number')}
      key="custom:phone"
      label=""
      errorMessage="Invalid phone number"
      hasError={phoneHasError}
      onChange={onPhoneChange}
      isRequired={true}
      name="custom:phone"
      value={phone}
      data-testid="phone_number"
    />
  );
}
