import React from 'react';
import './ProgessPie.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

interface ProgressPieProps {
  value: number;
}

const ProgressPie = ({ value = 0 }: ProgressPieProps) => {
  return (
    <div className="progress-pie-container">
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={value}
          size={120}
          sx={{
            color: '#DEB900',
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{
              color: '#4E5B73',
              fontFamily: '"Sofia W05 Medium", sans-serif',
              fontSize: '24px',
              lineHeight: '33px',
              fontWeight: 500,
            }}
          >{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ProgressPie;
