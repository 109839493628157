import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { JobType } from './jobs';
import { UserStudentStats } from './user';

export const fetchStudentById = async (
  studentUid: string,
): Promise<ProfileData> => {
  const { data } = await request<ServerResponse<ProfileData>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/students/${studentUid}`,
    method: 'get',
  });

  return data.data;
};

export function useFetchStudentById(studentUid: string) {
  return useQuery([studentUid], () => fetchStudentById(studentUid), {
    enabled: !!studentUid,
    onError: (e) => console.error(e),
  });
}

// export function getMaintenanceStatus() {
//   return axios.get(process.env.REACT_APP_API_URL + `/api/v1/status`);
// }

export interface StudentProfile {
  data: ProfileData;
  success: boolean;
  timestamp: Date;
}

export interface ProfileUser {
  user_uid: string;
  avatar: string;
  first_name: string;
  last_name: string;
}

export interface ProfileSkill {
  skill_name: string;
  skill_uid: string;
}

export interface ProfileLanguage {
  language_name: string;
  language_uid: string;
}

export interface ProfileJobInterests {
  job_interest_name: string;
  job_interest_uid: string;
}

export interface ProfileExperience {
  ended_at: string;
  job_description: string;
  job_title: string;
  job_type: JobType;
  organization_name: string;
  started_at: string;
  student_experience_uid: string;
}

export interface ProfileExperienceSkill {
  skill_uid: string;
  student_experience_uid: string;
}

export interface ProfileExperienceEducation {
  degree: string;
  ended_at: string;
  started_at: string;
  study: string;
  university_uid: string;
}

export interface ProfileCertification {
  certificate_name: string;
  certificate_uid: string;
}

export interface ProfileUniversity {
  university_name: string;
  university_uid: string;
}

export interface ProfileStudentLanguage {
  language_uid: string;
  level: string;
  student_language_uid: string;
}

export interface ProfileStudentPersonalSentences {
  question: string;
  response: string;
}

export interface ProfileData {
  universities: ProfileUniversity[];
  certificates?: ProfileCertification[];
  education: ProfileExperienceEducation[];
  experience_skills: ProfileExperienceSkill[];
  experiences: ProfileExperience[];
  hourly_rate: number;
  job_interests: ProfileJobInterests[];
  languages: ProfileLanguage[];
  one_liner?: string;
  personal_sentences: ProfileStudentPersonalSentences[];
  profile_summary?: string;
  rating: string;
  skills: ProfileSkill[];
  tags?: string[];
  personality_type?: string;
  student_languages: ProfileStudentLanguage[];
  student_uid: string;
  student_type: string;
  ambassador?: boolean;
  user: ProfileUser;
  top_talent?: boolean;
  student_stats?: UserStudentStats;
  profile: Profile;
}

export enum Profile {
  STEM = 'stem',
  Business = 'business',
  Arts = 'arts',
  Law = 'law',
  SocialSciences = 'social_sciences',
  Other = 'other',
}

export const studentRegistrationNotComplete = (profile: ProfileData) => {
  return (
    profile.education.length === 0 ||
    profile.experience_skills.length === 0 ||
    profile.experiences.length === 0 ||
    profile.skills.length === 0 ||
    profile.student_languages.length === 0 ||
    profile.universities.length === 0
  );
};
