import { useMutation, useQuery } from '@tanstack/react-query';
import { request, ServerResponse } from '../core/request';
import { MATCHES, TALENT_POOL } from './query-keys';
import { TalentPool } from '../components/favourite-talents/FavouriteTalents';
import { MatchedStudent, Pagination } from '../components/talents/Talents';

export interface Matches {
  students: Array<MatchedStudent>;
  pagination: Pagination;
}

const fetchJobMatches = async (
  jobUid: string,
  page: number,
  perPage: number,
) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/matches?page=${page}&per_page=${perPage}`,
    method: 'get',
  });

  return data;
};

export const useFetchMatches = (jobUid: string, page = 1, perPage = 9) =>
  useQuery([MATCHES, page], () => fetchJobMatches(jobUid, page, perPage), {
    enabled: !!jobUid,
    staleTime: 3600,
    select: (response) => {
      return response.data as unknown as Matches;
    },
    onError: (e) => console.error(e),
  });

const fetchTalentPool = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/talent_pool',
    method: 'get',
  });

  return data;
};

export const useFetchTalentPool = (byCompany: boolean) =>
  useQuery([TALENT_POOL], () => fetchTalentPool(), {
    enabled: byCompany,
    staleTime: 3600,
    select: (response) => {
      return response.data as unknown as TalentPool[];
    },
    onError: (e) => console.error(e),
  });

const addStudentToTalentPool = async (
  studentUid: string,
  fromHighlighted: boolean,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/talent_pool/${studentUid}`,
    data: { from_highlighted: fromHighlighted },
    method: 'post',
  });

  if (!data.success)
    throw new Error('addStudentToTalentPool failed, code: ' + data.code);
};

export const useAddStudentToTalentPool = (
  studentUid: string,
  fromHighlighted = false,
) =>
  useMutation(() => addStudentToTalentPool(studentUid, fromHighlighted), {
    onError: (e) => console.error(e),
  });

const removeStudentFromTalentPool = async (studentUid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/talent_pool/${studentUid}`,
    method: 'delete',
  });

  if (!data.success)
    throw new Error('removeStudentFromTalentPool failed, code: ' + data.code);
};

export const useRemoveStudentFromTalentPool = (studentUid: string) =>
  useMutation(() => removeStudentFromTalentPool(studentUid), {
    onError: (e) => console.error(e),
  });
