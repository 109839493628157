import create from 'zustand';
import * as yup from 'yup';
import { IOption } from '../utils/ioption';

export const MIN_EDUCATION_EXPERIENCE_DATE = '2010-01-01';

interface Certificate {
  name: string;
}

interface PersonalSentence {
  question: string;
  response: string;
}

export interface CreatePersonalForm {
  certificate?: Certificate[];
  rate: number;
  info?: string;
  details?: string;
  avatar: string;
  job_interests: IOption[];
  tags?: string[];
  personal_sentences: PersonalSentence[];
  personality_type?: string;
}

interface CreatePersonalFormState {
  personalFormState: CreatePersonalForm;
  setPersonalFormState: (formState: CreatePersonalForm) => void;
  resetFormState: () => void;
}

export const initialPersonalFormData = {
  rate: 20,
  avatar: '/images/student-avatars/7.png',
  job_interests: [],
  tags: [],
  personal_sentences: [
    {
      question: 'This gives me energy',
      response: '',
    },
    {
      question: 'This is my pet peeve',
      response: '',
    },
  ],
  personality_type: null,
};

export const useCreatePersonalForm = create<CreatePersonalFormState>((set) => ({
  personalFormState: initialPersonalFormData,
  setPersonalFormState: (formState) => set({ personalFormState: formState }),
  resetFormState: () =>
    set({
      personalFormState: initialPersonalFormData,
    }),
}));

export interface ExperienceJobData {
  job: string;
  type: string;
  organization: string;
  startDate: Date | null;
  endDate: Date | null;
  skills: IOption[];
  description: string;
}

export interface ExperienceFormData {
  jobs: ExperienceJobData[];
}

export const initialExperienceJob = {
  job: '',
  type: '',
  organization: '',
  startDate: null,
  endDate: null,
  skills: [],
  description: '',
};

export const initialExperienceFormData = {
  jobs: [initialExperienceJob],
};

export const experienceSchema = yup
  .object()
  .shape({
    jobs: yup
      .array()
      .of(
        yup.object().shape({
          job: yup.string().required('required'),
          type: yup.string().required('required'),
          organization: yup.string().required('required'),
          startDate: yup.date().nullable().required('required'),
          endDate: yup
            .date()
            .nullable()
            .when('startDate', (startDate) => {
              if (startDate && !isNaN(new Date(startDate).getDate())) {
                return yup
                  .date()
                  .min(startDate, 'must be greater than start date')
                  .nullable()
                  .optional();
              }
              return yup.date().nullable().optional();
            }),
          skills: yup
            .array()
            .nullable()
            .of(yup.object().shape({ label: yup.string(), id: yup.string() }))
            .min(1, 'requires at least 1 skill')
            .max(5, 'requires not more than 5 skills')
            .required('required'),
          description: yup
            .string()
            .max(500, 'Max 500 characters')
            .required('required'),
        }),
      )
      .required('required'),
  })
  .required();

interface CreateExperienceFormState {
  experienceFormState: ExperienceFormData;
  setExperienceFormState: (formState: ExperienceFormData) => void;
  resetFormState: () => void;
}

export const useCreateExperienceForm = create<CreateExperienceFormState>(
  (set) => ({
    experienceFormState: initialExperienceFormData,
    setExperienceFormState: (formState) =>
      set({ experienceFormState: formState }),
    resetFormState: () =>
      set({
        experienceFormState: initialExperienceFormData,
      }),
  }),
);

export interface University {
  university: IOption;
  degree: string;
  study: string;
  startDate: TInputDate | null;
  endDate: TInputDate | null;
}

interface Language {
  language: { language_uid: string; label: string };
  level: string;
}

export interface LanguageForm {
  language: IOption;
  level: string;
}

export interface EducationFormData {
  universities: University[];
  profile: string;
  languages: Language[];
}

export type TInputDate = Date;

export const initialUniversity = {
  university: null,
  degree: '',
  study: '',
  startDate: null,
  endDate: null,
};

export const universitiesSchema = yup
  .array()
  .of(
    yup.object().shape({
      university: yup
        .object()
        .shape({ label: yup.string(), id: yup.string() })
        .nullable()
        .required('required'),
      degree: yup.string().required('required'),
      study: yup.string().required('required'),
      startDate: yup.date().required('required'),
      endDate: yup.date().when('startDate', (startDate) => {
        if (startDate && !isNaN(new Date(startDate).getDate())) {
          return yup
            .date()
            .min(startDate, 'must be greater than start date')
            .nullable()
            .optional();
        }
        return yup.date().nullable().optional();
      }),
    }),
  )
  .min(1)
  .required('required');

export const initialLang = {
  language: null,
  level: '',
};

export const initialFormLang = {
  language: null,
  level: '',
};

export const languagesSchema = yup
  .array()
  .of(
    yup.object().shape({
      language: yup
        .object()
        .shape({ label: yup.string(), id: yup.string() })
        .nullable()
        .required('required'),
      level: yup.string().required('required'),
    }),
  )
  .min(1)
  .required('required');

export const initialEducationFormData = {
  universities: [{ ...initialUniversity }],
  languages: [{ ...initialLang }],
  profile: '',
  reason: '',
  otherReason: '',
};

interface CreateEducationFormState {
  educationFormState: EducationFormData;
  setEducationFormState: (formState: EducationFormData) => void;
  resetFormState: () => void;
}

export const useCreateEducationForm = create<CreateEducationFormState>(
  (set) => ({
    educationFormState: { ...initialEducationFormData },
    setEducationFormState: (formState) =>
      set({ educationFormState: formState }),
    resetFormState: () =>
      set({
        educationFormState: { ...initialEducationFormData },
      }),
  }),
);
