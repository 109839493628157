import './FormFields.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Authenticator,
  CheckboxField,
  Link,
  TextField,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import { SignUpType } from './SignUpType';
import { PhoneNumberField } from './PhoneNumberField';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { PasswordRequirements } from '../amplify/password-requirements';

export const FormFields = () => {
  const { t } = useTranslation();
  const { validationErrors } = useAuthenticator();
  const [passwords, setPasswords] = useState({
    password: null,
    confirm_password: '',
  });
  const [termsAndConditionsLink, setTermsAndConditionsLink] = useState('');

  useDocumentTitle('Sign Up');

  const passwordOnChange = (e) => {
    const { value, name } = e.target;

    setPasswords((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const password = document.querySelector('[name="password"]');
    const confirm_password = document.querySelector(
      '[name="confirm_password"]',
    );

    password.addEventListener('keyup', passwordOnChange);
    confirm_password.addEventListener('keyup', passwordOnChange);

    return () => {
      password.removeEventListener('keyup', passwordOnChange);
      confirm_password.removeEventListener('keyup', passwordOnChange);
    };
  }, []);

  // Update first name and last name requirements
  useEffect(() => {
    const first_name = document.getElementsByName(
      'given_name',
    )[0] as HTMLInputElement;
    const regex = /^\S.*$/;
    first_name.pattern = regex.toString().replace('/', '').replace('/', '');

    const last_name = document.getElementsByName(
      'family_name',
    )[0] as HTMLInputElement;
    last_name.pattern = regex.toString().replace('/', '').replace('/', '');
  }, []);

  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');

  const handleGivenNameChanged = (event) => {
    if (event.target.value.trim() === '') {
      setGivenName('');
    } else {
      setGivenName(event.target.value);
    }
  };

  const handleFamilyNameChanged = (event) => {
    if (event.target.value.trim() === '') {
      setFamilyName('');
    } else {
      setFamilyName(event.target.value);
    }
  };

  const onSignUpTypeChanged = (userType) => {
    switch (userType) {
      case 'student':
        setTermsAndConditionsLink(
          'https://www.minite.works/terms-of-use/terms-of-use-students',
        );
        break;
      case 'company':
        setTermsAndConditionsLink(
          'https://www.minite.works/terms-of-use/terms-of-use-companies',
        );
        break;
    }
  };

  return (
    <>
      <div className="user-name">
        <TextField
          placeholder={t('sign_up_student.firstname')}
          key="given_name"
          label=""
          isRequired={true}
          name="given_name"
          className="given_name"
          data-textid="given_name"
          onChange={handleGivenNameChanged}
          value={givenName}
        />
        <TextField
          placeholder={t('sign_up_student.lastname')}
          key="family_name"
          label=""
          isRequired={true}
          name="family_name"
          data-textid="family_name"
          onChange={handleFamilyNameChanged}
          value={familyName}
        />
      </div>
      <Authenticator.SignUp.FormFields />
      {passwords.password !== null && <PasswordRequirements {...passwords} />}
      <PhoneNumberField />
      <SignUpType onSignUpTypeChanged={onSignUpTypeChanged} />

      {termsAndConditionsLink && (
        <div className="terms-and-conditions">
          <CheckboxField
            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            isRequired={true}
            name="acknowledgement"
            data-testid="acknowledgement"
            value="yes"
            label={t('sign_up.i_accept')}
            style={{
              display: 'inline',
              width: 'fit-content',
            }}
          />
          <Link
            style={{
              fontWeight: 'var(--amplify-font-weights-normal)',
              color: '#1976d2',
              width: 'fit-content',
            }}
            href={termsAndConditionsLink}
            target="_blank"
          >
            &nbsp; {t('sign_up.terms_of_use')}
          </Link>
        </div>
      )}
    </>
  );
};
