import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import MiniteGrid from '../../../components/grid';

import { handleGridHeight } from '../../../utils/helpers';
import {
  AssessmentListItem,
  useGetStudentAssessments,
} from '../../../data/admin-students';

const Assessments = () => {
  const params = useParams<{ studentUid?: string }>();
  const { data: assessments = [], isLoading: loading } =
    useGetStudentAssessments(params.studentUid);

  const assessmentsGridData = (): unknown[] =>
    assessments.map((assessment: AssessmentListItem) => {
      const {
        student_test_uuid,
        started_at,
        finished_at,
        seconds,
        points,
        max_points,
      } = assessment;

      const minutes = (seconds ? seconds / 60 : 0).toFixed(2);
      const assessment_score =
        points && max_points ? (points / max_points) * 100 : 0;
      return {
        id: student_test_uuid,
        started_at,
        finished_at,
        minutes,
        assessment_score,
      };
    });

  const assessmentColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'started_at',
      headerName: 'Started at',
      flex: 1,
    },
    {
      field: 'finished_at',
      headerName: 'Finished at',
      flex: 1,
    },
    {
      field: 'minutes',
      headerName: 'Minutes',
      flex: 1,
    },

    {
      field: 'assessment_score',
      headerName: 'Score',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        let color = 'red';
        const { assessment_score } = params.row;

        if (assessment_score >= 50) {
          color = 'green';
        }
        return <span style={{ color }}>{assessment_score}%</span>;
      },
    },
  ];

  return (
    <div className="dashboard-assessments">
      <h3 className="heading-text">Assessments</h3>

      <MiniteGrid
        rowId={'id'}
        loading={loading}
        columns={assessmentColumns}
        rows={assessmentsGridData()}
        pageSize={8}
        pagination
        disableColumnFilter={true}
        rowCount={assessments.length}
        height={handleGridHeight(assessments?.length || 0, 8)}
      />
    </div>
  );
};

export default Assessments;
