import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { StudentType } from './jobs';
import {
  ADMIN_STUDENTS,
  STUDENT_ASSESSMENTS,
  STUDENTS_STATISTICS,
} from './query-keys';
import { AxiosError } from 'axios';

export const searchAdminStudents = async (body?: StudentsSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/students/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminStudents = (body: StudentsSearchRequest) => {
  return useQuery([ADMIN_STUDENTS, body], () => searchAdminStudents(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const studentsStatistic = async () => {
  const { data } = await request<ServerResponse<Statistics>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/statistics/students`,
    method: 'get',
  });

  return data.data;
};

export const useStudentsStatistic = () => {
  return useQuery([STUDENTS_STATISTICS], () => studentsStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const getStudentAssessments = async (
  student_uid: string,
): Promise<Array<AssessmentListItem>> => {
  const { data } = await request<ServerResponse<object>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/${student_uid}/tests`,
    method: 'get',
  });

  return data.data as Array<AssessmentListItem>;
};

export const useGetStudentAssessments = (student_uid: string) => {
  return useQuery(
    [STUDENT_ASSESSMENTS],
    () => getStudentAssessments(student_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

interface Search {
  students: any;
  pagination: { total: number; page: number; per_page: number };
}

interface Statistics {
  students_total: number;
  students_verified: number;
  students_with_job_in_progress: number;
}

export interface StudentsSearchRequest {
  student_type?: StudentType[];
  verified?: boolean[];
  how_did_you_find_us?: any;

  language_uid?: string[];
  skill_uid?: string[];
  university_uid?: string[];

  hourly_rate_min?: number;
  hourly_rate_max?: number;

  jobs_min?: number;
  jobs_max?: number;

  proposals_min?: number;
  proposals_max?: number;

  query?: string;

  page?: number;
  per_page?: number;
}

export interface AssessmentListItem {
  student_test_uuid: string;
  student_uid: string;
  started: boolean;
  started_at?: string;
  finished: boolean;
  finished_at?: string;
  seconds?: number;
  max_points?: number;
  points?: number;

  created_at?: string;
  updated_at?: string;
}
