import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  studentData?: any;
}

const SignupScore = ({ studentData }: Props) => {
  const sentiments = [
    studentData?.score?.bio_sentiment,
    studentData?.score?.one_liner_sentiment,
    studentData?.score?.jd_sentiment,
  ];
  const [sentimentScore, setSentimentScore] = useState(0);
  const [spellingScore, setSpellingScore] = useState(0);
  const [timeScore, setTimeScore] = useState(0);
  const [rageClickScore, setRageClickScore] = useState(0);
  const [typeRetryScore, setTypeRetryScore] = useState(0);
  useEffect(() => {
    for (const sentiment of sentiments) {
      if (sentiment === 'positive') {
        setSentimentScore((prev) => prev + 1);
      } else if (sentiment === 'neutral') {
        setSentimentScore((prev) => prev + 0.5);
      }
    }
    const averageSpelling =
      (studentData?.score?.bio_correct_spelling +
        studentData?.score?.one_liner_correct_spelling +
        studentData?.score?.jd_correct_spelling) /
      3;
    setSpellingScore((averageSpelling / 100) * 3);
    if (
      studentData?.score?.time_step_1 +
        studentData?.score?.time_step_2 +
        studentData?.score?.time_step_3 <
      240
    ) {
      setTimeScore(0);
    } else {
      setTimeScore(2);
    }
    if (studentData?.score?.rage_clicks < 5) {
      setRageClickScore(1);
    }
    if (studentData?.score?.long_deletes < 10) {
      setTypeRetryScore(1);
    }
  }, [studentData?.score]);

  return (
    <Grid
      container
      className="admin-student-info-container"
      columnGap={2}
      sx={{
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid item md={12}>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              backgroundColor: 'transparent',
            },
            backgroundColor: 'transparent',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="overview-info">
              <div className="title">Signup score</div>
              <div className="info">
                {(
                  sentimentScore +
                  spellingScore +
                  timeScore +
                  rageClickScore +
                  typeRetryScore
                ).toFixed(2)}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Time Step 1</TableCell>
                    <TableCell>Time Step 2</TableCell>
                    <TableCell>Time Step 3</TableCell>
                    <TableCell># rage clicks</TableCell>
                    <TableCell># type retries</TableCell>
                    <TableCell>Bio spelling</TableCell>
                    <TableCell>One-liner spelling</TableCell>
                    <TableCell>Job description spelling</TableCell>
                    <TableCell>Bio sentiment</TableCell>
                    <TableCell>One-liner sentiment</TableCell>
                    <TableCell>Job description sentiment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{studentData?.score?.time_step_1} s</TableCell>
                    <TableCell>{studentData?.score?.time_step_2} s</TableCell>
                    <TableCell>{studentData?.score?.time_step_3} s</TableCell>
                    <TableCell>{studentData?.score?.rage_clicks}</TableCell>
                    <TableCell>{studentData?.score?.long_deletes}</TableCell>
                    <TableCell>
                      {studentData?.score?.bio_correct_spelling?.toFixed(0)} %
                    </TableCell>
                    <TableCell>
                      {studentData?.score?.one_liner_correct_spelling?.toFixed(
                        0,
                      )}{' '}
                      %
                    </TableCell>
                    <TableCell>
                      {studentData?.score?.jd_correct_spelling?.toFixed(0)} %
                    </TableCell>
                    <TableCell>{studentData?.score?.bio_sentiment}</TableCell>
                    <TableCell>
                      {studentData?.score?.one_liner_sentiment}
                    </TableCell>
                    <TableCell>{studentData?.score?.jd_sentiment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Score</TableCell>
                    <TableCell colSpan={3} align="center">
                      {timeScore}
                    </TableCell>
                    <TableCell align="center">{rageClickScore}</TableCell>
                    <TableCell align="center">{typeRetryScore}</TableCell>
                    <TableCell colSpan={3} align="center">
                      {spellingScore?.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={3} align="center">
                      {sentimentScore}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default SignupScore;
