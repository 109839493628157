import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Checkbox,
  checkboxClasses,
} from '@mui/material';

import './TermsAndConditionsModal.scss';
import { LoadingButton } from '../LoadingButton';
import React, { useState } from 'react';
import { UserData, useAcceptTermsAndConditions } from '../../data/user';
import { useTranslation } from 'react-i18next';
import MiniteSnackbar from '../snackbar';
import { useUserData } from '../../stores/auth';

interface Props {
  title: string;
  loadingButton?: boolean;
  isLoading?: boolean;
  customWidth?: number;
  confirmButtonTestId?: string;
  user: UserData;
}

export const TermsAndConditionsModal = ({
  title,
  customWidth,
  confirmButtonTestId,
  user,
}: Props) => {
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [open, setOpen] = useState(true);
  const { mutateAsync: acceptTermsAndConditions, isLoading } =
    useAcceptTermsAndConditions();
  const refetchUser = useUserData((state) => state.refetchUser);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  let termsAndConditionsLink = null;

  if (user.company_uid) {
    termsAndConditionsLink =
      'https://www.minite.works/terms-of-use/terms-of-use-companies';
  }

  if (user.student_uid) {
    termsAndConditionsLink =
      'https://www.minite.works/terms-of-use/terms-of-use-students';
  }

  const handleConfirm = async () => {
    try {
      await acceptTermsAndConditions();
      await refetchUser();
      setOpen(false);
    } catch (error) {
      console.log('Error caught', error);
      setErrorMessage('Error accepting terms and conditions');
    }
  };
  return (
    <>
      <Dialog
        open={open}
        className="generic-modal"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: `${customWidth || '600'}px`,
            },
          },
        }}
      >
        <DialogTitle className="content-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="content-text">
            We recently updated our Terms of Use. In order to proceed, please
            accept these first
            {termsAndConditionsLink && (
              <div className="terms-and-conditions">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgement}
                      onChange={(e) => setAcknowledgement(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        [`&.${checkboxClasses.checked}`]: {
                          color: '#DEB900',
                        },
                      }}
                    />
                  }
                  label={t('sign_up.i_accept')}
                  sx={{
                    marginRight: '5px',
                  }}
                />

                <Link
                  href={termsAndConditionsLink}
                  target="_blank"
                  className="link"
                >
                  {t('sign_up.terms_of_use')}
                </Link>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            className={`button confirm`}
            onClick={handleConfirm}
            buttonText={'Proceed'}
            isLoading={isLoading}
            isDisabled={!acknowledgement}
            confirmButtonTestId={confirmButtonTestId}
          />
        </DialogActions>
      </Dialog>
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};
