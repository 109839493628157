import { Auth } from 'aws-amplify';

export const cognitoOauthRedirectSignIn =
  process.env.REACT_APP_COGNITO_OAUTH_REDIRECT || 'http://localhost:3000';

export const awsConfig = {
  Auth: {
    signUpVerificationMethod: 'code',
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    // NOTE: reconsider
    identityPoolId: process.env.REACT_APP_COGNITO_FEDERATED_IDENTITY_POOL,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: true,
    oauth: {
      domain:
        process.env.REACT_APP_COGNITO_DOMAIN ||
        'minite-dev.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: cognitoOauthRedirectSignIn,
      redirectSignOut:
        process.env.REACT_APP_COGNITO_OAUTH_REDIRECT || 'http://localhost:3000',
      clientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};
