import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { ADMIN_INVOICES } from './query-keys';
import { AxiosError } from 'axios';
import { Search } from 'history';

const searchAdminInvoices = async (body?: InvoicesSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/invoices/search`,
    method: 'post',
    data: body,
  });

  return data.data as unknown as InvoicesSearchResponse;
};

export const useSearchAdminInvoices = (body: InvoicesSearchRequest) => {
  return useQuery([ADMIN_INVOICES, body], () => searchAdminInvoices(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface InvoicesSearchRequest {
  query?: string;
  page?: number;
  per_page?: number;
}

export interface InvoicesSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  invoices: InvoicesResponse[];
}

export interface InvoicesResponse {
  invoice_uid: string;
  invoice_number: number;
  invoice_paid: boolean;
  invoice_paid_at: Date;
  invoice_sent: boolean;
  invoice_sent_at: Date;
  created_at: Date;
  updated_at: Date;
  type: string;

  job_title: string;
  job_uid: string;

  payment_uid: string;
  payment_status: string;
  payment_description: string;
  payment_type: string;
  amount: number;

  student_uid?: string;
  timesheet_uid?: string;

  company_collective_invoice_number?: string;
}

export interface ItemsResponse {
  invoice_item_uid: string;
  quantity: number;
  description: string;
  price: number;
}
