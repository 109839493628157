import create from 'zustand';
import { UserData, fetchUserData, getUserType } from '../data/user';
import { Auth } from 'aws-amplify';

interface UserDataState {
  user: UserData | null;
  setUserData?: (user: UserData | null) => void;
  logged_in?: boolean;
  setLoggedIn?: (logged_in: boolean) => void;
  refetchUser?: () => Promise<void>;
}
export const useUserData = create<UserDataState>((set) => ({
  user: null,
  logged_in: false,
  setUserData: (userData) => set({ user: userData }),
  setLoggedIn: (logged_in) => set({ logged_in }),
  refetchUser: async () => {
    const updatedUser = (await fetchUserData()) as UserData;
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    const type = getUserType(updatedUser);
    set({
      user: {
        type,
        token,
        ...updatedUser,
      },
    });
  },
}));
