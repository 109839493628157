import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './VoteModal.scss';
import React from 'react';

interface Props {
  open: boolean;
  handleCancel: () => void;
}

const VoteModal = ({ open, handleCancel }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      className="generic-modal"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle className="content-title">
        <strong>
          📢 This is your last chance to become consultant for a day with
          TOPdesk!
        </strong>
        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <a
        href="/jobs/8f09fafc-2445-4ee4-b807-425107cb9388"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleCancel}
      >
        <DialogContent>
          <img
            src="/images/topdesk.png"
            alt="Topdesk open day"
            style={{ maxWidth: '90%' }}
          />
        </DialogContent>
      </a>
    </Dialog>
  );
};

export default VoteModal;
