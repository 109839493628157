import {
  Firestore,
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
  getFirestore,
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

export function getFirebaseConfig() {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };
}

export async function addChatToUserChats(
  user_uid: string,
  db: Firestore,
  new_chat_id: string,
  role: string,
  name?: string,
  avatarUrl?: string,
  email?: string,
) {
  const docRef = doc(db, 'users', user_uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // Update
    const currentChats = docSnap.data().chats;
    let newChats = [];
    if (currentChats) {
      newChats = [...currentChats, new_chat_id];
    } else {
      newChats = [new_chat_id];
    }
    await setDoc(
      doc(db, 'users', user_uid),
      {
        chats: newChats,
      },
      { merge: true },
    );
  } else {
    await setDoc(doc(db, 'users', user_uid), {
      chats: [new_chat_id],
      role: role,
      name: name,
      avatarUrl: avatarUrl,
      email: email,
    });
  }
}

export async function createNewChatAndUser(
  user_uid: string,
  db: Firestore,
  role: string,
  name: string,
  avatarUrl: string,
  email: string,
  admin_uid: string,
) {
  const docRefCreated = await addDoc(collection(db, 'chats'), {
    messages: [],
    users: [user_uid, admin_uid],
    proposal_uid: 'admin',
  });

  await addChatToUserChats(
    user_uid,
    db,
    docRefCreated.id,
    role,
    name,
    avatarUrl,
    email,
  );
  await addChatToUserChats(admin_uid, db, docRefCreated.id, 'admin');
  const docRefNew = doc(db, 'users', user_uid);
  await getDoc(docRefNew);
  return docRefCreated.id;
}

export async function findAdminUidFromChats(db: Firestore): Promise<string> {
  const qAdmin = query(
    collection(db, 'users'),
    where('role', '==', 'admin'),
    limit(1),
  );
  const adminSnapshot = await getDocs(qAdmin);
  const admin_uid = adminSnapshot.docs[0].id;
  return admin_uid;
}

export async function updateMyDetails(
  user_uid: string,
  db: Firestore,
  name: string,
  avatarUrl: string,
  email: string,
) {
  const currentUser = await getDoc(doc(db, 'users', user_uid));
  if (currentUser.exists()) {
    // Update
    await setDoc(
      doc(db, 'users', user_uid),
      {
        avatarUrl: avatarUrl,
        name: name,
        email: email,
      },
      { merge: true },
    );
  }
}

export async function getOrCreateChatWithAdmin(
  user_uid: string,
  db: Firestore,
  role: string,
  name: string,
  avatarUrl: string,
  email: string,
) {
  let chatWithAdmin;
  const currentUser = await getDoc(doc(db, 'users', user_uid));
  const admin_uid = await findAdminUidFromChats(db);

  if (currentUser.exists()) {
    const currentUserChats = currentUser.data().chats;
    for (const chatId of currentUserChats) {
      const chat = await getDoc(doc(db, 'chats', chatId));
      const data = chat.data();
      if (data.users.includes(admin_uid)) {
        chatWithAdmin = chatId;
        break;
      }
    }
    if (!chatWithAdmin) {
      chatWithAdmin = createNewChatAndUser(
        user_uid,
        db,
        role,
        name,
        avatarUrl,
        email,
        admin_uid,
      );
    }
  } else {
    chatWithAdmin = createNewChatAndUser(
      user_uid,
      db,
      role,
      name,
      avatarUrl,
      email,
      admin_uid,
    );
  }
  await updateMyDetails(user_uid, db, name, avatarUrl, email);
  return chatWithAdmin;
}

export async function getOrCreateChatWithUser(
  current_user_uid: string,
  current_user_role: string,
  current_name: string,
  current_avatarUrl: string,
  current_email: string,
  to_user_uid: string,
  to_user_role: string,
  to_name: string,
  to_avatarUrl: string,
  to_email: string,
  proposal_uid: string,
  db: Firestore,
) {
  if (!current_user_uid || !to_user_uid) {
    return null;
  }
  let chatWithUser;
  const currentUser = await getDoc(doc(db, 'users', current_user_uid));
  const currentUserChats = currentUser.data().chats;
  for (const chatId of currentUserChats) {
    const chat = await getDoc(doc(db, 'chats', chatId));
    if (!chat.exists()) {
      continue;
    }
    const data = chat.data();
    if (data.users.includes(to_user_uid)) {
      if (data.proposal_uid === proposal_uid) {
        chatWithUser = chatId;
        break;
      }
    }
  }
  if (!chatWithUser) {
    // Create new chat
    const docRefCreated = await addDoc(collection(db, 'chats'), {
      messages: [],
      users: [current_user_uid, to_user_uid],
      proposal_uid: proposal_uid,
    });
    chatWithUser = docRefCreated.id;
    await addChatToUserChats(
      current_user_uid,
      db,
      docRefCreated.id,
      current_user_role,
      current_name,
      current_avatarUrl,
      current_email,
    );
    await addChatToUserChats(
      to_user_uid,
      db,
      docRefCreated.id,
      to_user_role,
      to_name,
      to_avatarUrl,
      to_email,
    );
  }
  return chatWithUser;
}

export async function getOrCreateChatWithUserWithoutDb(
  current_user_uid: string,
  current_user_role: string,
  current_name: string,
  current_avatarUrl: string,
  current_email: string,
  to_user_uid: string,
  to_user_role: string,
  to_name: string,
  to_avatarUrl: string,
  to_email: string,
  proposal_uid: string,
) {
  const firebaseConfig = getFirebaseConfig();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  await getOrCreateChatWithUser(
    current_user_uid,
    current_user_role,
    current_name,
    current_avatarUrl,
    current_email,
    to_user_uid,
    to_user_role,
    to_name,
    to_avatarUrl,
    to_email,
    proposal_uid,
    db,
  );
}

export async function countUnreadConversations(
  current_user_uid: string,
  db: Firestore,
) {
  let count = 0;
  if (current_user_uid === 'admin') {
    const qAdminChats = query(
      collection(db, 'chats'),
      where('proposal_uid', '==', 'admin'),
      where('messages', '!=', []),
    );
    const adminChats = await getDocs(qAdminChats);
    for (const chatDoc of adminChats.docs) {
      if (!chatDoc.exists()) {
        continue;
      }
      const data = chatDoc.data();
      const admin_uid = await findAdminUidFromChats(db);
      if (
        data.messages.length > 0 &&
        !data.seen &&
        data.messages[data.messages.length - 1].sent_by !== admin_uid
      ) {
        count++;
      }
    }
  } else {
    const currentUser = await getDoc(doc(db, 'users', current_user_uid));
    if (!currentUser.exists()) {
      return 0;
    }
    const currentUserChats = currentUser.data().chats;
    for (const chatId of currentUserChats) {
      const chat = await getDoc(doc(db, 'chats', chatId));
      if (chat.exists()) {
        const data = chat.data();
        if (
          data.messages.length > 0 &&
          !data.seen &&
          data.messages[data.messages.length - 1].sent_by !== current_user_uid
        ) {
          count++;
        }
      }
    }
  }
  return count;
}

export async function countUnreadConversationsByType(
  current_user_uid: string,
  db: Firestore,
): Promise<{ countCompany: number; countStudent: number }> {
  let countCompany = 0;
  let countStudent = 0;
  const qAdminChats = query(
    collection(db, 'chats'),
    where('proposal_uid', '==', 'admin'),
    where('messages', '!=', []),
  );
  const adminChats = await getDocs(qAdminChats);
  for (const chatDoc of adminChats.docs) {
    if (!chatDoc.exists()) {
      continue;
    }
    const data = chatDoc.data();
    const otherUserId = data.users.filter(
      (user: string) => user !== current_user_uid,
    )[0];
    const otherUser = await getDoc(doc(db, 'users', otherUserId));
    if (!otherUser.exists()) {
      continue;
    }
    if (
      data.messages.length > 0 &&
      !data.seen &&
      data.messages[data.messages.length - 1].sent_by !== current_user_uid
    ) {
      if (otherUser.data().role === 'student') {
        countStudent++;
      } else {
        countCompany++;
      }
    }
  }
  return { countCompany, countStudent };
}
