import React, { useEffect, useState, useMemo } from 'react';
import './AdminJobs.scss';
import '../AdminGeneralStyles.scss';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import { useFetchIndustries } from '../../../data/edit-profile';
import { MyJobsListItem } from '../../../data/jobs';
import JobStatusChip from '../../job-details/components/JobStatusChip';
import { Link } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import { useJobStatistic, useSearchAdminJobs } from '../../../data/admin-jobs';
import { budgetString } from '../../job-details';
import { formatDate } from '../../../utils/dates';
import { useDebounce } from '../../../utils/useDebounce';
import { useFetchLangsAndSkills } from '../../../data/shared';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import ResizableTable from '../../../components/resizable-table';

const jobTypes = {
  'one-time': 'One Time',
  ongoing: 'Ongoing',
  'one-time-premium': 'One Time Premium',
  direct: 'Direct job',
};

export const jobTypeIsOrigin = {
  1: 'Original',
  0: 'Duplicate',
};

const getStatisticCards = (statisticsInfo) => {
  const {
    jobs_pending_approval,
    jobs_open,
    jobs_in_progress,
    jobs_interviewing,
  } = statisticsInfo;
  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'approval'}
        text={'Jobs pending approval'}
        counter={jobs_pending_approval || 0}
      />
      <AdminStatisticCard
        key={'open'}
        text={'Jobs open'}
        counter={jobs_open || 0}
      />
      <AdminStatisticCard
        key={'progress'}
        text={'Jobs in progress'}
        counter={jobs_in_progress || 0}
      />
      <AdminStatisticCard
        key={'interview'}
        text={'Jobs interviewing'}
        counter={jobs_interviewing || 0}
      />
    </div>
  );
};

const AdminJobs = () => {
  const [values, setValues] = useState({
    languages: undefined,
    skills: undefined,
  });
  const [search, setSearch] = useState('');
  const { data: industries = [] } = useFetchIndustries();
  const { data: statistics = {} } = useJobStatistic();
  const [{ data: languages = [] }, { data: skills = [] }] =
    useFetchLangsAndSkills();

  useDocumentTitle('Admin All Jobs');

  const getJobColumns = useMemo(() => {
    return [
      {
        accessorKey: 'created_at',
        header: 'Created On',
        enableSorting: true,
      },
      {
        accessorKey: 'job_status',
        header: 'Status',
        enableSorting: true,
        Cell: ({ renderedCellValue }) => (
          <JobStatusChip jobStatus={renderedCellValue} />
        ),
      },
      {
        accessorKey: 'job_title',
        header: 'Title',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminJobsRoute}/${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'job_type',
        header: 'Type of Job',
        enableSorting: true,
        Cell: ({ renderedCellValue }) => <>{jobTypes[renderedCellValue]}</>,
      },
      {
        accessorKey: 'parent_job_uid',
        header: 'Type',
        enableSorting: true,
        Cell: (param) => (
          <>{jobTypeIsOrigin[Number(!param.row.original.parent_job_uid)]}</>
        ),
      },
      {
        accessorKey: 'company_name',
        header: 'Company',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminCompaniesRoute}/${row.original.company_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'budget',
        header: 'Rate',
        enableSorting: true,
      },
      {
        accessorKey: 'proposals_count',
        header: 'Proposals',
        enableSorting: true,
      },
    ];
  }, []);

  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
  });

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: jobsData,
    refetch: refetchJobsDate,
    isLoading,
  } = useSearchAdminJobs({ ...filters, query: debouncedSearch });

  const { pagination, jobs } = jobsData || {};

  const industriesOptions = industries?.map((item) => ({
    label: item.interest_name,
    id: item.interest_uid,
  }));

  useEffect(() => {
    const obj = {
      languages: values.languages || [],
      skills: values.skills || [],
    };

    if (!obj.languages.length) {
      obj.languages = languages.map((item: any) => {
        return {
          label: item.language_name,
          id: item.language_uid,
        };
      });
    }

    if (!obj.skills.length) {
      obj.skills = skills.map((item: any) => {
        return {
          label: item.skill_name,
          id: item.skill_uid,
        };
      });
    }

    setValues({ ...obj });
  }, [languages, skills]);

  const myJobsGridData = (): unknown[] =>
    jobs?.map((job: MyJobsListItem) => {
      return {
        id: job.job_uid,
        job_status: job.job_status,
        job_title: job.job_title || 'Draft',
        created_at: formatDate(job.created_at, 'DD MMMM YYYY'),
        budget: job.direct_job ? '-' : budgetString(job.budget, job.job_type),
        proposals_count: job.proposals_count,
        company_name: job.company.company_name,
        company_uid: job.company.company_uid,
        job_type: job.direct_job ? 'direct' : job.job_type,
        parent_job_uid: job.parent_job_uid,
      };
    });

  const refetchJobs = async () => {
    await refetchJobsDate();
  };
  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  useEffect(() => {
    refetchJobs();
  }, [filters]);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-jobs-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Jobs</p>

      <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
        {getStatisticCards(statistics)}
      </Grid>

      <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
        <div>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to={`${Routes.AdminOpenJobsRoute}`}
            title={'Go to open jobs'}
          >
            Go to open jobs overview →
          </Link>
        </div>
      </Grid>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All jobs</p>
          <div className="admin-table-inputs jobs-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-jobs-input"
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />

            <FiltersPanel
              values={{ ...values, industries: industriesOptions }}
              page="admin-jobs"
              filters={filters}
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;

                setFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <ResizableTable
          pageSize={filters.per_page}
          columns={getJobColumns}
          rows={myJobsGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminJobs;
