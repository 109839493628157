import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  FormGroup,
  Grid,
  SwipeableDrawer,
} from '@mui/material';

import './Filter.scss';
import { useForm } from 'react-hook-form';
import FilterListIcon from '@mui/icons-material/FilterList';
import AutocompleteField from './AutocompleteField';
import MinMax from './MinMax';
import CheckboxFilter from './Checkbox';
import { filterFields } from './filterFields';
import DatepickerFilter from './DatepickerFilter';
import { formatDate } from '../../utils/dates';

const getUidArray = (data) => {
  return data.map((item) => item.id);
};

const getValueIfTrue = (data, isValue = false) => {
  const result = [];

  for (const key in data) {
    if (data[key] && !isValue) {
      result.push(key);
    } else if (isValue) {
      let val = data[key];

      if (data[key] && key === 'No promo') {
        val = false;
      }

      if (data[key]) {
        result.push(val);
      }
    }
  }

  return result;
};

interface IProps {
  values?: any;
  page: string;
  changeFilters?: (arg: any) => void;
  filters: any;
}

const isEmptyValue = (obj, key, values) => {
  if (values?.length) {
    obj[key] = values;
  }
};

const isCheckboxBoolean = {
  referral: true,
  ambassador: true,
  top_talent: true,
};

const getAutocompleteValues = (values, selectedValues) => {
  const result = [];

  for (const key in values) {
    if (result.length < selectedValues.length) {
      const { id } = values[key];

      if (selectedValues.includes(id || values[key])) {
        result.push(values[key]);
      }
    } else {
      break;
    }
  }

  return result;
};

const getDateValue = (date) => {
  return new Date(('01/' + date).split('/').reverse().join('-'));
};

const FiltersPanel = ({ values, page, changeFilters, filters }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [showMoreObj, setShowMoreObj] = useState({});
  const filterValue = filterFields[page] || [];

  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
  });

  const setShowMore = (item: string, value: boolean) => {
    setShowMoreObj({
      ...showMoreObj,
      [item]: value,
    });
  };

  const getShowMore = (item: string) => {
    if (!(item in showMoreObj)) {
      return false;
    }
    return showMoreObj[item];
  };

  const generateEmptyValues = (isFilters?: boolean) => {
    const checkbox = {};
    const defaultObj = {};

    filterValue.forEach((filter) => {
      const { type, values: filterValues, key } = filter;

      if (type === 'minMax') {
        let value1 = '';
        let value2 = '';

        if (!isFilters) {
          value1 = filters?.[key[0]] || '';
          value2 = filters?.[key[1]] || '';
        }

        defaultObj[key[0]] = value1;
        defaultObj[key[1]] = value2;
      } else if (type === 'checkbox' && Array.isArray(filterValues)) {
        checkbox[key] = filterValues.reduce((acc, val) => {
          let value = false;

          if (!isFilters) {
            value = checkboxValues?.[key]?.[val.id || val] || value;
          }

          acc[val.id || val] = value;

          return acc;
        }, {});
      } else if (type === 'datepicker') {
        const filterValue1 = filters?.[key[0]];
        const filterValue2 = filters?.[key[1]];

        let value1 = null;
        let value2 = null;

        if (!isFilters) {
          value1 = filterValue1 || value1;
          value2 = filterValue2 || value2;

          if (filterValue1 && filterValue1.split('/').length === 2) {
            value1 = getDateValue(filterValue1);
          }
          if (filterValue2 && filterValue2.split('/').length === 2) {
            value2 = getDateValue(filterValue2);
          }
        }

        defaultObj[key[0]] = value1;
        defaultObj[key[1]] = value2;
      } else {
        let value = [];

        if (!isFilters) {
          const isString = typeof filterValues === 'string';
          const keyValues = isString ? filterValues : key;

          if (isString) {
            value = filters?.[key]
              ? getAutocompleteValues(values[keyValues], filters?.[key])
              : value;
          } else {
            value = filters?.[key] || value;
          }
        }

        defaultObj[key] = value;
      }
    });

    setDefaultValues(defaultObj);
    setCheckboxValues(checkbox);
    reset(defaultObj);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      generateEmptyValues();
    }
  };

  const onSubmit = (data) => {
    const info = { ...data, ...checkboxValues };
    const result = {};

    for (const key in info) {
      const dateType = /_date|_month/.test(key);
      const minMax = !dateType && /_min|_max/.test(key);
      const uid = /_uid/.test(key);
      const isBooleanValue = /_promo/.test(key);
      const others = /_type|_status|_completed_reason|_location/.test(key);
      const verified = 'verified' === key;
      const user_actvity = 'user_activity' === key;
      const isCheckbox = isCheckboxBoolean[key];

      switch (true) {
        case others:
          isEmptyValue(result, key, getValueIfTrue(info[key]));
          break;
        case isBooleanValue:
          isEmptyValue(result, key, getValueIfTrue(info[key], true));
          break;
        case uid:
          isEmptyValue(result, key, getUidArray(info[key]));
          break;
        case minMax:
          if (info[key]) {
            result[key] = +info[key];
          }
          break;
        case dateType:
          result[key] = formatDate(info[key], 'YYYY-MM-DD');
          break;
        case verified:
          for (const keyVer in info[key]) {
            const keyLowerCase = keyVer.toLowerCase();

            if (keyLowerCase.includes('verified')) {
              if (info[key][keyVer]) {
                if (!result[key]) {
                  result[key] = [];
                }

                result[key].push(keyLowerCase === 'verified');
              }
            } else {
              if (info[key][keyVer]) {
                if (!result['student_type']) {
                  result['student_type'] = [];
                }

                result['student_type'].push(keyVer);
              }
            }
          }
          break;
        case user_actvity:
          const params = [];
          for (const activity in info[key]) {
            if (info[key][activity]) params.push(activity);
          }
          result[key] = params;
          break;
        case isCheckbox:
          if (info[key][key]) {
            console.log(info[key][key]);
            result[key] = true;
          }
          break;
        default:
          if (info[key]?.length) {
            result[key] = info[key];
          }
          break;
      }
    }

    changeFilters(result);
    toggleDrawer();
  };

  const clearFilters = () => {
    generateEmptyValues(true);
  };

  const handleChangeCheckbox = (e: any, label: string) => {
    const { name, checked } = e.target;
    const copy = structuredClone(checkboxValues);
    const result = copy[label] || {};

    if (!result[name]) {
      result[name] = {};
    }

    result[name] = checked;
    copy[label] = result;

    setCheckboxValues({ ...copy });
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: '#DEB900',
          borderRadius: '4px',
          width: '86px',
          height: '30px',
          cursor: 'pointer',
        }}
        className={`admin-filter-table-button filter-students-button`}
        variant="contained"
        endIcon={<FilterListIcon />}
        onClick={toggleDrawer}
      >
        Filter
      </Button>
      <SwipeableDrawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className="filter-swipeable-drawer"
      >
        <Box className="filter-sidebar">
          <Grid container direction="row" justifyContent="space-between">
            <p className="title">Filter</p>
            <ButtonBase onClick={clearFilters} className="clearFilters">
              Clear all
            </ButtonBase>
          </Grid>
          <div className="contacts-list">
            <form>
              {filterValue.map((item: any, index) => {
                const { label, type, key } = item;
                return (
                  <div key={index} className="filter-wrapper">
                    <div className="label">{label}</div>
                    <div>
                      {type === 'checkbox' ? (
                        <FormGroup>
                          {item.values.map((checkboxName, i) => {
                            if (
                              (i <= 3 && !getShowMore(label)) ||
                              getShowMore(label)
                            ) {
                              return (
                                <CheckboxFilter
                                  key={i}
                                  checkboxName={
                                    checkboxName.label || checkboxName
                                  }
                                  isChecked={
                                    checkboxValues[key]?.[
                                      checkboxName.id || checkboxName
                                    ]
                                  }
                                  id={checkboxName.id || checkboxName}
                                  label={key}
                                  onChange={handleChangeCheckbox}
                                />
                              );
                            }

                            return null;
                          })}
                          {item.values.length > 4 && (
                            <ButtonBase
                              onClick={() =>
                                setShowMore(label, !getShowMore(label))
                              }
                              className="clearFilters"
                            >
                              {getShowMore(label)
                                ? 'Show less'
                                : `Show more(${item.values.length - 3})`}
                            </ButtonBase>
                          )}
                        </FormGroup>
                      ) : type === 'minMax' ? (
                        <MinMax
                          control={control}
                          min={defaultValues[key[0]]}
                          max={defaultValues[key[1]]}
                          names={key}
                          values={item.values}
                        />
                      ) : type === 'datepicker' ? (
                        <DatepickerFilter
                          control={control}
                          name={key}
                          min={defaultValues[key[0]]}
                          max={defaultValues[key[1]]}
                          calendarSetup={item.calendarSetup}
                        />
                      ) : (
                        <AutocompleteField
                          control={control}
                          keyValue={key}
                          defaultValue={defaultValues[key]}
                          options={values[item.values] || item.values}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </form>
            <Button
              variant="contained"
              className="apply-button"
              onClick={handleSubmit(onSubmit)}
            >
              Apply filters
            </Button>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};
export default FiltersPanel;
