import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { IOption } from '../../utils/ioption';
import { styled } from '@mui/system';

const StyledChip = styled(Chip)({
  tag: {
    backgroundColor: '#DEB900',
  },
});

const AutocompleteField = ({
  control,
  keyValue,
  options,
  defaultValue,
}: {
  control: any;
  options: any;
  defaultValue: string;
  keyValue: string;
}) => {
  return (
    <Controller
      control={control}
      name={keyValue}
      defaultValue={defaultValue}
      render={({ field, field: { onChange, value } }) => (
        <Autocomplete
          multiple
          id={`${keyValue}-select`}
          limitTags={5}
          {...field}
          options={options || []}
          onChange={(_, data) => onChange(data)}
          value={value}
          getOptionLabel={(option: IOption) => option?.label || String(option)}
          isOptionEqualToValue={(option, value) =>
            option.id ? option.id === value.id : option === value
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <StyledChip
                variant="outlined"
                label={`${option?.label || option}`}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      )}
    />
  );
};

export default AutocompleteField;
