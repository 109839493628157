import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ReferFriend = ({
  title,
  content,
  ref_code,
}: {
  title?: string;
  content: string;
  ref_code: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="dashboard-refer-card"
      onClick={() => navigate(generatePath(Routes.ReferFriend))}
    >
      {title && <p className="ref-card-title">{title}</p>}
      <p className="ref-card-text">
        {content}{' '}
        <span className="ref-code">
          {window.location.origin}?ref={ref_code}
        </span>{' '}
        <ContentCopyIcon
          onClick={(e) => {
            e.stopPropagation();

            navigator.clipboard.writeText(
              `${window.location.origin}?ref=${ref_code}`,
            );
          }}
          className="copy-ref"
        />
      </p>
    </div>
  );
};

export default ReferFriend;
