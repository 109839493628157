import React, { useEffect, useState } from 'react';
import { SidePanel } from './sidepanel/SidePanel';
import { useLocation } from 'react-router-dom';
import { Routes } from '../utils/consts';
import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import MiniteSnackbar from './snackbar';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../stores/auth';

const Layout = (props: any) => {
  const userData = useUserData((state) => state.user);
  const [auth, isAuth] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();

  useEffect(() => {
    isAuth(Boolean(userData?.user_uid));
  }, [userData, auth]);

  const showSidePanel =
    auth &&
    !location.pathname.includes(Routes.JobPostRoute) &&
    location.pathname !== Routes.JobMatchesRoute &&
    !location.pathname.includes('matches');

  return (
    <Box sx={{ flex: '1 1 100%', display: 'flex', flexDirection: 'column' }}>
      <Grid container className="main-container">
        {showSidePanel && !isMobile && (
          <Grid item lg={2} xl={2} md={2} sm={2} xs={0}>
            <SidePanel id="permanent-sidepanel" userType={userData?.type} />
          </Grid>
        )}

        <Grid
          item
          md={showSidePanel ? 10 : 12}
          sm={showSidePanel ? 10 : 12}
          xs={12}
        >
          <Box
            sx={{
              width: '100%',
              margin: '32px auto',
              height: '100%',
              padding: isMobile ? '0 16px 0 16px' : '0 32px 0 48px',
            }}
          >
            <main className="content-section">{props.children}</main>
          </Box>
        </Grid>
      </Grid>

      <MiniteSnackbar
        message={t('general.email_already_signed_up')}
        open={props.isShowError}
        onClose={() => props.setIsShowError(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        autoHideDuration={6000}
        cancelOffsetIfNotAtTop={true}
      />
    </Box>
  );
};

export default Layout;
