import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TIMESHEET_DETAILS } from './query-keys';
import { AxiosError } from 'axios';
import {
  DeleteTimeEntry,
  TimeEntry,
  TimesheetStatus,
  UpdateTimeEntry,
} from './timesheets';

const timesheetDetails = async (job_uid: string, student_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/students/${student_uid}/timesheets`,
    method: 'get',
  });

  return data.data.timesheets;
};

export const useTimesheetDetails = (job_uid: string, student_uid: string) => {
  return useQuery(
    [TIMESHEET_DETAILS, job_uid, student_uid],
    () => timesheetDetails(job_uid, student_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const rejectJobTimesheetMonth = async (
  jobUid: string,
  timesheetUid: string,
  rejectReason: string,
) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${jobUid}/timesheets/${timesheetUid}/reject`,
    method: 'post',
    data: { reject_reason: rejectReason },
  });
  if (!data.success)
    throw new Error('update job details failed, code: ' + data.code);
};

export const useRejectJobTimesheetMonth = (
  jobUid: string,
  timesheetUid: string,
) => {
  return useMutation(
    (rejectReason: string) =>
      rejectJobTimesheetMonth(jobUid, timesheetUid, rejectReason),
    {
      onError: (e) => console.error(e),
    },
  );
};

const submitJobTimesheetMonth = async (
  job_uid: string,
  timesheet_uid: string,
) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/timesheets/${timesheet_uid}/submit`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('update job details failed, code: ' + data.code);
};

export const useSubmitJobTimesheetMonth = () => {
  return useMutation(
    (payload: any) =>
      submitJobTimesheetMonth(payload.job_uid, payload.timesheet_uid),
    {
      onError: (e) => console.error(e),
    },
  );
};

const deleteJobTimeEntry = async (job_uid: string, time_entry_uid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/time_entries/${time_entry_uid}`,
    method: 'delete',
  });

  if (!data.success)
    throw new Error('time entry delete failed, code: ' + data.code);
};

export function useDeleteJobTimeEntry() {
  return useMutation(
    (payload: DeleteTimeEntry) =>
      deleteJobTimeEntry(payload?.job_uid, payload?.time_entry_uid),
    {
      onError: (e) => console.error(e),
    },
  );
}

const updateJobTimeEntry = async (
  job_uid: string,
  time_entry_uid: string,
  payload: TimeEntry,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/time_entries/${time_entry_uid}`,
    method: 'put',
    data: payload,
  });

  if (!data.success)
    throw new Error('time entry update failed, code: ' + data.code);
};

export function useUpdateJobTimeEntry(job_uid: string) {
  return useMutation(
    (payload: UpdateTimeEntry) =>
      updateJobTimeEntry(job_uid, payload?.time_entry_uid, payload.time_entry),
    {
      onError: (e) => console.error(e),
    },
  );
}

const createEntry = async ({
  job_uid,
  student_uid,
  year,
  month,
  time_entry,
}: {
  job_uid: string;
  student_uid: string;
  year: string;
  month: string;
  day: string;
  time_entry: TimeEntry;
}) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${job_uid}/students/${student_uid}/time_entries/${year}/${month}/${time_entry.day}`,
    method: 'post',
    data: time_entry,
  });
  if (!data.success)
    throw new Error('update job details failed, code: ' + data.code);
};

export const useCreateTimeEntry = () => {
  return useMutation((payload: any) => createEntry(payload), {
    onError: (e) => console.error(e),
  });
};

const approveJobTimesheetMonth = async (
  jobUid: string,
  timesheetUid: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/jobs/${jobUid}/timesheets/${timesheetUid}/approve`,
    method: 'post',
  });

  if (!data.success)
    throw new Error('timesheet approve failed, code: ' + data.code);
};

export function useApproveJobTimesheetMonth(
  jobUid: string,
  timesheetUid: string,
) {
  return useMutation(() => approveJobTimesheetMonth(jobUid, timesheetUid), {
    onError: (e) => console.error(e),
  });
}

const changeTimesheetStatus = async (
  timesheetUid: string,
  body: TimesheetChangeStatus,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/timesheets/${timesheetUid}`,
    method: 'put',
    data: body,
  });

  if (!data.success)
    throw new Error('timesheet approve failed, code: ' + data.code);
};

interface TimesheetChangeStatus {
  timesheet: { timesheet_status: TimesheetStatus };
}

export function useChangeTimesheetStatus(timesheetUid: string) {
  return useMutation(
    (payload: TimesheetChangeStatus) =>
      changeTimesheetStatus(timesheetUid, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

export interface TimesheetInvoiceLinks {
  admin_fee_invoice_url?: string | null;
  service_fee_invoice_url?: string | null;
  student_to_company_invoice_url?: string | null;
  student_collective_invoice_url?: string | null;
  company_collective_invoice_url?: string | null;
}

export interface TimesheetInvoiceMapping {
  timesheet_uid: string;
  links: TimesheetInvoiceLinks;
}

export interface TimesheetErrorMapping {
  timesheet_uid: string;
  error: string;
}

export const fetchTimesheetInvoices = async (timesheetUid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/timesheets/${timesheetUid}/invoices`,
    method: 'GET',
  });

  return data.data as TimesheetInvoiceLinks;
};

const createTimesheetInvoicesOne = async (
  timesheet_uid: string,
  body: CreateTimesheetInvoicesPayload,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/timesheets/${timesheet_uid}/create-invoices`,
    method: 'POST',
    data: body,
  });

  return data;
};

const setInvoicePaid = async (
  timesheet_uid: string,
  body: SetInvoicePaidPayload,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/timesheets/${timesheet_uid}/invoices`,
    method: 'PUT',
    data: body,
  });

  return data;
};

interface CreateTimesheetInvoicesPayload {
  send: boolean;
}

interface SetInvoicePaidPayload {
  invoice_paid: boolean;
}

export function useCreateTimesheetInvoicesOne(timesheet_uid: string) {
  return useMutation(
    (payload: CreateTimesheetInvoicesPayload) =>
      createTimesheetInvoicesOne(timesheet_uid, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

export function useSetInvoicePaid(timesheet_uid: string) {
  return useMutation(
    (payload: SetInvoicePaidPayload) => setInvoicePaid(timesheet_uid, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

const createTimesheetInvoicesMany = async (
  body: CreateTimesheetInvoicesPayload,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/timesheets/create-invoices`,
    method: 'POST',
    data: body,
  });

  return data;
};

interface CreateTimesheetInvoicesPayload {
  send: boolean;
}

export function useCreateTimesheetInvoicesMany() {
  return useMutation(
    (payload: CreateTimesheetInvoicesPayload) =>
      createTimesheetInvoicesMany(payload),
    {
      onError: (e) => console.error(e),
    },
  );
}
