import { createTheme } from '@mui/material/styles';

export const MiniteTheme = createTheme({
  palette: {
    primary: {
      main: '#393A42',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#DEB900',
              color: '#393A42',
              width: '100%',
              '&:hover': {
                backgroundColor: '#DEB900',
              },
            }),
        }),
      },
    },
  },
});
