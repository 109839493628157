import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ADMIN_ANNOUNCEMENTS } from './query-keys';

const announcementDetails = async (key: string) => {
  const { data } = await request<ServerResponse<AnnouncementDetails>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/admin_announcements/${key}`,
    method: 'get',
  });

  return data.data;
};

export const useAnnouncementDetails = (key: string) => {
  return useQuery([ADMIN_ANNOUNCEMENTS, key], () => announcementDetails(key), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const updateAnnouncement = async (key: string, body: AnnouncementUpdate) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/admin_announcements/${key}`,
    method: 'put',
    data: body,
  });

  if (!data.success)
    throw new Error('announcement approve failed, code: ' + data.code);
};

export function useUpdateAnnouncement(key: string) {
  return useMutation(
    (payload: AnnouncementUpdate) => updateAnnouncement(key, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

export interface AnnouncementDetails {
  admin_announcement_uid: string;
  key: string;
  value: string;
  url: string;
  visible: boolean;
  visible_for: string;
}

interface AnnouncementUpdate {
  announcement: {
    key?: string;
    value: string;
    url?: string;
    visible?: boolean;
    visible_for?: string;
  };
}
