import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { TablePagination } from '@mui/material';
import './EventLogsTable.scss';

interface Props {
  columns: any;
  rows: any;
  pageSize: number;
  onPageChange: (page: number) => void;
  onSortChange: (sort: { order_by: string; asc: boolean }) => void;
  page: number;
  rowCount: number;
  isLoading: boolean;
  onRowClick?: (data: unknown) => void;
  hideColumn?: { [key: string]: boolean };
  enableExpanding?: boolean;
}

const EventLogsTable = ({
  columns,
  rows,
  pageSize,
  onPageChange,
  page,
  rowCount,
  isLoading,
  onSortChange,
  onRowClick,
  hideColumn,
  enableExpanding = false,
}: Props) => {
  const [sorting, setSorting] = useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  useEffect(() => {
    const { id, desc } = sorting[0] || {};

    onSortChange({
      order_by: id,
      asc: !desc,
    });
  }, [sorting]);

  return (
    <div className="resizable-table-wrapper">
      <MaterialReactTable
        renderDetailPanel={({ row }) => {
          const original = row.original as any;
          return original.details;
        }}
        columns={columns}
        data={rows || []}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 150,
        }}
        manualSorting
        state={{
          sorting,
          isLoading,
          pagination: { pageIndex: 0, pageSize },
          columnVisibility: hideColumn,
        }}
        initialState={{
          sorting: [
            {
              id: 'created_at',
              desc: true,
            },
          ],
          columnVisibility: hideColumn,
        }}
        onSortingChange={setSorting}
        enableColumnResizing
        enableColumnActions={false}
        enableColumnFilters={false}
        enableBottomToolbar={false}
        enableExpanding={enableExpanding}
        enableTopToolbar={false}
        columnResizeMode="onChange"
        rowCount={rowCount}
        getRowId={(row: { id: string }) => row.id}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => (onRowClick ? onRowClick(row) : null),
          sx: { cursor: 'pointer' },
        })}
      />
      <TablePagination
        component="div"
        count={rowCount || 0}
        rowsPerPage={pageSize || 15}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </div>
  );
};

export default EventLogsTable;
