import React, { useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import { useResetPasswordByAdmin } from '../../data/admin';
import './ResetPassword.scss';
import MiniteSnackbar from '../snackbar';

const ResetPassword = ({ userUid }: { userUid: string }) => {
  const { mutateAsync: resetPasswordByAdmin, isSuccess } =
    useResetPasswordByAdmin(userUid);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async () => {
    try {
      setIsLoading(true);
      await resetPasswordByAdmin();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Skeleton sx={{ height: 50, width: 133 }} />
        ) : (
          <Button
            onClick={resetPassword}
            id="reset-password-button"
            disabled={isSuccess}
          >
            {isSuccess ? 'Password reset' : 'Reset Password'}
          </Button>
        )}
      </div>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />{' '}
    </>
  );
};

export default ResetPassword;
