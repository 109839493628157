import React from 'react';
import { Button } from '@mui/material';

const HighlightProfile = ({
  onSubmit,
  isHighlighted,
  isLoading,
}: {
  onSubmit: () => void;
  isHighlighted: boolean;
  isLoading: boolean;
}) => {
  return (
    <div className="highlight-student-wrapper">
      <Button
        onClick={onSubmit}
        id="highlight-student-button"
        data-testid={`${isHighlighted ? 'no-highlight' : 'highlight'}-admin`}
        disabled={isLoading}
      >
        {isHighlighted ? 'Stop Highlight' : 'Highlight'} student
      </Button>
    </div>
  );
};

export default HighlightProfile;
