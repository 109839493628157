import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ADMIN_DASHBOARD_PROPOSAL } from './query-keys';
import { ProposalStatus } from './jobs';

const fetchAdminProposals = async (body?: ProposalSearchRequest) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/proposals/search`,
    method: 'post',
    data: body,
  });

  return data.data.proposals;
};

export const useFetchAdminProposals = (body?: ProposalSearchRequest) => {
  return useQuery(
    [ADMIN_DASHBOARD_PROPOSAL, body],
    () => fetchAdminProposals(body),
    {
      onError: (e) => console.error(e),
      select: (data) => data,
    },
  );
};

const updateAdminProposalStatus = async (
  proposalId: string,
  status: string,
) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/proposals/${proposalId}`,
    method: 'put',
    data: { proposal_status: status },
  });
  if (!data.success)
    throw new Error('update job status failed, code: ' + data.code);
};

export const useUpdateAdminProposalStatus = () => {
  return useMutation(
    (payload: any) =>
      updateAdminProposalStatus(payload.proposalId, payload.status),
    {
      onError: (e) => console.error(e),
    },
  );
};

export interface ProposalSearchRequest {
  query?: string;
  proposal_status?: ProposalStatus[];
  order_by?: string;
  asc?: boolean;
}
