import React, { useEffect, useState } from 'react';
import { IOption, isOptionEqualToValue } from '../../../../../utils/ioption';
import { useUpdateUserProfileByAdmin } from '../../../../../data/user';
import { useFetchIndustries } from '../../../../../data/edit-profile';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';

const schema = yup
  .object()
  .shape({
    industry: yup.object(),
  })
  .required();

interface IndustryFormInterface {
  industry: IOption;
}

const AdminCompanyIndustry = ({ details, refetch }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    details?.user?.user_uid,
  );
  const { data: industries = [] } = useFetchIndustries();

  const industriesOptions = industries?.map((item) => ({
    label: item.interest_name,
    id: item.interest_uid,
  }));

  const defaultValue = industriesOptions?.find((item) => {
    return item?.id === details?.interest_uid;
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.company',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (industries.length === 0) {
      (async () => {
        await refetch();
      })();
    }
  }, [industries]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid, isDirty, isSubmitted },
  } = useForm<IndustryFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      industry: {
        ...defaultValue,
      },
    },
    reValidateMode: 'onChange',
  });

  if (defaultValue?.id && !getValues('industry')?.label) {
    setValue('industry', defaultValue);
  }

  const onSubmit = async (data: IndustryFormInterface) => {
    try {
      setIsLoading(true);
      const interest_uid = data?.industry?.id;
      await updateUserProfile({
        company: {
          interest_uid,
        },
      });

      await refetch();

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-title account-financial-forms-titles">
            {t('field_of_work')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <FormControl
              variant="standard"
              sx={{ width: '100%', marginBottom: '16px' }}
            >
              <Controller
                control={control}
                name="industry"
                render={({ field, field: { value, onChange } }) => (
                  <Autocomplete
                    id="interests-select"
                    options={industriesOptions}
                    value={value}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={(option: IOption) => option?.label || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label={t('industry')}
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-company-industry-admin"
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};
export default AdminCompanyIndustry;
