import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { INVOICES } from './query-keys';
import { AxiosError } from 'axios';
import { Search } from 'history';

const fetchInvoices = async (body?: InvoicesSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/invoices`,
    method: 'post',
    data: body,
  });

  return data.data as unknown as InvoicesSearchResponse;
};

export const useFetchInvoices = (body: InvoicesSearchRequest) => {
  return useQuery([INVOICES, body], () => fetchInvoices(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface InvoicesSearchRequest {
  page?: number;
  per_page?: number;
}

export interface InvoicesSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  invoices: InvoicesResponse[];
}

export interface InvoicesResponse {
  invoice_uid: string;
  invoice_number: number;
  invoice_paid: boolean;
  created_at: Date;

  job_title: string;

  url: string;
  stripe_payment_intent_id?: string;
}
