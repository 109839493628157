import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { IOption } from '../../../utils/ioption';
import { LoadingButton } from '../../../components/LoadingButton';
import { styled } from '@mui/system';

interface IProps {
  onSave: (arg: unknown) => void;
  buttonText: string;
  label: string;
  title: string;
  key_name: string;
  isLoading?: boolean;
  options: any;
}

const StyledChip = styled(Chip)({
  tag: {
    backgroundColor: '#DEB900',
  },
});

const RemoveElement = ({
  title,
  options,
  key_name,
  isLoading,
  onSave,
}: IProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = useForm<{ values: any }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      values: [],
    },
  });

  const onSubmit = (data) => {
    onSave(data.values);
    reset();
  };

  return (
    <div>
      <p>{title}</p>
      <form className="edits-form">
        <Controller
          control={control}
          render={({ field, field: { onChange, value } }) => (
            <Autocomplete
              multiple
              id={`${key_name}-select`}
              limitTags={5}
              {...field}
              options={options || []}
              onChange={(_, data) => onChange(data)}
              value={value}
              getOptionLabel={(option: IOption) =>
                option?.label || String(option)
              }
              isOptionEqualToValue={(option, value) =>
                option.id ? option.id === value.id : option === value
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <StyledChip
                    variant="outlined"
                    label={`${option?.label || option}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          )}
          name="values"
        />
      </form>
      <div className="save-button-container">
        <LoadingButton
          isDisabled={!isValid || !isDirty}
          className="save-button"
          onClick={handleSubmit(onSubmit)}
          buttonText="Save"
          isLoading={isLoading}
          confirmButtonTestId={`save-remove-${key_name}`}
        />
      </div>
    </div>
  );
};

export default RemoveElement;
