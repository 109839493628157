import React, { useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import {
  countUnreadConversationsByType,
  findAdminUidFromChats,
} from '../../../utils/chat';
import { Firestore } from 'firebase/firestore';

function a11yProps(index: number) {
  return {
    className: 'chat-page-tab',
    id: `chat-tab-${index}`,
    'aria-controls': `chat-tabpanel-${index}`,
  };
}

interface IProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
  db: Firestore;
}

const ChatTabs = ({ onChange, value, db }: IProps) => {
  const [countCompany, setCountCompany] = React.useState(0);
  const [countStudent, setCountStudent] = React.useState(0);

  useEffect(() => {
    const getChatCounts = async () => {
      const adminUid = await findAdminUidFromChats(db);
      const { countCompany, countStudent } =
        await countUnreadConversationsByType(adminUid, db);
      setCountCompany(countCompany);
      setCountStudent(countStudent);
    };
    getChatCounts();
  }, []);

  return (
    <Tabs
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: '#DEB900',
        },
      }}
    >
      <Tab label={`Student Support (${countStudent})`} {...a11yProps(0)} />
      <Tab label={`Company Support (${countCompany})`} {...a11yProps(1)} />
      <Tab label="Company - Student" {...a11yProps(2)} />
    </Tabs>
  );
};

export default ChatTabs;
