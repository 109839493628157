import React, { useEffect, useState, useMemo } from 'react';
import FiltersPanel from '../../../components/filter-panel';
import { Link } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import { numberWithDec } from '../../../utils/numberWithDec';
import { formatDate } from '../../../utils/dates';
import {
  OngoingRevenueResponse,
  useSearchAdminOngoingRevenue,
} from '../../../data/admin-revenue';
import ResizableTable from '../../../components/resizable-table';
import Search from '../../../components/search';

interface IProps {
  search: string;
  setSearch: (value: string) => void;
  debouncedSearch: string;
}

const date = new Date();
const currentMonth = `${date.getMonth() + 1}/${date.getFullYear()}`;

const Ongoing = ({ search, setSearch, debouncedSearch }: IProps) => {
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    max_month: currentMonth,
    min_month: currentMonth,
  });

  const hoursColumns = useMemo(() => {
    return [
      {
        accessorKey: 'month',
        header: 'Month',
        size: 80,
        enableSorting: true,
      },
      {
        accessorKey: 'hours',
        header: 'Hours',
        size: 80,
        enableSorting: true,
      },
      {
        accessorKey: 'rate',
        header: 'Rate',
        size: 80,
        enableSorting: true,
      },
      {
        accessorKey: 'revenue',
        header: 'Revenue',
        size: 80,
        enableSorting: true,
      },
      {
        accessorKey: 'gmv',
        header: 'GMV',
        size: 80,
        enableSorting: true,
      },
      {
        accessorKey: 'student',
        header: 'Student',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          console.log(row.original);
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminStudentsRoute}/${row.original.student_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'job_title',
        header: 'Job',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminJobsRoute}/${row.original.job_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'company_name',
        header: 'Company',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminCompaniesRoute}/${row.original.company_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
    ];
  }, []);

  const {
    data: revenueData,
    refetch,
    isLoading,
  } = useSearchAdminOngoingRevenue({ ...filters, query: debouncedSearch });

  const { pagination, revenue, total_gmv, total_revenue, total_hours } =
    revenueData || {};

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleFilters = (customFilters) => {
    const filters = { ...customFilters };
    if ('min_month' in filters) {
      filters.min_month = formatDate(filters.min_month, 'MM/YYYY');
    }
    if ('max_month' in filters) {
      filters.max_month = formatDate(filters.max_month, 'MM/YYYY');
    }
    setFilters(filters);
  };

  const handleSortModelChange = (sortModel) => {
    setFilters({
      ...filters,
      ...sortModel,
    });
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  const revenueGridData = () => {
    const data =
      revenue?.map((timesheet: OngoingRevenueResponse, index) => {
        const {
          month,
          hours,
          rate,
          revenue,
          gmv,
          student_name,
          job_title,
          company_name,
          student_uid,
          job_uid,
          company_uid,
        } = timesheet;

        return {
          id: index,
          month: month,
          hours: hours,
          rate: '€' + numberWithDec(rate, 2),
          revenue: '€' + numberWithDec(revenue, 2),
          gmv: '€' + numberWithDec(gmv, 2),
          student: student_name,
          job_title: job_title || 'Draft',
          company_name,
          student_uid,
          job_uid,
          company_uid,
        };
      }) || [];

    data.push({
      id: Date.now(),
      month: 'Total',
      hours: total_hours || 0,
      rate: '',
      revenue: '€' + numberWithDec(total_revenue, 2),
      gmv: '€' + numberWithDec(total_gmv, 2),
      student: '',
      job_title: '',
      company_name: '',
      student_uid: '',
      job_uid: '',
      company_uid: '',
    });

    return data;
  };

  return (
    <>
      <div className="grid-title-row">
        <p className="section-sub-title">Booked hours</p>
        <div className="admin-table-inputs jobs-table-inputs">
          <Search
            setSearch={setSearch}
            className="search-jobs-input"
            value={search}
          />
          <FiltersPanel
            page="admin-revenue"
            filters={filters}
            changeFilters={(customFilters) => {
              const { page, per_page, query } = filters;

              handleFilters({ page, per_page, query, ...customFilters });
            }}
          />
        </div>
      </div>

      <div className="table--wrapper">
        <ResizableTable
          pageSize={filters.per_page + 1}
          columns={hoursColumns}
          rows={revenueGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Ongoing;
