import React, { useState, useMemo } from 'react';
import '../AdminGeneralStyles.scss';
import {
  Alert,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import {
  AdminTimesheetsStatisticsResponse,
  TimesheetResponse,
  useSearchAdminTimesheets,
  useTimesheetStatistic,
} from '../../../data/admin-timesheets';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import { useDebounce } from '../../../utils/useDebounce';
import { Link } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import TimesheetStatusChip from '../../timesheet/components/TimesheetStatusChip';
import { formatDate } from '../../../utils/dates';
import ResizableTable from '../../../components/resizable-table';
import { LoadingButton } from '../../../components/LoadingButton';
import MiniteSnackbar from '../../../components/snackbar';
import { useCreateTimesheetInvoicesMany } from '../../../data/admin-timesheet-details';

const getStatisticCards = (statistics: AdminTimesheetsStatisticsResponse) => {
  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'total'}
        text={'Total hours booked this month'}
        counter={statistics?.total_hours_this_month || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'high-flyers'}
        text={'Ongoing jobs in progress this month'}
        counter={statistics?.total_jobs_in_progress_this_month || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'approved'}
        text={'Approved timesheets this month'}
        counter={
          (statistics?.percentage_timesheets_approved_this_month || 0) + '%'
        }
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'expected'}
        text={'Expected hours this month'}
        counter={Math.round(statistics?.expected_hours_this_month * 4.34) || 0}
        className={'admin-smaller-statistics'}
      />
      <AdminStatisticCard
        key={'min'}
        text={'Minimum hours this month'}
        counter={statistics?.min_hours_this_month || 0}
        className={'admin-smaller-statistics'}
      />
    </div>
  );
};

const date = new Date();
const currentMonth = `${date.getMonth() + 1}/${date.getFullYear()}`;

const AdminTimesheets = () => {
  const { data: statistics } = useTimesheetStatistic();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    max_month: currentMonth,
    min_month: currentMonth,
    asc: false,
  });
  const {
    data: timesheetData,
    refetch: refetchTimesheetsDate,
    isLoading,
  } = useSearchAdminTimesheets({ ...filters, query: debouncedSearch });

  const { pagination, timesheets, total_hours = 0 } = timesheetData || {};
  const refetchTimesheets = async () => {
    await refetchTimesheetsDate();
  };
  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });

    refetchTimesheets();
  };

  const timesheetColumns = useMemo(() => {
    return [
      {
        accessorKey: 'month',
        header: 'Month',
        enableSorting: true,
        size: 115,
      },
      {
        accessorKey: 'hours',
        header: 'Hours',
        enableSorting: true,
        size: 115,
      },
      {
        accessorKey: 'rate',
        header: 'Rate',
        enableSorting: true,
        size: 115,
      },
      {
        accessorKey: 'payout',
        header: 'Payout',
        enableSorting: true,
        size: 115,
      },
      {
        accessorKey: 'student',
        header: 'Student',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminStudentsRoute}/${row.original.student_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'job',
        header: 'Job',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminJobsRoute}/${row.original.job_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'company',
        header: 'Company',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminCompaniesRoute}/${row.original.company_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: true,
        Cell: (params) => {
          return (
            params.row.original.status && (
              <TimesheetStatusChip
                timesheetStatus={params.row.original.status}
              />
            )
          );
        },
      },
    ];
  }, []);

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };
  const handleRowClick = ({ original }) => {
    window.open(
      `${Routes.HoursRoute}/${original.student_uid}/${original.job_uid}`,
      '_blank',
    );
  };
  const timesheetGridData = (): unknown[] => {
    const data =
      timesheets?.map((timesheet: TimesheetResponse) => {
        return {
          id: timesheet.timesheet_uid,
          month: timesheet.month + '/' + timesheet.year,
          hours: timesheet.hours_sum,
          rate: '€' + timesheet.rate,
          payout: '€' + timesheet.payout,
          student:
            timesheet.student.first_name + ' ' + timesheet.student.last_name,
          job: timesheet.job.job_title,
          company: timesheet.company.company_name,
          status: timesheet.timesheet_status,
          student_uid: timesheet.student.student_uid,
          job_uid: timesheet.job.job_uid,
          company_uid: timesheet.company.company_uid,
        };
      }) || [];

    data.push({
      id: 'total',
      month: 'Total',
      hours: total_hours,
      rate: '',
      payout: '',
      student: '',
      job: '',
      company: '',
      status: '',
      student_uid: '',
      job_uid: '',
      company_uid: '',
    });

    return data;
  };

  const handleFilters = (customFilters) => {
    const filters = { ...customFilters };
    if ('min_month' in filters) {
      filters.min_month = formatDate(filters.min_month, 'MM/YYYY');
    }
    if ('max_month' in filters) {
      filters.max_month = formatDate(filters.max_month, 'MM/YYYY');
    }
    setFilters(filters);
  };

  const [createTimesheetInvoicesLoading, setCreateTimesheetInvoicesLoading] =
    useState(false);
  const [sendTimesheetInvoicesLoading, setSendTimesheetInvoicesLoading] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [invoicingSuccessMessage, setInvoicingSuccessMessage] = useState(null);

  const { mutateAsync: createTimesheetInvoicesMany } =
    useCreateTimesheetInvoicesMany();

  const handleCreateInvoices = async () => {
    try {
      setCreateTimesheetInvoicesLoading(true);
      await createTimesheetInvoicesMany({ send: false });
      setInvoicingSuccessMessage(
        'Job to create invoices has been submitted. A report will be sent to finance@minite.works after it finishes',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setCreateTimesheetInvoicesLoading(false);
    }
  };

  const handleSendInvoices = async () => {
    try {
      setSendTimesheetInvoicesLoading(true);
      await createTimesheetInvoicesMany({ send: true });
      setInvoicingSuccessMessage(
        'Job to send invoices for this timesheet has been submitted',
      );
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setSendTimesheetInvoicesLoading(false);
    }
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });

    refetchTimesheets();
  };

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-timesheets-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Timesheets</p>

      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        {getStatisticCards(statistics)}
      </Grid>
      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <Divider
          sx={{ marginTop: '10px', marginBottom: '10px' }}
          textAlign="left"
        >
          Invoices
        </Divider>
        <Alert
          sx={{
            marginBottom: '10px',
          }}
          severity="info"
        >
          After clicking <strong>Create</strong> button a job is submitted to
          create invoices. After the job finishes running a reporte is generated
          and sent to finance@minite.works
        </Alert>

        <LoadingButton
          isDisabled={createTimesheetInvoicesLoading}
          buttonText="Create invoices"
          onClick={handleCreateInvoices}
          isLoading={createTimesheetInvoicesLoading}
          type="button"
          startIcon={<PictureAsPdfIcon />}
          sx={{
            width: 'fit-content',
            marginBottom: '10px',
            marginTop: '10px',
            marginRight: '10px',
          }}
          confirmButtonTestId="create-invoices"
        />

        <LoadingButton
          isDisabled={sendTimesheetInvoicesLoading}
          buttonText="Send invoices"
          onClick={handleSendInvoices}
          startIcon={<SendIcon />}
          isLoading={sendTimesheetInvoicesLoading}
          variant="outlined"
          type="button"
          sx={{
            width: 'fit-content',
            marginBottom: '10px',
            marginTop: '10px',
          }}
          confirmButtonTestId="send-invoices"
        />

        <MiniteSnackbar
          message={errorMessage}
          title={'Oops :('}
          open={!!errorMessage}
          onClose={() => setErrorMessage(null)}
          location={{ vertical: 'top', horizontal: 'center' }}
          offsetTopValue={55}
          cancelOffsetIfNotAtTop={true}
        />
        <MiniteSnackbar
          message={invoicingSuccessMessage}
          title={'Message'}
          open={!!invoicingSuccessMessage}
          severity="success"
          onClose={() => setInvoicingSuccessMessage(null)}
          location={{ vertical: 'top', horizontal: 'center' }}
          offsetTopValue={55}
          cancelOffsetIfNotAtTop={true}
        />
        <Divider sx={{ marginTop: '10px' }} textAlign="left"></Divider>
      </Grid>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All booked hours</p>
          <div className="admin-table-inputs timesheets-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-timesheets-input"
              onChange={(event) => setSearch(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FiltersPanel
              page="admin-timesheets"
              filters={filters}
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;
                handleFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <ResizableTable
          pageSize={filters.per_page + 1}
          columns={timesheetColumns}
          rows={timesheetGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminTimesheets;
