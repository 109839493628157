import {
  MobileStepper,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import './Stepper.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

interface StepProps {
  label: string;
}

interface StepperProps {
  steps: StepProps[];
  activeStep: number;
  children?: Array<JSX.Element> | JSX.Element;
  buttons?: {
    back: string;
    next: string;
  };
  isFullSize?: boolean;
  isShortSteps?: boolean;
}

function CustomStepperIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <div className={className ? className : 'step'}>
      {completed ? (
        <CheckIcon className="CustomStepperIcon-completedIcon" />
      ) : active ? (
        <div className="CustomStepperIcon-active" />
      ) : (
        <div className="CustomStepperIcon-circle" />
      )}
    </div>
  );
}

export const SharedStepper: React.FC<StepperProps> = (props: StepperProps) => {
  const [steps, setSteps] = useState(props.steps);
  const [activeStep, setActiveStep] = useState(props.activeStep);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();
  useEffect(() => {
    setActiveStep(props.activeStep);
  }, [props.activeStep]);

  useEffect(() => {
    setSteps(props.steps);
  }, [props.steps]);

  return (
    <section className="stepper-container-parent">
      <div className="stepper-container">
        {(props.isFullSize || !isMobile) && (
          <>
            <div className="titles-container">
              {steps &&
                steps.map((step, index) => (
                  <p
                    className={`titles ${
                      index < activeStep + 1 ? 'confirmed' : ''
                    }`}
                    key={step.label + index}
                  >
                    <span className="title"> {t(step.label)}</span>
                  </p>
                ))}
            </div>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className="stepper-wrapper"
            >
              {steps &&
                steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconComponent={CustomStepperIcon}
                    ></StepLabel>
                  </Step>
                ))}
            </Stepper>
          </>
        )}

        {isMobile && (
          <div className="stepper-mobile-container">
            <MobileStepper
              variant="progress"
              steps={steps.length + 1}
              position="static"
              activeStep={activeStep + 1}
              nextButton={<></>}
              backButton={<></>}
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />
            <p className="stepper-progress-text">
              {props.isShortSteps
                ? `${activeStep + 1}/${steps.length}`
                : `Step ${activeStep + 1} of ${steps.length}`}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
