export interface IOption {
  id: string;
  label: string;
}

export const getOptionLabel = (option: IOption | string) =>
  option['label'] || option;

export const isOptionEqualToValue = (option: IOption, value) => {
  return option?.id === value['id'];
};
