import React from 'react';
import { Button } from '@mui/material';

const AdminAccess = ({
  onSubmit,
  isAdmin,
}: {
  onSubmit: () => void;
  isAdmin: boolean;
}) => {
  return (
    <div className="assign-admin-wrapper">
      <Button
        onClick={onSubmit}
        id="assign-admin-button"
        data-testid={`${isAdmin ? 'unassign' : 'assign'}-admin`}
      >
        {isAdmin ? 'Un-assign' : 'Assign'} admin access
      </Button>
    </div>
  );
};

export default AdminAccess;
