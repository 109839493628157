import React, { useEffect, useState } from 'react';
import '../../financial/components/AccountForms.scss';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useFetchJobInterests,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import { IOption, isOptionEqualToValue } from '../../../../utils/ioption';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../components/snackbar';
import { getPersonalQuestionTranslation as PSTranslator } from '../../../../components/sign-up/components/high-flyer/PersonalForm';
import { ProfileStudentPersonalSentences } from '../../../../data/profile';
import { initialPersonalFormData } from '../../../../stores/student-signup-form';
import { PersonalityTypeValues } from '../../../../data/students';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface StudentProfileFormInterface {
  one_liner?: string;
  bio: string;
  rate: number;
  job_interests: IOption[];
  tags: string[];
  avatar: string;
  personal_sentences: ProfileStudentPersonalSentences[];
  personality_type?: string;
}

const StudentPersonalForm = ({ user, student, loading, afterSubmit }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { data: job_interests = student.job_interests } =
    useFetchJobInterests();
  const [tempAvatar, setTempAvatar] = useState('/images/student-avatars/7.png');
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.student',
  });
  const { t: t_ps } = useTranslation('translation', {
    keyPrefix: 'personal_sentences',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const jobInterestsOptions = job_interests?.map((item) => ({
    label: item.job_interest_name,
    id: item.job_interest_uid,
  }));
  const interests = student?.job_interests?.filter(
    (item) => !!item.job_interest_uid,
  );

  const schema = yup
    .object()
    .shape({
      job_interests: yup.array().min(1, 'Min. is 1 interest').required(),
      personal_sentences: yup
        .array()
        .of(
          yup.object().shape({
            question: yup.string().max(150, 'Max 150 characters'),
            response: yup.string().max(150, 'Max 150 characters').optional(),
          }),
        )
        .optional(),
      personality_type: yup.string().optional().nullable(),
    })
    .required();

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<StudentProfileFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      bio: student?.profile_summary,
      avatar: user?.avatar || tempAvatar,
      rate: student?.hourly_rate,
      one_liner: student?.one_liner,
      job_interests:
        !!jobInterestsOptions?.length && !!interests?.length
          ? jobInterestsOptions?.filter((interest) => {
              return !!interests.find(
                (item) => item?.job_interest_uid === interest?.id,
              );
            })
          : [],
      // Users that made their account before personal sentences return an array of size 1, where both fiels are null
      personal_sentences:
        student?.personal_sentences === null ||
        student?.personal_sentence?.length === 0 ||
        (student?.personal_sentences?.length > 0 &&
          student?.personal_sentences[0].question === null)
          ? initialPersonalFormData.personal_sentences
          : student?.personal_sentences,
      personality_type: student?.personality_type || null,
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (student?.one_liner) {
      trigger();
    }

    if (user?.avatar) {
      setTempAvatar(user.avatar);
    }
  }, [user]);

  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState({ isError: false, message: '' });
  const [isTagsDirty, setIsTagsDirty] = useState(false);
  const tagRegex =
    /^(\w+ ?){0,2} ?(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])?$/;
  const addTag = (data: string) => {
    if (data.trim() === '') {
      return false;
    }
    if (tags?.length >= 5) {
      setTagsError({ isError: true, message: 'Max number of tags is 5' });
      return false;
    }
    if (!tagRegex.test(data)) {
      setTagsError({
        isError: true,
        message: 'Tag can have up to two words and one emoji at the end',
      });
      return false;
    }
    if (data?.length > 30) {
      setTagsError({
        isError: true,
        message: 'Tag must be less than 30 characters',
      });
      return false;
    }
    const newState = [...tags, data];
    setTagsError({ isError: false, message: '' });
    setTags(newState);
    setValue('tags', newState);
    return true;
  };

  const removeTag = (index: number) => {
    const newState = tags?.filter((_, arr_idx) => {
      return arr_idx !== index;
    });
    setTags(newState);
    setValue('tags', newState);
  };

  useEffect(() => {
    if (student?.tags !== null) {
      setTags(student?.tags);
    } else {
      setTags([]);
    }
  }, [student?.tags]);

  useEffect(() => {
    let originalEmpty, tagsEmpty;
    if (student?.tags === null || student?.tags?.length === 0) {
      originalEmpty = true;
    } else {
      originalEmpty = false;
    }
    if (tags === null || tags?.length === 0) {
      tagsEmpty = true;
    } else {
      tagsEmpty = false;
    }
    if (originalEmpty && tagsEmpty) {
      setIsTagsDirty(false);
      return;
    }
    if (student?.tags === null || tags === null) {
      //this is only entered when one array is null, and the other is non-empty
      setIsTagsDirty(true);
      return;
    }
    if (student?.tags?.length === tags?.length) {
      const allEqual = student?.tags?.every(
        (value, index) => value === tags[index],
      );
      setIsTagsDirty(!allEqual);
      return;
    }
    setIsTagsDirty(true);
  }, [student?.tags, tags]);

  const onSubmit = async (data: StudentProfileFormInterface) => {
    setIsLoading(true);

    try {
      await updateUserProfile({
        user: {
          avatar: data?.avatar,
        },
        student: {
          hourly_rate: data?.rate,
          one_liner: data?.one_liner,
          tags: tags,
          profile_summary: data?.bio,
          job_interests: data?.job_interests?.map((interest) => {
            return {
              job_interest_uid: interest?.id,
            };
          }),
          personal_sentences: data?.personal_sentences,
          personality_type: data?.personality_type,
        },
      });

      await reset(data);
      await afterSubmit();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">{t('personal')}</p>
        </Grid>

        <Grid item xs={12} md={9}>
          {loading ? (
            <Skeleton />
          ) : (
            <form className="account-form">
              <Alert severity="info" icon={false} className="toast-student">
                <span className="description">
                  {`${t('legal_warning')} `}
                  <a
                    href="https://www.minite.works/terms-of-use/terms-of-use-students"
                    className="toast-link"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  .
                </span>
              </Alert>

              <FormControl
                variant="standard"
                sx={{ width: '100%', marginBottom: '16px' }}
              >
                <Controller
                  control={control}
                  defaultValue={[]}
                  name="job_interests"
                  render={({ field: { onChange, value } }) => {
                    const handleButtonClick = (option) => {
                      if (
                        value.some((selected) =>
                          isOptionEqualToValue(selected, option),
                        )
                      ) {
                        onChange(
                          value.filter(
                            (item) => !isOptionEqualToValue(item, option),
                          ),
                        );
                      } else {
                        onChange([...value, option]);
                      }
                    };

                    return (
                      <Box>
                        <Typography variant="h6">{t('interests')}</Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {jobInterestsOptions.map((option) => (
                            <Button
                              key={option.id}
                              variant={
                                value.some((selected) =>
                                  isOptionEqualToValue(selected, option),
                                )
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() => handleButtonClick(option)}
                              sx={{
                                backgroundColor: value.some((selected) =>
                                  isOptionEqualToValue(selected, option),
                                )
                                  ? '#DEB900'
                                  : 'inherit',
                                width: 'auto',
                              }}
                            >
                              {t(`job_interests.${option.label}`)}
                            </Button>
                          ))}
                        </Box>
                        {errors.job_interests && (
                          <Typography color="error" variant="caption">
                            {errors.job_interests.message}
                          </Typography>
                        )}
                      </Box>
                    );
                  }}
                />
              </FormControl>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <FormControl variant="standard">
                    <InputLabel id="degree-select-label">
                      Personality type{' '}
                      <Tooltip
                        title={
                          'Showcase you personality as defined by the 16 personalities test.'
                        }
                        placement="right"
                      >
                        <InfoOutlinedIcon className="info-tooltip" />
                      </Tooltip>
                    </InputLabel>
                    <Select
                      labelId="personality-type-label"
                      id="personality-type"
                      label="Personality type"
                      value={value}
                      onChange={onChange}
                      data-testid="personality-type"
                    >
                      {PersonalityTypeValues.map((personality_type) => {
                        return (
                          <MenuItem
                            key={personality_type.id}
                            value={personality_type.id}
                            sx={{ color: personality_type.color }}
                            disabled={personality_type.color === 'disabled'}
                          >
                            {personality_type.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                control={control}
                name={'personality_type'}
              />
              <TextField
                label={t('personal_tags')}
                inputProps={{ maxLength: 30 }}
                maxRows={1}
                type="text"
                id="user-tags-input"
                data-testid="user-tags-input"
                variant="standard"
                name="tags"
                error={tagsError.isError}
                helperText={
                  tagsError.message ? tagsError.message : t('add_tag')
                }
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    const success = addTag(
                      (ev.target as HTMLInputElement).value,
                    );
                    if (success) {
                      (ev.target as HTMLInputElement).value = '';
                    }
                  }
                }}
              />
              <div
                className="tags"
                style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
              >
                {tags?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      onDelete={() => removeTag(index)}
                      sx={{
                        width: 'auto',
                        padding: '6px 6px 7px',
                        border: '1.5px solid #deb900',
                        backgroundColor: 'transparent',
                        color: '#deb900',
                        fontFamily: 'Sofia W05 Medium, sans-serif',
                      }}
                      label={item}
                      className="student-level-chip"
                    />
                  );
                })}
              </div>

              <FormControl>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  {getValues('personal_sentences')?.map((item, index) => {
                    return (
                      <TextField
                        key={index}
                        label={PSTranslator(t_ps, item.question)}
                        inputProps={{ maxLength: 200 }}
                        maxRows={3}
                        multiline
                        type="text"
                        id={`user-sentence-${index}-input`}
                        variant="standard"
                        data-testid={`user-sentence-${index}-input`}
                        error={
                          !!errors.personal_sentences &&
                          !!errors.personal_sentences[index] &&
                          !!errors.personal_sentences[index].response
                        }
                        helperText={
                          !!errors.personal_sentences &&
                          !!errors.personal_sentences[index] &&
                          !!errors.personal_sentences[index].response
                            ? errors.personal_sentences[index].response.message
                            : ''
                        }
                        {...register(`personal_sentences.${index}.response`)}
                      />
                    );
                  })}
                </div>
              </FormControl>
            </form>
          )}

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !(isDirty || isTagsDirty)}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-student-personal-info"
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default StudentPersonalForm;
