import { useMutation, useQuery } from '@tanstack/react-query';
import { request, ServerResponse } from '../core/request';
import { PROPOSALS, STUDENT_PROPOSALS } from './query-keys';
import {
  JobLocation,
  JobStatus,
  JobType,
  ProposalStatus,
  ProposalStatuses,
  StudentType,
} from './jobs';
import { AxiosError } from 'axios';

export interface ProposalJobResponse {
  job_uid: string;
  job_title: string;
  job_type: JobType;
  students_needed: number;
  budget: number;
  hours_estimated: number;
  job_location: JobLocation;
  job_status: JobStatus;
  job_start_at: string;
  job_end_at: string;
  job_description: string;
  matched_at?: string;
}

export interface StudentResponse {
  student_uid: string;
  user_uid: string;
  student_name: string;
  email: string;
  avatar?: string;
  student_type: StudentType;
  hourly_rate: number;
  one_liner?: string;
  profile_summary?: string;
  rating: number;
  top_talent?: boolean;
}

export interface CompanyResponse {
  company_uid: string;
  company_name: string;
  rating: number;
  email: string;
  user_uid: string;
  avatar: string;
}

export interface StudentLanguageResponse {
  language_uid: string;
  language_name: string;
}

export interface StudentSkillResponse {
  skill_uid: string;
  skill_name: string;
}

export interface StudentEducationResponse {
  degree: string;
  study: string;
  ended_at?: string;
}

export interface ProposalStudentUserResponse {
  student_name: string;
  avatar: string;
  languages: Array<StudentLanguageResponse>;
  skills: Array<StudentSkillResponse>;
  education: Array<StudentEducationResponse>;
  email: string;
  user_uid: string;
}

export interface ProposalResponse {
  proposal_uid: string;
  proposal_status: ProposalStatus;
  rate: number;
  min_month: number;
  counter_offer_description: string;
  how_you_do_x: string;
  how_you_do_y: string;
  job: ProposalJobResponse;
  student: StudentResponse & ProposalStudentUserResponse;
  company: CompanyResponse;
  variables: ProposalVariables;
  signed_by_student_at?: string | null;
  signed_by_company_at?: string | null;
  pdf_url?: string | null;
  chat_session_id?: string;
  signed_by_student_ip?: string | null;
  legacy_id?: string | null;
  x_sentiment?: string | null;
  y_sentiment?: string | null;
  interview_prediction?: string;
  match_score?: number;
  reject_reason?: string;
}

export interface ProposalVariables {
  JOBDESCRIPTION: string;
  JOBRATE: string;
  STARTDATE: string;
  STUDENTCOMPANYNAME: string;
  STUDENTCITY: string;
  STUDENTKVK: string;
  STUDENTFIRST_LASTNAME: string;
  COMPANYNAME: string;
  COMPANYKVK: string;
  COMPANYCITY: string;
  COMPANYFIRST_LASTNAME: string;
  COMPANYIP: string | null;
  COMPANY_DATE: string | null;
  COMPANY_TIME: string | null;
  STUDENTIP: string | null;
  STUDENT_DATE: string | null;
  STUDENT_TIME: string | null;
}

export interface MyProposalResponse {
  proposal_uid: string;
  created_at: string;
  job_uid: string;
  student_uid: string;
  proposal_status: ProposalStatus;
  rate: number;
  student_name: string;
  counter_offer_description: string;
  min_month?: number;
  how_you_do_x: string;
  how_you_do_y?: string;
  company: CompanyResponse;
  job: ProposalJobResponse;
}

export interface MyProposalsResponse {
  proposals: MyProposalResponse[];
}

export interface SingleProposalResponse {
  proposal: ProposalResponse;
}

// request for company
const fetchProposals = async (jobId: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/proposals`,
    method: 'get',
  });

  return data;
};

export function useFetchProposals(jobId: string) {
  return useQuery([PROPOSALS, jobId], () => fetchProposals(jobId), {
    enabled: !!jobId,
    onError: (e) => console.error(e),
  });
}

// request for students
export const fetchStudentProposals = async (excludeSigned = false) => {
  const { data } = await request<ServerResponse<MyProposalsResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/proposals`,
    method: 'get',
  });

  return excludeSigned
    ? data?.data?.proposals?.filter(
        (proposal) => proposal.proposal_status !== ProposalStatuses.Signed,
      )
    : data?.data?.proposals;
};

export function useFetchStudentProposals(
  byStudent = false,
  excludeSigned = false,
) {
  return useQuery(
    [STUDENT_PROPOSALS],
    () => fetchStudentProposals(excludeSigned),
    {
      enabled: byStudent,
      onError: (e: AxiosError) => e,
    },
  );
}

export const fetchProposalById = async (proposalId: string) => {
  const { data } = await request<ServerResponse<SingleProposalResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}`,
    method: 'get',
  });

  return data.data;
};

export function useFetchProposalById(proposalId: string) {
  return useQuery([proposalId], () => fetchProposalById(proposalId), {
    enabled: !!proposalId,
    onError: (e) => console.error(e),
  });
}

const createProposal = async (jobUid: string, payload) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobUid}/proposals`,
    method: 'post',
    data: payload,
  });
  if (!data.success)
    throw new Error('Apply for a job failed, code: ' + data.code);
};

export function useCreateProposal() {
  return useMutation(
    (payload: any) => createProposal(payload.jobUid, payload.data),
    {
      onError: (e) => console.error(e),
    },
  );
}

export const duplicateProposalRequest = async (
  jobUid: string,
  body: DuplicateProposalData,
) => {
  const {
    data: { data },
  } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/proposals/duplicate`,
    method: 'post',
    data: body,
  });

  return data;
};

export const useDuplicateProposalRequest = () => {
  return useMutation(
    (payload: { jobUid: string; body: DuplicateProposalData }) =>
      duplicateProposalRequest(payload.jobUid, payload.body),
    {
      onError: (e) => console.error(e),
    },
  );
};

const updateProposal = async (proposalId: string, payload) => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}`,
    method: 'put',
    data: payload,
  });
  if (!data.success)
    throw new Error('edit proposal failed, code: ' + data.code);
};

export function useUpdateProposal() {
  return useMutation(
    (payload: any) => updateProposal(payload.proposalId, payload.data),
    {
      onError: (e) => console.error(e),
    },
  );
}

const finishUpdateProposal = async (proposalId: string, payload) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/finish`,
    method: 'put',
    data: payload,
  });
  if (!data.success)
    throw new Error('edit proposal failed, code: ' + data.code);
};

export function useFinishUpdateProposal() {
  return useMutation(
    (payload: any) => finishUpdateProposal(payload.proposalId, payload.data),
    {
      onError: (e) => console.error(e),
    },
  );
}

const retractProposal = async (proposalId: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/retract`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('retract proposal failed, code: ' + data.code);
};

export function useRetractProposal() {
  return useMutation((proposalId: string) => retractProposal(proposalId), {
    onError: (e) => console.error(e),
  });
}

const signProposalByCompany = async (proposalId: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/proposals/${proposalId}/sign_company`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('sign proposal failed, code: ' + data.code);
};

export function useSignProposalByCompany() {
  return useMutation(
    (proposalId: string) => signProposalByCompany(proposalId),
    {
      onError: (e) => console.error(e),
    },
  );
}

const signProposalByStudent = async (proposalId: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/proposals/${proposalId}/sign_student`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('sign proposal failed, code: ' + data.code);
};

export function useSignProposalByStudent() {
  return useMutation(
    (proposalId: string) => signProposalByStudent(proposalId),
    {
      onError: (e) => console.error(e),
    },
  );
}

const rejectProposal = async (proposalId: string, reason?: string) => {
  const payload = { reason };
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/reject`,
    method: 'post',
    data: payload,
  });
  if (!data.success)
    throw new Error('reject proposal failed, code: ' + data.code);
};

export function useRejectProposal() {
  return useMutation(
    (payload: { proposalId: string; reason?: string }) =>
      rejectProposal(payload.proposalId, payload.reason),
    {
      onError: (e) => console.error(e),
    },
  );
}

const acceptProposal = async (proposalId: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/accept`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('accept proposal failed, code: ' + data.code);
};

export function useAcceptProposal() {
  return useMutation((proposalId: string) => acceptProposal(proposalId), {
    onError: (e) => console.error(e),
  });
}

const interviewInviteProposal = async (
  proposalId: string,
  payload: {
    start_date_time: string;
    end_date_time: string;
  },
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/proposals/${proposalId}/interview_invite`,
    method: 'post',
    data: payload,
  });
  if (!data.success)
    throw new Error('Interview invite proposal failed, code: ' + data.code);
};

export function useInterviewInviteProposal(proposalId: string) {
  return useMutation(
    (payload?: { start_date_time: string; end_date_time: string }) =>
      interviewInviteProposal(proposalId, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

const startChatProposal = async (proposalId: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/proposals/${proposalId}/chat_invite`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('Interview invite proposal failed, code: ' + data.code);
};

export function useStartChatProposal() {
  return useMutation((proposalId: string) => startChatProposal(proposalId), {
    onError: (e) => console.error(e),
  });
}

export const fetchPdfURLByProposalId = async (proposalId: string) => {
  const { data } = await request<ServerResponse<{ pdf_url: string }>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/pdf`,
    method: 'get',
  });

  return data.data;
};

export const fetchChatByProposalId = async (proposalId: string) => {
  console.log(proposalId);
  // const { data } = await request<ServerResponse<unknown>>({
  //   url: process.env.REACT_APP_API_URL + `/api/v1/proposals/${proposalId}/chat`,
  //   method: 'get',
  // });

  return true;
};

export const sendChatNotificationEmail = async (
  proposalId: string,
  payload: {
    to_email: string;
    to_user_role: string;
  },
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/proposals/${proposalId}/chat-notification`,
    method: 'POST',
    data: payload,
  });

  return data;
};

export interface DuplicateProposalData {
  new_job_uid: string;
}
