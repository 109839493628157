import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';

import './RehireStudentModal.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DuplicateJobResponse,
  JobData,
  JobTypes,
  PaymentType,
  useDuplicateJobRequest,
  usePayJob,
} from '../../../data/jobs';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MiniteSnackbar from '../../../components/snackbar';
import { useDuplicateProposalRequest } from '../../../data/proposals';
import { LoadingButton } from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
  jobData: JobData;
}

export interface RehireStudentForm {
  type: 'ongoing' | 'onetime';
  oneTimeData?: {
    isActive: boolean;
    rate: string;
  };
  ongoingData?: {
    isActive: boolean;
    hours: string;
    rate: string;
  };
  start: Date;
}

const schema = yup.object().shape({
  type: yup.string().required(),
  oneTimeData: yup.object().shape({
    isActive: yup.boolean(),
    rate: yup.mixed().when('isActive', {
      is: true,
      then: yup
        .number()
        .min(100, 'Minimum is €100')
        .typeError('Please enter a valid number')
        .required('This field is required'),
    }),
  }),
  ongoingData: yup.object().shape({
    isActive: yup.boolean(),
    hours: yup.mixed().when('isActive', {
      is: true,
      then: yup
        .number()
        .min(8, 'Minimum is 8')
        .typeError('Please enter a valid number')
        .required('This field is required'),
    }),
    rate: yup.mixed().when('isActive', {
      is: true,
      then: yup
        .number()
        .min(17, 'Minimum is €17')
        .typeError('Please enter a valid number')
        .required('This field is required'),
    }),
  }),
  start: yup.date().required('This field is required'),
});

const RehireStudentModal = ({ open, handleClose, jobData }: Props) => {
  const { mutateAsync: duplicateJobRequest } = useDuplicateJobRequest();
  const { mutateAsync: duplicateProposalRequest } =
    useDuplicateProposalRequest();

  const { mutateAsync: payJob } = usePayJob();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'job_details.rehire_student_details',
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm<RehireStudentForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const setOngoingActive = () => {
    setValue('ongoingData.isActive', true);
    setValue('oneTimeData.isActive', false);
  };
  const setOneTimeActive = () => {
    setValue('ongoingData.isActive', false);
    setValue('oneTimeData.isActive', true);
  };

  useEffect(() => {
    if (jobData.job_type === JobTypes.ONGOING) {
      setValue('type', 'ongoing');
      setOngoingActive();
      setValue('ongoingData.rate', jobData.budget);
      setValue('ongoingData.hours', jobData.hours_estimated.toString());
    } else {
      setValue('type', 'onetime');
      setOneTimeActive();
      setValue('oneTimeData.rate', jobData.budget);
    }
    setValue('start', new Date());
  }, [jobData]);

  const redirectToPayment = async (jobUid, redirect_url) => {
    const response = await payJob({
      jobUid,
      body: {
        job_boost_pack: 0,
        payment_type: PaymentType.PostingFee,
        overwrite_redirect: redirect_url,
      },
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    window.open(response.data.checkout_url, '_self');
  };

  const onSubmit = async (data: RehireStudentForm) => {
    setIsLoading(true);

    // Prepare data
    let rate, hours;
    if (data.oneTimeData.isActive) {
      rate = data.oneTimeData.rate;
    } else {
      rate = data.ongoingData.rate;
      hours = data.ongoingData.hours;
    }

    // Duplicate job
    let res_job: DuplicateJobResponse;
    try {
      res_job = await duplicateJobRequest({
        jobUid: jobData.job_uid,
        body: {
          type: data.type,
          start_date: data.start.toDateString(),
          changes: {
            rate,
            hours,
          },
        },
      });
    } catch (error) {
      setErrorMessage('Failed to create new job!');
      setIsLoading(false);
      return;
    }

    // Payment if required
    if (res_job.payment_required) {
      try {
        const redirect_url =
          '/jobs/' +
          jobData.job_uid +
          '?rehire_uid=' +
          res_job.duplicate_job_uid;
        await redirectToPayment(res_job.duplicate_job_uid, redirect_url);
        return; //duplicating proposal is handled by the jobs page after payment returns
      } catch (error) {
        setErrorMessage('Failed to generate payment link for job!');
        setIsLoading(false);
        return;
      }
    }

    // Duplicate proposal
    try {
      const res_proposal = await duplicateProposalRequest({
        jobUid: jobData.job_uid,
        body: {
          new_job_uid: res_job.duplicate_job_uid,
        },
      });
      window.open('/Agreement/' + res_proposal.proposal.proposal_uid, '_self');
    } catch (error) {
      setErrorMessage('Failed to autogenerate proposal!');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} className="rehire-modal">
        <DialogTitle className="content-title">
          {t('title')}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <Controller
              {...register('type')}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <RadioGroup
                      {...field}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                      data-testid="job-types"
                    >
                      <FormControlLabel
                        value={'ongoing'}
                        control={<Radio />}
                        label={t('ongoing_job')}
                        onClick={setOngoingActive}
                      />
                      <FormControlLabel
                        value={'onetime'}
                        control={<Radio />}
                        label={t('onetime_job')}
                        onClick={setOneTimeActive}
                      />
                    </RadioGroup>
                    {getValues('type') === 'ongoing' && (
                      <>
                        <Controller
                          {...register('ongoingData.rate')}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ marginTop: '10px' }}
                              variant="standard"
                              data-testid="ongoing-job-rate"
                              error={!!errors.ongoingData?.rate}
                              helperText={errors.ongoingData?.rate?.message}
                              label={t('ongoing_rate')}
                            />
                          )}
                        />
                        <Controller
                          {...register('ongoingData.hours')}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ marginTop: '10px' }}
                              variant="standard"
                              data-testid="one-time-job-rate"
                              error={!!errors.ongoingData?.hours}
                              helperText={errors.ongoingData?.hours?.message}
                              label={t('ongoing_hours')}
                            />
                          )}
                        />
                      </>
                    )}
                    {getValues('type') === 'onetime' && (
                      <Controller
                        {...register('oneTimeData.rate')}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ marginTop: '10px' }}
                            variant="standard"
                            data-testid="one-time-job-rate"
                            error={!!errors.oneTimeData?.rate}
                            helperText={errors.oneTimeData?.rate?.message}
                            label={t('onetime_rate')}
                          />
                        )}
                      />
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        {...register('start')}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <DesktopDatePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            disableMaskedInput={true}
                            label={t('start_date')}
                            inputFormat="DD/MM/YYYY"
                            minDate={new Date()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ marginTop: '10px' }}
                                variant="standard"
                                data-testid="one-time-start-date"
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </>
                );
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="button"
            data-testid="close-rehire-modal"
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            className="button agree"
            autoFocus
            data-testid="confirm-rehire"
            isDisabled={!isValid}
            buttonText={t('confirm')}
            isLoading={isLoading}
          />
        </DialogActions>
      </Dialog>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default RehireStudentModal;
