import { BachelorDegrees } from '../components/sign-up/interfaces/static-data';
import { ProfileData } from '../data/profile';
import {
  AssessmentParams,
  adminGetStudentAssessments,
  getStudentAssessments,
} from '../data/user';
import { Countries } from './consts';

import { Location } from 'react-router-dom';

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isDevelopmentEnv = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const gridHeights = {
  1: 170,
  2: 110,
  3: 92,
  4: 81,
  5: 75,
};

export const handleGridHeight = (rowsLength: number, perPage = 5) => {
  if (rowsLength) {
    if (rowsLength <= 5) {
      return gridHeights[rowsLength] * rowsLength;
    } else if (rowsLength > 5 && rowsLength <= 6) {
      return 72 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 6 && rowsLength <= 8) {
      return 70 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 8 && rowsLength <= 10) {
      return 66 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 10 && rowsLength <= 15) {
      return 64 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 15 && rowsLength <= 20) {
      return 62 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 20 && rowsLength <= 25) {
      return 58 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 25 && rowsLength <= 30) {
      return 57 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 30 && rowsLength <= 35) {
      return 56 * Math.min(perPage, rowsLength);
    } else if (rowsLength > 35) {
      return 55 * Math.min(perPage, rowsLength);
    }
  } else {
    return 170;
  }

  return 170;
};

export const sleep = async (millis): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, millis));
};

/**
 * @returns {boolean} - checks if a date is more than 10 months
 */
export const isMoreThanTenMonths = (startedAtDate: string): boolean => {
  const tenMonths = 1000 * 3600 * 24 * 304;
  const dateNow = Date.now();
  const startedDateNum = Date.parse(startedAtDate);

  return (dateNow - startedDateNum) / tenMonths > 1;
};

/**
 * Determines whether a student is a first year student or not;
 * Requirements:
 *  - Bachelor student (BSc, BA, Bachelor, LLB)
 *  - Only one education so far
 * @returns {boolean} A bool deciding if student is in their first year.
 */
export const isFirstYearStudent = (student: ProfileData): boolean => {
  return (
    student.education.length === 1 &&
    BachelorDegrees.includes(student.education[0].degree) &&
    !isMoreThanTenMonths(student.education[0].started_at)
  );
};

/**
 * @returns {boolean} - checks if a date is more than 1 month
 */
export const isMoreThanOneMonth = (
  startedAtDate: string,
  numberOfAttempts: number,
): boolean => {
  const oneMonth = 1000 * 3600 * 24 * 30;
  const dateNow = Date.now();
  const startedDateNum = Date.parse(startedAtDate);
  if (numberOfAttempts > 1) {
    return (dateNow - startedDateNum) / (oneMonth * 6) > 1;
  }
  return (dateNow - startedDateNum) / oneMonth > 1;
};

export const canRetryAssessment = async (): Promise<boolean> => {
  const studentAssessments: AssessmentParams[] = await getStudentAssessments();

  // Sort them by started date desc, to get last one
  studentAssessments
    .sort(function (a, b) {
      return Date.parse(a.started_at) - Date.parse(b.started_at);
    })
    .reverse();
  return (
    studentAssessments.length === 0 ||
    isMoreThanOneMonth(
      studentAssessments[0].started_at,
      studentAssessments[0].number_of_attempts,
    )
  );
};

export const getTestResults = async (
  studentUid: string,
): Promise<AssessmentParams[]> => {
  const studentAssessments: AssessmentParams[] =
    await adminGetStudentAssessments(studentUid);
  studentAssessments
    .sort(function (a, b) {
      return Date.parse(a.started_at) - Date.parse(b.started_at);
    })
    .reverse();
  return studentAssessments;
};

/**
 * Extracts a query parameter value from location object
 *
 * @param {Location} location - The current location object
 * @param {string} parameterName - The parameter to extract from location object
 *
 * @return {(string | null)} - a query parameter value
 */
export const extractQueryParameter = (
  location: Location,
  parameterName: string,
): string | null => {
  const parameters = location.search.replace('?', '').split('&');
  const parameter = parameters.find((param) => param.includes(parameterName));

  if (parameter) {
    return parameter.split('=')[1];
  }

  return null;
};

export const getCountryName = (code: string): string | undefined => {
  return Countries.find((country) => country.code === code)?.name;
};

export const getCountryCode = (name: string): string | undefined => {
  return Countries.find((country) => country.name === name)?.code;
};
