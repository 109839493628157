import React, { useEffect, useState } from 'react';
import './AccountForms.scss';
import {
  Alert,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useConfirmEmail } from '../../../../data/user';
import {
  UpdateUserInterface,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Auth } from 'aws-amplify';
import MiniteSnackbar from '../../../../components/snackbar';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';
import { useUserData } from '../../../../stores/auth';

const schema = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^\S.*$/)
      .required(),
    last_name: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^\S.*$/)
      .required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .max(12)
      .matches(/^\+?\d+$/, {
        message: 'Phone is required',
      })
      .required(),
  })
  .required();

interface AccountFormInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

const AccountForm = () => {
  const user = useUserData((state) => state.user);
  const setUser = useUserData((state) => state.setUserData);
  const refetch = useUserData((state) => state.refetchUser);
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const email = user?.email;
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [emailUnverified, setEmailUnverified] = useState(false);
  const [emailChangeInprogress, setEmailChangeInProgress] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [isResetVerificationCode, setIsResetVerificationCode] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);

  const { mutateAsync: confirmEmail, isLoading: isConfirmedLoading } =
    useConfirmEmail();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty, isSubmitted },
  } = useForm<AccountFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone: user?.phone,
    },
    reValidateMode: 'onChange',
  });

  const checkEmailUnverified = () => {
    if (
      user?.email_unverified &&
      user?.email_unverified !== user?.email &&
      isDirty
    ) {
      setEmailUnverified(true);
    } else {
      if (!emailChangeInprogress) setEmailUnverified(false);
    }
  };

  const discardIncompleteEmailChange = () => {
    if (user?.email_unverified && !emailChangeInprogress) {
      (async () => {
        try {
          // just discard it because the user didn't complete verication last time
          await updateUserProfile({
            user: {
              email_unverified: null,
            } as unknown as UpdateUserInterface,
          });
          await refetch();
          setValue('email', user.email);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  };

  useEffect(discardIncompleteEmailChange, []);
  useEffect(checkEmailUnverified, [user]);

  const onSubmit = async (data: AccountFormInterface) => {
    setIsLoading(true);

    try {
      await updateUserProfile({ user: data as unknown as UpdateUserInterface });

      if (email !== data?.email) {
        setUser({
          ...user,
          email_verified: false,
        });
        setEmailChangeInProgress(true);
      }

      await refetch();

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailVerify = async (event, code: string) => {
    if (event && code) {
      event?.preventDefault();

      try {
        await confirmEmail({ code });
        setSuccessMessage('Email verification was successful');
        await setIsLoading(false);
        await Auth.currentAuthenticatedUser();
        await Auth.currentSession();

        await refetch();
        setEmailChangeInProgress(false);
      } catch (error) {
        setErrorMessage(error.message.toString());
        setIsLoading(false);
      }
    }
  };

  const [resetCodeSent, setResetCodeSent] = useState(false);

  let sentTime: number;
  let sentInterval;

  function checkTimeFinished() {
    const currentTime = Date.now();
    sentTime = parseInt(localStorage.getItem('sent_time'));

    if (currentTime - sentTime > 60000) {
      clearInterval(sentInterval);
      localStorage.removeItem('sent_time');
      setResetCodeSent(false);
      setIsResetVerificationCode(false);
    }
  }

  const handleSendVerifyCode = async (event) => {
    event?.preventDefault();
    setIsResetLoading(true);

    await updateUserProfile({
      user: { email: getValues().email } as unknown as UpdateUserInterface,
    });

    await setIsResetLoading(false);
    await setIsResetVerificationCode(true);
    setResetCodeSent(true);
    localStorage.setItem('sent_time', Date.now() + '');
    sentInterval = setInterval(checkTimeFinished, 1000);
  };

  const isGoogleLogin = JSON.parse(
    localStorage.getItem('amplify-signin-with-hostedUI'),
  );

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">Account</p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <FormGroup className="form-group">
              <TextField
                className="form-group-input form-input"
                {...register('first_name')}
                name="first_name"
                type="text"
                id="first-name-input"
                variant="standard"
                label={t('first_name')}
                error={!!errors.first_name}
                InputLabelProps={{ shrink: true }}
                data-testid="first-name"
              />
              <TextField
                className="form-group-input form-input"
                type="text"
                id="last-name-input"
                {...register('last_name')}
                variant="standard"
                label={t('last_name')}
                name="last_name"
                error={!!errors.last_name}
                InputLabelProps={{ shrink: true }}
                data-testid="last-name"
              />
            </FormGroup>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange } }) => (
                <TextField
                  className="form-input"
                  {...register('email')}
                  defaultValue={user?.email_unverified || user?.email}
                  name="email"
                  type="text"
                  id="email-input"
                  variant="standard"
                  label="Email"
                  error={!!errors.email}
                  helperText={isGoogleLogin ? '' : t('email_verify_text')}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChange}
                  data-testid="email"
                  InputProps={{
                    endAdornment: !user?.email_unverified && (
                      <InputAdornment position="end">
                        <Tooltip
                          title={'Email Verified Status'}
                          placement="right"
                        >
                          <VerifiedIcon className="email-verified-icon" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                    readOnly: isGoogleLogin,
                  }}
                />
              )}
            />

            {emailUnverified && (
              <div className="verification-email-container">
                <Alert severity="error" className="verification-alert-bar">
                  <div className="verification-content">
                    <p className="verification-text">
                      {t('email_verify_alarm')}
                    </p>
                    <LoadingButton
                      className="email-verification-button"
                      onClick={handleSendVerifyCode}
                      isDisabled={resetCodeSent}
                      confirmButtonTestId="send-verification-code"
                      sx={{
                        minWidth: 190,
                      }}
                      buttonText={
                        isResetVerificationCode
                          ? t('verification_code_sent')
                          : t('email_the_code')
                      }
                      isLoading={isResetLoading}
                    />
                  </div>
                </Alert>

                <div className="verification-code-container">
                  <TextField
                    className="form-input"
                    name="verification-code"
                    type="text"
                    id="verification-code-input"
                    variant="standard"
                    label={t('verification_code')}
                    data-testid="verification-code"
                    onChange={(event) =>
                      setVerificationCode(event.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="verify-button-container"
                        >
                          <LoadingButton
                            className="save-button verify-button"
                            onClick={(event) =>
                              handleEmailVerify(event, verificationCode)
                            }
                            isDisabled={!verificationCode}
                            buttonText={t('verify')}
                            isLoading={isConfirmedLoading}
                            confirmButtonTestId="verify-button-account-form"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            )}

            <TextField
              className="form-input"
              {...register('phone')}
              name="phone"
              type="text"
              id="phone-input"
              variant="standard"
              label={t('phone_number')}
              inputProps={{ maxLength: 12 }}
              error={!!errors.phone}
              InputLabelProps={{ shrink: true }}
              data-testid="phone-number"
            />
          </form>

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              confirmButtonTestId="save-changes-account-form"
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <MiniteSnackbar
        severity="success"
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </section>
  );
};

export default AccountForm;
