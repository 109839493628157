import React, { useEffect, useState } from 'react';
import './BrowseJobs.scss';
import {
  Box,
  Checkbox,
  checkboxClasses,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Slider,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import {
  JobLocation,
  JobLocations,
  JobType,
  StudentType,
  useFetchFavorites,
  useSearchJobs,
} from '../../data/jobs';
import { JobCard } from '../../components/jobs/JobCard';
import { useTranslation } from 'react-i18next';
import { useFetchCategories } from '../../data/shared';
import { UserData } from '../../data/user';
import { useDebounce } from '../../utils/useDebounce';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import { useFetchLanguages } from '../../data/edit-profile';
import { useUserData } from '../../stores/auth';
import {
  studentRegistrationNotComplete,
  useFetchStudentById,
} from '../../data/profile';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface JobsSearchRequest {
  job_type?: Array<JobType>;
  student_type?: Array<StudentType>;
  job_location?: Array<JobLocation>;
  language_uid?: Array<string>;
  category_uid?: Array<string>;
  budget_min?: number;
  budget_max?: number;
  hours_min?: number;
  hours_max?: number;
  favorites?: boolean;
  query?: string;
  page?: number;
  per_page?: number;
  open?: boolean;
  direct_job?: boolean;
  direct_job_type?: Array<string>;
}

const defaultValues = {
  favorites: false,
  priceRange: [15, 30000],
  job_type: {
    'one-time': false,
    ongoing: false,
  },
  student_type: {
    regular: false,
    'entry-level': false,
  },
  job_location: {
    remote: false,
    office: false,
    hybrid: false,
  },
  language_uid: {
    eng: false,
    dut: false,
    other: false,
  },
  category_uid: {},
  open: false,
  direct_job: false,
};

const prepareSearchParams = (values, lang) => {
  const request: JobsSearchRequest = {
    job_type: [],
    student_type: [],
    job_location: [],
    category_uid: [],
    language_uid: [],
    favorites: false,
    query: '',
    page: 1,
    per_page: 8,
    open: false,
    direct_job: false,
    direct_job_type: [],
  };

  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === 'object') {
      Object.entries(value).forEach(([intKey, intValue]) => {
        if (key === 'language_uid' && intValue) {
          const langList = lang.reduce((acc, item) => {
            if (
              item.code === intKey ||
              (intKey === 'other' && item.code !== 'eng' && item.code !== 'dut')
            ) {
              acc.push(item.language_uid);
            }
            return acc;
          }, []);

          request[key].push(...langList);
        } else if (typeof intValue === 'boolean' && intValue) {
          request[key].push(intKey);
        }

        if (typeof intValue === 'number') {
          if (intKey === '0') {
            request.budget_min = intValue;
          }

          if (intKey === '1') {
            request.budget_max = intValue;
          }
        }
      });
    } else if (typeof value === 'string') {
      request[key] = value;
    } else if (typeof value === 'boolean') {
      request[key] = value;
    }
  });

  Object.keys(request).forEach((key) => {
    if (
      request[key] === undefined ||
      (request[key].hasOwnProperty('length') && !request[key]?.length)
    ) {
      delete request[key];
    }
  });

  return request;
};

const BrowseJobs = () => {
  useDocumentTitle('Browse Jobs');

  const isTabView = useMediaQuery('(max-width: 899px)');
  const [isFiltersShow, setShowFilters] = useState(!isTabView);
  const navigation = useNavigate();
  const userData = useUserData((state) => state.user);
  const user: UserData = userData as unknown as UserData;
  const [searchParams, setSearchParams] = useState({});
  const debouncedFilter = useDebounce(searchParams, 500);
  const { data: languages = [] } = useFetchLanguages();
  const { control, watch, register, getValues } = useForm<any>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const filterLabelStyles = {
    fontFamily: '"Sofia Pro Medium", sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000000',
    marginBottom: '20px',
  };
  const { t } = useTranslation();
  const { data: categories = [] } = useFetchCategories();
  const { data: favorites = [], refetch: refetchFavorites } =
    useFetchFavorites();

  const {
    data: studentJobs,
    isLoading,
    refetch: reloadJobs,
  } = useSearchJobs(debouncedFilter);

  const refreshData = async () => {
    await refetchFavorites();
    await reloadJobs(debouncedFilter);
  };

  const { data: student } = useFetchStudentById(user.student_uid);
  /**
   * Assume student has completed registration
   */
  const [registrationNotComplete, setRegistrationNotComplete] = useState(false);

  useEffect(() => {
    // Only check after student has been loaded
    if (student) {
      setRegistrationNotComplete(studentRegistrationNotComplete(student));
    }
  }, [student]);

  useEffect(() => {
    const subscription = watch((value) => {
      const payload = {
        ...value,
        priceRange: [+value?.priceRange[0], +value?.priceRange[1]],
      };

      setSearchParams(prepareSearchParams(payload, languages));
    });
    return () => subscription.unsubscribe();
  }, [watch, languages]);

  const setPageApi = (value) => {
    setSearchParams({
      ...searchParams,
      page: value,
    });
  };

  useEffect(() => {
    if (user.student_uid === null) {
      navigation(Routes.Forbidden);
    }
  }, [user]);

  const handleShowFilters = () => {
    setShowFilters(!isFiltersShow);
  };

  return (
    <Grid
      container
      className="browse-job-container"
      columnGap={4}
      sx={{
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <p className={'page-title'}>{t('header.browse_jobs')}</p>
        {isTabView && !isFiltersShow && (
          <FilterListIcon onClick={handleShowFilters} />
        )}
        {isTabView && isFiltersShow && (
          <CloseIcon onClick={handleShowFilters} />
        )}
      </Grid>
      {(!isTabView || isFiltersShow) && (
        <Grid
          item
          md={3}
          lg={2}
          sm={isTabView ? 12 : 1}
          xs={isTabView ? 12 : 1}
          className="browse-job-filters"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.favourites')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name="favorites"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.favourites_only')}
                      {...field}
                      control={
                        <Checkbox
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                          onChange={onChange}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.open')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name="open"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.open_only')}
                      {...field}
                      control={
                        <Checkbox
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                          onChange={onChange}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.job_type')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name={'job_type.one-time'}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label="One time job"
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name="job_type.ongoing"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.ongoing_job')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
                <Divider />
                <>
                  <Controller
                    name={`direct_job_type.fulltime`}
                    control={control}
                    render={({ field, field: { onChange } }) => (
                      <FormControlLabel
                        className="filter-label"
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                            }}
                          >
                            {t('filters.fulltime')}
                            <Tooltip
                              title={`Direct jobs are directly with the company, and are not
                                freelance jobs.`}
                              placement="right"
                            >
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </Box>
                        }
                        {...field}
                        control={
                          <Checkbox
                            defaultChecked={getValues(
                              'direct_job_type.fulltime',
                            )}
                            onChange={onChange}
                            sx={{
                              [`&.${checkboxClasses.checked}`]: {
                                color: '#DEB900',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`direct_job_type.internship`}
                    control={control}
                    render={({ field, field: { onChange } }) => (
                      <FormControlLabel
                        className="filter-label"
                        label={t('filters.internship')}
                        {...field}
                        control={
                          <Checkbox
                            defaultChecked={getValues(
                              'direct_job_type.internship',
                            )}
                            onChange={onChange}
                            sx={{
                              [`&.${checkboxClasses.checked}`]: {
                                color: '#DEB900',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`direct_job_type.traineeship`}
                    control={control}
                    render={({ field, field: { onChange } }) => (
                      <FormControlLabel
                        className="filter-label"
                        label={t('filters.traineeship')}
                        {...field}
                        control={
                          <Checkbox
                            defaultChecked={getValues(
                              'direct_job_type.traineeship',
                            )}
                            onChange={onChange}
                            sx={{
                              [`&.${checkboxClasses.checked}`]: {
                                color: '#DEB900',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`direct_job_type.inhouse`}
                    control={control}
                    render={({ field, field: { onChange } }) => (
                      <FormControlLabel
                        className="filter-label"
                        label={t('filters.inhouse')}
                        {...field}
                        control={
                          <Checkbox
                            defaultChecked={getValues(
                              'direct_job_type.inhouse',
                            )}
                            onChange={onChange}
                            sx={{
                              [`&.${checkboxClasses.checked}`]: {
                                color: '#DEB900',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />
                </>

                <Controller
                  name={`direct_job_type.event`}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.event')}
                      {...field}
                      control={
                        <Checkbox
                          defaultChecked={getValues('direct_job_type.event')}
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
                {/* )} */}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                Salary range, €
              </FormLabel>
              <FormGroup>
                <Box
                  className="range-fields"
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'baseline',
                    marginBottom: '24px',
                    flexDirection: 'row',
                  }}
                >
                  <TextField
                    sx={{
                      width: '48%',
                    }}
                    type="number"
                    label="Min"
                    variant="standard"
                    value={getValues().priceRange[0]}
                    {...register(`priceRange.${0}`)}
                    defaultValue={15}
                  />

                  <TextField
                    sx={{
                      width: '48%',
                    }}
                    type="number"
                    id="max_budget"
                    label="Max"
                    variant="standard"
                    value={getValues().priceRange[1]}
                    {...register(`priceRange.${1}`)}
                    defaultValue={30000}
                  />
                </Box>

                <Controller
                  name="priceRange"
                  control={control}
                  defaultValue={[15, 1500]}
                  render={({ field, field: { onChange, value } }) => {
                    return (
                      <Slider
                        {...field}
                        value={value}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        sx={{
                          maxWidth: isTabView ? '100%' : 200,
                          [`& .MuiSlider-thumb, & .MuiSlider-track`]: {
                            color: '#DEB900',
                          },
                        }}
                        valueLabelDisplay="auto"
                        min={15}
                        max={30000}
                        step={1}
                      />
                    );
                  }}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.category')}
              </FormLabel>
              <FormGroup>
                {categories.length &&
                  categories.map((item) => (
                    <Controller
                      key={item.category_uid}
                      name={'category_uid.' + item.category_uid}
                      control={control}
                      render={({ field, field: { onChange } }) => (
                        <FormControlLabel
                          className="filter-label"
                          label={t(`categories.${item.code}`)}
                          {...field}
                          control={
                            <Checkbox
                              onChange={onChange}
                              sx={{
                                [`&.${checkboxClasses.checked}`]: {
                                  color: '#DEB900',
                                },
                              }}
                            />
                          }
                        />
                      )}
                    />
                  ))}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.exp_level')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name="student_type.entry-level"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.entry_level')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name="student_type.regular"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.exp_level_high_flyer')}
                      {...field}
                      control={
                        <Checkbox
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                          onChange={onChange}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.language')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name={`language_uid.eng`}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.english')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name={`language_uid.dut`}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.dutch')}
                      {...field}
                      control={
                        <Checkbox
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                          onChange={onChange}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  name="language_uid.other"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.other')}
                      {...field}
                      control={
                        <Checkbox
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                          onChange={onChange}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  ...filterLabelStyles,
                }}
                component="legend"
              >
                {t('filters.work_location')}
              </FormLabel>
              <FormGroup>
                <Controller
                  name={'job_location.' + JobLocations.OFFICE}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.office')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name={'job_location.' + JobLocations.REMOTE}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.remote')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name={'job_location.' + JobLocations.HYBRID}
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="filter-label"
                      label={t('filters.hybrid')}
                      {...field}
                      control={
                        <Checkbox
                          onChange={onChange}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      )}
      <Grid item md={8} lg={9} sm={12} className="browse-job-pool">
        <Controller
          name="query"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              placeholder="Search"
              helperText={`${studentJobs?.pagination.total || 0} results`}
              sx={{ width: '100%' }}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {isLoading && <span>loading...</span>}
        {!isLoading && (
          <Grid container className="browse-job-list">
            {studentJobs?.jobs.map((item) => {
              return (
                <JobCard
                  favorites={favorites}
                  refetchFavorites={refreshData}
                  studentType={user?.student?.student_type as StudentType}
                  job={item}
                  key={item.jobUid}
                  isVerified={user.verified}
                  registrationNotComplete={registrationNotComplete}
                />
              );
            })}
          </Grid>
        )}

        {studentJobs?.pagination.total > 8 && (
          <div className="pool-pagination">
            <Stack spacing={1}>
              <Pagination
                page={searchParams['page']}
                onChange={(e, value) => setPageApi(value)}
                count={Math.ceil(
                  studentJobs.pagination.total / studentJobs.pagination.perPage,
                )}
                showFirstButton
                showLastButton
              />
            </Stack>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default BrowseJobs;
