import React from 'react';
import { Typography } from '@mui/material';
import './Maintenance.scss';

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <Typography variant="h6">
        We are currently undergoing maintenance and will be back in a few hours.
        Check back in to experience our brand-new platform! In the meantime,
        please find us on Instagram{' '}
        <a
          href="https://www.instagram.com/miniteworks/"
          target="_blank"
          className="link"
        >
          @miniteworks
        </a>{' '}
        where we will provide updates as well.
      </Typography>

      <img src="/images/team.jpg" alt="Team" className="team-img" />
      <div className="photo-description">
        Meanwhile, please enjoy this photo of Micky and Levi working behind the
        scenes to get everything ready for you!
      </div>
    </div>
  );
};

export default Maintenance;
