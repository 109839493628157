import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  INDUSTRIES,
  JOB_INTERESTS,
  LANGUAGES,
  SKILLS,
  UNIVERSITIES,
} from './query-keys';
import { Profile } from './profile';

const updateCompanyLogo = async (payload: {
  filename: string;
  contentType: string;
}) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me/upload`,
    method: 'POST',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useUpdateCompanyLogo = () => {
  return useMutation(
    (payload: {
      filename: string;
      contentType: string;
      for_user_uid?: string;
    }) => updateCompanyLogo(payload),
    {
      onError: (e) => console.error(e),
    },
  );
};

const updateUserProfile = async (payload: UpdateUserProfile) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me`,
    method: 'put',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
};

export const useUpdateUserProfile = () => {
  return useMutation(
    (payload: UpdateUserProfile) => updateUserProfile(payload),
    {
      onError: (e) => console.error(e),
    },
  );
};

const deleteUserAccount = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me`,
    method: 'delete',
  });
  if (!data.success) throw new Error('delete user profile, code: ' + data.code);
};

export const useDeleteUserAccount = () => {
  return useMutation(() => deleteUserAccount(), {
    onError: (e) => console.error(e),
  });
};

const deactivateUserAccount = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me/deactivate`,
    method: 'post',
  });
  if (!data.success)
    throw new Error('deactivate user profile, code: ' + data.code);
};

export const useDeactivateAccount = () => {
  return useMutation(() => deactivateUserAccount(), {
    onError: (e) => e,
  });
};

const confirmDeleteUserAccount = async (code: string) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/me/delete/${code}`,
    method: 'post',
  });
  if (!data.success) throw new Error('delete user profile, code: ' + data.code);
};

export const useConfirmDeleteUserAccount = () => {
  return useMutation((code: string) => confirmDeleteUserAccount(code), {
    onError: (e) => console.error(e),
  });
};

const fetchIndustries = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/interests',
    method: 'get',
  });
  if (!data.success)
    throw new Error('fetch industries failed, code: ' + data.code);
  return data.data;
};

export const useFetchIndustries = () => {
  return useQuery([INDUSTRIES], () => fetchIndustries(), {
    onError: (e) => console.error(e),
  });
};

const fetchJobInterests = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/job_interests',
    method: 'get',
  });
  if (!data.success)
    throw new Error('fetch job interests failed, code: ' + data.code);
  return data.data;
};

export const useFetchJobInterests = () => {
  return useQuery([JOB_INTERESTS], () => fetchJobInterests(), {
    onError: (e) => console.error(e),
  });
};

interface UniversityRecord {
  university_uid: string;
  university_name: string;
}

const fetchUniversities = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/universities',
    method: 'get',
  });
  if (!data.success)
    throw new Error('fetch universities failed, code: ' + data.code);
  return data.data as UniversityRecord[];
};

export const useFetchUniversities = () => {
  return useQuery([UNIVERSITIES], () => fetchUniversities(), {
    onError: (e) => console.error(e),
  });
};

const fetchLanguages = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/languages',
    method: 'get',
  });
  if (!data.success)
    throw new Error('fetch languages failed, code: ' + data.code);
  return data.data;
};

export const useFetchLanguages = () => {
  return useQuery([LANGUAGES], () => fetchLanguages(), {
    onError: (e) => console.error(e),
  });
};

const fetchSkills = async () => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_API_URL + '/api/v1/dictionary/skills',
    method: 'get',
  });
  if (!data.success) throw new Error('fetch skills failed, code: ' + data.code);
  return data.data;
};

export const useFetchSkills = () => {
  return useQuery([SKILLS], () => fetchSkills(), {
    onError: (e) => console.error(e),
  });
};

export interface UpdateUserProfile {
  user?: UpdateUserInterface;
  company?: UpdateCompanyInterface;
  student?: UpdateStudentInterface;
}

export interface UpdateUserInterface {
  deactivate_reason?: string;
  kvk_number?: string;
  how_did_you_find_minite?: string;
  other_how_did_you_find_minite?: string;
  street_name?: string;
  house_number?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  iban?: string;
  vat_number?: number;
  phone?: number;
  avatar?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  invoice_email?: string;
}

export interface UpdateCompanyInterface {
  company_name?: string;
  website?: string;
  bio?: string;
  interest_uid?: string;
  admin?: boolean;
}

export interface UpdateStudentInterface {
  company_name?: string;
  kor_approved_at?: string;
  hourly_rate?: number;
  one_liner?: string;
  tags?: string[];
  profile_summary?: string;
  profile?: Profile;
  job_interests?: StudentJobInterestDto[];
  languages?: StudentLanguageDto[];
  certificates?: StudentCertificateDto[];
  education?: StudentEducationDto[];
  work_experience?: StudentWorkExperienceDto[];
  personal_sentences?: PersonalSentenceDto[];
  personality_type?: string;
}

export interface PersonalSentenceDto {
  question: string;
  response: string;
}

export interface StudentLanguageDto {
  language_uid: string;
  level: string;
}

export interface StudentCertificateDto {
  certificate_name: string;
}

export interface StudentEducationDto {
  university_uid: string;
  degree: string;
  study: string;
  started_at: string;
  ended_at?: string;
}

export interface StudentJobInterestDto {
  job_interest_uid: string;
}

export interface StudentSkillDto {
  skill_uid: string;
}

export interface StudentWorkExperienceDto {
  organization_name: string;
  job_title: string;
  job_type: string;
  job_description: string;
  started_at: string;
  ended_at?: string;
  skills?: StudentSkillDto[];
}
