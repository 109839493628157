import React from 'react';
import { FormGroup, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

interface IProps {
  names: string[];
  min: string;
  max: string;
  values: { min: number; max: number | string };
  control: any;
}

const MinMax = ({ min, max, values, names, control }: IProps) => {
  return (
    <FormGroup row={true} className="min-max-block">
      <Controller
        defaultValue={min}
        render={({ field, field: { value, onChange } }) => (
          <TextField
            {...field}
            type="number"
            id="min-input"
            label={'{Min}'}
            variant="standard"
            name={names[0]}
            value={value}
            InputProps={{ inputProps: values }}
            onChange={(value) => onChange(value)}
          />
        )}
        control={control}
        name={names[0]}
      />
      <span className="separator">To</span>
      <Controller
        defaultValue={max}
        render={({ field, field: { value, onChange } }) => (
          <TextField
            {...field}
            type="number"
            id="max-input"
            label="{Max}"
            variant="standard"
            value={value}
            name={names[1]}
            InputProps={{ inputProps: values }}
            onChange={(value) => onChange(value)}
          />
        )}
        control={control}
        name={names[1]}
      />
    </FormGroup>
  );
};

export default MinMax;
