import { useQuery } from '@tanstack/react-query';
import { ADMIN_TIMESHEET_STATISTICS, ADMIN_TIMESHEETS } from './query-keys';
import { AxiosError } from 'axios';
import { request, ServerResponse } from '../core/request';
import { Search } from 'react-router-dom';

const timesheetStatistic = async () => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/statistics/timesheets`,
    method: 'get',
  });

  return data.data as AdminTimesheetsStatisticsResponse;
};

export const useTimesheetStatistic = () => {
  return useQuery([ADMIN_TIMESHEET_STATISTICS], () => timesheetStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const searchAdminTimesheets = async (body?: TimesheetsSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/timesheets/search`,
    method: 'post',
    data: body,
  });

  return data.data as unknown as TimesheetsSearchResponse;
};

export const useSearchAdminTimesheets = (body: TimesheetsSearchRequest) => {
  return useQuery([ADMIN_TIMESHEETS, body], () => searchAdminTimesheets(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface AdminTimesheetsStatisticsResponse {
  total_hours_this_month: number;
  total_jobs_in_progress_this_month: number;
  total_timesheets_this_month: number;
  percentage_timesheets_approved_this_month: number;
  expected_hours_this_month: number;
  min_hours_this_month: number;
}

export interface TimesheetsSearchRequest {
  query?: string;
  page?: number;
  per_page?: number;
}

export interface TimesheetsSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  timesheets: TimesheetResponse[];
  total_hours: number;
}

export interface TimesheetResponse {
  created_at: Date;
  hours_sum: number;
  timesheet_status: string;
  timesheet_uid: string;
  year: number;
  month: number;
  payout: number;
  rate: number;
  company: {
    company_name: string;
    company_uid: string;
  };
  job: {
    job_title: string;
    job_uid: string;
    job_type?: string;
  };
  student: {
    first_name: string;
    last_name: string;
    student_uid: string;
    user_uid: string;
  };
}
