import create from 'zustand';

export interface CompanyForm {
  company: string;
  kvk: string;
  street: string;
  house: string;
  postal: string;
  city: string;
  manual: boolean;
  showAllFields: boolean;
  otherReason: string;
  reason: string;
  country: string;
}

interface CompanyFormState {
  companyFormState: CompanyForm;
  setCompanyFormState: (formState: CompanyForm) => void;
  resetFormState: () => void;
}

export const initialCompanyFormState = {
  company: '',
  kvk: '',
  street: '',
  house: '',
  postal: '',
  city: '',
  reason: '',
  otherReason: '',
  manual: false,
  showAllFields: false,
  country: 'Netherlands',
};

export const mockedCompanyFormState = {
  company: 'Test Company',
  kvk: '12345678',
  street: 'Street',
  house: '10',
  postal: '1123CC',
  city: 'LA',
  manual: false,
  showAllFields: true,
};

export const useCompanyForm = create<CompanyFormState>((set) => ({
  companyFormState: initialCompanyFormState,
  setCompanyFormState: (formState) => set({ companyFormState: formState }),
  resetFormState: () =>
    set({
      companyFormState: initialCompanyFormState,
    }),
}));
