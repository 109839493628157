import React, { useEffect, useState } from 'react';
import './MyProfile.scss';
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  Chip,
  Grid,
  Link as MuiLink,
  ListItem,
  ListItemAvatar,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import StarIcon from '@mui/icons-material/Star';
import LanguageIcon from '@mui/icons-material/Language';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import {
  ProfileData,
  ProfileExperience,
  ProfileExperienceEducation,
  ProfileExperienceSkill,
  ProfileLanguage,
  ProfileStudentLanguage,
  ProfileUniversity,
  useFetchStudentById,
} from '../../data/profile';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../utils/dates';
import { StudentTypes, useFetchJobById } from '../../data/jobs';
import {
  useAddStudentToTalentPool,
  useFetchTalentPool,
  useRemoveStudentFromTalentPool,
} from '../../data/talent-pool';
import { Skill } from '../../stores/job-form';
import { Routes as NavigationRoutes, Routes } from '../../utils/consts';
import { UserData } from '../../data/user';
import { useTranslation } from 'react-i18next';
import ReviewsModal from '../../components/reviews-modal/ReviewsModal';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { numberWithDec } from '../../utils/numberWithDec';
import { fetchReviewsByUserType } from '../../data/reviews';
import StudentAvatar from '../../components/student-avatar';
import Ambassador from '../../components/ambassador';
import TopTalent from '../../components/top-talent';
import { useUserData } from '../../stores/auth';
import { sortArrayByDate } from '../../utils/sortArrayByDate';
import { getPersonalQuestionTranslation as PSTranslator } from '../../components/sign-up/components/high-flyer/PersonalForm';
import { getLabelById } from '../../data/students';
interface SkillChipProps {
  title: string;
}

interface EducationItem {
  id: string;
  study: string;
  university: string;
  time_period: string;
}

interface ExperienceItem {
  id: string;
  job_title: string;
  type_and_company: string;
  time_period: string;
  job_description: string;
  skills: string[];
}

interface LanguageItem {
  id: string;
  language: string;
  level: string;
}

const StudentLanguageLevel = {
  limited: 'Limited working proficiency',
  pro: 'Professional working proficiency',
  native: 'Native/bilingual proficiency',
};

const SkillChip = ({ title }: SkillChipProps) => {
  const chipStyle = {
    width: 'auto',
    height: 32,
    padding: 0,
    fontSize: '12px',
    backgroundColor: '#F2F9FC',
    color: '#393A42',
    fontFamily: 'Sofia W05 Medium, sans-serif',
  };

  return <Chip sx={chipStyle} label={title} key={title} />;
};

const getEducationListItem = (item: EducationItem) => {
  return (
    <ListItem className="education-list-item" key={item?.id}>
      <ListItemAvatar>
        <SchoolOutlinedIcon />
      </ListItemAvatar>
      <div className="student-education-item">
        <span className="study-label">{item?.study}</span>
        <ListItemText
          className="secondary-labels"
          primary={item?.university}
          secondary={item?.time_period}
        />
      </div>
    </ListItem>
  );
};

const getExperienceListItem = (item: ExperienceItem) => {
  return (
    <ListItem className="experience-list-item" key={item?.id}>
      <ListItemAvatar>
        <WorkOutlineOutlinedIcon />
      </ListItemAvatar>
      <div className="list-item-container">
        <div className="student-education-item">
          <span className="study-label">{item?.job_title}</span>
          <ListItemText
            className="secondary-labels"
            primary={item?.type_and_company}
            secondary={item?.time_period}
          />
        </div>

        <div className="skills-row">
          {item?.skills?.map((skill, index) => (
            <SkillChip title={skill} key={skill + index} />
          ))}
        </div>

        <p className="work-description">{item?.job_description}</p>
      </div>
    </ListItem>
  );
};

const getLanguageListItem = (item: LanguageItem) => {
  return (
    <ListItem className="language-list-item" key={item?.id}>
      <ListItemAvatar className="language-item-avatar">
        <LanguageIcon />
      </ListItemAvatar>
      <div className="student-language-item">
        <span className="language-label">{item?.language}</span>
        <ListItemText
          className="language-secondary-label"
          secondary={StudentLanguageLevel[item?.level] || item?.level}
        />
      </div>
    </ListItem>
  );
};

const getFormatLanguageList = (
  languages: ProfileLanguage[],
  studentLanguages: ProfileStudentLanguage[],
): LanguageItem[] => {
  return languages?.map((lang) => {
    return {
      id: lang?.language_uid,
      language: lang?.language_name,
      level: studentLanguages.find(
        (studentLang) => studentLang?.language_uid === lang?.language_uid,
      )?.level,
    };
  });
};

const getFormatEducationList = (
  education: ProfileExperienceEducation[],
  universities: ProfileUniversity[],
) => {
  return education
    ?.sort((a: ProfileExperienceEducation, b: ProfileExperienceEducation) =>
      sortArrayByDate(a, b),
    )
    .map((item) => {
      return {
        id: item?.university_uid,
        study: `${item?.degree} ${item?.study}`,
        university: universities?.find(
          (university) => university?.university_uid === item?.university_uid,
        )?.university_name,
        time_period: `${formatDate(item?.started_at, 'MMMM YYYY')} - ${
          item?.ended_at ? formatDate(item?.ended_at, 'MMMM YYYY') : 'Present'
        }`,
      };
    });
};

const getFormatExperienceList = (
  experiences: ProfileExperience[],
  skills: ProfileExperienceSkill[],
  originalSkills: Skill[],
): ExperienceItem[] => {
  return experiences
    ?.sort((a: ProfileExperience, b: ProfileExperience) =>
      sortArrayByDate(a, b),
    )
    .map((experience) => {
      return {
        id: experience?.student_experience_uid,
        job_title: experience?.job_title,
        job_description: experience?.job_description,
        type_and_company: `${experience?.job_type}, ${experience?.organization_name}`,
        time_period: `${formatDate(experience?.started_at, 'MMMM YYYY')} - ${
          experience?.ended_at
            ? formatDate(experience?.ended_at, 'MMMM YYYY')
            : 'Present'
        }`,
        skills: skills?.reduce((acc, expSkill) => {
          const skill =
            experience?.student_experience_uid ===
              expSkill?.student_experience_uid &&
            originalSkills?.find(
              (originalSkill) =>
                originalSkill?.skill_uid === expSkill?.skill_uid,
            )?.skill_name;

          skill && acc.push(skill);

          return acc;
        }, []),
      };
    });
};

function compareLevels(a, b) {
  const levels = {
    'Native/bilingual proficiency': 0,
    'Professional working proficiency': 1,
    'Limited working proficiency': 2,
  };

  if (levels[a.level] !== levels[b.level]) {
    return levels[a.level] - levels[b.level];
  }

  return a.language.localeCompare(b.language);
}

const MyProfile = () => {
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const params = useParams();
  const { jobUid, profileId } = params;
  let userProfileId = profileId;
  const userData = useUserData((state) => state.user);
  if (!userProfileId) {
    userProfileId = (userData as UserData)?.student_uid;
  }

  const { t } = useTranslation();

  const user: UserData = userData as unknown as UserData;
  const byCompany = !!user?.company_uid;
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [reviews, setReviews] = useState([]);

  useDocumentTitle(profileId ? 'Student Profile' : 'My Profile');

  const { data: job } = useFetchJobById(jobUid);
  const { data: profile } = useFetchStudentById(userProfileId);

  const { data: talentPool, refetch: refreshTalentPool } =
    useFetchTalentPool(byCompany);
  const existInTalentPool = Boolean(
    talentPool?.find((talent) => talent.student.student_uid === profileId),
  );
  const { mutateAsync: addStudentToTalentPool } =
    useAddStudentToTalentPool(profileId);
  const { mutateAsync: removeStudentFromTalentPool } =
    useRemoveStudentFromTalentPool(profileId);
  const profileData = profile as unknown as ProfileData;
  const profileUser = profileData?.user;
  let uniqueSkills = profileData?.experience_skills?.reduce((acc, skill) => {
    if (skill) {
      acc.push(
        profileData?.skills?.find(
          (originalSkill) => originalSkill?.skill_uid === skill?.skill_uid,
        )?.skill_name,
      );
    }

    return acc;
  }, []);

  useEffect(() => {
    const userId = profileId || user.student_uid;

    if (userId) {
      fetchReviewsByUserType(userId, 'student').then((res) => {
        setReviews(res.reviews);
      });
    }
  }, [profileId, user]);

  if (uniqueSkills?.length) {
    uniqueSkills = [...new Set([...uniqueSkills])] as string[];
  }

  const handleToggleTalentPoolClick = async (event) => {
    event?.preventDefault();
    if (existInTalentPool) {
      await removeStudentFromTalentPool();
    } else {
      await addStudentToTalentPool();
    }
    await refreshTalentPool();
  };

  const handleJobLinkClick = () => {
    const path = generatePath(Routes.JobDetails, {
      jobUid: job?.job_uid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const handleEditProfileClick = () => {
    return navigate(NavigationRoutes.ProfileSettingsRoute, { replace: true });
  };

  const handleShowReviewModal = () => {
    setIsOpenReviewModal(!isOpenReviewModal);
  };

  const { t: t_ps } = useTranslation('translation', {
    keyPrefix: 'personal_sentences',
  });

  return (
    <Box sx={{ flex: '1 1 100%' }}>
      <Grid
        container
        className="profile-container"
        columnGap={2}
        sx={{
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Grid item md={6} className="student-main-info">
          {!!jobUid && !isMobileView && (
            <Breadcrumbs className="student-profile-breadcrumbs">
              <p className="profile-link-label">Jobs </p>
              <MuiLink
                className="profile-link-label profile-link"
                onClick={handleJobLinkClick}
              >
                Job: {job?.job_title}
              </MuiLink>
              <p className="profile-link-label last-link">
                Student: {profileUser?.first_name}
              </p>
            </Breadcrumbs>
          )}

          {!jobUid && !isMobileView && (
            <Breadcrumbs className="student-profile-breadcrumbs">
              <p className="profile-link-label">{t('navigation.my_profile')}</p>
            </Breadcrumbs>
          )}

          <div className="student-description">
            <p className="student-description-text">
              {profileData?.profile_summary}
            </p>
            {profileData?.top_talent && (
              <TopTalent
                styles={{
                  width: '50px',
                  position: 'relative',
                  top: '-20px',
                  right: '-5px',
                }}
              />
            )}
          </div>

          {profileData?.tags && profileData?.tags.length > 0 && (
            <div
              className="tags"
              style={{
                display: 'flex',
                gap: '10px',
                flexWrap: 'wrap',
                paddingBottom: '15px',
              }}
            >
              {profileData?.tags.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      width: 'auto',
                      padding: '6px 6px 7px',
                      border: '1.5px solid #deb900',
                      backgroundColor: 'transparent',
                      color: '#deb900',
                      fontFamily: 'Sofia W05 Medium, sans-serif',
                    }}
                    label={item}
                    className="student-level-chip"
                  />
                );
              })}
            </div>
          )}

          <div className="education-container">
            <p className="title">{t('sign_up_student.education')}</p>

            <List
              className="profile-info-list education-list"
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              {getFormatEducationList(
                profileData?.education,
                profileData?.universities,
              )?.map((item) => getEducationListItem(item))}
            </List>
          </div>

          <div className="work-experience-container">
            <p className="title">{t('sign_up_student.work_exp')}</p>

            <List
              className="profile-info-list experience-list"
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              {getFormatExperienceList(
                profileData?.experiences,
                profileData?.experience_skills,
                profileData?.skills,
              )?.map((item) => getExperienceListItem(item))}
            </List>
          </div>
        </Grid>
        <Grid item md={4} className="student-sidebar-info">
          {!!jobUid && isMobileView && (
            <Breadcrumbs className="student-profile-breadcrumbs">
              <p className="profile-link-label">Jobs </p>
              <MuiLink
                className="profile-link-label profile-link"
                onClick={handleJobLinkClick}
              >
                Job: {job?.job_title}
              </MuiLink>
              <p className="profile-link-label last-link">
                Student: {profileUser?.first_name}
              </p>
            </Breadcrumbs>
          )}

          {!jobUid && isMobileView && (
            <Breadcrumbs className="student-profile-breadcrumbs">
              <p className="profile-link-label">
                {t('navigation.my_profile')}{' '}
              </p>
            </Breadcrumbs>
          )}
          {!jobUid && isMobileView && !byCompany && (
            <ButtonBase
              className="action medium-text"
              onClick={handleEditProfileClick}
              data-testid="edit-profile"
              style={{ marginTop: '15px' }}
            >
              <p className="profile-action">
                <EditIcon />
                <span className="profile-action-label">
                  {t('dashboard.edit_profile')}
                </span>
              </p>
            </ButtonBase>
          )}

          <div className="student-sidebar-header">
            <div className="student-level">
              {profileData?.student_type === StudentTypes.EntryLevel && (
                <Chip
                  sx={{
                    width: 'auto',
                    height: '24px',
                    border: '1.5px solid #1976D2',
                    backgroundColor: 'transparent',
                    color: '#1976D2',
                    fontFamily: "'Sofia W05 Medium', sans-serif",
                  }}
                  label="Entry-level"
                  className="student-level-chip"
                />
              )}
            </div>

            <div className="student-avatar">
              <StudentAvatar avatar={user?.avatar} />
            </div>

            <div className="student-name-wrapper">
              <p className="student-name-alias">{profileUser?.first_name}</p>
              {profileData?.ambassador && <Ambassador />}
            </div>

            <div className="student-rate-rating-row">
              <div className="student-rating-column">
                <div className="rating">
                  <StarIcon className="favourite-rate-star" />
                  <p className="rate-number">
                    {numberWithDec(profileData?.rating)}
                  </p>
                </div>

                <ButtonBase
                  className="review-count"
                  data-testid="show-reviews"
                  onClick={handleShowReviewModal}
                >
                  {reviews.length} reviews
                </ButtonBase>
                <ReviewsModal
                  reviews={reviews}
                  isOpen={isOpenReviewModal}
                  onClose={handleShowReviewModal}
                />
              </div>
            </div>
            {profileData?.personality_type &&
              getLabelById(profileData?.personality_type) && (
                <div className="student-stats">
                  <Chip
                    label={getLabelById(profileData?.personality_type).label}
                    sx={{
                      backgroundColor: getLabelById(
                        profileData?.personality_type,
                      ).color,
                      color: 'white',
                    }}
                  />
                </div>
              )}
            <div className="student-stats">
              <p>
                <span>Jobs in progress:</span>{' '}
                {+profileData?.student_stats?.total_jobs_in_progress || 0}
              </p>
              <p>
                <span>Completed jobs:</span>{' '}
                {+profileData?.student_stats?.total_jobs_completed || 0}
              </p>
              <hr />
              <p>
                <span>Attention to detail:</span>{' '}
                {!profileData?.student_stats
                  ?.latest_attention_to_detail_assessment_score ||
                +profileData?.student_stats
                  ?.latest_attention_to_detail_assessment_score === 0
                  ? 'Did not try'
                  : `${profileData?.student_stats?.latest_attention_to_detail_assessment_score}%`}
              </p>
              <p>
                <span>Total number of tries:</span>{' '}
                {!profileData?.student_stats
                  ?.number_of_attention_to_detail_assessment_tries ||
                +profileData?.student_stats
                  ?.number_of_attention_to_detail_assessment_tries === 0
                  ? 0
                  : profileData?.student_stats
                      ?.number_of_attention_to_detail_assessment_tries}
              </p>

              {profileData?.student_stats?.personality_assessment_results && (
                <>
                  <hr />
                  <Tooltip
                    title="Personality assessment is a beta feature. More in-depth details will follow soon."
                    arrow
                    placement="bottom"
                  >
                    <Chip
                      label="Beta"
                      color="secondary"
                      sx={{ float: 'right' }}
                    />
                  </Tooltip>
                </>
              )}
              {profileData?.student_stats?.personality_assessment_results?.map(
                (item: any) => {
                  return (
                    <p key={item?.domain}>
                      {/* word.charAt(0).toUpperCase() + word.slice(1) */}
                      <span>
                        {item?.title.charAt(0) +
                          item?.title.slice(1).toLowerCase()}
                        :
                      </span>{' '}
                      {item?.scoreText}
                    </p>
                  );
                },
              )}
              <p></p>
            </div>
          </div>

          <div className="student-language-container">
            <List
              className="student-language-list"
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
              }}
            >
              {getFormatLanguageList(
                profileData?.languages,
                profileData?.student_languages,
              )
                ?.sort((a, b) => compareLevels(a, b))
                .map((item) => getLanguageListItem(item))}
            </List>
          </div>

          <div className="student-skills-container">
            <p className="title">Skills</p>
            <div className="skill-interest-list">
              {uniqueSkills?.map((item) => (
                <SkillChip title={item as string} key={item as string} />
              ))}
            </div>
          </div>

          <div className="student-interests-container">
            <p className="title">{t('sign_up_student.interests')}</p>
            <div className="skill-interest-list">
              {profileData?.job_interests?.map((item) => (
                <SkillChip
                  title={item?.job_interest_name}
                  key={item?.job_interest_uid}
                />
              ))}
            </div>
          </div>

          {!!profileData?.personal_sentences?.length &&
            profileData?.personal_sentences[0].question !== null && (
              <div className="personal-sentences-container">
                <p className="title">
                  {t('settings_profile.student.personal_sentences')}
                </p>
                <div className="personal-sentences-wrapper">
                  {profileData?.personal_sentences.map((entry, index) => {
                    return (
                      <div key={index} className="personal-sentence-entry">
                        <span className="question">
                          {PSTranslator(t_ps, entry.question)}
                        </span>
                        <span className="response">
                          {entry.response === '' ? '-' : entry.response}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {!!jobUid && (
            <Button
              startIcon={existInTalentPool ? <RemoveIcon /> : <AddIcon />}
              className="button medium-text add-talent-pool-btn"
              onClick={handleToggleTalentPoolClick}
              data-testid={`${
                existInTalentPool ? 'remove-from' : 'add-to'
              }-talent-pool`}
            >
              {`${existInTalentPool ? 'Remove from' : 'Add to'} talent pool`}
            </Button>
          )}
        </Grid>
        {!jobUid && !isMobileView && !byCompany && (
          <Grid item md={1} className="profile-actions-container">
            <p className="profile-actions-title">{t('dashboard.actions')}</p>

            <div className="profile-actions-wrapper">
              <List className="profile-actions-list">
                <ListItem className="profile-actions-list-item">
                  <ButtonBase
                    className="action medium-text"
                    onClick={handleEditProfileClick}
                    data-testid="edit-profile"
                  >
                    <p className="profile-action">
                      <EditIcon />
                      <span className="profile-action-label">
                        {t('dashboard.edit_profile')}
                      </span>
                    </p>
                  </ButtonBase>
                </ListItem>
              </List>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MyProfile;
