import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { INVOICE_DETAILS } from './query-keys';
import { ItemsResponse } from './admin-invoices';

const invoiceDetails = async (invoice_uid: string) => {
  const { data } = await request<ServerResponse<InvoiceDetails>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/invoices/${invoice_uid}`,
    method: 'get',
  });

  return data.data;
};

export const useInvoiceDetails = (invoice_uid: string) => {
  return useQuery(
    [INVOICE_DETAILS, invoice_uid],
    () => invoiceDetails(invoice_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const changeInvoiceStatus = async (
  invoiceUid: string,
  body: InvoiceChangeStatus,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/invoices/${invoiceUid}`,
    method: 'put',
    data: body,
  });

  if (!data.success)
    throw new Error('invoice approve failed, code: ' + data.code);
};

interface InvoiceChangeStatus {
  invoice: { invoice_paid: boolean };
}

export function useChangeInvoiceStatus(invoiceUid: string) {
  return useMutation(
    (payload: InvoiceChangeStatus) => changeInvoiceStatus(invoiceUid, payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

export interface InvoiceDetails {
  invoice_uid: string;
  invoice_number: string;
  invoice_paid: boolean;
  invoice_paid_at: Date;
  invoice_sent: boolean;
  invoice_sent_at: Date;
  type: string;
  payment: {
    payment_uid: string;
    payment_status: string;
    amount: number;
    mollie_payment_id: string;
    payment_description: string;
  };
  invoice_items: ItemsResponse[];
  invoice_url: string;
}
