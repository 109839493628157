import React, { useState, useEffect } from 'react';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  ListItem,
  ListItemAvatar,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { formatDate } from '../../../utils/dates';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import ListItemText from '@mui/material/ListItemText';
import { ProfileLanguage, ProfileStudentLanguage } from '../../../data/profile';
import JobsStudent from './Jobs';
import ProposalsStudent from './Proposals';
import { useParams } from 'react-router-dom';
import { useStudentDetails } from '../../../data/admin-student-details';
import { useAdminUpdateUser, useAdminVerifyUser } from '../../../data/admin';
import MiniteSnackbar from '../../../components/snackbar';
import Assessments from './Assessments';
import SignupScore from './SignupScore';

interface LanguageItem {
  id: string;
  language: string;
  level: string;
}

const SkillChip = ({ title }: { title: string }) => {
  const chipStyle = {
    width: 'auto',
    height: 32,
    padding: 0,
    fontSize: '12px',
    backgroundColor: '#F2F9FC',
    color: '#393A42',
    fontFamily: 'Sofia W05 Medium, sans-serif',
  };

  return <Chip sx={chipStyle} label={title} key={title} />;
};

const getFormatLanguageList = (
  languages: ProfileLanguage[],
  studentLanguages: ProfileStudentLanguage[],
): LanguageItem[] => {
  return languages?.map((lang) => {
    return {
      id: lang?.language_uid,
      language: lang?.language_name,
      level: studentLanguages.find(
        (studentLang) => studentLang?.language_uid === lang?.language_uid,
      )?.level,
    };
  });
};
const ItemInfo = ({
  title,
  info,
  isBlue,
}: {
  title: string;
  info?: string;
  isBlue?: boolean;
}) => {
  return (
    <div className="overview-info">
      <div className="title">{title}</div>
      <div className={`info ${isBlue ? 'isBlue' : ''}`}>{info}</div>
    </div>
  );
};

const StudentLanguageLevel = {
  limited: 'Limited working proficiency',
  pro: 'Professional working proficiency',
  native: 'Native/bilingual proficiency',
};

const getLanguageListItem = (item: LanguageItem) => {
  return (
    <ListItem className="language-list-item" key={item?.id}>
      <ListItemAvatar className="language-item-avatar">
        <LanguageIcon />
      </ListItemAvatar>
      <div className="student-language-item">
        <span className="language-label">{item?.language}</span>
        <ListItemText
          className="language-secondary-label"
          secondary={StudentLanguageLevel[item?.level] || item?.level}
        />
      </div>
    </ListItem>
  );
};
const Overview = () => {
  const params = useParams<{ studentUid?: string }>();
  const { data: studentData = {}, refetch } = useStudentDetails(
    params.studentUid,
  );
  const { mutateAsync: updateUserByAdmin } = useAdminUpdateUser();
  const { mutateAsync: verifyUserByAdmin } = useAdminVerifyUser();
  const [
    isLoadingChangeVerificationStatus,
    setIsLoadingChangeVerificationStatus,
  ] = useState(false);
  const [isLoadingChangeStudentType, setIsLoadingChangeStudentType] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const {
    user,
    created_at,
    skills,
    job_interests,
    languages,
    student_languages,
    student_type,
    student_uid,
    top_talent,
    ambassador,
  } = studentData;

  const { t } = useTranslation();
  const [verifiedStatus, setVerifiedStatus] = useState(0);
  const [entryStatus, setEntryStatus] = useState('regular');
  const [isLoadingChangeAmbassador, setIsLoadingChangeAmbassador] =
    useState(false);

  useEffect(() => {
    if (user) {
      setVerifiedStatus(Number(user?.verified));
    }

    if (student_type) {
      setEntryStatus(student_type);
    }
  }, [studentData]);

  const changeVerificationStatus = async (e) => {
    const verified = Boolean(e.target.value);

    try {
      setIsLoadingChangeVerificationStatus(true);
      await verifyUserByAdmin({
        user_uid: user.user_uid,
        verified,
      });

      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoadingChangeVerificationStatus(false);
    }
  };

  const changeStudentType = async (e) => {
    const type = e.target.value;

    try {
      setIsLoadingChangeStudentType(true);
      await updateUserByAdmin({
        dto: {
          student: {
            student_type: type,
          },
        },
        userId: user.user_uid,
      });

      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoadingChangeStudentType(false);
    }
  };

  const handleViewProfile = () => {
    window.open(`${window.location.origin}/students/${student_uid}`);
  };

  const changeAmbassador = async (e) => {
    setIsLoadingChangeAmbassador(true);

    try {
      await updateUserByAdmin({
        dto: {
          student: {
            ambassador: !!Number(e.target.value),
          },
        },
        userId: user.user_uid,
      });

      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoadingChangeAmbassador(false);
    }
  };

  const changeTopTalent = async (e) => {
    setIsLoading(true);

    try {
      await updateUserByAdmin({
        dto: {
          student: {
            top_talent: !!Number(e.target.value),
          },
        },
        userId: user.user_uid,
      });

      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Grid
        container
        className="admin-student-info-container"
        columnGap={2}
        sx={{
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Grid item md={3}>
          <ItemInfo
            title="Register date"
            info={formatDate(created_at, 'DD/MM/YYYY')}
          />
          {/* <ItemInfo title="Rate" info={`€${hourly_rate}`} /> */}
          <FormControl
            className="type-select"
            variant="standard"
            sx={{ flex: '1 1 100%', width: '100%' }}
          >
            <div id="type-select-label">Verified status</div>
            {isLoadingChangeVerificationStatus ? (
              <Skeleton sx={{ height: 50, width: 225 }} animation="wave" />
            ) : (
              <Select
                value={verifiedStatus}
                onChange={changeVerificationStatus}
                labelId="type-select-label"
                id="type-select"
                label={'Verified status'}
              >
                <MenuItem value={1}>Verified</MenuItem>
                <MenuItem value={0}>Unverified</MenuItem>
              </Select>
            )}
          </FormControl>
          <FormControl
            className="type-select"
            variant="standard"
            sx={{ flex: '1 1 100%', width: '100%' }}
          >
            <div id="type-select-label">Ambassador?</div>
            {isLoadingChangeAmbassador ? (
              <Skeleton sx={{ height: 50, width: 225 }} animation="wave" />
            ) : (
              <Select
                value={Number(!!ambassador)}
                onChange={changeAmbassador}
                labelId="type-select-label"
                id="type-select"
                label={'Entry level status'}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
            )}
          </FormControl>
          <ItemInfo
            title="Deactivated On"
            info={formatDate(user?.deactivated_at, 'DD/MM/YYYY')}
          />
        </Grid>
        <Grid item md={3}>
          <ItemInfo
            title="Phone number"
            info={user?.phone || ''}
            isBlue={true}
          />
          <ItemInfo
            title="How did you find us?"
            info={`Via ${user?.how_did_you_find_minite || ''}`}
          />
          <FormControl
            className="type-select"
            variant="standard"
            sx={{ flex: '1 1 100%', width: '100%' }}
          >
            <div id="type-select-label">Entry level status</div>
            {isLoadingChangeStudentType ? (
              <Skeleton sx={{ height: 50, width: 225 }} animation="wave" />
            ) : (
              <Select
                value={entryStatus}
                onChange={changeStudentType}
                labelId="type-select-label"
                id="type-select"
                label={'Entry level status'}
              >
                <MenuItem value="entry-level">Entry-level</MenuItem>
                <MenuItem value="regular">Regular</MenuItem>
              </Select>
            )}
          </FormControl>
          <ItemInfo title="Deactivate Reason" info={user?.deactivate_reason} />
        </Grid>
        <Grid item md={3}>
          <ItemInfo title="E-mail" info={user?.email || ''} isBlue={true} />
          <ItemInfo title="Estimated monthly revenue" />
          <FormControl
            className="type-select"
            variant="standard"
            sx={{ flex: '1 1 100%', width: '100%' }}
          >
            <div id="type-select-label">Top Talent</div>
            {isLoading ? (
              <Skeleton sx={{ height: 50, width: 225 }} animation="wave" />
            ) : (
              <Select
                value={Number(!!top_talent)}
                onChange={changeTopTalent}
                labelId="top-talent-select-label"
                id="top-talent-select"
                label={'Top Talent'}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
            )}
          </FormControl>
          <ItemInfo
            title="Latest assessment score"
            info={
              studentData?.latest_assessment_score
                ? studentData?.latest_assessment_score + '%'
                : ''
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        className="admin-student-info-container"
        columnGap={2}
        sx={{
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Grid item md={12}>
          <Button
            className="button medium-text"
            onClick={handleViewProfile}
            data-testid="view-student-profile"
          >
            {t('proposal.view_profile')}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        className="admin-student-info-container"
        columnGap={2}
        sx={{
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Grid item md={3}>
          <div className="student-container">
            <p className="title">Skills</p>
            <div className="skill-interest-list">
              {skills?.map(({ skill_name, skill_uid }) => (
                <SkillChip title={skill_name} key={skill_uid} />
              ))}
            </div>
          </div>

          <div className="student-container">
            <p className="title">{t('sign_up_student.interests')}</p>
            <div className="skill-interest-list">
              {job_interests?.map((item) => (
                <SkillChip
                  title={item?.job_interest_name}
                  key={item?.job_interest_uid}
                />
              ))}
            </div>
          </div>
        </Grid>

        <Grid item md={3}>
          <div className="student-container">
            <p className="title">Languages</p>

            <List
              className="student-language-list"
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
              }}
            >
              {getFormatLanguageList(languages, student_languages)?.map(
                (item) => getLanguageListItem(item),
              )}
            </List>
          </div>
        </Grid>
      </Grid>
      {studentData?.score?.score && <SignupScore studentData={studentData} />}
      <section>
        <ProposalsStudent />
        <JobsStudent />
        <Assessments />
        <MiniteSnackbar
          open={!!errorMessage}
          title={'Error'}
          message={errorMessage}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => setErrorMessage(null)}
        />
      </section>
    </div>
  );
};

export default Overview;
