import { Button } from '@mui/material';
import GenericModal from '../../../components/generic-modal/GenericModal';
import React from 'react';
import { TimesheetStatus, TimesheetStatuses } from '../../../data/timesheets';
import TimesheetStatusChip from './TimesheetStatusChip';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  enabled: boolean;
  content: string;
  timesheetStatus: TimesheetStatus;
  onToggle: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const SubmitConfirmation = ({
  open,
  enabled,
  content,
  timesheetStatus,
  onToggle,
  onConfirm,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'timesheet' });
  return (
    <>
      {timesheetStatus == TimesheetStatuses.Draft ? (
        <Button
          disabled={!enabled}
          className="submit-month-button"
          variant="contained"
          onClick={onToggle}
          data-testid="submit-month"
        >
          {t('submit_month')}
        </Button>
      ) : (
        <TimesheetStatusChip timesheetStatus={timesheetStatus} />
      )}

      <GenericModal
        open={open}
        title={t('submit_your_monthly_timesheet')}
        mode="confirm"
        content={content}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={onToggle}
        handleConfirm={onConfirm}
        isLoading={isLoading}
        loadingButton
        confirmButtonTestId="submit-month-confirm"
      />
    </>
  );
};

export default SubmitConfirmation;
