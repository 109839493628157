import { request, ServerResponse } from '../core/request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { JOB_STUDENT_TIMESHEETS, JOB_TIMESHEETS } from './query-keys';
import { AxiosError } from 'axios';

// Types

export const TimesheetStatuses = {
  Draft: 'draft',
  Submitted: 'submitted',
  Approved: 'approved',
  Rejected: 'rejected',
  Closed: 'closed',
} as const;

export type TimesheetStatus =
  typeof TimesheetStatuses[keyof typeof TimesheetStatuses];

// Interfaces

export interface TimeEntryResponse {
  time_entry_uid: string;
  day: number;
  hours: number;
  description: string;
  created_at: string;
}

export interface StudentUser {
  user_uid: string;
  student_uid: string;
  first_name: string;
  last_name: string;
}

export interface TimesheetResponse {
  timesheet_uid: string;
  job_uid: string;
  student_uid: string;

  year: number;
  month: number;

  timesheet_status: TimesheetStatus;

  submitted_at?: string;
  approved_at?: string;
  rejected_at?: string;
  reject_reason?: string;

  created_at: string;
  updated_at: string;

  time_entries: Array<TimeEntryResponse>;
  student?: StudentUser;
  reviewed: boolean;
}

export interface JobTimesheetsResponse {
  timesheets: Array<TimesheetResponse>;
}

export interface TimeEntry {
  day: number;
  hours: number;
  description: string;
}

export interface UpdateTimeEntry {
  time_entry_uid: string;
  time_entry: TimeEntry;
}

export interface DeleteTimeEntry {
  time_entry_uid: string;
  job_uid: string;
}

export interface CreateTimeEntry {
  job_uid: string;
  year: number;
  month: number;
  time_entry: TimeEntry;
}

// API wrappers

// GET /api/v1/jobs/{job_uid}/timesheets
// Called by student user
const fetchJobTimesheets = async (jobId: string) => {
  const { data } = await request<ServerResponse<JobTimesheetsResponse>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/jobs/${jobId}/timesheets`,
    method: 'get',
  });

  return data.data.timesheets;
};

export const useFetchJobTimesheets = (jobUid: string, byStudent: boolean) =>
  useQuery([JOB_TIMESHEETS, jobUid], () => fetchJobTimesheets(jobUid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
    enabled: !!jobUid && byStudent,
  });

const fetchStudentTimesheets = async (jobId: string, studentUid: string) => {
  const { data } = await request<ServerResponse<JobTimesheetsResponse>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobId}/students/${studentUid}/timesheets`,
    method: 'get',
  });

  return data.data.timesheets;
};

export const useFetchStudentTimesheets = (jobUid: string, studentUid: string) =>
  useQuery(
    [JOB_STUDENT_TIMESHEETS, jobUid, studentUid],
    () => fetchStudentTimesheets(jobUid, studentUid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
      enabled: !!jobUid && !!studentUid,
    },
  );

// POST /api/v1/jobs/{job_uid}/time_entries/{year}/{month}/{day}
// Called by student user
const createJobTimeEntry = async (payload: CreateTimeEntry) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${payload.job_uid}/time_entries/${payload.year}/${payload.month}/${payload.time_entry.day}`,
    method: 'post',
    data: payload.time_entry,
  });

  if (!data.success)
    throw new Error('time entry submit failed, code: ' + data.code);
};

export function useCreateJobTimeEntry() {
  return useMutation(
    (payload: CreateTimeEntry) => createJobTimeEntry(payload),
    {
      onError: (e) => console.error(e),
    },
  );
}

// PUT /api/v1/jobs/{job_uid}/time_entries/{time_entry_uid}
// Called by student user
const updateJobTimeEntry = async (
  job_uid: string,
  time_entry_uid: string,
  payload: TimeEntry,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${job_uid}/time_entries/${time_entry_uid}`,
    method: 'put',
    data: payload,
  });

  if (!data.success)
    throw new Error('time entry update failed, code: ' + data.code);
};

export function useUpdateJobTimeEntry(job_uid: string) {
  return useMutation(
    (payload: UpdateTimeEntry) =>
      updateJobTimeEntry(job_uid, payload?.time_entry_uid, payload.time_entry),
    {
      onError: (e) => console.error(e),
    },
  );
}

// DELETE /api/v1/jobs/{job_uid}/time_entries/{time_entry_uid}
// Called by student user
const deleteJobTimeEntry = async (job_uid: string, time_entry_uid: string) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${job_uid}/time_entries/${time_entry_uid}`,
    method: 'delete',
  });

  if (!data.success)
    throw new Error('time entry delete failed, code: ' + data.code);
};

const setReviewJob = async (params: ReviewJob) => {
  const { timesheet_uid, rating, description, job_uid, student_uid } = params;

  const { data } = await request<
    ServerResponse<{ rating: number; description: string }>
  >({
    method: 'POST',
    url: process.env.REACT_APP_API_URL + `/api/v1/reviews`,
    data: { rating, description, timesheet_uid, job_uid, student_uid },
  });

  return data;
};

export function useSetReviewJob() {
  return useMutation((data: ReviewJob) => setReviewJob(data), {
    onError: (e: AxiosError) => e,
  });
}

export function useDeleteJobTimeEntry() {
  return useMutation(
    (payload: DeleteTimeEntry) =>
      deleteJobTimeEntry(payload?.job_uid, payload?.time_entry_uid),
    {
      onError: (e) => console.error(e),
    },
  );
}

// POST /api/v1/jobs/{job_uid}/timesheets/{timesheet_uid}/submit
// Called by student user
const submitJobTimesheetMonth = async (
  jobUid: string,
  timesheetUid: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/timesheets/${timesheetUid}/submit`,
    method: 'post',
  });

  if (!data.success)
    throw new Error('timesheet submit failed, code: ' + data.code);
};

export function useSubmitJobTimesheetMonth(
  jobUid: string,
  timesheetUid: string,
) {
  return useMutation(() => submitJobTimesheetMonth(jobUid, timesheetUid), {
    onError: (e) => console.error(e),
  });
}

// POST /api/v1/jobs/{job_uid}/timesheets/{timesheet_uid}/reason
// Called by student user
const lowHourReason = async (
  jobUid: string,
  timesheetUid: string,
  reason: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/timesheets/${timesheetUid}/reason`,
    method: 'post',
    data: { reason_for_low_hours: reason },
  });

  if (!data.success)
    throw new Error('timesheet submit failed, code: ' + data.code);
};

export function useLowHourReason(jobUid: string, timesheetUid: string) {
  return useMutation(
    (reason: string) => lowHourReason(jobUid, timesheetUid, reason),
    {
      onError: (e) => console.error(e),
    },
  );
}

// POST /api/v1/jobs/{job_uid}/timesheets/{timesheet_uid}/reject
// Called by company user, job owner
const rejectJobTimesheetMonth = async (
  jobUid: string,
  timesheetUid: string,
  rejectReason: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/timesheets/${timesheetUid}/reject`,
    method: 'post',
    data: { reject_reason: rejectReason },
  });

  if (!data.success)
    throw new Error('timesheet reject failed, code: ' + data.code);
};

export function useRejectJobTimesheetMonth(
  jobUid: string,
  timesheetUid: string,
) {
  return useMutation(
    (rejectReason: string) =>
      rejectJobTimesheetMonth(jobUid, timesheetUid, rejectReason),
    {
      onError: (e) => console.error(e),
    },
  );
}

// POST /api/v1/jobs/{job_uid}/timesheets/{timesheet_uid}/approve
// Called by company user, job owner
const approveJobTimesheetMonth = async (
  jobUid: string,
  timesheetUid: string,
) => {
  const { data } = await request<ServerResponse<unknown>>({
    url:
      process.env.REACT_APP_API_URL +
      `/api/v1/jobs/${jobUid}/timesheets/${timesheetUid}/approve`,
    method: 'post',
  });

  if (!data.success)
    throw new Error('timesheet approve failed, code: ' + data.code);
};

export function useApproveJobTimesheetMonth(
  jobUid: string,
  timesheetUid: string,
) {
  return useMutation(() => approveJobTimesheetMonth(jobUid, timesheetUid), {
    onError: (e) => console.error(e),
  });
}

interface ReviewJob {
  rating: number;
  description?: string;
  timesheet_uid?: string;
  job_uid?: string;
  student_uid?: string;
}
