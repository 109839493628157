import { checkOutlinedIcon } from '../../svg/checkOutlinedIcon';
import { closeIcon } from '../../svg/closeIcon';

const isPasswordMatchRule = (password, rule) => {
  return password.match(rule) ? checkOutlinedIcon : closeIcon;
};

export const passwordCheck = ({ password, confirm_password }, t) => {
  return `<span class="password-hint">
      <span class="text">
        ${t('sign_up.form_details.8_characters')}
        ${isPasswordMatchRule(password, '[a-zA-Z0-9!@#$%^&*]{8,}')}
      </span>
      <span class="text">
        ${t('sign_up.form_details.1_special_character')}
        ${isPasswordMatchRule(password, '(?=.*[!@#$%^&*])')}
      </span>
      <span class="text">
        ${t('sign_up.form_details.1_capital_letter')}
        ${isPasswordMatchRule(password, '(?=.*[A-Z]{1,})')}
      </span>
      <span class="text">
        1 number ${isPasswordMatchRule(password, '(?=.*?[0-9])')}
      </span>
      <span class="text">
        Passwords must match ${
          confirm_password !== password ? closeIcon : checkOutlinedIcon
        }
      </span>
    </span>
  `;
};
