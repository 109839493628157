import { request, ServerResponse } from '../core/request';
import { useMutation } from '@tanstack/react-query';

const addSkill = async (payload: IAddSkill[]) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/skills/create`,
    method: 'POST',
    data: payload,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useAddSkill = () => {
  return useMutation((payload: IAddSkill[]) => addSkill(payload), {
    onError: (e) => console.error(e),
  });
};

const deleteSkill = async (skills: string[]) => {
  const { data } = await request<ServerResponse<any>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/skills/delete`,
    method: 'POST',
    data: skills,
  });
  if (!data.success) throw new Error('update user profile, code: ' + data.code);
  return data;
};

export const useDeleteSkill = () => {
  return useMutation((skills: string[]) => deleteSkill(skills), {
    onError: (e) => console.error(e),
  });
};

export interface IAddSkill {
  skill_name: string;
}
