import {
  CompletionReasons,
  DegreeType,
  JobLocations,
  JobStatuses,
  JobTypes,
  StudentTypes,
  UserActivity,
} from '../../data/jobs';
import {
  HowFoundCompany,
  HowFoundStudent,
} from '../sign-up/interfaces/static-data';
import { TimesheetStatuses } from '../../data/timesheets';
import { formatDate } from '../../utils/dates';

export const filterFields = {
  'admin-students': [
    {
      label: 'Status & Labels',
      values: [
        { label: 'Entry-level', id: StudentTypes.EntryLevel },
        {
          label: 'Regular',
          id: StudentTypes.Regular,
        },
        'Verified',
        'Unverified',
      ],
      type: 'checkbox',
      key: 'verified',
    },

    {
      label: 'Activity',
      values: [UserActivity.Active, UserActivity.Deactivated],
      type: 'checkbox',
      key: 'user_activity',
    },

    {
      label: 'Degree',
      values: [
        { label: DegreeType.BA, id: DegreeType.BA },
        {
          label: DegreeType.Bachelor,
          id: DegreeType.Bachelor,
        },
        {
          label: DegreeType.BSc,
          id: DegreeType.BSc,
        },
        {
          label: DegreeType.LLB,
          id: DegreeType.LLB,
        },
        {
          label: DegreeType.PreMaster,
          id: DegreeType.PreMaster,
        },
        {
          label: DegreeType.MA,
          id: DegreeType.MA,
        },
        {
          label: DegreeType.MSc,
          id: DegreeType.MSc,
        },
        {
          label: DegreeType.LLM,
          id: DegreeType.LLM,
        },
        {
          label: DegreeType.MBA,
          id: DegreeType.MBA,
        },
        {
          label: DegreeType.PhD,
          id: DegreeType.PhD,
        },
      ],
      type: 'checkbox',
      key: 'degree_type',
    },
    {
      label: 'Referral',
      values: [{ label: 'Referral', id: 'referral' }],
      type: 'checkbox',
      key: 'referral',
    },
    {
      label: 'Top Talent',
      values: [{ label: 'Top Talent', id: 'top_talent' }],
      type: 'checkbox',
      key: 'top_talent',
    },
    {
      label: 'Ambassador',
      values: [{ label: 'Ambassador', id: 'ambassador' }],
      type: 'checkbox',
      key: 'ambassador',
    },
    {
      label: 'How did you find us?',
      values: HowFoundStudent,
      key: 'how_did_you_find_us',
    },
    {
      label: 'University',
      values: 'universities',
      key: 'university_uid',
    },
    {
      label: 'Languages',
      values: 'languages',
      key: 'language_uid',
    },
    {
      label: 'Skills',
      values: 'skills',
      key: 'skill_uid',
    },
    {
      label: 'Assessment score (%)',
      values: { assessment_score_min: 0, assessment_score_max: 8 },
      type: 'minMax',
      key: ['assessment_score_min', 'assessment_score_max'],
    },
    {
      label: 'Amount of Jobs',
      values: { jobs_min: 0, jobs_max: 100 },
      type: 'minMax',
      key: ['jobs_min', 'jobs_max'],
    },
    {
      label: 'Amount of Proposal',
      values: { proposals_min: 0, proposals_max: 200 },
      type: 'minMax',
      key: ['proposals_min', 'proposals_max'],
    },
    {
      label: 'Creation Date',
      values: { created_min_date: null, created_max_date: null },
      type: 'datepicker',
      key: ['created_min_date', 'created_max_date'],
      calendarSetup: {
        isMinYear: '2020-01-01',
        isMaxYear: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    },
    {
      label: 'Study start date',
      values: { start_min_date: null, start_max_date: null },
      type: 'datepicker',
      key: ['study_start_min_date', 'study_start_max_date'],
      calendarSetup: {
        isMaxYear: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    },
    {
      label: 'Study end date',
      values: { end_min_date: null, end_max_date: null },
      type: 'datepicker',
      key: ['study_end_min_date', 'study_end_max_date'],
    },
  ],
  'admin-jobs': [
    {
      label: 'Type of Job',
      values: [
        {
          label: 'Ongoing',
          id: JobTypes.ONGOING,
        },
        {
          label: 'One Time Standard',
          id: JobTypes.ONE_TIME,
        },
        {
          label: 'One Time Premium',
          id: JobTypes.PREMIUM,
        },
        {
          label: 'Direct',
          id: 'direct',
        },
      ],
      type: 'checkbox',
      key: 'job_type',
    },
    {
      label: 'Promo status',
      values: ['Boost your Job', 'No promo'],
      type: 'checkbox',
      key: 'job_boost_promo',
    },
    {
      label: 'Job Status',
      values: [
        { label: 'Draft', id: JobStatuses.DRAFT },
        { label: 'Open', id: JobStatuses.OPEN },
        { label: 'Interviewing', id: JobStatuses.INTERVIEWING },
        { label: 'Pending approval', id: JobStatuses.PENDING_APPROVAL },
        { label: 'Matched', id: JobStatuses.MATCHED },
        { label: 'In progress', id: JobStatuses.IN_PROGRESS },
        { label: 'Completed', id: JobStatuses.COMPLETED },
        { label: 'Cancelled', id: JobStatuses.CANCELLED },
        { label: 'Rejected', id: JobStatuses.REJECTED },
      ],
      type: 'checkbox',
      key: 'job_status',
    },
    {
      label: 'Job completion reason',
      values: Object.values(CompletionReasons).map((reason) => {
        return { label: reason, id: reason };
      }),
      type: 'checkbox',
      key: 'job_completed_reason',
    },
    {
      label: 'Location',
      values: [
        { label: 'Remote', id: JobLocations.REMOTE },
        { label: 'Hybrid', id: JobLocations.HYBRID },
        {
          label: 'On location',
          id: JobLocations.OFFICE,
        },
      ],
      type: 'checkbox',
      key: 'job_location',
    },
    {
      label: 'Select industry',
      values: 'industries',
      key: 'category_uid',
    },
    {
      label: 'Start date',
      values: { start_date: null, end_date: null },
      type: 'datepicker',
      key: ['start_date', 'end_date'],
    },
    {
      label: 'Estimated hours per week',
      values: { hours_min: 0, hours_max: 100 },
      type: 'minMax',
      key: ['hours_min', 'hours_max'],
    },
    {
      label: 'Languages',
      values: 'languages',
      key: 'language_uid',
    },
    {
      label: 'Skills',
      values: 'skills',
      key: 'skill_uid',
    },
    {
      label: 'Creation Date',
      values: { created_min_date: null, created_max_date: null },
      type: 'datepicker',
      key: ['created_min_date', 'created_max_date'],
      calendarSetup: {
        isMinYear: '2020-01-01',
        isMaxYear: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    },
  ],
  'admin-open-jobs': [
    {
      label: 'Type of Job',
      values: [
        {
          label: 'Ongoing',
          id: JobTypes.ONGOING,
        },
        {
          label: 'One Time Standard',
          id: JobTypes.ONE_TIME,
        },
        {
          label: 'One Time Premium',
          id: JobTypes.PREMIUM,
        },
        {
          label: 'Direct',
          id: 'direct',
        },
      ],
      type: 'checkbox',
      key: 'job_type',
    },
    {
      label: 'Promo status',
      values: ['Boost your Job', 'No promo'],
      type: 'checkbox',
      key: 'job_boost_promo',
    },
    {
      label: 'Location',
      values: [
        { label: 'Remote', id: JobLocations.REMOTE },
        { label: 'Hybrid', id: JobLocations.HYBRID },
        {
          label: 'On location',
          id: JobLocations.OFFICE,
        },
      ],
      type: 'checkbox',
      key: 'job_location',
    },
    {
      label: 'Select industry',
      values: 'industries',
      key: 'category_uid',
    },
    {
      label: 'Start date',
      values: { start_date: null, end_date: null },
      type: 'datepicker',
      key: ['start_date', 'end_date'],
    },
    {
      label: 'Estimated hours per week',
      values: { hours_min: 0, hours_max: 100 },
      type: 'minMax',
      key: ['hours_min', 'hours_max'],
    },
    {
      label: 'Languages',
      values: 'languages',
      key: 'language_uid',
    },
    {
      label: 'Skills',
      values: 'skills',
      key: 'skill_uid',
    },
    {
      label: 'Creation Date',
      values: { created_min_date: null, created_max_date: null },
      type: 'datepicker',
      key: ['created_min_date', 'created_max_date'],
      calendarSetup: {
        isMinYear: '2020-01-01',
        isMaxYear: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    },
  ],
  'admin-invoices': [
    {
      label: 'Invoice date',
      values: { min_month: null, max_month: null },
      type: 'datepicker',
      key: ['min_month', 'max_month'],
    },
    {
      label: 'Invoice type',
      values: ['One time job', 'Service fee and promo packs'],
      type: 'checkbox',
      key: 'invoice_type',
    },
    {
      label: 'Payment type',
      values: [
        { label: 'Payment', id: 'payment' },
        { label: 'Credit', id: 'credit' },
      ],
      type: 'checkbox',
      key: 'payment_type',
    },
  ],
  'admin-timesheets': [
    {
      label: 'Status',
      values: [
        {
          label: 'Draft',
          id: TimesheetStatuses.Draft,
        },
        {
          label: 'Approved',
          id: TimesheetStatuses.Approved,
        },
        {
          label: 'Rejected',
          id: TimesheetStatuses.Rejected,
        },
        {
          label: 'Submitted',
          id: TimesheetStatuses.Submitted,
        },
      ],
      type: 'checkbox',
      key: 'timesheet_status',
    },
    {
      label: 'Month',
      values: { min_month: null, max_month: null },
      type: 'datepicker',
      key: ['min_month', 'max_month'],
      calendarSetup: {
        isMinYear: '2023-01-01',
        isMaxYear: '2030-12-31',
      },
    },
    {
      label: 'Hours',
      values: { min: 0, max: 100 },
      type: 'minMax',
      key: ['min_hours', 'max_hours'],
    },
  ],
  'admin-companies': [
    {
      label: 'Activity',
      values: [
        {
          label: UserActivity.Active,
          id: UserActivity.Active,
        },
        {
          label: UserActivity.Deactivated,
          id: UserActivity.Deactivated,
        },
      ],
      type: 'checkbox',
      key: 'user_activity',
    },
    {
      label: 'Referral Code',
      values: [{ label: 'Referral Code', id: 'referral' }],
      type: 'checkbox',
      key: 'referral',
    },
    {
      label: 'How did you find us?',
      values: HowFoundCompany,
      key: 'how_did_you_find_us',
    },
    {
      label: 'Select industry',
      values: 'industries',
      key: 'interest_uid',
    },
    {
      label: 'Amount of jobs posted',
      values: { jobs_posted_min: 0, jobs_posted_max: 100 },
      type: 'minMax',
      key: ['jobs_posted_min', 'jobs_posted_max'],
    },
    {
      label: 'Amount of jobs in progress',
      values: { jobs_in_progress_min: 0, jobs_in_progress_max: 100 },
      type: 'minMax',
      key: ['jobs_in_progress_min', 'jobs_in_progress_max'],
    },
    {
      label: 'Amount of high flyers',
      values: { students_min: 0, students_max: 100 },
      type: 'minMax',
      key: ['students_min', 'students_max'],
    },
    {
      label: 'Creation Date',
      values: { created_min_date: null, created_max_date: null },
      type: 'datepicker',
      key: ['created_min_date', 'created_max_date'],
      calendarSetup: {
        isMinYear: '2020-01-01',
        isMaxYear: formatDate(new Date(), 'YYYY-MM-DD'),
      },
    },
  ],
  'admin-reviews': [
    {
      label: 'Review date',
      values: { start_date: 'current', end_date: null },
      type: 'datepicker',
      key: ['start_date', 'end_date'],
    },
    {
      label: 'Rating',
      values: { rating_min: 0, rating_max: 5 },
      type: 'minMax',
      key: ['rating_min', 'rating_max'],
    },
  ],
  'admin-revenue': [
    {
      label: 'Time period',
      values: { min_month: null, max_month: null },
      type: 'datepicker',
      key: ['min_month', 'max_month'],
    },
  ],
  'admin-event-logs': [
    {
      label: 'Creation Date',
      values: { created_min_date: null, created_max_date: null },
      type: 'datepicker',
      key: ['created_min_date', 'created_max_date'],
    },
  ],
};
