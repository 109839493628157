import { UserData } from '../../data/user';
import {
  VerificationRequirement,
  VerificationRequirements,
} from '../../routes';
import GenericModal from '../generic-modal/GenericModal';
import { UserType } from '../sidepanel/UserType';

const blockAccessUnverified = (
  requiresVerified: VerificationRequirement,
  userData: UserData,
): boolean => {
  if (!!requiresVerified && userData) {
    if (
      userData.type === UserType.Student &&
      (requiresVerified === VerificationRequirements.Both ||
        requiresVerified === VerificationRequirements.Student)
    ) {
      return true;
    }

    return (
      userData.type === UserType.Company &&
      (requiresVerified === VerificationRequirements.Both ||
        requiresVerified === VerificationRequirements.Company)
    );
  }

  return false;
};

const UnverifiedModal = ({ cancelAction, confirmAction }) => {
  return (
    <GenericModal
      open
      title="Oops, you’re not verified yet"
      content="In order to browse jobs, you first need to be verified by us. Please finalise your profile and schedule a 15 min screening call with us."
      cancelButtonText="Cancel"
      confirmButtonText="Complete Registration"
      handleCancel={cancelAction}
      handleConfirm={confirmAction}
    />
  );
};

export { blockAccessUnverified, UnverifiedModal };
