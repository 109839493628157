import React, { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import { SharedStepper } from '../../../shared/stepper/Stepper';
import {
  ButtonBase,
  SwipeableDrawer,
  useMediaQuery,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

interface ISteps {
  label: string;
  description: string;
}

interface IProps {
  steps: ISteps[];
  step: number;
  description: string;
  time: number;
  isCompleteOngoing: boolean;
  buttons: JSX.Element;
}
const SidePanel = ({
  steps,
  step,
  description,
  time,
  isCompleteOngoing,
  buttons,
}: IProps) => {
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const [isOpen, setIsOpen] = useState(false);

  function toggleDrawer() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      {!isMobileView && (
        <div className="sidepanel">
          <div className="sidepanel-top">
            <p className="sidepanel-title">Onboarding</p>
            <p className="sidepanel-description">{description}</p>

            <div className="sidepanel-icon">
              <AccessTimeIcon />
              <p>{time} minutes</p>
            </div>
            {isCompleteOngoing && (
              <div className="sidepanel-icon completed">
                <VerifiedIcon className="verified-user-icon" />
                <p>Completed</p>
              </div>
            )}
          </div>
          <SharedStepper steps={steps} activeStep={step} />
        </div>
      )}
      <SwipeableDrawer
        anchor={'left'}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className="onboarding-swipeable-drawer"
      >
        <div className="sidepanel">
          <div className="sidepanel-top">
            <Grid container justifyContent="space-between">
              <p className="sidepanel-title">Onboarding</p>
              <ButtonBase onClick={toggleDrawer} data-testid="close-sidepanel">
                <CloseIcon />
              </ButtonBase>
            </Grid>
            <p className="sidepanel-description">{description}</p>

            <div className="sidepanel-icon">
              <AccessTimeIcon />
              <p>{time} minutes</p>
            </div>

            {isCompleteOngoing && (
              <div className="sidepanel-icon completed">
                <VerifiedIcon className="verified-user-icon" />
                <p>Completed</p>
              </div>
            )}
          </div>
          <SharedStepper steps={steps} activeStep={step} isFullSize={true} />
        </div>
      </SwipeableDrawer>
      {!isOpen && isMobileView && (
        <div className="stepper-onbording-wrapper">
          <div className="menu-container" onClick={toggleDrawer}>
            <MenuIcon
              sx={{
                color: '#393A42',
                fontSize: 28,
              }}
            />
          </div>
          <SharedStepper steps={steps} activeStep={step} isShortSteps={true} />
          {buttons}
        </div>
      )}
    </>
  );
};

export default SidePanel;
