import create from 'zustand';
import {
  JobLocation,
  JobLocations,
  JobStatus,
  JobStatuses,
  JobType,
  JobTypes,
  StudentType,
  StudentTypes,
} from '../data/jobs';

export interface Option {
  title: string;
  id: string;
}

export interface Skill {
  skill_uid: string;
  skill_name: string;
}

export interface Language {
  language_uid: string;
  language_name: string;
}

export interface JobForm {
  parent_job_uid?: string | null;
  proposals_count?: number;
  job_uid: string;
  job_title: string;
  job_description: string;
  category_uid: string;
  sub_category_uid?: string;
  sub_category_name?: string;
  job_start_at: any;
  job_end_at?: any;
  hours_estimated: number | null;
  budget: number;
  payment_uid?: string;
  student_type: StudentType;
  students_needed: number;
  office_location: string;
  question_required: string;
  question_optional?: string;
  job_boost_pack: number;
  job_type: JobType;
  job_status: JobStatus;
  job_location: JobLocation;
  skills: Skill[];
  languages: Language[];
  created_at?: string;

  company_desc?: string;
  main_tasks?: string;
  desc_language?: string;

  direct_job?: boolean;
  direct_job_type?: string;
  direct_job_compensation?: string;
}

export interface JobFormState {
  jobFormState: JobForm;
  setJobFormState: (formState: JobForm) => void;
  resetJobFormState: () => void;
}

export const initialJobFormState = {
  job_uid: null,
  job_title: '',
  job_description: 'job_description',
  category_uid: '',
  sub_category_uid: undefined,
  sub_category_name: '',
  job_start_at: new Date(),
  job_end_at: undefined,
  payment_uid: null,
  hours_estimated: null,
  budget: null,
  office_location: '',
  students_needed: 1,
  question_required: '',
  question_optional: '',
  job_boost_pack: 0,
  student_type: StudentTypes.Regular,
  job_type: JobTypes.ONGOING,
  job_location: JobLocations.REMOTE,
  job_status: JobStatuses.DRAFT,
  skills: [],
  languages: [],
  company_desc: 'company_desc',
  main_tasks: 'main_tasks',
  desc_language: 'desc_language',
  direct_job: false,
  direct_job_type: '',
  direct_job_compensation: '',
};

export const useManageJobForm = create<JobFormState>((set) => ({
  jobFormState: initialJobFormState,
  setJobFormState: (formState) => set({ jobFormState: formState }),
  resetJobFormState: () =>
    set({
      jobFormState: initialJobFormState,
    }),
}));
