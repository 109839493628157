import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { STUDENT_DETAIL, STUDENT_JOBS, STUDENT_PROPOSALS } from './query-keys';
import { AxiosError } from 'axios';

const studentDetails = async (student_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/students/${student_uid}`,
    method: 'get',
  });

  return data.data;
};

export const useStudentDetails = (student_uid: string) => {
  return useQuery(
    [STUDENT_DETAIL, student_uid],
    () => studentDetails(student_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const studentProposals = async (student_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/students/${student_uid}/proposals`,
    method: 'get',
  });

  return data.data.proposals;
};

export const useStudentProposals = (student_uid: string) => {
  return useQuery(
    [STUDENT_PROPOSALS, student_uid],
    () => studentProposals(student_uid),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const studentJobs = async (student_uid: string) => {
  const { data } = await request<ServerResponse<any>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/students/${student_uid}/jobs`,
    method: 'get',
  });

  return data.data.jobs;
};

export const useStudentJobs = (student_uid: string) => {
  return useQuery([STUDENT_JOBS, student_uid], () => studentJobs(student_uid), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};
