import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Degrees, LangLevels } from '../../interfaces/static-data';
import './HighFlyer.scss';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EducationFormData,
  initialLang,
  initialUniversity,
  languagesSchema,
  MIN_EDUCATION_EXPERIENCE_DATE,
  universitiesSchema,
  useCreateEducationForm,
} from '../../../../stores/student-signup-form';
import {
  getOptionLabel,
  isOptionEqualToValue,
} from '../../../../utils/ioption';
import {
  Profile,
  ProfileData,
  useFetchStudentById,
} from '../../../../data/profile';
import StepInfo from './StepInfo';
import { LoadingButton } from '../../../LoadingButton';

interface Props {
  handleFormSubmit: (arg: any) => Promise<void>;
  student_uid: string;
}

export const EducationForm = ({ handleFormSubmit, student_uid }: Props) => {
  const [universities, setUniversities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { t } = useTranslation('translation', { keyPrefix: 'sign_up_student' });
  const { educationFormState, setEducationFormState } =
    useCreateEducationForm();
  const { data: student } = useFetchStudentById(student_uid);
  const studentInfo = student as unknown as ProfileData;
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup
    .object()
    .shape({
      universities: universitiesSchema,
      profile: yup.mixed<Profile>().required(),
      languages: languagesSchema,
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control,
  } = useForm<EducationFormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: educationFormState,
  });

  const {
    fields: universitiesFields,
    append: appendUniversity,
    remove: removeUniversity,
  } = useFieldArray({
    control,
    name: 'universities',
  });

  const {
    fields: languagesFields,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: 'languages',
  });

  const universitiesWatch = useWatch({ control, name: 'universities' });
  const languagesWatch = useWatch({ control, name: 'languages' });
  const getUniversitiesAndLanguages = () => {
    Promise.all([
      fetch(process.env.REACT_APP_API_URL + '/api/v1/dictionary/universities'),
      fetch(process.env.REACT_APP_API_URL + '/api/v1/dictionary/languages'),
    ]).then(
      ([universities, languages]) => {
        universities.json().then((res) => {
          const data = res.data.map((item: any) => {
            return {
              label: item.university_name,
              id: item.university_uid,
            };
          });
          setUniversities(data);
        });

        languages.json().then((res) => {
          const data = res.data.map((item: any) => {
            return {
              label: item.language_name,
              language_uid: item.language_uid,
            };
          });
          setLanguages(data);
        });
      },
      (err) => {
        console.log(err);
      },
    );
  };

  useEffect(() => {
    getUniversitiesAndLanguages();
    setIsLoading(false);
  }, []);

  const changeUniversity = (studentInfo) => {
    return studentInfo.education.map(
      ({ degree, ended_at, started_at, study, university_uid }) => {
        const education = {
          degree,
          endDate: ended_at,
          startDate: started_at,
          study,
        };

        const { university_uid: id, university_name } =
          studentInfo?.universities.find(
            (item) => university_uid === item.university_uid,
          );

        return {
          ...education,
          university: { label: university_name, id },
        };
      },
    );
  };

  const changeLang = ({ student_languages, languages }) => {
    return student_languages.map(({ language_uid, level }) => {
      const { language_uid: id, language_name } = languages.find(
        (item) => language_uid === item.language_uid,
      );

      return {
        level,
        language: {
          language_uid: id,
          label: language_name,
        },
      };
    });
  };

  useEffect(() => {
    const data: {
      universities: Array<any>;
      profile: Profile;
      languages: Array<any>;
    } = {
      universities: [{ ...initialUniversity }],
      profile: studentInfo.profile,
      languages: [{ ...initialLang }],
    };

    if (studentInfo?.universities[0]?.university_uid) {
      data.universities = changeUniversity(studentInfo);
    }

    if (studentInfo?.languages[0]?.language_uid) {
      data.languages = changeLang(studentInfo);
    }

    setEducationFormState({
      ...data,
    });
  }, [studentInfo]);

  useEffect(() => {
    setValue('universities', educationFormState.universities);
    setValue('languages', educationFormState.languages);
  }, [educationFormState]);

  const onSubmit = (data) => {
    setIsLoading(true);

    setEducationFormState({
      ...educationFormState,
      ...data,
    });

    handleFormSubmit({
      ...educationFormState,
      ...data,
    }).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <section className="sign-up-section">
      <p className="step-title">{t('your_studies')}</p>
      <StepInfo
        highlightText="Welcome High-Flyer!"
        text="I'm Tofu, Minite's Chief Happiness Officer, here to help you create a kickass profile to showcase to potentiel clients! Let's start with the basics."
      />

      <form className="form">
        <div className="form-inputs">
          {universitiesFields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      options={universities}
                      value={value}
                      isOptionEqualToValue={isOptionEqualToValue}
                      getOptionLabel={getOptionLabel}
                      data-testid="university"
                      onChange={(e, data: { id: string } | null) => {
                        if (data) {
                          onChange(data);
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.universities?.[index]?.university}
                          helperText={
                            errors?.universities?.[index]?.university?.message
                          }
                          variant="standard"
                          label={t('university')}
                        />
                      )}
                    />
                  )}
                  control={control}
                  name={`universities.${index}.university`}
                />

                <FormGroup className="form-group">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FormControl variant="standard">
                        <InputLabel id="degree-select-label">
                          {t('degree')}
                        </InputLabel>
                        <Select
                          labelId="degree-select-label"
                          id="degree-select"
                          label={t('degree')}
                          value={value}
                          onChange={onChange}
                          data-testid="degree"
                        >
                          {Degrees.map((item) => {
                            return (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    control={control}
                    name={`universities.${index}.degree`}
                  />

                  <TextField
                    type="text"
                    id="study"
                    sx={{ flex: '1 1 100%' }}
                    variant="standard"
                    label={t('your_study')}
                    data-testid="study"
                    name="study"
                    error={!!errors.universities?.[index]?.study}
                    helperText={errors?.universities?.[index]?.study?.message}
                    {...register(`universities.${index}.study`)}
                  />
                </FormGroup>

                <FormGroup className="form-group">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <DesktopDatePicker
                          value={value}
                          views={['year', 'month']}
                          onChange={(value) => onChange(value)}
                          disableMaskedInput={true}
                          label={t('start_date')}
                          inputFormat="MMMM YYYY"
                          minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              data-testid="start-university"
                              error={!!errors.universities?.[index]?.startDate}
                              helperText={
                                !!errors.universities?.[index]?.startDate
                                  ? t('datepicker_error')
                                  : ''
                              }
                            />
                          )}
                        />
                      )}
                      control={control}
                      name={`universities.${index}.startDate`}
                    />

                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <DesktopDatePicker
                          value={value}
                          views={['year', 'month']}
                          onChange={(value) => onChange(value)}
                          disableMaskedInput={true}
                          label={t('end_date')}
                          inputFormat="MMMM YYYY"
                          data-testid="end-university"
                          minDate={MIN_EDUCATION_EXPERIENCE_DATE}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              error={!!errors.universities?.[index]?.endDate}
                              helperText={
                                !!errors.universities?.[index]?.endDate
                                  ? t('datepicker_error')
                                  : 'Must be a date after start date'
                              }
                            />
                          )}
                        />
                      )}
                      control={control}
                      name={`universities.${index}.endDate`}
                    />
                  </LocalizationProvider>
                </FormGroup>
                <div className="array-buttons-container">
                  {universitiesWatch.length > 1 && (
                    <Button
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      variant="outlined"
                      className="array-buttons remove-field-button"
                      type="button"
                      data-testid="remove-university"
                      onClick={() => removeUniversity(index)}
                    >
                      {t('remove')}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}

          <div className="array-buttons-container">
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className="array-buttons add-field-button"
              type="button"
              data-testid="add-university"
              onClick={() => appendUniversity(initialUniversity)}
            >
              {t('add_study')}
            </Button>
          </div>
          <div>
            <p className="step-subtitle">{t('profile')}</p>
            <FormControl>
              <Controller
                name="profile"
                control={control}
                render={({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      sx={{ marginTop: '10px' }}
                      data-testid="deactivation-reasons"
                    >
                      {Object.values(Profile).map((value) => (
                        <FormControlLabel
                          value={value}
                          control={
                            <Radio
                              sx={{
                                '&, &.Mui-checked': {
                                  color: '#deb900',
                                },
                              }}
                            />
                          }
                          label={t(value)}
                          key={value}
                        />
                      ))}
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className="form-inputs">
          <p className="step-subtitle">{t('lang')}</p>
          {languagesFields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      options={languages}
                      value={value}
                      data-testid="student-language"
                      isOptionEqualToValue={(option: { id: string }, value) => {
                        return option.id === value.id;
                      }}
                      onChange={(e, data: { id: string } | null) => {
                        if (data) {
                          onChange(data);
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t('lang')}
                          error={!!errors.languages?.[index]?.language}
                          helperText={
                            errors?.languages?.[index]?.language?.message
                          }
                        />
                      )}
                    />
                  )}
                  name={`languages.${index}.language`}
                  control={control}
                />

                <Controller
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="standard">
                      <InputLabel id="level-select-label">
                        {t('level')}
                      </InputLabel>
                      <Select
                        labelId="level-select-label"
                        id="level-select"
                        label={t('level')}
                        name="level"
                        data-testid="laguage-level"
                        error={!!errors.languages?.[index]?.level}
                        value={value}
                        onChange={onChange}
                      >
                        {LangLevels.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  control={control}
                  name={`languages.${index}.level`}
                />

                <div className="array-buttons-container">
                  {languagesWatch.length > 1 && (
                    <Button
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      variant="outlined"
                      className="array-buttons remove-field-button"
                      type="button"
                      data-testid="remove-language"
                      onClick={() => removeLanguage(index)}
                    >
                      {t('remove')}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}

          <div className="array-buttons-container">
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className="array-buttons add-field-button"
              type="button"
              data-testid="add-language"
              onClick={() => appendLanguage({ ...initialLang })}
            >
              {t('add_lang')}
            </Button>
          </div>
        </div>
      </form>

      <div className="form-buttons">
        <LoadingButton
          className="confirm-button"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          buttonText={t('continue')}
          isLoading={isLoading}
          confirmButtonTestId="save-education"
        />
      </div>
    </section>
  );
};
