import React from 'react';
import '../Dashboard.scss';

import NoData from './NoData';
import FavouriteTalents from '../../../components/favourite-talents/FavouriteTalents';
import { useFetchTalentPool } from '../../../data/talent-pool';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const MyTalents = () => {
  const { data: talents = [], isLoading } = useFetchTalentPool(true);
  const { t } = useTranslation();
  return (
    <div className="dashboard-jobs fav-container-small">
      <h3 className="heading-text">{t('dashboard.my_talent_pool')}</h3>
      {isLoading ? (
        <Skeleton sx={{ height: 250 }} />
      ) : talents?.length === 0 ? (
        <NoData message={t('dashboard.no_talent_pool')} />
      ) : (
        <FavouriteTalents dashboard pool={talents} />
      )}
    </div>
  );
};

export default MyTalents;
