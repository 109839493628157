import React from 'react';
import { checkboxClasses, FormControlLabel, Checkbox } from '@mui/material';

interface IProps {
  checkboxName: string;
  id: string;
  label: string;
  isChecked: boolean;
  onChange: (arg1: any, arg2: string) => void;
}

const CheckboxFilter = ({
  checkboxName,
  label,
  isChecked,
  onChange,
  id,
}: IProps) => {
  return (
    <div>
      <FormControlLabel
        label={checkboxName}
        control={
          <Checkbox
            id={`${id}-checkbox`}
            name={id}
            onChange={(e) => onChange(e, label)}
            checked={isChecked}
            sx={{
              [`&.${checkboxClasses.checked}`]: {
                color: '#DEB900',
              },
            }}
          />
        }
      />
    </div>
  );
};

export default CheckboxFilter;
