import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number },
) => {
  // If value < 33%, set to red, between 33 and 66, set to yellow, else set to green
  const color =
    props.value < 33 ? 'red' : props.value < 66 ? 'orange' : 'green';
  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex', marginRight: '5px' }}
    >
      <CircularProgress
        variant="determinate"
        style={{ color: color }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
