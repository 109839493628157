import React, { useState } from 'react';
import { Grid } from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import { useDebounce } from '../../../utils/useDebounce';
import Ongoing from './Ongoing';
import OneTime from './OneTime';
import './Revenue.scss';
import { numberWithDec } from '../../../utils/numberWithDec';
import { useRevenueStatistic } from '../../../data/admin-revenue';

const getStatisticCards = (statisticsInfo) => {
  const {
    total_hours_logged_this_month,
    total_hours_logged_last_month,
    total_revenue_this_month_to_date,
    approved_timesheets_this_month,
  } = statisticsInfo;
  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'logged_hours_this_month'}
        text={'Total hours logged this month'}
        counter={total_hours_logged_this_month || 0}
      />
      <AdminStatisticCard
        key={'logged_hours_last_month'}
        text={'Total hours logged last month'}
        counter={total_hours_logged_last_month || 0}
      />
      <AdminStatisticCard
        key={'revenue'}
        text={'Total revenue this month to date'}
        counter={`€${numberWithDec(total_revenue_this_month_to_date, 2) || 0}`}
        isColumn
      />
      <AdminStatisticCard
        key={'timesheets'}
        isColumn
        text={'Approved timesheets this month'}
        counter={`${approved_timesheets_this_month || 0}`}
      />
    </div>
  );
};
const AdminRevenue = () => {
  const { data: statistics = {} } = useRevenueStatistic();
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-revenue-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Hours & revenue</p>

      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        {getStatisticCards(statistics)}
      </Grid>
      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <Ongoing
          search={search}
          setSearch={setSearch}
          debouncedSearch={debouncedSearch}
        />
        <OneTime debouncedSearch={debouncedSearch} />
      </Grid>
    </Grid>
  );
};

export default AdminRevenue;
