import { Routes } from './utils/consts';

import Signup from './pages/signup';
import Reset from './pages/reset';
import Dashboard from './pages/dashboard/index';
import JobMatches from './pages/job-matches/index';
import Postajob from './pages/postajob';
import PostDirectJob from './pages/post-direct-job/index';

import Profile from './pages/settings/profile';
import Financial from './pages/settings/financial';
import Notifications from './pages/settings/notifications';
import Onboarding from './pages/support/onboarding';
import KnowledgeBase from './pages/support/knowledgebase';
import LogCase from './pages/support/logcase';
import MyProfile from './pages/myprofile';
import ChangePassword from './pages/change-password';
import BrowseJobs from './pages/browsejobs';
import JobDetails from './pages/job-details';
import Chat from './pages/chat';
import Agreement from './pages/agreement';
import Timesheet from './pages/timesheet';
import WelcomeAboard from './components/sign-up/components/high-flyer/welcome-aboard/WelcomeAboard';
import Assessment from './components/assessments/Assessment';
import CompanyAsStudentJobDetails from './pages/job-details/CompanyAsStudentJobDetails';
import AdminJobs from './pages/admin-panel/admin-jobs';
import AdminStatistics from './pages/admin-panel/admin-statistics';
import AdminInvoices from './pages/admin-panel/admin-invoices';
import AdminStudents from './pages/admin-panel/admin-students';
import AdminCompanies from './pages/admin-panel/admin-companies';
import AdminReviews from './pages/admin-panel/admin-reviews';
import AdminTimesheets from './pages/admin-panel/admin-timesheets';
import AdminTasks from './pages/admin-panel/admin-tasks';
import AdminDashboard from './pages/admin-panel/admin-dashboard';
import AdminStudentDetails from './pages/admin-panel/admin-students-details';
import AdminCompanyOverview from './pages/admin-panel/admin-companies/admin-company-overview';
import AdminTimesheetDetails from './pages/admin-panel/admin-timesheet-details';
import AdminJobOverview from './pages/admin-panel/admin-jobs/admin-job-overview';
import AdminInvoiceOverview from './pages/admin-panel/admin-invoice-details';
import NotFound from './pages/not-found';
import Student from './pages/signup/student';
import Company from './pages/signup/company';
import AdminChat from './pages/admin-panel/admin-chat';
import Forbidden from './pages/forbidden';
import JobDetailsAdmin from './pages/admin-panel/admin-jobs/job-details/JobDetailsAdmin';
import AdminRevenue from './pages/admin-panel/admin-revenue';
import ReferFriend from './pages/refer-friend';
import EditsAdmin from './pages/admin-panel/admin-edits/EditsAdmin';
import AdminEventLogs from './pages/admin-panel/admin-event-logs';
import { SelectRole } from './components/sign-up/components/SelectRole';
import PayInvoice from './pages/pay-invoice';
import { StudentAssessments } from './pages/assessments';
import { AssessmentView } from './pages/assessments/AssessmentView';
import NewChat from './pages/chat-v2';
import AdminChatNew from './pages/admin-panel/admin-chat-v2';
import Invoices from './pages/invoices';
import AdminOpenJobs from './pages/admin-panel/admin-open-jobs';
import Reviews from './pages/reviews';

export const VerificationRequirements = {
  Student: 'student',
  Company: 'company',
  Both: 'both',
};

export type VerificationRequirement =
  typeof VerificationRequirements[keyof typeof VerificationRequirements];

type RouteType = {
  path: string;
  Component: any;
  title: string;
  requiresVerified?: VerificationRequirement;
};

export const authRoutes: RouteType[] = [
  { path: Routes.SignUp, Component: Signup, title: 'Sign Up' },
  { path: Routes.SignUpUserType, Component: Signup, title: 'Sign Up' },
  { path: Routes.ResetPassword, Component: Reset, title: 'Reset Password' },
  {
    path: Routes.ChangePassword,
    Component: ChangePassword,
    title: 'Change Password',
  },
];

export const protectedRoutes: RouteType[] = [
  {
    path: Routes.DashboardRoute,
    Component: Dashboard,
    title: 'Dashboard',
  },
  { path: Routes.SelectRole, Component: SelectRole, title: 'Select Role' },
  {
    path: Routes.SignUpStudentInfo,
    Component: Student,
    title: 'Sign Up',
  },
  { path: Routes.SignUpCompanyInfo, Component: Company, title: 'Sign Up' },
  {
    path: Routes.BrowseJobsRoute,
    Component: BrowseJobs,
    title: 'Browse Jobs',
  },
  {
    path: Routes.JobPostRoute,
    Component: Postajob,
    title: 'Post a job',
  },
  {
    path: Routes.JobEditRoute,
    Component: Postajob,
    title: 'Finish job posting',
  },
  {
    path: Routes.JobPaidRoute,
    Component: Postajob,
    title: 'Post a job',
  },
  {
    path: Routes.JobDirectPostRoute,
    Component: PostDirectJob,
    title: 'Post a job',
  },
  {
    path: Routes.JobDirectEditRoute,
    Component: PostDirectJob,
    title: 'Post a job',
  },
  {
    path: Routes.JobDirectPaidRoute,
    Component: PostDirectJob,
    title: 'Post a job',
  },
  {
    path: Routes.JobMatchesRoute,
    Component: JobMatches,
    title: 'Invite Talent',
  },
  {
    path: Routes.MyProfileRoute,
    Component: MyProfile,
    title: 'My Profile',
  },
  {
    path: Routes.StudentProfileRoute,
    Component: MyProfile,
    title: 'Student Profile',
  },
  {
    path: Routes.WelcomeAboard,
    Component: WelcomeAboard,
    title: 'Schedule a call',
  },
  {
    path: Routes.Assessment,
    Component: Assessment,
    title: 'Finish your assessment',
  },
  {
    path: Routes.StudentJobProfileRoute,
    Component: MyProfile,
    title: 'Student Profile',
  },
  {
    path: Routes.ProfileSettingsRoute,
    Component: Profile,
    title: 'Profile',
  },
  {
    path: Routes.ProfileSettingsDeleteRoute,
    Component: Profile,
    title: 'Profile',
  },
  {
    path: Routes.FinancialSettingsRoute,
    Component: Financial,
    title: 'Account & Financial',
  },
  {
    path: Routes.NotificationSettingsRoute,
    Component: Notifications,
    title: 'Notifications',
  },
  {
    path: Routes.OnboardingSupportRoute,
    Component: Onboarding,
    title: 'Onboarding',
  },
  {
    path: Routes.KnowledgeBaseSupportRoute,
    Component: KnowledgeBase,
    title: 'Knowledge Base',
  },
  {
    path: Routes.LogCaseSupportRoute,
    Component: LogCase,
    title: 'Log a Support Case',
  },
  {
    path: Routes.ForCompanies,
    Component: '',
    title: 'For Companies',
  },
  {
    path: Routes.ReferFriend,
    Component: ReferFriend,
    title: 'Refer a Friend',
  },
  {
    path: Routes.AboutMinite,
    Component: '',
    title: 'About Minite',
  },
  {
    path: Routes.FAQ,
    Component: '',
    title: 'FAQ',
  },
  {
    path: Routes.Terms,
    Component: '',
    title: 'Terms of Use',
  },
  {
    path: Routes.JobDetails,
    Component: JobDetails,
    title: 'Job Details',
  },
  {
    path: Routes.CompanyStudentJobDetails,
    Component: CompanyAsStudentJobDetails,
    title: 'Job Details',
    requiresVerified: VerificationRequirements.Student,
  },
  {
    path: Routes.ChatRoute,
    Component: Chat,
    title: 'Minite Chat',
  },
  {
    path: Routes.AgreementSignRoute,
    Component: Agreement,
    title: 'Agreement',
  },
  {
    path: Routes.TimesheetRoute,
    Component: Timesheet,
    title: 'Timesheet',
  },
  {
    path: Routes.TimesheetStudentRoute,
    Component: Timesheet,
    title: 'Timesheet',
  },
  {
    path: Routes.JobTimesheetRoute,
    Component: Timesheet,
    title: 'Timesheet',
  },
  {
    path: Routes.AdminJobsRoute,
    Component: AdminJobs,
    title: 'Admin Jobs',
  },
  {
    path: Routes.AdminJobsOverviewRoute,
    Component: AdminJobOverview,
    title: 'Admin Job Overview',
  },
  {
    path: Routes.AdminOpenJobsRoute,
    Component: AdminOpenJobs,
    title: 'Admin Open Jobs',
  },
  {
    path: Routes.AdminInvoiceOverviewRoute,
    Component: AdminInvoiceOverview,
    title: 'Admin Invoice Overview',
  },
  {
    path: Routes.AdminStudentsRoute,
    Component: AdminStudents,
    title: 'Admin Students',
  },
  {
    path: Routes.AdminCompaniesRoute,
    Component: AdminCompanies,
    title: 'Admin Companies',
  },
  {
    path: Routes.AdminCompanyOverviewRoute,
    Component: AdminCompanyOverview,
    title: 'Admin Company Overview',
  },
  {
    path: Routes.AdminReviewsRoute,
    Component: AdminReviews,
    title: 'Admin Reviews',
  },
  {
    path: Routes.HoursRoute,
    Component: AdminTimesheets,
    title: 'Admin Timesheet',
  },
  {
    path: Routes.AdminChatRoute,
    Component: AdminChat,
    title: 'Admin Chats',
  },
  {
    path: Routes.StatisticsRoute,
    Component: AdminStatistics,
    title: 'Statistics',
  },
  {
    path: Routes.AdminInvoicesRoute,
    Component: AdminInvoices,
    title: 'Invoices',
  },
  {
    path: Routes.AdminNewChatRoute,
    Component: AdminChatNew,
    title: 'Admin Chats',
  },
  {
    path: Routes.TasksRoute,
    Component: AdminTasks,
    title: 'Admin Tasks',
  },
  {
    path: Routes.AdminDashboardRoute,
    Component: AdminDashboard,
    title: 'Admin Dashboard',
  },
  {
    path: Routes.AdminStudentDetailsRoute,
    Component: AdminStudentDetails,
    title: 'Admin Student Details',
  },
  {
    path: Routes.AdminTimesheetDetailsRoute,
    Component: AdminTimesheetDetails,
    title: 'Admin Timesheet Details',
  },
  {
    path: Routes.AdminRevenueRoute,
    Component: AdminRevenue,
    title: 'Hours & revenue',
  },
  {
    path: Routes.AdminEventLogs,
    Component: AdminEventLogs,
    title: 'Event logs',
  },
  {
    path: Routes.AdminJobsDetailsAsStudentRoute,
    Component: JobDetailsAdmin,
    title: 'Job Details',
  },
  {
    path: Routes.AdminEditsRoute,
    Component: EditsAdmin,
    title: 'Edits',
  },
  {
    path: Routes.StudentAssessments,
    Component: StudentAssessments,
    title: 'Assessments',
  },
  {
    path: Routes.StudentAssessmentView,
    Component: AssessmentView,
    title: 'Assessment',
  },
  {
    path: Routes.NewChatRoute,
    Component: NewChat,
    title: 'Chat',
  },
  {
    path: Routes.Invoices,
    Component: Invoices,
    title: 'Invoices',
  },
  {
    path: Routes.ReviewsRoute,
    Component: Reviews,
    title: 'Reviews',
  },
  {
    path: Routes.Forbidden,
    Component: Forbidden,
    title: 'Forbidden',
  },
  {
    path: Routes.SignIn,
    Component: Dashboard,
    title: 'Dashboard',
  },
  {
    path: Routes.SignUp,
    Component: Dashboard,
    title: 'Dashboard',
  },
  {
    path: '*',
    Component: NotFound,
    title: '404 Not Found',
  },
];

export const unprotectedRoutes: RouteType[] = [
  {
    path: Routes.PayInvoiceRoute,
    Component: PayInvoice,
    title: 'Pay Invoice',
  },
];
