import create from 'zustand';
import { CreateJob, fetchJobForEditRequest } from '../../data/jobs';
interface PostJobWizardLoadingState {
  loading: boolean;
  setLoading?: (loading: boolean) => void;
  initializing: boolean;
  setInitializing?: (initializing: boolean) => void;
  setJob?: (job: CreateJob | null) => void;
  job: CreateJob | null;
  refetchJob?: (job_uid: string) => Promise<void>;
}
export const usePostJobWizardLoading = create<PostJobWizardLoadingState>(
  (set) => ({
    loading: false,
    setLoading: (loading: boolean) => set({ loading }),
    initializing: false,
    setInitializing: (initializing: boolean) => set({ initializing }),
    setJob: (job: CreateJob | null) => set({ job }),
    job: null,
    refetchJob: async (job_uid: string) => {
      const response = await fetchJobForEditRequest(job_uid);
      set({ job: response.job });
    },
  }),
);
