import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColDef,
  GridEditRowsModel,
  GridSortModel,
} from '@mui/x-data-grid';
import './Grid.scss';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';

interface GridProps {
  columns: (GridColDef | GridActionsColDef)[];
  rows: unknown[];
  pagination?: boolean;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  height?: number | string;
  width?: string;
  rowId?: any;
  disableColumnFilter?: boolean;
  loading?: boolean;
  editRowCallback?: (data: GridEditRowsModel) => void;
  editRowStartCallback?: (data: GridEditRowsModel) => void;
  editRowEndCallback?: (data: GridEditRowsModel) => void;
  onSortModelChange?: (data: GridSortModel) => void;
  onPageChange?: (data: number) => void;
  onRowClick?: (data: any) => void;
  rowCount?: number;
  page?: number;
  paginationMode?: boolean;
  sortingMode?: boolean;
  isAuto?: boolean;
  components?: any;
}

const MiniteGrid = ({
  columns,
  rows,
  pagination,
  pageSize,
  rowsPerPageOptions,
  width,
  height,
  rowId,
  disableColumnFilter,
  loading,
  editRowCallback,
  editRowStartCallback,
  editRowEndCallback,
  rowCount,
  onPageChange,
  page,
  paginationMode,
  onRowClick,
  onSortModelChange,
  sortingMode,
  isAuto,
  components = {},
}: GridProps) => {
  const [editRowsModel, setEditRowsModel] = useState({});
  const handleEditRowsModelChange = useCallback((model: GridEditRowsModel) => {
    setEditRowsModel(model);
    editRowCallback && editRowCallback(model);
  }, []);

  const handleRowEditStart = (model) => {
    editRowStartCallback && editRowStartCallback(model);
  };

  const processRowUpdate = (model) => {
    const updatedRow = { ...model, isNew: false };
    editRowEndCallback && editRowEndCallback(model);
    return updatedRow;
  };

  return (
    <Box sx={{ height: height || 400, width: width || '100%' }}>
      <DataGrid
        className={`minite-grid${pagination ? '' : ' hide-pagination'}`}
        loading={loading}
        getRowId={(row) => {
          return row && row[rowId];
        }}
        rows={rows || []}
        columns={columns}
        editMode="row"
        experimentalFeatures={{ newEditingApi: true }}
        editRowsModel={editRowsModel}
        disableColumnFilter={disableColumnFilter}
        pageSize={pageSize || 8}
        paginationMode={paginationMode ? 'server' : 'client'}
        onEditRowsModelChange={handleEditRowsModelChange}
        onRowEditStart={handleRowEditStart}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={console.log}
        rowsPerPageOptions={
          rowsPerPageOptions ? rowsPerPageOptions : [pageSize]
        }
        getRowHeight={(e) => {
          return isAuto && e.model?.description?.length > 70 ? 'auto' : 52;
        }}
        getEstimatedRowHeight={() => 200}
        onRowClick={onRowClick}
        page={page}
        rowCount={rowCount}
        onPageChange={onPageChange}
        disableColumnMenu={true}
        sortingMode={sortingMode ? 'server' : 'client'}
        onSortModelChange={onSortModelChange}
        sx={{
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
            '.MuiDataGrid-cell': {
              borderBottomColor: '#e0e0e0',
            },
          },
        }}
        components={components}
      />
    </Box>
  );
};

export default MiniteGrid;
