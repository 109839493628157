import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { ADMIN_EVENT_LOGS } from './query-keys';
import { AxiosError } from 'axios';

const fetchEventLogs = async (body) => {
  const { data } = await request<ServerResponse<IEventLogRequest>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/audits/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useFetchEventLogs = (body) => {
  return useQuery([ADMIN_EVENT_LOGS, body], () => fetchEventLogs(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

interface IEventLogRequest {
  pagination: { total: number; page: number; per_page: number };
  audits: IEventLog[];
}

export interface IEventLog {
  previous_state: any;
  updated_state: any;
  email: string;
  created_at: string;
  audit_id: number;
  table_name: string;
  record_uid: string;
  user_uid: string;
  user_type: string;
  operation: string;
}
