export const openChatUrl = (
  proposalUid,
  openIn = '_blank',
  isAdmin = false,
) => {
  const url = `${window.location.origin}/${
    isAdmin ? 'admin-chat-v2' : 'chat-v2'
  }/${isAdmin ? '' : proposalUid}`;
  return window.open(url, openIn);
};
