import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ANNOUNCEMENTS } from './query-keys';

const announcementDetails = async (key: string) => {
  const { data } = await request<ServerResponse<AnnouncementDetails>>({
    url: process.env.REACT_APP_API_URL + `/api/v1/announcements/${key}`,
    method: 'get',
  });

  return data.data;
};

export const useAnnouncementDetailsUser = (key: string) => {
  return useQuery([ANNOUNCEMENTS, key], () => announcementDetails(key), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface AnnouncementDetails {
  admin_announcement_uid: string;
  key: string;
  value: string;
  url: string;
  visible: boolean;
  visible_for: string;
}
