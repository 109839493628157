import React, { useEffect, useState } from 'react';
import './CompanyAccountForms.scss';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useFetchCompanies,
  useFetchCompanyByKvk,
} from '../../../../../data/companies';
import { useUpdateUserProfileByAdmin } from '../../../../../data/user';
import { useDebounce } from '../../../../../utils/useDebounce';
import { useTranslation } from 'react-i18next';
import { AdminCompanyOverview } from '../../../../../data/admin-companies';
import { LoadingButton } from '../../../../../components/LoadingButton';
import MiniteSnackbar from '../../../../../components/snackbar';
import { Countries } from '../../../../../utils/consts';
import { getCountryCode, getCountryName } from '../../../../../utils/helpers';
import { postcodeValidator } from 'postcode-validator';

function a11yProps(index: boolean) {
  return {
    className: 'company-level-form-tabs',
    id: `company-level-tab-${+index}`,
    'aria-controls': `company-level-tabpanel-${+index}`,
  };
}

interface CompanyFormInterface {
  company: string;
  kvk: string;
  street: string;
  house: string;
  postal: string;
  city: string;
  showAllFields?: boolean;
  manual?: boolean;
  vat_number?: number;
  country?: string;
}

const AdminCompanyForm = (props) => {
  const details = props.details as AdminCompanyOverview;
  const refetch = props.refetch;
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const schema = yup
    .object()
    .shape({
      company: yup.string().required(),
      kvk: yup.string().when('manual', (value) => {
        return value
          ? yup
              .string()
              .matches(/\d{8}$|^$/)
              .optional()
              .nullable()
              .transform((val) => (!val && val !== 0 ? null : val))
          : yup
              .string()
              .matches(/\d{8}$/)
              .required();
      }),
      street: yup.string().required(),
      house: yup.string().required(),
      postal: yup
        .string()
        .test((value) => {
          if (!selectedCountryCode) return false;
          try {
            return postcodeValidator(value, selectedCountryCode);
          } catch (error) {
            return false;
          }
        })
        .required(),
      city: yup.string().required(),
      vat_number: yup
        .string()
        .matches(/[A-Za-z]{2}\d{9}B\d{2}$/)
        .nullable()
        .optional()
        .transform((val) => (!val && val !== 0 ? null : val)),
      country: yup.string().required(),
    })
    .required();

  const [search, setSearch] = useState('');
  const debouncedFilter = useDebounce(search, 500);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors, isValid, isDirty, isSubmitted },
    control,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      company: details?.company_name,
      kvk: details?.user?.kvk_number,
      street: details?.user?.street_name,
      house: details?.user?.house_number,
      postal: details?.user?.postal_code,
      city: details?.user?.city,
      country: getCountryName(details?.user?.country) || 'Netherlands',
      vat_number: details?.user?.vat_number,
      showAllFields: true,
      manual: false,
    },
  });

  /**
   * Used for postal code validation based on the country selected by the user
   */
  const selectedCountryName = watch('country');
  useEffect(() => {
    setSelectedCountryCode(getCountryCode(selectedCountryName));
  }, [selectedCountryName]);

  const selectedCompanyName = useWatch({ control, name: 'company' });
  const manualValue = useWatch({ control, name: 'manual' });
  const showAllValue = useWatch({ control, name: 'showAllFields' });
  const [companyName, setCompanyName] = useState(null);

  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    details?.user?.user_uid,
  );
  const {
    data: companies = [],
    error,
    isLoading: companiesLoading,
  } = useFetchCompanies(debouncedFilter);

  const companyOptions = companies?.map((item) => {
    return {
      label: `${item.name} ${item.city || ''} ${item.kvkNumber}`,
      id: item.name,
      kvk: item.kvkNumber,
    };
  });
  const selectedCompanyKvk = companyOptions.find(
    (item) => item.id === selectedCompanyName,
  )?.kvk;

  const { data: companyData } = useFetchCompanyByKvk(
    selectedCompanyKvk,
    !manualValue,
  );

  const countryOptions = Countries.map((country) => ({
    label: country.name,
    country: country.name,
    code: country.code,
  }));

  const onSubmit = async (data: CompanyFormInterface) => {
    try {
      const { country: countryName } = data;

      const countryCode = getCountryCode(countryName);

      setIsLoading(true);
      const payload: any = {
        user: {
          kvk_number: data?.kvk,
          street_name: data?.street,
          house_number: data?.house,
          postal_code: data?.postal,
          city: data?.city,
          vat_number: data?.vat_number,
          country: countryCode,
        },
        company: {
          company_name: data?.company,
        },
      };

      await updateUserProfile(payload);

      await refetch();

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event: any) => {
    if (event && event.type === 'change') {
      setSearch(event.target.value);
    }
  };

  useEffect(() => {
    if (companyData) {
      setValue('company', companyData.company);
      setValue('kvk', companyData.kvk);
      setValue('street', companyData.street);
      setValue('house', companyData.house);
      setValue('city', companyData.city);
      setValue('postal', companyData.postal);
      trigger();
    }
  }, [companyData]);

  useEffect(() => {
    if (details?.company_name) {
      setCompanyName(details?.company_name);
    }
  }, [details]);

  useEffect(() => {
    if (companyName && !manualValue) {
      setValue('manual', true);
    }
  }, [error]);

  useEffect(() => {
    if (details?.company_name) {
      setSearch(details?.company_name);
    }
  }, []);

  const handleFillManually = () => {
    setValue('company', null);
    setValue('kvk', null);
    setValue('street', null);
    setValue('house', null);
    setValue('city', null);
    setValue('postal', null);
    setValue('manual', true);
    setValue('showAllFields', true);
    setValue('country', null);
    trigger();
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-forms-titles">{t('your_company')}</p>
        </Grid>

        <Grid item xs={12} md={9}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: '10px',
            }}
          >
            <Tabs
              value={Number(manualValue)}
              onChange={() => {
                setValue('manual', !manualValue);
                setCompanyName(null);
              }}
              centered
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#DEB900',
                },
              }}
            >
              <Tab label={t('autofill')} {...a11yProps(false)} />
              <Tab label={t('manual_input')} {...a11yProps(true)} />
            </Tabs>
          </Box>
          <form className="financial-company-form">
            <>
              {!manualValue && (
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      loading={companiesLoading && !!debouncedFilter}
                      value={value}
                      loadingText={
                        <div style={{ display: 'flex' }}>
                          <CircularProgress size={14} sx={{ margin: 'auto' }} />
                        </div>
                      }
                      options={companyOptions}
                      onInputChange={handleSearch}
                      onChange={(e, data: { id: string } | null) => {
                        if (data) {
                          onChange(data.id);
                        } else {
                          onChange(null);
                        }
                      }}
                      isOptionEqualToValue={(option: { id: string }, value) => {
                        return option.id === value.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="form-input"
                          {...params}
                          variant="standard"
                          label={t('company_name')}
                        />
                      )}
                    />
                  )}
                  name="company"
                  control={control}
                />
              )}
              {manualValue && (
                <TextField
                  {...register('company')}
                  type="text"
                  className="form-input"
                  id="company-name-input"
                  label={t('company_name')}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              )}

              {error && !manualValue && !companyData && (
                <div className="fill-manually-hint-container">
                  <p className="no-results-text">
                    {t('no_result')} {search}
                  </p>
                  <p className="try-again-text">
                    {t('fill_in')}{' '}
                    <span
                      className="fill-manually-text"
                      onClick={handleFillManually}
                    >
                      {t('fill_manual')}
                    </span>
                  </p>
                </div>
              )}

              {(companyData || manualValue) && showAllValue && (
                <>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={value}
                        data-testid="company-country"
                        id="company-country"
                        disabled={true}
                        options={countryOptions}
                        onChange={(e, data: { country: string } | null) => {
                          if (data) {
                            onChange(data.country);
                          } else {
                            onChange(null);
                          }
                        }}
                        isOptionEqualToValue={(
                          option: { country: string },
                          value,
                        ) => {
                          return option.country === value.country;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            id="company-country-input"
                            label={t('company_country')}
                          />
                        )}
                      />
                    )}
                    name="country"
                    control={control}
                  />
                  <FormGroup className="form-group">
                    <TextField
                      {...register('street')}
                      className="form-group-input form-input"
                      name="street"
                      type="text"
                      id="street-input"
                      variant="standard"
                      label={t('street')}
                      error={!!errors.street}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                    <TextField
                      className="form-group-input form-input"
                      type="text"
                      id="house-input"
                      {...register('house')}
                      variant="standard"
                      label={t('house_number')}
                      name="house"
                      error={!!errors.house}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                  </FormGroup>

                  <FormGroup className="form-group">
                    <TextField
                      {...register('postal')}
                      className="form-group-input form-input"
                      type="text"
                      id="postal-input"
                      variant="standard"
                      label={t('postal')}
                      name="postal"
                      error={!!errors.postal}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />

                    <TextField
                      {...register('city')}
                      className="form-group-input form-input"
                      type="text"
                      id="city-input"
                      variant="standard"
                      label={t('city')}
                      name="city"
                      error={!!errors.city}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                  </FormGroup>

                  <TextField
                    {...register('kvk')}
                    type="text"
                    id="kvk-input"
                    label={'Kvk Nr.'}
                    variant="standard"
                    className="form-input"
                    name="kvk"
                    disabled={!manualValue && !!selectedCompanyKvk}
                    error={!!errors.kvk}
                    inputProps={{
                      maxLength: 8,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            </>
          </form>

          <div className="save-button-container">
            <Tooltip
              disableHoverListener={isValid}
              title="Please fill in all fields"
            >
              <span>
                <LoadingButton
                  isDisabled={!isValid || !isDirty}
                  className="save-button"
                  onClick={handleSubmit(onSubmit)}
                  buttonText={
                    !isDirty && isSubmitted
                      ? t('changes_saved')
                      : t('save_changes')
                  }
                  isLoading={isLoading}
                  confirmButtonTestId="save-company-info-admin"
                />
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AdminCompanyForm;
