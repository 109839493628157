import React, { useEffect, useMemo, useState } from 'react';
import '../AdminGeneralStyles.scss';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import AdminStatisticCard from '../../../components/admin-statistic-card';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import {
  useCompanyStatistic,
  useSearchAdminCompanies,
} from '../../../data/admin-companies';
import { useDebounce } from '../../../utils/useDebounce';
import { useFetchIndustries } from '../../../data/edit-profile';
import { formatDate } from '../../../utils/dates';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Routes } from '../../../utils/consts';
import ResizableTable from '../../../components/resizable-table';

interface ICompany {
  company_uid: string;
  company_name: string;
  email: string;
  jobs_in_progress_count: number;
  created_at: string;
  jobs_posted_count: number;
  students_hired_count: number;
  verified: boolean;
  ref_code: string;
  invited_by_ref_code: string | undefined;
  other_how_did_you_find_minite: string;
}

const getStatisticCards = (statistics) => {
  return (
    <div className="admin-statistics-container">
      <AdminStatisticCard
        key={'total'}
        text={'Total companies'}
        counter={statistics.companies_total}
      />
      <AdminStatisticCard
        key={'average'}
        text={'Average posted jobs per company'}
        counter={statistics.average_jobs_per_company}
      />
      <AdminStatisticCard
        key={'high-flyers'}
        text={'Average High Flyers per company'}
        counter={statistics.average_students_per_company}
      />
      <AdminStatisticCard
        key={'this-month'}
        text={'New company signups this month'}
        counter={statistics.companies_this_month}
      />
    </div>
  );
};

const AdminCompanies = () => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    how_did_you_find_us: undefined,
    referral: undefined,
  });
  const [hidenColumn, setHidenColumn] = useState({
    other: false,
    invited_by_ref_code: false,
  });

  const companiesColumns = useMemo(() => {
    return [
      {
        accessorKey: 'company_name',
        header: 'Name',
        enableSorting: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false,
      },
      {
        accessorKey: 'jobs_in_progress_count',
        header: 'Jobs in progress',
        enableSorting: true,
      },
      {
        accessorKey: 'jobs_posted_count',
        header: 'Jobs posted',
        enableSorting: true,
      },
      {
        accessorKey: 'students_hired_count',
        header: 'High Flyers hired',
        enableSorting: true,
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
        enableSorting: true,
      },
      {
        accessorKey: 'verified',
        header: 'Verified',
        enableSorting: true,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'ref_code',
        header: 'Ref code of a user',
        enableSorting: true,
      },
      {
        accessorKey: 'invited_by_ref_code',
        header: 'Ref code used by a user',
        enableSorting: true,
      },
      {
        accessorKey: 'other',
        header: 'Other',
        enableSorting: true,
      },
    ];
  }, []);

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: companiesData,
    refetch: refetchCompaniesData,
    isLoading,
  } = useSearchAdminCompanies({ ...filters, query: debouncedSearch });
  const { data: industries = [] } = useFetchIndustries();
  const industriesOptions = industries?.map((item) => ({
    label: item.interest_name,
    id: item.interest_uid,
  }));
  const { pagination, companies } = companiesData || {};
  const refetchCompanies = async () => {
    await refetchCompaniesData();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  const { data: statistics = {} } = useCompanyStatistic();
  const myCompaniesGridData = (): unknown[] =>
    companies?.map((company: ICompany) => {
      const {
        company_uid,
        company_name,
        email,
        jobs_in_progress_count,
        created_at,
        jobs_posted_count,
        students_hired_count,
        verified,
        ref_code,
        invited_by_ref_code,
        other_how_did_you_find_minite,
      } = company;

      return {
        id: company_uid,
        company_name,
        email,
        jobs_in_progress_count,
        created_at: formatDate(created_at, 'DD/MM/YYYY'),
        jobs_posted_count,
        students_hired_count,
        verified,
        ref_code,
        invited_by_ref_code,
        other: other_how_did_you_find_minite || '',
      };
    });

  const handleRowClick = (data) => {
    window.open(`${Routes.AdminCompaniesRoute}/${data.id}`, '_blank');
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const isHideOtherColumn = () => {
    return filters?.how_did_you_find_us?.includes('Other');
  };

  useEffect(() => {
    refetchCompanies();
    setHidenColumn({
      other: isHideOtherColumn() ?? false,
      invited_by_ref_code: filters?.referral ?? false,
    });
  }, [filters]);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-companies-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Companies</p>

      <Grid item lg={10} md={10} xl={10} sm={10} xs={10}>
        {getStatisticCards(statistics)}
      </Grid>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All companies</p>
          <div className="admin-table-inputs companies-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-companies-input"
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />
            <FiltersPanel
              values={{ industries: industriesOptions }}
              page="admin-companies"
              filters={filters}
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;

                setFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <ResizableTable
          pageSize={filters.per_page}
          columns={companiesColumns}
          rows={myCompaniesGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          hideColumn={hidenColumn}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminCompanies;
