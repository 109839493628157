import React from 'react';
import { Avatar, AvatarGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Community.scss';

const Community = () => {
  const { t } = useTranslation();

  return (
    <div className="community-wrapper sides">
      <div className="cat-background">
        <img src="/images/sign-up-avatars/cat2x.png" alt="Cat" />
      </div>
      <p className="title">{t('select_role.community')}</p>

      <p className="description">{t('select_role.collar_life')}</p>

      <div className="avatars-container">
        <AvatarGroup max={4}>
          <Avatar alt="Remy Sharp" src="/images/sign-up-avatars/avatar1.png" />
          <Avatar
            alt="Travis Howard"
            src="/images/sign-up-avatars/avatar2.png"
          />
          <Avatar alt="Cindy Baker" src="/images/sign-up-avatars/avatar3.png" />
          <Avatar
            alt="Agnes Walker"
            src="/images/sign-up-avatars/avatar4.png"
          />
        </AvatarGroup>
        <p className="join-text">{t('select_role.join_community')}</p>
      </div>
    </div>
  );
};

export default Community;
