import React, { useEffect, useState } from 'react';
import '../../financial/components/AccountForms.scss';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useFetchIndustries,
  useUpdateUserProfile,
} from '../../../../data/edit-profile';
import { IOption, isOptionEqualToValue } from '../../../../utils/ioption';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../components/LoadingButton';

const schema = yup
  .object()
  .shape({
    industry: yup.object(),
  })
  .required();

interface IndustryFormInterface {
  industry: IOption;
}

const IndustryForm = ({ user, refetchUser }) => {
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { data: industries = [] } = useFetchIndustries();
  const [isLoading, setIsLoading] = useState(false);

  const industriesOptions = industries?.map((item) => ({
    label: item.interest_name,
    id: item.interest_uid,
  }));
  const defaultValue = industriesOptions?.find((item) => {
    return item?.id === user?.company?.interest_uid;
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.company',
  });

  useEffect(() => {
    if (industries.length === 0) {
      refetchUser();
    }
  }, [industries]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid, isDirty, isSubmitted },
  } = useForm<IndustryFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      industry: {
        ...defaultValue,
      },
    },
    reValidateMode: 'onChange',
  });

  if (defaultValue?.id && !getValues('industry')?.label) {
    setValue('industry', defaultValue);
  }

  const selectedIndustry = useWatch({ control, name: 'industry' });

  const onSubmit = async (data: IndustryFormInterface) => {
    setIsLoading(true);

    const interest_uid = data?.industry?.id;
    await updateUserProfile({
      company: {
        interest_uid,
      },
    });

    await refetchUser();

    reset(data);
    setIsLoading(false);
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-title account-financial-forms-titles">
            {t('field_of_work')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <Alert severity="info" className="">
              <AlertTitle>{t('example')}</AlertTitle>
              {t('example_alert')} <strong>{selectedIndustry?.label}</strong>
            </Alert>

            <FormControl
              variant="standard"
              sx={{ width: '100%', marginBottom: '16px' }}
            >
              <Controller
                control={control}
                name="industry"
                render={({ field, field: { onChange } }) => (
                  <Autocomplete
                    id="interests-select"
                    options={industriesOptions}
                    onChange={(_, data) => onChange(data)}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={(option: IOption) => option?.label || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label={t('industry')}
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </form>

          <div className="save-button-container">
            <LoadingButton
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !isDirty}
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
              confirmButtonTestId="save-job-industry"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default IndustryForm;
