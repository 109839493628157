import React, { useState } from 'react';
import './CompanyAccountForms.scss';
import { Button, FormGroup, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import MiniteSnackbar from '../../../../../components/snackbar';
import { useUpdateCompanyLogo } from '../../../../../data/edit-profile';
import { useTranslation } from 'react-i18next';
import { AdminCompanyOverview } from '../../../../../data/admin-companies';
import { useUpdateUserProfileByAdmin } from '../../../../../data/user';
import CompanyAvatar from '../../../../../components/company-avatar';
import { LoadingButton } from '../../../../../components/LoadingButton';

const schema = yup
  .object()
  .shape({
    website: yup.string(),
    bio: yup.string().max(600),
    avatar: yup.string().nullable(),
  })
  .required();

interface CompanyProfileFormInterface {
  website: string;
  bio: string;
  avatar: string;
}

const AdminCompanyProfileForm = (props) => {
  const details = props.details as AdminCompanyOverview;
  const refetch = props.refetch;
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: updateUserProfile } = useUpdateUserProfileByAdmin(
    details?.user?.user_uid,
  );
  const { mutateAsync: updateCompanyLogo } = useUpdateCompanyLogo();
  const [tempAvatar, setTempAvatar] = useState(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_profile.company',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(
    details?.user?.avatar
      ? process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${details.user.avatar}?` +
          Date.now()
      : null,
  );
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    trigger,
    formState: { isValid, errors, isDirty, isSubmitted },
  } = useForm<CompanyProfileFormInterface>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      website: details?.website,
      bio: details?.bio,
      avatar: details?.user?.avatar,
    },
    reValidateMode: 'onChange',
  });

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded?.size > 1048576) {
      setErrorMessage('Image size should be less or equal 1mb');
      return;
    }
    const tempFileUrl = URL.createObjectURL(fileUploaded);
    const reader = new FileReader();
    reader.addEventListener('loadend', async () => {
      setValue('avatar', tempFileUrl, { shouldDirty: true });
      trigger();
      setTempAvatar({ url: tempFileUrl, file: fileUploaded, reader: reader });
    });
    reader.readAsArrayBuffer(fileUploaded);
  };

  const onSubmit = async (data: CompanyProfileFormInterface) => {
    try {
      setIsLoading(true);
      if (tempAvatar) {
        const imageResponse = await updateCompanyLogo({
          filename: tempAvatar?.file?.name,
          contentType: tempAvatar?.file?.type,
          for_user_uid: details.user.user_uid,
        });

        await fetch(imageResponse?.data?.url, {
          method: 'PUT',
          body: new Blob([tempAvatar?.reader?.result], {
            type: tempAvatar?.file?.type,
          }),
        });

        await updateUserProfile({
          user: {
            avatar: imageResponse?.data?.filename,
          },
        });
      }

      await updateUserProfile({
        company: {
          website: data?.website,
          bio: data?.bio,
        },
      });

      await refetch();

      setTempAvatar(null);
      setAvatar(
        process.env.REACT_APP_S3_BUCKET_URL +
          '/avatars/companies/' +
          `${details?.user?.avatar}?` +
          Date.now(),
      );
      reset(data);
    } catch (error) {
      console.log('Failed to upload profile', error);
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account-form-container account-financial-forms">
      <Grid
        container
        direction="row"
        className="account-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="account-financial-title account-financial-forms-titles">
            {t('company_profile')}
          </p>
        </Grid>

        <Grid item xs={12} md={9}>
          <form className="account-form">
            <CompanyAvatar avatar={tempAvatar?.url || avatar} />

            <FormGroup className="form-group form-input-btn-group">
              <TextField
                className="form-group-input form-input"
                {...register('avatar')}
                name="avatar"
                type="text"
                id="avatar-input"
                disabled={true}
                variant="standard"
                label={t('company_logo')}
                placeholder="No file selected"
                error={!!errors.avatar}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                className="choose-logo-btn"
                variant="contained"
                component="label"
                data-testid="choose-logo-company-as-admin"
                sx={{
                  minWidth: 100,
                }}
              >
                {t('choose_file')}
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onClick={handleClick}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                />
              </Button>
            </FormGroup>

            <TextField
              className="form-group-input form-input"
              {...register('website')}
              name="website"
              type="text"
              id="website-input"
              variant="standard"
              label={t('website')}
              error={!!errors.website}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              className="form-group-input form-input"
              {...register('bio')}
              name="bio"
              type="text"
              multiline
              maxRows={6}
              inputProps={{ maxLength: 600 }}
              id="bio-input"
              variant="standard"
              label={t('company_bio')}
              helperText={t('max_600_characters')}
              error={!!errors.bio}
              InputLabelProps={{ shrink: true }}
            />
          </form>

          <div className="save-button-container">
            <LoadingButton
              isDisabled={!isValid || !isDirty}
              className="save-button"
              onClick={handleSubmit(onSubmit)}
              confirmButtonTestId="save-company-info-admin"
              buttonText={
                !isDirty && isSubmitted ? t('changes_saved') : t('save_changes')
              }
              isLoading={isLoading}
            />
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default AdminCompanyProfileForm;
