import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useState } from 'react';
import { defaultButtonStyle } from './Assessment';
import './MC.scss';

interface AnswerOption {
  value: string;
  label: string;
}

interface Props {
  options: AnswerOption[];
  onSubmit: (answer: string) => void;
  submitButtonText?: string;
}

const MC = ({ options, onSubmit, submitButtonText }: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleOnSubmit = () => {
    if (!value) return;
    setIsDisabled(true);
    onSubmit(value);
  };

  return (
    <FormControl className="mc-container">
      <div className="mc-options">
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {options.map(
            (option) =>
              option.label && (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio required={true} />}
                  label={option.label}
                  disabled={isDisabled}
                />
              ),
          )}
        </RadioGroup>
      </div>
      <div className="mc-submit-button-container">
        <Button
          sx={defaultButtonStyle}
          disabled={isDisabled}
          onClick={handleOnSubmit}
          variant="contained"
        >
          {submitButtonText ? submitButtonText : 'Submit'}
        </Button>
      </div>
    </FormControl>
  );
};

export default MC;
