import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({
  setSearch,
  value,
  className = '',
}: {
  setSearch: (val: string) => void;
  className: string;
  value: string;
}) => {
  return (
    <>
      <TextField
        placeholder="Type to search"
        sx={{ width: '148px' }}
        variant="standard"
        className={`admin-table-search-input ${className}`}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(event) => setSearch(event.target.value)}
      />
    </>
  );
};

export default Search;
