import React from 'react';
import SignupForm from '../../components/sign-up/Signup';
import './Signup.scss';
import useDocumentTitle from '../../utils/useDocumentTitle';

const Signup = () => {
  useDocumentTitle('Sign Up');

  return <SignupForm />;
};

export default Signup;
