import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import './Logcase.scss';
import { Routes } from '../../../utils/consts';
import { categories, levels } from './information';
import { UserData } from '../../../data/user';
import MiniteSnackbar from '../../../components/snackbar';
import { useTranslation } from 'react-i18next';
import { useLogSupport } from '../../../data/support';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { LoadingButton } from '../../../components/LoadingButton';
import { useUserData } from '../../../stores/auth';

interface Support {
  category: string;
  urgency_level: string;
  message: string;
}

const schema = yup.object().shape({
  category: yup.string().required('required'),
  urgency_level: yup.string().required('required'),
  message: yup.string().required('required').max(2000),
});

const initialSupportForm = {
  category: '',
  urgency_level: '',
  message: '',
};

const Logcase = () => {
  useDocumentTitle('Log a Support Case');

  const [isShowToast, setIsShowToast] = useState(false);
  const { mutateAsync: logSupportRequest, isLoading } = useLogSupport();
  const userData = useUserData((state) => state.user);
  const userInfo: UserData = userData as unknown as UserData;
  const userType = userInfo.student_uid ? 'student' : 'company';
  const category = categories[userType];
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    control,
  } = useForm<Support>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { ...initialSupportForm },
  });

  const onSubmit = (data) => {
    logSupportRequest(data).then(() => {
      setIsShowToast(true);
    });
  };

  return (
    <Grid container className="support-page">
      <Grid
        className="support-forms-container"
        sx={{
          maxWidth: 'sm',
          width: '100%',
        }}
      >
        <p className="title">{t('navigation.support_case')}</p>
        <Typography variant="body2" className="description-text">
          {t('log_support_case.we_are_here')}
        </Typography>
        <Typography variant="body2" className="description-text">
          {t('log_support_case.dont_forget')}{' '}
          <Link to={Routes.KnowledgeBaseSupportRoute} className="link">
            knowledge base
          </Link>{' '}
          {t('log_support_case.dont_forget1')}
        </Typography>
        <form className="account-form">
          <Grid direction="column" container>
            <Controller
              render={({ field: { value, onChange } }) => (
                <FormControl variant="standard">
                  <InputLabel id="type-select-label">
                    {t('log_support_case.first_q')}
                  </InputLabel>
                  <Select
                    labelId="question-select-label"
                    id="question-select"
                    label={t('log_support_case.first_q')}
                    value={value}
                    onChange={onChange}
                    disabled={isSubmitted}
                    error={!!errors.category}
                    {...register(`category`)}
                  >
                    {category.map((item, i) => {
                      return (
                        <MenuItem key={`${item}${i}`} value={item}>
                          {t('log_support_case.' + item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              control={control}
              name={'category'}
            />

            <Controller
              render={({ field: { value, onChange } }) => (
                <FormControl variant="standard">
                  <InputLabel id="type-select-label">
                    {t('log_support_case.urgency')}
                  </InputLabel>
                  <Select
                    name={'urgency_level'}
                    labelId="question-select-label"
                    id="question-select"
                    label={t('log_support_case.urgency')}
                    value={value}
                    onChange={onChange}
                    disabled={isSubmitted}
                    error={!!errors.urgency_level}
                    {...register(`urgency_level`)}
                  >
                    {levels.map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {t('log_support_case.' + item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              control={control}
              name={'urgency_level'}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <FormControl variant="standard">
                  <TextField
                    {...register('message')}
                    type="text"
                    className="user-info-form-groups"
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    label={t('log_support_case.desc')}
                    error={!!errors.message}
                    multiline={true}
                    disabled={isSubmitted}
                    rows={15}
                    inputProps={{
                      maxLength: 2000,
                    }}
                    helperText={t('log_support_case.max_char')}
                  />
                </FormControl>
              )}
              control={control}
              name={'message'}
            />
            {(!isSubmitted || isLoading) && (
              <LoadingButton
                className="confirm-button"
                onClick={handleSubmit(onSubmit)}
                isDisabled={!isValid}
                buttonText={t('log_support_case.submit_case')}
                isLoading={isLoading}
                confirmButtonTestId="submit-support-case"
              />
            )}
          </Grid>
        </form>

        <MiniteSnackbar
          severity={'success'}
          message={t('log_support_case.alert_text')}
          title={t('log_support_case.case_submitted')}
          autoHideDuration={10000}
          open={isShowToast}
          onClose={() => setIsShowToast(false)}
        />
      </Grid>
    </Grid>
  );
};

export default Logcase;
