import React, { useEffect, useState } from 'react';
import { Alert, Box, Skeleton, Snackbar } from '@mui/material';
import AddressForm from '../../../components/sign-up/components/company/AddressForm';
import '../Signup.scss';
import './Company.scss';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { FormSteps } from '../../../components/sign-up/interfaces/steps';
import { SharedStepper } from '../../../shared/stepper/Stepper';
import { signUpCompany } from '../../../data/user';
import { sleep } from '../../../utils/helpers';

const FormSkeleton = () => (
  <Box sx={{ width: 360 }} className="skeleton-wrapper">
    <Skeleton sx={{ height: 50 }} />
    <Skeleton sx={{ height: 50 }} animation="wave" />
    <Skeleton sx={{ height: 50 }} animation="wave" />
    <Skeleton sx={{ height: 50, width: 60 }} animation="wave" />
  </Box>
);

const Company = () => {
  const [steps] = useState(FormSteps.company);
  const [errorSnack, showSnack] = useState(null);

  useEffect(() => {
    // Report conversion to google adwords
    (window as any).gtagReportCompanySignupTestConversion();
  }, []);

  const [isLoading] = useState(false);

  useDocumentTitle('Sign Up Company');

  const handleNext = async (data) => {
    const { company, kvk, street, house, postal, city, country } = data;

    const companyData = {
      company_name: company,
      user: {
        kvk_number: kvk,
        street_name: street,
        house_number: house,
        postal_code: postal,
        city,
        country,
      },
    };

    try {
      await signUpCompany(companyData);

      // send to google analytics
      const companySignUpTracker = document.querySelector(
        '#track-company-completed-signup',
      ) as HTMLInputElement;

      companySignUpTracker.click();

      // send conversion to google adwords
      if (window.location.host === 'app.minite.works') {
        (window as any).gtagReportCompanyConversion();
      }

      // allow time to report to google ads and google analytics
      await sleep(4000);

      window.location.pathname = '/';
    } catch (error) {
      if (error.response.status === 500) {
        showSnack(
          'Oops, looks like a timeout! Please try again later or contact it@minite.works',
        );
      } else {
        showSnack(error.message);
      }
    }
  };

  return (
    <main>
      {/* Start google analytics */}
      <input type="hidden" id="track-company-completed-signup" />
      {/* End google analytics */}
      <section className="company-form">
        <SharedStepper steps={steps} activeStep={1} />
        <div>
          {isLoading ? (
            <FormSkeleton />
          ) : (
            <AddressForm handleNextStep={handleNext} />
          )}
        </div>
      </section>

      {errorSnack && (
        <Snackbar
          onClose={() => showSnack(null)}
          autoHideDuration={5000}
          open={!!errorSnack}
          message={errorSnack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            marginTop: '55px',
          }}
        >
          <Alert onClose={() => showSnack(null)} severity="error">
            {errorSnack}
          </Alert>
        </Snackbar>
      )}
    </main>
  );
};

export default Company;
