import React from 'react';
import { IItemInfo } from './ContentInfo';

const InfoBoldText = ({ item }: { item: IItemInfo }) => {
  const { text, boldText } = item;

  return (
    <>
      <span className="bold-text">{boldText}</span>
      {text}
    </>
  );
};

export default InfoBoldText;
