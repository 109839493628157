import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';

function a11yProps(index: number) {
  return {
    className: 'chat-page-tab',
    id: `chat-tab-${index}`,
    'aria-controls': `chat-tabpanel-${index}`,
  };
}

const setUnreadChatCount = (count) => (count ? `(${count})` : '');

interface IProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
}

const ChatTabs = ({ onChange, value }: IProps) => {
  const [unreadChats, setUnreadChats] = useState(null);

  useEffect(() => {
    const time = setInterval(() => {
      const chats = JSON.parse(localStorage.getItem('chats'));
      const { company, student } = chats;

      if (
        company !== unreadChats?.company ||
        student !== unreadChats?.student
      ) {
        setUnreadChats(chats);
      }
    }, 1500);

    return () => clearInterval(time);
  }, []);

  return (
    <Tabs
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: '#DEB900',
        },
      }}
    >
      <Tab
        label={`Student Support ${setUnreadChatCount(unreadChats?.student)}`}
        {...a11yProps(0)}
      />
      <Tab
        label={`Company Support ${setUnreadChatCount(unreadChats?.company)}`}
        {...a11yProps(1)}
      />
      <Tab label="Company - Student" {...a11yProps(2)} />
    </Tabs>
  );
};

export default ChatTabs;
