import React from 'react';
import { IItem } from './ContentInfo';
import InfoWithLink from './InfoWithLink';

const InfoList = ({ list }: { list: IItem[] }) => {
  return (
    <ul>
      {list.map((item, index) => (
        <li key={index} className="list-item">
          {item.link ? <InfoWithLink item={item} /> : item.text}
        </li>
      ))}
    </ul>
  );
};

export default InfoList;
