import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import CompanyStudentChat from './CompanyStudentChat';
import './AdminChat.scss';
import ChatByType from './Chat';
import { useUserData } from '../../../stores/auth';
import ChatTabs from './ChatTabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AdminChat = () => {
  const [value, setValue] = useState(0);
  const user = useUserData((state) => state.user);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid
      sx={{
        maxWidth: '1200px',
        width: '100%',
      }}
      className="admin-chat"
    >
      <p className="section-title">Chats</p>
      <Box sx={{ width: '100%' }}>
        <Box
          className="tabs-wrapper"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <ChatTabs onChange={handleChange} value={value} />
        </Box>
        <TabPanel value={value} index={0}>
          {user && <ChatByType user={user.internal_chat} type="student" />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {user && <ChatByType user={user.internal_chat} type="company" />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CompanyStudentChat />
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default AdminChat;
