import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchAdminProposals } from '../../../data/admin-dashboard';
import { ProposalResponse } from '../../../data/proposals';
import { useDebounce } from '../../../utils/useDebounce';
import UserChatInfo from './UserChatInfo';

const CompanyStudentChat = () => {
  const talkjsContainer = useRef(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { data: proposalsData = [] } = useFetchAdminProposals({
    query: debouncedSearch,
    proposal_status: [
      'pending',
      'retracted',
      'interviewing',
      'rejected',
      'accepted',
      'signed',
    ],
  });

  const [activeProposal, setActiveProposal] = useState(null);

  const changeProposal = (proposal) => {
    setActiveProposal(proposal as ProposalResponse);
  };

  useEffect(() => {
    if (proposalsData[0]) {
      setActiveProposal(proposalsData[0]);
    }
  }, [proposalsData]);

  return (
    <>
      <div>
        <TextField
          placeholder="Type to search"
          sx={{ width: '256px' }}
          variant="standard"
          className="admin-table-search-input search-timesheets-input"
          onChange={(event) => setSearch(event.target.value)}
          data-testid="find-chat"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {proposalsData.length ? (
        <div className="chat-wrapper">
          <div className="proposals-list">
            {proposalsData.map((item) => {
              const { proposal_uid, company, student, job } = item;

              return (
                <div
                  key={proposal_uid}
                  className={
                    activeProposal?.proposal_uid === proposal_uid
                      ? 'active'
                      : ''
                  }
                  onClick={() => changeProposal(item)}
                >
                  <UserChatInfo
                    companyAvatar={company.avatar}
                    studentAvatar={student.avatar}
                    jobTitle={job.job_title}
                    studentName={student.first_name}
                    companyName={company.company_name || company.first_name}
                    list
                  />
                </div>
              );
            })}
          </div>
          <div className="chat-box">
            <UserChatInfo
              companyAvatar={activeProposal?.company.avatar}
              studentAvatar={activeProposal?.student.avatar}
              jobTitle={activeProposal?.job.job_title}
              studentName={activeProposal?.student.first_name}
              companyName={
                activeProposal?.company.company_name ||
                activeProposal?.company.first_name
              }
            />
            <div ref={talkjsContainer} className="messages-list">
              ...loading
            </div>
          </div>
        </div>
      ) : (
        <div className="nothing-found">Nothing found</div>
      )}
    </>
  );
};

export default CompanyStudentChat;
