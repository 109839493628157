import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  checkboxClasses,
} from '@mui/material';
import './SelectRole.scss';
import { Routes } from '../../../utils/consts';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '../../LoadingButton';
import { useNavigate } from 'react-router-dom';
import { HowFoundCompany, HowFoundStudent } from '../interfaces/static-data';
import Community from '../../community';
import { signUpCompany, signUpStudent } from '../../../data/user';
import MiniteSnackbar from '../../snackbar';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useTranslation } from 'react-i18next';

interface RoleForm {
  user_type: string;
  phone: string;
  how_did_you_find_minite: string;
  ref_code?: string;
}

const user_types = [
  {
    name: 'Company',
    value: 'company',
  },
  {
    name: 'Student',
    value: 'student',
  },
];

export const SelectRole = () => {
  const navigate = useNavigate();
  const [howFoundOptions, setHowFoundOptions] = useState(['Referral']);
  const storedRefCode = localStorage.getItem('ref_code') || '';
  const [refCode, setRefCode] = useState(storedRefCode);
  const [howFound] = useState(!!refCode ? 'Referral' : '');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [termsAndConditionsLink, setTermsAndConditionsLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useDocumentTitle('Select Role');

  const schema = yup
    .object()
    .shape({
      user_type: yup.string().required(),
      phone: yup
        .string()
        .matches(/[0-9]{10,14}/)
        .optional(),
      how_did_you_find_minite: yup.string().required(),
      ref_code: yup.string().optional(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<RoleForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      user_type: '',
      phone: '',
      how_did_you_find_minite: howFound || '',
      ref_code: '',
    },
    reValidateMode: 'onChange',
  });

  const userType = watch('user_type');
  const how_did_you_find_minite = watch('how_did_you_find_minite');
  const phoneField = watch('phone');

  useEffect(() => {
    switch (userType) {
      case 'company':
        setHowFoundOptions(HowFoundCompany);
        setTermsAndConditionsLink(
          'https://www.minite.works/terms-of-use/terms-of-use-companies',
        );
        break;
      case 'student':
        setHowFoundOptions(HowFoundStudent);
        setTermsAndConditionsLink(
          'https://www.minite.works/terms-of-use/terms-of-use-students',
        );
        break;
    }
  }, [userType]);

  const onSubmit = async (data) => {
    setIsClicked(true);

    const {
      phone,
      ref_code: invited_by_ref_code,
      how_did_you_find_minite,
    } = data;

    if (
      (phone &&
        how_did_you_find_minite === 'Referral' &&
        invited_by_ref_code) ||
      how_did_you_find_minite !== 'Referral'
    ) {
      localStorage.setItem('ref_code', invited_by_ref_code);

      const signup_data = {
        user: {
          phone,
          invited_by_ref_code,
          how_did_you_find_minite,
        },
      };

      try {
        setIsLoading(true);
        switch (data.user_type) {
          case 'company':
            await signUpCompany(signup_data);

            // Note: this element is found in App.tsx just before Navbar component

            // send to google analytics
            const companyVerificationTracker = document.querySelector(
              '#track-company-land-on-verify-email',
            ) as HTMLInputElement;
            companyVerificationTracker.click();

            // send to google analytics
            const companySignUpTracker = document.querySelector(
              '#track-company-land-on-kvk-details-page',
            ) as HTMLInputElement;
            companySignUpTracker.click();
            localStorage.removeItem('ref_code');
            navigate(Routes.SignUpCompanyInfo);
            break;
          case 'student':
            await signUpStudent(signup_data);

            // Note: these element is found in App.tsx just before Navbar component

            // send to google analytics
            const studentVerificationTracker = document.querySelector(
              '#track-student-land-on-verify-email',
            ) as HTMLInputElement;
            studentVerificationTracker.click();

            // send to google analytics
            const studentSignUpTracker = document.querySelector(
              '#track-student-land-on-student-details-page',
            ) as HTMLInputElement;
            studentSignUpTracker.click();
            localStorage.removeItem('ref_code');
            navigate(Routes.SignUpStudentInfo);
            break;
        }
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error.message || error.message.toString(),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buttonVariant = (type) => {
    return userType === type ? 'contained' : 'outlined';
  };

  const handleChangeUserType = (e) => {
    setValue('user_type', e.target.value);
  };

  return (
    <section className="select-role-section">
      <div className="sides left-side">
        <div className="select-role">
          <div className="title-container">
            <p className="title">Select role</p>
          </div>
          <form className="user-details-form">
            <div className="form-group">
              <div className="form-inputs">
                <div>
                  <Controller
                    name="user_type"
                    control={control}
                    render={() => (
                      <FormControl variant="standard">
                        <div className="user-role-wrapper">
                          {user_types.map((item) => (
                            <Button
                              variant={buttonVariant(item.value)}
                              onClick={handleChangeUserType}
                              className="button"
                              value={item.value}
                              key={item.value}
                            >
                              {item.name}
                            </Button>
                          ))}
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
              {userType && (
                <div className="form-inputs">
                  <div>
                    <Controller
                      name="how_did_you_find_minite"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormControl variant="standard">
                          <InputLabel id="category-select-label">
                            How did you find us?
                          </InputLabel>
                          <Select
                            id="status-select"
                            variant="standard"
                            value={value}
                            required={true}
                            onChange={onChange}
                            error={!!errors.user_type}
                          >
                            {howFoundOptions.map((option) => {
                              return (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                </div>
              )}
              {how_did_you_find_minite === 'Referral' && (
                <div className="form-inputs">
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <TextField
                      {...register('ref_code')}
                      type="text"
                      id="ref_code"
                      onChange={(e) => setRefCode(e.target.value)}
                      value={refCode}
                      error={
                        (isClicked || errors.phone) &&
                        how_did_you_find_minite === 'Referral' &&
                        !refCode
                      }
                      label={'Referral code'}
                      variant="standard"
                    />
                  </FormControl>
                </div>
              )}
              <div className="form-inputs">
                <FormControl
                  sx={{
                    width: '100%',
                  }}
                >
                  <TextField
                    {...register('phone')}
                    type="text"
                    id="phone"
                    error={(isClicked && !phoneField) || Boolean(errors.phone)}
                    label={'Phone'}
                    variant="standard"
                  />
                </FormControl>
              </div>
            </div>
          </form>

          {termsAndConditionsLink && (
            <div className="terms-and-conditions">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acknowledgement}
                    onChange={(e) => setAcknowledgement(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      [`&.${checkboxClasses.checked}`]: {
                        color: '#DEB900',
                      },
                    }}
                  />
                }
                label={t('sign_up.i_accept')}
              />

              <Link
                href={termsAndConditionsLink}
                target="_blank"
                className="link"
              >
                {t('sign_up.terms_of_use')}
              </Link>
            </div>
          )}

          <LoadingButton
            className="save-button"
            isDisabled={
              !userType ||
              !how_did_you_find_minite ||
              !acknowledgement ||
              isLoading
            }
            onClick={handleSubmit(onSubmit)}
            buttonText={'Proceed'}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Community />
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </section>
  );
};
