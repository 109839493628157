import React, { useEffect, useMemo, useState } from 'react';
import '../AdminGeneralStyles.scss';
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FiltersPanel from '../../../components/filter-panel';
import { useDebounce } from '../../../utils/useDebounce';
import { Routes } from '../../../utils/consts';
import ResizableTable from '../../../components/resizable-table';
import DoneIcon from '@mui/icons-material/Done';
import { formatDate } from '../../../utils/dates';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import {
  InvoicesResponse,
  useSearchAdminInvoices,
} from '../../../data/admin-invoices';

const TypeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const AdminInvoices = () => {
  const [search, setSearch] = useState('');

  const [hidenColumn, setHidenColumn] = useState({
    other: false,
  });

  const [filters, setFilters] = useState({
    per_page: 15,
    page: 1,
    query: '',
    order_by: 'created_at',
    asc: false,
    how_did_you_find_us: undefined,
    show: 'onetime',
  });

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      show: event.target.checked ? 'ongoing' : 'onetime',
    });
  };

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: invoicesData,
    refetch: refetchInvoicesDate,
    isLoading,
  } = useSearchAdminInvoices({ ...filters, query: debouncedSearch });

  const { pagination, invoices } = invoicesData || {};

  const myInvoicesGridData = (): unknown[] =>
    invoices?.map((invoice_response: InvoicesResponse) => {
      return {
        id: invoice_response.invoice_uid,
        job_name: invoice_response.job_title,
        job_uid: invoice_response.job_uid,
        invoice_number: invoice_response.invoice_number,
        created_at:
          filters.show === 'onetime'
            ? formatDate(invoice_response.created_at, 'DD/MM/YYYY')
            : formatDate(
                invoice_response.invoice_sent_at
                  ? invoice_response.invoice_sent_at
                  : invoice_response.created_at,
                'DD/MM/YYYY',
              ),
        paid_status: invoice_response.invoice_paid,
        sent_status: invoice_response.invoice_sent,
        payment_status: invoice_response.payment_status,
        invoice_amount: invoice_response.amount,
        invoice_type: invoice_response.type,
        student_uid: invoice_response.student_uid,
        timesheet_uid: invoice_response.timesheet_uid,
        company_collective_invoice_number:
          invoice_response.company_collective_invoice_number
            ? invoice_response.company_collective_invoice_number
            : '',
      };
    });

  const refetchInvoices = async () => {
    await refetchInvoicesDate();
  };

  const handleChangePage = async (page) => {
    setFilters({ ...filters, page: 1 + page });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.order_by) {
      setFilters({
        ...filters,
        ...sortModel,
      });
    }
  };

  const handleChangeRowsPerPage = async (per_page: number) => {
    setFilters({ ...filters, page: 1, per_page: per_page });
  };

  const isHideOtherColumn = () => {
    return filters?.how_did_you_find_us?.includes('Other');
  };

  useEffect(() => {
    refetchInvoices();
    setHidenColumn({ other: isHideOtherColumn() ?? false });
  }, [filters]);

  const invoiceColumns = useMemo(() => {
    return [
      {
        accessorKey: 'job_name',
        header: 'Job name',
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminJobsRoute}/${row.original.job_uid}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice number',
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          return row.original.invoice_number[0] === 'B' ? (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`/hours/${row.original.student_uid}/${row.original.job_uid}?timesheet_uid=${row.original.timesheet_uid}`}
              title={renderedCellValue}
            >
              ABCE{renderedCellValue.substring(1)} /{' '}
              {row.original.company_collective_invoice_number}
            </Link>
          ) : (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminInvoicesRoute}/${row.original.id}`}
              title={renderedCellValue}
            >
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Creation date',
        enableSorting: true,
      },
      {
        accessorKey: 'paid_status',
        header: 'Paid status',
        enableSorting: false,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'sent_status',
        header: 'Sent status',
        enableSorting: false,
        Cell: ({ renderedCellValue }) => {
          return (
            <>
              {renderedCellValue ? (
                <DoneIcon sx={{ color: '#14DB87' }} />
              ) : (
                <CloseIcon sx={{ color: '#EA4335' }} />
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Grid
      container
      className="admin-grid-dashboard admin-students-container"
      sx={{
        width: '100%',
        gap: 5.3,
      }}
    >
      <p className="section-title">Invoices</p>

      <Grid
        className="table-grid-item"
        item
        lg={12}
        md={12}
        xl={12}
        sm={12}
        xs={12}
      >
        <div className="grid-title-row">
          <p className="section-sub-title">All invoices</p>
          <div>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                Single invoices (posting fee, promo pack, OTJ)
              </Typography>
              <TypeSwitch
                inputProps={{ 'aria-label': 'type switch' }}
                checked={filters.show === 'ongoing'}
                onChange={handleChangeSwitch}
              />
              <Typography>Ongoing job invoices</Typography>
            </Stack>
          </div>
          <div className="admin-table-inputs students-table-inputs">
            <TextField
              placeholder="Type to search"
              sx={{ width: '148px' }}
              variant="standard"
              className="admin-table-search-input search-students-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearch(event.target.value)}
            />
            <FiltersPanel
              values={invoices}
              filters={filters}
              page="admin-invoices"
              changeFilters={(customFilters) => {
                const { page, per_page, query } = filters;

                setFilters({ page, per_page, query, ...customFilters });
              }}
            />
          </div>
        </div>

        <ResizableTable
          pageSize={filters.per_page}
          columns={invoiceColumns}
          rows={myInvoicesGridData()}
          page={filters.page}
          onSortChange={handleSortModelChange}
          onPageChange={handleChangePage}
          rowCount={pagination?.total || 0}
          isLoading={isLoading}
          hideColumn={hidenColumn}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default AdminInvoices;
