import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { switchLanguageReducer } from './reducers/switch-language.reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { StudentFormReducer } from './reducers/student-form.reducer';
import { PostJobReducer } from './reducers/post-job.reducer';

const persistConfig = {
  key: 'root',
  storage,
};

export const rootReducer = combineReducers({
  language: switchLanguageReducer,
  student: StudentFormReducer,
  postjob: PostJobReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
