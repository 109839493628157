import React from 'react';
import { Button } from '@mui/material';

const HotJob = ({
  onSubmit,
  isHot,
  isLoading,
}: {
  onSubmit: () => void;
  isHot: boolean;
  isLoading: boolean;
}) => {
  return (
    <div className="hot-job-wrapper">
      <Button
        onClick={onSubmit}
        id="hot-job-button"
        data-testid={`${isHot ? 'unhot' : 'hot'}-admin`}
        disabled={isLoading}
      >
        {isHot ? 'Remove hot label' : 'Mark job as hot'}
      </Button>
    </div>
  );
};

export default HotJob;
