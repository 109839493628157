import React from 'react';
import './HighlightedProfileCard.scss';
import { ButtonBase } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { HighlightedProfiles } from './HighlightedProfiles';
import { generatePath, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Routes } from '../../utils/consts';
import { numberWithDec } from '../../utils/numberWithDec';
import StudentAvatar from '../student-avatar';
import {
  useAddStudentToTalentPool,
  useFetchTalentPool,
  useRemoveStudentFromTalentPool,
} from '../../data/talent-pool';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface Props extends HighlightedProfiles {
  dashboard?: boolean;
}

const HighlightedProfileCard = (props: Props) => {
  const navigate = useNavigate();
  const { dashboard, student, user, education } = props;

  const { data: talentPool, refetch: refreshTalentPool } =
    useFetchTalentPool(true);
  const { mutateAsync: addStudentToTalentPool } = useAddStudentToTalentPool(
    student.student_uid,
    true,
  );
  const { mutateAsync: removeStudentFromTalentPool } =
    useRemoveStudentFromTalentPool(student.student_uid);

  const existInTalentPool = Boolean(
    talentPool?.find(
      (talent) => talent.student.student_uid === student.student_uid,
    ),
  );

  const studyInfo = `${education[0]?.degree || 'N/A'} ${
    education[0]?.study || 'N/A'
  }`;

  const handleNavigation = () => {
    navigate(
      generatePath(Routes.StudentProfileRoute, {
        profileId: student.student_uid,
      }),
      {
        replace: true,
      },
    );
  };

  const handleToggleTalentPoolClick = async (event) => {
    event?.preventDefault();
    if (existInTalentPool) {
      await removeStudentFromTalentPool();
    } else {
      await addStudentToTalentPool();
    }
    await refreshTalentPool();
  };

  return (
    <>
      <div className="highlighted-card-container">
        <div className="card-header">
          <div className="flex-top">
            <StudentAvatar
              avatar={user?.avatar}
              className="talent-avatar"
              alt="user avatar"
            />
            <div>
              <ButtonBase>
                {existInTalentPool ? (
                  <FavoriteIcon
                    onClick={handleToggleTalentPoolClick}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    className="add-talent-button"
                  />
                ) : (
                  <FavoriteBorderIcon
                    onClick={handleToggleTalentPoolClick}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    className="remove-talent-button"
                  />
                )}
              </ButtonBase>
            </div>
          </div>
          <div className="student-name-rate">
            <span className="name">{student.alias}</span>
            <div className="rate">
              <StarIcon className="favourite-rate-star" />
              <span className="rate-number">
                {numberWithDec(student.rating)}
              </span>
            </div>
          </div>
        </div>
        <div className="card-content">
          <p className="student-info">{studyInfo}</p>
        </div>
        {dashboard && (
          <div className="talent-footer">
            <div></div>
            <ButtonBase>
              <ArrowForwardIcon
                onClick={handleNavigation}
                sx={{
                  color: '#DEB900',
                  cursor: 'pointer',
                }}
                className="next-button"
              />
            </ButtonBase>
          </div>
        )}
      </div>
    </>
  );
};

export default HighlightedProfileCard;
