import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ChatButton.scss';
import { openChatUrl } from '../../utils/openChat';
import { countUnreadConversations } from '../../utils/chat';
import { Firestore } from 'firebase/firestore';

const ChatFloatButton = ({
  user_uid,
  db,
  isAdmin,
}: {
  user_uid: string;
  db: Firestore;
  isAdmin: boolean;
}) => {
  const [openChat, setOpenChat] = useState(undefined);
  const { pathname } = useLocation();
  const [unreadMessages, setUnreadMessages] = useState(null);

  useEffect(() => {
    setOpenChat('admin');
    const countUnreadConversationsHandler = async () => {
      let userUid: string;
      if (isAdmin) {
        userUid = 'admin';
      } else {
        userUid = user_uid;
      }
      countUnreadConversations(userUid, db).then((res) => {
        setUnreadMessages(res);
      });
    };
    countUnreadConversationsHandler();
  }, []);

  useEffect(() => {
    if (pathname.includes('chat')) {
      setOpenChat(false);
    } else {
      setOpenChat('admin');
    }
  }, [pathname]);

  return (
    openChat && (
      <div
        className="chat-float-button"
        onClick={() => openChatUrl(openChat, '_blank', isAdmin)}
        data-testid="chat-float-button"
      >
        Open chat
        {!!unreadMessages && (
          <span className="unread-messages">{unreadMessages}</span>
        )}
      </div>
    )
  );
};

export default ChatFloatButton;
