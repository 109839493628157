import React from 'react';
import './OneTime.scss';
import { ProposalVariables } from '../../data/proposals';
import { formatDate } from '../../utils/dates';

const OneTime = ({ variables }: { variables: ProposalVariables }) => {
  const {
    JOBDESCRIPTION,
    JOBRATE,
    STARTDATE,
    STUDENTCOMPANYNAME,
    STUDENTCITY,
    STUDENTKVK,
    STUDENTFIRST_LASTNAME,
    COMPANYNAME,
    COMPANYKVK,
    COMPANYCITY,
    COMPANYFIRST_LASTNAME,
    COMPANYIP,
    COMPANY_DATE,
    COMPANY_TIME,
    STUDENTIP,
    STUDENT_DATE,
    STUDENT_TIME,
  } = variables || {};

  return (
    <div className="one-time-content-wrapper">
      <div className="c9 doc-content">
        <p className="c13">
          <span className="c5">
            OVEREENKOMST VAN OPDRACHT VOOR BEPAALDE TIJD TUSSEN OPDRACHTGEVER EN
            OPDRACHTNEMER
          </span>
        </p>
        <p className="c11">
          <span className="c5">DE PARTIJEN:</span>
        </p>
        <ol className="c3 lst-kix_wh33pv27lh7k-0 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c1">
              {COMPANYNAME}, statutair gevestigd te {COMPANYCITY} en
              ingeschreven bij de Kamer van Koophandel onder nummer {COMPANYKVK}{' '}
              , hierbij rechtsgeldig vertegenwoordigd door{' '}
              {COMPANYFIRST_LASTNAME} (hierna:{' '}
            </span>
            <span className="c12">Opdrachtgever</span>
            <span className="c0">), en</span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_wh33pv27lh7k-0" start={2}>
          <li className="c4 li-bullet-0">
            <span className="c1">
              {STUDENTFIRST_LASTNAME}, te dezen handelend onder de naam{' '}
              {STUDENTCOMPANYNAME}, gevestigd en kantoorhoudende te{' '}
              {STUDENTCITY} en ingeschreven bij de Kamer van Koophandel onder
              nummer {STUDENTKVK}, hierbij rechtsgeldig vertegenwoordigd door{' '}
              {STUDENTFIRST_LASTNAME} (hierna:{' '}
            </span>
            <span className="c12">Opdrachtnemer</span>
            <span className="c0">)</span>
          </li>
        </ol>
        <p className="c11">
          <span className="c1">Hierna gezamenlijk aangeduid als </span>
          <span className="c12">Partijen</span>
          <span className="c1">&nbsp;en individueel als </span>
          <span className="c5">Partij.</span>
        </p>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <p className="c11">
          <span className="c5">IN AANMERKING NEMENDE DAT:</span>
        </p>
        <ol className="c3 lst-kix_r7pvhwgbo37n-0 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever een ondernemer is die in het kader van de
              uitoefening van zijn bedrijf behoefte heeft aan een zelfstandig
              professional;
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever ter zake van zijn onderneming behoefte heeft aan
              werkzaamheden als door Opdrachtnemer uit te voeren;
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever na bemiddeling van een Platform aan Opdrachtnemer de
              opdracht wenst te verstrekken om voor hem werkzaamheden te
              verrichten zoals overeengekomen in deze opdrachtovereenkomst;
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer vrij is in het al dan niet sluiten van
              overeenkomsten van opdracht met andere opdrachtgevers;
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Partijen uitsluitend met elkaar wensen te contracteren op basis
              van een overeenkomst van opdracht in de zin van artikel 7:400 BW;
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Partijen ervoor kiezen om in voorkomende gevallen de fictieve
              dienstbetrekking van thuiswerkers of gelijkgestelden zoals bedoeld
              in de artikelen 2b en 2c Uitvoeringsbesluit Loonbelasting 1965 en
              de artikelen 1 en 5 van het Besluit aanwijzing gevallen waarin de
              arbeidsverhouding als dienstbetrekking wordt beschouwd (Besluit
              van 24 december 1986, Stb. 1986, 655), buiten toepassing te laten
              en daartoe deze overeenkomst opstellen en ondertekenen voordat
              uitbetaling plaatsvindt;
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Deze opdrachtovereenkomst gebaseerd is op de door de
              Belastingdienst op 1 maart 2022 onder nummer 9092076897
              beoordeelde modelovereenkomst.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <p className="c11">
          <span className="c5">KOMEN HET VOLGENDE OVEREEN:</span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0 start" start={1}>
          <li className="c7 li-bullet-0">
            <span className="c5">Definities</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              In deze Overeenkomst worden de volgende definities gebruikt:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-2 start" start={1}>
          <li className="c10 li-bullet-0">
            <span className="c12">Platform: </span>
            <span className="c0">
              Minite Works B.V., statutair gevestigd te Rotterdam en
              ingeschreven bij de Kamer van Koophandel onder nummer 83447539;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c12">Gebruikersvoorwaarden: </span>
            <span className="c0">
              de voorwaarden voor gebruikmaking van het Platform en de daaraan
              gekoppelde diensten van Minite die beide Partijen, elk
              afzonderlijk, hebben geaccepteerd;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c12">Derde:</span>
            <span className="c0">
              &nbsp;eenieder die geen partij is bij deze Overeenkomst;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c12">Opdracht: </span>
            <span className="c0">
              de opdracht die Opdrachtnemer ten behoeve van Opdrachtgever zal
              uitvoeren, zoals omschreven in Bijlage 1 bij deze Overeenkomst;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c12">Overeenkomst:</span>
            <span className="c0">&nbsp;deze overeenkomst van opdracht;</span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c12">Project:</span>
            <span className="c0">
              &nbsp;het project ten behoeve waarvan de Opdracht wordt
              uitgevoerd, zoals omschreven in Bijlage 1.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1" start={2}>
          <li className="c4 li-bullet-0">
            <span className="c0"></span>
          </li>
        </ol>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={2}>
          <li className="c7 li-bullet-0" id="h.gjdgxs">
            <span className="c5">Opdrachtverlening</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Opdrachtgever wenst gebruik te maken van de kennis en ervaring van
              Opdrachtnemer.
            </span>
            <span className="c0">
              Daartoe verleent Opdrachtgever aan Opdrachtnemer, na bemiddeling
              door Platform, opdracht om in het kader van de uitoefening van
              zijn beroep en/of bedrijf de werkzaamheden te verrichten die staan
              omschreven in Bijlage 1.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Opdrachtgever verklaart zich ermee akkoord dat Opdrachtnemer ook
              ten behoeve van andere opdrachtgevers werkzaamheden verricht
              waarbij Opdrachtnemer hetgeen in artikel 5 is bepaald
              uitdrukkelijk in acht neemt.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={3}>
          <li className="c7 li-bullet-0">
            <span className="c5">Uitvoering van de Opdracht</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer accepteert de Opdracht en aanvaardt daarmee de volle
              verantwoordelijkheid voor het op juiste wijze uitvoeren van de
              overeengekomen werkzaamheden.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer deelt zijn werkzaamheden zelfstandig in. Wel vindt,
              voor zover dat voor de uitvoering van de Opdracht nodig is,
              afstemming met Opdrachtgever plaats in geval van samenwerking met
              anderen, zodat deze optimaal zal verlopen. Indien noodzakelijk
              voor de werkzaamheden richt Opdrachtnemer zich naar de
              arbeidstijden bij Opdrachtgever.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever verstrekt Opdrachtnemer alle bevoegdheid en
              informatie benodigd voor een goede uitvoering van de Opdracht.
              Andersom informeert Opdrachtnemer Opdrachtgever over de voortgang
              van zijn werkzaamheden ter uitvoering van de Opdracht.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Opdrachtnemer is bij het uitvoeren van de overeengekomen
              werkzaamheden geheel zelfstandig. Hij/zij verricht de
              overeengekomen werkzaamheden naar eigen inzicht en zonder toezicht
              of leiding van Opdrachtgever. Opdrachtgever kan wel aanwijzingen
              en instructies geven omtrent het beoogde doel van de Opdracht.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={4}>
          <li className="c7 li-bullet-0">
            <span className="c5">Verplichtingen Opdrachtnemer</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer zal bij de uitvoering van de werkzaamheden in het
              kader van de Opdracht de zorg van een goed opdrachtnemer in acht
              nemen, en garandeert dat de te verrichten werkzaamheden op
              vakbekwame wijze zullen worden uitgevoerd. Tevens zal
              Opdrachtnemer zich gedurende de Opdracht houden aan alle van
              toepassing zijnde wet- en regelgeving. Opdrachtnemer garandeert
              dat hij gedurende de looptijd van de Opdracht gekwalificeerd,
              bevoegd en bekwaam is om de Opdracht uit te voeren.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Opdrachtnemer zorgt ervoor dat protocollen en huis- en
              gedragsregels die voor iedereen gelden op de locatie van
              Opdrachtgever en/of de locatie van derde(n) waar de Opdracht wordt
              uitgevoerd, en welke betrekking hebben op algemene of bijzondere
              veiligheidsvoorschriften, en/of voorschriften ter nakoming van
              toepasselijke wet- en regelgeving, in redelijkheid worden
              opgevolgd. Opdrachtgever zal deze protocollen en huis- en
              gedragsregels al dan niet via derden v&oacute;&oacute;r aanvang
              van de werkzaamheden aan Opdrachtnemer verstrekken. Opdrachtnemer
              zal in redelijkheid meewerken aan voor een ieder geldende
              veiligheidsonderzoeken, die door Opdrachtgever worden
              voorgeschreven teneinde aan wet- en regelgeving te kunnen voldoen.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={5}>
          <li className="c7 li-bullet-0">
            <span className="c5">Afwezigheid</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Indien de Opdrachtnemer op enig moment voorziet dat hij de
              verplichtingen in verband met de Opdracht niet, niet tijdig of
              niet naar behoren kan nakomen (bijvoorbeeld vanwege ziekte), dan
              dient Opdrachtnemer Opdrachtgever hiervan onmiddellijk op de
              hoogte te stellen.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={6}>
          <li className="c7 li-bullet-0">
            <span className="c5">Hulpmiddelen</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer beschikt over alle voor de uitvoering van de
              werkzaamheden benodigde hulpmiddelen. Als er hulpmiddelen
              beschikbaar worden gesteld door Opdrachtgever, zal Opdrachtnemer
              daarvoor een vooraf in overleg vast te stellen vergoeding zijn
              verschuldigd aan Opdrachtgever. De voorwaarden voor het gebruik
              van de hulpmiddelen en de vergoeding voor het gebruik daarvan
              worden schriftelijk vastgelegd. Ook worden afspraken gemaakt over
              de betaling van deze vergoeding en de daarvoor geldende
              betalingstermijn.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Indien er geen afspraken worden gemaakt over het gebruik van
              hulpmiddelen, zoals bedoeld in lid 1 van dit artikel, zijn er geen
              vergoedingen verbonden aan het gebruik van de hulpmiddelen en
              dient Opdrachtnemer zich als een goed huisvader te gedragen in de
              omgang met deze hulpmiddelen.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={7}>
          <li className="c7 li-bullet-0">
            <span className="c5">Vergoeding</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever is aan Opdrachtnemer een vergoeding verschuldigd
              voor de uitvoering van de Opdracht. Opdrachtgever blijft onder
              alle omstandigheden verantwoordelijk voor nakoming van deze
              betalingsverplichting aan Opdrachtnemer.{' '}
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever betaalt Opdrachtnemer &euro;{JOBRATE} voor de
              complete uitvoering van de Opdracht. In de vergoeding zijn
              reiskosten, verblijfkosten, eventuele materiaalkosten, overige
              onkosten en reistijd inbegrepen. Deze kosten en de met deze kosten
              samenhangende tijd zoals reistijd worden derhalve niet separaat
              vergoed, tenzij schriftelijk anders tussen Partijen
              overeengekomen.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtgever is verplicht om de factuur van Opdrachtnemer binnen
              veertien (14) dagen te voldoen. Deze factuur zal Platform, namens
              Opdrachtnemer, na aanvang van het project aan Opdrachtgever
              versturen.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Platform zal Opdrachtnemer betalen na oplevering van het project,
              mits Platform de betaling van Opdrachtgever dienaangaande heeft
              ontvangen. Ten aanzien van de betaling vervult Platform
              uitsluitend een kassiersfunctie. Betaling door Platform aan
              Opdrachtnemer bevrijdt Opdrachtgever van zijn
              betalingsverplichting jegens Opdrachtnemer op basis van deze
              Overeenkomst. Platform is verder op geen enkele wijze
              verantwoordelijk voor de betaling door Opdrachtgever aan
              Opdrachtnemer. Het debiteurenrisico ligt altijd bij Opdrachtnemer.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Tenzij expliciet anders vermeld in deze Overeenkomst zijn alle
              genoemde bedragen in euro’s en exclusief BTW.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={8}>
          <li className="c7 li-bullet-0">
            <span className="c5">Intellectueel eigendom</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Alle intellectuele eigendomsrechten die ontstaan in het kader van
              de werkzaamheden van Opdrachtnemer op grond van deze Overeenkomst,
              berusten uitsluitend bij Opdrachtgever en komen toe aan
              Opdrachtgever. Onder intellectuele eigendomsrechten worden in
              ieder geval verstaan auteursrechten, merkenrechten, rechten op
              uitvindingen (ongeacht of deze al dan niet geoctrooieerd zijn), op
              beeldmateriaal, publicaties en presentaties (ongeacht of deze op
              schrift zijn gesteld op welke drager dan ook) en andere werken
              vervaardigd of bewerkt door de Opdrachtnemer in het kader van de
              uitoefening van zijn/haar opdracht bij Opdrachtgever, ongeacht of
              de desbetreffende productie of bewerking tijdens werktijd heeft
              plaatsgevonden.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Voor zover het niet mogelijk is om de intellectuele
              eigendomsrechten, zoals bedoeld in lid 1 van dit artikel, vooraf
              over te dragen, machtigt Opdrachtnemer Opdrachtgever hierbij om al
              datgene te doen dat redelijkerwijs noodzakelijk is om de
              intellectuele eigendomsrechten over te dragen aan Opdrachtgever.{' '}
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer garandeert dat de over te dragen rechten onbezwaard
              zijn en dat er geen rechten van derden op rusten. Voor zover deze
              rechten niet kunnen worden overgedragen, verleent de Opdrachtnemer
              hierbij aan de Opdrachtgever het gratis, wereldwijde,
              eeuwigdurende, overdraagbare onbeperkte en exclusieve recht om
              deze rechten te gebruiken en te exploiteren.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Opdrachtnemer doet hierbij, voor zover wettelijk mogelijk, afstand
              van zijn/haar persoonlijkheidsrechten op grond van de Auteurswet.
              Voor zover het niet mogelijk is om afstand te doen van zijn/haar
              persoonlijkheidsrechten garandeert Opdrachtnemer hierbij dat
              hij/zij geen beroep zal doen op deze persoonlijkheidsrechten.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={9}>
          <li className="c7 li-bullet-0">
            <span className="c5">Geheimhouding</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Opdrachtnemer verplicht zich tot geheimhouding met betrekking tot
              alle informatie die omtrent Opdrachtgever en de Opdracht, welke
              haar uit hoofde van het uitvoeren van deze Overeenkomst ter kennis
              komt. Opdrachtnemer verbindt zich, zowel gedurende de looptijd van
              deze Overeenkomst als na be&euml;indiging daarvan, strikte
              geheimhouding te betrachten, omtrent alle aangelegenheden van
              Opdrachtgever waarvan het vertrouwelijke karakter aan
              Opdrachtnemer bekend is of redelijkerwijs bekend had kunnen zijn.
              Geheimhouding is niet noodzakelijk voor zover dit nodig is voor de
              normale uitvoering van de Opdracht.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Alle correspondentie, documenten en andere zaken met inbegrip van
              kopie&euml;n ter zake van Opdrachtgever, welke Opdrachtnemer onder
              zich heeft of krijgt, zijn en blijven eigendom van Opdrachtgever.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={10}>
          <li className="c7 li-bullet-0">
            <span className="c5">Aansprakelijkheid</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Opdrachtnemer kan niet aansprakelijk worden gesteld voor
              materi&euml;le schade. Opdrachtnemer kan uitsluitend aansprakelijk
              worden gesteld in het geval van opzet en grove schuld. De schade
              bedraagt maximaal het bedrag van deze Overeenkomst.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Platform is onder geen enkele omstandigheid aansprakelijk voor de
              uitvoering van deze Overeenkomst of omstandigheden die hiermee
              samenhangen.{' '}
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={11}>
          <li className="c7 li-bullet-0">
            <span className="c5">Duur van de Overeenkomst</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Deze Overeenkomst vangt aan op{' '}
              {formatDate(STARTDATE, 'DD/MM/YYYY')} en wordt aangegaan voor de
              duur van het Project. De Overeenkomst zal zonder dat opzegging is
              vereist daarom in ieder geval van rechtswege eindigen met het
              eindigen van het Project.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Indien het Project 3 maanden na het begin van de Overeenkomst nog
              niet is afgerond, zullen Partijen in overleg treden om het Project
              zo spoedig mogelijk af te ronden. Indien het niet voltooien van
              het Project niet te wijten is aan Opdrachtnemer, is Opdrachtnemer
              bevoegd om de Overeenkomst op te zeggen, waarna Opdrachtgever
              gehouden zal zijn om de volledige vergoeding, zoals bedoeld in
              artikel 7, te betalen.{' '}
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={12}>
          <li className="c7 li-bullet-0">
            <span className="c5">Be&euml;indiging van de Overeenkomst</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Iedere Partij heeft tevens het recht om de Overeenkomst, met
              onmiddellijke ingang te ontbinden zonder dat daartoe een
              ingebrekestelling is vereist indien:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-2 start" start={1}>
          <li className="c10 li-bullet-0">
            <span className="c0">
              de andere Partij surseance van betaling aanvraagt of in staat van
              faillissement wordt verklaard of anderszins in een blijvende
              toestand van betalingsonmacht verkeert dan wel wordt ontbonden;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              sprake is van onvoorziene omstandigheden (daaronder begrepen
              wijziging van wet- en regelgeving) die niet voor rekening van de
              opzeggende Partij komen en die zo ingrijpend zijn, dat van de
              opzeggende Partij naar maatstaven van redelijkheid en billijkheid
              instandhouding van de Overeenkomst niet verlangd kan worden;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              sprake is van een onrechtmatige daad als bedoeld in artikel 6:162
              BW, van de andere Partij, en deze onrechtmatige daad te wijten is
              aan schuld van die Partij of aan een oorzaak welke krachtens de
              wet of de in &nbsp;het verkeer geldende opvattingen voor zijn
              rekening komt, als gevolg waarvan instandhouding van de
              Overeenkomst niet langer van opzeggende Partij kan worden
              verwacht;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              de andere Partij niet voldoet aan haar verplichtingen uit hoofde
              van de Overeenkomst en deze Partij na schriftelijke aanmaning niet
              binnen veertien (14) dagen alsnog aan deze verplichting voldoet,
              tenzij nakoming blijvend onmogelijk is dan per direct;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              de andere Partij haar activiteiten staakt of overdraagt, wordt
              ontbonden of feitelijk wordt geliquideerd;
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              de andere Partij de vrije beschikking over haar vermogen of een
              deel daarvan verliest, of
            </span>
          </li>
          <li className="c10 li-bullet-0">
            <span className="c0">
              beslag wordt gelegd op een of meer vermogensbestanddelen van de
              andere Partij.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1" start={2}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Indien sprake is van een wijziging van omstandigheden ten opzichte
              van de omstandigheden ten tijde van het aangaan van de
              Overeenkomst, welke tot gevolg zouden hebben dat &ndash; ware deze
              omstandigheden reeds ten tijde van het aangaan van de Overeenkomst
              bekend en/of aan de orde geweest &ndash; Opdrachtgever de
              Overeenkomst niet of tegen andere voorwaarden zou zijn aangegaan,
              zullen Partijen in overleg treden over wijziging van de
              Overeenkomst. Indien Partijen ter zake niet tot een vergelijk
              komen, heeft Opdrachtgever het recht, naar zijn keuze, de
              Overeenkomst zonder toepassing van een opzegtermijn (tussentijds)
              op te zeggen dan wel de Overeenkomst met onmiddellijke ingang te
              ontbinden.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              De Partij die de Overeenkomst op grond van de in lid 1 en 2
              genoemde gronden eindigt, is jegens de andere Partij nimmer
              gehouden tot enige schadevergoeding betreffende schade die wordt
              veroorzaakt door het op grond van deze artikelleden
              be&euml;indigen van de Overeenkomst.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c1">
              De Overeenkomst eindigt met onmiddellijke ingang bij overlijden
              van Opdrachtnemer.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={13}>
          <li className="c7 li-bullet-0">
            <span className="c5">Overige bepalingen</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c0">
              De eventuele algemene voorwaarden van Partijen zijn niet van
              toepassing op de Overeenkomst.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Wijzigingen in deze Overeenkomst zijn slechts bindend indien deze
              schriftelijk zijn overeengekomen tussen Partijen.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Indien enige bepaling van de Overeenkomst nietig is dan wel
              vernietigd wordt, zullen de overige bepalingen van kracht blijven
              en zullen Partijen in overleg treden teneinde nieuwe bepalingen
              ter vervanging van de nietige c.q. vernietigde bepalingen overeen
              te komen, waarbij zo veel mogelijk het doel en de strekking van de
              nietige c.q. vernietigde bepalingen in acht wordt genomen.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c0">
              Partijen gaan er mee akkoord dat de Overeenkomst elektronisch
              ondertekend kan worden.{' '}
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Deze overeenkomst is gebaseerd op de door de Belastingdienst op 1
              maart 2022 onder nummer 9092076897 beoordeelde overeenkomst.
            </span>
          </li>
        </ol>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <ol className="c3 lst-kix_lo7wzc7ageg3-0" start={14}>
          <li className="c7 li-bullet-0">
            <span className="c5">Toepasselijk recht en bevoegde rechter</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_lo7wzc7ageg3-1 start" start={1}>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Op deze Overeenkomst is uitsluitend Nederlands recht van
              toepassing.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Indien er tussen Partijen een geschil ontstaat, zullen zij in
              onderling overleg treden om dit geschil zo veel mogelijk op te
              lossen. Indien Partijen hier niet samen uit komen, zullen zij de
              hulp van Platform inroepen om in dit kader tussen Partijen te
              bemiddelen, waarbij de belangen van alle Partijen zo goed mogelijk
              in acht worden genomen.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c1">
              Indien onderling overleg tussen Partijen, inclusief overleg onder
              begeleiding van Platform, niet tot een oplossing leidt waar alle
              Partijen zich in kunnen vinden, zal het geschil worden voorgelegd
              aan de bevoegde rechter.
            </span>
          </li>
        </ol>
        <hr />
        <p className="c6">
          <span className="c5"></span>
        </p>
        <p className="c17">
          <span className="c1 c8">Bijlage 1 &ndash; omschrijving Opdracht</span>
        </p>
        <p className="c6">
          <span className="c0"></span>
        </p>
        <p className="c11">
          <span className="c0">{JOBDESCRIPTION}</span>
        </p>
        <p className="c6">
          <span className="c5"></span>
        </p>
        <p className="c16">
          <span className="c14"></span>
        </p>

        <div className="footer-wrapper">
          {COMPANY_DATE && (
            <p className="footer-text">
              Signed by {COMPANYFIRST_LASTNAME} ({COMPANYIP}) on behalf of{' '}
              {COMPANYNAME} on {COMPANY_DATE} at {COMPANY_TIME}
            </p>
          )}
          {STUDENT_DATE && (
            <p className="footer-text">
              Signed by {STUDENTFIRST_LASTNAME} ({STUDENTIP}) on {STUDENT_DATE}{' '}
              at {STUDENT_TIME}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OneTime;
