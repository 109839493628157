import React from 'react';
import { ResetForm } from '../../components/reset-password/ResetForm';
import useDocumentTitle from '../../utils/useDocumentTitle';

const Reset = () => {
  useDocumentTitle('Reset Password');

  return <ResetForm />;
};

export default Reset;
