import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  ADMIN_ONETIME_REVENUE,
  ADMIN_ONGOING_REVENUE,
  ADMIN_REVENUE_STATISTICS,
} from './query-keys';
import { JobType } from './jobs';

const searchAdminOnetimeRevenue = async (body?: RevenueSearchRequest) => {
  const { data } = await request<ServerResponse<OnetimeRevenueSearchResponse>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/revenue/onetime/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminOnetimeRevenue = (body: RevenueSearchRequest) => {
  return useQuery(
    [ADMIN_ONETIME_REVENUE, body],
    () => searchAdminOnetimeRevenue(body),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const searchAdminOngoingRevenue = async (body?: RevenueSearchRequest) => {
  const { data } = await request<ServerResponse<OngoingRevenueSearchResponse>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL +
      `/api/v1/admin/revenue/ongoing/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminOngoingRevenue = (body: RevenueSearchRequest) => {
  return useQuery(
    [ADMIN_ONGOING_REVENUE, body],
    () => searchAdminOngoingRevenue(body),
    {
      onError: (e: AxiosError) => e,
      select: (data) => data,
    },
  );
};

const revenueStatistic = async () => {
  const { data } = await request<ServerResponse<Statistics>>({
    url:
      process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/statistics/revenue`,
    method: 'get',
  });

  return data.data;
};

export const useRevenueStatistic = () => {
  return useQuery([ADMIN_REVENUE_STATISTICS], () => revenueStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

export interface Statistics {
  total_hours_logged_this_month: number;
  total_hours_logged_last_month: number;
  total_revenue_this_month_to_date: number;
  approved_timesheets_this_month: number;
}

export interface RevenueSearchRequest {
  month_min?: number;
  month_max?: number;
  query?: string;
  page?: number;
  per_page?: number;
}

export interface OnetimeRevenueSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  revenue: OnetimeRevenueResponse[];
  total_gmv: number;
  total_revenue: number;
}

export interface OnetimeRevenueResponse {
  month: string;
  job_type: JobType;
  rate: number;
  revenue: number;
  gmv: number;
  student_name: string;
  job_title: string;
  company_name: string;
  student_uid: string;
  job_uid: string;
  company_uid: string;
}

export interface OngoingRevenueSearchResponse {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  revenue: OngoingRevenueResponse[];
  total_gmv: number;
  total_revenue: number;
  total_hours: number;
}

export interface OngoingRevenueResponse {
  month: string;
  hours: number;
  rate: number;
  revenue: number;
  gmv: number;
  student_name: string;
  job_title: string;
  company_name: string;
  student_uid: string;
  job_uid: string;
  company_uid: string;
}
