import React from 'react';
import { JobRecommended } from '../../../data/jobs';
import '../Dashboard.scss';

import DashboardJobCard from '../../../components/dashboard-job-card';
import { Routes } from '../../../utils/consts';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../stores/auth';

interface Props {
  jobs: JobRecommended[];
  loading: boolean;
}

const JobsRecommended = ({ jobs, loading }: Props) => {
  const userData = useUserData((state) => state.user);
  const { t } = useTranslation();
  if (loading) {
    return <>Loading...</>;
  }

  if (jobs.length === 0) {
    return <></>;
  }

  return (
    <div className="dashboard-recommendations-container">
      <p className="recommendations-title">
        {t('dashboard.these_jobs')}
        {userData?.verified && (
          <Link to={Routes.BrowseJobsRoute} style={{ float: 'right' }}>
            <Button
              variant="outlined"
              data-testid="view-all-jobs"
              sx={{
                color: '#2E7D32',
                borderColor: '#2E7D32',
                borderRadius: 15,
              }}
              endIcon={<ArrowForwardIcon />}
            >
              VIEW ALL
            </Button>
          </Link>
        )}
      </p>
      <div className="dashboard-recommendations-cards">
        {jobs.map((job) => (
          <DashboardJobCard
            key={job.job_uid}
            verified={userData.verified}
            routeLink={`${Routes.BrowseJobsRoute}/${job.job_uid}`}
            job={job}
          />
        ))}
      </div>
    </div>
  );
};

export default JobsRecommended;
