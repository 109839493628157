import { request, ServerResponse } from '../core/request';
import { useQuery } from '@tanstack/react-query';
import {
  JobLocation,
  JobStatus,
  JobType,
  MyJobsListItem,
  StudentType,
} from './jobs';
import { ADMIN_JOBS, JOB_STATISTICS } from './query-keys';
import { AxiosError } from 'axios';

const searchAdminJobs = async (body?: JobsSearchRequest) => {
  const { data } = await request<ServerResponse<Search>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/jobs/search`,
    method: 'post',
    data: body || {},
  });

  return data.data;
};

export const useSearchAdminJobs = (body: JobsSearchRequest) => {
  return useQuery([ADMIN_JOBS, body], () => searchAdminJobs(body), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

const jobStatistic = async () => {
  const { data } = await request<ServerResponse<unknown>>({
    url: process.env.REACT_APP_ADMIN_API_URL + `/api/v1/admin/statistics/jobs`,
    method: 'get',
  });

  return data.data;
};

export const useJobStatistic = () => {
  return useQuery([JOB_STATISTICS], () => jobStatistic(), {
    onError: (e: AxiosError) => e,
    select: (data) => data,
  });
};

interface Search {
  jobs: MyJobsListItem[];
  pagination: { total: number; page: number; per_page: number };
}

interface JobsSearchRequest {
  job_type?: JobType[];
  job_boost_promo?: boolean[];
  job_status?: JobStatus[];
  job_completed_reason?: string;
  job_location?: JobLocation[];
  student_type?: StudentType[];
  category_uid?: string[];

  language_uid?: string[];
  skill_uid?: string[];

  start_date?: string;
  end_date?: string;

  budget_min?: number;
  budget_max?: number;

  hours_min?: number;
  hours_max?: number;

  query?: string;

  page?: number;
  per_page?: number;
}
