import React from 'react';
import useDocumentTitle from '../../../utils/useDocumentTitle';

const Notifications = () => {
  useDocumentTitle('Notifications');

  return (
    <>
      <title>Notifications | Minite</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <p>Notifications</p>
    </>
  );
};

export default Notifications;
