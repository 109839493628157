import React, { useEffect, useRef, useState } from 'react';
import CompanyAvatar from '../../../components/company-avatar';
import StudentAvatar from '../../../components/student-avatar';
import { Routes } from '../../../utils/consts';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from '../../../utils/useDebounce';
import { searchAdminStudents } from '../../../data/admin-students';
import { searchAdminCompanies } from '../../../data/admin-companies';

const ChatByType = ({ type }: { type: string; user: any }) => {
  const talkjsContainer = useRef(null);
  const [userChat, setUserChat] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const openChat = (userType, userInfo) => {
    const { avatar, first_name, user_uid } = userInfo;

    setUserChat({
      avatar: avatar,
      name: first_name,
      role: userType,
      user_id: user_uid,
    });
  };

  useEffect(() => {
    if (debouncedSearch) {
      if (type === 'student') {
        searchAdminStudents({
          query: debouncedSearch,
        }).then(({ students }) => {
          if (students[0]) {
            openChat('student', students[0].user);
          }
        });
      } else if (type === 'company') {
        searchAdminCompanies({
          query: debouncedSearch,
        }).then(({ companies }) => {
          if (companies[0]) {
            const { avatar, company_name, user_uid, email } = companies[0];

            openChat('company', {
              avatar,
              first_name: company_name,
              user_uid,
              email,
            });
          }
        });
      }
    }
  }, [debouncedSearch]);

  const openInfo = () => {
    if (userChat?.role !== 'admin' && userChat.user_id) {
      window.open(
        `${
          userChat?.role === 'company'
            ? Routes.AdminCompaniesRoute
            : Routes.AdminStudentsRoute
        }/${userChat.user_id}`,
        '_blank',
      );
    }
  };

  return (
    <div className={`chat-container`}>
      <div className="chat-header">
        <TextField
          placeholder="Type to search"
          sx={{ width: '256px' }}
          variant="standard"
          className="admin-table-search-input search-chat-input"
          onChange={(event) => setSearch(event.target.value)}
          data-testid="find-chat"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {userChat?.name && (
          <div className="chat-avatar-wrapper">
            {userChat?.role === 'company' ? (
              <CompanyAvatar
                avatar={userChat?.avatar}
                className="avatar-wrapper top"
              />
            ) : (
              <StudentAvatar
                alt="avatar"
                avatar={userChat?.avatar}
                className="avatar-wrapper right"
              />
            )}
          </div>
        )}
        <span className={`user-name`} onClick={openInfo}>
          {userChat?.name}
        </span>
      </div>
      <div ref={talkjsContainer} className="messages-list">
        ...loading
      </div>
    </div>
  );
};

export default ChatByType;
