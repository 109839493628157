import React, { useState, useEffect } from 'react';
import './JobOverview.scss';
import {
  ButtonBase,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
} from '@mui/material';
import MiniteGrid from '../../../../components/grid';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';

import {
  AdminJobProposals,
  AdminJobStudents,
  useFetchJob,
  useJobProposalsDetails,
  useJobStudentsDetails,
} from '../../../../data/admin-job-details';
import { Link, useParams } from 'react-router-dom';
import {
  CompletionReasons,
  JobStatuses,
  ProposalStatus,
} from '../../../../data/jobs';
import { formatDate } from '../../../../utils/dates';
import { budgetString, ProposalStatuses } from '../../../job-details';
import { Routes } from '../../../../utils/consts';
import ProposalStatusChip from '../../../job-details/components/ProposalStatusChip';
import { JobTypes, StatusValues, StudentLevel } from './constants';
import {
  fetchProposalInfoByProposalUid,
  useUpdateAdminJobDetails,
} from '../../../../data/admin';
import { jobTypeIsOrigin } from '../index';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { JobDrawer } from '../../../../components/job-drawer';
import SkeletonProposalModal from '../../../job-details/SkeletonProposalModal';
import ProposalModal from './proposal-modal';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useUpdateAdminProposalStatus } from '../../../../data/admin-dashboard';
import MiniteSnackbar from '../../../../components/snackbar';
import { sortDate } from '../../../../utils/sortDate';
import GenericModal from '../../../../components/generic-modal/GenericModal';

const proposalStatuses = [
  { label: 'Draft', key: 'draft' },
  { label: 'Pending', key: 'pending' },
  { label: 'Retracted', key: 'retracted' },
  { label: 'Interviewing', key: 'interviewing' },
  { label: 'Rejected', key: 'rejected' },
  { label: 'Accepted', key: 'accepted' },
  { label: 'Signed', key: 'signed' },
];

const openTimesheet = (student_uid, job_uid) => {
  window.open(`${Routes.HoursRoute}/${student_uid}/${job_uid}`, '_blank');
};

const getProposalRows = (proposalList: AdminJobProposals, jobType: string) => {
  if (!proposalList || !proposalList.proposals?.length) return [];

  return proposalList.proposals
    .sort((a, b) => sortDate(a.created_at, b.created_at))
    .map((proposal) => {
      const {
        proposal_uid,
        created_at,
        proposal_status,
        student,
        rate,
        alias,
      } = proposal;
      return {
        id: proposal_uid,
        date: formatDate(created_at, 'DD/MM/YYYY'),
        status: proposal_status,
        student: student,
        student_id: student.student_uid,
        rate: budgetString(rate, jobType),
        alias: alias || '',
        isLoading: false,
      };
    });
};

const getStudentRows = (studentList: AdminJobStudents, jobType: string) => {
  if (!studentList || !studentList.students?.length) return [];

  return studentList.students.map((student) => {
    const { student_type, rate, education, proposal_uid } = student;
    const { student_uid } = student.student;

    const recentEducation = education.sort((a, b) =>
      new Date(b.ended_at).getTime() - new Date(a.ended_at).getTime() ? 1 : -1,
    )[0];

    return {
      id: student_uid,
      student: student.student,
      education: `${recentEducation.degree} ${recentEducation.study}`,
      level: StudentLevel[student_type],
      rate: budgetString(rate, jobType),
      proposalUid: proposal_uid,
    };
  });
};

const JobDetails = () => {
  const { id: jobId } = useParams<{ id?: string }>();

  const { data: job, refetch } = useFetchJob(jobId);
  const { data: students } = useJobStudentsDetails(jobId);
  const {
    data: proposals,
    refetch: refetchProposals,
    isLoading: isLoadingProposals,
  } = useJobProposalsDetails(jobId);
  const [jobStatus, setJobStatus] = useState('draft');
  const { mutateAsync: updateAdminJobDetails } = useUpdateAdminJobDetails();
  const {
    mutateAsync: updateAdminProposalStatus,
    isLoading: isUpdatingProposalStatus,
  } = useUpdateAdminProposalStatus();
  const [shownProposalDrawer, setShownProposalDrawer] = useState(false);
  const [proposal, setProposal] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isAgreement, setIsAgreement] = useState(false);
  const [isLoadingChangeJobStatus, setIsLoadingChangeJobStatus] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isJobCompleteModalOpen, setIsJobCompleteModalOpen] = useState(false);
  const [jobCompletionReason, setJobCompletionReason] = useState('');

  const getJobStudents = (onProposalModalOpen): GridColDef[] => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'student',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const { first_name, last_name, student_uid } = params.row.student;

          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminStudentsRoute}/${student_uid}`}
              title={`${first_name} ${last_name}`}
            >
              {first_name} {last_name}
            </Link>
          );
        },
      },
      {
        field: 'education',
        headerName: 'Education',
        flex: 1,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'level',
        headerName: 'Level',
        width: 110,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'rate',
        headerName: 'Rate',
        width: 100,
        sortingOrder: ['asc', 'desc'],
        hide: job?.direct_job,
        sortComparator: (a, b) => {
          return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 185,
        sortable: false,
        align: 'right',
        hide: job?.direct_job,
        renderCell: (params: GridRenderCellParams) => {
          const { student, proposalUid } = params.row;
          return (
            <div className="actions-bar">
              <ButtonBase
                onClick={() => openTimesheet(student.student_uid, jobId)}
                className="open-button"
                data-testid="open-timesheet"
              >
                Go to Timesheet
              </ButtonBase>

              <ButtonBase
                onClick={() => onProposalModalOpen(proposalUid)}
                data-testid="open-proposal-modal"
              >
                <ChevronRightOutlinedIcon
                  sx={{
                    color: '#DEB900',
                    cursor: 'pointer',
                  }}
                  className="next-button"
                />
              </ButtonBase>
            </div>
          );
        },
      },
    ];
  };

  useEffect(() => {
    if (job) {
      setJobStatus(job?.job_status);
    }
  }, [job]);

  const finalizeChangeJobStatus = async (status: string, reason?: string) => {
    try {
      setIsLoadingChangeJobStatus(true);
      await updateAdminJobDetails({
        jobId,
        jobDetails: {
          job: { job_status: status, job_completed_reason: reason },
        },
      });

      await refetch();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoadingChangeJobStatus(false);
    }
  };

  const handleChangeJobStatus = async (e) => {
    if (e.target.value === JobStatuses.COMPLETED) {
      setIsJobCompleteModalOpen(true);
    } else {
      finalizeChangeJobStatus(e.target.value);
    }
  };

  const openAgreement = (id) => {
    fetchProposalInfoByProposalUid(id).then((res) => {
      if (res.proposal.pdf_url) {
        window.open(res.proposal.pdf_url, '_blank');
      } else {
        setIsAgreement(true);
      }
    });
  };

  const handleProposalDrawerClose = () => {
    setShownProposalDrawer(!shownProposalDrawer);
  };

  const onProposalModalOpen = async (proposalId) => {
    handleProposalDrawerClose();
    setIsSkeleton(true);

    const { proposal } = await fetchProposalInfoByProposalUid(proposalId);

    await setProposal(proposal);
    await setIsSkeleton(false);
  };

  const changeProposalStatus = (id, status) => {
    updateAdminProposalStatus({ proposalId: id, status })
      .then(() => {
        refetchProposals();
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.error.message || error.message.toString(),
        );
      });
  };

  const getJobProposals = (): GridColDef[] => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'date',
        headerName: 'Date',
        width: 100,
        sortingOrder: ['asc', 'desc'],
        sortComparator: (a, b) => sortDate(a, b),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 180,
        sortingOrder: ['asc', 'desc'],
        renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
          <span data-testis={params.value}>
            <ProposalStatusChip forStudent proposalStatus={params.value} />
          </span>
        ),
      },
      {
        field: 'student',
        headerName: 'Student',
        flex: 1,
        sortingOrder: ['asc', 'desc'],
        renderCell: (params: GridRenderCellParams) => {
          const { first_name, last_name, student_uid } = params.row.student;

          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              to={`${Routes.AdminStudentsRoute}/${student_uid}`}
              title={`${first_name} ${last_name}`}
            >
              {first_name} {last_name}
            </Link>
          );
        },
      },
      {
        field: 'alias',
        headerName: 'Alias',
        width: 95,
        sortingOrder: ['asc', 'desc'],
        sortComparator: (a, b) => {
          return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
        },
      },
      {
        field: 'rate',
        headerName: 'Rate',
        width: 95,
        sortingOrder: ['asc', 'desc'],
        hide: job?.direct_job,
        sortComparator: (a, b) => {
          return parseFloat(a.slice(1)) < parseFloat(b.slice(1)) ? -1 : 1;
        },
      },
      {
        field: 'actions',
        headerName: 'Change Status',
        type: 'actions',
        sortable: false,
        align: 'right',
        width: 125,
        getActions: (params: GridRowParams) => {
          return proposalStatuses.reduce((acc, { label, key }) => {
            acc.push(
              <GridActionsCellItem
                sx={{
                  textTransform: 'capitalize',
                }}
                onClick={() => changeProposalStatus(params.row.id, key)}
                label={label}
                showInMenu
                data-testid={`status-${key}`}
              />,
            );

            return acc;
          }, []);
        },
      } as GridActionsColDef,
      {
        field: 'arrow',
        headerName: '',
        sortable: false,
        align: 'right',
        width: 195,
        renderCell: (params: GridRenderCellParams) => (
          <div className="actions-bar">
            {params.row.status === ProposalStatuses.SIGNED && (
              <ButtonBase
                onClick={() => openAgreement(params.row.id)}
                className="open-button"
                data-testid="view-sign-agreement"
              >
                View Signed Agreement
              </ButtonBase>
            )}

            <ButtonBase
              onClick={() => onProposalModalOpen(params.row.id)}
              data-testid="open-proposal-modal"
            >
              <ChevronRightOutlinedIcon
                sx={{
                  color: '#DEB900',
                  cursor: 'pointer',
                }}
                className="next-button"
              />
            </ButtonBase>
          </div>
        ),
      },
    ];
  };

  return (
    <div className="job-details-container">
      <section className="job-info-section">
        <div className="job-info-container">
          <span className="info-label">
            {job?.direct_job ? 'Compensation' : 'Rate'}
          </span>
          <span className="info-value">
            {job?.direct_job
              ? job.direct_job_compensation || '-'
              : budgetString(job?.budget || 0, job?.job_type)}
          </span>
        </div>

        <div className="job-info-container">
          <span className="info-label">Proposals</span>
          <span className="info-value">{job?.proposals_count}</span>
        </div>

        <div className="job-info-container">
          <span className="info-label">Hiring amount of High-Flyers</span>
          <span className="info-value">
            {job?.direct_job
              ? '-'
              : job?.students_needed > 1 && Number(!job?.parent_job_uid) == 1
              ? `${job?.children_jobs || 0} / ${job?.students_needed}`
              : job?.students_needed || 0}
          </span>
        </div>

        <div className="job-info-container">
          <span className="info-label">Job type</span>
          <span className="info-value">
            {job?.direct_job
              ? `Direct job - ${job?.direct_job_type}`
              : JobTypes[job?.job_type]}
            , {jobTypeIsOrigin[Number(!job?.parent_job_uid)]}
          </span>
        </div>

        <div className="job-info-container">
          <span className="info-label">Promo pack</span>
          <span className="info-value">
            {Number(job?.job_boost_pack) ? 'Yes' : 'No'}
          </span>
        </div>

        <div className="job-info-container">
          <span className="info-label">Status</span>
          {isLoadingChangeJobStatus ? (
            <Skeleton sx={{ height: 50, width: 284 }} />
          ) : (
            <Select
              id="status-select"
              variant="standard"
              value={jobStatus}
              onChange={handleChangeJobStatus}
            >
              {StatusValues.map((item) => {
                const { id, label } = item;
                return (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </div>
        <div className="job-info-container">
          <span className="info-label">Creation date</span>
          <span className="info-value">
            {formatDate(job?.created_at, 'DD/MM/YYYY')}
          </span>
        </div>
        <div className="job-info-container">
          <span className="info-label">View count</span>
          <span className="info-value">
            Total: {job?.views ? job?.views : '-'} Unique:{' '}
            {job?.unique_views ? job?.unique_views : '-'}
          </span>
        </div>
        {(job?.job_status === JobStatuses.IN_PROGRESS ||
          job?.job_status === JobStatuses.COMPLETED ||
          job?.job_status === JobStatuses.CANCELLED) && (
          <>
            <div className="job-info-container">
              <span className="info-label">Match date</span>
              <span className="info-value">
                {job?.matched_at
                  ? formatDate(job?.matched_at, 'DD/MM/YYYY')
                  : '-'}
              </span>
            </div>
          </>
        )}
        {job?.job_status === JobStatuses.COMPLETED && (
          <>
            <div className="job-info-container">
              <span className="info-label">Completion date</span>
              <span className="info-value">
                {job?.completed_at
                  ? formatDate(job?.completed_at, 'DD/MM/YYYY')
                  : '-'}
              </span>
            </div>
            <div className="job-info-container">
              <span className="info-label">Completion reason</span>
              <span className="info-value">
                {job?.job_completed_reason ? job?.job_completed_reason : '-'}
              </span>
            </div>
            <div className="job-info-container">
              <span className="info-label">Company end reason</span>
              <span className="info-value">
                {job?.job_cancelled_reason ? job?.job_cancelled_reason : '-'}
              </span>
            </div>
          </>
        )}
        {job?.job_status === JobStatuses.CANCELLED && (
          <>
            <div className="job-info-container">
              <span className="info-label">Cancel date</span>
              <span className="info-value">
                {job?.cancelled_at
                  ? formatDate(job?.cancelled_at, 'DD/MM/YYYY')
                  : '-'}
              </span>
            </div>
            <div className="job-info-container">
              <span className="info-label">Cancel reason</span>
              <span className="info-value">
                {job?.job_cancelled_reason ? job?.job_cancelled_reason : '-'}
              </span>
            </div>
          </>
        )}
      </section>
      {
        <>
          <section className="job-info-section overview-grids">
            <p className="job-grid-title overview-grid-title">
              Received Proposals
            </p>
            <MiniteGrid
              pagination
              columns={getJobProposals()}
              rows={getProposalRows(proposals, job?.job_type)}
              rowId={'student_id'}
              rowsPerPageOptions={[5, 10, 20]}
              disableColumnFilter={true}
              loading={isUpdatingProposalStatus || isLoadingProposals}
            />
          </section>
          <section className="job-info-section overview-grids">
            <p className="job-grid-title overview-grid-title">Hired students</p>
            <MiniteGrid
              pagination
              columns={getJobStudents(onProposalModalOpen)}
              rows={getStudentRows(students, job?.job_type)}
              rowId={'id'}
              rowsPerPageOptions={[5, 10, 20]}
              disableColumnFilter={true}
            />
          </section>
          {shownProposalDrawer && (
            <JobDrawer
              open={shownProposalDrawer}
              onClose={handleProposalDrawerClose}
              className="job-drawer-company--wrapper"
            >
              {isSkeleton ? (
                <SkeletonProposalModal />
              ) : (
                <ProposalModal
                  jobInfo={{
                    question_required: job.question_required,
                    student_type: job.student_type,
                    question_optional: job.question_optional,
                  }}
                  proposal={proposal}
                  handleDrawerClose={handleProposalDrawerClose}
                />
              )}
            </JobDrawer>
          )}
        </>
      }
      <GenericModal
        open={isJobCompleteModalOpen}
        title="Completion reason"
        content=""
        confirmButtonText="Complete"
        cancelButtonText="Cancel"
        handleConfirm={() => {
          setIsJobCompleteModalOpen(false);
          finalizeChangeJobStatus(JobStatuses.COMPLETED, jobCompletionReason);
          setJobCompletionReason('');
        }}
        handleCancel={() => {
          setIsJobCompleteModalOpen(false);
          setJobCompletionReason('');
        }}
        confirmButtonDisabled={jobCompletionReason === ''}
        confirmButtonTestId="complete-job"
      >
        <RadioGroup
          sx={{ marginTop: '10px' }}
          data-testid="completion-reasons"
          onChange={(e) => {
            setJobCompletionReason((e.target as HTMLInputElement).value);
          }}
        >
          {Object.values(CompletionReasons).map((reason) => (
            <FormControlLabel
              value={reason}
              control={<Radio />}
              label={reason}
              key={reason}
            />
          ))}
        </RadioGroup>
      </GenericModal>
      <MiniteSnackbar
        autoHideDuration={null}
        message="There is no agreement"
        open={isAgreement}
        onClose={() => setIsAgreement(false)}
      />
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
};

export default JobDetails;
