import React, { useEffect, useState } from 'react';
import {
  Firestore,
  collection,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { doc, getDoc, onSnapshot, Timestamp } from 'firebase/firestore';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  Button,
} from '@chatscope/chat-ui-kit-react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { updateUserProfileByAdmin, UserData } from '../../../data/user';
import { findAdminUidFromChats } from '../../../utils/chat';
import { COMPANY_AVATAR_BUCKET, Routes } from '../../../utils/consts';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import { CircularProgress } from '@mui/material';
import { useUploadAttachment } from '../../../data/chats';
import Linkify from 'react-linkify';
import { FaFileDownload } from 'react-icons/fa';
import MiniteSnackbar from '../../../components/snackbar';

const ChatByType = ({
  type,
  user,
  db,
}: {
  type: string;
  user: UserData;
  db: Firestore;
}) => {
  const [messageInputValue, setMessageInputValue] = useState('');
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userChat, setUserChat] = useState(null);
  const [adminUid, setAdminUid] = useState(null);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [isChangeLoading, setIsChangeLoading] = useState(false);
  const [tempAttachment, setTempAttachment] = useState(null);
  const [errorSnack, showSnack] = useState(null);

  const { mutateAsync: uploadAttachment, isLoading: attachmentIsLoading } =
    useUploadAttachment();

  useEffect(() => {
    /**
     * This useEffect is used to get all chats of the user
     */
    if (user) {
      const getChats = async () => {
        setChatsLoading(true);
        const adminUidFromDb = await findAdminUidFromChats(db);
        setAdminUid(adminUidFromDb);
        const qAdminChats = query(
          collection(db, 'chats'),
          where('proposal_uid', '==', 'admin'),
          where('messages', '!=', []),
        );
        const adminChats = await getDocs(qAdminChats);
        const chats = [];
        for (const chatDoc of adminChats.docs) {
          if (!chatDoc.exists()) {
            continue;
          } else if (chatDoc.data().messages.length === 0) {
            continue;
          }
          const otherUserId = chatDoc
            .data()
            .users.filter((u) => u !== adminUid)[0];
          const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
          const otherUser = otherUserDoc.data();
          if (otherUser.role === type) {
            chats.push({
              ...chatDoc.data(),
              ...otherUserDoc.data(),
              id: chatDoc.id,
            });
          }
        }
        const sortedChats = chats.sort(
          (a, b) =>
            b.messages[b.messages.length - 1]?.created_at.toDate() -
            a.messages[a.messages.length - 1]?.created_at.toDate(),
        );
        setChats(sortedChats);
        setChatsLoading(false);
      };
      getChats();
    }
  }, []);

  const handleChangeChatSetOtherUser = async (otherUserId) => {
    setIsChangeLoading(true);
    const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
    const otherUser = otherUserDoc.data();
    setUserChat({
      avatar:
        type === 'student'
          ? otherUser.avatarUrl
          : otherUser.avatar
          ? COMPANY_AVATAR_BUCKET + otherUser.avatar
          : undefined,
      name: otherUser.name,
      role: otherUser.role,
      user_id: otherUserDoc.id,
    });
    setIsChangeLoading(false);
  };

  const handleChangeChat = async (chatId) => {
    setCurrentChat(chatId);
    let otherUserId;
    onSnapshot(doc(db, 'chats', chatId), (document) => {
      otherUserId = document.data().users.filter((u) => u !== adminUid)[0];
      const sortedMessages = document
        .data()
        .messages?.sort(
          (a, b) =>
            a.created_at.toDate().toString() - b.created_at.toDate().toString(),
        );
      setMessages(sortedMessages);
      handleChangeChatSetOtherUser(otherUserId);
    });
    if (messages[messages.length - 1]?.sent_by !== adminUid) {
      setDoc(
        doc(db, 'chats', chatId),
        {
          seen: true,
        },
        { merge: true },
      );
    }
  };

  const stripMessage = (message: string) => {
    let messageValue = message.replace(
      /(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g,
      '',
    );
    messageValue = messageValue.replace(/<br\s*\/?>/g, '\n');
    const tmp = document.createElement('DIV');
    tmp.innerHTML = messageValue;
    return tmp.textContent || tmp.innerText || '';
  };

  const sendMessage = async () => {
    if (
      messageInputValue
        .replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '')
        .trim() === ''
    ) {
      return;
    }

    let messageValue = stripMessage(messageInputValue);
    if (tempAttachment) {
      const imageResponse = await uploadAttachment({
        filename: tempAttachment?.file?.name,
        contentType: tempAttachment?.file?.type,
        for_user_uid: adminUid,
        for_chat_id: currentChat,
      });
      await fetch(imageResponse?.data?.url, {
        method: 'PUT',
        body: new Blob([tempAttachment?.reader?.result], {
          type: tempAttachment?.file?.type,
        }),
      });
      const bucketUrl =
        process.env.REACT_APP_S3_BUCKET_URL +
        `/attachments/${currentChat}/${imageResponse?.data?.filename}`;
      messageValue = 'Download attachment: ' + bucketUrl;
    }

    await setDoc(
      doc(db, 'chats', currentChat),
      {
        messages: [
          ...messages,
          {
            message: messageValue,
            sent_by: adminUid,
            created_at: Timestamp.now(),
          },
        ],
        seen: false,
      },
      { merge: true },
    );
    setMessageInputValue('');
    setTempAttachment(null);
  };

  const markConversationAsUnread = async (chatToUnread) => {
    setCurrentChat(null);
    setDoc(
      doc(db, 'chats', chatToUnread),
      {
        seen: false,
      },
      { merge: true },
    );
  };

  const openUser = async () => {
    const updatedUser = await updateUserProfileByAdmin(userChat.user_id, {});
    if (userChat?.role !== 'admin' && userChat.user_id) {
      window.open(
        `${
          userChat?.role === 'company'
            ? Routes.AdminCompaniesRoute
            : Routes.AdminStudentsRoute
        }/${
          userChat?.role === 'company'
            ? updatedUser.company_uid
            : updatedUser.student_uid
        }`,
        '_blank',
      );
    }
  };

  // Attachment

  const hiddenFileInput = React.useRef(null);

  const handleAttachment = async () => {
    hiddenFileInput.current.click();
    setMessageInputValue('Selecting Attachment');
  };

  const handleChange = async (event) => {
    setMessageInputValue('Attachment selected');
    const fileUploaded = event.target.files[0];
    if (fileUploaded?.size > 1048576) {
      showSnack('File size should be less or equal 1mb');
      setMessageInputValue('');
      return;
    }
    const tempFileUrl = URL.createObjectURL(fileUploaded);
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileUploaded);
    setTempAttachment({ url: tempFileUrl, file: fileUploaded, reader: reader });
  };

  return (
    <div>
      <section>
        <div style={styles}>
          <MainContainer responsive style={{ height: '500px' }}>
            <Sidebar
              position="left"
              scrollable={false}
              style={{ height: '500px' }}
            >
              <Search placeholder="Search..." disabled />
              {chatsLoading ? (
                <div style={{ margin: 'auto' }}>
                  <CircularProgress />
                </div>
              ) : (
                <ConversationList>
                  {chats.map((chat) => (
                    <Conversation
                      key={chat.id}
                      name={chat.name}
                      onClick={() => handleChangeChat(chat.id)}
                      active={currentChat === chat.id}
                      lastSenderName={
                        chat.messages[chat.messages.length - 1]?.sent_by ===
                        adminUid
                          ? 'You'
                          : chat.name
                      }
                      info={chat.messages[chat.messages.length - 1]?.message}
                      unreadDot={
                        chat.messages.length > 0 &&
                        !chat.seen &&
                        chat.messages[chat.messages.length - 1]?.sent_by !==
                          adminUid
                      }
                      lastActivityTime={chat.messages[
                        chat.messages.length - 1
                      ]?.created_at
                        .toDate()
                        .toLocaleString([], {
                          month: 'numeric',
                          day: 'numeric',
                        })}
                    >
                      <Avatar
                        src={
                          chat.avatarUrl
                            ? chat.avatarUrl
                            : '/images/student-avatars/7.png'
                        }
                      />
                    </Conversation>
                  ))}
                </ConversationList>
              )}
            </Sidebar>

            {isChangeLoading && (
              <div style={{ margin: 'auto' }}>
                <CircularProgress />
              </div>
            )}

            {currentChat && (
              <ChatContainer style={{ height: '500px' }}>
                <ConversationHeader>
                  <Avatar
                    src={
                      userChat?.avatar
                        ? userChat.avatar
                        : '/images/student-avatars/7.png'
                    }
                  />
                  <ConversationHeader.Content userName={userChat?.name}>
                    <span className={`user-name`} onClick={openUser}>
                      {userChat?.name}
                    </span>
                  </ConversationHeader.Content>
                  <ConversationHeader.Actions>
                    <Button
                      icon={<MarkChatUnreadIcon />}
                      onClick={() => markConversationAsUnread(currentChat)}
                    >
                      Mark as unread
                    </Button>
                  </ConversationHeader.Actions>
                </ConversationHeader>
                <MessageList>
                  {messages.map((message) => (
                    <Message
                      key={message.created_at.toDate().toString()}
                      model={{
                        sentTime: message.created_at.toDate().toString(),
                        sender: message.sent_by,
                        direction:
                          message.sent_by === adminUid
                            ? 'outgoing'
                            : 'incoming',
                        position: 'single',
                      }}
                    >
                      <Message.Header
                        sentTime={message.created_at
                          .toDate()
                          .toLocaleString([], {
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                      />
                      <Message.CustomContent>
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key,
                          ) => (
                            <a
                              target="blank"
                              rel="noopener"
                              href={decoratedHref}
                              key={key}
                              style={{ textDecoration: 'underline' }}
                            >
                              {decoratedText}
                            </a>
                          )}
                        >
                          {message.message.startsWith(
                            `Download attachment: ${process.env.REACT_APP_S3_BUCKET_URL}`,
                          )
                            ? 'Download attachment'
                            : stripMessage(message.message)}
                        </Linkify>
                        {message.message.startsWith(
                          `Download attachment: ${process.env.REACT_APP_S3_BUCKET_URL}`,
                        ) && (
                          <>
                            <br />
                            <a
                              target="blank"
                              rel="noopener"
                              href={message.message
                                .split(' ')
                                .slice(2)
                                .join(' ')}
                            >
                              <FaFileDownload style={{ fontSize: '400%' }} />
                            </a>
                          </>
                        )}
                      </Message.CustomContent>
                    </Message>
                  ))}
                </MessageList>
                <MessageInput
                  placeholder="Type message here"
                  value={
                    !!tempAttachment
                      ? attachmentIsLoading
                        ? '⏳'
                        : `📁 [ATTACHMENT: ${tempAttachment?.file?.name}]`
                      : messageInputValue
                  }
                  onChange={(val) => setMessageInputValue(val)}
                  onSend={() => sendMessage()}
                  onAttachClick={handleAttachment}
                  sendDisabled={false}
                  sendButton={!attachmentIsLoading}
                  disabled={attachmentIsLoading}
                />
              </ChatContainer>
            )}
          </MainContainer>
        </div>
        <input
          hidden
          accept="image/*,application/pdf,video/*,.doc,.xls,.csv,.ppt"
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
        />

        <MiniteSnackbar
          open={!!errorSnack}
          title={'Error'}
          message={errorSnack}
          autoHideDuration={4000}
          severity={'error'}
          onClose={() => showSnack(null)}
        />
      </section>
    </div>
  );
};

export default ChatByType;
